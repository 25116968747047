import React from 'react';
import DataTable from 'react-data-table-component';
import AddIndicatorModal from '../../Modals/indicatorModal';

import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import MetaDataindiactor from './metaData-indicator'; 
// import MetaDataModal from '../../../Common/components/modal/metadata.modal';
// const handleChange = ({ selectedRows }) => {
//   // You can set state or dispatch with something like Redux so we can use the retrieved data
//   console.log('Selected Rows: ', selectedRows);
// };

var indTemplate = function() {
  
  return (
<>

    <div className="main" id="main">
    <div className='container-fluid areaCont'>
    <section className='areaSection' id="areaSection">
      <subHeaderComponentMemo.default onSearch={this.onSearch} 
                                      searchText={this.state.searchText} 
                                      PropDeleteBulk={this.deleteBulkUser}
                                      value='5'
                                      checkData = {this.state.bulkDelete}
                                      nRows={this.state.filteredData?.length}
                                      />
      <DataTable
          columns={this.columns}
          data={this.state.filteredData}
          direction="auto"
          fixedHeaderScrollHeight="500px"
          pagination
          paginationRowsPerPageOptions={[10, 25, 50,100]}
          style={{ display: "flex" }}
          persistTableHead
          selectableRows
          onSelectedRowsChange={this.handleChange}
          clearSelectedRows={this.handleChangeData}
          className='dataTableScroll'
      />
    </section>
    </div>
    </div>
    <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>

<div id="openDataModal" onClick={this.openModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
        <img
          src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
      </div>
      <div class="right-tab-heading are_head">
        <h2 class="form-heading">
          <span class="form-heading_he">
            <span class="ddf">
              {this.state.isEdit==true ? 'Edit indicator': 'Add indicator'}
            </span>
          </span>
        </h2>
      </div>
    <AddIndicatorModal  parentCallback = {this.callBack} 
                        editMode = {this.state.editModeId} 
                        allIndicatorList = {this.state.allIndicatorList}
                        allUnitList = {this.state.allUnitList}
                        allSubgroupDimensionList = {this.state.allSubgroupDimensionList}
                        allDimensionList = {this.state.allDimensionList}
                        sectorSubsectorList = {this.state.sectorSubsectorList}
                        goalTargetList = {this.state.goalTargetList} 
                        />
      


    <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div> 
      </div>
      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>
    

      <MetaDataindiactor  data = {this.state.inddata}  />
    </>
  )
}

export default indTemplate;