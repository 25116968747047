
import React, { useEffect, useRef, useState } from "react";

import Resources from '../pages/manage-resource/resources.js'

import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import InfoIcon from '@mui/icons-material/Info';
const ResourceEntryModal = (props) => {
    const form = useRef()
    
    
    

    
    
    
    const sourceRef = useRef();
    const titleRef = useRef();
    const typeRef = useRef();
    const documentRef = useRef();
    const descriptionRef = useRef();
    const urlRef = useRef();
    const imageRef = useRef();
    const imageSideRef = useRef();
    
    let imageBackground="";
    let documentBackground="";
    let urlpath="";

    const [subData, setSubGroup] = useState([]);
    const [areaTree, setAreaTree] = useState([]);
    const [image, setImage] = useState([]);
    const [title, setTitle] = useState([]);
    const [description, setDescription] = useState([]);
    const [typestate, setType] = useState([]);
    const [innovationLogoBase64, setInnovationLogoBase64] = useState([]);
    // const [filename, setFilename] = useState([]);
    const [documentState, setDocumentState] = useState([]);
    const [iddata, setIdData] = useState([]);
    const [urlState, setURLState] = useState([]);
    const [urlDataState, setURLData] = useState([]);
    const [docDataState, setDocDataState] = useState([]);
    const [formError, setError] = useState({error:false});
    const [documentdataSate, setDocumentDataState] = useState()
    const [imagedatastate, setImageDataState] = useState()
    const [slectGroup, setSelectGroup] = useState()

    useEffect(() => {
        
        setAreaTree(props.areaTree)
     
       
    });

    useEffect(() => {
     
        console.log(props.isEdit)
        if(props.isEdit==true){
            
            getEditData(props.editData[0])
        }
        else{
            
            
            
            setTitle("");
            setDescription("");
            
            setType("");
            
            // image.current.files[0].value = '';
            setURLState(false);
            setDocumentState(false);
            setDocDataState("");
            setImage("");
            setURLData("");
            setDocumentDataState("");
            
            setImageDataState("");
            
            return false;
        }


        
    },[props.isEdit, props.editData])

   const getEditData =(data) =>{


  if(data!=null){

    // props.resourcesId(data['iu_id'])
    console.log(data)
  
    
    setIdData(data['_id']);
    console.log("id--"+iddata)
    
    setTitle(data['title']);
    
    setType(data['type']);
    handleChange1(data['type']);
    console.log("state--"+urlState)
    documentBackground=data['document'];
    imageBackground=data['image'];
    setImage(data['image']);

    setURLData(data['url']);
    urlpath=data['url'];
    setDocDataState(data['document']);
   
    
    setDescription(data['description']);
    
    
    
    
   }
}

const document=false;
const url=false;

console.log("url--"+urlpath)

const handleChange = async (e) => {


    if(e.target.value=='Document'){
        setType("Document");
        setDocumentState(true);
        setURLState(false);
        setURLData("");
    }
    else{
        setType("URL");
        setDocumentState(false);
        setURLState(true);
        setDocDataState(false);
    }
  
}
const handleChange1 = async (e) => {


    if(e=='Document'){
        setDocumentState(true);
        setURLState(false);
    }
    else{
        setDocumentState(false);
        setURLState(true);
    }
  
}





    const searchTree = (e) => {


    //     areaTree
    //    .filter(item => {
    //       if (!e.target.value) return true
    //       if (item.name.includes(e.target.value) || item.areaSubData.name.includes(e.target.value)) {
    //         console.log('s',item)
    //         return true
    //       }
    //     }
    //     )

    //     const newData = []

    //    let found =  areaTree.filter((item2=>item2.name === e.target.value))

    //    console.log('ddd', found)

        };


   let path="";

  const getBase64 = async(file) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      
      let reader = new FileReader();

      
      reader.readAsDataURL(file);

     
        
        
        
        
      
      reader.onload = () => {
        
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
   
      console.log(fileInfo);
    });
  };

       
     const getImage = async (event)=> {

       
            let bfile = "";
            let files = imageRef.current.files[0];
            
      
        
            let file = files;
            
            
            
            let filename=files.name;
        
            
            
            if (files && file) {
              
              
         
             await getBase64(file)
              .then(result => {
                file["base64"] = result;
                let b = file.base64;
                console.log("File Is", file.base64);
                
                bfile=b.split(",").pop()

                
              })

            
              console.log("bfile"+bfile)
              
              
            
            path = (filename + ';' + bfile);
            console.log("path--"+path)
            setImageDataState(path);
       
            }
          };


          let docpath="";
          const getDocument = async (event)=> {
            //setDocumentState(false);
            setURLState(false);
            let bfile="";
           
            let files = documentRef.current.files[0];
            console.log("uuuu---"+files)
 
        setURLState(false);
        
            let file = files;
            console.log("ffff--"+files.name)
            
            // setFilename(files.name)
            let filename=files.name;
        
            console.log("fname"+filename)
            
            if (files && file) {
              
                await getBase64(file)
                .then(result => {
                  file["base64"] = result;
                  let b = file.base64;
                  console.log("File Is", file.base64);
                  
                  bfile=b.split(",").pop()
  
                  
                })
        
            
              
              console.log("bfile"+bfile)
            
            docpath = (filename + ';' + bfile);
            console.log("docpath--"+docpath)
            setDocumentDataState(docpath);
            // setDocDataState("")
            //   reader.readAsBinaryString(file);
              //this.image = "";
            }
          };


          
          const getURL = async (event)=> {

            setURLData(urlRef.current.value)
           
            urlpath = urlRef.current.value;
       
        
            console.log("ffff--"+urlpath)
          
            
      
          };
          const getTitle = async (event)=> {

            setTitle(titleRef.current.value);
      
          };

          const getDescription = async (event)=> {

            setDescription(descriptionRef.current.value);
      
          };
 
        //  const _handleReaderLoaded = (readerEvt) => {
        //     console.log("------a------"+readerEvt)
        //     let binaryString = readerEvt.target.result;
        //     setInnovationLogoBase64(btoa(binaryString));
        //     console.log("name--"+filename)
        //     console.log("innnno", innovationLogoBase64);
            
        //     setImage(filename + ';' + innovationLogoBase64)
            
            
        //     console.log("image"+image)
        // };
   


  const  onDataSubmit = (e) =>{

        e.preventDefault();
        
        // debugger;
        let dataToSend = [];
        if(props.isEdit==true){
            console.log("iddata--"+iddata)
             dataToSend = {
            
                
                    "id": iddata,
                    "description":e.target.description.value,
                    "document":documentdataSate,
                    "image":imagedatastate,
                    "title":e.target.title.value,
                    "type":e.target.ptype.value,
                    "url":urlDataState
                
            }


        }
        else{
            console.log("docpath11111--"+docpath)
             dataToSend = {
                
               
                "description":e.target.description.value,
                "document":documentdataSate,
                "image":imagedatastate,
                "title":e.target.title.value,
                "type":e.target.ptype.value,
                "url":urlDataState
            }
            
    
        }
        console.log("igggggg--"+imageBackground)
        console.log("document--"+documentState)
        console.log("urlstate--"+urlState)
        let doccheck=documentState;
        let urlcheck=urlState;
        console.log("doccheck--"+doccheck)
        console.log("urlcheck--"+urlcheck)
        if(e.target.title.value==null || e.target.title.value==''){
            setError({error:true, msg:'Please enter title!', input:'title'})
            return;
        }
        else if(e.target.description.value==null || e.target.description.value==''){
            setError({error:true, msg:'Please enter description!', input:'description'})
            return;
        
        }

     

        else if(typestate==""){
            setError({error:true, msg:'Please select type!', input:'ptype'})
            return;
        
        }
        
        else if( image=="" && imagedatastate=="" ){
            console.log("gggggggggggggggggggggggggggggggggg")
            setError({error:true, msg:'Please add a image ', input:'image'})
            return;
        
        }
        
       

        
     else if(doccheck && docDataState=="" && documentdataSate==""){
        if(docDataState=="" && documentdataSate==""){
            setError({error:true, msg:'Please add a document!', input:'document'})
            return;
        }else{
            console.log("check-----------efffffwefffffw")
        }
       }
         
      
        
       else if(urlcheck && urlDataState==""){
            console.log("data-------------dfwmfnjfnwfnw");
            if(urlDataState==""){

                setError({error:true, msg:'Please enter url!', input:'url'})
                return;
            }else{
                console.log("else----------")
            }
       }
         

    
    
        else{

            console.log("fgdghhhhhhhhhhhjv6565688888888888")
            props.newDataEntry(dataToSend);
            
            
            e.target.reset();
            typeRef.current.value = '';
       
           
        }

  

    }


      // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  }
// ====================================================== Form Validation Start




// ====================================================== Form Validation End

    return (
        <>
            <div className="user-form">
                <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={onDataSubmit} id="addUserForm">
                    <Stack spacing={2} sx={{ width: 300 }}  className="w-100">
                        {/* <form onSubmit={onDataSubmit} className="data_entry_form" id="dataEntryForm"> */}
                            <div className="gallery-collections mt-0 metadata_form">
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                    {/* {formError.error && formError.input == 'title'&& 
                                                    <small class="text-danger" ><i className="fa fa-info-circle"></i> {formError.msg}</small> */}
                                                    {/* // }                           <span className="mat-form-field-label-wrapper"></span> */}
                                            <label>Title</label>
                                        
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="mat-form-field-wrapper">
                                                <TextValidator name="title" value={title} onKeyUp={(e)=>{getTitle();}} onClick={getTitle} onChange={getTitle} autoComplete="off" ref={titleRef} className="w-100"/>
                                                {formError.error && formError.input == 'title'&& 
                                                    <small class="text-danger" > {formError.msg}</small>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Description</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="mat-form-field-wrapper">
                                                <TextValidator name="description" ref={descriptionRef} value={description} onKeyUp={(e)=>{getDescription();}} onClick={getDescription} onChange={getDescription} autoComplete="off" className="w-100"/>
                                                {formError.error && formError.input == 'description'&& 
                                                    <small class="text-danger" >
                                                        {/* <i className="fa fa-info-circle"></i> */}
                                                         {formError.msg}</small>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Type</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="mat-form-field-wrapper">
                                                <Select ref={typeRef} id="ptype" name="ptype" value={typestate} onChange={handleChange}>
                                                <MenuItem style={{'display':'none'}} value="" selected disabled></MenuItem>
                                                    <MenuItem value="Document">Document</MenuItem>
                                                    <MenuItem value="URL">URL</MenuItem>
                                                </Select>
                                                {formError.error && formError.input == 'ptype'&& 
                                                    <small class="text-danger" >
                                                        {/* <i className="fa fa-info-circle"></i>  */}
                                                    {formError.msg}</small>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {documentState==true && 
                                    <>
                                        <div className="row align-items-center mb-30">
                                            <div className="col-md-3">
                                                <span className="mat-form-field-label-wrapper">
                                                    <label>Document</label>
                                                </span>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style">
                                                    <div className="mat-form-field-wrapper">
                                                        <input className="w-100 form-control"  type="file" ref={documentRef} name="document" onChange={getDocument} id="document"/>
                                                        <p className="mt-2 mb-0"><b>{docDataState}</b></p>
                                                        {formError.error && formError.input == 'document'&& 
                                                         <small class="text-danger" >
                                                            {/* <i className="fa fa-info-circle">
                                                            </i> */}
                                                             {formError.msg}</small>
                                                       }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>                                    
                                }
                                {urlState==true &&                                     
                                    <>
                                        <div className="row align-items-center mb-30">
                                            <div className="col-md-3">
                                                <span className="mat-form-field-label-wrapper">
                                                    <label>URL</label>
                                                </span>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style">
                                                    <div className="mat-form-field-wrapper">
                                                        <input className="w-100" ref={urlRef} name="url" value={urlDataState}  onKeyUp={(e)=>{getURL();}} onClick={getURL} onChange={getURL} type="url"/>
                                                        {formError.error && formError.input == 'url'&& 
                                                    <small class="text-danger" >
                                                        {/* <i className="fa fa-info-circle"></i>  */}
                                                        {formError.msg}</small>
                                                    }
                                                    </div>
                                                </div>
                                                <Tooltip title="Ex:https://www.example.com Or http://www.example.com " placement="right">
                                                <a style={{ 'float': 'right', 'margin': '-36px -30px 0 0', 'cursor': 'pointer', 'color': '#0C6638' }} href="javascript:;"><InfoIcon /></a>

                                            </Tooltip>
                                            </div>
                                        </div>
                                    </>
                                }
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Image</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="mat-form-field-wrapper">
                                                <input className="w-100 form-control" type="file" name="image" ref={imageRef} id="image" onChange={getImage} accept="image/*" />
                                                <p className="mt-2 mb-0" name="imgbak" id="imgbak" value={image}><b>{image}</b></p>
                                                {formError.error && formError.input == 'image'&& 
                                                    <small class="text-danger" >
                                                        {/* <i className="fa fa-info-circle"></i>  */}
                                                    {formError.msg}</small>
                                                    }
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            
                            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                                <div className="d-flex align-items-center justify-content-center">
                                    <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                                    <Button variant="contained" className="addbtn" type="submit">
                                        {props.isEdit? 'Update': 'Add' }
                                    </Button>
                                </div>
                            </div>
                            {/* <div className="col-lg-12">

const resetValue = async (e) => {

console.log("reset value")
    titleRef.current.value = '';
            typeRef.current.value = '';
            descriptionRef.current.value = '';
            // image.current.files[0].value = '';
            setURLState(false);
            setDocumentState(false);
            setDocDataState("");
            setImage("");
            setURLData("");
            setDocumentDataState("");
            
            setImageDataState("");
  
}

                               <div className="row">
                                    

                                    <div className="col-lg-12">
                                        
                                        <div className="bg-white form-data-box">


                    
                                            
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-6">
                                                <div className="form-group ">

                                                    <label>TITLE</label>
                                                    <input ref={titleRef} name="title" className="form-control" type="text"/>
                                                    {formError.error && formError.input == 'title'&& 
                                                    <small class="text-danger" ><i className="fa fa-info-circle"></i> {formError.msg}</small>
                                                    }

                                                </div>
                                            </div>
                                            </div>
                                            <div className="row">
                                            <div className="col-lg-12 col-sm-12">
                                                <div className="form-group ">

                                                    <label>DESCRIPTION</label>
                                                    <input ref={descriptionRef} name="description" className="form-control" type="text"/>
                                                    {formError.error && formError.input == 'description'&& 
                                                    <small class="text-danger" ><i className="fa fa-info-circle"></i> {formError.msg}</small>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12">
                                                <div className="form-group ">

                                                    <label>TYPE</label>
                                                    <select className="form-control pt-0 pb-0" ref={typeRef} id="ptype" name="ptype" onChange={handleChange}>
                                                        <option style={{'display':'none'}} value="" selected disabled></option>
                                                        <option value="Document">Document</option>
                                                        <option value="URL">URL</option>
                                                    </select>
                                                    {formError.error && formError.input == 'ptype'&& 
                                                    <small class="text-danger" ><i className="fa fa-info-circle"></i> {formError.msg}</small>
                                                    }

                                                </div>
                                            </div>
                                        </div>
                                    
                                    {documentState==true && 
                                    
                                    <>

                                        <div className="row">
                                        

                                        
                                            <div className="col-lg-12">
                                                    <div className="form-group filegroup">
                                                        <label class="form-label" for="customFile">DOCUMENT</label>
                                                        <input type="file" class="form-control pt-0" ref={documentRef} name="document" onChange={getDocument} id="document" />
                                                        <p><b>{docDataState}</b></p>
                                                        {formError.error && formError.input == 'document'&& 
                                                    <small class="text-danger" ><i className="fa fa-info-circle"></i> {formError.msg}</small>
                                                    }
                                                    </div>
                                                </div>

                                                

                                        </div>



                                    </>
                                    
                                    
                                    }  

                                    {urlState==true && 
                                    
                                    <>

                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12">
                                                <div className="form-group ">

                                                    <label>URL</label>
                                                    
                                                    <input ref={urlRef} name="url" className="form-control" value={urlDataState}  onKeyUp={(e)=>{getURL();}} onClick={getURL} onChange={getURL} type="text"/>
                                                    {formError.error && formError.input == 'url'&& 
                                                    <small class="text-danger" ><i className="fa fa-info-circle"></i> {formError.msg}</small>
                                                    }

                                                </div>
                                            </div>
                                        </div>

                                    </>
                                
                                
                                }  
                                            
                                        <div className="row">
                                                <div className="col-lg-12">
                                                    <div className="form-group filegroup">
                                                        <label class="form-label" for="customFile">IMAGE</label>
                                                        <input type="file" class="form-control pt-0" name="image" ref={imageRef} id="image" onChange={getImage} accept="image/*" />
                                                        <p name="imgbak" id="imgbak" value={image}><b>{image}</b></p>
                                                        {formError.error && formError.input == 'image'&& 
                                                    <small class="text-danger" ><i className="fa fa-info-circle"></i> {formError.msg}</small>
                                                    }
                                                    </div>
                                                </div>
                                                </div>
                                        
                                    
                                        
                                                
                                                
                                        <div className="row">
                                            <div className="col-lg-12 col-sm-12 text-center">
                                                
                                                <button type="submit"  className="btn btn-danger mdsubmitBtn" > {props.isEdit? 'Update': 'Add' }</button>
                                            </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>



                            </div> */}

                        {/* </form> */}
                    </Stack>
                </ValidatorForm>  
            </div>
        </>
    );

    

}


export default ResourceEntryModal