import * as constants from '../Config/app.constants';



  // const commonUrl = 'https://itmcloud.org/wmisstagingapigateway/api/';
  const commonUrl = constants.server+'api';

    // post method call ====================//
  

    const httpPost = (api, body) => {
      let headers =   new Headers({
        'Authorization': localStorage.getItem('token'),
       //  'Authorization' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxOGE1N2RjZDc4NTQ4MzZiMWMyOGFjMiIsInVzZXJfZGV0YWlscyI6ImFkbWluQHdtaXMub3JnIiwicm9sZSI6MSwiYXJlYSI6W3siX2lkIjoiNjE4YjdjZDZkMGJkOGQwMDEyYzRkZGUzIiwiYXJlYV9jb2RlIjoiTFNPIn1dLCJpYXQiOjE2NzAzMDE2ODIsImV4cCI6MTY3MDMxOTY4Mn0.wp7-tS-Q1LXOcfJEjAJKHK5fFCBaH-Blqd9q_KFrmEc',
         'Content-Type': 'application/json'
     });
 

      return  fetch(`${commonUrl}${api}`, {
            method: 'post',
            headers: headers,
            body: JSON.stringify(body),
        }).then((response) => handleResponse(response))
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw Error(error);
        });

    }


    const httpPostFile = async(api, body) => {
      console.log("formdata--"+body)
      let headers =   new Headers({
        'Authorization': localStorage.getItem('token'),
       
       'ContentType': 'multipart/form-data; boundary=----WebKitFormBoundarybD3Q7wNZESGu8puj'
                  //  'Accept': 'application/json'
     });
 

      return  await fetch(`${commonUrl}${api}`, {
            method: 'post',
            maxChunkSize: 2000000,
            acceptFileTypes: /(\.|\/)(csv)$/i,
            headers: headers,
            dataType: "json",
            body: body
        }).then((response) => handleResponse1(response))
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw Error(error);
        });

    }

    const httpPut = (api, body) => {
      let headers =   new Headers({
        'Authorization': localStorage.getItem('token'),
       //  'Authorization' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxOGE1N2RjZDc4NTQ4MzZiMWMyOGFjMiIsInVzZXJfZGV0YWlscyI6ImFkbWluQHdtaXMub3JnIiwicm9sZSI6MSwiYXJlYSI6W3siX2lkIjoiNjE4YjdjZDZkMGJkOGQwMDEyYzRkZGUzIiwiYXJlYV9jb2RlIjoiTFNPIn1dLCJpYXQiOjE2NzAzMDE2ODIsImV4cCI6MTY3MDMxOTY4Mn0.wp7-tS-Q1LXOcfJEjAJKHK5fFCBaH-Blqd9q_KFrmEc',
         'Content-Type': 'application/json'
     });

      return  fetch(`${commonUrl}${api}`, {
            method: 'put',
            headers: headers,
            body: JSON.stringify(body),
        }).then((response) => handleResponse(response))
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw Error(error);
        });

    }


    const httpGet =  (api) => {
      let headers =   new Headers({
        'Authorization': localStorage.getItem('token'),
       //  'Authorization' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxOGE1N2RjZDc4NTQ4MzZiMWMyOGFjMiIsInVzZXJfZGV0YWlscyI6ImFkbWluQHdtaXMub3JnIiwicm9sZSI6MSwiYXJlYSI6W3siX2lkIjoiNjE4YjdjZDZkMGJkOGQwMDEyYzRkZGUzIiwiYXJlYV9jb2RlIjoiTFNPIn1dLCJpYXQiOjE2NzAzMDE2ODIsImV4cCI6MTY3MDMxOTY4Mn0.wp7-tS-Q1LXOcfJEjAJKHK5fFCBaH-Blqd9q_KFrmEc',
         'Content-Type': 'application/json'
     });
        return  fetch(`${commonUrl}${api}`, {
              method: 'get',
              headers: headers,
             
          }).then((response) => handleResponse(response))
          .then((response) => response)
          .catch((error) => {
            console.error(error);
            throw Error(error);
          });
  
      }

    

    // response handallelr-=========//

  const checkToken =()=>{
    let headers =   new Headers({
      'Authorization': localStorage.getItem('token'),
     //  'Authorization' : 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjYxOGE1N2RjZDc4NTQ4MzZiMWMyOGFjMiIsInVzZXJfZGV0YWlscyI6ImFkbWluQHdtaXMub3JnIiwicm9sZSI6MSwiYXJlYSI6W3siX2lkIjoiNjE4YjdjZDZkMGJkOGQwMDEyYzRkZGUzIiwiYXJlYV9jb2RlIjoiTFNPIn1dLCJpYXQiOjE2NzAzMDE2ODIsImV4cCI6MTY3MDMxOTY4Mn0.wp7-tS-Q1LXOcfJEjAJKHK5fFCBaH-Blqd9q_KFrmEc',
       'Content-Type': 'application/json'
   });
    return  fetch(`${commonUrl}/data-retrieval/data/datamanager/data/summary`, {
      method: 'get',
      headers: headers,
     
  }).then((response) => handleResponse(response))
  .then((response) => response)
  .catch((error) => {
    localStorage.clear('userInfo')
    localStorage.clear('token')
    window.location.reload();
    console.error(error);
    throw Error(error);
  });
  }
    
const handleResponse = (response) => {
    // You can handle 400 errors as well.
    if (response.status === 200) {
      return response.json();
    } else {
      throw Error(response.json() | 'error');
    }
  };

  const handleResponse1 = (response) => {
    // You can handle 400 errors as well.
    if (response.status === 200 || response.status === 400) {
      return response.json();
    } else {
      throw Error(response.json() | 'error');
    }
  };



  //Dashboard APIS
  const httpUserPost = (api, body) => {
    let headers =   new Headers({
   
       'Content-Type': 'application/json'
   });


    return  fetch(`${commonUrl}${api}`, {
          method: 'post',
          headers: headers,
          body: JSON.stringify(body),
      }).then((response) => handleResponse(response))
      .then((response) => response)
      .catch((error) => {
        console.error(error);
        throw Error(error);
      });

  }

  const httpNoAuthGet =  (api) => {
    let headers =   new Headers({
      'Content-Type': 'application/json'
   });
      return  fetch(`${commonUrl}${api}`, {
            method: 'get',
            headers: headers,
           
        }).then((response) => handleResponse(response))
        .then((response) => response)
        .catch((error) => {
          console.error(error);
          throw Error(error);
        });

    }


   
  


export default {httpPost, httpGet, httpPut, httpUserPost, httpPostFile, httpNoAuthGet, checkToken}


