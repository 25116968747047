
import React, { useEffect, useRef, useState } from "react";

// import Select from 'react-select';

import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import InfoIcon from '@mui/icons-material/Info';

import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';

import * as $ from 'jquery';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

import Tooltip from '@mui/material/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';

import CheckboxTree from 'react-checkbox-tree';





const DataEntryModal = (props) => {
    const form = useRef()


    const [unit, setUnit] = useState({
        unit: '',
        ius: '',
        unitName: ''
    })

    const [areaExpand, setAreaExpanded] = useState([]);
    const [, forceUpdate] = useState();
    const [status, setStatus] = useState('');
    const [areaChecked, setAreaChecked] = useState([])
    const [allAreaLevelData, setAllAreaLevelData] = useState([])
    const [loginArea, setLoginArea] = useState([])

    const [subData, setSubGroup] = useState([]);
    const [labelData, setLabelData] = useState([]);
    const [dfInd, setDfInd] = useState({
        dfLabel: '',
        dfUnit: ''
    });
    const [areaTree, setAreaTree] = useState([]);
    const [disabledareaTree, setDisabledAreaTree] = useState([]);
    const [getTreeData, setTreeData] = useState([]);
    const [TimeMsg, setTimeMsg] = useState('Please enter time period!')

    const [indLabel, setIndLabel] = useState({
        indCode: '',
        indText: '',

    })

    const renderTestTree = (nodes) => {
        
        return (
            <>
            {nodes && nodes.map(node=> {
                
                return (
                <li>
                        <span class="caret"></span>
                        <div 
                        className={!props.loginArea.includes(node.value)? 'text-gray':'' } >
                            <input disabled={!props.loginArea.includes(node.value)  } 
                            // checked={FormState.areaCode?.includes(area.value)} 
                            value={FormState.areaCode}
                            checked={ FormState.areaCode==node.value } 
                            onChange={(e)=>{getAreaList(node)}} 
                             type="checkbox" name="arearadio" /> 
                            <span for="arearadio">{node.label}</span></div>
                        <ul class="nested">
                            {
                                renderTestTree(node.children)
                            }
                        </ul>
                    </li>
                )
            })

            }
            </>
        );
    }

    const [FormState, setFormState] = useState(
        {
            indicData: '',
            subGroup: '',
            areaCode: '',
            areaName: '',
            timePeriod: '',
            source: '',
            dataValue: '',
            footnote: null,

        });


    useEffect(() => {
        setTimeout(() => {
            $('.caret').on('click', function() {
                // console.log("---", this.parentElement.querySelector(".nested").classList.toggle("active"))
                this.parentElement.querySelector(".nested").classList.toggle("active");
                this.classList.toggle("caret-down");
            })
        }, 2000);
      
        // console.log('ind', props.indicatorData)

        setLabelData(props.indicatorData)




        // console.log(props.editData[0])



        if (props.editData == null || props.editData == undefined || props.editData.length == 0) {
            setFormState({
                ...FormState,
                indicData: '',
                subGroup: '',
                areaCode: '',
                areaName: '',
                footnote: '',
                dataValue: '',
                source: '',
                timePeriod: '',

            });



            setIndLabel({ ...indLabel, indCode: '', indText: '' })
            setUnit({ ...unit, unit: '', ius: '', unitName: '' })

        }
        else {



            const data = props.editData[0];

            // console.log('ed', data)


            setFormState({
                ...FormState,
                indicData: data.indicator,
                subGroup: data.source.publisher,
                areaCode: data.area_code,
                areaName: data.areaData[0].name,
                footnote: data.footnote,
                dataValue: data.new_value,
                source: data.source.publisher,
                timePeriod: data.time_period.start_time_period,

            });

            setAreaChecked([data.area_code]);

            let i = -1;
            let j = -1;
            labelData?.forEach((item, index) => {
                i = i + 1;
                if (item._id == props.editData[0].iu_id) {
                    // console.log('index', i)
                    setDfInd({ ...dfInd, dfLabel: i, dfUnit: 0 })
                    // console.log(dfInd)


                    item.subgroup?.forEach((item2, index2) => {
                        j = j + 1;
                        setSubGroup([...subData, item2])
                        if (item2._id == props.editData[0].ius_id) {
                            setDfInd({ ...dfInd, dfLabel: i, dfUnit: j })
                        }

                    })

                }

            })


            labelData?.forEach((item, index) => {
                i = i + 1;
                if (item._id == props.editData[0].ius_id) {
                    // console.log('index', index)
                    setDfInd({ ...dfInd, dfUnit: i })


                }

            })



            setIndLabel({ ...indLabel, indCode: data.iu_id, indText: data.indicator })
            setUnit({ ...unit, unit: data.iusData[0].subgroup.name, ius: data.ius_id, unitName: data.iusData[0].unit })





        }


        if (props.areaTree != '' && props.areaTree != null) {
            // console.log("test by react team111111111",areaTree)
            let flatLevel = [];
            
            var locs = localStorage.getItem('userInfo');
            var mainDt = mainDt = JSON.parse(locs);
            // setLevelData(adminLevel)

            setLoginArea(mainDt);
            
            var adminLevel = [];
            props.areaTree?.forEach((item) => {



                var obj = {
                    value: item.area_code,
                    label: item.name,
                    parent: item.parent_id,
                    level: item.level

                }
                
                adminLevel.push(obj)



            });

            console.log("adminlevel",adminLevel)
            let allAreaLevelData1 = setLevelData(adminLevel);

            console.log("leveldata",allAreaLevelData1);
            setAllAreaLevelData(allAreaLevelData1);

          


            props.areaTree?.forEach((item) => {
                mainDt.area?.forEach(dt => {

                    if (item.area_code == dt) {

                        var obj = {
                            value: item.area_code,
                            label: item.name,
                            parent: item.parent_id,
                            level: item.level,
                           
                        }

                        flatLevel.push(obj)

                    }

                })
            });


            var flater2 = [];
            props.areaTree?.forEach((item) => {
                flatLevel?.forEach(dt => {
                    if (item.area_code == dt.parent) {
                        var obj = {
                            value: item.area_code,
                            label: item.name,
                            parent: item.parent_id,
                            level: item.level,



                        }

                        flater2.push(obj)

                    }
                })
            })


            var flater3 = [];
            props.areaTree?.forEach((item) => {
                flater2?.forEach(dt => {
                    console.log('level1', dt)
                    if (item.area_code == dt.parent) {
                        var obj = {
                            value: item.area_code,
                            label: item.name,
                            parent: item.parent_id,
                            level: item.level,


                        }

                        flater3.push(obj)

                    }
                })
            })

            var merger = flatLevel.concat(flater3, flater2)


            let mergerData = [];
            mergerData.push(merger[0]);
            merger?.forEach(element => {
                var count=0;
               
                mergerData.forEach(ele => {
                    if(ele.value==element.value){
                        count=1;
                    }
                });

                if(count!=1){
                        mergerData.push(element);
                }

                

            });


            console.log("loginarea",props.loginArea)

            console.log("merger----",mergerData)
            var finalMerger = [];
            var finaldisabledMerger = [];
            mergerData?.forEach((item) => {

                var count =0;
                var obj = {}
                mainDt.area?.forEach(dt => {
                    
                    if (item.value === dt) {
                        count=1;
                    }
                })

                if(count==1){
                    obj = {
                        value: item.value,
                        label: item.label,
                        parent: item.parent,
                        level: item.level,
                        disabled: false

                    }
                }else{
                    obj = {
                        value: item.value,
                        label: item.label,
                        parent: item.parent,
                        level: item.level,
                        disabled: true

                    }
                    finaldisabledMerger.push(obj)
                }
                finalMerger.push(obj)

            })
            console.log('obj', finalMerger)
            var data = [];
            // var finalData = setLevelData(finaldisabledMerger)
            // if (mainDt.role == 1) {
            //     data = setLevelData(adminLevel)
            // } else {
            //     data = setLevelData(finalMerger)
            // }
            console.log("data----",JSON.parse(JSON.stringify(data)))
            // console.log("finaldisabledMerger",finaldisabledMerger)
            
            // console.log("finalData",finalData)
            setAreaTree(data)

            // setDisabledAreaTree(finalData)



        }
    }, [props]);


    const getAreaList = (e) => {
        var locs = localStorage.getItem('userInfo');
        var mainDt = mainDt = JSON.parse(locs);

        console.log("datae",e)

        var val = e?.value;

        // if(mainDt?.area.length==1 && mainDt?.area[0]=='SWZ'){
        //     setFormState({ ...FormState, areaCode: val, areaName: e?.label });
        //     setAreaChecked([val])
        // }else{
        //     mainDt?.area?.map(item => {
        //         if (val == item) {
        //             setFormState({ ...FormState, areaCode: val, areaName: e?.label });
        //             setAreaChecked([val])
        //         }
    
    
        //     })
        // }

        setFormState({ ...FormState, areaCode: val, areaName: e?.label });
                    setAreaChecked([val])

        

        

        console.log("state--",FormState.areaName)




    }



    const getSubIndigator = (e, v, r) => {

        // console.log(r)

        if (r != 'clear') {


            setFormState({ ...FormState, indicData: v.name })

            setIndLabel({ ...indLabel, indCode: v._id, indText: v.name })

            // console.log('lb', labelData)

            labelData?.map(item => {

                if (item._id == v._id) {
                    setSubGroup([{
                        ...subData,
                        label: item.subgroup[0].name, value: item.subgroup[0]._id, name: item.unit
                    }])

                }



            });


            // console.log('ssb', subData)

        }
        else {
            setFormState({
                ...FormState, indicData: '', subGroup: ''
            });

            setSubGroup([...subData, subData])
        }


    }



    const getUnit = (e, v, r) => {

        if (r != 'clear') {
            setUnit({ ...unit, unit: v.label, ius: v.value, unitName: v.name })
            setFormState({
                ...FormState, subGroup: v.label
            });

        }
        else {

            setUnit({ ...unit, unit: '', ius: '', unitName: '' })
            setFormState({
                ...FormState, subGroup: ''
            });
        }


    }


    const handleChange = (event) => {

        setFormState({ ...FormState, [event.target.name]: event.target.value });
        event.preventDefault();

    }

    const handleSubmit = (e) => {
        e.preventDefault();
        let dataToSend = [];


        if (props.isEdit == true) {
            dataToSend = {


                "id": props.editData[0]._id,
                "area": FormState.areaCode,
                "source": FormState.source,
                "time_period": FormState.timePeriod,
                "indicator": indLabel.indText,
                "iu_id": indLabel.indCode,
                "unit": unit.unitName,
                "ius_id": unit.ius,
                "value": FormState.dataValue,
                "footnote": FormState.footnote

            }

        }
        else {
            console.log(unit)
            dataToSend = {
                "id": null,
                "area": FormState.areaCode,
                "source": FormState.source,
                "time_period": FormState.timePeriod,
                "indicator": indLabel.indText,
                "iu_id": indLabel.indCode,
                "unit": unit.unitName,
                "ius_id": unit.ius,
                "value": FormState.dataValue,
                "footnote": FormState.footnote

            }

        }
        props.newDataEntry(dataToSend);
        setFormState({
            ...FormState,
            areaCode: '',
            areaName: '',
            timePeriod: '',
            source: '',
            dataValue: '',
            footnote: null,
        })

        setIndLabel({
            ...indLabel,
            indCode: '',
            indText: '',
        })
        setUnit({
            ...unit,
            unit: '',
            ius: ''
        })





    }







    // area tree sctructure=========================//

    const getHowManyLevel = (obj) => {
        let res = JSON.stringify(obj).replace(/[^{|^}]/g, '')
        while (/}{/g.test(res)) {
            res = res.replace(/}{/g, '')
        }
        return res.replace(/}/g, '').length
    }



    const setLevelDataw = (flat) => {
        console.log("Mohit flatlever", flat);
    
        var roots = []
    
        var all = {}
    
        flat.forEach(function (item) {
            all[item.label] = item
        })
        Object.keys(all).forEach(function (label) {
            var item = all[label]
            if (item.parent === null || item.parent === '') {
                roots.push(item)
            } else if (item.parent in all) {
                var p = all[item.parent]
                if (!('children' in p)) {
                    p.children = []
                }
                p.children.push(item)
            }
        })
    
        return roots
    }

    const setLevelData = (flat) => {


        var roots = [];

        var all = {};

        flat.forEach(function (item) {

            all[item.value] = item;
        });

        console.log("alldata11",all)



        Object.keys(all).forEach(function (value) {
            var item = all[value];
            console.log("item11",item)
            if (item.parent === null || item.parent === "") {

                roots.push(item);
            } else if (item.parent in all) {
                var p = all[item.parent];
                if (!("children" in p)) {

                    p.children = [];

                }
                p.children.push(item);
            }
        });


        return roots;
    };




    const renderTree = (nodes) => (

        nodes.map((item) => {
            return (<>

                <TreeItem
                    className={item.areaSubData?.length > 0 ? 'has-child' : 'no-child'}
                    key={item.area_code} nodeId={item._id} label={item.name}
                    onClick={() => { setFormState({ ...FormState, areaCode: item.area_code, areaName: item.name }) }} >

                    {Array.isArray(item.areaSubData) && item.areaSubData != null
                        ? renderTree(item.areaSubData)
                        : null}
                </TreeItem>

            </>);


        })


    );





    console.log("FormState,,,", FormState);
    console.log("YOYOYOYO HONEY", FormState.indicData, FormState.timePeriod)

    return (

        <>
            <div className="formBox dataEntryForm">
                <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0 metadata_form">
                            <div className="subgroup_indicator  mb-30">
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label className="label">Indicator <span className="text-muted">| Unit</span></label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style autocomplete">
                                            <div className="mat-form-field-wrapper">

                                                <Autocomplete id="free-solo-demo"
                                                    freeSolo
                                                    value={{ name: FormState.indicData }}
                                                    readOnly={props.isEdit}
                                                    onChange={(event, value, reason) => { getSubIndigator(event, value, reason) }}
                                                    options={labelData?.map((option) => option)}
                                                    // readOnly= {props.isEdit} 
                                                    filterSelectedOptions
                                                    renderInput={(params) => <TextValidator  {...params}
                                                        value={FormState.indicData}
                                                        name="indicData"

                                                        validators={['required']}
                                                        errorMessages={['Please select a indicator!']}
                                                    />}
                                                    defaultValue={labelData[dfInd.dfLabel]}
                                                    getOptionLabel={(option) => option.name}




                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Subgroup</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style autocomplete">
                                            <div className="mat-form-field-wrapper">

                                                <Autocomplete id="free-solo-demo1"

                                                    freeSolo
                                                    value={{ label: FormState.subGroup }}
                                                    readOnly={props.isEdit}
                                                    onChange={(event, value, reason) => { getUnit(event, value, reason); }}
                                                    options={subData}
                                                    renderInput={(params) => <TextValidator  {...params}
                                                        name="subGroup"
                                                        value={FormState.subGroup}
                                                        validators={['required']}
                                                        errorMessages={['Please select a subgroup!']}
                                                    />}
                                                    defaultValue={subData[dfInd.dfUnit]}
                                                    getOptionLabel={(option) => option.label}




                                                />


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Area</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style ifs_dropdown hdt_dropdown deDropdown">


                                        {/* <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                    {FormState.areaName}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="treeviewCheckbox">
                                                    <CheckboxTree
                                                        nodes={areaTree}
                                                        checked={areaChecked}
                                                        expanded={areaExpand}
                                                        // disabled= {areaTree}
                                                        onClick={(e) => getAreaList(e)}
                                                        onExpand={(expanded) => setAreaExpanded(expanded)}
                                                    />
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>
                                        <div className="inPuthidden">       <TextValidator
                                            className="sdjksdjk78 w-100"

                                            type="hidden"
                                            label=""
                                            onChange={handleChange}
                                            name="pareaName"
                                            value={FormState.areaName}
                                            validators={['required']}
                                            errorMessages={['Please select a areaName!']}
                                        /></div> */}

                                        <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                    {FormState.areaName}
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="treeviewCheckbox">
                                                    <ul id="myUL1">
                                                        {renderTestTree(allAreaLevelData)}
                            {/* {
                                allAreaLevelData.map(area=>{
                                    return (
                                        <li>
                                            <span class="caret"></span>
                                            <div 
                                            className={!props.loginArea.includes(area.value)? 'text-gray':'' } >
                                                <input disabled={!props.loginArea.includes(area.value)  } 
                                                // checked={FormState.areaCode?.includes(area.value)} 
                                                checked={ FormState.areaCode==area.value } 
                                                onChange={(e)=>{getAreaList(area)}} 
                                                 type="radio" name="arearadio" /> 
                                                <span for="arearadio">{area.label}</span></div>
                                            <ul class="nested">
                                                {
                                                    area.children.map((child1,section)=>{
                                                        return (
                                                            <li>
                                                                {child1.label!='Select All'?<span class="caret"></span>:null}
                                                                <div 
                                                                className={!props.loginArea.includes(child1.value)? 'text-gray':'' } >
                                                                    <input 
                                                                    disabled={ !props.loginArea.includes(child1.value) } 
                                                                    // checked={ FormState.areaCode?.includes(child1.value)} 
                                                                    checked={ FormState.areaCode==child1.value } 
                                                                    onChange={(e)=>{getAreaList(child1)}} 
                                                                    type="radio" name="arearadio"  />
                                                                    <span for="arearadio" class={ !child1.value.includes('selectall') ? 'area-ser' : '' } >{child1.label}</span>
                                                                </div>
                                                                <ul class="nested">
                                                                    {
                                                                        child1.children!=undefined?child1.children.map(child2=>{
                                                                            {console.log("FormState.areaCode",FormState.areaCode==child2.value, FormState.areaCode)
                                                                                console.log("FormState.areaCode---",child2.value)
                                                                        }
                                                                            return (
                                                                                <li><div 
                                                                                className={!props.loginArea.includes(child2.value)? 'text-gray':'' } >
                                                                                    <input disabled={!props.loginArea.includes(child2.value)} 
                                                                                    value={child2.value}
                                                                                    // checked={ FormState.areaCode==child2.value? 'checked' : '' } 
                                                                                    checked={ FormState.areaCode==child2.value? true : false } 
                                                                                    
                                                                                    onChange={(e)=>{getAreaList(child2)}} 
                                                                                    type="radio" name="arearadio" />
                                                                                <span for="arearadio">{child2.label}</span></div>
                                                                                </li>
                                                                            )
                                                                        }):null
                                                                    }
                                                                    
                                                                </ul>
                                                            </li> 
                                                        )
                                                    })
                                                }
    
                                            </ul>
                                        </li>
                                    )
                                })
                            } */}
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                        



                                        {/* <div className="mat-form-field-wrapper">
                                            <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                    <TextValidator
                                                        className=" w-100"


                                                        label=""
                                                        onChange={handleChange}
                                                        name="pareaName"
                                                        value={FormState.areaName}
                                                        validators={['required']}
                                                        errorMessages={['Please select a areaName!']} 
                                                    />

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <TreeView

                                                        aria-label="file system navigator"
                                                        defaultCollapseIcon={<ExpandMoreIcon />}
                                                        defaultExpandIcon={<ChevronRightIcon />}

                                                        sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                    >
                                                        {renderTree(areaTree)}

                                                    </TreeView>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div> */}
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Time Period</label>
                                    </span>
                                </div>



                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator className="w-100"
                                                onChange={handleChange}
                                                name="timePeriod"
                                                value={FormState.timePeriod}

                                                inputProps={{ pattern: "^[0-9]{4}$|^[0-9]{4}-[0-9]{4}$|^[0-9]{4}.(3[01]|[12][0-9]|0[1-9]).(1[0-2]|0[1-9])$|^[0-9]{4}.(3[01]|[12][0-9]|0[1-9])-[0-9]{4}$" }}

                                                validators={['required']}
                                                errorMessages={['Please enter a valid Time Period format!']}
                                            />
                                            <Tooltip title="Valid formats: YYYY, YYYY-YYYY, YYYY.MM.DD, MM.DD-YYYY" placement="top">
                                                <a style={{ 'float': 'right', 'margin': '-36px -30px 0 0', 'cursor': 'pointer', 'color': '#0C6638' }} href="javascript:;"><InfoIcon /></a>

                                            </Tooltip>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Source</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator className="w-100" label="" onChange={handleChange} name="source" value={FormState.source} validators={['required']} errorMessages={['This field is required']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Data Value</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator className="w-100" label="" type="number" onChange={handleChange} name="dataValue" value={FormState.dataValue} validators={['required']} errorMessages={['This field is required']}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Footnote</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style autocomplete">
                                        <div className="mat-form-field-wrapper">
                                            <TextValidator className="w-100" label="" onChange={handleChange} name="footnote" value={FormState.footnote} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" className="cancelbtn">Cancel</Button>
                                <Button className="addbtn" color="error" variant="contained" type="submit">
                                    {props.editData[0] != null ? 'Update' : 'Add'}
                                </Button>
                            </div>
                        </div>
                    </Stack>
                </ValidatorForm>
            </div>


        </>


    );


}


export default DataEntryModal