import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
// import AddAreaModal from '../../Modals/addArea.modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Sonnet from '../../components/Sonnet';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import { Tooltip } from '@material-ui/core'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ProfileModal from '../../../Common/components/modal/profile.modal';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';

import Collapse from 'react-bootstrap/Collapse';




import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';



const actorTemplate = function () {
//   const  openCloseModal = async () => {

//         this.setState({scrollableModal:false})
  
// }
// const handleSubmit = (e) => {
//   e.preventDefault();
//   let dataToSend = [];

//   dataToSend = {
 
//           "id": this.state.id,
//           "registrationdate":this.state.date_of_registration,
//           "approvaldate":this.state.approvaldate,
//           "role":this.state.role,
//           "location":this.state.location,
            
//          }
 
    
//  console.log("test done by itm",dataToSend)
 
     
    
//     }


// const  closeModalClose = async () => {

  
//         this.setState({disaaprovest:false})
    
  
// }

// const  closeModalClosePublis = async () => {


//       this.setState({publishModal:false})
  

// }

// const  closeDeleteModal = async () => {

  
//     this.setState({deleteApprovedData:false})


// }

  return (
    <>

      
      <div className="main actor_page" id="main">
        <div className='container-fluid areaCont'>
          <section className='areaSection area_margin' id="areaSection">
              
            <subHeaderComponentMemo.default 
             PropDeleteBulk={this.deleteBulkUser}
            onSearch={this.onSearch} searchText={this.state.searchText} 
            checkData = {this.state.bulkDelete}
             
            //  value='6'
             nRows={this.state.filteredData?.length}
            />                                          
                <div className="tab_box ma_tables">
                  <div className="tabsLists">
                
                    <div className="tl_lists">
                      <ToggleButtonGroup
                        color="primary"
                        value={this.state.alignment}
                        exclusive
                        onChange={this.handleChange}
                        aria-label="Platform"
                      >
                        <ToggleButton value="Pending">Pending <span class="aNumber">{this.state.pendingCountData}</span></ToggleButton>
                        <ToggleButton value="Approved">Approved<span class="aNumber">{this.state.approvedCountData}</span></ToggleButton>
                        <ToggleButton value="Rejected">Disapproved<span class="aNumber">{this.state.disapprovedCountData}</span></ToggleButton>
                      </ToggleButtonGroup>
                    </div>


                    {/* <div className="filter_box d-flex align-items-center justify-content-center">
                     

                      <Button className="filter_icon" data-toggle="modal" data-target="#filterPopup"> <Tooltip title="Filter" placement="top" arrow><img src="assets/lib/images/filter.svg"/></Tooltip> </Button>
                    </div> */}
                  </div>              
                  

                  {/* <div class="modal filterPopups fade" id="filterPopup" tabindex="-1" aria-labelledby="filterPopupLabel" aria-hidden="true">
                    <div class="modal-dialog modal-dialog-centered">
                      <div class="modal-content">
                        <div class="modal-header">
                        <h5 class="modal-title" id="filterPopupLabel">Filters</h5>
                          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">
                              <img src="assets/lib/images/cross.svg" alt="" />
                            </span>
                          </button>
                        </div>
                        
                        <div class="modal-body">
                          <div className='filter_form'>
                          
            
                            <div className="row align-items-center mb-30" onSubmit={handleSubmit}>
                                <div className="col-md-5">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Registration Date</label>
                                    </span>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <ul className="rs_formlist">
                                                <li>
                                                    <span className="date_formto">From</span>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Stack spacing={1}>
                                                            <DesktopDatePicker                                                   
                                                                inputFormat="DD/MM/YYYY"
                                                                value={this.value}
                                                                onChange={this.handleDate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                />                                                 
                                                        </Stack>
                                                    </LocalizationProvider>    
                                                </li>
                                                <li>
                                                    <span className="date_formto">To</span>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Stack spacing={1}>
                                                            <DesktopDatePicker                                                   
                                                                inputFormat="DD/MM/YYYY"
                                                                value={this.value}
                                                                onChange={this.handleDate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                />                                                 
                                                        </Stack>
                                                    </LocalizationProvider>    
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                                <div className="col-md-5">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Approval Date</label>
                                    </span>
                                </div>
                                <div className="col-md-7">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                            <ul className="rs_formlist">
                                                <li>
                                                    <span className="date_formto">From</span>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Stack spacing={1}>
                                                            <DesktopDatePicker                                                   
                                                                inputFormat="DD/MM/YYYY"
                                                                value={this.value}
                                                                onChange={this.handleDate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                />                                                 
                                                        </Stack>
                                                    </LocalizationProvider>    
                                                </li>
                                                <li>
                                                    <span className="date_formto">To</span>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <Stack spacing={1}>
                                                            <DesktopDatePicker                                                   
                                                                inputFormat="DD/MM/YYYY"
                                                                value={this.value}
                                                                onChange={this.handleDate}
                                                                renderInput={(params) => <TextField {...params} />}
                                                                />                                                 
                                                        </Stack>
                                                    </LocalizationProvider>    
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row align-items-center mb-30">
                              <div className="col-md-5">
                                  <span className="mat-form-field-label-wrapper">
                                      <label>Role Type</label>
                                  </span>
                              </div>
                              <div className="col-md-7">
                                  <div className="form-group mb-0 inputform_style">
                                      <div className="mat-form-field-wrapper">
                                      <Select name="role" id="user_role" >
                                          {
                                            this.state.actorData?.map(item=>{ 
                                              console.log('role',item)
                                              return (
                                              <MenuItem     
                                              key={item.value}
                                              value={item.value}>
                                                {item.value}
                                                </MenuItem>);
                                            })
                                          }
                                          
                                        
                                        </Select>
                                      </div>
                                  </div>
                              </div>
                            </div> 
                            <div className="row align-items-center mb-30">
                              <div className="col-md-5">
                                  <span className="mat-form-field-label-wrapper">
                                      <label>Location</label>
                                  </span>
                              </div>
                              <div className="col-md-7">
                                  <div className="form-group mb-0 inputform_style">
                                      <div className="mat-form-field-wrapper">
                                    
                                        <Select name="location" id="location" >
                                          {
                                            this.state.actorfilterData?.map(item=>{ 
                                              console.log('location',item)
                                              return (
                                              <MenuItem     
                                              key={item.value}
                                              value={item.value}>
                                                {item.label}
                                                </MenuItem>);
                                            })
                                          }
                                          
                                        
                                        </Select>
                                      </div>
                                  </div>
                              </div>
                            </div>
                            <div className="row align-items-center mb-30">                                                            
                              <div className="col-md-12 d-flex align-items-center justify-content-between">
                                <div className="form-group mb-0 radioform_style pe-7">                                                        
                                  <FormControl>
                                      <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" defaultValue="company" name="row-radio-buttons-group"
                                      >
                                          <FormControlLabel value="company" control={<Radio id="flexRadioDefault1" />} label="Company"  />
                                          <FormControlLabel value="individual" control={<Radio id="flexRadioDefault2" />} label="Individual" />                                                
                                      </RadioGroup>
                                  </FormControl>
                                </div>
                              </div>                                                            
                            </div>                          
                          </div>
                        </div>
                        <div class="modal-footer filter_btn d-flex align-items-center justify-content-center">
                          <Button variant="contained" className="cancelbtn">Reset</Button>
                          <Button variant="contained" className="applybtn" type="submit">Apply</Button>
                        </div>
                       
                      </div>
                     
                    </div>
                  </div> */}

                  <div className="manageActor">
            
                    {/* <DataTableMain /> */}

                    <DataTable
                      columns={this.state.openTab =='Pending' && this.columnsPending 
                      ||  this.state.openTab =='Approved'  && this.columnsApproved 
                      ||   this.state.openTab =='Rejected' && this.columnsRejected ||  this.columnsPending}
                      data={this.state.filteredData}
                      direction="auto"
                      fixedHeaderScrollHeight="500px"
                      pagination
                      paginationRowsPerPageOptions={[10, 25, 50,100]}
                      style={{ display: "flex" }}
                      persistTableHead
                      selectableRows
                      onSelectedRowsChange={this.handleChangeActor}
                      clearSelectedRows={this.handleChangeData}
                      className='dataTableScroll'
                    />

                  </div>
                      
               </div>
              
                  
          
          </section>
        </div>
        
      </div>






      



      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350': 'gallery-sidebar add-area-modal add-form width0'}>

   
            <div class="right-tab-heading are_head">
              <h2 class="form-heading form-heading text_trnsfrm-all-labels">
                <span class="form-heading_he">
                  <span class="ddf">{this.state.isEdit==true ? 'Edit Actor': 'Add area'}</span>
                </span>
              </h2>
            </div> 
   
      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>

   
      </div>



      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>


      <ProfileModal profileData={this.state.profileData}/>

      
    </>
  );
};

export default actorTemplate;