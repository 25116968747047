import { Component } from 'react'
import './actor.css';
import * as jsx from './actor.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';

import dayjs from 'dayjs';
import swal from 'sweetalert';
import actorTemplate from './actor.module';
class Actor extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            // mainData: [],
            isEdit: false,
            editEntry: [],
            modal: false,
            loader: false,
            profileData: [],
            // setValue:dayjs('2014-08-18T21:11:54')
            setOpen: false,
            openTab: 'Pending',
            alignment: 'Pending',
            disaaprovest: false,
            publishModal: false,
            deleteApprovedData: false,
            disscrollableModal: false,
            editModeId: null,
            modal: false,
            remarkdata: '',
            loader: false,
            dataLimit: 10,
            actorfilterData: [],
            actorData: [],
            bulkDelete: [],
            dataStatus: 2,
            pendingCountData: '',
            approvedCountData: '',
            disapprovedCountData: '',
            mainAreaData:[],
            statusData: [
                { "label": "Pending", "value": 2 },
                { "label": "Approved", "value": 1 },
                { "label": "Disapproved", "value": 3 }
            ]

            // open:false,
        }
    }


    handleChange = (event, newAlignment) => {
        // this.setState({ alignment: newAlignment, openTab: newAlignment });
        // this.getActorData(newAlignment);
        this.getActorData(newAlignment);
    };


    // handleDate = (newValue) => {
    //     this.setState({ setValue: newValue });
    // };




    onSearch = (val) => {
        this.setState({ searchText: val });
        let d = this.state.data;
        let fd = null;
        if (val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.date_of_registration && item.date_of_registration.toLowerCase().includes(val.toLowerCase())
                    || (item.usersData?.[0]?.email && item.usersData?.[0]?.email.toLowerCase().includes(val.toLowerCase()))

                ));
        }

        this.setState({ filteredData: fd });
    }



    columnsPending = [
        // {
        //     name: '#',
        //     selector: (row, index) => index+1,
        //     sortable: false,
        //     width: "50px"  
        // },
        {
            name: 'Registration Date',
            selector: row => !row.date_of_registration ? '' : dayjs(row.date_of_registration).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            sortable: true,
        },

        {
            name: 'Email',
            selector: row => row.usersData?.[0]?.email,
            sortable: true,
            // width: "350px"  
        },
        {
            name: 'Profile Status',
            selector: row => row?.profile_percentage !==null ? row?.profile_percentage?.toFixed()+'%' : '--',
            sortable: true,
            // width: "300px"   
        },


        {
            name: 'Action',
            width: "300px",
            cell: row =>
                <>



                    <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#actorprofile" onClick={(e) => this.sendActorData(row)}>View Profile</a>
                            <a className="pop-up-btn dropdown-item" onClick={(e) => this.approveData(row._id, 2)} >
                                <span _ngcontent-nud-c5="" title="Approve">
                                    Approve
                                </span>
                            </a>
                            <a className="pop-up-btn dropdown-item" onClick={(e) => { this.disapproveData(row._id, 3) }} >
                                <span _ngcontent-nud-c5="" title="Disapprove">
                                    Disapprove
                                </span>
                            </a>
                        </div>
                    </div>







                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    columnsApproved = [
        // {
        //     name: '#',
        //     selector: (row, index) => index+1,
        //     sortable: false,
        //     width: "50px"  
        // },
        {
            name: 'Approval Date',
            // selector: row => row.approval_date,
            selector: row => !row.approval_date ? '' : dayjs(row.approval_date).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            sortable: true,
            // width: "300px"  
        },

        {
            name: 'Last Access Date',
            selector: row =>  dayjs(row.updatedAt).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A') ,
            sortable: true,
            // width: "300px"  
        },
        {
            name: 'Email',
            selector: row => row.usersData?.[0]?.email,
            sortable: true,
            // width: "300px"  
        },

        {
            name: 'Action',
            width: "300px",
            cell: row =>
                <>


                    <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            {/* <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.status)}> {row.status==1? 'Hide':'Show'}</a> */}
                            {/* <a class="dropdown-item  text-bold" onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</a> */}
                            <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#actorprofile" onClick={(e) => this.sendActorData(row)}>View Profile</a>
                            {/* <img className='cursor-pointer' dataToggle="modal" dataTarget="#actorprofile" 
                            src="assets/lib/images/home/eyes.svg" /> </a> */}
                            <a class="dropdown-item text-danger text-bold" onClick={(e) => this.deleteEntry(e, row._id)} href="javascript:;">Delete</a>
                        </div>
                    </div>

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    columnsRejected = [
        // {
        //     name: '#',
        //     selector: (row, index) => index+1,
        //     sortable: false,
        //     width: "50px"  
        // },
        {
            name: 'Disapproval Date',
            selector: row => !row.disapproval_date ? '' : dayjs(row.disapproval_date).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            sortable: true,
            // width: "300px"  
        },

        {
            name: 'Email',
            selector: row => row.usersData?.[0]?.email,
            sortable: true,
            // width: "300px"  
        },
        {
            name: 'Profile Status',
            selector: row => 'Disapproved',
            sortable: true,
            // width: "300px"  
        },

        {
            name: 'Action',
            width: "300px",
            cell: row =>
                <>


                    <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                        <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#actorprofile" onClick={(e) => this.sendActorData(row)}>View Profile</a>
                          {/* <a class="dropdown-item text-danger text-bold" onClick={(e) => this.undo(e, row.id)} href="javascript:;">UNDO</a> */}
                          <a className="pop-up-btn dropdown-item" onClick={(e) => this.undo(row._id, 3)} >
                                <span _ngcontent-nud-c5="" title="Approve">
                                Undo
                                </span>
                            </a>
                        {/* <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#actorprofile" onClick={(e) => this.sendActorData(row)}>View Profile</a> */}
                            {/* <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.status)}> {row.status==1? 'Hide':'Show'}</a>  */}
                            {/* <a class="dropdown-item  text-bold" onClick={(e) => this.editMode(e, row._id)}  href="javascript:;">Edit</a> */}

                            <a class="dropdown-item text-danger text-bold" onClick={(e) => this.deleteEntry(e, row._id)} href="javascript:;">Delete</a>
                        </div>
                    </div>

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    cancelModal = async (e) => {

        if (this.state.disaaprovest == false) {
            this.setState({ disaaprovest: true })
            this.setState({ remarkdata: e })
        }
        else {

            this.setState({ disaaprovest: false })
        }

    }

    openViewModal = async (e) => {

        swal({
            title: "REASON OF DISAPPROVAL",
            text: e

        })

    }

    // sendStatus = (e) => {
    //     this.state.dataStatus = e;
    //     // this.getActorData(e);
    // }

    // getActorDataByStatus = (e) => {
    //     console.log("data-----" + e.target.value)
    //     const val = e.target.value;
    //     this.setState({ dataStatus: val })
    //     console.log("------aa--" + val)
    //     console.log("data-----" + e.target.value)
    //     console.log("state--" + this.state.dataStatus)

    //     this.getActorData(e.target.value);
    // }


    publishAll = (e) => {

        console.log("length++--" + this.state.filteredData)
        if (this.state.data == "") {
            toast.error("No record found to approve");
        } else {


            if (this.state.publishModal == false) {
                
                this.setState({ publishModal: true , remarkdata: e })
            }
            else {

                this.setState({ publishModal: false })
            }
        }

    }








    sendActorData(row) {
        this.setState({ profileData: row });

        console.log('row', row)
    }




    // /==========change status api=============

    changeStatus(e, id, status, value) {
        if (value == 0) {
            value = 1;
        } else {
            value = 0;
        }


        const dataToBeSend = {

            show: value

        }


        AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {

            if (res) {
                this.setState({ loader: false })
                let d = res;

                // console.log(res)
                // this.getActorData(this.state.dataStatus);

                if (res.message == 'Status updated successfully !') {
                    toast.success("Status updated successfully !");
                    // this.openAddModal();
                    // this.getActorData();
                }
                else {
                    toast.error("Failed to update status ");

                }





            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }



    ////delete api code///////
    deleteEntry = (e, id) => {

        swal({
            title: "Are you sure you want to delete?",
            text: "Once deleted, you will not be able to recover this actor!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        "id": id
                    }

                    AdminService.httpPost('/data-import/actor/delete-actor', dataToBeSend).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            console.log(res)

                            if (res.success == true) {
                                toast.success("Actor deleted successfully!");
                                this.getActorData();
                            }
                            else {
                                toast.error("Failed to delete actor");

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });






    }





    editMode(event, id) {
        const edData = [];
        this.state.filteredData.forEach((item) => {

            if (item._id == id) {
                edData.push(item)
                return;
            }

        });

        this.setState({ ...this.state.editEntry, editEntry: edData })
        console.log('edt', this.state.editEntry)
        this.setState({ isEdit: true })
        this.openAddModal();

    }


    openEditModal() {
        if ($('#EditAreaModal').hasClass('width350')) {
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else {
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        // this.getActorData();
        this.setState({data : [], loader: true, searchText: "",pendingCountData:0,approvedCountData:0,disapprovedCountData:0})
        this.getRoleData();
        this.getAllAreaData();
        this.getActorData();
        AdminService.checkToken();
    }

    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }




    getAllAreaData = async () => {
   

        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

            if (res) {

                let d = res.data;

     
                this.setState({ mainAreaData: d })
                this.getActorData();
                console.log('rdfdffdf', d)
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }



    ///////////get api------------------/////////////

    // getData = async (alignment = null) => {

    //     console.log('fddd')
    //     this.setState({ loader: true, searchText: "" });
    //     let actorState = constants.actorByState[this.state.alignment];
    //     console.log("actorState=========>" + actorState)
    //     if (alignment) {
    //         actorState = constants.actorByState[alignment];
    //         //  console.log("actorState1111111111--"+actorState)
    //     }
    //     AdminService.httpGet('/data-import/actor/getByStatus/' + actorState).then((res) => {
    //         // console.log("What is this response??--->", d)
    //         let d = res?.data;
    //         this.setState({pendingCountData: d[0]?.status == 2 ? d?.length : 0, approvedCountData: d[0]?.status == 1 ? d?.length : 0, disapprovedCountData: d[0]?.status == 3 ?  d?.length : 0})
    
    //         if (res) {

    //             this.setState({ loader: false })
    //             let d = res.data;


    //             const filtData = [];

    //             d.forEach(item => {

    //                 this.state.mainAreaData?.forEach(dt => {

    //                     if (item.location == dt.area_code) {

    //                         item.areaName = dt.name;
    //                     }

    //                 })

    //                 filtData.push(item);

    //             });
            

    //             let fd  = filtData?.filter(item=>{
    //                 return parseInt(item.profile_percentage) ==100;
    //             })

               

    //             this.setState({ filteredData: fd, data: d })


    //             // this.forceUpdate();
    //             this.setState({ loader: false })
                
    //         }

    //     }).catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     });

    // }
////data 


getActorData = async (alignment = null) => {
    

    let actorState = constants.actorByState[this.state.alignment];
    if (alignment) {
        actorState = constants.actorByState[alignment];
        
    }

    
    AdminService.httpGet('/data-import/actor/getAllActorData').then((res) => {
        if (res) {
            
            // this.setState({ loader: false })
            let d = res;
            
          
                       
        
                        // this.setState({ filteredData: fdata, data: d })
        
        
                        // this.forceUpdate();
                        // this.setState({ loader: false })
          console.log("What is this response??--->", d)
           

            let fd = [];
        

            if(actorState == constants.actorByState['Approved']){
                fd = d?.approved;
            }
            else if(actorState==constants.actorByState['Pending']){

                fd = d?.pending;
                

            }else{
                fd = d?.disapproved;
            }

            const filtData = [];

            fd.forEach(item => {

                this.state.mainAreaData?.forEach(dt => {

                    if (item.location == dt.area_code) {

                        item.areaName = dt.name;
                    }

                })

                filtData.push(item);

            });
        

            // let fdata  = filtData?.filter(item=>{
            //     return parseInt(item.profile_percentage) ==100;
            // })


            this.setState({loader: false, 
                          filteredData: filtData,
                            // filteredData: fd, 
                            data: fd, 
                            pendingCountData: d?.pending?.length,
                            approvedCountData:d?.approved?.length,
                            disapprovedCountData:d?.disapproved?.length,
                            alignment: alignment? alignment: this.state.alignment, 
                            openTab: alignment? alignment: this.state.alignment
                        })
            // this.getActorData();
            // this.setState({  })
        console.log("pppppp",actorState)
        }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });

}


    // open add modal=====================//


    openAddModal = () => {


        if (this.state.modal == false) {

            this.setState({ modal: true })

        } else {
            this.setState({ ...this.state.editEntry, editEntry: [] })
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            this.setState({ editModeId: null })
        }

    }
    ////=========appprove data api////==========
    approveData = (id1, status) => {

        this.setState({ loader: false })
        swal({
            title: "Are you sure you want to approve this actor?",
            // text: "Once Approved, you will not be able to disapprove this actor!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        id: id1,
                        status: 1

                    }



                    AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {
                        if (res) {
                            this.setState({ loader: false })
                            let d = res.data;
                            this.setState({ filteredData: d, data: d })
                            this.getActorData();
                            if (res.success == true) {
                                toast.success("Actor approved successfully!");
                                // this.openAddModal();
                                // this.getActorData();
                            }
                            else {
                                toast.error("Failed to approve actor");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });





    }



    ////=========disappprove data api////==========
    disapproveData = (id, status) => {
        this.setState({ loader: false })

        swal({
            title: "Are you sure you want to disapprove this actor?",
            text: "Once Disapproved, you will not be able to approve this actor!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {

                    const dataToBeSend = {
                        id: id,

                        status: 3

                    }


                    AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {
                        if (res) {
                            this.setState({ loader: false })
                            let d = res.data;
                            this.setState({ filteredData: d, data: d })
                            this.getActorData();
                            if (res.success == true) {
                                toast.success("Actor disapproved successfully!");
                                // this.openAddModal();
                                this.getActorData();
                            }
                            else {
                                toast.error("Failed to disapprove actor");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });


    }
    /////////////////==========role and actor  data get==========//////////////
    getRoleData = async () => {
        this.setState({ loader: true })


        AdminService.httpGet('/data-retrieval/data/masterData').then((res) => {
            if (res) {

                let d = res[0]?.data.Actors;

                console.log("somratest========>", d);
                this.setState({ actorData: d, data: d })

            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }


    // /multiple delete

    handleChangeActor = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };







    //////////=========checkbox delete============/////////

    deleteBulkUser = (e) => {
        let ids = [];

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)
           
        })

        if (ids?.length > 0) {

            swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this actor!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "_id": ids.toString()
                        }
                        AdminService.httpPost('/data-import/actor/delete-multiple-actor', data).then((res) => {

                            if (res) {
                                this.setState({ loader: false })
                                let d = res;

                                console.log("eeeee", res)

                                if (res.success == 1) {
                                    toast.success("Actor deleted successfully!");
                                    this.getActorData();
                                }
                                else {
                                    toast.error("Failed to delete actor");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }


        else {
            swal({
                title: "No Record Selected!",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }

    }



    ////undo function


        undo = (id1, status) => {

            this.setState({ loader: false })
            swal({
                title: "Are you sure you want to undo ?",
                // text: "Once Approved, you will not be able to disapprove this actor!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const dataToBeSend = {
                            id: id1,
                            status: 2
    
                        }
    
    
    
                        AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {
                            if (res) {
                                this.setState({ loader: false })
                                let d = res.data;
                                this.setState({ filteredData: d, data: d })
                                this.getActorData();
                                if (res.success == true) {
                                    toast.success(" Undo operation successfull!");
                                    // this.openAddModal();
                                    // this.getActorData();
                                }
                                else {
                                    toast.error("Failed to undo actor");
    
                                }
                            }
    
                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
    
    
    
    
    
        }
        
           
    
    
    
        

    }




const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
)(Actor);
