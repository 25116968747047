import React, { useState, useEffect } from 'react';

import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import { Link } from 'react-router-dom';
import adminService from '../../../services/admin.service';
import * as constants from '../../../Config/app.constants';
import { type } from 'jquery';
function FaciliyModal(props) {


  const getdate = (date) => {
    var d = new Date(date);

    var date = [
      d.getFullYear(),
      ('0' + (d.getMonth() + 1)).slice(-2),
      ('0' + d.getDate()).slice(-2)
    ].join('-');

    return date
  }

  const [show, setShow] = useState(false);
  const [state, setState] = useState({
    areaData: []
  });

  const [reqFields, setReqFields] = useState(new Set());

  useEffect(() => {
    try {
      adminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

        if (res) {

          let d = res.data;
          setState({ ...state, areaData: d })
        }

      })
    } catch (error) {
      console.log(error)
    }


    console.log(props.modalTrigger)
    setShow(props.modalTrigger)

    console.log('333333333333===>', props.modalData)

  }, [props])



  useEffect(() => {
    let facPermissions = constants.facilityPermissions;
    let facType = props.modalData?.facility_type;
    let requiredFields = new Set();
    facPermissions.forEach((typePermissions) => {
      if (facType === typePermissions.type) {
        for (const [key, value] of Object.entries(typePermissions.data)) {
          if (value.required) {
            requiredFields.add(key);
          }
        }
      }
    })
    setReqFields(requiredFields);

  }, [props.modalData]);

  const handleClose = () => {
    setShow(false)

  }

  return (
    <>
      {/* <Modal centered show={show} onHide={handleClose} className="maps_popup">
            <Modal.Header closeButton>
              <Modal.Title>Facility Name | <div className='mp_title'>{props.modalData?.facility_name} <span>({props.modalData?.permissionStatus==1?'Permitted':'Non-Permitted'})</span></div></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='facility_details'>
                <div className='fd_img'>
                  <img src='assets/lib/images/home/facility_details_img.jpg' />
                </div>
                <div className='fd_text'>
                  <ul className='fd_text_box'>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/license_number.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>License Number</h5>
                        <h4>{props.modalData?.license_number}</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/date.svg' />
                      </div>
                      <div className='fdt_text fdt_date'>
                        <div>
                          <h5>Date Awarded From</h5>
                          <h4>{props.modalData?.date_awarded_from}</h4>
                        </div>
                        <div>
                          <h5>Date Awarded To</h5>
                          <h4>{props.modalData?.date_awarded_to}</h4>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/location.svg' />
                      </div>
                      <div className='fdt_text fdt_date'>
                        <div>
                          <h5>Longitude</h5>
                          <h4>{props.modalData?.longitude}</h4>
                        </div>
                        <div>
                          <h5>Latitude</h5>
                          <h4>{props.modalData?.latitude}</h4>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/file.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Description</h5>
                        <h4>{props.modalData?.description}</h4>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/area.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Area (sq.meter)</h5>
                        <h4>{props.modalData?.area_sq_mtr}</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/facility-type.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Facility Type</h5>
                        <h4>{props.modalData?.facility_type}</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/delete.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Waste Type</h5>
                        <h4>{props.modalData?.waste_types} </h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/document.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Document</h5>
                        <h4>{props.modalData?.document? props.modalData?.document:'Not Available'}</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion 1</Accordion.Header>
                  <Accordion.Body>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Modal.Body>            
          </Modal> */}


      <div className="modal maps_popup fade" id="facilityModal" tabindex="-1" aria-labelledby="facilityModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <div class="modal-title h4">{props.modalData?.facility_name} | <div className='mp_title'> {props.modalData?.location != null && props.modalData?.location != '' ? state.areaData?.map(item => { if (props.modalData?.location == item.area_code) return (<>{item.name}</>) }) : ''}  <span>({props.modalData?.permissionStatus == 1 ? 'Licensed' : 'Non-Licensed'})</span></div></div>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div className='facility_details'>
                <div className='fd_img'>


                  {
                    props.modalData?.document != null && props.modalData?.document != '' ?
                      <img src={`${constants.facilityDocPath}${props.modalData?.document}`} />
                      :

                      <img src='assets/lib/images/home/facility_details_img.jpg' />
                  }



                </div>


                <div className='fd_text'>
                  <ul className='fd_text_box'>
                    <li className={!reqFields.has('Name') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/username.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Name</h5>
                        <h4>{props.modalData?.facility_name != null ? props.modalData?.facility_name : ['NA']}</h4>
                      </div>
                    </li>
                    <li className={!reqFields.has('Location') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/mappointer.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Location</h5>
                        <h4>{props.modalData?.location != null ? props.modalData?.location : 'NA'}</h4>
                      </div>
                    </li>
                    <li className={!reqFields.has('Capacity') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/capacity.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Capacity (in Tonnes)</h5>
                        <h4>{props.modalData?.capacity != null ? props.modalData?.capacity : 'NA'}</h4>
                      </div>
                    </li>
                    <li className={!reqFields.has('Waste Type') ? 'not-required' : ''}>
                      <div className='fdt_img'>

                        <img src='assets/lib/images/home/delete.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Waste Type</h5>
                        <h4>{props.modalData?.waste_types?.length > 0 ? props.modalData?.waste_types?.map((item, index) => { return (<><span className="badge">{item}</span></>) }) : 'NA'}</h4>
                        {/* <h4>{ props.modalData?.waste_types != [] ? props.modalData?.waste_types : 'NA'}</h4> */}
                      </div>
                    </li>
                    <li className={!reqFields.has('Coordinates') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/location.svg' />
                      </div>
                      <div className='fdt_text fdt_date'>
                        <div>
                          <h5>Longitude</h5>
                          <h4>{props.modalData?.longitude != null ? props.modalData?.longitude : 'NA'}</h4>

                        </div>
                        <div>
                          <h5>Latitude</h5>
                          <h4>{props.modalData?.latitude != null ? props.modalData?.latitude : 'NA'}</h4>

                        </div>
                      </div>
                    </li>

                    {console.log("statusss---->", props.modalData)}

                    {props?.modalData?.permissionStatus == 1 &&
                      <li className={!reqFields.has('License Number') ? 'not-required' : ''}>
                        <>
                          <div className='fdt_img'>

                            <img src='assets/lib/images/home/date.svg' />
                          </div>
                          <div className='fdt_text fdt_date'>

                            <div>
                              <h5>License Awarded From</h5>
                              <h4>{getdate(props.modalData?.date_awarded_from != null ? props.modalData?.date_awarded_from : 'NA')}</h4>
                            </div>

                            <div>
                              <h5>License Awarded To</h5>
                              <h4>{getdate(props.modalData?.date_awarded_to != null ? props.modalData?.date_awarded_to : 'NA')}</h4>
                            </div>
                          </div>
                        </>
                      </li>
                    }



                    <li className={!reqFields.has('Facility Type') ? 'not-required' : ''}>


                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/facility-type.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Facility Type</h5>
                        <h4>{props.modalData?.facility_type != null ? <span className='badge'>{props.modalData?.facility_type}</span> : 'NA'}</h4>

                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li className={!reqFields.has('Permission Status') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/permission-status.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Permission Status</h5>
                        {/* <h4>{props.modalData?.permissionStatus!=null? props.modalData?.permissionStatus : 'NA'}</h4> */}
                        <h4>{(props.modalData?.permissionStatus === 1 && <span className='badge badge-success'>Licensed</span>) || (props.modalData?.permissionStatus === '0' || <span className='badge badge-danger'>Non-Licensed</span>) || (props.modalData?.permissionStatus == null && 'NA')}</h4>
                        {console.log("Check for the data here---->", props.modalData)}
                      </div>
                    </li>
                    <li className={!reqFields.has('License Number') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/license_number.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>License Number</h5>
                        <h4>{props.modalData?.license_number != null ? props.modalData?.license_number : 'NA'}</h4>

                      </div>
                    </li>
                    <li className={!reqFields.has('Operating hours') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/operating-hours.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Operating hours</h5>
                        <h4>{props.modalData?.operation_hours != null ? props.modalData?.operation_hours : 'NA'}</h4>
                      </div>
                    </li>
                    <li className={!reqFields.has('Number of working days') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/number-working-days.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Number of working days</h5>
                        <h4>{props.modalData?.number_of_working_days != null ? props.modalData?.number_of_working_days : 'NA'}</h4>
                      </div>
                    </li>
                    <li className={!reqFields.has('Available services') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/available-services.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Available services</h5>
                        <h4>{props.modalData?.available_services != null ? props.modalData?.available_services : 'NA'}</h4>
                      </div>
                    </li>
                    <li className={!reqFields.has('Operating temperature') ? 'not-required' : ''}>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/operating-temperature.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Operating temperature</h5>
                        <h4>{props.modalData?.operating_temperature != null ? props.modalData?.operating_temperature : 'NA'}</h4>
                      </div>
                    </li>

                    {/* <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/file.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Description</h5>
                        <h4>{props.modalData?.description}</h4>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/area.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Area (sq.meter)</h5>
                        <h4>{props.modalData?.area_sq_mtr}</h4>
                      </div>
                    </li>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/document.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Document</h5>
                        <h4>{props.modalData?.document? props.modalData?.document:'Not Available'}</h4>
                      </div>
                    </li> */}
                  </ul>
                </div>
              </div>
              {/* <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion 1</Accordion.Header>
                  <Accordion.Body>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
              </Accordion> */}
            </div>
          </div>
        </div>
      </div>

    </>



  );
}


export default FaciliyModal;