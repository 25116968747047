import { Component } from 'react'
import './tracker.css';
import * as jsx from './tracker.module.jsx';

import "react-toastify/dist/ReactToastify.css";

import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import eventBus from '../../../features/eventBus';

import * as $ from 'jquery';
import * as htmlToImage from 'html-to-image';
import domtoimage from 'dom-to-image';
import adminService from '../../../services/admin.service';


class TrackerPage extends Component {
    // const [loader, setLoader] = useState(false)
    constructor() {
        super()
        this.currentAreaCode = 'SWZ';
        this.currentAreaName = 'Eswatini';
        this.state = {
            value: 0,
            plus: '+',
            loader: false,
            setValue: null,
            fecData: [],
            resData: [],
            flatArea : [],
            actorData: [],
            activityData: [],
            profileData: [],
            data: [],
            actorCount: [],
            mainAreaData: [],
            sendAreaData: [],
            selectedArea: 'SWZ',
            masterData: [],
            viewProfile: false,



        }



    }

    componentWillUnmount() {
        eventBus.remove("changeArea");
    }

    componentDidMount() {
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const data = {
            "user_id" : userInfo?.id
        }
        adminService.httpPost('/data-import/actor/getById', data).then((res)=>{
            if(res){
                if((userInfo?.role == 1 || userInfo?.role == 2 || userInfo?.role == 4 ) || (userInfo?.id && res?.data?.[0]?.status === 1) ){
                    this.setState({viewProfile: true});
                } else {
                    this.setState({viewProfile: false});
                }
            }
        })



        eventBus.on("changeArea", (data) => {
            // console.log('init', data)
            this.setState({ loader: true });
            this.currentAreaCode = data.message;
            this.currentAreaName = data.name;
            this.setState({ selectedArea: data.message })
            this.setState({ loader: false });
            this.getFacilities();
            this.getAllAreaData();

            // this.getFacilitiesByArea();
        });

        // console.log("abc")
        // console.log("abc", this.currentAreaCode)
        // console.log("---------------------------------")
        this.getAllAreaData();
        this.getFacilities();
        this.getAllAreaData();
        this.getMasterData();
        // adminService.checkToken();

    }



    columns = [ 

        {
            name: 'Actor Name',
            // selector: row => row.name ? row.name : '--',
            selector: row => row.name ? row.name : '--',
            sortable: true,
            minWidth: "150px",
            maxWidth: "450px",

        },
        {
            name: 'Actor Type',
            // selector: row => row.profile_type ? row.profile_type?.map((item, index) => { return (<>{row.profile_type?.length == index + 1 ? item : item + ', '}</>); }) : '--',
            // selector: row => row.profile_type ? row.profile_type?.map((item, index) => { return (<span class="badge">{row.profile_type?.length == index + 1 ? item : item + ', '}</span>); }) : '--',
            selector: row => row.profile_type?.length > 2 ? row.profile_type.slice(0, 3).map((d, i) => { return (<>{i <= 1 && <span className="badge">  {d}   </span>} {i > 1 && <><span className="badgeNumber">  {this.state.plus}{row.profile_type.length - 2} </span></>}  </>) }) :
                row.profile_type.slice(0, 2).map((d, i) => { return (<><span className="badge">{d} </span></>) }),









            // <><span className="badge">{row.profile_type[0] +  row.profile_type[1] +
            //     ((row.profile_type.length)-2)} </span></>    : '',





            // row.profile_type.slice(0, 2).map((d, i) => {  return (<><span className="badge">{d} </span></>) +{d} }  )  : row.profile_type.slice(0, 2).map((d, i) => {  return (<><span className="badge">{d} </span></>) }),
            // <><span>{row.profile_type?.length - 1}+</span></> : 




            // row.profile_type.slice(0, 2).map((d, i) => {  return (<><span className="badge">{d} </span></>) }) : '',

            // row.profile_type?.length > 3 ? <><span>{row.profile_type?.length - 1}+</span></> : '' 


            sortable: true,
            minWidth: "150px",
            maxWidth: '150px',
        },
        {
            name: 'Waste Type',
            // selector: row => row.waste_type ? row.waste_type : '--',
            // selector: row => row.waste_type ? row?.waste_type?.map((item, index) => { return (<span class="badge">{item}</span>) }) : '--',
            selector: row => row.waste_type?.length > 2 ? row.waste_type.slice(0, 3).map((d, i) => { return (<>{i <= 1 && <span className="badge">  {d}   </span>} {i > 1 && <><span className="badgeNumber">  {this.state.plus}{row.waste_type.length - 2} </span></>}  </>) }) :
                row.waste_type.slice(0, 2).map((d, i) => { return (<><span className="badge">{d} </span></>) }),
            minWidth: "150px",
            // maxWidth: '180px',
            sortable: true,
        },
        {
            name: 'Location',
            selector: row => row.areaName != null ? row.areaName : '--',
            sortable: true,
            maxWidth: '180px',
        },

        { 
            
            name: 'View Profile',
            selector: row => this.state.viewProfile ? <div className="table_view" ><img onClick={   (e) => {this.getActivityData(row._id);  this.sendActorData(row);}} class="cursor-pointer" data-toggle="modal" data-target="#actorprofile" src="assets/lib/images/home/eyes.svg" /></div> : 'Not Permitted',
            width: "150px",
            style: {
                justifyContent: "center",
            },

            //     var interval = setInterval(()=>{
            //         AdminService.httpGet('/data-import/facility/getAll', dataToBeSend).then((res)=>{
            //             if(res){
            //                 this.setState({loader:false})
            //                 let d = res.data;


            //                 this.setState({fecData: d})
            //                 console.log(this.state.fecData)
            //             }
            // })
            //     }, 3000)

            // console.log("3 SECOND KE BAAD CHALEGA----",interval)
            // setTimeout(()=>{
            //     clearInterval(1694)
            // }, 8000)
            headerClassName: 'your-class-name'
        },
    ]


    render() {
        return jsx.default.bind(this)();
    }

    // handleClose = () =>{
    //     this.setState({setShow:false});
    //    } 


    //    handleShow = () =>{
    //     this.setState({setShow:true});
    //    } 

    sendActorData(row) {
        this.setState({ profileData: row });
        console.log('nbrowj', row)
    }






    // getFacilitiesByArea() {
    //     let dataToSend = {
    //         "area" : this.currentAreaCode
    //     };
    //     AdminService.httpPost('/data-import/facility/filterByArea', dataToSend).then((res) => {
    //         if (res) {
    //             // res = res.data.filter(e => e.location == this.currentAreaCode)
    //             // let d = res.data.filter(e => e.location == this.currentAreaCode)
    //             // console.log('d', d)
    //             console.log('aa', res.data)

    //         }

    //     }).catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     })
    // }

    //  downloadMap = () => {


    //      $('.numberActor-dlt').hide();
    //      var node = document.getElementById('actorChart');
    //      this.setState({loader:true})

    //         htmlToImage.toPng(node)
    //           .then(function (dataUrl) {
    //             var a = document.createElement('a');
    //             a.href = dataUrl;
    //             a.download = "Eswatini-actor-chart.jpg";
    //             document.body.appendChild(a);
    //             a.click();
    //             document.body.removeChild(a); 

    //             $('.numberActor-dlt').show()
    //             this.setState({loader: false})

    //         })
    //         .catch(function (error) {

    //             this.setState({loader:false})
    //             $('.numberActor-dlt').show();
    //             console.error('oops, something went wrong!', error);
    //         });

    //       }


    async getActivityData(e) {
        // this.setState({ loader: true })
            const id=e;
            console.log("activity",id)
        
          await  AdminService.httpGet('/data-import/actor-activity/getByActorApproved/'+id).then((res) => {
                if (res) {

                    let d = res.data;


                    
                    this.setState({ activityData: d[0].activityData })
                    // this.setState({ loader: false })
                    console.log('newActorData=================================>', this.state.activityData)
                }

            }).catch((err) => {

            console.log(err)

        });
    }


    downloadMap = () => {


        this.setState({ loader: true })
        $('.numberActor-dlt').hide();
        $('.countryName').css('display', 'unset');
        var node1 = document.getElementById('actorChart');
        var node2 = document.getElementById('copy')

        node2.innerHTML = node1.innerHTML;
        //  $("#copy").find(".hArea")[0].style.display='block';

        domtoimage.toBlob(node1).then((blob) => {
            this.saveFile(blob)
            $('.numberActor-dlt').show();
            $('.countryName').css('display', 'none');
            this.setState({ loader: false });
        })

    }

    saveFile = async (blob) => {
        const a = document.createElement('a');
        const dt = Date.now();
        a.download = 'Eswatini-actor-chart.jpg';
        a.href = URL.createObjectURL(blob);
        a.click();
        setTimeout(() => URL.revokeObjectURL(a.href), 30 * 1000);
    };


    getFacilities() {
        console.log("xyz", this.state)
        const dataToBeSend = {}
        let dataToSend = {
            "area": this.currentAreaCode //, 'SWZ003', 'SWZ002', 'SWZ004', 'SWZ001', 'SWZ004055', 'SWZ004054', 'SWZ004053', 'SWZ004052', 'SWZ004051', 'SWZ004050', 'SWZ004049', 'SWZ004048', 'SWZ004047', 'SWZ004046', 'SWZ004045', 'SWZ003044', 'SWZ003043', 'SWZ003042', 'SWZ003041', 'SWZ003040', 'SWZ003039', 'SWZ003038', 'SWZ003037', 'SWZ003036', 'SWZ003035', 'SWZ003034', 'SWZ003033', 'SWZ003032', 'SWZ003031', 'SWZ002030', 'SWZ002029', 'SWZ002028', 'SWZ002027', 'SWZ002026', 'SWZ002025', 'SWZ002024', 'SWZ002023', 'SWZ002022', 'SWZ002021', 'SWZ002020', 'SWZ002019', 'SWZ002018', 'SWZ002017', 'SWZ002016', 'SWZ002015', 'SWZ001014', 'SWZ001013', 'SWZ001012', 'SWZ001011', 'SWZ001010', 'SWZ001009', 'SWZ001008', 'SWZ001007', 'SWZ001006', 'SWZ001005', 'SWZ001004', 'SWZ001003', 'SWZ001002', 'SWZ001001']
        };
        // console.log('send', dataToSend)
        // NEW CODE
        AdminService.httpGet('/data-import/facility/getAll', dataToBeSend).then((res) => {
            // AdminService.httpPost('/data-import/facility/filterByArea', dataToSend).then((res) => {
            if (res) {
                // res = res.data.filter(e => e.location == this.currentAreaCode)
                let d = res.data
                console.log('d', d)
                this.setState({ fecData: d })
                console.log('aa', this.state.fecData)

            }

        }).catch((err) => {
            this.setState({ loader: false })

        });


        // AdminService.httpGet('/data-import/facility/getAll', dataToBeSend).then((res)=>{
        AdminService.httpPost('/data-import/facility/filterByArea', dataToSend).then((res) => {
            if (res) {
                // res = res.data.filter(e => e.location == this.currentAreaCode)
                let d = res.data
                console.log('d', d)
                this.setState({ fecData: d })
                console.log('aa', this.state.fecData)

            }

        }).catch((err) => {
            this.setState({ loader: false })

        });

        // OLD CODE

        // AdminService.httpGet('/data-import/facility/getAll', dataToBeSend).then((res) => {
        //     if (res) {
        //         // res = res.data.filter(e => e.location == this.currentAreaCode)
        //         let d = res.data.filter(e => e.location == this.currentAreaCode)
        //         console.log('d', d)
        //         this.setState({ fecData: d })
        //         console.log('aa', this.state.fecData)

        //     }

        // }).catch((err) => {
        //     this.setState({ loader: false })
        //     console.log(err)


        //     var i = setInterval(
        //         AdminService.httpGet('/data-import/facility/getAll', dataToBeSend).then((res) => {
        //             if (res) {

        //                 let d = res.data;


        //                 this.setState({ fecData: d })
        //                 console.log(this.state.fecData)
        //             }
        //         })
        //         , 1000);
        //     setTimeout(function () { clearInterval(i); }, 1500);

        // });
    }

    getActorData() {
        this.setState({ loader: true })
        const dataToBeSend = {
            'area': this.currentAreaCode
        }
        // AdminService.httpGet('/data-import/actor/getByStatus/1').then((res) => {
        AdminService.httpPost('/data-import/actor/filterByArea', dataToBeSend).then((res) => {
            if (res) {
                console.log('res', res)

                let d = res.data;

                const filtData = [];

                d.forEach(item => {

                    this.state.mainAreaData?.forEach(dt => {

                        if (item.location == dt.area_code) {

                            item.areaName = dt.name;


                        }



                    })

                    filtData.push(item);



                });




                this.setState({ actorData: filtData })

                this.setState({ loader: false })




            }

        }).catch((err) => {

            console.log(err)



            AdminService.httpGet('/data-import/actor/getByStatus/1').then((res) => {
                if (res) {

                    let d = res.data;



                    this.setState({ actorData: d })
                    this.setState({ loader: false })
                    console.log('newActorData=================================>', this.state.actorData)
                }

            })



        });
    }



    getAllAreaData = async () => {
        this.setState({ loader: true })

        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

            if (res) {

                let d = res.data;

                if (d != '' && d != null) {
                  
                    let flatLevel = [];
              
              
                    d.forEach((item) => {
              
              
                        item.areaSubData = [];
              
                        flatLevel.push(item)
              
              
                    })
                    
                    this.setState({flatArea:flatLevel})
                    
                  }
                this.setState({ mainAreaData: d, loader: false, sendAreaData: d })
                this.getActorData();
                console.log('areaData================>tracker', d)
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });


    }



    getMasterData = () => {
        AdminService.httpGet('/data-import/master/getAll').then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;


                // d.forEach((ele  , index)=>{ele.index = index })

                this.setState({ masterData: d })




                // if(this.state.filterCat=="actor"){
                //     this.setState({ filteredData: d.Actors })
                // }else if(this.state.filterCat=="waste"){
                //     this.setState({ filteredData: d.Waste_Types })
                // }else {
                //     this.setState({ filteredData: d.Facility_Types })
                // }

           
                this.setState({ loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }

}







export default connect(

)(TrackerPage);
