import React from 'react';
import { Navigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import * as $ from 'jquery';

export function RolesAuthRoute({ children, roles }) {
    const location = useLocation();
    const route = location.pathname;
  
    let userInfo = localStorage.getItem('userInfo');
    if(userInfo != "undefined" && userInfo != null && userInfo.length > 0){
        let userObj = JSON.parse(userInfo);
        let userRole = userObj.role;
        
        const canAccess = roles.includes(userRole);
        if (canAccess)
            return (
                <>
                    {children}
                </>
            );
    }
    
    return (<><Navigate to="/home" /> {setTimeout(()=>{$('#homeUserProfile').trigger('click')},500)}</>);
}