import { Component } from 'react'
import './activity.css';
import * as jsx from './activity.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import AdminService from '../../../services/admin.service';
import ViewModal from './viewModal';

import dayjs from 'dayjs';
import swal from 'sweetalert';
import activityTemplate from './activity.module';
import ModalImage from "react-modal-image";
class Activity extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            licenceData : [],
            sourceType : [],
            actorAreaData : [],
            actorProfileData : [],
            filteredData: [],
            filterTypeData : [],
            locData : [],
            searchText: '',
            // mainData: [],
            isEdit: false,
            editEntry: [],
            WasteTypeOrg: [],
            modal: false,
            pendingCount : 0,
            approvedCount : 0,
            disapprovedCount : 0,
            areaData : null,
            loader: false,
            profileData: [],
            // setValue:dayjs('2014-08-18T21:11:54')
            setOpen: false,
            areaTree : [],
            openTab: 'Pending',
            alignment: 'Pending',
            disaaprovest: false,
            publishModal: false,
            deleteApprovedData: false,
            disscrollableModal: false,
            editModeId: null,
            remarkdata: '',
            loader: false,
            dataLimit: 10,
            masterData : null,
            wtTreeData : null,
            actorsTreeData : null,
            atTreeData : null,
            ftTreeData : null,
            cvTreeData : null,  
            actorfilterData: [],
            actorData: [],
            bulkDelete: [],
            dataStatus: 2,
            statusData: [
                { "label": "Pending", "value": 2 },
                { "label": "Approved", "value": 1 },
                { "label": "Disapproved", "value": 3 }
            ],
            flatArea:[]

            // open:false,
        }
    }

    
    handleChange = (event, newAlignment) => {
        this.setState({ alignment: newAlignment, openTab: newAlignment });
        this.getData(newAlignment);
    };


    handleDate = (newValue) => {
        this.setState({ setValue: newValue });
    };




    onSearch = (val) => {
        this.setState({ searchText: val });
        let d = this.state.data;
        let fd = null;
        if (val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.date_of_registration && item.date_of_registration.toLowerCase().includes(val.toLowerCase())
                    || (item.usersData?.[0]?.email && item.usersData?.[0]?.email.toLowerCase().includes(val.toLowerCase()))

                ));
        }

        this.setState({ filteredData: fd });
    }






    columnsPending = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: false,
            width: "50px"
        },
     
        {
            name: 'Activity Type',
            selector: row => <div className='activity_box'>{row?.activity_type}</div> ,
            sortable: true,
            width: '150px',

        },

        {
            name: 'Origin',
            selector: row => row?.origin,
            sortable: true,
            width: "100px"  
        },
        {
            name: 'Destination',
            selector: row => row?.destination,
            sortable: true,
            width: "130px"  
        },
        {
            name: 'Collection Vehicle',
            selector: row => row?.Collection_Vehicle,
            sortable: true,
            width: "200px"  
        },
        {
            name: 'Waste Type',
            selector: row =>  <span className='badge'>{row?.type_of_waste}</span>,
            sortable: true,
            width: "150px"
        },
        // return <>{row?.source_of_waste?.length > 0 && row?.source_of_waste?.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
        // {row?.source_of_waste?.length > 2 ? <><span>{row?.source_of_waste?.length - 1}+</span></> : ''}</> ;
        {
            name: 'Source of Waste',
            selector: row =>  <>{row?.source_of_waste?.length > 0 && row?.source_of_waste?.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
            {row?.source_of_waste?.length > 2 ? <><span>{row?.source_of_waste?.length - 1}+</span></> : ''}</>,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Image',
			selector: row => <div className='documentImg'><span>
				{row.document != ''?
					<ModalImage
					small={`${constants.actorDocPath}${row.document}`}
					large={`${constants.actorDocPath}${row.document}`}
					hideDownload
					hideZoom
					/>
					: ''
				}
				{/* <img src={`${constants.actorDocPath}${row.document}`} /> */}
				</span>
				</div>,
            sortable: true,
            width: '150px'
        },
      

        {
            name: <div className='extra_content_thead d-flex flex-column align-item-center'>Quantity</div>,
            selector: row => <div> {row?.quantity} {row?.unit}</div>,
            sortable: true,
            width: '150px'
        },

        {
            name: 'Date & Time',
            selector: row => dayjs(row?.activity_date_time).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            
            sortable: true,
            width: '150px'
        },
      
        {
            name: 'Actor Location',
            // selector: row => row?.actor_location, 
            selector: row => this.state.flatArea?.map(item=>{

                
                if(item.area_code==row?.actor_location){

                    if((row?.activity_type).toLowerCase() == "generator"){
                        return (<>{item.name}</>);
                    }
                    console.log("item-----",item.name)
                    
                }
        
        
              }),
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Name',
            selector: row => row?.facility_name,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Type',
            selector: row => <span className='badge'>{row?.facility_type}</span>,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Facility Location',
            // selector: row => row?.facility_location,
            // cell: row => <>{this.showLocation("abc")} </>,
            selector : row => this.showLocation(row?.facility_location),
            sortable: true,
            width: '200px'
        },
        // {
        //     name: 'License No.',
        //     selector: row => row?.licenseno,
        //     sortable: true,
        //     width: '150px'
        // },
        // {
        //     name: 'License Awarded',
        //     selector: row => row?.licenseawarded,
        //     sortable: true,
        //     width: '180px'
        // },
        {
            name: 'Description',
            selector: row => <div className='actor_description_box'>{row?.description}</div> ,
            sortable: true,
            width: '350px'
        },


        {
            name: 'Action',
            width: "170px",
            cell: row =>
                <>
                    <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#activityviewmodal" onClick={(e) => this.sendActorData(row)}>View</a>
                        
                            <a className="pop-up-btn dropdown-item" onClick={(e)=>{this.editDataEntry(row._id)}} >
                                <span _ngcontent-nud-c5="" title="Approve">
                                    Edit
                                </span>
                            </a>
                            <a className="pop-up-btn dropdown-item" onClick={(e) => this.deleteEntry(e, row?._id)} >
                                <span _ngcontent-nud-c5="" title="Disapprove">
                                    Delete
                                </span>
                            </a>
                        </div>
                    </div>
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    columnsApproved = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: false,
            width: "50px"
        },
      
        {
            name: 'Activity Type',
            selector: row => <div className='activity_box'>{row?.activity_type}</div> ,
            sortable: true,
            width: '150px',

        },

        {
            name: 'Origin',
            selector: row => row?.origin,
            sortable: true,
            width: "100px"  
        },
        {
            name: 'Destination',
            selector: row => row?.destination,
            sortable: true,
            width: "130px"  
        },
        {
            name: 'Collection Vehicle',
            selector: row => row?.Collection_Vehicle,
            sortable: true,
            width: "200px"  
        },
        {
            name: 'Waste Type',
            selector: row =>  <span className='badge'>{row?.type_of_waste}</span>,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Source of Waste',
            selector: row =>  <>{row?.source_of_waste?.length > 0 && row?.source_of_waste?.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
            {row?.source_of_waste?.length > 2 ? <><span>{row?.source_of_waste?.length - 1}+</span></> : ''}</>,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Image',
			selector: row => <div className='documentImg'><span>
				{row.document != ''?
					<ModalImage
					small={`${constants.actorDocPath}${row.document}`}
					large={`${constants.actorDocPath}${row.document}`}
					hideDownload
					hideZoom
					/>
					: ''
				}
				{/* <img src={`${constants.actorDocPath}${row.document}`} /> */}
				</span>
				</div>,
            sortable: true,
            width: '150px'
        },
   

        {
            name: <div className='extra_content_thead d-flex flex-column align-item-center'>Quantity</div>,
            selector: row => <div> {row?.quantity} {row?.unit}</div>,
            sortable: true,
            width: '150px'
        },

        {
            name: 'Date & Time',
            selector: row => dayjs(row?.activity_date_time).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            sortable: true,
            width: '150px'
        },
   
        {
            name: 'Actor Location',
            // selector: row => row?.actor_location, 
            selector: row => this.state.flatArea?.map(item=>{

      
                if(item.area_code==row?.actor_location){
                    if((row?.activity_type).toLowerCase() == "generator"){
                        return (<>{item.name}</>);
                    }
                }
        
        
              }),
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Name',
            selector: row => row?.facility_name,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Type',
            selector: row => <span className='badge'>{row?.facility_type}</span>,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Facility Location',
            // selector: row => row?.facility_location,
            selector : row => this.showLocation(row?.facility_location),
            sortable: true,
            width: '200px'
        },
        // {
        //     name: 'License No.',
        //     selector: row => row?.licenseno,
        //     sortable: true,
        //     width: '150px'
        // },
        // {
        //     name: 'License Awarded',
        //     selector: row => row?.licenseawarded,
        //     sortable: true,
        //     width: '180px'
        // },
        {
            name: 'Description',
            selector: row => <div className='actor_description_box'>{row?.description}</div> ,
            sortable: true,
            width: '350px'
        },
        {
            name: 'Action',
            width: "170px",
            cell: row =>
                <>


                    <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                            
                            <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#activityviewmodal" onClick={(e) => this.sendActorData(row)}>View</a>
                           
                            <a class="dropdown-item text-danger text-bold" onClick={(e) => this.deleteEntry(e, row?._id)} href="javascript:;">Delete</a>
                        </div>
                    </div>

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];

    columnsRejected = [
        {
            name: '#',
            selector: (row, index) => index + 1,
            sortable: false,
            width: "50px"
        },
      
        {
            name: 'Activity Type',
            selector: row => <div className='activity_box'>{row?.activity_type}</div> ,
            sortable: true,
            width: '150px',

        },

        {
            name: 'Origin',
            selector: row => row?.origin,
            sortable: true,
            width: "100px"  
        },
        {
            name: 'Destination',
            selector: row => row?.destination,
            sortable: true,
            width: "130px"  
        },
        {
            name: 'Collection Vehicle',
            selector: row => row?.Collection_Vehicle,
            sortable: true,
            width: "200px"  
        },
        {
            name: 'Waste Type',
            selector: row =>  <span className='badge'>{row?.type_of_waste}</span>,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Source of Waste',
            selector: row =>  <>{row?.source_of_waste?.length > 0 && row?.source_of_waste?.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
            {row?.source_of_waste?.length > 2 ? <><span>{row?.source_of_waste?.length - 1}+</span></> : ''}</>,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Image',
			selector: row => <div className='documentImg'><span>
				{row.document != ''?
					<ModalImage
					small={`${constants.actorDocPath}${row.document}`}
					large={`${constants.actorDocPath}${row.document}`}
					hideDownload
					hideZoom
					/>
					: ''
				}
				{/* <img src={`${constants.actorDocPath}${row.document}`} /> */}
				</span>
				</div>,
            sortable: true,
            width: '150px'
        },
      

        {
            name: <div className='extra_content_thead d-flex flex-column align-item-center'>Quantity</div>,
            selector: row => <div> {row?.quantity} {row?.unit}</div>,
            sortable: true,
            width: '150px'
        },

        {
            name: 'Date & Time',
            selector: row => dayjs(row?.activity_date_time).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            sortable: true,
            width: '150px'
        },
     
        {
            name: 'Actor Location',
            // selector: row => row?.actor_location, 
            selector: row => this.state.flatArea?.map(item=>{

      
                if(item.area_code==row?.actor_location){
                    if((row?.activity_type).toLowerCase() == "generator"){
                        return (<>{item.name}</>);
                    }
                }
        
        
              }),
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Name',
            selector: row => row?.facility_name,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Type',
            selector: row => <span className='badge'>{row?.facility_type}</span>,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Facility Location',
            // selector: row => row?.facility_location,
            selector : row => this.showLocation(row?.facility_location),
            sortable: true,
            width: '200px'
        },
        // {
        //     name: 'License No.',
        //     selector: row => row?.licenseno,
        //     sortable: true,
        //     width: '150px'
        // },
        // {
        //     name: 'License Awarded',
        //     selector: row => row?.licenseawarded,
        //     sortable: true,
        //     width: '180px'
        // },
        {
            name: 'Description',
            selector: row => <div className='actor_description_box'>{row?.description}</div>,
            sortable: true,
            width: '350px'
        },
        {
            name: 'Action',
            width: "170px",
            cell: row =>
                <>


                    <div class="dropdown table-action">
                        <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                            Select
                        </button>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">

                        <a className="dropdown-item  text-bold" data-toggle="modal" data-target="#activityviewmodal" onClick={(e) => this.sendActorData(row)}>View</a>
                       
                      

                            <a class="dropdown-item text-danger text-bold" onClick={(e) => this.deleteEntry(e, row?._id)} href="javascript:;">Delete</a>
                        </div>
                    </div>

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];


    showLocation =  (e) => {
        console.log("e-------",e);
        const dtarr = [];
        var i=0;
        this.state.flatArea?.map(item=>{

            e?.forEach(element => {

                if(item.area_code==element){
                    console.log("item-----",item.name)
                    // if(){

                    // }
                    dtarr.push(item.name +", " )
                    
                }
                
            });

            
    
    
          })
        

        return <>{dtarr?.length > 0 && dtarr.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
        {dtarr?.length > 2 ? <><span>{dtarr?.length - 1}+</span></> : ''}</> ;

      

    }

    cancelModal = async (e) => {

        if (this.state.disaaprovest == false) {
            this.setState({ disaaprovest: true })
            this.setState({ remarkdata: e })
        }
        else {

            this.setState({ disaaprovest: false })
        }

    }

    openViewModal = async (e) => {

        swal({
            title: "REASON OF DISAPPROVAL",
            text: e

        })

    }

    sendStatus = (e) => {
        this.state.dataStatus = e;
        this.getData(e);
    }

    getDataByStatus = (e) => {
        console.log("data-----" + e.target.value)
        const val = e.target.value;
        this.setState({ dataStatus: val })
        console.log("------aa--" + val)
        console.log("data-----" + e.target.value)
        console.log("state--" + this.state.dataStatus)

        this.getData(e.target.value);
    }


    editDataEntry = (id) => {


        const edData = [];
        this.state.filteredData.forEach((item)=>{

            if(item._id==id){
                edData.push(item)
               return;
            }

        });

        this.setState({editEntry:edData})
        this.setState({ isEdit: true })
        this.openAddModal();

    }

    publishAll = (e) => {

        console.log("length++--" + this.state.filteredData)
        if (this.state.data == "") {
            toast.error("No record found to approve");
        } else {


            if (this.state.publishModal == false) {
                this.setState({ publishModal: true })
                this.setState({ remarkdata: e })
            }
            else {

                this.setState({ publishModal: false })
            }
        }

    }

    getDate = async () => {
        const today = new Date();
        const currentYear = today.getFullYear();
        const currentMonth = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
        const currentDate = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
        const todaysDate = String(currentYear)+'-' + String(currentMonth)+'-' + String(currentDate);
        return todaysDate;
    }

    fetchData = async () => {
        
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        
        let id = savedItem.id;
        
        
        
        this.setState({loader:true})
        const dataToBeSend = {
            
            "user_id" : id

        }
    
         await AdminService.httpPost('/data-import/actor-activity/downloadDataOfActivity',dataToBeSend).then((res)=>{
          
            if(res){
                
                let d = res;
      
                    let filepath = constants.uploadPath + d.filepath;
                    
                    
                    let filename = d.filepath.replace(/\.[^/.]+$/, "");
                    
                    
                    
                    let a = document.createElement("a");
                    a.href = filepath;
                    a.download = filename;
                   
                    document.body.appendChild(a);
                    setTimeout(() => {
                    a.click();
                    
                    
                    
                  }, 1000);
                  this.setState({loader:false})
                  document.body.removeChild(a);
    
    
         
              
            }
        
        }).catch((err)=>{
        
        console.log(err)
        })
    

    
    }








    sendActorData(row) {
        this.setState({ profileData: row });
        

        
    }




    // /==========change status api=============

    changeStatus(e, id, status, value) {
        if (value == 0) {
            value = 1;
        } else {
            value = 0;
        }


        const dataToBeSend = {

            show: value

        }


        AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {

            if (res) {
                this.setState({ loader: false })
                let d = res;

                // console.log(res)
                // this.getData(this.state.dataStatus);

                if (res.message == 'Status  successfully updated!') {
                    toast.success("Status  successfully updated!");
                    // this.openAddModal();
                    // this.getData();
                }
                else {
                    toast.error("Failed to update status ");

                }





            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }



    ////delete api code///////
    deleteEntry = (e, id) => {

        swal({
            title: "Are you sure you want to delete?",
            text: "Once deleted, you will not be able to recover this activity!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        "selectedIds": id
                    }

                    AdminService.httpPost('/data-import/actor-activity/delete', dataToBeSend).then((res) => {

                        if (res) {
                            this.setState({ loader: false })
                            let d = res;

                            console.log(res)

                            if (res.success == true) {
                                toast.success("Activity deleted successfully!");
                                this.getData();
                            }
                            else {
                                toast.error("Failed to delete activity");

                            }

                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });






    }





    editMode(event, id) {
        const edData = [];
        this.state.filteredData.forEach((item) => {

            if (item._id == id) {
                edData.push(item)
                return;
            }

        });

        this.setState({ editEntry : edData, isEdit: true })
        
        this.openAddModal();

    }


    openEditModal() {
        if ($('#EditAreaModal').hasClass('width350')) {
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else {
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

    componentDidMount() {
        this.actorProfileData();
        this.getMasterData();
        this.getAllAreaData();
        this.actorAreaData();
        
        this.getData();
        
        // this.getRoleData();
        // this.facilityTypeData();

    
        
        
    }

    render() {
        return jsx.default.bind(this)();
    }
    testClickEvent(param) {
        alert('Row Click Event');
    }


    actorAreaData = async(state) =>{

        
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const data = {
            "user_id" : userInfo?.id
        }
       await AdminService.httpPost('/data-import/actor/getByActorArea', data).then((res)=>{
            if(res){
                 let d = res?.data;
                 
               
                 
                 this.setState({ actorAreaData : d }) 
            }
        })
        .catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });




    }

    actorProfileData = async(state) =>{

        
        let userInfo = JSON.parse(localStorage.getItem('userInfo'));
        const data = {
            "user_id" : userInfo?.id
        }
       await AdminService.httpPost('/data-import/actor/getById', data).then((res)=>{
            if(res){
                 let d = res?.data?.[0];
                 let collections=[];
               
                 
                 this.setState({ actorProfileData: d, cvTreeData: d?.Collection_Vehicle, 
                    wtTreeData: d?.waste_type, actorsTreeData: d?.profile_type,
                    ftTreeData: d?.facility_type, licenceData : d?.licenceData })

                    const data = {
                        "type" : this.state.ftTreeData
                    }
                    AdminService.httpPost('/data-import/facility/filterByType', data).then((res)=>{
                        if(res){
                             let d = res?.data;
                             this.setState({filterTypeData : d})
                           
                             
                           
                             
                            
                        }
                    })
                 
                
            }
        })
        .catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });




    }

    // facilityTypeData = async(state) =>{

        
        
    //     const data = {
    //         "type" : this.state.ftTreeData
    //     }
    //    await AdminService.httpPost('/data-import/facility/filterByType', data).then((res)=>{
    //         if(res){
    //              let d = res?.data?.[0];
    //              let collections=[];
               
                 
    //             //  this.setState({ actorProfileData: d, cvTreeData: d?.Collection_Vehicle, 
    //             //     wtTreeData: d?.waste_type, actorsTreeData: d?.profile_type,
    //             //     ftTreeData: d?.facility_type })
                 
                
    //         }
    //     })
    //     .catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     });




    // }

    ///////////get api------------------/////////////

    getData = async (alignment = null) => {
        
        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
            
            let iduser = savedItem.id;
            this.setState({data : [], loader: true, searchText: "",pendingCount:0,approvedCount:0,disapprovedCount:0})
            
        // this.setState({  });
        let actorState = constants.actorByState[this.state.alignment];
        
        
        if (alignment) {
            actorState = constants.actorByState[alignment];
            
        }
        const dataToBeSend = {
            id: iduser,
            status: actorState

        }

        
       await AdminService.httpPost('/data-import/actor-activity/getByUserId',dataToBeSend).then((res) => {
            if (res && res.data?.length > 0) {
                // this.setState({ loader: false })
                let d = res.data;

                
                this.setState({ approvedCount : d[0].activityApprovedData.length,pendingCount : d[0].activityPendingData.length, disapprovedCount : d[0].activityDisapprovedData.length})
                
                if(actorState==1){
                    
                        this.setState({loader: false,  filteredData: d[0].activityApprovedData, data: d[0].activityApprovedData})
                }else if(actorState==2){

                    this.setState({loader: false,  filteredData: d[0].activityPendingData, data: d[0].activityPendingData})
                    

                }else{
                    this.setState({loader: false,  filteredData: d[0].activityDisapprovedData, data: d[0].activityDisapprovedData})
                }

                


                
            } else {
                this.setState({ loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }

    getMasterData = async () => {
      
        await AdminService.httpGet('/data-import/master/getAll').then((res) => {
            if (res) {
                let d = res.data;
                this.setState({ masterData: d, 
                                    
                                    atTreeData: d[constants.masterListType[10]] ,
                                    WasteTypeOrg: d[constants.masterListType[3]],
                                    sourceType: d[constants.masterListType[11]]
                                                                  
                                    
                                 })

                                    
            }
      
          }).catch((err) => {
              this.setState({ loader: false })
              console.log(err)
          });

    }

 getAllAreaData = async () => {

       await AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {
            if (res) {
    
                let d = res.data;
                
    
                if (d != '' && d != null) {
                  
                  let flatLevel = [];
            
            
                  d.forEach((item) => {
            
            
                      item.areaSubData = [];
            
                      flatLevel.push(item)
            
            
                  })
                  const data = this.setLevelData(flatLevel)
                  this.setState({areaTree : data, flatArea:flatLevel})
                  
                }
                this.setState({areaData : d})
                
    
            }
    
        }).catch((err) => {
    
            console.log(err)
        });
    
    
      }


     setLevelData = (flat) => {

        var roots = []
    
        var all = {}
    
        flat.forEach(function (item) {
            all[item.area_code] = item
        })
        Object.keys(all).forEach(function (area_code) {
            var item = all[area_code]
            if (item.parent_id === null || item.parent_id === '') {
                roots.push(item)
            } else if (item.parent_id in all) {
                var p = all[item.parent_id]
                if (!('areaSubData' in p)) {
                    p.areaSubData = []
                }
                p.areaSubData.push(item)
            }
        })
    
        return roots
    }


    saveData = async (e) => {
        console.log("dataaaaaa hai yeeee--->", e.id)

        if(e.id!=null){

            this.updateEntry(e)

        }
        else{
            this.addNewEntry(e)
        }

    }

    updateEntry = async(state) =>{

        
        // this.setState({ loader: true })
        const dataToBe2= {

            "actor_type": state.actor_type,
            "facility_type": state.facility_type,
            "facility_name": state.facility_name,
            "type_of_waste": state.type_of_waste,
            "activity_type": state.activity_type,
            "quantity" : state.quantity,
            "unit" : state.qty_unit,//
            "source_of_waste" : state.source_of_waste,
            "Collection_Vehicle" : state.Collection_Vehicle,
            "destination": state.destination,
            "origin" : state.origin,
            "activity_date_time": state.activity_date_time,
            
            // "document" : state.document,
            "document": state.document,
            "activity_name": state.activity_name,   
            "facility_location": state.facility_location ,
            "actor_location": state.actor_location ,
            "description": state.description
           

        }

        console.log('dten', dataToBe2)

        AdminService.httpPut('/data-import/actor-activity/update/'+state.id, dataToBe2).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;

                console.log(res)
                if(  res.success == true){
                    toast.success("Activity updated successfully!");
                    this.setState({ isEdit: false, editEntry: [],modal:true})
                    this.openAddModal();
                    
                    
                    this.getData();
                    
        
                    
                }
                else{
                    toast.error(res.message);

                }
             
           
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });




    }

    addNewEntry = async (e) =>{

        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
            
            let iduser = savedItem.id;
        console.log('dten', e)
        this.setState({ loader: true })
        const dataToSend = {
            "actor_type": e.actor_type,
            "facility_type": e.facility_type,
            "facility_name": e.facility_name,
            "type_of_waste": e.type_of_waste,
            "activity_type": e.activity_type,
            "quantity" : e.quantity,
            "unit" : e.qty_unit,
            "source_of_waste" : e.source_of_waste,
            "Collection_Vehicle" : e.Collection_Vehicle,
            "destination": e.destination,
            "origin" : e.origin,
            "activity_date_time": e.activity_date_time,
            "user_id": iduser,
            "status" : 2,
            "n_status" : 1,
            "document" : e.document,
            "activity_name": e.activity_name,   
            "facility_location": e.facility_location,
            "actor_location": e.actor_location,
            "description": e.description
          };


        console.log('check',dataToSend)

        AdminService.httpPost('/data-import/actor-activity/add', dataToSend).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;

                console.log(res)

                if(  res.success == true){
                    toast.success("Activity created successfully!");
                    this.openAddModal();
                    this.getData();
                }
                else{
                    toast.error(res.message);

                }
           
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });




    }

    // open add modal=====================//


    openAddModal = () => {


        if (this.state.modal == false) {
            console.log("hiiiii")
            this.setState({ modal: true })

        } else {
            this.setState({ ...this.state.editEntry, editEntry: [] })
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            this.setState({ editModeId: null })
        }

    }
    ////=========appprove data api////==========
    approveData = (id1, status) => {

        this.setState({ loader: false })
        swal({
            title: "Are you sure you want to approve this data?",
            text: "Once Approved, you will not be able to disapprove this actor!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        id: id1,
                        status: 1

                    }



                    AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {
                        if (res) {
                            this.setState({ loader: false })
                            let d = res.data;
                            this.setState({ filteredData: d, data: d })
                            this.getData();
                            if (res.success == true) {
                                toast.success("Activity approved successfully!");
                                // this.openAddModal();
                                // this.getData();
                            }
                            else {
                                toast.error("Failed to approve activity");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });





    }



    /////////////////==========role and actor  data get==========//////////////
    // getRoleData = async () => {
    //     this.setState({ loader: true })


    //     AdminService.httpGet('/data-retrieval/data/masterData').then((res) => {
    //         if (res) {

    //             let d = res[0]?.data.Actors;

    //             console.log("somratest========>", d);
    //             this.setState({ actorData: d, loader: false })

    //         }

    //     }).catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     });


    // }


    // /multiple delete

    handleChangeActor = ({ selectedRows }) => {

        this.setState({ bulkDelete: selectedRows })

    };



    handleChangeData = ({ toggleCleared }) => {
        this.setState({ bulkDelete: toggleCleared })

    };







    //////////=========checkbox delete============/////////

    deleteBulkUser = (e) => {
        let ids = [];

        this.state.bulkDelete.forEach(item => {
            ids.push(item._id)

        })

        if (ids?.length > 0) {

            swal({
                title: "Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this actor!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
                .then((willDelete) => {
                    if (willDelete) {
                        const data = {
                            "_id": ids.toString()
                        }
                        AdminService.httpPost('/data-import/actor/delete-multiple-actor', data).then((res) => {

                            if (res) {
                                this.setState({ loader: false })
                                let d = res;

                                console.log("eeeee", res)

                                if (res.success == 1) {
                                    toast.success("Activity deleted successfully!");
                                    this.getData();
                                }
                                else {
                                    toast.error("Failed to delete activity");

                                }

                            }

                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                    }
                });
        }


        else {
            swal({
                title: "No Record Selected!",
                text: "Please select at a record to delete.",
                icon: "warning",
                button: true,

            })

            return false;


        }

    }



    ////undo function


    undo = (id1, status) => {

        this.setState({ loader: false })
        swal({
            title: "Are you sure you want to undo ?",
            // text: "Once Approved, you will not be able to disapprove this actor!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    const dataToBeSend = {
                        id: id1,
                        status: 2

                    }



                    AdminService.httpPost('/data-import/actor/update-status', dataToBeSend).then((res) => {
                        if (res) {
                            this.setState({ loader: false })
                            let d = res.data;
                            this.setState({ filteredData: d, data: d })
                            this.getData();
                            if (res.success == true) {
                                toast.success("Activity approved successfully!");
                                // this.openAddModal();
                                // this.getData();
                            }
                            else {
                                toast.error("Failed to approve activity");

                            }
                        }

                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                }
            });





    }







}




const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
)(Activity);
