import { Component } from 'react'
import './home.css';
import * as jsx from './home.module.jsx';
import * as $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import { connect } from "react-redux";

class HomePage extends Component {
    constructor() {
        super()
        this.state = {
            fecData:[],
            resData:[],
            keyData: [],
            loader:false,
            data:[]
        }

        this.countUpRefs = [];
        this.starts = [];
        this.abc = [
                <svg version="1.1" id="Layer_1" width="961" height="596" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                viewBox="0 0 961 596">
                <a className='cursor-default bannerbg'>
                  <g>
                    <path className="st0" d="M826.3,204.7C676.1,236,764.1-110.6,559.6,36.5C478.2,95,361.1,24.3,248.9,62.9
                      c-103.1,35.5-77.7,152.1-67.7,190.3c20.1,77.1-80.2,46.3-138.3,79.4c-91.2,51.9-11.2,261.3,74.9,262.2
                      c86.1,0.9,770.6-1.7,770.6-1.7s37.6-29.4,57.6-90C986.9,378.8,958.5,177.1,826.3,204.7z"/>
                  </g>
                </a>
                <a className='cursor-default building'>
                  <g>
                    <path className="st1" d="M276.9,289.4v-35h10.6v-60.2h57v60.2h53.3v-82.6l58.8-22.3v70.4h13.4v-93.8l58.8,19.5v140.8h74.4v83.2h27.8
                      v21h63.9v-36.1h36.7v-98.1h45.3v181h45.3V595h-45.3h-38.8h-6.5h-36.7h-20.7h-33.2h-9.9h-27.8h-26.1H529h-14.8h-29.4h-14.6h-13.4
                      H426h-21h-7.1h-53.3h-56.1h-1h-10.6h-45.4V289.4H276.9z"/>
                    <path className="st2" d="M480.5,167.3h39.7v-14.5h-39.7V167.3z"/>
                    <path className="st2" d="M480.5,194.6h39.7v-14.5h-39.7V194.6z"/>
                    <path className="st2" d="M480.5,219.2h39.7v-14.5h-39.7V219.2z"/>
                    <path className="st2" d="M777.1,322.5h-46.1V345h46.1V322.5z"/>
                    <path className="st2" d="M777.1,391.4h-45.7v22.4h45.7V391.4z"/>
                    <path className="st2" d="M777.1,425.3h-45.7v22.4h45.7V425.3z"/>
                    <path className="st2" d="M732.1,483.2h45.7v-22.4h-45.7V483.2z"/>
                    <path className="st2" d="M777.8,495.5h-45.7v22.4h45.7V495.5z"/>
                    <path className="st2" d="M732.1,553.4h45.7V531h-45.7V553.4z"/>
                    <path className="st2" d="M449,204.6h-41.8v14.5H449V204.6z"/>
                    <path className="st2" d="M449,180.3h-41.8v14.5H449V180.3z"/>
                    <path className="st2" d="M521.2,232.9h-114v14.5h114V232.9z"/>
                    <path className="st2" d="M521.2,254.8h-114v14.5h114V254.8z"/>
                    <path className="st2" d="M521.2,276.5h-114v14h114V276.5z"/>
                    <path className="st2" d="M777.1,357.5h-45.7V380h45.7V357.5z"/>
                    <path className="st2" d="M592,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M573.4,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M555.2,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M537.9,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M520.2,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M502.4,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M483.8,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M465.5,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M448.2,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M430.6,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M412.3,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M393.6,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M375.4,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M358.1,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M340.4,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M304,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M285.7,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M268.4,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M250.8,300.1h-10v14.1h10V300.1z"/>
                    <path className="st2" d="M592,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M573.4,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M555.2,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M537.9,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M520.2,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M502.4,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M483.8,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M465.5,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M448.2,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M430.6,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M412.3,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M393.6,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M375.4,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M358.1,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M340.4,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M322.6,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M304,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M285.7,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M268.4,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M250.8,324.9h-10V339h10V324.9z"/>
                    <path className="st2" d="M592,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M573.4,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M555.2,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M537.9,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M520.2,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M502.4,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M483.8,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M465.5,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M448.2,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M430.6,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M412.3,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M393.6,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M375.4,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M358.1,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M340.4,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M322.6,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M304,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M285.7,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M268.4,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M250.8,349.4h-10v14.1h10V349.4z"/>
                    <path className="st2" d="M592,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M573.4,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M555.2,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M537.9,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M520.2,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M502.4,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M483.8,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M465.5,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M448.2,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M430.6,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M412.3,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M393.6,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M375.4,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M358.1,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M340.4,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M322.6,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M304,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M285.7,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M268.4,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M250.8,375.3h-10v14.1h10V375.3z"/>
                    <path className="st2" d="M592,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M573.4,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M555.2,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M537.9,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M520.2,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M502.4,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M483.8,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M465.5,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M448.2,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M430.6,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M412.3,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M393.6,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M375.4,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M358.1,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M340.4,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M322.6,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M304,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M268.4,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M285.7,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M250.8,400.1h-10v14.1h10V400.1z"/>
                    <path className="st2" d="M592,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M573.4,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M555.2,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M537.9,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M520.2,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M502.4,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M483.8,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M465.5,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M448.2,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M430.6,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M412.3,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M393.6,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M375.4,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M358.1,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M340.4,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M322.6,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M304,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M268.4,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M250.8,424.6h-10v14.1h10V424.6z"/>
                    <path className="st2" d="M157.2,396.8H135v150.1h22.2V396.8z"/>
                    <path className="st3" d="M157.2,592.3h23.8V488h-23.8V592.3z"/>
                    <path className="st2" d="M678.9,354.5v-29.2h-1.2v29.2h-16.5v36.1h33.9v-36.1H678.9z"/>
                    <path className="st2" d="M777.1,279v-22.4h-9.5v-9.8h-1.2v9.8h-34.8h-0.6h-6.6v-9.8h-1.2v9.8h-8.4v98.1h16.9v-42.2h45.5v-22.4h-45.5
                      V279H777.1z"/>
                    <path className="st1" d="M284.5,418.6v126.6H157.2V396.8L284.5,418.6z"/>
                    <path className="st1" d="M125.7,594.4h158.7V487.6H125.7V594.4z"/>
                    <path className="st2" d="M125.7,459.2h158.7v-34.7H125.7V459.2z"/>
                    <path className="st2" d="M273.7,516.6h-8.2v11.5h8.2V516.6z"/>
                    <path className="st2" d="M258.4,516.6h-8.2v11.5h8.2V516.6z"/>
                    <path className="st2" d="M243.5,516.6h-8.2v11.5h8.2V516.6z"/>
                    <path className="st2" d="M229.4,516.6h-8.2v11.5h8.2V516.6z"/>
                    <path className="st2" d="M215,516.6h-8.2v11.5h8.2V516.6z"/>
                    <path className="st2" d="M200.4,516.6h-8.2v11.5h8.2V516.6z"/>
                    <path className="st2" d="M185.2,516.6H177v11.5h8.2V516.6z"/>
                    <path className="st2" d="M170.3,516.6h-8.2v11.5h8.2V516.6z"/>
                    <path className="st2" d="M156.1,516.6H148v11.5h8.2V516.6z"/>
                    <path className="st2" d="M273.7,536.9h-8.2v11.5h8.2V536.9z"/>
                    <path className="st2" d="M258.4,536.9h-8.2v11.5h8.2V536.9z"/>
                    <path className="st2" d="M243.5,536.9h-8.2v11.5h8.2V536.9z"/>
                    <path className="st2" d="M229.4,536.9h-8.2v11.5h8.2V536.9z"/>
                    <path className="st2" d="M215,536.9h-8.2v11.5h8.2V536.9z"/>
                    <path className="st2" d="M200.4,536.9h-8.2v11.5h8.2V536.9z"/>
                    <path className="st2" d="M185.2,536.9H177v11.5h8.2V536.9z"/>
                    <path className="st2" d="M170.3,536.9h-8.2v11.5h8.2V536.9z"/>
                    <path className="st2" d="M156.1,536.9H148v11.5h8.2V536.9z"/>
                    <path className="st2" d="M273.7,475.7h-8.2v11.5h8.2V475.7z"/>
                    <path className="st2" d="M258.4,475.7h-8.2v11.5h8.2V475.7z"/>
                    <path className="st2" d="M243.5,475.7h-8.2v11.5h8.2V475.7z"/>
                    <path className="st2" d="M229.4,475.7h-8.2v11.5h8.2V475.7z"/>
                    <path className="st2" d="M215,475.7h-8.2v11.5h8.2V475.7z"/>
                    <path className="st2" d="M200.4,475.7h-8.2v11.5h8.2V475.7z"/>
                    <path className="st2" d="M185.2,475.7H177v11.5h8.2V475.7z"/>
                    <path className="st2" d="M170.3,475.7h-8.2v11.5h8.2V475.7z"/>
                    <path className="st2" d="M273.7,496h-8.2v11.5h8.2V496z"/>
                    <path className="st2" d="M258.4,496h-8.2v11.5h8.2V496z"/>
                    <path className="st2" d="M243.5,496h-8.2v11.5h8.2V496z"/>
                    <path className="st2" d="M229.4,496h-8.2v11.5h8.2V496z"/>
                    <path className="st2" d="M215,496h-8.2v11.5h8.2V496z"/>
                    <path className="st2" d="M200.4,496h-8.2v11.5h8.2V496z"/>
                    <path className="st2" d="M185.2,496H177v11.5h8.2V496z"/>
                    <path className="st2" d="M170.3,496h-8.2v11.5h8.2V496z"/>
                    <path className="st2" d="M156.1,496H148v11.5h8.2V496z"/>
                    <path className="st2" d="M273.7,556.8h-8.2v11.5h8.2V556.8z"/>
                    <path className="st2" d="M258.4,556.8h-8.2v11.5h8.2V556.8z"/>
                    <path className="st2" d="M243.5,556.8h-8.2v11.5h8.2V556.8z"/>
                    <path className="st2" d="M229.4,556.8h-8.2v11.5h8.2V556.8z"/>
                    <path className="st2" d="M215,556.8h-8.2v11.5h8.2V556.8z"/>
                    <path className="st2" d="M200.4,556.8h-8.2v11.5h8.2V556.8z"/>
                    <path className="st2" d="M185.2,556.8H177v11.5h8.2V556.8z"/>
                    <path className="st2" d="M170.3,556.8h-8.2v11.5h8.2V556.8z"/>
                    <path className="st2" d="M156.1,556.8H148v11.5h8.2V556.8z"/>
                    <path className="st2" d="M273.7,574.6h-8.2v11.5h8.2V574.6z"/>
                    <path className="st2" d="M258.4,574.6h-8.2v11.5h8.2V574.6z"/>
                    <path className="st2" d="M243.5,574.6h-8.2v11.5h8.2V574.6z"/>
                    <path className="st2" d="M229.4,574.6h-8.2v11.5h8.2V574.6z"/>
                    <path className="st2" d="M215,574.6h-8.2v11.5h8.2V574.6z"/>
                    <path className="st2" d="M200.4,574.6h-8.2v11.5h8.2V574.6z"/>
                    <path className="st2" d="M185.2,574.6H177v11.5h8.2V574.6z"/>
                    <path className="st2" d="M170.3,574.6h-8.2v11.5h8.2V574.6z"/>
                    <path className="st2" d="M156.1,574.6H148v11.5h8.2V574.6z"/>
                    <path className="st1" d="M168.1,372.9h-2.4V403h2.4V372.9z"/>
                    <path className="st1" d="M272.4,434.2h-6.8v11.9h6.8V434.2z"/>
                    <path className="st1" d="M204.4,434.2h-6.8v11.9h6.8V434.2z"/>
                    <path className="st1" d="M150.2,434.2h-6.8v11.9h6.8V434.2z"/>
                    <path className="st2" d="M702.3,460.8H473.9v133.6h228.4V460.8z"/>
                    <path className="st2" d="M623.6,466.9h47.7v-41.6l-47.7,18.3V466.9z"/>
                    <path className="st2" d="M388.7,594.4H279.6V475.7l109.1,37.8V594.4z"/>
                    <path className="st1" d="M385.8,517.4H282.5v3.7h103.3V517.4z"/>
                    <path className="st1" d="M385.8,529.2H282.5v3.7h103.3V529.2z"/>
                    <path className="st1" d="M385.8,541H282.5v3.7h103.3V541z"/>
                    <path className="st1" d="M385.8,552.8H282.5v3.7h103.3V552.8z"/>
                    <path className="st1" d="M385.8,564.6H282.5v3.7h103.3V564.6z"/>
                    <path className="st1" d="M385.8,576.4H282.5v3.7h103.3V576.4z"/>
                    <path className="st2" d="M680.5,425.3h-9.2v35.5h9.2V425.3z"/>
                    <path className="st2" d="M295.7,199h-6.3v53.6h6.3V199z"/>
                    <path className="st2" d="M308.2,199h-6.3v53.6h6.3V199z"/>
                    <path className="st2" d="M320.6,199h-6.3v53.6h6.3V199z"/>
                    <path className="st2" d="M333.5,199h-6.3v53.6h6.3V199z"/>
                    <path className="st4" d="M850.2,514.2h-38.9v80.2h38.9V514.2z"/>
                  </g>
                </a>
                <a className='cursor-default truck'>
                  <g>
                    <path className="st5" d="M835.5,393.4h-76.5c-1.9,0-3.7-0.8-5.1-2.1c-1.3-1.3-2.1-3.2-2.1-5.1v-75c0-1.9,0.8-3.7,2.1-5.1
                      c1.3-1.3,3.2-2.1,5.1-2.1h48.2c1.5,0,2.9,0.5,4.1,1.3c1.2,0.8,2.1,2,2.6,3.4l28.3,75c0.4,1.1,0.5,2.3,0.4,3.4
                      c-0.1,1.2-0.6,2.3-1.2,3.2c-0.7,1-1.5,1.7-2.6,2.3C837.9,393.1,836.7,393.4,835.5,393.4L835.5,393.4z M759.1,308.5
                      c-0.7,0-1.4,0.3-1.9,0.8c-0.5,0.5-0.8,1.2-0.8,1.9v75c0,0.7,0.3,1.4,0.8,1.9c0.5,0.5,1.2,0.8,1.9,0.8h76.5c0.4,0,0.9-0.1,1.3-0.3
                      c0.4-0.2,0.7-0.5,1-0.9c0.3-0.4,0.4-0.8,0.5-1.2c0.1-0.4,0-0.9-0.2-1.3l-28.3-75c-0.2-0.5-0.5-1-1-1.3c-0.5-0.3-1-0.5-1.6-0.5
                      H759.1z"/>
                    <path className="st6" d="M846.8,577.7h-23.6c-1.7,0-3.4-0.7-4.7-1.9c-1.2-1.2-1.9-2.9-1.9-4.7v-23.8h36.7v23.8c0,1.7-0.7,3.4-1.9,4.7
                      C850.2,577,848.5,577.7,846.8,577.7L846.8,577.7z M821.1,551.7v19.3c0,0.6,0.2,1.1,0.6,1.5c0.4,0.4,1,0.6,1.5,0.6h23.6
                      c0.6,0,1.1-0.2,1.5-0.6c0.4-0.4,0.6-0.9,0.6-1.5v-19.3H821.1z"/>
                    <path className="st7" d="M227.4,288.5l408.8-2.1c1.4,0,2.7,0.3,4,0.8c1.3,0.5,2.4,1.3,3.4,2.2c1,1,1.7,2.1,2.2,3.3
                      c0.5,1.3,0.8,2.6,0.8,3.9v192.8l-533.8,30.1v-30.1l50.8-137L227.4,288.5z"/>
                    <path className="st6" d="M829.2,489.5H140.4v56.9h688.8V489.5z"/>
                    <path className="st8" d="M785,580.8c19-19,19-49.7,0-68.7c-19-19-49.7-19-68.7,0c-19,19-19,49.7,0,68.7
                      C735.3,599.8,766,599.8,785,580.8z"/>
                    <path className="st9" d="M750.7,516.3c-6,0-11.8,1.8-16.7,5.1c-4.9,3.3-8.8,8-11.1,13.5c-2.3,5.5-2.9,11.6-1.7,17.4
                      c1.2,5.8,4,11.2,8.2,15.4c4.2,4.2,9.6,7.1,15.4,8.2c5.8,1.2,11.9,0.6,17.4-1.7c5.5-2.3,10.2-6.1,13.5-11.1
                      c3.3-5,5.1-10.8,5.1-16.7c0-8-3.2-15.6-8.8-21.3C766.3,519.5,758.6,516.3,750.7,516.3L750.7,516.3z M754.8,519.6
                      c6.4,1,12.2,4.2,16.4,9.2l-10.4,6.4c-1.7-1.6-3.8-2.7-6-3.3L754.8,519.6z M746.5,519.6v12.3c-2.2,0.6-4.3,1.8-6,3.3l-10.4-6.4
                      C734.3,523.9,740.1,520.6,746.5,519.6L746.5,519.6z M725.6,557c-1.4-3.3-2.1-6.9-2.1-10.6c0-3.6,0.7-7.2,2.2-10.6l10.4,6.4
                      c-0.8,2.7-0.8,5.6,0,8.3L725.6,557z M746.5,573.2c-6.5-1-12.3-4.3-16.5-9.3l10.4-6.4c1.7,1.6,3.8,2.8,6.1,3.4V573.2z M743.8,546.4
                      c0-1.4,0.4-2.7,1.2-3.8c0.8-1.1,1.8-2,3.1-2.5c1.2-0.5,2.6-0.7,3.9-0.4c1.3,0.3,2.5,0.9,3.5,1.9s1.6,2.2,1.9,3.5
                      c0.3,1.3,0.1,2.7-0.4,3.9c-0.5,1.2-1.4,2.3-2.5,3.1c-1.1,0.8-2.4,1.2-3.8,1.2c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.3-1.6-0.8-2.2-1.5
                      s-1.1-1.4-1.5-2.2C744,548.2,743.8,547.3,743.8,546.4z M754.8,573.2V561c2.3-0.6,4.4-1.8,6.1-3.4l10.4,6.4
                      C767.1,569,761.2,572.3,754.8,573.2L754.8,573.2z M765.2,550.5c0.8-2.7,0.7-5.6,0-8.3l10.4-6.4c1.4,3.3,2.2,6.9,2.2,10.6
                      c0,3.6-0.7,7.2-2.1,10.6L765.2,550.5z"/>
                    <path className="st8" d="M371.6,580.8c19-19,19-49.7,0-68.7c-19-19-49.7-19-68.7,0c-19,19-19,49.7,0,68.7
                      C321.9,599.8,352.7,599.8,371.6,580.8z"/>
                    <path className="st9" d="M337.3,516.3c-6,0-11.8,1.8-16.7,5.1c-5,3.3-8.8,8-11.1,13.5c-2.3,5.5-2.9,11.6-1.7,17.4
                      c1.2,5.8,4,11.2,8.2,15.4c4.2,4.2,9.6,7.1,15.4,8.2c5.8,1.2,11.9,0.6,17.4-1.7s10.2-6.1,13.5-11.1c3.3-5,5.1-10.8,5.1-16.7
                      c0-8-3.2-15.6-8.8-21.3C352.9,519.5,345.3,516.3,337.3,516.3L337.3,516.3z M341.4,519.6c6.4,1,12.2,4.2,16.4,9.2l-10.4,6.4
                      c-1.7-1.6-3.8-2.7-6-3.3L341.4,519.6z M333.2,519.6v12.3c-2.2,0.6-4.3,1.8-6,3.3l-10.4-6.4C320.9,523.9,326.8,520.6,333.2,519.6
                      L333.2,519.6z M312.3,557c-1.4-3.4-2.1-6.9-2.1-10.6c0-3.6,0.7-7.2,2.2-10.6l10.4,6.4c-0.8,2.7-0.8,5.6,0,8.3L312.3,557z
                      M333.2,573.3c-6.5-1-12.3-4.3-16.5-9.3l10.4-6.4c1.7,1.6,3.8,2.8,6.1,3.4V573.3z M330.5,546.5c0-1.4,0.4-2.7,1.2-3.8
                      c0.8-1.1,1.8-2,3.1-2.5c1.2-0.5,2.6-0.7,4-0.4c1.3,0.3,2.5,0.9,3.5,1.9s1.6,2.2,1.9,3.5s0.1,2.7-0.4,4c-0.5,1.2-1.4,2.3-2.5,3.1
                      c-1.1,0.8-2.4,1.2-3.8,1.2c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.3-1.6-0.9-2.2-1.5c-0.6-0.6-1.1-1.4-1.5-2.2
                      C330.6,548.2,330.5,547.3,330.5,546.5L330.5,546.5z M341.4,573.3V561c2.3-0.6,4.4-1.8,6.1-3.4L358,564
                      C353.7,569,347.9,572.3,341.4,573.3L341.4,573.3z M351.9,550.5c0.8-2.7,0.7-5.6,0-8.3l10.4-6.4c1.4,3.3,2.2,6.9,2.2,10.6
                      c0,3.6-0.7,7.2-2.1,10.6L351.9,550.5z"/>
                    <path className="st8" d="M491,556.5c5.6-26.2-11.1-52.1-37.3-57.7c-26.2-5.6-52.1,11.1-57.7,37.3c-5.6,26.2,11.1,52.1,37.3,57.7
                      C459.6,599.5,485.4,582.7,491,556.5z"/>
                    <path className="st9" d="M443.6,516.3c-6,0-11.8,1.8-16.7,5.1c-5,3.3-8.8,8-11.1,13.5c-2.3,5.5-2.9,11.6-1.7,17.4
                      c1.2,5.8,4,11.2,8.2,15.4c4.2,4.2,9.6,7.1,15.4,8.2c5.8,1.2,11.9,0.6,17.4-1.7c5.5-2.3,10.2-6.1,13.5-11.1
                      c3.3-4.9,5.1-10.8,5.1-16.7c0-8-3.2-15.6-8.8-21.3S451.5,516.3,443.6,516.3z M447.7,519.6c6.4,1,12.2,4.2,16.4,9.2l-10.4,6.4
                      c-1.7-1.6-3.8-2.7-6-3.3L447.7,519.6z M439.4,519.6v12.3c-2.2,0.6-4.3,1.8-6,3.3l-10.4-6.4C427.2,523.8,433,520.6,439.4,519.6
                      L439.4,519.6z M418.5,556.9c-1.4-3.4-2.1-6.9-2.1-10.6s0.7-7.2,2.2-10.6l10.4,6.4c-0.8,2.7-0.8,5.6,0,8.3L418.5,556.9z
                      M439.4,573.2c-6.5-1-12.3-4.3-16.5-9.3l10.4-6.4c1.7,1.6,3.8,2.8,6.1,3.4V573.2z M436.7,546.4c0-1.4,0.4-2.7,1.1-3.8
                      c0.8-1.1,1.8-2,3.1-2.5c1.2-0.5,2.6-0.7,4-0.4c1.3,0.3,2.5,0.9,3.5,1.9c1,1,1.6,2.2,1.9,3.5c0.3,1.3,0.1,2.7-0.4,4
                      c-0.5,1.2-1.4,2.3-2.5,3.1c-1.1,0.8-2.4,1.2-3.8,1.2c-0.9,0-1.8-0.2-2.6-0.5c-0.8-0.3-1.6-0.9-2.2-1.5c-0.6-0.6-1.1-1.4-1.5-2.2
                      C436.9,548.2,436.7,547.3,436.7,546.4L436.7,546.4z M447.7,573.2v-12.3c2.3-0.6,4.4-1.8,6.1-3.4l10.4,6.4
                      C460,568.9,454.1,572.2,447.7,573.2L447.7,573.2z M458.1,550.5c0.8-2.7,0.7-5.6,0-8.3l10.4-6.4c1.4,3.3,2.2,6.9,2.2,10.6
                      s-0.7,7.2-2.1,10.6L458.1,550.5z"/>
                    <path className="st7" d="M840.8,288.5H716.6c-3.8,0-7.5,1.5-10.3,4.1c-2.8,2.6-4.4,6.3-4.6,10.1l-8.2,180H692
                      c-6.8,0-13.4,2.7-18.2,7.6c-4.8,4.8-7.5,11.4-7.5,18.2V570c0,1.7,0.7,3.4,1.9,4.7c1.2,1.2,2.9,1.9,4.7,1.9h17.9
                      c1.7,0,3.4-0.7,4.7-1.9c1.2-1.2,1.9-2.9,1.9-4.7v-23.5c0-7.2,1.4-14.3,4.2-20.8c2.8-6.6,6.9-12.6,12.1-17.5
                      c5.2-5,11.3-8.9,17.9-11.4c6.7-2.6,13.8-3.8,21-3.5c27.5,0.9,49.9,22.9,51.4,50.3c0.2,3.3,0.1,6.6-0.4,9.8h83.3V395.4l-29.7-95
                      c-1.1-3.5-3.3-6.5-6.2-8.7C848,289.6,844.5,288.5,840.8,288.5L840.8,288.5z"/>
                    <path className="st10" d="M789.5,497.9c7.3,5.8,13.2,13.2,17.2,21.6c4,8.4,6.1,17.6,6.1,27c0,0.6,0,1.2,0,1.8h44.2v-50.4H789.5z"/>
                    <path className="st9" d="M858.2,303.9h-33c-0.5,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.5-1,0.9c-0.3,0.4-0.4,0.8-0.4,1.3
                      c0,0.5,0,0.9,0.2,1.4l35.1,80.3c0.4,1,1.1,1.9,2,2.6c0.9,0.7,1.9,1.1,3,1.3l24.3,3.9L858.2,303.9z"/>
                    <path className="st9" d="M835.5,391.1h-76.5c-1.3,0-2.6-0.5-3.5-1.5c-0.9-0.9-1.5-2.2-1.5-3.5v-75c0-1.3,0.5-2.6,1.5-3.5
                      c0.9-0.9,2.2-1.5,3.5-1.5h48.2c1,0,2,0.3,2.8,0.9c0.8,0.6,1.5,1.4,1.8,2.3l28.3,75c0.3,0.8,0.4,1.6,0.3,2.4
                      c-0.1,0.8-0.4,1.6-0.8,2.2c-0.5,0.7-1.1,1.2-1.8,1.6C837.1,391,836.3,391.1,835.5,391.1L835.5,391.1z"/>
                    <path className="st10" d="M706.4,391.4h36.1c0.6,0,1.2-0.2,1.6-0.7c0.4-0.4,0.7-1,0.7-1.6v-80.6c0-0.6-0.2-1.2-0.7-1.6
                      c-0.4-0.4-1-0.7-1.6-0.7h-32.3c-0.6,0-1.1,0.2-1.6,0.6s-0.7,1-0.7,1.5l-3.8,80.6c0,0.3,0,0.6,0.1,0.9c0.1,0.3,0.3,0.6,0.5,0.8
                      c0.2,0.2,0.5,0.4,0.8,0.5C705.8,391.4,706.1,391.4,706.4,391.4L706.4,391.4z"/>
                    <path className="st5" d="M887,398.1c-0.1,0-0.2,0-0.4,0l-24.3-3.9c-1.5-0.2-2.9-0.8-4-1.7c-1.2-0.9-2.1-2.1-2.7-3.5l-35.1-80.3
                      c-0.3-0.8-0.5-1.6-0.4-2.4s0.3-1.6,0.8-2.3s1.1-1.3,1.8-1.7c0.7-0.4,1.6-0.6,2.4-0.6h33c0.5,0,0.9,0.2,1.3,0.4
                      c0.4,0.3,0.7,0.7,0.8,1.1l28.8,92c0.1,0.3,0.1,0.7,0.1,1c-0.1,0.3-0.2,0.7-0.4,1c-0.2,0.3-0.5,0.5-0.8,0.7
                      C887.7,398,887.4,398.1,887,398.1L887,398.1z M825.2,306.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.2,0.2c-0.1,0.1-0.1,0.2-0.1,0.3
                      c0,0.1,0,0.2,0.1,0.3l35.1,80.3c0.3,0.7,0.8,1.3,1.3,1.7c0.6,0.5,1.3,0.8,2,0.9l20.7,3.3l-27.2-87H825.2z"/>
                    <path className="st11" d="M886.9,412.9h-8.6v64h8.6V412.9z"/>
                    <path className="st7" d="M889.1,496.5h-22.7c-2.5,0-4.4,2-4.4,4.4v59.7c0,2.5,2,4.4,4.4,4.4h22.7c2.5,0,4.4-2,4.4-4.4v-59.7
                      C893.5,498.5,891.6,496.5,889.1,496.5z"/>
                    <path className="st10" d="M862,560.6c0,1.2,0.5,2.3,1.3,3.1c0.8,0.8,2,1.3,3.1,1.3h22.7c1.2,0,2.3-0.5,3.1-1.3c0.8-0.8,1.3-2,1.3-3.1
                      H862z"/>
                    <path className="st10" d="M844.8,493.2c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3v-96.6c0-0.5,0.2-0.9,0.5-1.3
                      c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3v96.6c0,0.2,0,0.5-0.1,0.7c-0.1,0.2-0.2,0.4-0.4,0.6
                      c-0.2,0.2-0.4,0.3-0.6,0.4C845.3,493.2,845,493.2,844.8,493.2z"/>
                    <path className="st10" d="M754.1,451.6c-0.5,0-0.9-0.2-1.3-0.5c-0.3-0.3-0.5-0.8-0.5-1.3v-52.2c0-0.5,0.2-0.9,0.5-1.3
                      c0.3-0.3,0.8-0.5,1.3-0.5c0.5,0,0.9,0.2,1.3,0.5c0.3,0.3,0.5,0.8,0.5,1.3v52.2c0,0.5-0.2,0.9-0.5,1.3
                      C755,451.4,754.6,451.6,754.1,451.6z"/>
                    <path className="st8" d="M777.8,459.1h-23.7v9.2h23.7V459.1z"/>
                    <path className="st7" d="M825.1,288.5H719.2v-3.9c0-1.2,0.5-2.3,1.3-3.1c0.8-0.8,2-1.3,3.1-1.3h82c4.8,0,9.4,1.4,13.4,4.1L825.1,288.5
                      z"/>
                    <path className="st10" d="M819,284.3l-99.8,0.3v3.9h105.9L819,284.3z"/>
                    <path className="st7" d="M240.1,532.5H92.1c-1.6,0-3.1-0.6-4.2-1.7c-1.1-1.1-1.7-2.6-1.7-4.2v-7c0-1.4,0.5-2.8,1.4-3.8l200-234.2
                      c0.4-0.4,0.8-0.8,1.3-1c0.5-0.2,1.1-0.4,1.6-0.4h14.7c0.6,0,1.2,0.1,1.7,0.4c0.5,0.3,1,0.6,1.4,1.1c0.4,0.5,0.6,1,0.7,1.6
                      c0.1,0.6,0.1,1.2,0,1.8L248.5,526c-0.5,1.9-1.5,3.5-3.1,4.7C243.9,531.8,242,532.5,240.1,532.5L240.1,532.5z"/>
                    <path className="st7" d="M228.5,541H79c-3.7,0-6.7,3-6.7,6.7v26.1c0,3.7,3,6.7,6.7,6.7h149.5c3.7,0,6.7-3,6.7-6.7v-26.1
                      C235.1,544,232.1,541,228.5,541z"/>
                    <path className="st10" d="M72.3,573.8c0,1.8,0.7,3.5,2,4.7c1.3,1.2,2.9,2,4.7,2h149.5c1.8,0,3.5-0.7,4.7-2c1.2-1.2,2-2.9,2-4.7H72.3z"
                      />
                    <path className="st7" d="M206.1,514.7c-1.6-1.1-3.5-1.6-5.5-1.4c-1.9,0.2-3.7,1-5.1,2.4c-1.4,1.4-2.2,3.2-2.5,5.1
                      c-0.2,1.9,0.3,3.9,1.3,5.5l-14.3,21.4c-1.9-0.4-3.9,0-5.6,0.9c-1.7,0.9-3,2.4-3.8,4.2c-0.7,1.8-0.8,3.8-0.3,5.6
                      c0.6,1.9,1.8,3.5,3.4,4.5c1.6,1.1,3.5,1.6,5.5,1.4c1.9-0.2,3.7-1,5.1-2.4c1.4-1.4,2.2-3.1,2.5-5.1c0.2-1.9-0.2-3.9-1.3-5.5
                      l14.3-21.4c1.9,0.4,3.9,0,5.6-0.9c1.7-0.9,3-2.4,3.8-4.2c0.7-1.8,0.8-3.8,0.3-5.6C209,517.3,207.8,515.7,206.1,514.7L206.1,514.7z
                      "/>
                    <path className="st10" d="M199.8,513.4c1.1-0.2,2.2-0.2,3.3,0c1.1,0.2,2.1,0.6,3,1.3c0.9,0.6,1.7,1.4,2.3,2.3c0.6,0.9,1,1.9,1.3,3
                      c0.2,1.1,0.2,2.2,0,3.3c-0.2,1.1-0.6,2.1-1.3,3c-0.6,0.9-1.4,1.7-2.3,2.3c-0.9,0.6-1.9,1-3,1.3c-1,0.2-2.1,0.2-3.2,0l-14.3,21.4
                      c1,1.6,1.5,3.6,1.3,5.5c-0.2,1.9-1.1,3.7-2.5,5.1c-1.4,1.4-3.2,2.2-5.1,2.4c-1.9,0.2-3.8-0.3-5.4-1.4c-1.6-1.1-2.8-2.7-3.4-4.5
                      c-0.6-1.8-0.5-3.8,0.3-5.6s2-3.3,3.7-4.2c1.7-0.9,3.7-1.2,5.6-0.9l14.3-21.4c-0.7-1.2-1.2-2.5-1.3-3.8c-0.1-1.4,0.1-2.7,0.6-4
                      c0.5-1.3,1.4-2.4,2.4-3.2C197.2,514.2,198.5,513.6,199.8,513.4L199.8,513.4z M199.6,512.3c-1.5,0.3-2.8,0.9-4,1.8
                      c-1.2,0.9-2.1,2.1-2.7,3.4c-0.6,1.3-0.9,2.8-0.9,4.3c0,1.5,0.4,2.9,1.1,4.2l-13.6,20.3c-1-0.1-1.9-0.1-2.9,0.1
                      c-2,0.4-3.7,1.4-5.1,2.8c-1.4,1.5-2.2,3.3-2.5,5.3c-0.2,2,0.1,4,1.1,5.7c1,1.7,2.5,3.1,4.3,4c1.8,0.8,3.8,1.1,5.8,0.7
                      c2-0.4,3.7-1.4,5.1-2.8c1.4-1.5,2.2-3.3,2.5-5.3s-0.1-4-1.1-5.7l13.6-20.3c1.4,0.2,2.7,0,4-0.4c1.3-0.4,2.5-1.1,3.5-2.1
                      s1.8-2.1,2.3-3.3c0.5-1.3,0.8-2.6,0.7-4c-0.1-1.4-0.4-2.7-1-3.9c-0.6-1.2-1.5-2.3-2.6-3.1c-1.1-0.8-2.3-1.4-3.7-1.8
                      C202.4,512.1,201,512.1,199.6,512.3L199.6,512.3z"/>
                    <path className="st10" d="M204.7,523.8c-0.4,0.6-1.1,1.2-1.8,1.5c-0.7,0.3-1.5,0.4-2.3,0.2c-0.8-0.2-1.5-0.5-2-1.1
                      c-0.6-0.6-0.9-1.3-1.1-2c-0.2-0.8-0.1-1.6,0.2-2.3c0.3-0.7,0.8-1.3,1.5-1.8c0.6-0.4,1.4-0.7,2.2-0.7c0.8,0,1.5,0.2,2.2,0.7
                      c0.4,0.3,0.8,0.7,1.1,1.1c0.3,0.4,0.5,0.9,0.6,1.4c0.1,0.5,0.1,1,0,1.5C205.2,522.9,205,523.4,204.7,523.8L204.7,523.8z"/>
                    <path className="st10" d="M181.8,558c-0.4,0.6-1.1,1.2-1.8,1.5c-0.7,0.3-1.5,0.4-2.3,0.2c-0.8-0.2-1.5-0.5-2-1.1
                      c-0.6-0.6-0.9-1.3-1.1-2c-0.2-0.8-0.1-1.6,0.2-2.3c0.3-0.7,0.8-1.3,1.5-1.8c0.7-0.4,1.4-0.7,2.2-0.7c0.8,0,1.5,0.2,2.2,0.7
                      c0.9,0.6,1.5,1.5,1.7,2.5C182.6,556.1,182.4,557.2,181.8,558L181.8,558z"/>
                    <path className="st10" d="M185.6,551.3l14.3-21.4c-1.1-0.2-2.2-0.6-3.1-1.3l-13.6,20.2C184.2,549.5,185,550.3,185.6,551.3L185.6,551.3
                      z"/>
                    <path className="st7" d="M155.2,514.7c-1.6-1.1-3.5-1.6-5.5-1.4c-1.9,0.2-3.7,1-5.1,2.4c-1.4,1.4-2.2,3.2-2.5,5.1
                      c-0.2,1.9,0.3,3.9,1.3,5.5l-14.3,21.4c-1.9-0.4-3.9,0-5.6,0.9c-1.7,0.9-3,2.4-3.8,4.2c-0.7,1.8-0.8,3.8-0.3,5.6
                      c0.6,1.9,1.8,3.5,3.4,4.5c1.6,1.1,3.5,1.6,5.5,1.4c1.9-0.2,3.7-1,5.1-2.4c1.4-1.4,2.3-3.1,2.5-5.1c0.2-1.9-0.2-3.9-1.3-5.5
                      l14.3-21.4c1.9,0.4,3.9,0,5.6-0.9c1.7-0.9,3-2.4,3.8-4.2c0.7-1.8,0.8-3.8,0.3-5.6C158,517.3,156.8,515.7,155.2,514.7L155.2,514.7z
                      "/>
                    <path className="st10" d="M148.9,513.4c1.1-0.2,2.2-0.2,3.3,0c1.1,0.2,2.1,0.6,3,1.3c0.9,0.6,1.7,1.4,2.3,2.3s1,1.9,1.3,3
                      c0.2,1.1,0.2,2.2,0,3.3c-0.2,1.1-0.6,2.1-1.3,3c-0.6,0.9-1.4,1.7-2.3,2.3c-0.9,0.6-1.9,1-3,1.3c-1,0.2-2.1,0.2-3.2,0l-14.3,21.4
                      c1,1.5,1.4,3.3,1.3,5.1c-0.1,1.8-0.8,3.5-2,4.9c-1.2,1.4-2.7,2.3-4.5,2.8c-1.7,0.4-3.6,0.3-5.2-0.4c-1.7-0.7-3-1.9-4-3.5
                      c-0.9-1.5-1.3-3.4-1.1-5.1c0.2-1.8,0.9-3.5,2.2-4.8c1.2-1.3,2.8-2.2,4.6-2.6c1-0.2,2.1-0.2,3.2,0l14.3-21.4
                      c-0.8-1.2-1.2-2.5-1.3-3.8c-0.1-1.4,0.1-2.8,0.6-4s1.4-2.4,2.4-3.3C146.2,514.2,147.5,513.6,148.9,513.4z M148.7,512.3
                      c-1.5,0.3-2.8,0.9-4,1.8c-1.2,0.9-2.1,2.1-2.7,3.4c-0.6,1.3-0.9,2.8-0.9,4.3s0.4,2.9,1.2,4.2l-13.6,20.3c-1-0.1-1.9-0.1-2.9,0.1
                      c-2,0.4-3.7,1.4-5.1,2.8c-1.4,1.5-2.2,3.3-2.5,5.3c-0.2,2,0.1,4,1.1,5.7c1,1.7,2.5,3.1,4.3,4c1.8,0.8,3.8,1.1,5.8,0.7
                      c2-0.4,3.7-1.4,5.1-2.8c1.4-1.5,2.2-3.3,2.5-5.3c0.3-2-0.1-4-1.1-5.7l13.6-20.3c1.4,0.2,2.7,0,4-0.4c1.3-0.4,2.5-1.1,3.5-2.1
                      s1.8-2.1,2.3-3.3c0.5-1.3,0.8-2.6,0.7-4c-0.1-1.4-0.4-2.7-1-3.9c-0.6-1.2-1.5-2.3-2.6-3.1c-1.1-0.8-2.3-1.4-3.7-1.8
                      C151.4,512.1,150,512.1,148.7,512.3L148.7,512.3z"/>
                    <path className="st10" d="M153.8,523.8c-0.4,0.6-1.1,1.2-1.8,1.5c-0.7,0.3-1.5,0.4-2.3,0.2c-0.8-0.2-1.5-0.5-2-1.1
                      c-0.6-0.6-0.9-1.3-1.1-2c-0.2-0.8-0.1-1.6,0.2-2.3c0.3-0.7,0.8-1.3,1.5-1.8c0.6-0.4,1.4-0.7,2.2-0.7c0.8,0,1.5,0.2,2.2,0.7
                      c0.4,0.3,0.8,0.7,1.1,1.1c0.3,0.4,0.5,0.9,0.6,1.4c0.1,0.5,0.1,1,0,1.5C154.3,522.9,154.1,523.4,153.8,523.8L153.8,523.8z"/>
                    <path className="st10" d="M130.9,558c-0.4,0.6-1.1,1.2-1.8,1.5c-0.7,0.3-1.5,0.4-2.3,0.2s-1.5-0.5-2-1.1c-0.6-0.6-0.9-1.3-1.1-2
                      c-0.2-0.8-0.1-1.6,0.2-2.3c0.3-0.7,0.8-1.3,1.5-1.8c0.6-0.4,1.4-0.7,2.2-0.7c0.8,0,1.5,0.2,2.2,0.7c0.9,0.6,1.5,1.5,1.7,2.5
                      S131.4,557.2,130.9,558L130.9,558z"/>
                    <path className="st10" d="M134.7,551.3l14.3-21.4c-1.1-0.2-2.2-0.6-3.1-1.3l-13.6,20.2C133.2,549.5,134,550.3,134.7,551.3L134.7,551.3
                      z"/>
                    <path className="st10" d="M290.1,293.1H302c0.2,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.6,0.5c0.1,0.2,0.3,0.4,0.3,0.7c0,0.2,0,0.5,0,0.7
                      l-47.9,177.3c-0.5,2-1.7,3.8-3.4,5.1c-1.7,1.3-3.7,2-5.8,2l-106.2,0.2c-0.7,0-1.4-0.2-2-0.6c-0.6-0.4-1-0.9-1.3-1.5
                      c-0.3-0.6-0.4-1.3-0.3-2c0.1-0.7,0.4-1.3,0.8-1.9l151.5-180.1c0.2-0.2,0.3-0.3,0.6-0.4C289.6,293.1,289.9,293.1,290.1,293.1
                      L290.1,293.1z"/>
                    <path className="st10" d="M257.6,489.6l50.6-201.5h3.8l-49.8,201.5H257.6z"/>
                    <path className="st10" d="M554,284.6h-3.8c-0.9,0-1.8,0.4-2.4,1c-0.6,0.6-1,1.5-1,2.4v201.5h10.7V288c0-0.4-0.1-0.9-0.3-1.3
                      s-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7C554.9,284.7,554.4,284.6,554,284.6z"/>
                    <path className="st10" d="M465.1,284.6h-3.8c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.7-0.7,1.1
                      c-0.2,0.4-0.3,0.9-0.3,1.3v201.5h10.7V288c0-0.4-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7
                      C466,284.7,465.5,284.6,465.1,284.6z"/>
                    <path className="st10" d="M371.7,284.6h-3.8c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.7-0.7,1.1
                      c-0.2,0.4-0.3,0.9-0.3,1.3v201.5h10.7V288c0-0.4-0.1-0.9-0.3-1.3s-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7
                      C372.6,284.7,372.2,284.6,371.7,284.6z"/>
                    <path className="st7" d="M549.5,284.6h-3.8c-0.9,0-1.8,0.4-2.4,1c-0.6,0.6-1,1.5-1,2.4v201.5h10.7V288c0-0.4-0.1-0.9-0.3-1.3
                      c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7C550.4,284.7,550,284.6,549.5,284.6z"/>
                    <path className="st7" d="M460.6,284.6h-3.9c-0.9,0-1.8,0.4-2.4,1c-0.6,0.6-1,1.5-1,2.4v201.5H464V288c0-0.4-0.1-0.9-0.3-1.3
                      c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7C461.5,284.7,461.1,284.6,460.6,284.6L460.6,284.6z"/>
                    <path className="st7" d="M367.3,284.6h-3.8c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.7-0.7,1.1
                      c-0.2,0.4-0.3,0.9-0.3,1.3v201.5h10.7V288c0-0.4-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7
                      C368.2,284.7,367.7,284.6,367.3,284.6z"/>
                    <path className="st7" d="M497.7,473.4H285.5c-3.4,0-6.7,1.4-9.1,3.8c-2.4,2.4-3.8,5.7-3.8,9.1v77.4c0,3.2,1.2,6.2,3.2,8.6
                      c2.1,2.4,5,3.9,8.1,4.2v-30c0-11.6,3.8-22.8,10.7-32.1c7-9.2,16.7-16,27.8-19.2c11.1-3.2,23-2.7,33.8,1.4
                      c10.8,4.1,20,11.6,26.2,21.4h16c6.2-9.8,15.3-17.3,26.1-21.4c10.8-4.1,22.7-4.6,33.8-1.4c11.1,3.2,20.9,9.9,27.8,19.2
                      c7,9.2,10.7,20.5,10.7,32.1v30.2h0.8c3.4,0,6.7-1.4,9.1-3.8c2.4-2.4,3.8-5.7,3.8-9.1v-77.4c0-1.7-0.3-3.4-1-4.9
                      c-0.6-1.6-1.6-3-2.8-4.2c-1.2-1.2-2.6-2.1-4.2-2.8C501,473.7,499.4,473.4,497.7,473.4L497.7,473.4z"/>
                    <path className="st10" d="M496.9,560.6v15.9h0.8c3.4,0,6.7-1.4,9.1-3.8c2.4-2.4,3.8-5.7,3.8-9.1v-3H496.9z"/>
                    <path className="st10" d="M272.6,560.6v3c0,3.2,1.2,6.2,3.2,8.6c2.1,2.4,5,3.9,8.1,4.2v-15.8H272.6z"/>
                    <path className="st10" d="M510.6,486.3v3.3h8.9v-3.3c0-3.4-1.4-6.7-3.8-9.1c-2.4-2.4-5.7-3.8-9.1-3.8h-8.9c3.4,0,6.7,1.4,9.1,3.8
                      C509.2,479.6,510.6,482.8,510.6,486.3L510.6,486.3z"/>
                    <path className="st10" d="M237.3,340.3V288c0-0.4-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7
                      c-0.4-0.2-0.9-0.3-1.3-0.3h-3.8c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.7-0.7,1.1s-0.3,0.9-0.3,1.3v64.8
                      L237.3,340.3z"/>
                    <path className="st7" d="M232.9,345.5V288c0-0.4-0.1-0.9-0.3-1.3c-0.2-0.4-0.4-0.8-0.7-1.1c-0.3-0.3-0.7-0.6-1.1-0.7
                      c-0.4-0.2-0.9-0.3-1.3-0.3h-3.8c-0.4,0-0.9,0.1-1.3,0.3c-0.4,0.2-0.8,0.4-1.1,0.7c-0.3,0.3-0.6,0.7-0.7,1.1
                      c-0.2,0.4-0.3,0.9-0.3,1.3v70L232.9,345.5z"/>
                    <path className="st11" d="M755.5,257.4h9.6c2.2,0,4.3,0.9,5.9,2.4c1.6,1.6,2.4,3.7,2.4,5.9v14.5h-26.2v-14.5c0-2.2,0.9-4.3,2.4-5.9
                      C751.2,258.2,753.3,257.4,755.5,257.4L755.5,257.4z"/>
                    <path className="st12" d="M754.1,272c0-1.1,0.2-2.2,0.6-3.2c0.4-1,1-1.9,1.8-2.7c0.8-0.8,1.7-1.4,2.7-1.8c1-0.4,2.1-0.6,3.2-0.6h9.6
                      c0.4,0,0.8,0,1.1,0.1c-0.4-1.8-1.5-3.4-2.9-4.6c-1.5-1.2-3.3-1.8-5.1-1.8h-9.6c-1.1,0-2.2,0.2-3.2,0.6c-1,0.4-1.9,1-2.7,1.8
                      c-0.8,0.8-1.4,1.7-1.8,2.7c-0.4,1-0.6,2.1-0.6,3.2v14.5h7V272z"/>
                    <path className="st13" d="M110.8,497.9h-7.9l5.5-6.3l7.9,0L110.8,497.9z"/>
                    <path className="st11" d="M116.2,491.5l-7.9,0l5.5-6.3l7.8,0L116.2,491.5z"/>
                    <path className="st13" d="M121.7,485.2l-7.8,0l5.4-6.2l7.8,0L121.7,485.2z"/>
                    <path className="st11" d="M127.1,479l-7.8,0l5.4-6.2l7.8,0L127.1,479z"/>
                    <path className="st13" d="M132.4,472.7l-7.8,0l5.3-6.1l7.8,0L132.4,472.7z"/>
                    <path className="st11" d="M137.8,466.6l-7.8,0l5.3-6.1l7.8-0.1L137.8,466.6z"/>
                    <path className="st13" d="M143,460.5l-7.8,0.1l5.3-6.1l7.7-0.1L143,460.5z"/>
                    <path className="st11" d="M148.3,454.4l-7.7,0.1l5.2-6l7.7-0.1L148.3,454.4z"/>
                    <path className="st13" d="M153.5,448.4l-7.7,0.1l5.2-6l7.7-0.1L153.5,448.4z"/>
                    <path className="st11" d="M158.6,442.4l-7.7,0.1l5.1-5.9l7.7-0.1L158.6,442.4z"/>
                    <path className="st13" d="M163.7,436.5l-7.7,0.1l5.1-5.9l7.6-0.1L163.7,436.5z"/>
                    <path className="st11" d="M168.8,430.6l-7.6,0.1l5.1-5.8l7.6-0.1L168.8,430.6z"/>
                    <path className="st13" d="M173.9,424.8l-7.6,0.1l5-5.8l7.6-0.1L173.9,424.8z"/>
                    <path className="st11" d="M178.9,419l-7.6,0.1l5-5.7l7.6-0.1L178.9,419z"/>
                    <path className="st13" d="M183.8,413.2l-7.6,0.1l5-5.7l7.6-0.1L183.8,413.2z"/>
                    <path className="st11" d="M188.8,407.5l-7.6,0.1l4.9-5.7l7.5-0.1L188.8,407.5z"/>
                    <path className="st10" d="M634.1,445.1h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9
                      S634.8,445.1,634.1,445.1z"/>
                    <path className="st10" d="M545.2,445.1H464v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9
                      S545.9,445.1,545.2,445.1z"/>
                    <path className="st10" d="M451.9,445.1h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9
                      C453.3,444.8,452.6,445.1,451.9,445.1z"/>
                    <path className="st10" d="M363,445.1h-92.3l1.3-5.3h91c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9c0,0.7-0.3,1.4-0.8,1.9
                      S363.7,445.1,363,445.1z"/>
                    <path className="st10" d="M634.1,400.7h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      S634.8,400.7,634.1,400.7z"/>
                    <path className="st10" d="M545.2,400.7H464v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      S545.9,400.7,545.2,400.7z"/>
                    <path className="st10" d="M451.9,400.7h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C453.3,400.4,452.6,400.7,451.9,400.7z"/>
                    <path className="st10" d="M363,400.7h-82.1l1.3-5.3H363c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C364.4,400.4,363.7,400.7,363,400.7z"/>
                    <path className="st10" d="M634.1,356.2h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C635.5,355.9,634.8,356.2,634.1,356.2z"/>
                    <path className="st10" d="M545.2,356.2H464v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C546.6,355.9,545.9,356.2,545.2,356.2z"/>
                    <path className="st10" d="M451.9,356.2h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C453.3,355.9,452.6,356.2,451.9,356.2z"/>
                    <path className="st10" d="M363,356.2h-71.4l1.3-5.3H363c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C364.4,355.9,363.7,356.2,363,356.2z"/>
                    <path className="st10" d="M634.1,311.8h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C635.5,311.5,634.8,311.8,634.1,311.8z"/>
                    <path className="st10" d="M545.2,311.8H464v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C546.6,311.5,545.9,311.8,545.2,311.8z"/>
                    <path className="st10" d="M451.9,311.8h-81.2v-5.3h81.2c0.7,0,1.4,0.3,1.9,0.8c0.5,0.5,0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C453.3,311.5,452.6,311.8,451.9,311.8z"/>
                    <path className="st10" d="M363,311.8h-59.6l1.3-5.3H363c0.7,0,1.4,0.3,1.9,0.8s0.8,1.2,0.8,1.9s-0.3,1.4-0.8,1.9
                      C364.4,311.5,363.7,311.8,363,311.8z"/>
                    <path className="st13" d="M859.8,412.9h-3.7c-1.7,0-3.1,1.4-3.1,3.1v8.3c0,1.7,1.4,3.1,3.1,3.1h3.7c1.7,0,3.1-1.4,3.1-3.1V416
                      C862.9,414.3,861.5,412.9,859.8,412.9z"/>
                    <path className="st7" d="M646.5,510H537.1c-5,0-9.1,4.1-9.1,9.1v32.4c0,5,4.1,9.1,9.1,9.1h109.3c5,0,9.1-4.1,9.1-9.1v-32.4
                      C655.6,514.1,651.5,510,646.5,510z"/>
                    <path className="st10" d="M528,551.5L528,551.5c0,2.5,1,4.8,2.7,6.5c1.7,1.7,4,2.7,6.4,2.7h109.3c2.4,0,4.7-1,6.4-2.7s2.7-4,2.7-6.4v0
                      H528z"/>
                    <path className="st10" d="M556.6,508.6h3c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.4,0.6,0.4,0.9v41.5h-5.6V510c0-0.4,0.1-0.7,0.4-0.9
                      C555.9,508.8,556.2,508.6,556.6,508.6z"/>
                    <path className="st10" d="M627.7,508.6h3c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.4,0.6,0.4,0.9v41.5h-5.6V510c0-0.4,0.1-0.7,0.4-0.9
                      C627,508.8,627.3,508.6,627.7,508.6z"/>
                    <path className="st7" d="M554.8,508.6h3c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.4,0.6,0.4,0.9v41.5h-5.6V510c0-0.4,0.1-0.7,0.4-0.9
                      C554.1,508.8,554.4,508.6,554.8,508.6z"/>
                    <path className="st7" d="M625.9,508.6h3c0.4,0,0.7,0.1,0.9,0.4c0.2,0.3,0.4,0.6,0.4,0.9v41.5h-5.6V510c0-0.4,0.1-0.7,0.4-0.9
                      C625.2,508.8,625.5,508.6,625.9,508.6z"/>
                    <path className="st10" d="M543.7,527.8h-2.9c-3.3,0-5.9,2.9-5.9,6.4c0,3.5,2.7,6.4,5.9,6.4h2.9c3.3,0,5.9-2.9,5.9-6.4
                      C549.6,530.7,547,527.8,543.7,527.8z"/>
                    <path className="st7" d="M544.5,534.2c0,3.1-2,5.7-4.4,5.7c-2.4,0-4.4-2.5-4.4-5.7s2-5.7,4.4-5.7C542.5,528.5,544.5,531.1,544.5,534.2
                      z"/>
                    <path className="st10" d="M690.8,576.5h-17.9c-1.7,0-3.4-0.7-4.7-1.9c-1.2-1.2-1.9-2.9-1.9-4.7h31.1c0,1.7-0.7,3.4-1.9,4.7
                      C694.2,575.8,692.5,576.5,690.8,576.5L690.8,576.5z"/>
                  </g>
                </a>
                <a className='cursor-default men2'>
                  <g>
                    <path className="st14" d="M687.2,576c0,0,4.2,3.9,3.4,6.7c-0.8,2.8-4.6,4.6-5.2,8.1c-0.5,3.4-2.6,5.3-4.4,5.3h-8.9
                      c0,0-1.4-2.4,1.9-3.1c3.3-0.7,4.6-4.7,4.3-8.4c-0.1-2.9,0-5.8,0.3-8.7l7-1.7L687.2,576z"/>
                    <path className="st14" d="M635.2,586.1c0,0,0.8,8.9-1.1,9.9h-23.8c0,0,0-2.8,3.8-3.5c3.8-0.6,10.5-2.9,11.7-8
                      C627,579.5,635.2,586.1,635.2,586.1z"/>
                    <path className="st15" d="M615.1,459.9c0,0,1.3,15.7,2.7,34.5c2,25.6,4.4,57,4.7,62.9c0.4,10.3,2.1,26.9,2.1,26.9s3.9,3.9,11.8,2.3
                      c0,0,7-23.6,9-48.6c0-0.4,0.1-0.8,0.1-1.2c1.9-25.4,4.8-78.9,4.8-78.9L615.1,459.9z"/>
                    <path className="st16" d="M615.1,459.9c0,0,1.3,15.7,2.7,34.5l27.5,43.5c0-0.4,0.1-0.8,0.1-1.2c1.9-25.4,4.8-78.9,4.8-78.9
                      L615.1,459.9z"/>
                    <path className="st15" d="M620.9,461.7c0,0,22.6,56.7,37.5,83.4c14.9,26.7,19.5,34.1,19.5,34.1s8.5-3.4,9.3-5.7c0,0-5.7-51.5-15.7-64
                      c-5.6-7.1-12.2-28.4-12.6-35.8c-0.3-7.4-2-22.2-2-22.2L620.9,461.7z"/>
                    <path className="st17" d="M650.6,374.3c0,0-15.3,7.5-24.1,4.7c1.7-2,3.7-3.6,6-4.9c-0.3-1.8-0.5-3.4-0.7-4.7c-0.2-1.6-0.3-3.2-0.4-4.8
                      l5.9-4.7l5.6-4.5C642.5,368.2,650.6,374.3,650.6,374.3z"/>
                    <path className="st18" d="M658.7,454.4c0,1-2,2-2.8,2.4c-6.6,2.9-12.7,5-19.6,5.6c-8.2,0.8-17.9-1-21.2-1c-0.3,0-0.7-0.1-0.9-0.4
                      c-0.2-0.2-0.4-10.2-0.2-22.8c0-0.5,0-1,0-1.5v-0.2c0.1-4.9,0.2-10.1,0.5-15.2c0.5-11.3,1.3-22.3,2.8-28.3
                      c1.8-7.1,5.9-11.8,9.6-15.3l3.1-2.7c3.2,0.7,6.6,0.3,9.5-1.2c3.5-1.7,7.4-3.7,8-3.3c4.6,3.4,7.7,11.3,9.3,21.1
                      c1,6.2,1.5,12.4,1.4,18.7c0,2.4-0.1,5-0.2,7.4c-0.8,13.4,0,25.1,0.5,31.9C658.6,451.8,658.7,453.3,658.7,454.4z"/>
                    <path className="st19" d="M658.4,449.8c-74.2-0.1-13.7-55.3-13.7-55.3l12-2.7c1,6.2,1.5,12.4,1.4,18.7c0,2.4-0.1,5-0.2,7.4
                      C657.1,431.4,658,443.1,658.4,449.8z"/>
                    <path className="st20" d="M625.4,413.5l-0.2,5.6l-0.2,5.2l-0.7,17.9l-10.5,18.2c0,0-7.9,8.4-28.1,8.4c-25.3,0-29-13.9-29-13.9
                      l0.3-22.5l9.3-13.3l-4.6-13.3l11.4-20.4l15.1-8l9.6,2.5l8-1.9l12.7,5.9L625.4,413.5z"/>
                    <path className="st21" d="M597,378l-2.3-7.3c-0.1-0.3-0.3-0.5-0.5-0.7c-0.2-0.2-0.5-0.2-0.8-0.2c-2,0.1-6.5,0.4-6.5,0.8
                      C586.9,371.1,597,378,597,378z"/>
                    <path className="st21" d="M598.2,378.3l0.3-5c0-0.2,0.1-0.3,0.1-0.4c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.3-0.1,0.4-0.2
                      c0.2,0,0.3,0,0.5,0l2.1,0.6c0.3,0.1,0.6,0.3,0.9,0.6c0.2,0.3,0.4,0.6,0.4,0.9c0,0.3,0,0.7-0.2,1c-0.2,0.3-0.4,0.6-0.7,0.7
                      L598.2,378.3z"/>
                    <path className="st21" d="M595.1,378.4l0.4-0.8c0.1-0.2,0.2-0.3,0.4-0.4c0.2-0.1,0.4-0.2,0.6-0.2l2.9,0c0.2,0,0.4,0,0.5,0.1
                      c0.2,0.1,0.3,0.2,0.4,0.3l0.4,0.6c0.1,0.1,0.2,0.3,0.2,0.4c0,0.1,0,0.3,0,0.4l-0.1,0.4c0,0.3-0.2,0.5-0.4,0.7
                      c-0.2,0.2-0.5,0.3-0.8,0.3l-3.7-0.2c-0.2,0-0.4-0.1-0.5-0.2c-0.2-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.1-0.4-0.1-0.5
                      C595,378.8,595,378.6,595.1,378.4z"/>
                    <path className="st21" d="M572.9,385.4l6,15.9c0.1,0.2,0.2,0.4,0.4,0.5s0.4,0.2,0.6,0.2c0.2,0,0.4-0.1,0.6-0.2
                      c0.2-0.1,0.3-0.3,0.4-0.5l2.5-5.4c0.2-0.4,0.3-0.9,0.1-1.4c-0.1-0.5-0.4-0.9-0.8-1.2L572.9,385.4z"/>
                    <path className="st21" d="M566.1,419.1c0,0,7,9.7,5.5,11.1c-1.4,1.4-14.8,2.2-14.8,2.2L566.1,419.1z"/>
                    <path className="st21" d="M624.2,442.2c0,0-27.6,9.2-26,12.4c1.5,3.3,15.5,5.8,15.5,5.8L624.2,442.2z"/>
                    <path className="st21" d="M625.2,419.2l-0.2,5.2l-25.6,2.5c-0.2,0-0.4,0-0.6-0.1c-0.2-0.1-0.3-0.3-0.4-0.5c-0.1-0.2-0.1-0.4,0-0.6
                      c0.1-0.2,0.2-0.4,0.4-0.5l15.8-10c0.5-0.3,1.1-0.5,1.7-0.5c0.6,0,1.2,0.1,1.7,0.4L625.2,419.2z"/>
                    <path className="st21" d="M594.6,381.3l-6.9,1.8c-0.3,0.1-0.5,0.2-0.7,0.4c-0.2,0.2-0.4,0.4-0.5,0.7c-0.1,0.3-0.1,0.5-0.1,0.8
                      c0,0.3,0.1,0.5,0.3,0.8l0.8,1.3c0.1,0.2,0.3,0.3,0.4,0.4c0.2,0.1,0.4,0.2,0.6,0.2c0.2,0,0.4,0,0.6-0.1c0.2-0.1,0.4-0.2,0.5-0.4
                      L594.6,381.3z"/>
                    <path className="st21" d="M598.2,381.1l6.9-1.2c0.2,0,0.5,0,0.7,0c0.2,0.1,0.4,0.1,0.6,0.3l2.8,2c0.1,0.1,0.2,0.2,0.2,0.3
                      c0,0.1,0,0.3,0,0.4c-0.1,0.1-0.1,0.2-0.3,0.3c-0.1,0.1-0.2,0.1-0.4,0.1L598.2,381.1z"/>
                    <path className="st22" d="M606.4,427.1l-2.7-0.9c0,0-1.9-6.4-8.4-3.5c0,0-2.6,1.4-1.5,3.1c1.2,1.7,1,0.8,2.5,3.2
                      c1.6,2.4,4.1,3.5,6.1,2.6l2.3,0.1L606.4,427.1z"/>
                    <path className="st18" d="M650.6,374.3c0,0,16.8,26.7-1.1,57.6c-5.6,9.6-47.8,0.5-47.8,0.5s-1.6-3,2.8-6.1c0,0,28.1-4.8,31.4-5.6
                      c3-0.7,1-28,4.4-35.9C642.3,380.1,646,376.4,650.6,374.3z"/>
                    <path className="st23" d="M643,360.3c0,0-3.9,7.2-11.2,9.2c-0.2-1.6-0.3-3.2-0.4-4.8l5.9-4.7L643,360.3z"/>
                    <path className="st17" d="M628.7,364.5c0,0,14.6,2.4,16-6.7c1.4-9.1,4.2-14.9-5.1-16.9c-9.3-1.9-11.6,1.3-12.8,4.2
                      C625.6,348.1,625,363.6,628.7,364.5z"/>
                    <path className="st18" d="M650,339.8c0.1-0.2,0.4-0.3,1.1-0.3c-0.4-0.2-0.8-0.3-1.3-0.4c-0.1-1-0.6-2.7-2.3-3.1
                      c0.3,0.2,0.5,0.4,0.7,0.6c0.2,0.3,0.3,0.6,0.3,0.9c-1.9-2-5.9-3.4-17.1-2.5c-14.8,1.2-9.9,10.8-8,11.9c1.9,1.1,6.7-2,10.3-1.7
                      c3.6,0.3,4.2,5.5,5.4,6.2c1.2,0.7,0.7-0.6,2.4-1.3c1.6-0.7,1.8,2,1.3,3.8c-0.4,1.8,1.8,4.1,1.8,4.1l2.3-2.5
                      c2.3-2.5,4.4-13,3.4-15.2L650,339.8z"/>
                  </g>
                </a>
                <a className='cursor-default men1'>
                  <g>
                    <path className="st2" d="M149.4,516.8h-8.2v11.5h8.2V516.8z"/>
                    <path className="st2" d="M149.4,537.1h-8.2v11.5h8.2V537.1z"/>
                    <path className="st2" d="M149.4,496.2h-8.2v11.5h8.2V496.2z"/>
                    <path className="st2" d="M149.4,557.1h-8.2v11.5h8.2V557.1z"/>
                    <path className="st2" d="M149.4,574.8h-8.2v11.5h8.2V574.8z"/>
                    <path className="st13" d="M129.8,507.1l-16.9-1.6l-10.8,8.5L97.9,530l5.1,10.9l-11.1-4.7l-25.9,29l-4.7,24.7L70,596h75.1l10.9-35.6
                      l-0.4-29L129.8,507.1z"/>
                    <path className="st24" d="M91.9,536.2c0,0,9.8,12.9,11.9,13.4c2.1,0.5,8.5-0.5,8.4-1.4c-0.2-1-9.2-7.3-9.2-7.3L91.9,536.2z"/>
                    <path className="st24" d="M66,565.2l13.7,10.2c0.6,0.4,1,1,1.3,1.7c0.3,0.7,0.3,1.4,0.2,2.1c-0.6,3-1.7,7.9-2.6,8.2
                      C77.2,587.6,66,565.2,66,565.2z"/>
                    <path className="st24" d="M153.4,563.1c0,0-19.6,10.2-20.3,11.9c-0.7,1.7,6,15.5,8.7,13.5C144.5,586.6,153.4,563.1,153.4,563.1z"/>
                    <path className="st13" d="M106.4,508.9c0,0-14-3.7-15.3-3.1c-1.3,0.6-7.7,9.5-7.1,11.1C84.6,518.5,106.4,508.9,106.4,508.9z"/>
                    <path className="st25" d="M107.4,500.2l-1,8.7l6-5.3C112.4,503.6,110.1,499.2,107.4,500.2z"/>
                    <path className="st25" d="M108.3,509.5c0-0.1-0.2-1.9-0.5-1.9c-0.3,0.1-2.5,1.4-2.5,1.7c0,0.2-0.4,2.1,0.4,2
                      C106.6,511.2,108.3,509.5,108.3,509.5z"/>
                    <path className="st24" d="M107.5,510.5c0,0-2.1,7.6-1.8,8.2s4.7,5.6,5.2,5.3C111.6,523.8,107.5,510.5,107.5,510.5z"/>
                    <path className="st24" d="M108.2,510.1c0,0,2.4,7.6,3.1,7.5c0.6-0.1,4-6,3.6-6.5C114.3,510.6,108.2,510.1,108.2,510.1z"/>
                    <path className="st24" d="M108.5,509.7l2.1-1.1c0,0,0.1,0,0.2,0c0.1,0,0.1,0,0.2,0l0.9,0.4c0,0,0.1,0,0.1,0.1c0,0,0,0.1,0,0.1
                      c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1L108.5,509.7z"/>
                    <path className="st24" d="M106.9,510.8l-1.2,1.2c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0,0.2c0,0.1,0.1,0.1,0.1,0.1
                      l0.4,0.3c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1,0c0,0,0.1,0,0.1-0.1c0,0,0-0.1,0-0.1L106.9,510.8z"/>
                    <path className="st25" d="M106.6,508.9c-0.2-0.1-9.1-1-9.8-0.6c-0.7,0.4-4.1,3.4-2.7,3.6C95.4,512.1,106.6,508.9,106.6,508.9z"/>
                    <path className="st24" d="M70,596c0,0,31.3-4.7,34.5-3.6c3.2,1.1,14.4,3.4,14.4,3.4s13.9-3.6,15.3-2.7c1.5,0.8,3.4,2.9,3.4,2.9L70,596
                      z"/>
                    <path className="st26" d="M89.1,376.9c0,0,11.7,34.2,17.1,40.7c5.4,6.5,24.2,16.1,24.4,16.9c0.4,2-1.2,5.2-2.9,5.2
                      c-6.3,0-19-4.7-24.9-10.4c-6.2-6-16.1-40.1-16.1-40.1L89.1,376.9z"/>
                    <path className="st14" d="M186,585.7h-46.8c-2.3,0-4.6-0.9-6.3-2.5s-2.7-3.8-2.8-6.1l-8.4-139.3h81.9l-8.4,139.3
                      c-0.1,2.3-1.1,4.5-2.8,6.1C190.6,584.8,188.4,585.7,186,585.7L186,585.7z"/>
                    <path className="st27" d="M199.4,585.7h-46.8c-4.9,0-9.1-0.7-9.1-8.7l-8.4-139.3h81.9l-8.4,139.3C208.5,585.7,204.2,585.7,199.4,585.7
                      z"/>
                    <path className="st19" d="M123.2,427.3h77c1.4,0,2.8,0.6,3.8,1.6c1,1,1.6,2.4,1.6,3.8v5.1h-87.8v-5.1c0-1.4,0.6-2.8,1.6-3.8
                      C120.4,427.9,121.8,427.3,123.2,427.3L123.2,427.3z"/>
                    <path className="st18" d="M139.2,427.3h77c1.4,0,2.8,0.6,3.8,1.6c1,1,1.6,2.4,1.6,3.8v5.1h-87.8v-5.1c0-1.4,0.6-2.8,1.6-3.8
                      C136.4,427.9,137.8,427.3,139.2,427.3L139.2,427.3z"/>
                    <path className="st18" d="M133.7,566.6h-3.5c-4.7,0-8.4,6.2-8.4,13.9c0,7.7,3.8,13.9,8.4,13.9h3.5c4.7,0,8.4-6.2,8.4-13.9
                      C142.1,572.8,138.3,566.6,133.7,566.6z"/>
                    <path className="st19" d="M136.4,580.5c0,5.6-2.8,10.2-6.2,10.2c-3.4,0-6.2-4.6-6.2-10.2c0-5.6,2.8-10.2,6.2-10.2
                      C133.6,570.3,136.4,574.8,136.4,580.5z"/>
                    <path className="st19" d="M72.9,583c0,0-1.4,11.2,0.8,11.7c0.9,0.2,26.1-0.1,26.1-0.1s0-3-4.1-3.7c-4.1-0.7-11.4-3.1-12.7-8.6
                      C81.8,576.8,72.9,583,72.9,583z"/>
                    <path className="st18" d="M97.9,524.6c0,9.5-11,59.9-11,59.9s-11.1,2-15.9-2.8c0,0,1-46.7,2.7-56.7c0.3-2-0.2-5-1.2-8.6
                      c-4.1-14.2-16.1-38.4-13.3-52.7c3.4-17.9,27.8,1.4,27.8,1.4S88,470,89.5,477C92.7,492.3,97.9,518.1,97.9,524.6z"/>
                    <path className="st19" d="M89.4,477l-17,39.6c-4.1-14.2-16.1-38.4-13.3-52.7c3.4-17.9,27.8,1.4,27.8,1.4S88,470,89.4,477z"/>
                    <path className="st19" d="M11.1,572.9c0,0-4.6,4.3-3.7,7.3c0.9,3,5,5,5.6,8.7c0.6,3.7,2.8,5.7,4.8,5.7h9.6c0,0,1.5-2.6-2.1-3.4
                      c-3.6-0.8-4.9-5.1-4.7-9.2c0.1-3.1,0-6.3-0.3-9.4l-7.6-1.8L11.1,572.9z"/>
                    <path className="st18" d="M87.4,454.5c0,0-28.9,55.7-45,84.6c-16.1,28.9-21.2,37-21.2,37s-9.2-3.7-10.1-6.2c0,0,8.3-47.8,21.7-64.7
                      c6.1-7.7,5.8-29.4,7.6-41.5c1.2-8,6.6-22.1,6.6-22.1L87.4,454.5z"/>
                    <path className="st28" d="M98.8,372.1c1.8,2.2,3.2,4.6,4.1,7.3c-10-0.7-22.5-14.4-22.5-14.4s7.3-2,12.4-10c0.3-0.4,0.5-0.8,0.8-1.3
                      c0.8-1.5,1.5-3,2.1-4.6l3,5.2l4.9,8.7c-1,2.3-2.1,4.6-3.4,6.8C99.7,370.5,99.3,371.3,98.8,372.1z"/>
                    <path className="st29" d="M105.9,393.9c-0.3,4.9-1.8,12.3-3.8,20.4c-0.8,3.2-1.6,6.5-2.5,9.8c-1.5,5.4-3,10.8-4.5,15.9l-0.1,0.2
                      c-0.1,0.5-0.3,1.1-0.4,1.6c-1.4,4.5-2.7,8.7-3.8,12.2c-2.2,6.8-2.2,10.5-3.9,11.3c-3.5,1.5-17.1-0.1-25.3-3.7
                      c-6.9-2.9-17.5-11.8-17.4-16.3c0.1-5.2,7-20.8,11.6-39c5.7-22.3,18.3-40,28.8-43.7c0.8-0.3,1.6,1.4,4.7,4.3
                      c2.8,2.7,6.4,4.4,9.7,4.8l2,3.3C103.8,379.7,106.4,385.9,105.9,393.9z"/>
                    <path className="st30" d="M102.2,414.3c-0.8,3.2-1.6,6.5-2.5,9.8c-1.5,5.4-3,10.8-4.5,15.9l-0.1,0.2c-0.1,0.5-0.3,1.1-0.4,1.6
                      c-1.4,4.5-2.7,8.7-3.8,12.2c-21.9-27.8-15.2-65.2-15.2-65.2L102.2,414.3z"/>
                    <path className="st26" d="M103.6,363c-1,2.3-2.1,4.6-3.4,6.8C94,365.2,93,358,92.8,355c0-0.8,0-1.3,0-1.3l0.8,0.1l5.1,0.6L103.6,363z"
                      />
                    <path className="st28" d="M106.4,363.9c0,0-15.7-3.3-13.5-13.1c2.2-9.7,1.7-16.7,11.8-15c10.1,1.7,11.2,5.9,11.3,9.3
                      C116.1,348.6,110.5,364.5,106.4,363.9z"/>
                    <path className="st18" d="M92.8,351.4c0,0,5.1-12.2,19.3-8.9c9.7,2.3,7.2-7.6,6.6-9.2c0,0-7.6-1.4-15.4-2.7
                      C86.9,328,91.9,349,92.8,351.4z"/>
                    <path className="st13" d="M101,374.9l-2-3.3c-3.4-0.4-6.9-2-9.7-4.8c-3-2.9-3.9-4.6-4.7-4.3c-10.5,3.7-23.1,21.4-28.8,43.7
                      c-4.6,18.2-11.6,33.8-11.6,39c-0.1,4.5,10.5,13.4,17.4,16.3c8.2,3.6,21.9,5.2,25.3,3.7c1.7-0.8,1.7-4.5,3.9-11.3
                      c1.1-3.6,2.4-7.7,3.8-12.2c0.2-0.5,0.3-1.1,0.4-1.6l0.1-0.2c1.6-5.1,3.1-10.5,4.5-15.9c0.9-3.3,1.7-6.6,2.5-9.8
                      c2-8.2,3.5-15.6,3.8-20.4C106.4,385.9,103.8,379.7,101,374.9z M101.7,398.7c-3.9,12.5-15.1,20.1-25.1,17
                      c-10-3.1-14.9-15.7-11-28.2c3.9-12.5,15.1-20.1,25.1-17S105.5,386.3,101.7,398.7z"/>
                    <path className="st16" d="M95.1,440.2l0.1-0.2c1.1-3.6,2.2-7.4,3.3-11.3l-9.9-14c-3.8,1.8-8,2.2-11.9,1c-0.5-0.1-0.9-0.3-1.4-0.5
                      c1.5,12.4,5.6,27.7,16.3,36.7c1-3.1,2-6.5,3.2-10.2C94.8,441.2,94.9,440.7,95.1,440.2z"/>
                    <path className="st28" d="M126.9,440.1c0,0,6.4,1.9,10.6,0.8c4.2-1.1,11.2,1.2,12.2,2.8s0.6,2.1-1.4,2.6c-2.1,0.4-9.4,3.2-14.1,1.2
                      c-4.7-2-10.8-2.3-10.8-2.3L126.9,440.1z"/>
                    <path className="st27" d="M94.1,443.5l0.5-1.7c0.2-0.5,0.3-1.1,0.4-1.6l0.1-0.2c0.5-1.5,0.9-3,1.3-4.5c-16.9-8.6-32.3-18.7-41.7-25.4
                      c-0.7,2.6-1.4,5-2.2,7.4C60.7,423.1,77.3,434.3,94.1,443.5z"/>
                    <path className="st14" d="M80.6,435.7c4.4,2.6,8.9,5.3,13.5,7.8l0.5-1.7c0.2-0.5,0.3-1.1,0.4-1.6l0.1-0.2c0.5-1.5,0.9-3,1.3-4.5
                      c-6.8-3.4-13.3-7.1-19.3-10.7C78.1,428.4,79.2,432.1,80.6,435.7z"/>
                    <path className="st28" d="M73.2,381.6c0,0,5.2,52,17.1,57.1c5.9,2.5,32.4,6.8,40.1,7.6c0,0,1.5,1.1,1.3-5.2c0,0-27.1-11.8-30.8-17.4
                      c-5.5-8.4,5-33.3-2-41.2C91.8,374.4,79.6,370.6,73.2,381.6z"/>
                    <path className="st29" d="M101.3,405.8c0,0-19.9,0.1-24.8,5.4l-5.3-31.4l13.9-8.5l14.1,9.1C99.2,380.4,104.6,386.7,101.3,405.8z"/>
                    <path className="st19" d="M195.9,566.6h-3.5c-4.7,0-8.4,6.2-8.4,13.9c0,7.7,3.8,13.9,8.4,13.9h3.5c4.7,0,8.4-6.2,8.4-13.9
                      C204.3,572.8,200.5,566.6,195.9,566.6z"/>
                    <path className="st20" d="M242.8,499.7l-11.2-2.9l-17.8,9.4l-13.4,24l5.4,15.6l-10.9,15.6l-0.4,26.5l-4.4,6.5l71.5,0l12.3-21.4
                      l1.4-33.8l-8.3-34.8l-14.9-6.9L242.8,499.7z"/>
                    <path className="st21" d="M242.1,497.5l-2.7-8.6c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.3-0.9-0.3c-2.4,0.1-7.7,0.4-7.7,0.9
                      C230.3,489.4,242.1,497.5,242.1,497.5z"/>
                    <path className="st21" d="M243.5,497.9l0.4-5.9c0-0.2,0.1-0.4,0.2-0.5c0.1-0.2,0.2-0.3,0.4-0.4c0.1-0.1,0.3-0.2,0.5-0.2
                      c0.2,0,0.4,0,0.5,0l2.4,0.7c0.4,0.1,0.8,0.3,1,0.6c0.3,0.3,0.4,0.7,0.5,1.1c0,0.4,0,0.8-0.2,1.2c-0.2,0.4-0.5,0.7-0.8,0.9
                      L243.5,497.9z"/>
                    <path className="st21" d="M239.9,498l0.5-1c0.1-0.2,0.3-0.4,0.5-0.5c0.2-0.1,0.4-0.2,0.7-0.2l3.4,0c0.2,0,0.4,0,0.6,0.1
                      c0.2,0.1,0.3,0.2,0.5,0.4l0.5,0.7c0.1,0.1,0.2,0.3,0.2,0.5c0,0.2,0.1,0.4,0,0.5l-0.1,0.5c-0.1,0.3-0.2,0.6-0.5,0.8
                      c-0.3,0.2-0.6,0.3-0.9,0.3L241,500c-0.2,0-0.4-0.1-0.6-0.2c-0.2-0.1-0.3-0.3-0.5-0.5c-0.1-0.2-0.2-0.4-0.2-0.6
                      C239.7,498.4,239.8,498.2,239.9,498z"/>
                    <path className="st21" d="M213.8,506.2l7.1,18.7c0.1,0.2,0.2,0.4,0.5,0.6c0.2,0.1,0.5,0.2,0.7,0.2s0.5-0.1,0.7-0.2
                      c0.2-0.1,0.4-0.3,0.5-0.6l2.9-6.3c0.2-0.5,0.3-1.1,0.2-1.7c-0.1-0.6-0.4-1.1-0.9-1.4L213.8,506.2z"/>
                    <path className="st21" d="M206.4,546.6c0,0,8.7,10.6,7,12.3c-1.7,1.7-17.4,2.6-17.4,2.6L206.4,546.6z"/>
                    <path className="st21" d="M274.1,573c0,0-32.4,10.8-30.6,14.6c1.8,3.9,18.3,6.8,18.3,6.8L274.1,573z"/>
                    <path className="st21" d="M235.6,536.9l34.6,4c0.2,0,0.5,0.1,0.6,0.3c0.2,0.2,0.3,0.4,0.3,0.6c0,0.2,0,0.5-0.2,0.7
                      c-0.1,0.2-0.3,0.4-0.6,0.5l-19.8,7.3c-0.6,0.2-1.3,0.3-2,0.2c-0.7-0.1-1.3-0.4-1.8-0.9l-12-10.9c-0.2-0.1-0.3-0.3-0.3-0.5
                      s0-0.4,0.1-0.6c0.1-0.2,0.2-0.3,0.4-0.5C235.2,537,235.4,536.9,235.6,536.9z"/>
                    <path className="st21" d="M239.3,501.4l-8.1,2.1c-0.3,0.1-0.6,0.2-0.9,0.5c-0.2,0.2-0.4,0.5-0.6,0.8c-0.1,0.3-0.2,0.6-0.1,1
                      c0,0.3,0.1,0.6,0.3,0.9l1,1.5c0.1,0.2,0.3,0.4,0.5,0.5c0.2,0.1,0.4,0.2,0.7,0.2c0.2,0,0.5,0,0.7-0.1c0.2-0.1,0.4-0.2,0.6-0.4
                      L239.3,501.4z"/>
                    <path className="st21" d="M243.5,501.1l8.1-1.4c0.3,0,0.5,0,0.8,0c0.3,0.1,0.5,0.2,0.7,0.3l3.2,2.3c0.1,0.1,0.2,0.2,0.3,0.4
                      s0,0.3,0,0.5c-0.1,0.1-0.2,0.3-0.3,0.3c-0.1,0.1-0.3,0.1-0.5,0.1L243.5,501.1z"/>
                  </g>
                </a>
                </svg>,

                <svg version="1.1" id="Layer_1" width="914" height="522"  xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                viewBox="0 0 914 522" className='btt2'>                    
                <a className='cursor-default bannerbg'>
                <g>
                    <path className="st0" d="M914,328.3c0,81.1-10.7,139-50.8,193.1H35.7c-78-143.1-7.3-162,16.8-209.7c5.3-10.4,8.3-22.2,7.1-37
                    c-0.2-2.5-0.5-5.2-1-7.9c-0.9-4.9-2.1-9.8-3.6-14.6c-1.7-5.4-3.4-10.9-4.9-16.3c-1.4-4.8-2.7-9.6-3.9-14.4
                    C31.4,164,29.8,107.6,89.2,78.8c83.9-40.7,118,21.7,185.6,11.3C351.2,78.3,363.4,0.2,481.7,0c68-0.1,158.7,81.3,244.9,43.4
                    C807,7.9,896.1,66.1,874.9,142.6C856.1,210.1,914,233.5,914,328.3z"/>
                    <path className="st1" d="M35.4,393.4c-0.3,0.3-0.9,1-0.9,1l5.5,7.8l7.5-5.3l-2.6-6C41.7,388.2,37.2,391.7,35.4,393.4L35.4,393.4z"/>
                    <path className="st1" d="M57.2,393c-3.8-4.6-11.3-2.6-11.3-2.6c5,8.6,3.4,11.8,3.4,11.8l-3.6,1.3l10.4,3.6l5.9-8.2l-3.2,0.7
                    C58.7,399.5,58,394.4,57.2,393L57.2,393z"/>
                    <path className="st1" d="M64,407.6l-9.3,2.2l2.2,8.9l6.5-0.2c3.7-1.9,2-7.4,1.1-9.7C64.3,408.4,64,407.6,64,407.6L64,407.6z"/>
                    <path className="st1" d="M51.2,418.5l0.1-3.8l-7.1,8.4l5.5,8.5l0.5-3.2c0,0,5,1.2,6.6,0.9c5.7-1.9,6.6-9.6,6.6-9.6
                    C53.5,421.2,51.2,418.5,51.2,418.5L51.2,418.5z"/>
                    <path className="st1" d="M28.9,420.5c-0.1,4.1,5.5,5.3,7.9,5.7c0.4,0.1,1.3,0.1,1.3,0.1l2.6-9.2l-8.9-2.5L28.9,420.5L28.9,420.5z"/>
                    <path className="st1" d="M24.2,402.3l2.5,2.1c0,0-3.5,3.8-4,5.3c-1.2,5.9,5.1,10.4,5.1,10.4c3.5-9.4,7-10.1,7-10.1l3.2,2l-3.8-10.3
                    L24.2,402.3z"/>
                    <path className="st1" d="M657.7,250.5c0,0.5,0.1,1.3,0.1,1.3l9.5,1.1l1-9.1l-6.2-2C658,242.3,657.7,248,657.7,250.5z"/>
                    <path className="st1" d="M671.9,233.9c-6-0.2-9.4,6.8-9.4,6.8c9.8,1.9,11.1,5.3,11.1,5.3l-1.4,3.5l9.6-5.4l-2.3-9.8l-1.6,2.8
                    C677.8,237,673.5,234.1,671.9,233.9L671.9,233.9z"/>
                    <path className="st1" d="M687.3,238.5l-4.4,8.4l8.1,4.3l4.1-5c1-4-4.2-6.4-6.5-7.3C688.2,238.7,687.3,238.5,687.3,238.5L687.3,238.5z"
                    />
                    <path className="st1" d="M687.1,255.2l-2.8-2.6l1.6,10.9l10,1.5l-2.1-2.5c0,0,4.2-2.9,5-4.3c2.4-5.5-2.9-11.3-2.9-11.3
                    C690.6,255.2,687.1,255.2,687.1,255.2L687.1,255.2z"/>
                    <path className="st1" d="M673.8,273.3c3,2.8,7.6-0.6,9.5-2.1c0.4-0.3,1-0.9,1-0.9l-5.1-8l-7.7,5L673.8,273.3L673.8,273.3z"/>
                    <path className="st1" d="M657,264.7l3.2-0.5c0,0,0.5,5.1,1.3,6.5c3.7,4.8,11.2,3,11.2,3c-4.7-8.8-2.9-11.9-2.9-11.9l3.6-1.1l-10.3-4
                    L657,264.7L657,264.7z"/>
                    <path className="st2" d="M679.8,203.1c-1,0-2-0.3-2.8-0.8c-0.8-0.6-1.5-1.3-1.9-2.3c-0.4-0.9-0.5-1.9-0.3-2.9c0.2-1,0.7-1.9,1.4-2.6
                    c0.7-0.7,1.6-1.2,2.6-1.4s2-0.1,2.9,0.3c0.9,0.4,1.7,1,2.3,1.9c0.6,0.8,0.8,1.8,0.8,2.8c0,1.3-0.5,2.6-1.5,3.6
                    C682.5,202.5,681.2,203.1,679.8,203.1L679.8,203.1z M679.8,188.8c-1.8,0-3.6,0.5-5.1,1.5c-1.5,1-2.7,2.5-3.4,4.1
                    c-0.7,1.7-0.9,3.5-0.5,5.3c0.4,1.8,1.2,3.4,2.5,4.7c1.3,1.3,2.9,2.2,4.7,2.5c1.8,0.4,3.6,0.2,5.3-0.5c1.7-0.7,3.1-1.9,4.1-3.4
                    c1-1.5,1.6-3.3,1.6-5.1c0-2.4-1-4.8-2.7-6.5C684.6,189.8,682.3,188.8,679.8,188.8z"/>
                    <path className="st3" d="M309,43.1c0,1.3-0.4,2.5-1.1,3.6c-0.7,1.1-1.7,1.9-2.9,2.4c-1.2,0.5-2.5,0.6-3.8,0.4
                    c-1.3-0.2-2.4-0.9-3.3-1.8c-0.9-0.9-1.5-2.1-1.8-3.3c-0.3-1.3-0.1-2.6,0.4-3.8c0.5-1.2,1.3-2.2,2.4-2.9c1.1-0.7,2.3-1.1,3.6-1.1
                    c1.7,0,3.4,0.7,4.6,1.9C308.3,39.8,309,41.4,309,43.1L309,43.1z"/>
                    <path className="st3" d="M691.9,31.9c0,2.7-0.8,5.4-2.3,7.7c-1.5,2.3-3.7,4.1-6.2,5.1c-2.5,1-5.3,1.3-8,0.8c-2.7-0.5-5.2-1.8-7.1-3.8
                    c-1.9-1.9-3.3-4.4-3.8-7.1c-0.5-2.7-0.3-5.5,0.8-8c1-2.5,2.8-4.7,5.1-6.2c2.3-1.5,5-2.3,7.7-2.3c1.8,0,3.6,0.4,5.3,1.1
                    c1.7,0.7,3.2,1.7,4.5,3c1.3,1.3,2.3,2.8,3,4.5C691.6,28.3,691.9,30.1,691.9,31.9L691.9,31.9z"/>
                    <path className="st3" d="M290.5,72.7c-1.3,0-2.6-0.4-3.7-1.1s-1.9-1.8-2.4-3c-0.5-1.2-0.6-2.5-0.4-3.8c0.3-1.3,0.9-2.5,1.8-3.4
                    c0.9-0.9,2.1-1.6,3.4-1.8c1.3-0.3,2.6-0.1,3.8,0.4c1.2,0.5,2.2,1.3,3,2.4c0.7,1.1,1.1,2.4,1.1,3.7c0,1.8-0.7,3.4-1.9,4.7
                    C293.9,72,292.2,72.7,290.5,72.7L290.5,72.7z M290.5,54c-2.4,0-4.7,0.7-6.7,2c-2,1.3-3.5,3.2-4.5,5.4s-1.2,4.6-0.7,7
                    c0.5,2.3,1.6,4.5,3.3,6.2c1.7,1.7,3.8,2.8,6.2,3.3c2.3,0.5,4.8,0.2,7-0.7c2.2-0.9,4.1-2.5,5.4-4.5c1.3-2,2-4.3,2-6.7
                    c0-3.2-1.3-6.3-3.5-8.5C296.7,55.3,293.7,54,290.5,54L290.5,54z"/>
                </g>
                </a>
                <a className='cursor-default btmen2'>
                <g>
                    <path className="st4" d="M211.8,293.8l-5.6-1.9l2.8-12.5l9,3.1L211.8,293.8z"/>
                    <path className="st5" d="M208.4,293.4c-9.8-0.2-53.9-6.4-64.5-16.8c4.5-8,8.2-17.6,17.1-16.6c14.7,1.3,51.8,19.1,51.8,19.1
                    L208.4,293.4L208.4,293.4z"/>
                    <path className="st5" d="M160.9,202.6c0,0-21.8,11-23.1,30.9c-0.9,12.7-1.1,32.6,5.8,41.6c3.9,5.2,15.1-6.7,20.3-6.8
                    C171.5,233.2,166.2,213,160.9,202.6L160.9,202.6z"/>
                    <path className="st4" d="M229.8,284.9c-2.2-0.7-8.9-3.7-12.9-2.4c-2.4,0.8-4,4.3-4.6,6.9c-4.1,3.3,1.7,14,1.7,14l14.9-11.1
                    L229.8,284.9L229.8,284.9z"/>
                    <path className="st6" d="M259.5,281.1l3,6.5c0.1,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.4c0,0.1-0.1,0.2-0.2,0.4
                    c-0.1,0.1-0.2,0.2-0.3,0.2l-5.1,2.4l-3.9-8.4l5.1-2.4c0.3-0.1,0.5-0.1,0.8,0C259.1,280.7,259.3,280.9,259.5,281.1L259.5,281.1z"/>
                    <path className="st7" d="M199.7,300.9c0,0,36.3-17.6,39.3-17.9c1.1-0.1,2.1-0.1,3.2,0l10.8,0l3.9,8.4l-7,8.2c-0.6,0.9-1.3,1.7-2.1,2.4
                    c-2.1,2.1-39,18.4-39,18.4c-0.7,0.3-1.5,0.3-2.2,0.1c-0.7-0.3-1.3-0.8-1.6-1.5c0,0-1.9-5.7-2.7-7.5c-0.8-1.8-4-7-4-7
                    c-0.3-0.7-0.3-1.5-0.1-2.2S199,301.2,199.7,300.9L199.7,300.9z"/>
                    <path className="st4" d="M228.1,305.6c-1.9-0.5-4.3,1.1-5.3,3.6c0.1,0.1,0,0.2,0,0.2c-0.2-0.2-0.4-0.2-0.7-0.3
                    c-1.6-0.2-3.6,0.9-4.2,2.6c-0.6,1.7,0,3.5,1.7,3.8c1.3,0.3,3-0.4,3.8-1.7c0.3,0.3,0.8,0.6,1.2,0.7c1.3,0.3,3-0.4,4.2-1.8l0.9,0.3
                    c1.7,0.3,3.9-1,5-3c0.3,0.4,0.7,0.6,1.2,0.7c1.9,0.3,4.1-1,5-3c0.9-2.1,0-4.1-1.9-4.6c-1.7-0.3-3.4,0.4-4.5,1.9
                    c-0.3-0.4-0.8-0.7-1.3-0.8c-1.3-0.3-3,0.4-4.2,1.8L228.1,305.6L228.1,305.6z"/>
                    <path className="st4" d="M229.9,284.9c-1.8-0.3-3.7,1.7-4.2,4.5c-0.5,2.8,0.5,5.3,2.3,5.7c1.8,0.3,3.7-1.7,4.2-4.5
                    C232.7,287.8,231.7,285.3,229.9,284.9L229.9,284.9z"/>
                    <path className="st8" d="M128.7,312.3l-51,26.8c0,0,41.6,78.5,65.5,102.8c4.2,4.2,28-2.3,29.7-7.4
                    C179.2,415.9,128.7,312.3,128.7,312.3L128.7,312.3z"/>
                    <path className="st8" d="M170.6,522H138c-2.3-22.9-4.8-61.3,0.3-85.6c0.3-1.6,0.7-3.1,1.1-4.6c2-5.3,4.8-9.3,8.1-12.1
                    c8.6-7.2,20.1-5.6,25.3,3.9c0,0.1,0.1,0.2,0.1,0.3c0.1,0.6,0.1,1.2,0.2,1.9c0,0,0,0,0,0c0.1,1.6,0.2,3.2,0.3,4.9
                    C174.3,450.3,171.4,475.5,170.6,522L170.6,522z"/>
                    <path className="st9" d="M117.4,324.4l-61-14.8c0,0-2.6,99.2,4.9,132.4c1.3,5.8,22.8,12.3,26.9,8.9
                    C103.2,438.6,117.4,324.4,117.4,324.4z"/>
                    <path className="st9" d="M95.6,437.3c0,0,0,0.1,0,0.1c-4.8,18-6.9,36.9-21.8,84.6H44c1.4-21.8,5.4-62.6,16.1-86.1
                    c1.1-2.4,2.3-4.8,3.8-7C75.1,411.6,97.7,419.1,95.6,437.3L95.6,437.3z"/>
                    <path className="st10" d="M97.3,188.9l46.6,6.8l17,6.9c1,27-19.9,123.2-19.9,123.2s-30.1,11.2-48,11.4c-24.5,0.2-43.2-13.1-43.2-13.1
                    C49,295.6,56,260.3,68,224.5C72.6,210.8,84.8,193.9,97.3,188.9L97.3,188.9z"/>
                    <path className="st11" d="M127.2,170.3L127.2,170.3l11.3,0.9l0.4,26.8c0,0-0.2,6.3-11.7,7.2c-9.9,0.8-15.5-9.3-15.5-9.3l4.4-26.5
                    L127.2,170.3L127.2,170.3z"/>
                    <path className="st4" d="M113.8,149.7c-1.2,19.4,8.1,35.8,23.4,36.7c15.3,0.9,28.6-14.1,29.8-33.6c1.2-19.4-10.3-36-25.6-36.9
                    C126.1,115,115,130.2,113.8,149.7z"/>
                    <path className="st11" d="M148.1,156c2,4.1,4.7,9.1,4.7,9.1s-1.8,3.9-7.2,3.5c2.4-0.7,4.8-2,4.1-5.2C149,160.3,148.1,156,148.1,156
                    L148.1,156z"/>
                    <path className="st12" d="M108.5,133.1c1.9-18.4,19.7-21.1,19.7-21.1s-3.5-2-1.5-7.3c2.4,2.1,5.3,3.5,8.5,3.8
                    c21.1,2,33.4,7.9,29.8,15.6c-1.3,2.7,11.8,6.8,2.1,24.8c-0.8-2.5-0.3-11.2-4-13.8c-3.7-2.6-2.6,4.4-11.5,1.6
                    c-9-2.8-14.3-7.8-21.7-5.5c-6,2.3-17,22.9-17,24.5C112.9,152,107.6,141.2,108.5,133.1L108.5,133.1z"/>
                    <path className="st12" d="M165.1,163.2c0,0-3.8,10-6.5,12.3c0.4-4.5-9.9-3.6-14.2-4.1c-7.8-0.8-14.2-9.1-18.8,0.4
                    c-13.1-1.2-9.2-23.5-9.2-23.5c-5.3,16.6-3.9,40.1,21.4,44.9C154,196.3,164.9,179.4,165.1,163.2L165.1,163.2z"/>
                    <path className="st12" d="M164.6,153.5c-0.2,0-0.4-0.1-0.6-0.3c-0.2-0.1-0.3-0.3-0.4-0.5c-0.5-1.2-1.3-2-2.2-2.3
                    c-1.6-0.6-3.3,0.3-3.4,0.3c-0.3,0.2-0.7,0.2-1,0.1c-0.3-0.1-0.6-0.3-0.8-0.7s-0.2-0.7-0.1-1c0.1-0.3,0.3-0.6,0.7-0.8
                    c0.1-0.1,2.8-1.4,5.5-0.5c1.7,0.6,2.9,1.9,3.8,3.8c0.1,0.3,0.1,0.7,0,1c-0.1,0.3-0.4,0.6-0.7,0.7
                    C165.1,153.5,164.9,153.5,164.6,153.5L164.6,153.5z"/>
                    <path className="st12" d="M133.5,147.9c-0.3-0.1-0.6-0.2-0.8-0.4c-0.2-0.3-0.3-0.6-0.3-1c0-0.4,0.2-0.7,0.5-0.9
                    c2.1-1.8,4.3-2.6,6.3-2.4c1.7,0.2,3.2,1,4.2,2.3c0.2,0.3,0.3,0.6,0.3,1c0,0.3-0.2,0.7-0.5,0.9c-0.3,0.2-0.6,0.3-1,0.3
                    c-0.3,0-0.7-0.2-0.9-0.5c-0.6-0.7-1.5-1.2-2.4-1.3c-1.3-0.1-2.7,0.5-4.3,1.8c-0.2,0.1-0.3,0.2-0.5,0.3
                    C133.9,147.9,133.7,147.9,133.5,147.9L133.5,147.9z"/>
                    <path className="st4" d="M116.1,148.4c2.7,3.7,2.3,8.5-0.7,10.7c-3.1,2.2-7.7,0.9-10.3-2.8c-2.7-3.7-2.3-8.5,0.7-10.7
                    C108.8,143.4,113.4,144.6,116.1,148.4z"/>
                    <path className="st11" d="M111.4,157c0.2,0,0.3-0.1,0.4-0.2c1.9-1.4,2.6-3.3,1.9-5.2c-0.7-1.9-2.6-3.3-4.5-3.2c-0.3,0-0.5,0.1-0.7,0.3
                    c-0.2,0.2-0.3,0.5-0.3,0.7c0,0.3,0.1,0.5,0.3,0.7c0.2,0.2,0.5,0.3,0.7,0.3c1.1,0,2.2,0.8,2.6,1.9c0.4,1.1-0.1,2.1-1.2,3
                    c-0.2,0.1-0.3,0.3-0.4,0.5c-0.1,0.2,0,0.5,0,0.7c0.1,0.2,0.2,0.4,0.4,0.5C110.9,157,111.1,157,111.4,157L111.4,157z"/>
                    <path d="M141.2,155c0.4-0.4,0.7-0.9,0.7-1.5c0.1-0.6,0-1.2-0.2-1.7c-0.2-0.5-0.6-1-1.1-1.3c-0.5-0.3-1.1-0.5-1.6-0.4
                    c-0.6,0-1.1,0.2-1.6,0.5c-0.5,0.3-0.8,0.8-1,1.3c-0.2,0.5-0.2,1.1-0.1,1.7c0.1,0.6,0.4,1.1,0.8,1.5c0.6,0.5,1.3,0.8,2.1,0.8
                    C140,155.9,140.7,155.6,141.2,155L141.2,155z"/>
                    <path d="M157.6,158c0.4,0.4,0.9,0.6,1.4,0.7c0.5,0.1,1.1,0,1.5-0.2c0.5-0.2,0.9-0.6,1.2-1c0.3-0.4,0.4-1,0.4-1.5
                    c0-0.5-0.2-1-0.5-1.5c-0.3-0.4-0.7-0.8-1.2-0.9c-0.5-0.2-1-0.2-1.5-0.1c-0.5,0.1-1,0.4-1.3,0.8c-0.2,0.3-0.4,0.6-0.5,0.9
                    c-0.1,0.3-0.2,0.7-0.2,1c0,0.4,0.1,0.7,0.2,1C157.1,157.5,157.3,157.8,157.6,158L157.6,158z"/>
                    <path className="st13" d="M125.4,250.8l5.2,5c0.1,0.1,0.2,0.2,0.2,0.3c0.1,0.1,0.1,0.3,0.1,0.4s0,0.3-0.1,0.4
                    c-0.1,0.1-0.1,0.2-0.2,0.3l-3.9,4.1L120,255l3.9-4.1c0.2-0.2,0.5-0.3,0.7-0.3C125,250.5,125.2,250.6,125.4,250.8z"/>
                    <path className="st7" d="M77,291.1c0,0,27.3-29.7,30-31c1-0.5,1.9-0.9,3-1.2l10-4l6.7,6.4l-3.5,10.2c-0.3,1-0.6,2-1,3
                    C121,277.4,92.7,306,92.7,306c-0.3,0.3-0.6,0.5-0.9,0.6c-0.3,0.2-0.7,0.2-1.1,0.2c-0.4,0-0.7-0.1-1.1-0.2
                    c-0.3-0.1-0.7-0.3-0.9-0.6c0,0-3.8-4.6-5.3-6c-1.4-1.4-6.3-5-6.3-5c-0.3-0.3-0.5-0.6-0.6-0.9c-0.1-0.3-0.2-0.7-0.2-1.1
                    c0-0.4,0.1-0.7,0.2-1.1C76.6,291.7,76.8,291.4,77,291.1L77,291.1z"/>
                    <path className="st1" d="M120,274.6c0,0-0.1,0-0.1,0c-10.1-1.7-12.5-11.2-12.5-11.3c0-0.2,0-0.4,0.1-0.6c0.1-0.2,0.3-0.3,0.5-0.4
                    c0.2,0,0.4,0,0.6,0.1c0.2,0.1,0.3,0.3,0.4,0.5l0,0c0.1,0.3,2.1,8.5,11.2,10c0.2,0,0.4,0.1,0.6,0.3c0.1,0.2,0.2,0.4,0.2,0.6
                    c0,0.2-0.1,0.4-0.3,0.6C120.5,274.5,120.2,274.6,120,274.6L120,274.6z"/>
                    <path className="st4" d="M77,280.2c2.1-3.3,1.8-7.4,4.2-9.2c1.4-1.1,4.7-0.2,7.7-0.9c2.9-0.7,4.2-3.5,7.5-4.8c2.7-1.1,4.1-0.6,4.6,0.5
                    l-4.5,6c-0.9,1.9,0.3,5,7.3,9.9c5.3,3.7,10.6,4.4,9.4,7.8c-0.8,2.2-3.7,0.2-7.4-2.2c3.8,4.1,5.5,2.7,4.8,5.5
                    c-0.9,3.4-8-1.3-11.1-4.2c3,5.5,8.3,4.9,7.8,7.1c-0.8,2.5-5.8,2.4-10.1-3c3,5.5,6.4,4.7,5.4,6.7c-1.1,2-10-0.4-16.6-7.4
                    C84.2,290.1,77,280.2,77,280.2L77,280.2z"/>
                    <path className="st5" d="M96.9,189c0,0-41.1,11.2-69.5,52.7c9.9,19.6,25,11.1,25,11.1l40.2-22.6c0,0,10.4-10.6,10.5-24.2
                    C103.2,192.8,96.9,189,96.9,189L96.9,189z"/>
                    <path className="st5" d="M87.4,268.7l-34.9-27.9c0,0-7.5-12.7-25.1,1c2.6,11,12.3,30.4,48.7,41.1C81.5,278.9,87.4,268.7,87.4,268.7
                    L87.4,268.7z"/>
                    <path className="st11" d="M144.8,179.5c1.8,0,3.6-0.3,5.3-0.9c0.3-0.1,0.6-0.4,0.7-0.7c0.1-0.3,0.2-0.7,0-1c-0.1-0.3-0.4-0.6-0.7-0.7
                    c-0.3-0.1-0.7-0.1-1,0c-0.3,0.1-7.8,2.9-13.6-3.5c-0.2-0.3-0.6-0.4-0.9-0.5c-0.4,0-0.7,0.1-1,0.3c-0.3,0.2-0.4,0.6-0.5,0.9
                    c0,0.4,0.1,0.7,0.3,1c3.7,4.1,8,5.1,11,5.1L144.8,179.5z"/>
                    <path className="st1" d="M217.7,310.1c-0.2,0-0.3,0-0.4-0.1c-5.2-3.4-5.8-11.6-5.8-12c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.2-0.3
                    c0.1-0.1,0.2-0.1,0.3-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.3
                    c0,0.1,0.1,0.2,0.1,0.3l0,0c0,0.1,0.6,7.8,5.1,10.7c0.1,0.1,0.3,0.2,0.3,0.4c0.1,0.2,0.1,0.3,0,0.5c-0.1,0.2-0.2,0.3-0.3,0.4
                    C218,310,217.8,310.1,217.7,310.1L217.7,310.1z"/>
                    <path className="st1" d="M246.8,300.4c-0.2,0-0.4-0.1-0.5-0.2c-6.9-5.6-7.7-14.4-7.7-14.8c0-0.1,0-0.2,0-0.3c0-0.1,0.1-0.2,0.2-0.3
                    c0.1-0.1,0.2-0.1,0.2-0.2c0.1,0,0.2-0.1,0.3-0.1c0.1,0,0.2,0,0.3,0c0.1,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.2,0.2
                    c0,0.1,0.1,0.2,0.1,0.3l0,0c0,0.1,0.8,8.5,7.1,13.7c0.1,0.1,0.2,0.2,0.3,0.4c0,0.2,0,0.3,0,0.5c-0.1,0.2-0.2,0.3-0.3,0.4
                    C247.2,300.4,247,300.4,246.8,300.4L246.8,300.4z"/>
                    <path className="st14" d="M284.8,336.6l-0.5,6.1l-13.8,177c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.8-0.8,1H155.7
                    c-0.4-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.8-0.3-1.3l-5.4-76.5l-1.6-23.4l-5.4-77.1l-0.1-2l-0.5-6.5c0-1.9,7.4-9.5,9.9-9.5
                    l124.1,0.6C281,323.4,284.8,334.6,284.8,336.6L284.8,336.6z"/>
                    <path className="st15" d="M291.3,324.7H133.1c-2.6,0-4.7,1.5-4.7,3.4v11.2c0,1.9,2.1,3.4,4.7,3.4h158.2c2.6,0,4.7-1.5,4.7-3.4v-11.2
                    C295.9,326.2,293.8,324.7,291.3,324.7z"/>
                    <path className="st15" d="M213.1,444.1c-21.4,0-38.8-17.4-38.8-38.8c0-21.4,17.4-38.8,38.8-38.8c21.4,0,38.8,17.4,38.8,38.8
                    C251.9,426.7,234.5,444.1,213.1,444.1z M213.1,363.8c-22.9,0-41.5,18.6-41.5,41.5c0,22.9,18.6,41.5,41.5,41.5s41.5-18.6,41.5-41.5
                    S236,363.8,213.1,363.8L213.1,363.8z"/>
                    <path className="st15" d="M215.8,374.5h-7.2c-0.1,0-0.3,0-0.4,0.1s-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
                    c-0.1,0.1-0.1,0.3-0.1,0.4v5.7h9.3v-5.7c0-0.3-0.1-0.5-0.3-0.7C216.3,374.6,216,374.5,215.8,374.5L215.8,374.5z"/>
                    <path className="st15" d="M222.2,424h-20.2c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5V437c0,0.7,0.3,1.5,0.8,2
                    c0.5,0.5,1.2,0.8,2,0.8h15.9c0.7,0,1.5-0.3,2-0.8c0.5-0.5,0.8-1.2,0.8-2v-12.3c0-0.2-0.1-0.4-0.2-0.5
                    C222.6,424.1,222.4,424,222.2,424L222.2,424z"/>
                    <path className="st15" d="M222.1,401.1h-19.9c-0.2,0-0.4,0.1-0.5,0.2c-0.1,0.1-0.2,0.3-0.2,0.5v19.8c0,0.2,0.1,0.4,0.2,0.5
                    c0.1,0.1,0.3,0.2,0.5,0.2h19.9c0.2,0,0.4-0.1,0.5-0.2c0.1-0.1,0.2-0.3,0.2-0.5v-19.8c0-0.2-0.1-0.4-0.2-0.5
                    C222.5,401.2,222.3,401.1,222.1,401.1z"/>
                    <path className="st15" d="M222.7,393.9c-0.4-1-0.8-2-1.3-2.9H203c-0.5,0.9-1,1.9-1.3,2.9c-1,2.8,0.5,5.4,0.5,5.4h20
                    C222.2,399.3,223.7,396.7,222.7,393.9z"/>
                    <path className="st15" d="M203,391l4.5-9.8h1.2l-4.3,10L203,391z"/>
                    <path className="st15" d="M221.3,391l-4.5-9.8h-1.4l4.3,10L221.3,391z"/>
                </g>
                </a>
                <a className='cursor-default btgirl2'>
                <g>
                    <path className="st2" d="M273.5,185.9c3.7,2.9,8.5-1.4,10.5-3.4c0.4-0.4,1-1.1,1-1.1l-6.6-8.5l-8.2,6.4L273.5,185.9L273.5,185.9z"/>
                    <path className="st16" d="M387.7,206.8c0,0,10.7,3.8,23.3,22.7h-19.2L387.7,206.8z"/>
                    <path className="st17" d="M397.9,226.5c0,0,8.9,0.4,14.9,1.1c6,0.7,37.2,6.8,47.5,14.1c-4.7,3.8-30.3,1.4-36.2,0
                    C406,237.6,397.9,226.5,397.9,226.5z"/>
                    <path className="st6" d="M411.3,207.8l-5.5-0.1c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3
                    c0,0.1-0.1,0.2-0.1,0.3l0,1.2c0,0.1,0,0.2,0.1,0.4c0,0.1,0.1,0.2,0.2,0.3l-0.3,11c-0.1,2.3-0.9,4.6-2.3,6.4l-0.1,0.1
                    c-1.7,2.1-2.6,4.8-2.7,7.5l-0.6,21.9c0,0.4,0.1,0.8,0.2,1.1c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.5,0.9,0.7
                    c0.3,0.2,0.7,0.2,1.1,0.2l10.3,0.3c0.4,0,0.8-0.1,1.1-0.2c0.4-0.1,0.7-0.3,1-0.6c0.3-0.3,0.5-0.6,0.7-0.9c0.2-0.3,0.2-0.7,0.3-1.1
                    l0.6-21.8c0.1-2.8-0.8-5.5-2.4-7.8c-1.4-1.9-2.1-4.3-2-6.7l0.3-10.8c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.3,0.1-0.4l0-1.2
                    c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3c-0.1-0.1-0.2-0.2-0.3-0.2C411.6,207.8,411.5,207.8,411.3,207.8L411.3,207.8z"/>
                    <path className="st18" d="M457.1,220l-5.2-1.6c-0.2-0.1-0.5,0-0.7,0.1c-0.2,0.1-0.4,0.3-0.4,0.5l-0.3,1.1c0,0.1,0,0.2,0,0.4
                    c0,0.1,0.1,0.2,0.1,0.3l-3.3,10.5c-0.7,2.2-2.1,4.2-3.9,5.5l-0.1,0.1c-2.2,1.6-3.8,3.9-4.6,6.5l-6.5,20.9
                    c-0.2,0.7-0.2,1.5,0.2,2.2c0.4,0.7,1,1.2,1.7,1.4l9.8,3.1c0.4,0.1,0.7,0.2,1.1,0.1c0.4,0,0.7-0.1,1.1-0.3c0.3-0.2,0.6-0.4,0.9-0.7
                    c0.2-0.3,0.4-0.6,0.5-1l6.5-20.8c0.8-2.7,0.8-5.5-0.2-8.1c-0.8-2.2-0.9-4.7-0.1-7l3.2-10.3c0.1,0,0.2-0.1,0.3-0.2
                    c0.1-0.1,0.2-0.2,0.2-0.3l0.3-1.1c0-0.1,0-0.2,0-0.3c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.1-0.2-0.2-0.3
                    C457.3,220.1,457.2,220.1,457.1,220L457.1,220z"/>
                    <path className="st19" d="M440.2,224.1v-3.2h-8.3v3.1c0,0.8-0.2,1.6-0.5,2.4c-0.3,0.8-0.8,1.5-1.4,2c-0.9,0.8-1.5,1.8-2,2.9
                    c-0.5,1.1-0.7,2.2-0.7,3.4v23.7h17.3v-23.7c0-1.2-0.2-2.3-0.7-3.4c-0.5-1.1-1.1-2.1-2-2.9c-0.6-0.6-1.1-1.3-1.4-2
                    C440.3,225.7,440.1,224.9,440.2,224.1L440.2,224.1z"/>
                    <path className="st20" d="M427.6,212.7l1.2-2.9l-7.7-3l-1.2,2.9c-0.3,0.8-0.7,1.5-1.3,2.1c-0.6,0.6-1.3,1.1-2,1.4
                    c-1.1,0.4-2.1,1.1-2.9,1.9s-1.5,1.8-1.9,2.9l-8.7,22l16.1,6.4l8.7-22c0.4-1.1,0.6-2.3,0.6-3.4c0-1.2-0.3-2.3-0.8-3.4
                    c-0.3-0.8-0.5-1.6-0.5-2.4C427.2,214.3,427.3,213.4,427.6,212.7L427.6,212.7z"/>
                    <path className="st16" d="M435.5,216.2l-5-2.2c-0.2-0.1-0.5-0.1-0.7,0c-0.2,0.1-0.4,0.3-0.5,0.5l-0.5,1.1c0,0.1-0.1,0.2-0.1,0.4
                    c0,0.1,0,0.2,0.1,0.4l-4.4,10c-0.9,2.1-2.5,3.9-4.5,5.1l-0.1,0.1c-2.3,1.4-4.2,3.4-5.3,5.9l-8.8,20.1c-0.2,0.3-0.2,0.7-0.2,1.1
                    c0,0.4,0.1,0.8,0.2,1.1c0.1,0.4,0.3,0.7,0.6,1c0.3,0.3,0.6,0.5,0.9,0.6l9.4,4.1c0.7,0.3,1.5,0.3,2.2,0c0.7-0.3,1.3-0.8,1.6-1.5
                    l8.8-19.9c1.1-2.5,1.4-5.4,0.7-8.1c-0.5-2.3-0.3-4.8,0.6-6.9l4.3-9.9c0.1,0,0.3-0.1,0.4-0.2c0.1-0.1,0.2-0.2,0.2-0.3l0.5-1.1
                    c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2-0.1-0.3c0-0.1-0.1-0.2-0.2-0.3C435.7,216.3,435.6,216.2,435.5,216.2L435.5,216.2z"/>
                    <path className="st15" d="M397.9,226.5c0,0,8.1,8.6,31.4,13.3c22.9,4.6,31.1,1.9,31.1,1.9s-3.6,18.8-9.6,37.9
                    c-4.3,13.4-12.1,23.2-12.4,32.2c-9.2,6.2-51.4-8.9-51.4-8.9L397.9,226.5L397.9,226.5z"/>
                    <path className="st4" d="M451.9,259c-1.6,1.1-1.9,4.1-0.7,6.4c0.1,0,0.1,0.1,0.1,0.1c-0.3,0-0.4,0.2-0.7,0.3c-1.2,1.1-1.6,3.3-0.7,4.9
                    c0.9,1.6,2.6,2.3,4,1.2c1.1-0.8,1.6-2.6,1.2-4c0.5,0,0.9-0.2,1.3-0.5c1.1-0.8,1.6-2.6,1.3-4.3l0.8-0.5c1.4-1.1,1.8-3.6,0.9-5.8
                    c0.5,0,0.9-0.2,1.3-0.5c1.5-1.2,1.9-3.8,0.9-5.8c-1-2-3.1-2.7-4.7-1.5c-1.4,1.1-1.9,2.9-1.5,4.6c-0.5,0-1,0.2-1.4,0.5
                    c-1.1,0.8-1.6,2.6-1.3,4.3L451.9,259L451.9,259z"/>
                    <path className="st21" d="M428.8,247.9c0,0-12.1,1.4-22.4-6.6c1.2,6.3,7.2,11.4,10.5,20.7c-0.5-6.7-1.3-10.2-3-12.5
                    C418,250.1,428.8,247.9,428.8,247.9L428.8,247.9z"/>
                    <path className="st21" d="M444.7,282.1l-8.7,17C436,299.1,432.6,288.9,444.7,282.1L444.7,282.1z"/>
                    <path className="st22" d="M385.4,300.9l-43,29.1c0,0,27.6,74.4,46.2,98.7c3.2,4.2,26.3,1.7,27.7-3.3
                    C421.2,406.9,385.4,300.9,385.4,300.9L385.4,300.9z"/>
                    <path className="st22" d="M416.8,522h-27.7c-3.1-17.8-8.6-55.1-5.6-82.1c0.1-1,0.2-2,0.4-3c0.5-3.9,1.3-7.7,2.5-11.4l0,0
                    c2-7.2,5.9-12.4,10.3-15.3c6.3-4.3,13.6-4,17.8,1.4c0.8,1,1.4,2.2,1.9,3.4c0.2,2.1,0.3,4.2,0.4,6.5c0,0,0,0,0,0v0
                    c0.3,6.1,0.5,13.1,0.6,21.1c0,0.9,0,1.8,0,2.8C417.4,464.2,417,488.7,416.8,522L416.8,522z"/>
                    <path className="st10" d="M374.3,310.7c0,0-27.5-39.8-44.9-12.5c-17.4,27.3-5.4,101.6,0.4,134.8c1,5.8,17.7,12.3,20.9,8.9
                    C362.3,429.5,374.3,310.7,374.3,310.7L374.3,310.7z"/>
                    <path className="st10" d="M352.5,522h-35.7c0.6-19.7,2.9-68.3,12-94.8c0.8-2.5,1.8-5,3-7.4c2.3-4.7,5.3-7.5,8.4-8.9
                    c8.3-3.7,17.4,3.9,16.2,17.1c0,0.1,0,0.2,0,0.2C352.6,446.8,363.5,470.9,352.5,522L352.5,522z"/>
                    <path className="st22" d="M375.1,123.1c-9.1-15.9-43.4-11.5-45.8,7.8c-2.4,19.3-0.3,31.9-24.2,30.5c-19.1-1.1-37.3,17.2-35.6,43.8
                    c2.3,34.1,25,19.3,20.7,36.5s-6.7,34.5,17.7,33.5c24.4-1.1,45.3-22.9,40.6-50.4c-4.7-27.5,17-25,21.3-39.1
                    C375.9,165.7,375.1,123.1,375.1,123.1L375.1,123.1z"/>
                    <path className="st11" d="M375.8,209.1c0,0-0.9,10.4-7,10.4c-6.1,0-16.7-12.8-16.7-12.8c1-2.8,1.4-6.5,1.4-10.6c0-1.1,0-2.3-0.1-3.4
                    c-0.4-11.5-3.1-24-3.1-24l23,11.5c0,0,0.7,9.7,1.4,18.1c0.1,1,0.2,2.1,0.3,3C375.3,204.6,375.6,207.5,375.8,209.1L375.8,209.1z"/>
                    <path className="st23" d="M390.9,283.7c0.8-12.8,14.8-32.2,10.3-45.6c-3.2-10.7-7.9-28.7-16.1-30.9c-3.3-0.9-5.2-3.2-9.8-3.3
                    c0,0,1.1,11.3-4.4,11.3c-10.6,0.2-17.6-13.9-17.6-13.9c-5.7-0.9-24.9,3.9-26.1,8.8c-1,16.8,4.8,37.7,8.1,56.2
                    c0.2,9.3-2.4,16.7-3.7,21.9c-2,5.4-5.2,11.2-5.3,12.4c0,0,38.2,14.6,64,2.5C390.2,303.1,390.3,292.4,390.9,283.7L390.9,283.7z"/>
                    <path className="st13" d="M375.2,204c0,0,16.7,18,18,39.1s-5.1,51.9-5.1,51.9l6.6-2.4c0,0,8.4-31.7,8.3-54.6
                    c-10-28.5-15.5-31.2-15.5-31.2L375.2,204L375.2,204z"/>
                    <path className="st4" d="M350.2,160.6c-0.9,18.9,10.5,34.7,25.3,35.4c14.8,0.7,27.5-14.1,28.4-33c0.9-18.9-10.5-34.7-25.3-35.4
                    S351.1,141.8,350.2,160.6z"/>
                    <path d="M394.8,169c0.1,0.5,0.3,1,0.7,1.3c0.4,0.4,0.8,0.6,1.3,0.7c0.5,0.1,1,0.1,1.5-0.1c0.5-0.2,0.9-0.5,1.2-0.9
                    c0.3-0.4,0.4-0.9,0.5-1.4c0-0.5-0.1-1-0.4-1.4c-0.3-0.4-0.7-0.8-1.1-1c-0.5-0.2-1-0.3-1.5-0.2c-0.3,0.1-0.7,0.2-0.9,0.4
                    c-0.3,0.2-0.5,0.4-0.7,0.7c-0.2,0.3-0.3,0.6-0.4,0.9C394.8,168.3,394.8,168.7,394.8,169L394.8,169z"/>
                    <path d="M375.9,164.7c0.1,0.5,0.4,1,0.7,1.4c0.4,0.4,0.9,0.7,1.4,0.8c0.5,0.1,1.1,0.1,1.6-0.1c0.5-0.2,1-0.6,1.3-1
                    c0.3-0.5,0.5-1,0.5-1.5s-0.1-1.1-0.4-1.6c-0.3-0.5-0.7-0.8-1.2-1c-0.5-0.2-1.1-0.3-1.6-0.2c-0.7,0.1-1.4,0.6-1.8,1.2
                    C376,163.2,375.8,163.9,375.9,164.7z"/>
                    <path className="st11" d="M390.2,170.2c1,1.8,1.7,3.6,2.8,4.8c0.2,0.2,0.3,0.5,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7
                    c-1.6,1.6-5.1,2.2-7.2,2.5c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1-0.1-0.1-0.2c0-0.1,0-0.1,0-0.2s0.1-0.1,0.1-0.1c2.4-1.1,3.5-2.1,4.1-2.7
                    c0.1-0.1,0.2-0.3,0.3-0.5c0.1-0.2,0.1-0.4,0-0.6c-0.2-0.9-0.5-2.7-0.6-4.1c0-0.1,0-0.2,0.1-0.3c0.1-0.1,0.1-0.2,0.2-0.2
                    c0.1,0,0.2,0,0.3,0C390,170,390.1,170.1,390.2,170.2L390.2,170.2z"/>
                    <path className="st11" d="M388.6,185.6c-0.1-0.4-0.3-0.7-0.6-0.9c-0.3-0.2-0.7-0.2-1.1-0.2c-6.5,1.6-11.8-2.9-12.6-4.1
                    c-0.1-0.3-0.3-0.6-0.6-0.8c-0.3-0.2-0.6-0.3-1-0.2c-0.3,0-0.6,0.2-0.9,0.5c-0.2,0.3-0.3,0.6-0.3,0.9c0,0.9,1,1.9,2,2.8
                    c0.2,0.2,0.4,0.3,0.6,0.5c1.5,1.2,5.2,3.5,9.9,3.5c1.2,0,2.3-0.1,3.5-0.4c0.2,0,0.3-0.1,0.5-0.2c0.1-0.1,0.3-0.2,0.4-0.4
                    c0.1-0.2,0.2-0.3,0.2-0.5C388.7,186,388.7,185.8,388.6,185.6L388.6,185.6z"/>
                    <path className="st24" d="M374.6,159.7c-0.3,0-0.6-0.1-0.9-0.3c-0.2-0.2-0.4-0.5-0.5-0.8c-0.1-0.3,0-0.6,0.1-0.9
                    c0.1-0.3,0.4-0.5,0.6-0.7c4.3-2.2,7.9-0.6,8.1-0.5c0.3,0.2,0.6,0.4,0.7,0.8c0.1,0.3,0.1,0.7-0.1,1c-0.2,0.3-0.4,0.6-0.8,0.7
                    s-0.7,0.1-1,0c-0.1-0.1-2.6-1.1-5.6,0.4C375.1,159.6,374.9,159.7,374.6,159.7L374.6,159.7z"/>
                    <path className="st24" d="M401.8,167.5c-0.3,0-0.6-0.1-0.8-0.3s-0.4-0.4-0.5-0.7c-0.2-0.6-0.5-1.1-0.9-1.5c-0.4-0.4-0.9-0.8-1.5-0.9
                    c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.2-0.4-0.4c-0.1-0.2-0.2-0.3-0.2-0.5c0-0.2,0-0.4,0-0.5c0.1-0.4,0.3-0.7,0.7-0.8
                    c0.3-0.2,0.7-0.2,1.1-0.1c1,0.3,1.9,0.9,2.7,1.6c0.7,0.8,1.3,1.7,1.6,2.7c0.1,0.4,0.1,0.7-0.1,1.1c-0.2,0.3-0.5,0.6-0.8,0.7
                    C402,167.5,401.9,167.5,401.8,167.5L401.8,167.5z"/>
                    <path className="st24" d="M349.2,165.9c10.9,1.6,23.1-10.6,30.9-15.7c8.6-0.6,17-6.9,18.6-9.8c-0.2-11.3-6.5-17.6-29.7-17.5
                    C354.8,123,330.6,141.2,349.2,165.9L349.2,165.9z"/>
                    <path className="st24" d="M372.3,148.5c0,0,22.2,4.7,31.6,14.5c11.2-8.5,3.7-19.4-5.6-25.7C390.2,138,372.3,148.5,372.3,148.5
                    L372.3,148.5z"/>
                    <path className="st4" d="M356.9,162.7c2.6,3.6,2.4,8.2-0.6,10.4c-2.9,2.1-7.4,1-10.1-2.6c-2.6-3.6-2.4-8.2,0.6-10.4
                    C349.7,157.9,354.2,159.1,356.9,162.7z"/>
                    <path className="st11" d="M352.4,171.1c0.1,0,0.3-0.1,0.4-0.2c1.8-1.4,2.5-3.2,1.8-5.1c-0.7-1.9-2.5-3.2-4.4-3.1c-0.1,0-0.3,0-0.4,0.1
                    c-0.1,0.1-0.2,0.1-0.3,0.2c-0.1,0.1-0.2,0.2-0.2,0.3c0,0.1-0.1,0.2-0.1,0.4c0,0.1,0,0.3,0.1,0.4c0.1,0.1,0.1,0.2,0.2,0.3
                    c0.1,0.1,0.2,0.2,0.3,0.2c0.1,0,0.2,0.1,0.4,0.1c1,0,2.2,0.8,2.5,1.8c0.4,1,0,2-1.2,2.9c-0.2,0.1-0.3,0.3-0.4,0.5
                    c-0.1,0.2,0,0.4,0.1,0.6c0.1,0.2,0.2,0.4,0.4,0.5C351.9,171.1,352.2,171.1,352.4,171.1L352.4,171.1z"/>
                    <path className="st13" d="M333.7,205.1c0,0,14.1-5.1,19.5-4.6c5.1,12.4,10.4,20.8,11.6,40.2c1.2,19.5-3.6,27.1,0.5,55.7
                    c-11.5,2.4-32.3,2.2-44-6.7c5.8-11.7,6.4-13.6,6.2-31.9C327.5,239.6,333.7,205.1,333.7,205.1L333.7,205.1z"/>
                    <path className="st16" d="M325.4,210.6c-10.4,16.3-12.9,68-0.4,85.6c12.5,0.2,24.8-11.1,24.8-11.1s-3.9-43.2-2-55.3
                    c0.8-4.9,4-17.9-2.1-21.7C336.4,202.3,328,206.5,325.4,210.6L325.4,210.6z"/>
                    <path className="st6" d="M427.5,291.5c-0.6-2.6-0.2-5.3,0.9-7.7l5.8-12.4l-6.7-3.1l-5.8,12.5c-1.1,2.4-2.9,4.3-5.2,5.5l-0.1,0.1
                    c-2.6,1.5-4.8,3.7-6,6.5l-12.8,26.9c-0.2,0.4-0.3,0.8-0.3,1.2c0,0.4,0,0.8,0.2,1.2c0.1,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.6,0.6,1,0.7
                    l10.4,4.9c0.4,0.2,0.8,0.3,1.2,0.3c0.4,0,0.8,0,1.2-0.2c0.4-0.1,0.8-0.4,1.1-0.7c0.3-0.3,0.6-0.6,0.7-1l12.7-26.8
                    C427.8,297.7,428.1,294.6,427.5,291.5L427.5,291.5z"/>
                    <path className="st6" d="M432.8,272.8l-5.6-2.6c-0.1-0.1-0.2-0.1-0.3-0.2c-0.1-0.1-0.2-0.2-0.2-0.3c0-0.1-0.1-0.3-0.1-0.4
                    c0-0.1,0-0.3,0.1-0.4l0.5-1.2c0.1-0.1,0.1-0.2,0.2-0.3c0.1-0.1,0.2-0.2,0.3-0.2c0.1,0,0.3-0.1,0.4-0.1c0.1,0,0.3,0,0.4,0.1
                    l5.6,2.6c0.1,0.1,0.2,0.1,0.3,0.2c0.1,0.1,0.2,0.2,0.2,0.3c0,0.1,0.1,0.3,0.1,0.4c0,0.1,0,0.3-0.1,0.4l-0.5,1.2
                    c-0.1,0.2-0.3,0.4-0.6,0.5C433.3,272.9,433.1,272.9,432.8,272.8L432.8,272.8z"/>
                    <path className="st4" d="M395.3,301.8c0,0-43.3-13.5-51.3-16.8c-8.4-3.5-15,8.2-16.9,16.7c24.2,8.7,44.7,10.1,66.7,9.1
                    C394,307.7,394.5,304.7,395.3,301.8z"/>
                    <path className="st4" d="M392.8,310.5c1-3.2-0.2-6.5,1.4-8.5c0.9-1.2,3.8-1.2,6.1-2.5c2.2-1.2,2.7-3.9,5.4-5.4
                    c2.3-1.3,3.5-1.4,4.1-0.6l-2.7,5.8c-0.3,1.7,1.2,4,8,6.4c5.2,1.9,9.7,1.3,9.4,4.3c-0.2,2-2.9,1-6.6-0.1c4,2.5,5.1,1,5.2,3.5
                    c0,3-6.9,0.7-10-0.9c3.7,3.8,7.9,2.1,7.9,4.1c-0.1,2.2-4.2,3.3-9-0.2c3.7,3.8,6.3,2.4,5.9,4.3c-0.4,1.9-8.3,1.9-15.2-2.4
                    C401,317.1,392.8,310.5,392.8,310.5L392.8,310.5z"/>
                    <path className="st25" d="M349.4,279.7c1.5-0.5,0.1,2.8,1.1,4.3c1,1.5,2.9,1.9,1.4,3.3c-1.5,1.4-13.2,15-26.4,13.6
                    c-2-1.8-7.6-9.2-6.2-15.6C325.9,287.4,349.4,279.7,349.4,279.7L349.4,279.7z"/>
                    <path className="st16" d="M353.5,196.3l-8.3,5l8.1,16.3l9.2,6.7C362.4,224.3,358.2,208.7,353.5,196.3L353.5,196.3z"/>
                    <path className="st16" d="M374.9,200.3l8.3,4.9l6.1,10.7l-1.2,8l-12.9-20L374.9,200.3z"/>
                    <path className="st18" d="M478,336.6l-0.5,6.1l-13.8,177c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.8-0.8,1H348.9c-0.4-0.3-0.6-0.6-0.8-1
                    c-0.2-0.4-0.3-0.8-0.3-1.3l-5.5-77.7l-2.2-30.9l-4.8-68.3l-0.6-8.5c0-1.9,7.4-9.5,9.9-9.5l5.7,0l22.4,0.1l20.4,0.1l7.9,0l13.7,0.1
                    l53.9,0.3C474.3,323.4,478,334.6,478,336.6L478,336.6z"/>
                    <path className="st20" d="M484.6,324.7H326.4c-2.6,0-4.7,1.5-4.7,3.4v11.2c0,1.9,2.1,3.4,4.7,3.4h158.2c2.6,0,4.7-1.5,4.7-3.4v-11.2
                    C489.2,326.2,487.1,324.7,484.6,324.7z"/>
                    <path className="st20" d="M407.2,413.8h-1.7c-3.4,0-6.7-1.4-9.1-3.8c-2.4-2.4-3.8-5.7-3.8-9.1v-18.6h27.4v18.6c0,3.4-1.4,6.7-3.8,9.1
                    C413.9,412.4,410.6,413.8,407.2,413.8L407.2,413.8z"/>
                    <path className="st20" d="M408.7,434.9h-4.6v-27h4.6V434.9z"/>
                    <path className="st20" d="M415.3,434.9c0-2.9-4-5.3-8.9-5.3s-8.9,2.4-8.9,5.3H415.3z"/>
                    <path className="st20" d="M417.3,442.5c-6.7,2-13.8,2.1-20.6,0.4c-6.8-1.7-13-5.3-17.9-10.3c-4.9-5-8.4-11.2-10.1-18
                    c-1.7-6.8-1.5-13.9,0.5-20.6c4.8-15.7,19.2-27.2,36.4-27.5c0.3,0,0.5,0,0.8,0c21.4,0,38.8,17.4,38.8,38.8
                    C445.2,422.9,433.4,437.8,417.3,442.5L417.3,442.5z M406.4,363.8c-0.5,0-1,0-1.5,0c-17,0.6-31.5,11.5-37.3,26.7
                    c-2.7,7.2-3.4,14.9-2,22.5c1.4,7.5,4.9,14.5,10.1,20.2c5.1,5.7,11.8,9.8,19.1,11.9c7.4,2.1,15.2,2.2,22.5,0.2
                    c17.6-4.8,30.5-20.9,30.5-40C447.9,382.4,429.3,363.8,406.4,363.8L406.4,363.8z"/>
                </g>
                </a>
                <a className='cursor-default btgirl3'>
                <g>
                    <path className="st11" d="M631.5,211.7c14.7,5.3,23.9,34.7,19.4,71.2c-11.5,7.4-16.8-12.6-16.8-12.6s-6.2-23.1-9.4-32
                    C617.1,217,624.4,209.1,631.5,211.7L631.5,211.7z"/>
                    <path className="st10" d="M544.4,222.8c-9.7-14.4-17.7-36.8-2.6-42.9c15.1-6,12.6-17.7,9.2-29c-3.4-11.3,8.8-33.7,33.7-35.2
                    c27.6-1.6,47.4,18.1,45.5,29.5c-1.8,11.4-0.3,18.6,8.2,22.6c8.5,3.9,17.7,19.9,7,33c-10.7,13.2,0.7,6.2-0.1,19.1
                    C644.5,232.8,554.7,238,544.4,222.8L544.4,222.8z"/>
                    <path className="st22" d="M598.3,360.1l39.8-23.3l-12-39.2l-64.2,1.1l-9.4,40.1l40.9,21.4L598.3,360.1z"/>
                    <path className="st26" d="M650.7,234.8c-4.3-16.6-22.1-26.5-26.1-27.6l-3.1-0.7l-5.5,24.9c0,0,7.3,9.8,12.4,18.6L650.7,234.8
                    L650.7,234.8z"/>
                    <path className="st11" d="M552.4,213.2c-14.4,6-23,43.5-24.7,66.2c11.8,6.8,17.5-6.7,17.5-6.7s12.5-24.3,15.2-33.3
                    C567,217.8,559.3,210.3,552.4,213.2L552.4,213.2z"/>
                    <path className="st26" d="M534.8,236.7c3.3-17.5,20-26.8,23.9-28l3.1-0.8l6.7,24.6c0,0-8.1,9.6-12.7,18.7L534.8,236.7L534.8,236.7z"/>
                    <path className="st6" d="M626.5,245.5c-0.1-3.7-2.2-10-4.7-14.1c-2.1-3.4-1.4-8.1-1.5-12.2c-0.1-4.5,0-8.6,1.2-12.7
                    c0,0-8.9-3.1-19.1-3.9l-22.2,0.8c-11.2,1.4-19.4,4.5-19.4,4.5c1.5,4.2,2.3,8.5,2.4,12.9c0.1,4.3,0.3,8.7-1.6,12.4
                    c-2.1,4.1-3.8,10.3-3.7,13.9c0.1,3.2,1.7,5.5,4.3,11.4l-1.3,41.9l65.7-1.2l-4.2-41.3C624.3,253.5,626.5,248.8,626.5,245.5
                    L626.5,245.5z"/>
                    <path className="st7" d="M568,239.8l10.3,16.2c-3.3,3.9-19.6,20.5-27.1,32.9c-10.6,17.7-7.8,61,33,61c40,0,38.4-33.8,35.9-50.9
                    c-3-20.7-37.1-39.4-33.3-44.1c3.8-4.7,8.1-19.7,8.1-19.7l-12.8,4.8l-9.8-4.8L568,239.8L568,239.8z"/>
                    <path className="st27" d="M582.4,258c0,0,7.7,25.5,13.9,31.1C602.5,294.8,586.6,266.7,582.4,258z"/>
                    <path className="st27" d="M587.5,260.7c0,0,11.4,23.8,17,22.1C610.1,281.1,587.5,260.7,587.5,260.7L587.5,260.7z"/>
                    <path className="st27" d="M580.2,256c0,0-3.1-3.7-5-11c3,3.3,5.4,5.2,6.2,8.3c2-4.1,5.1-7.8,5.2-7.5c-0.2,4.1-3.5,10.8-3.5,10.8
                    L580.2,256L580.2,256z"/>
                    <path className="st6" d="M590.5,322.7c2.1-1.7,5.1-1.4,5.1-1.4s-0.4,3-2.4,4.7c-2.1,1.7-5.1,1.4-5.1,1.4S588.4,324.4,590.5,322.7z"/>
                    <path className="st28" d="M565.9,298.7c0,0,0.8,4.1-1.3,6.5c-2.1,2.4-4.9,3.4-6.3,2.2c-1.4-1.2-0.8-4.1,1.3-6.5
                    C561.7,298.5,565.9,298.7,565.9,298.7z"/>
                    <path className="st28" d="M579.4,310.2c0,0-3.5,2.4-6.5,1.4c-3-1-4.5-4.9-4.5-4.9s3.5-2.4,6.5-1.4
                    C577.9,306.3,579.4,310.2,579.4,310.2L579.4,310.2z"/>
                    <path className="st29" d="M580.2,315.5c0,0,1.5,5.1-1.8,6.7c-1.2-0.1-1.1-3.4-1.1-3.4l-0.5-3L580.2,315.5z"/>
                    <path className="st30" d="M577.4,318.8c0,0-0.3,2.1,5,4.6c-1.6,1-5.7,1.3-5.7,1.3l-2-3.8L577.4,318.8z"/>
                    <path className="st14" d="M577.4,318.8c-0.1,0.6,0,1.2,0.1,1.7c0.2,0.6,0.4,1.1,0.8,1.5c-7.8-1.3-15.2-4.3-19.6-10.3
                    c-0.4-0.8-0.7-1.6-0.9-2.5c-0.5-2.8,0.9-5,3.1-5.7c0.8-0.3,1.6-0.3,2.4-0.2c2,0.3,4.2,1.5,6,4.1c2.1,3,4.6,4.6,7.4,5.1
                    c0.4,0.7,0.9,1.3,1.5,1.8c0.6,0.5,1.3,0.9,2.1,1.1c-0.7,0.2-1.4,0.7-1.9,1.3C577.8,317.3,577.5,318,577.4,318.8L577.4,318.8z"/>
                    <path className="st31" d="M582.5,323.8C582.5,323.8,582.5,323.8,582.5,323.8c-10.9-0.9-21.8-4.5-26.5-14.1c-0.1-0.3-0.3-0.6-0.4-0.9
                    c-1.2-3.7,2.5-6,5.4-5.2c0.6,0.2,1.2,0.5,1.7,1c0.5,0.5,0.8,1,1,1.7l0,0c3.2,4.9,7.6,7.5,15.3,8.7c0.4,0.2,0.9,0.4,1.4,0.5
                    c-1,0.3-1.8,1-2.3,1.9s-0.7,2-0.4,3c0.1,0.6,0.4,1.2,0.9,1.8c0.5,0.6,1.1,1,1.8,1.2c0.7,0.2,1.5,0.3,2.2,0.1h0
                    C582.4,323.5,582.5,323.6,582.5,323.8L582.5,323.8z"/>
                    <path className="st14" d="M586.2,327c-1.4,0.9-3.4,1.5-6.1,1.7c-2,0.2-4,0.2-6,0c-3.7-0.2-7.3-1-9.7-2.3c-1.2-0.7-2.3-1.3-3.4-2
                    c-10-6.5-9-13.1-5.1-14.9c0.6-0.3,1.2-0.4,1.9-0.5c2.3-0.2,5,1,7.2,4c3.3,4.4,7.9,5.6,12.3,5.5c-0.1,0.7,0,1.3,0.2,2
                    c0.2,0.6,0.6,1.2,1.1,1.6c0.5,0.5,1.1,0.8,1.7,1c0.6,0.2,1.3,0.2,2,0h0c0,0.1,0,0.3,0.1,0.4c0,0,0,0,0,0c0.2,0.9,0.7,1.6,1.3,2.2
                    C584.5,326.6,585.3,326.9,586.2,327L586.2,327z"/>
                    <path className="st15" d="M578.6,325.4c0.1,0.4,0.8,0.5,1.5,0.4c0.8-0.2,1.3-0.6,1.2-1c-0.1-0.4-0.8-0.5-1.5-0.4
                    C579.1,324.6,578.5,325,578.6,325.4z"/>
                    <path className="st15" d="M575.4,326.4c-0.1,0.4,0.4,0.8,1.2,1.1c0.8,0.2,1.5,0.1,1.6-0.2c0.1-0.4-0.4-0.8-1.2-1.1
                    C576.3,325.9,575.6,326,575.4,326.4L575.4,326.4z"/>
                    <path className="st15" d="M558.1,319.9c-0.3,0.1-0.3,0.7,0,1.3c0.3,0.6,0.8,0.9,1.1,0.8c0.3-0.1,0.3-0.7,0-1.3
                    C558.8,320.1,558.3,319.7,558.1,319.9z"/>
                    <path className="st15" d="M555.2,316.3c-0.3,0.1-0.3,0.7,0,1.3c0.3,0.6,0.8,0.9,1.1,0.8c0.3-0.1,0.3-0.7,0-1.3
                    C555.9,316.5,555.4,316.2,555.2,316.3z"/>
                    <path className="st15" d="M574.3,324.3c0,0.3,0.5,0.5,1.1,0.5c0.6,0,1.1-0.3,1.1-0.6c0-0.3-0.5-0.5-1.1-0.5
                    C574.8,323.8,574.3,324,574.3,324.3z"/>
                    <path className="st15" d="M557.5,318.5c0.1,0.3,0.7,0.3,1.2,0c0.6-0.3,0.9-0.7,0.8-1c-0.1-0.3-0.7-0.3-1.2,0
                    C557.8,317.8,557.4,318.3,557.5,318.5L557.5,318.5z"/>
                    <path className="st32" d="M605.9,288.1c0.8-0.6,1.5-1.3,2.2-2.1c1-1.3,0.9-2.4,0.9-2.4c-0.3-1-1.5-1-2-0.4c0,0,1.7,1.3-1.4,4.1
                    L605.9,288.1L605.9,288.1z"/>
                    <path className="st15" d="M601.3,284c0,0,1.3,2.1,0.4,5.3c-0.6,0.4-4.4,2.7-4.4,2.7l-0.3,3c0,0-2,2.7-4.2,2.9c1.1,0.7,2.6,1.1,3.5,1
                    c0.2,0.8,1.7,1.8,2.4,1.7c0.4,0.9,1.1,2.4,2.1,2.9c-0.1-0.4-0.6-3.2-0.4-4.2c0.7-0.4,4.9-3.4,4.9-3.4l1.6-4.6c0,0,1.5-0.9,2.3-0.8
                    c0.8,0.1,1.6,0.3,1.6,0.3s-1.7-1.6-3.6-1.1c-0.1-0.4-1.9-2.2-3.2-2.1C603.8,287,602.7,284.3,601.3,284L601.3,284z"/>
                    <path className="st31" d="M603.7,284.9c-0.7-0.5-1.5-0.8-2.4-0.9c1.2,0.7,2,1.9,2.4,3.6c0.6,0,1.2,0.2,1.8,0.5
                    c0.5,0.3,0.9,0.9,1.2,1.5c1.7-0.2,3.2,0.2,4.2,1c-0.4-0.8-1-1.5-1.8-2c-0.9-0.6-1.9-1-2.9-1.2l-0.3-0.1c-0.1-0.2-0.2-0.3-0.3-0.4
                    C605.1,286.1,604.5,285.5,603.7,284.9L603.7,284.9z"/>
                    <path className="st31" d="M592.9,297.9c0.4,0.6,0.9,1.2,1.6,1.6c0.5,0.4,1.1,0.6,1.7,0.8c0.3,0.1,0.5,0.2,0.8,0.3
                    c0.2,0.3,0.4,0.6,0.6,0.8c0.4,0.4,0.8,0.8,1.2,1.1c0.6,0.4,1.4,0.8,2.1,0.9c-1-0.6-1.7-1.6-2.1-2.9c-0.5,0-1.1-0.2-1.5-0.5
                    c-0.4-0.3-0.8-0.7-1-1.2C594.9,299,593.8,298.6,592.9,297.9L592.9,297.9z"/>
                    <path className="st30" d="M585.3,315.8c0,0,1.5,1.4,0.5,3.2c0.5,0.5,3.1,1.6,3.1,1.6l1-3.6l-3.1-2.3L585.3,315.8L585.3,315.8z"/>
                    <path className="st29" d="M601.9,321.4c-1,1.1,0,3.5,2.1,5.3c2.1,1.8,4.6,2.4,5.6,1.3c1-1.1,0-3.5-2.1-5.3
                    C605.3,320.9,602.8,320.3,601.9,321.4L601.9,321.4z"/>
                    <path className="st31" d="M602,320.6c-1,1.1,0,3.5,2.1,5.3c2.1,1.8,4.6,2.4,5.6,1.3c1-1.1,0-3.5-2.1-5.3
                    C605.5,320.1,603,319.5,602,320.6L602,320.6z"/>
                    <path className="st33" d="M585,315.8l0.3,0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1l-0.1,0L585,315.8L585,315.8z"/>
                    <path className="st14" d="M585.3,315.8c0.5,0,1.1,0,1.6,0.2c0.5,0.2,0.9,0.5,1.3,0.9c0.2,0.2,0.3,0.4,0.4,0.6c0.2,0.4,0.4,0.9,0.5,1.3
                    c0.1,0.5,0,0.9-0.1,1.4c0,0.1-0.1,0.3-0.1,0.4c1.3,0.5,2.8,1,4.3,1.4c8.5,2.3,14.1,1.6,14.6-0.3c0.2-0.7,0.2-1.5,0.1-2.2l0,0
                    c0-0.1-0.1-0.3-0.1-0.4c-0.2-0.6-0.5-1.1-1-1.3c-0.1,0-0.1,0-0.2-0.1l-21-5.7c-0.3,0.6-0.7,1.1-1.2,1.5
                    C585,314.1,585.3,314.9,585.3,315.8L585.3,315.8z"/>
                    <path className="st34" d="M585,315.8l0.3,0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1l-0.1,0L585,315.8L585,315.8z"/>
                    <path className="st30" d="M585.3,315.8c0.5,0,1.1,0,1.6,0.2c0.5,0.2,0.9,0.5,1.3,0.9c0.2,0.2,0.3,0.4,0.4,0.6c1.7,0.7,3.5,1.3,5.3,1.8
                    c4.9,1.3,13.4,2.5,14,0.5c0-0.1,0-0.2,0-0.3c0-0.1,0-0.3-0.1-0.4c-0.2-0.5-0.6-0.9-1.1-1.3l-0.1-0.1c-2-1.6-6.1-3.3-11-4.6
                    c-3.7-1-7.2-1.6-9.7-1.7c0,0,0,0.1,0,0.1c-0.2,0.8-0.7,1.5-1.4,1.9C585,314.1,585.3,314.9,585.3,315.8L585.3,315.8z"/>
                    <path className="st32" d="M585.3,315.8C585.3,315.8,585.3,315.8,585.3,315.8l-0.1-0.1C585.2,315.8,585.2,315.8,585.3,315.8
                    L585.3,315.8z"/>
                    <path className="st32" d="M585.7,312c-0.3,0.6-0.7,1.1-1.2,1.5c0.5,0.7,0.8,1.5,0.8,2.3c0.5,0,1.1,0,1.6,0.2s0.9,0.5,1.3,0.9
                    c1.8,0.5,3.5,1,5.3,1.3c6.3,1.2,11.8,0.9,13.2-0.4l0,0c0.2-0.2,0.3-0.4,0.3-0.6l0.3-1.4c0.3-1.9-5.6-2.5-12.7-3.8
                    c-3.4-0.6-6.4-1.3-8.7-1.6c0,0.4,0,0.7-0.1,1.1c0,0,0,0.1,0,0.1C585.8,311.7,585.8,311.8,585.7,312L585.7,312z"/>
                    <path className="st35" d="M586.5,308.6l1.5,3.9c0,0,15.2,5.2,15.3,5.9c0.1,0.8-0.5,5.5-0.5,5.5l6.2-2.2l-0.4-5.5l-6.6-6.4L586.5,308.6
                    z"/>
                    <path className="st29" d="M598,313.8c-0.2,1.5,2.2,2.9,5.2,3.2c3,0.3,5.5-0.7,5.8-2.1c0,0,0.3-0.8,0.2-0.9c-0.3-1.3-2.7-2-5.5-2.3
                    C600.7,311.4,598.1,312.3,598,313.8L598,313.8z"/>
                    <path className="st31" d="M598.2,312.9c-0.2,1.5,2.2,2.9,5.2,3.2c3,0.3,5.7-0.6,5.8-2.1c0.2-1.5-2.2-2.9-5.2-3.2
                    C600.9,310.5,598.3,311.4,598.2,312.9z"/>
                    <path className="st29" d="M588.3,310.7c-0.8,1.4,0.7,3.7,3.3,5.2s5.4,1.6,6.2,0.3c0.8-1.4-0.7-3.7-3.3-5.2
                    C591.8,309.5,589.1,309.4,588.3,310.7z"/>
                    <path className="st31" d="M588.2,309.6c-0.8,1.4,0.7,3.7,3.3,5.2c2.6,1.5,5.4,1.6,6.2,0.3c0.8-1.4-0.7-3.7-3.3-5.2
                    C591.7,308.4,588.9,308.3,588.2,309.6z"/>
                    <path className="st6" d="M608.2,308.9c1.9,0.7,1.7,2.3,0,2.3c-1.7,0-3,0.9-4.4,1.4c-1.4,0.5-5.4-1.6-7-1.7c-1.6-0.1-3,0.7-3.8-0.3
                    c-0.9-1-3.4-2-5-1.9s-3-0.6-3.2-1.5c-0.2-0.8-0.5-0.6-2-0.6c-3.1,0.1-2.8-2.1-1.8-2.3c4.5-0.7,5.3-2.3,10.4-1.4
                    C593,303.5,608.2,308.9,608.2,308.9L608.2,308.9z"/>
                    <path className="st14" d="M612.7,313.9c1.1-1.9,1.8-6.7-8.3-12.4c-10.3-5.8-13.8-2.6-14.9-0.7v0c0,0,0,0,0,0c-0.5,1.2,4.1,5,10.5,8.6
                    C606.2,313,611.9,314.9,612.7,313.9L612.7,313.9L612.7,313.9L612.7,313.9L612.7,313.9z"/>
                    <path className="st34" d="M606.4,308.2c-0.1,0.2,0.2,0.6,0.7,0.8c0.5,0.2,1,0.3,1.1,0c0.1-0.2-0.2-0.6-0.7-0.8
                    C607,308,606.5,308,606.4,308.2z"/>
                    <path className="st34" d="M593.7,302.3c0,0.2,0.4,0.5,1,0.5c0.6,0.1,1-0.1,1-0.3c0-0.2-0.4-0.5-1-0.5
                    C594.2,301.9,593.7,302.1,593.7,302.3z"/>
                    <path className="st36" d="M600.9,303.1c0,0.2,0.4,0.5,1,0.5c0.6,0.1,1-0.1,1-0.3c0-0.2-0.4-0.5-1-0.5
                    C601.4,302.7,600.9,302.9,600.9,303.1z"/>
                    <path className="st37" d="M585,315.8l0.3,0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1l-0.1,0L585,315.8L585,315.8z"/>
                    <path className="st37" d="M585,315.8l0.3,0.2c0,0,0-0.1,0-0.1c0,0,0,0,0-0.1l-0.1,0L585,315.8L585,315.8z"/>
                    <path className="st32" d="M568.9,284.5c-0.4-1.1-1-2.2-1.7-3.2c-1.2-1.5-2.5-1.8-2.5-1.8c-1.2,0-1.7,1.4-1,2.2c0,0,2-1.5,4.3,2.9
                    L568.9,284.5L568.9,284.5z"/>
                    <path className="st15" d="M562.6,288.6c0,0,2.9-0.9,6.3,1.2c0.2,0.8,1.8,6,1.8,6l3.4,1.3c0,0,2.5,3.2,2.1,5.8c1.1-1,2.1-2.6,2.2-3.8
                    c1,0,2.7-1.4,2.8-2.3c1.2-0.1,3.2-0.5,4-1.6c-0.5,0-4-0.4-5.1-0.9c-0.3-1-2.4-6.8-2.4-6.8l-4.8-3.3c0,0-0.6-2.1-0.2-2.9
                    c0.3-0.9,0.9-1.8,0.9-1.8s-2.4,1.4-2.5,3.9c-0.5,0-3.2,1.5-3.5,3.1C566.9,286.6,563.4,287.1,562.6,288.6L562.6,288.6z"/>
                    <path className="st31" d="M564.5,286.1c-0.8,0.7-1.4,1.5-1.8,2.5c1.2-1.2,2.9-1.7,5-1.7c0.2-0.7,0.6-1.4,1.2-1.9
                    c0.6-0.5,1.3-0.8,2.1-0.9c0.3-2.1,1.2-3.6,2.5-4.6c-1.1,0.2-2,0.7-2.9,1.4c-1,0.9-1.8,1.9-2.4,3c-0.1,0.1-0.1,0.2-0.2,0.4
                    c-0.2,0.1-0.4,0.1-0.6,0.2C566.3,284.8,565.3,285.4,564.5,286.1L564.5,286.1z"/>
                    <path className="st31" d="M576.2,302.9c0.9-0.3,1.7-0.7,2.4-1.3c0.6-0.5,1.1-1.1,1.5-1.7c0.2-0.3,0.4-0.6,0.6-0.9
                    c0.4-0.1,0.8-0.3,1.1-0.5c0.6-0.3,1.2-0.7,1.7-1.1c0.7-0.6,1.3-1.3,1.8-2.2c-1,1-2.4,1.5-4,1.6c-0.2,0.6-0.6,1.2-1.1,1.6
                    c-0.5,0.4-1.1,0.7-1.7,0.8C578,300.8,577.3,302.1,576.2,302.9L576.2,302.9z"/>
                    <path className="st32" d="M574.1,291.2c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1
                    c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.1c-0.1-0.1-0.2-0.1-0.2-0.2c-0.1-0.1-0.1-0.2-0.1-0.3c-0.1-0.4-0.2-1.3,0.2-1.4
                    C573.4,290.1,574,290.8,574.1,291.2z"/>
                    <path className="st32" d="M575.8,289.3c0.1,0,0.2,0.1,0.2,0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2,0,0.3c0,0.1-0.1,0.2-0.1,0.3
                    c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.2,0-0.3,0c-0.1,0-0.2-0.1-0.3-0.2c-0.4-0.2-1-0.9-0.7-1.2
                    C574.5,288.9,575.4,289,575.8,289.3z"/>
                    <path className="st6" d="M557.3,293.5c1.7,2,1.5,5.1,1.5,5.1s-3-0.3-4.8-2.3c-1.7-2-1.5-5.1-1.5-5.1S555.6,291.5,557.3,293.5z"/>
                    <path className="st6" d="M610.1,294.1c2.1-1.7,5.1-1.4,5.1-1.4s-0.4,3-2.4,4.7c-2.1,1.7-5.1,1.4-5.1,1.4S608,295.8,610.1,294.1z"/>
                    <path className="st27" d="M576.9,264.5c0,0-16.3,8.1-16.1,17.1C561.1,290.7,576.9,264.5,576.9,264.5L576.9,264.5z"/>
                    <path className="st4" d="M596.9,258.6l38.6,6.2c0,0,19.9,0.2,15.3,18.7c-8.3,4.8-18.9,3.2-55.3-15.5L596.9,258.6L596.9,258.6z"/>
                    <path className="st11" d="M583.8,256l-41.3,10.4c0,0-19.8,1.6-13.9,19.7c8.6,4.2,20.1,0,55-21.3C591.6,260.7,583.8,256,583.8,256
                    L583.8,256z"/>
                    <path className="st11" d="M579.7,189.6l22.4-0.5l0.4,16.7c0,0-4.2,2-11.9,2.2s-10.5-1.6-10.5-1.6L579.7,189.6L579.7,189.6z"/>
                    <path className="st11" d="M611.6,162.9c-1.6,4.6-0.2,9.2,3.1,10.3c3.3,1.1,7.3-1.7,8.8-6.3c1.6-4.6,0.2-9.2-3.1-10.3
                    C617.2,155.5,613.2,158.3,611.6,162.9L611.6,162.9z"/>
                    <path className="st4" d="M616.3,157.6c-1.5-16.1-1.3-32.6-26.8-32c-25.5,0.6-24.6,14.5-25.1,33.2c-0.5,19.6,13.7,35.2,26.8,35.7
                    C603.7,195,618.1,177.1,616.3,157.6L616.3,157.6z"/>
                    <path d="M602.1,165.6c-0.4,0.7-1.1,1.2-1.9,1.4c-0.8,0.2-1.6,0.1-2.3-0.3c-0.3-0.2-0.7-0.5-0.9-0.8c-0.2-0.3-0.4-0.7-0.5-1.1
                    c-0.1-0.4-0.1-0.8-0.1-1.2c0.1-0.4,0.2-0.8,0.4-1.1c0.4-0.7,1.1-1.2,1.9-1.4c0.8-0.2,1.6-0.1,2.3,0.3c0.3,0.2,0.7,0.5,0.9,0.8
                    c0.2,0.3,0.4,0.7,0.5,1.1c0.1,0.4,0.1,0.8,0.1,1.2C602.5,164.9,602.3,165.3,602.1,165.6L602.1,165.6z"/>
                    <path d="M578.9,166.8c-0.4,0.7-1,1.1-1.8,1.3c-0.7,0.2-1.5,0.1-2.2-0.3c-0.3-0.2-0.6-0.4-0.8-0.7c-0.2-0.3-0.4-0.6-0.5-1
                    c-0.1-0.4-0.1-0.7-0.1-1.1c0.1-0.4,0.2-0.7,0.4-1c0.4-0.7,1-1.1,1.8-1.3c0.7-0.2,1.5-0.1,2.2,0.3c0.3,0.2,0.6,0.4,0.8,0.7
                    c0.2,0.3,0.4,0.6,0.5,1c0.1,0.4,0.1,0.7,0.1,1.1C579.2,166.2,579.1,166.5,578.9,166.8L578.9,166.8z"/>
                    <path className="st10" d="M570.1,161.3c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2-0.3-0.5-0.4-0.8c0-0.3,0.1-0.6,0.3-0.9c3.3-4,7.6-3,7.8-3
                    c0.1,0,0.3,0.1,0.4,0.2c0.1,0.1,0.2,0.2,0.3,0.3c0.1,0.1,0.1,0.3,0.1,0.4s0,0.3-0.1,0.4c-0.1,0.3-0.3,0.6-0.5,0.7
                    c-0.3,0.2-0.6,0.2-0.9,0.2c-0.1,0-3.2-0.7-5.5,2.1c-0.1,0.2-0.4,0.3-0.6,0.4C570.6,161.4,570.4,161.4,570.1,161.3L570.1,161.3z"/>
                    <path className="st10" d="M604.9,159.8c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.2,0.3-0.5,0.3-0.9s-0.1-0.6-0.4-0.8c-3.6-3.8-7.8-2.5-8-2.4
                    c-0.1,0.1-0.3,0.1-0.4,0.2c-0.1,0.1-0.2,0.2-0.3,0.4c-0.1,0.1-0.1,0.3-0.1,0.4c0,0.2,0,0.3,0.1,0.4c0.1,0.3,0.3,0.5,0.6,0.7
                    c0.3,0.2,0.6,0.2,0.9,0.1c0.1,0,3.1-0.9,5.6,1.8c0.2,0.2,0.4,0.3,0.6,0.4C604.4,159.9,604.7,159.9,604.9,159.8L604.9,159.8z"/>
                    <path className="st11" d="M585.5,169c-0.8,1.9-1.5,3.8-2.5,5c-0.2,0.2-0.2,0.5-0.2,0.7c0,0.3,0.2,0.5,0.4,0.7c1.8,1.4,5.4,1.8,7.6,1.9
                    c0.1,0,0.1,0,0.2,0c0.1,0,0.1-0.1,0.1-0.2c0-0.1,0-0.1,0-0.2c0-0.1-0.1-0.1-0.2-0.1c-2.5-0.9-3.8-1.8-4.4-2.4
                    c-0.1-0.1-0.3-0.3-0.3-0.5c-0.1-0.2-0.1-0.4-0.1-0.6c0.1-0.9,0.3-2.7,0.3-4.2c0-0.1,0-0.2-0.1-0.3c-0.1-0.1-0.2-0.1-0.3-0.2
                    c-0.1,0-0.2,0-0.3,0C585.6,168.8,585.6,168.8,585.5,169L585.5,169z"/>
                    <path className="st7" d="M597,182.5c0.3-0.5-0.1-1.3-0.7-1.2c-4.3,0.8-8.8,1-13.1,0.5c-0.6-0.1-1,0.7-0.6,1.2c1.2,1.6,3.6,4,7.4,3.9
                    C593.7,186.8,595.9,184.2,597,182.5L597,182.5z"/>
                    <path className="st10" d="M561.2,155.1c0,0,22.6,1.5,39.4-11.3c2.5,4.2,10.4,9.9,15.3,12.4c4.9,2.5,0.6-23.3-3.7-27.5
                    c-4.3-4.2-33.4-7.6-39.7-4.5C566.2,127.3,561.2,155.1,561.2,155.1L561.2,155.1z"/>
                    <path className="st4" d="M595.5,267.9c0.5-3.1,2.9-5.4,2.4-7.7c-0.3-1.4-2.7-2.6-4.1-4.7c-1.3-2-0.6-4.4-2.2-6.8
                    c-1.4-2.1-2.1-2.5-2.9-2.1l-0.3,5.9c-0.5,1.6-2.9,2.8-9.6,2c-5.1-0.6-8.7-3.1-9.7-0.4c-0.7,1.7,2,2.1,5.6,2.7
                    c-4.4,0.4-4.7-1.4-5.8,0.7c-1.3,2.5,5.5,3.5,8.8,3.5c-4.7,1.6-7.6-1.6-8.4,0.1c-0.9,1.9,2.1,4.5,7.6,3.6c-4.8,1.6-6.3-0.6-6.8,1.1
                    c-0.5,1.8,6.1,5.2,13.8,4.5C585.9,270,595.5,267.9,595.5,267.9L595.5,267.9z"/>
                    <path className="st26" d="M609,224.1c2.4-2.8,4.7-0.2,4.1,1.8c-0.4,2.2-4.2,5.2-4.2,4.9C600.9,224.9,606.8,220.8,609,224.1L609,224.1z
                    "/>
                    <path className="st16" d="M661.5,336.6l-0.5,6.1l-13.8,177c0,0.5-0.1,0.9-0.3,1.3c-0.2,0.4-0.5,0.8-0.8,1H532.4
                    c-0.4-0.3-0.6-0.6-0.8-1c-0.2-0.4-0.3-0.8-0.3-1.3l-12.4-177l-0.6-8.5c0-1.9,7.4-9.5,9.9-9.5l20,0.1l7.8,0l5.7,0l17.3,0.1h2.6h1.6
                    l5.8,0l5.3,0l8.4,0h0.8l6.1,0h0.5l10,0l14.7,0.1l17.6,0.1C657.8,323.4,661.5,334.6,661.5,336.6L661.5,336.6z"/>
                    <path className="st13" d="M668,324.7H509.8c-2.6,0-4.7,1.5-4.7,3.4v11.2c0,1.9,2.1,3.4,4.7,3.4H668c2.6,0,4.7-1.5,4.7-3.4v-11.2
                    C672.6,326.2,670.5,324.7,668,324.7z"/>
                    <path className="st13" d="M589.8,444.1c-21.4,0-38.8-17.4-38.8-38.8c0-21.4,17.4-38.8,38.8-38.8c21.4,0,38.8,17.4,38.8,38.8
                    C628.6,426.7,611.2,444.1,589.8,444.1z M589.8,363.8c-22.9,0-41.5,18.6-41.5,41.5c0,22.9,18.6,41.5,41.5,41.5
                    c22.9,0,41.5-18.6,41.5-41.5S612.7,363.8,589.8,363.8L589.8,363.8z"/>
                    <path className="st13" d="M606.4,415c-2.5,1.2-6.5,1.7-9.4,0.1c2.5-6.4,8.2-12.2,8.3-12.4c-0.2,0.1-6.8,4.2-9.8,11.4
                    c-0.1-0.1-0.2-0.2-0.2-0.2c-2.9-3.2-2-8.5-0.5-10.9c2.7-4.4,8.6-8.4,18.6-8.4C615.1,404.1,612.1,412.4,606.4,415L606.4,415z"/>
                    <path className="st13" d="M571.8,389.8c0.3,0.3,9.2,8.1,16.4,18c-4.3,3-10.7,1.7-14.3-0.4c-6.6-3.8-9.8-13.4-7.3-23.6
                    c12.2-1.6,22.4,3,25,10.2c1.3,3.6,1.2,9.3-2.2,12.7C581.8,397.1,572.2,390,571.8,389.8L571.8,389.8z"/>
                    <path className="st13" d="M589.3,426.9c-4.7,3.7-13.2,2.5-20.8-3.4c4.4-8.5,11.5-13.2,17.3-12c2.1,0.5,5.1,1.9,6.6,4.5
                    c-6.3-0.9-13,3.2-13.4,3.4c0.4-0.2,8-3.2,14.1-1.5C594,421.7,591.5,425.2,589.3,426.9L589.3,426.9z"/>
                </g>
                </a>
                <a className='cursor-default btmen3'>
                <g>
                    <path className="st16" d="M784.7,191.6c0,0-36.6,17.8-44.6,51.3c7.2,13.8,43.4,12.4,43.4,12.4L784.7,191.6z"/>
                    <path className="st10" d="M793.1,302.7l51,26.8c0,0-41.6,78.5-65.5,102.8c-4.2,4.2-28-2.3-29.7-7.4
                    C742.7,406.3,793.1,302.7,793.1,302.7L793.1,302.7z"/>
                    <path className="st10" d="M782.8,522h-31.5c-0.3-37.9-2.2-62.7-2.9-81.3c0-1.1-0.1-2.2-0.1-3.3c-0.2-6-0.2-11.3,0.1-16.2
                    c0.1-1.7,0.2-3.4,0.3-5c0,0,0,0,0,0c0,0,0,0,0,0v0c0-0.6,0.1-1.2,0.2-1.8c0-0.1,0.1-0.2,0.1-0.3l0.1-0.2c1.1-2.1,2.7-3.9,4.7-5.3
                    c0.6-0.4,1.2-0.8,1.8-1.2c4.3-2.3,9.6-2.3,14.4,0c1.5,0.7,3,1.7,4.3,2.8c1.4,1.2,2.6,2.5,3.7,3.9c0.7,0.9,1.3,1.8,1.9,2.8
                    c0.7,1.3,1.4,2.6,1.9,3.9c0.2,0.5,0.4,0.9,0.6,1.4c0.1,0.4,0.2,0.9,0.4,1.4c0.3,1,0.5,2.1,0.7,3.2c0,0.2,0.1,0.4,0.1,0.5
                    c0.9,4.5,1.5,9.1,1.9,13.6c0.1,0.9,0.2,1.9,0.2,2.8C787.9,471.2,784.6,505.6,782.8,522L782.8,522z"/>
                    <path className="st9" d="M804.4,314.8l61-14.8c0,0,2.6,99.2-4.9,132.4c-1.3,5.8-22.8,12.3-26.9,8.9C818.6,429,804.4,314.8,804.4,314.8
                    L804.4,314.8z"/>
                    <path className="st9" d="M878.3,522H851c-10.1-31.4-15.1-51.5-18.5-66.5c-2.5-11.2-4.1-19.6-6.3-27.7l0-0.1c-1-8.6,3.6-14.9,9.8-17.2
                    c6.9-2.6,16-0.3,21.9,8.8c1.4,2.2,2.7,4.6,3.8,7l0,0C874.3,454,877.6,505.5,878.3,522L878.3,522z"/>
                    <path className="st11" d="M827.1,162.8L827.1,162.8l-11.4,0l-2.6,26.9l13.3,7l14.2-6.9v-30.8L827.1,162.8z"/>
                    <path className="st22" d="M826.7,164.1c10.3,1.3,15.8-11.8,15.8-11.8c6.3-1,5.3-7.3,5.3-7.3c3-0.9,7-4.1,5.8-9.8
                    c-1.1-4.9-5.8-4.7-6.4-10.4c-0.5-4.6,2.9-15.7-11.6-16.7c0,0-0.3-8.5-9-10.6c-10.7-2.6-13.4,3.9-18.1,5
                    c-5.3,1.3-16.5-3.7-18.1,8.5c0,0-7.5,0.1-11,5.5c-3.2,5.1-1.4,11.6,0.7,14c0,0-6,2-7.4,7.7c-1,3.8,0.5,8.8,4.3,11.2
                    c1.4,0.9,3,1.5,4.7,1.7c2.5,0.4,2.6,7,4.7,8.3c3.5,2.1,7.2,3.5,11,4.7L826.7,164.1L826.7,164.1z"/>
                    <path className="st4" d="M842.8,145.7c2.1,18.7-10.1,37-24.8,38.7c-14.7,1.7-26.4-13.9-28.5-32.6c-2.1-18.7,8.1-35.3,22.8-37
                    C827.1,113.1,840.7,127,842.8,145.7L842.8,145.7z"/>
                    <path d="M798.4,156.8c-0.1,0.5-0.3,1-0.6,1.4c-0.3,0.4-0.8,0.7-1.3,0.8c-0.5,0.1-1,0.1-1.5,0c-0.5-0.2-0.9-0.5-1.2-0.9
                    c-0.3-0.4-0.5-0.9-0.5-1.4c0-0.5,0.1-1,0.3-1.5c0.2-0.4,0.6-0.8,1.1-1c0.5-0.2,1-0.3,1.5-0.3c0.7,0.1,1.3,0.4,1.7,1
                    C798.3,155.4,798.5,156.1,798.4,156.8L798.4,156.8z"/>
                    <path d="M817.9,153c-0.1,0.5-0.3,1.1-0.6,1.5c-0.4,0.4-0.8,0.7-1.4,0.9c-0.5,0.2-1.1,0.1-1.6,0s-1-0.5-1.3-0.9
                    c-0.3-0.4-0.5-1-0.6-1.5c0-0.5,0.1-1.1,0.3-1.6c0.3-0.5,0.7-0.9,1.2-1.1c0.5-0.3,1-0.4,1.6-0.3c0.4,0,0.7,0.2,1,0.3
                    s0.6,0.4,0.8,0.7c0.2,0.3,0.4,0.6,0.5,1C818,152.2,818,152.6,817.9,153L817.9,153z"/>
                    <path className="st11" d="M806.5,155.6c-1.8,4.1-4,9.1-4,9.1s1.9,3.7,7.1,3c-2.3-0.6-4.7-1.7-4.3-4.8
                    C805.7,159.8,806.5,155.6,806.5,155.6z"/>
                    <path className="st22" d="M820.9,148.3c0.1-0.1,0.2-0.1,0.2-0.2c0.1-0.1,0.2-0.3,0.2-0.5c0-0.2,0.1-0.3,0-0.5s-0.1-0.3-0.2-0.5
                    c-0.1-0.1-0.2-0.3-0.3-0.4c-1.5-1.2-5.3-2.8-8.3-0.3c-0.3,0.2-0.4,0.5-0.5,0.9c0,0.3,0.1,0.7,0.3,0.9c0.2,0.3,0.5,0.4,0.9,0.5
                    c0.3,0,0.7-0.1,0.9-0.3c2.3-1.8,5.1,0.3,5.1,0.3c0.2,0.2,0.5,0.3,0.8,0.3C820.4,148.6,820.7,148.5,820.9,148.3L820.9,148.3z"/>
                    <path className="st22" d="M797.5,151.7c0.2-0.1,0.3-0.2,0.4-0.4c0.1-0.2,0.2-0.3,0.2-0.5c0-0.2,0-0.4-0.1-0.5
                    c-0.1-0.2-0.2-0.3-0.4-0.4c-0.1,0-2-1.2-4-0.5c-1.4,0.5-2.5,1.6-3.3,3.4c-0.1,0.1-0.1,0.3-0.2,0.4c0,0.2,0,0.3,0,0.5
                    c0,0.1,0.1,0.3,0.2,0.4c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0.1,0.3,0.1,0.5,0.1c0.2,0,0.3,0,0.4-0.1c0.1-0.1,0.3-0.2,0.4-0.3
                    c0.1-0.1,0.2-0.3,0.2-0.4c0.5-1.2,1.2-1.9,1.9-2.2c1.1-0.4,2.2,0.3,2.3,0.3c0.2,0.1,0.3,0.2,0.5,0.2
                    C797.2,151.8,797.4,151.8,797.5,151.7L797.5,151.7z"/>
                    <path className="st22" d="M782.1,133.8l28.3-25.2l35.9,18.4l-4.5,25.9c-0.9-12-8.2-8.1-12-11.6c-3.8-3.6-5.5-9.1-14.5-4.2
                    c0,0-7.3-5.1-11.1,2c-2.9,5.5-6.9,4.4-10.8,4.7c-4.6,0.3-3.6,4.8-6.4,4.7L782.1,133.8L782.1,133.8z"/>
                    <path className="st4" d="M838.5,144.8c-2.4,3.7-1.8,8.4,1.2,10.3c3.1,1.9,7.5,0.5,9.9-3.2c2.4-3.7,1.8-8.4-1.2-10.3
                    C845.3,139.6,840.9,141.1,838.5,144.8L838.5,144.8z"/>
                    <path className="st11" d="M843.5,152.9c-0.1,0-0.3-0.1-0.4-0.2c-1.9-1.2-2.7-3.1-2.1-4.9c0.5-1.9,2.3-3.3,4.2-3.4
                    c0.3,0,0.5,0.1,0.7,0.3c0.2,0.2,0.3,0.4,0.3,0.7c0,0.3-0.1,0.5-0.3,0.7c-0.2,0.2-0.4,0.3-0.7,0.3c-1,0-2.1,0.9-2.4,2
                    c-0.3,1,0.2,2,1.3,2.8c0.2,0.1,0.3,0.3,0.4,0.5c0.1,0.2,0.1,0.4,0,0.6c-0.1,0.2-0.2,0.4-0.4,0.5
                    C843.9,152.9,843.7,152.9,843.5,152.9L843.5,152.9z"/>
                    <path className="st11" d="M809.2,175.8c-0.8,0-1.6-0.1-2.4-0.2c-0.2,0-0.3-0.1-0.5-0.2c-0.1-0.1-0.3-0.2-0.4-0.3
                    c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.3,0-0.5c0-0.2,0.1-0.3,0.2-0.5c0.1-0.1,0.2-0.3,0.3-0.4c0.1-0.1,0.3-0.2,0.4-0.2
                    c0.2,0,0.3,0,0.5,0c7.9,1.2,12.1-4.8,12.3-5.1c0.2-0.3,0.5-0.4,0.8-0.5s0.7,0,0.9,0.2c0.3,0.2,0.5,0.5,0.5,0.8
                    c0.1,0.3,0,0.7-0.2,0.9C821.3,169.7,817.1,175.8,809.2,175.8L809.2,175.8z"/>
                    <path className="st13" d="M873.1,316.2c0,0-23.8,2.5-46.2,2.6c-22.3-0.2-46.2-4.5-46.2-4.5s3-41,2.7-56.8c-0.4-18.1,1.2-42.1,1.2-66
                    c0,0,15.8-2.6,24.8-3.7c1.5-0.2,2.7-0.3,3.7-0.4c0,0,5.1,10.7,27.3,0c1.4-0.1,2.8-0.1,4.2,0c9.3,0.6,24.3,4.1,24.3,4.1
                    c0,23.8,1,47.2,0.6,65.3C870.6,273,873.1,316.2,873.1,316.2L873.1,316.2z"/>
                    <path className="st6" d="M839.9,252.1l-59.4-3.6l3-50.4l59.3,3.6L839.9,252.1z"/>
                    <path className="st7" d="M839.8,231L806.4,219l6.2-17.4l33.4,11.9L839.8,231z"/>
                    <path className="st38" d="M804.7,251.4l-29.3,2.9l-4.8-47.8l34,3.4L804.7,251.4z"/>
                    <path className="st19" d="M800.1,259.1l-29.6,3l-4.7-47.2l29.6-3L800.1,259.1z"/>
                    <path className="st18" d="M840.6,284.9l-59,19.3l-19.4-78.6l59-19.3L840.6,284.9z"/>
                    <path className="st38" d="M849.8,287.6l-62,15.8l-20-78.4l62-15.8L849.8,287.6z"/>
                    <path className="st7" d="M846.5,303.6l-65.5,0.9l-1.1-81.4l65.5-0.9L846.5,303.6z"/>
                    <path className="st20" d="M838.7,294.8l-63.1,10.6l-13.5-79.8l63.1-10.6L838.7,294.8z"/>
                    <path className="st7" d="M787.6,219.9l18.7-12.8l3,7.5L787.6,219.9z"/>
                    <path className="st1" d="M795.5,302.1l3.3,8.5l22.7-12.9L795.5,302.1z"/>
                    <path className="st16" d="M844.7,187.5c-1.5,2.6-3.7,4.5-6.8,6.1c-4.9,2.5-11.5,3.5-15.6,3.2c-3.2-0.3-6.8-1.6-9.1-3.4
                    c-2.4-1.9-3.5-4.4-3.7-5.4c1.5-0.2,2.9-0.4,3.9-0.5c0,0,4.9,10.8,27.1,0.1C841.8,187.4,843.2,187.4,844.7,187.5L844.7,187.5z"/>
                    <path className="st4" d="M807.4,254c2.2-5,4.2-5.5,4.2-12.7c0-3.3-3-7.1-4.2-10c-1.9-4.5-4.2-8-5.3-7.9c-2.3,0.4-2.6,1.8-1.9,4.7
                    c0.5,2.2,2.7,4.5,1.7,6.3c-0.9,1.5-2.5,2.9-9.1-0.7c-8.3-4.6-10.5-7.7-12.3-5.1c-1.2,1.7,2.8,4.3,10.5,8.8
                    c-4.8-0.8-13.6-9.3-15.5-5.5c-1.4,2.8,11.6,8.9,15.2,9.8c-6.8-0.1-14.3-7.3-15.6-5.8c-2.6,3.3,7.7,9,13.8,9.5
                    c-6,1.1-9.8-3.4-10.9-1.8c-2,3,9.5,8.6,16.8,9.3L807.4,254L807.4,254z"/>
                    <path className="st4" d="M818.1,256.7l-10.7-2.7l3.1-14l10.9,3.4L818.1,256.7z"/>
                    <path className="st16" d="M864.4,251.5c-20.4-6.3-47.8-12.5-47.8-12.5c-5.2,7.4-4.2,17.5-4.2,17.5c29.3,23.6,69.7,31.7,82.7,25.8
                    c11.8-5.4,3.6-74.2-26.1-90.7l-1,0.1C857.8,208.9,864.4,251.5,864.4,251.5L864.4,251.5z"/>
                    <path className="st4" d="M778.2,284.3c2.3-5,1.3-6.8,6.6-11.6c2.4-2.2,7.2-2.6,10.2-3.6c4.6-1.6,8.8-2.2,9.3-1.4
                    c1.3,2,0.4,3.1-2.1,4.6c-2,1.1-5.2,1-5.8,3c-0.5,1.7-0.5,3.9,6.6,6.2c9,3.1,12.7,2.6,12,5.6c-0.4,2-5.1,0.8-13.6-1.9
                    c3.8,3.1,16,3.8,14.5,7.8c-1.1,2.9-14.4-2.6-17.4-4.7c4.7,5,15,5.7,14.8,7.7c-0.7,4.2-11.9,0.4-16.3-3.8c3.2,5.2,9.1,5,8.7,6.8
                    c-0.9,3.5-12.8-1.2-18.2-6.2L778.2,284.3L778.2,284.3z"/>
                    <path className="st4" d="M768.9,278.2l9.2,6.1l8.3-11.7l-9.8-5.8L768.9,278.2z"/>
                    <path className="st16" d="M740.1,242.9c0,0,10.5,30.2,34.6,42.9c1.7-5.2,7.5-18.5,7.5-18.5s-10.1-12-16.6-21.8
                    C761.5,244.8,740.1,242.9,740.1,242.9z"/>
                    <path className="st26" d="M826.5,522c0.3-0.3,0.6-0.6,0.8-1c0.2-0.4,0.3-0.8,0.3-1.2l5-64.2l1.1-14.2l2.4-30.9l5.3-67.8l0.5-6.1
                    c-0.1-0.7-0.2-1.4-0.4-2.1c-1.1-3.8-4.4-10.7-8.9-9.2l-26.7-0.1l-23.2-0.1l-74.2-0.4c-2.6,0-9.9,7.6-9.9,9.5l0.6,8.5l12.4,177
                    c0,0.4,0.1,0.9,0.3,1.2c0.2,0.4,0.5,0.7,0.8,1L826.5,522L826.5,522z"/>
                    <path className="st6" d="M848.3,324.8H690.1c-2.6,0-4.7,1.5-4.7,3.4v11.2c0,1.9,2.1,3.4,4.7,3.4h158.2c2.6,0,4.7-1.5,4.7-3.4v-11.2
                    C853,326.3,850.9,324.8,848.3,324.8z"/>
                    <path className="st6" d="M785.6,441c-5.9,2.6-12.3,3.6-18.8,3c-6.4-0.6-12.6-2.7-17.9-6.2c-5.4-3.5-9.8-8.3-12.8-14
                    c-3.1-5.7-4.7-12-4.7-18.4c0-19.5,14.4-35.6,33.1-38.4c1.9-0.3,3.8-0.4,5.7-0.4c17.2,0,31.9,11.3,36.9,26.9
                    c3,9.2,2.4,19.1-1.6,27.9C801.5,430.2,794.4,437.2,785.6,441L785.6,441z M808.9,390.6c-6-15.6-21.1-26.7-38.7-26.7
                    c-1.5,0-3,0.1-4.5,0.2c-20.8,2.2-37,19.9-37,41.2c0,6.1,1.3,12.1,3.9,17.6c2.6,5.5,6.3,10.4,11,14.3c4.7,3.9,10.2,6.7,16,8.2
                    c5.9,1.5,12,1.8,18,0.7c6-1.1,11.7-3.5,16.6-7.1c4.9-3.5,9.1-8.1,12-13.4c3-5.3,4.8-11.2,5.2-17.3
                    C812,402.3,811.1,396.2,808.9,390.6L808.9,390.6z"/>
                    <path className="st6" d="M791.1,427.3h-42v-37.2l9.1-10.5h32.9V427.3z"/>
                    <path className="st19" d="M749.2,390.1l9.1-10.5l1.5,10.5H749.2z"/>
                    <path className="st19" d="M786.5,390.1h-25.2v-2.8h25.2V390.1z"/>
                    <path className="st19" d="M786.5,410.2h-32.7v-2.8h32.7V410.2z"/>
                    <path className="st19" d="M786.5,423.6h-25.2v-2.8h25.2V423.6z"/>
                    <path className="st19" d="M764.6,403.5h-10.8v-2.8h10.8V403.5z"/>
                    <path className="st19" d="M770.4,416.9h-16.6v-2.8h16.6V416.9z"/>
                    <path className="st19" d="M786.3,416.9h-14.6v-2.8h14.6V416.9z"/>
                    <path className="st19" d="M786.5,403.5h-20.8v-2.8h20.8V403.5z"/>
                    <path className="st19" d="M773.2,396.8h-19.3V394h19.3V396.8z"/>
                    <path className="st19" d="M786.5,396.8h-12V394h12V396.8z"/>
                </g>
                </a>
                </svg>,

                <svg width="960" height="600" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 960 600" className='slide3'>
                  <a className="cursor-default bannerbg">
                    <g>
                      <path class="st0" d="M138.5,210c149.3,31.2,61.8-313.4,265.2-167.2c80.9,58.2,197.3-12.2,309,26.3
                        c102.4,35.4,77.2,151.2,67.4,189.2c-20,76.6,79.7,46.1,137.6,79c90.7,51.7,11.1,259.8-74.6,260.7c-85.7,0.9-766.3-1.7-766.3-1.7
                        S39.4,567,19.6,506.8C-21.3,383.1,6.9,182.6,138.5,210z"/>
                    </g>
                  </a>
                  <a className='cursor-default building'>
                    <g>
                      <rect x="145.3" y="526.8" class="st1" width="35.3" height="72.7"/>
                      <polygon class="st2" points="665.1,322.9 665.1,291.2 655.5,291.2 655.5,236.6 603.8,236.6 603.8,291.2 555.4,291.2 555.4,216.3 
                        502,196.1 502,260 489.8,260 489.8,175 436.6,192.5 436.6,320.3 369.1,320.3 369.1,395.7 343.9,395.7 343.9,414.8 286,414.8 
                        286,382 252.7,382 252.7,293.1 211.7,293.1 211.7,457.2 170.6,457.2 170.6,600 211.7,600 246.8,600 252.7,600 286,600 304.7,600 
                        334.9,600 343.9,600 369.1,600 392.8,600 436.6,600 449.9,600 476.6,600 489.8,600 502,600 530,600 549.1,600 555.4,600 
                        603.8,600 654.6,600 655.5,600 665.1,600 706.4,600 706.4,322.9 		"/>
                      <rect x="444.5" y="199" class="st3" width="36" height="13.2"/>
                      <rect x="444.5" y="223.9" class="st3" width="36" height="13.2"/>
                      <rect x="444.5" y="246.1" class="st3" width="36" height="13.2"/>
                      <rect x="211.7" y="353" class="st3" width="41.8" height="20.4"/>
                      <rect x="211.7" y="415.4" class="st3" width="41.4" height="20.4"/>
                      <rect x="211.7" y="446.1" class="st3" width="41.4" height="20.4"/>
                      <rect x="211" y="478.4" class="st3" width="41.4" height="20.4"/>
                      <rect x="211" y="509.8" class="st3" width="41.4" height="20.4"/>
                      <rect x="211" y="542" class="st3" width="41.4" height="20.4"/>
                      <rect x="509.1" y="246.1" class="st3" width="37.8" height="13.2"/>
                      <rect x="509.1" y="224" class="st3" width="37.8" height="13.2"/>
                      <rect x="443.6" y="271.7" class="st3" width="103.4" height="13.2"/>
                      <rect x="443.6" y="291.6" class="st3" width="103.4" height="13.2"/>
                      <rect x="443.6" y="311.3" class="st3" width="103.4" height="12.7"/>
                      <rect x="211.7" y="384.8" class="st3" width="41.4" height="20.4"/>
                      <rect x="379.4" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="396.4" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="412.9" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="428.5" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="444.5" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="460.6" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="477.5" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="494.1" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="509.8" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="525.8" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="542.5" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="559.4" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="575.9" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="591.5" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="607.5" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="640.5" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="657.1" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="672.8" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="688.8" y="332.7" class="st3" width="9" height="12.7"/>
                      <rect x="379.4" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="396.4" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="412.9" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="428.5" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="444.5" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="460.6" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="477.5" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="494.1" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="509.8" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="525.8" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="542.5" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="559.4" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="575.9" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="591.5" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="607.5" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="623.6" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="640.5" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="657.1" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="672.8" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="688.8" y="355.2" class="st3" width="9" height="12.7"/>
                      <rect x="379.4" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="396.4" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="412.9" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="428.5" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="444.5" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="460.6" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="477.5" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="494.1" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="509.8" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="525.8" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="542.5" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="559.4" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="575.9" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="591.5" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="607.5" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="623.6" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="640.5" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="657.1" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="672.8" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="688.8" y="377.4" class="st3" width="9" height="12.7"/>
                      <rect x="379.4" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="396.4" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="412.9" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="428.5" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="444.5" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="460.6" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="477.5" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="494.1" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="509.8" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="525.8" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="542.5" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="559.4" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="575.9" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="591.5" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="607.5" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="623.6" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="640.5" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="657.1" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="672.8" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="688.8" y="400.9" class="st3" width="9" height="12.7"/>
                      <rect x="379.4" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="396.4" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="412.9" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="428.5" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="444.5" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="460.6" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="477.5" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="494.1" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="509.8" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="525.8" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="542.5" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="559.4" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="575.9" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="591.5" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="607.5" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="623.6" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="640.5" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="672.8" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="657.1" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="688.8" y="423.4" class="st3" width="9" height="12.7"/>
                      <rect x="379.4" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="396.4" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="412.9" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="428.5" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="444.5" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="460.6" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="477.5" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="494.1" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="509.8" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="525.8" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="542.5" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="559.4" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="575.9" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="591.5" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="607.5" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="623.6" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="640.5" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="672.8" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="688.8" y="445.5" class="st3" width="9" height="12.7"/>
                      <rect x="773.7" y="420.3" class="st3" width="20.1" height="136.2"/>
                      <rect x="752" y="503.1" class="st4" width="21.5" height="94.5"/>
                      <polygon class="st3" points="300.6,382 300.6,355.4 301.7,355.4 301.7,382 316.6,382 316.6,414.6 286,414.6 286,382 		"/>
                      <polygon class="st3" points="211.7,313.5 211.7,293.1 220.3,293.1 220.3,284.3 221.3,284.3 221.3,293.1 252.9,293.1 253.4,293.1 
                        259.4,293.1 259.4,284.3 260.5,284.3 260.5,293.1 268.1,293.1 268.1,382 252.9,382 252.9,343.8 211.7,343.8 211.7,323.4 
                        252.9,323.4 252.9,313.5 		"/>
                      <polygon class="st2" points="658.3,440.2 658.3,554.8 773.7,554.8 773.7,420.3 		"/>
                      <rect x="658.3" y="502.7" class="st2" width="144" height="96.8"/>
                      <rect x="658.3" y="445.5" class="st3" width="144" height="31.5"/>
                      <rect x="668" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="681.9" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="695.4" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="708.2" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="721.3" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="734.5" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="748.3" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="761.8" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="774.6" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="787.7" y="528.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="668" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="681.9" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="695.4" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="708.2" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="721.3" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="734.5" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="748.3" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="761.8" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="774.6" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="787.7" y="547.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="668" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="681.9" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="695.4" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="708.2" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="721.3" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="734.5" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="748.3" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="761.8" y="491.9" class="st3" width="7.4" height="10.4"/>
                      <rect x="668" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="681.9" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="695.4" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="708.2" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="721.3" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="734.5" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="748.3" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="761.8" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="774.6" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="787.7" y="510.2" class="st3" width="7.4" height="10.4"/>
                      <rect x="668" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="681.9" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="695.4" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="708.2" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="721.3" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="734.5" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="748.3" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="761.8" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="774.6" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="787.7" y="565.4" class="st3" width="7.4" height="10.4"/>
                      <rect x="668" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="681.9" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="695.4" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="708.2" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="721.3" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="734.5" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="748.3" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="761.8" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="774.6" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="787.7" y="581.5" class="st3" width="7.4" height="10.4"/>
                      <rect x="763.8" y="398.6" class="st2" width="2.1" height="27.2"/>
                      <rect x="669.2" y="454.3" class="st2" width="6.2" height="10.7"/>
                      <rect x="731" y="454.3" class="st2" width="6.2" height="10.7"/>
                      <rect x="780" y="454.3" class="st2" width="6.2" height="10.7"/>
                      <rect x="279.4" y="478.4" class="st3" width="207.2" height="121.2"/>
                      <polygon class="st3" points="350.8,483.8 307.5,483.8 307.5,446.1 350.8,462.8 		"/>
                      <polygon class="st3" points="563.7,599.5 662.8,599.5 662.8,491.9 563.7,526.1 		"/>
                      <rect x="566.4" y="529.7" class="st2" width="93.7" height="3.3"/>
                      <rect x="566.4" y="540.4" class="st2" width="93.7" height="3.3"/>
                      <rect x="566.4" y="551.1" class="st2" width="93.7" height="3.3"/>
                      <rect x="566.4" y="561.8" class="st2" width="93.7" height="3.3"/>
                      <rect x="566.4" y="572.5" class="st2" width="93.7" height="3.3"/>
                      <rect x="566.4" y="583.1" class="st2" width="93.7" height="3.3"/>
                      <rect x="299.2" y="446.1" class="st3" width="8.3" height="32.1"/>
                      <rect x="648.1" y="241.1" class="st3" width="5.7" height="48.7"/>
                      <rect x="636.7" y="241.1" class="st3" width="5.7" height="48.7"/>
                      <rect x="625.5" y="241.1" class="st3" width="5.7" height="48.7"/>
                      <rect x="613.8" y="241.1" class="st3" width="5.7" height="48.7"/>
                      <path class="st5" d="M390.3,209.6h-3.7v-0.3l1.1-9.8c0-0.3,0.3-0.5,0.7-0.5h0.1c0.3,0,0.4,0.1,0.5,0.3c0.1,0.1,0.3,0.4,0.3,0.7
                        L390.3,209.6z"/>
                      <path class="st6" d="M387,207.5h-0.1l0.9-7.8c0-0.3,0.3-0.5,0.7-0.5h0.1c0,0,0.1,0,0.1,0.1c0.1,0.1,0.3,0.4,0.3,0.7v5.7
                        C389,206.6,388,207.3,387,207.5z"/>
                      <rect x="385.5" y="253" class="st5" width="7" height="310.9"/>
                      <rect x="385.5" y="253" class="st6" width="3.3" height="310.9"/>
                      <rect x="377.9" y="514.3" class="st5" width="22.5" height="82.8"/>
                      <rect x="377.9" y="514.3" class="st6" width="13.4" height="82.8"/>
                      <path class="st5" d="M401.4,214.4c0,3.8-5.8,7-13,7c-0.5,0-1.1,0-1.6,0c-6.3-0.4-11.4-3.3-11.4-6.9s4.9-6.5,11.4-6.9
                        c0.5,0,1.1,0,1.6,0C395.6,207.3,401.4,210.5,401.4,214.4z"/>
                      <polygon class="st6" points="400.3,259.2 377.9,259.2 369.9,221.1 407.1,221.1 		"/>
                      <rect x="367.8" y="218.2" class="st5" width="41.1" height="3.7"/>
                      <path class="st5" d="M381.6,514.3H396l-2.1-6.3c-0.1-0.5-0.7-0.9-1.3-0.9h-6.7c-0.8,0-1.3,0.4-1.7,1.1L381.6,514.3z"/>
                      <path class="st6" d="M394.8,214L394.8,214c-0.3-2.9-3.4-5.4-7.9-6.6c-6.2,0.4-11,3.2-11.4,6.6h-2.1l-2.9,4.2h-2.6v3.7h25.9v-3.7
                        h3.8L394.8,214z"/>
                      <polygon class="st7" points="372.6,222.9 404.3,222.9 398.1,257.1 379.8,257.1 		"/>
                      <path class="st7" d="M376.4,214.8c0,0,2.4-5.3,7.1-5.2c0,0-3,2.6-2.8,5.2C381,217.3,376.4,214.8,376.4,214.8z"/>
                      <polygon class="st5" points="406.4,218.2 370.5,218.2 373.5,214 403.6,214 		"/>
                      <polygon class="st8" points="374.7,224.1 382,224.1 384.1,256 382,256 		"/>
                      <path class="st5" d="M608.4,322.6h-2.6v-0.3l0.8-6.9c0-0.3,0.3-0.4,0.4-0.4h0.1c0.1,0,0.3,0.1,0.4,0.1c0.1,0.1,0.3,0.3,0.3,0.4
                        L608.4,322.6z"/>
                      <path class="st6" d="M606,321L606,321l0.5-5.6c0-0.3,0.3-0.4,0.4-0.4h0.1h0.1c0.1,0.1,0.1,0.3,0.1,0.5v4
                        C607.5,320.4,606.8,321,606,321z"/>
                      <rect x="605.1" y="353.4" class="st5" width="5" height="220.8"/>
                      <rect x="605.1" y="353.4" class="st6" width="2.4" height="220.8"/>
                      <rect x="599.6" y="539.1" class="st5" width="16" height="58.4"/>
                      <rect x="599.6" y="539.1" class="st6" width="9.5" height="58.4"/>
                      <path class="st5" d="M616.4,325.9c0,2.8-4.1,4.9-9.1,4.9c-0.4,0-0.8,0-1.2,0c-4.5-0.3-8.1-2.4-8.1-4.9c0-2.5,3.6-4.6,8.1-4.9
                        c0.4,0,0.8,0,1.2,0C612.3,321,616.4,323.3,616.4,325.9z"/>
                      <polygon class="st6" points="615.6,357.8 599.6,357.8 594,330.7 620.3,330.7 		"/>
                      <rect x="592.6" y="328.7" class="st5" width="29.2" height="2.6"/>
                      <path class="st5" d="M602.2,539.1h10.2l-1.5-4.5c-0.1-0.4-0.5-0.7-0.9-0.7h-4.8c-0.5,0-0.9,0.3-1.2,0.8L602.2,539.1z"/>
                      <path class="st6" d="M611.6,325.7L611.6,325.7c-0.3-2.1-2.5-3.8-5.7-4.6c-4.4,0.3-7.8,2.2-8.1,4.6h-1.5l-2.1,2.9h-1.9v2.6h18.4
                        v-2.6h2.8L611.6,325.7z"/>
                      <polygon class="st7" points="595.9,332 618.3,332 614,356.3 601,356.3 		"/>
                      <path class="st7" d="M598.5,326.2c0,0,1.7-3.7,5.2-3.6c0,0-2.1,1.9-2,3.6C601.9,327.9,598.5,326.2,598.5,326.2z"/>
                      <polygon class="st5" points="619.9,328.7 594.4,328.7 596.5,325.7 617.9,325.7 		"/>
                      <polygon class="st8" points="597.3,332.9 602.5,332.9 604.1,355.6 602.5,355.6 		"/>
                      <path class="st5" d="M146.1,127.2h-4.5v-0.4l1.3-11.8c0-0.4,0.4-0.7,0.8-0.7h0.1c0.5,0.1,1.1,0.5,1.1,1.1L146.1,127.2z"/>
                      <path class="st6" d="M142,124.6h-0.1l1.1-9.5c0-0.4,0.4-0.7,0.8-0.7h0.1l0.1,0.1c0.3,0.3,0.3,0.5,0.3,0.8v6.9
                        C144.4,123.4,143.3,124.5,142,124.6z"/>
                      <rect x="140.3" y="179.8" class="st5" width="8.6" height="377.3"/>
                      <rect x="140.3" y="179.8" class="st6" width="4" height="377.3"/>
                      <rect x="130.9" y="496.9" class="st5" width="27.2" height="99.8"/>
                      <rect x="130.9" y="496.9" class="st6" width="16.3" height="99.8"/>
                      <path class="st5" d="M159.4,132.9c0,4.6-7,8.5-15.7,8.5c-0.7,0-1.3,0-2,0c-7.8-0.5-13.7-4.1-13.7-8.3c0-4.4,5.9-7.8,13.7-8.3
                        c0.7,0,1.3-0.1,2-0.1C152.4,124.5,159.4,128.3,159.4,132.9z"/>
                      <polygon class="st6" points="158.3,187.3 130.9,187.3 121.2,141.1 166.3,141.1 		"/>
                      <rect x="118.9" y="137.5" class="st5" width="50" height="4.5"/>
                      <path class="st5" d="M135.4,496.9h17.5l-2.5-7.7c-0.3-0.7-0.9-1.2-1.6-1.2h-8.2c-0.9,0-1.7,0.5-2,1.3L135.4,496.9z"/>
                      <path class="st6" d="M151.5,132.5L151.5,132.5c-0.4-3.6-4.2-6.6-9.7-7.9c-7.5,0.5-13.4,3.8-13.7,7.9h-2.5l-3.6,5h-3.2v4.5h31.5
                        v-4.5h4.6L151.5,132.5z"/>
                      <polygon class="st7" points="124.5,143.4 163,143.4 155.5,184.9 133.4,184.9 		"/>
                      <path class="st7" d="M129.2,133.4c0,0,2.9-6.3,8.7-6.2c0,0-3.7,3.3-3.3,6.2C134.9,136.4,129.2,133.4,129.2,133.4z"/>
                      <polygon class="st5" points="165.5,137.5 122,137.5 125.6,132.5 162.2,132.5 		"/>
                      <polygon class="st8" points="127.1,144.8 135.9,144.8 138.4,183.5 135.9,183.5 		"/>
                    </g>
                  </a>
                  <a className='cursor-default tree'>
                    <g>
                      <path class="st9" d="M1.1,597H959c0.6,0,1.1,0.5,1.1,1.3v0.4c0,0.6-0.5,1.3-1.1,1.3H1.1c-0.6,0-1.1-0.5-1.1-1.3v-0.4
                        C0,597.5,0.5,597,1.1,597z"/>
                      <path class="st10" d="M696.4,497.3c0.6-2.4,0.9-4.8,0.9-7.2c0-11.3-6.6-20.5-14.6-20.5c-1.2,0-2.3,0.2-3.4,0.6
                        c-1.3-30.7-14.7-54.8-31.1-54.8c-15.6,0-28.5,21.9-30.9,50.5c-11.6,7.9-19.6,24-19.6,42.6c-0.1,8.4,1.6,16.6,5,24.3
                        c-10.8,6.6-17.6,16.6-17.6,27.8c0,20,21.8,36.2,48.8,36.2c20.2,0,37.5-9.1,44.9-22c2.1,0.5,4.2,0.7,6.4,0.7
                        c18.3,0,33.2-18,33.2-40.3C718.3,517.8,709.2,502.9,696.4,497.3z"/>
                      <path class="st11" d="M696.4,497.3c0,0-32.3-12.6-56.9,9.6c-24.6,22.2-41.7-12.8-33.5-29c-5.2,8.3-8.3,18.9-8.3,30.6
                        c-0.1,8.4,1.6,16.6,5,24.3c-10.8,6.6-17.6,16.6-17.6,27.8c0,20,21.8,36.2,48.8,36.2c15.1,0,30.4,1.9,40.3-2.6
                        c8.1-3.7,17.2-5.1,26-3.5c5.1,0.9,9.4,1.6,10.3,1.6c18.3,0,7.9-34.8,7.9-57.1C718.3,517.8,709.2,502.9,696.4,497.3z"/>
                      <path class="st10" d="M786.7,477.2c-0.6-2.8-0.9-5.8-0.9-8.7c0-13.6,6.6-24.7,14.6-24.7c1.2,0,2.4,0.2,3.4,0.7
                        c1.3-36.9,14.7-65.9,31.1-65.9c15.6,0,28.5,26.3,30.9,60.7c11.6,9.5,19.6,28.9,19.6,51.3c0,10.7-1.8,20.7-5,29.2
                        c10.8,8,17.6,20,17.6,33.5c0,24-21.8,43.5-48.8,43.5c-20.2,0-37.5-10.9-44.9-26.5c-2.1,0.6-4.2,0.9-6.4,0.9
                        c-18.3,0-33.2-21.7-33.2-48.5C764.8,501.8,773.9,483.9,786.7,477.2z"/>
                      <path class="st10" d="M804.8,491.6c-2.3-27.4-15.3-48.4-30.9-48.4c-16.4,0-29.8,23.1-31.1,52.5c-1.1-0.4-2.3-0.6-3.4-0.6
                        c-8.1,0-14.6,8.8-14.6,19.7c0,2.3,0.3,4.7,0.9,6.9c-12.8,5.4-21.9,19.6-21.9,36.3c0,21.3,14.9,38.6,33.2,38.6
                        c14.7,0,27.2-11.1,31.5-26.5c5.7,5.1,12.7,8.1,20.2,8.1c19.7,0,35.7-20.5,35.7-45.8C824.4,514.7,816.4,499.2,804.8,491.6z"/>
                      <path class="st11" d="M797.6,512.5c-33.3-4.5-38.2,31.7-38.2,31.7c-39.4-17.9-55.5,15.9-55.5,15.9l0,0
                        c0.9,20.4,15.4,36.7,33.2,36.7c8.9,0,17-4.1,23-10.8c5.9-6.7,14.8-9.8,23.6-8.2c1.7,0.3,3.4,0.5,5.1,0.5
                        c11.8,0,22.3-7.4,28.8-18.8C817.7,558.8,830.6,516.9,797.6,512.5z"/>
                      <path class="st10" d="M199,553.6C70.3,529.9,65.2,597.5,65.2,597.5H458C295.6,597.6,327.6,577.3,199,553.6z"/>
                      <path class="st11" d="M793.5,563.5c-29.1,9.8-81.8,32.2-143.3,15.1s-135.5-4.5-227,13.9s-206.1-14.7-277.6-14.8S30,598.3,30,598.3
                        l900-1.3C890.9,553.5,838.3,548.5,793.5,563.5z"/>
                    </g>
                  </a>
                  <a className='cursor-default men1'>
                    <g>
                      <path class="st12" d="M481.3,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C488.9,560.6,485.5,554.9,481.3,554.9z"/>
                      <path class="st13" d="M472.3,572.2h-42.4c-4.4,0-8.1-3.4-8.3-7.8l-7.7-126.2H488l-7.7,126.2C480.3,568.8,476.8,572.2,472.3,572.2z
                        "/>
                      <path class="st14" d="M484.4,572.2H442c-4.4,0-8.3-0.7-8.3-7.8L426,438.3h74.1l-7.7,126.2C492.6,572.2,488.8,572.2,484.4,572.2z"
                        />
                      <path class="st12" d="M415.3,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6h-79.5v-4.6C410.4,430.9,412.7,428.8,415.3,428.8z"/>
                      <path class="st15" d="M429.9,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6H425v-4.6C425,430.9,427.1,428.8,429.9,428.8z"/>
                      <path class="st15" d="M424.8,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C432.5,560.6,429.1,554.9,424.8,554.9z"/>
                      <path class="st12" d="M427.2,567.5c0,5.2-2.5,9.2-5.5,9.2c-3,0-5.5-4.1-5.5-9.2s2.5-9.2,5.5-9.2
                        C424.7,558.2,427.2,562.3,427.2,567.5z"/>
                      <path class="st16" d="M454.4,544.2c0-1.8,0.4-3.3,1.3-4.4c0.9-1.1,2.1-1.6,3.8-1.6c1.7,0,3,0.5,3.8,1.6c0.9,1.1,1.3,2.5,1.3,4.4
                        v3.7h5.2c0.4,0,0.7,0.3,0.7,0.7v1.8c0,0.4-0.3,0.7-0.7,0.7h-14.8c-0.4,0-0.7-0.3-0.7-0.7l0,0l0,0L454.4,544.2z M461.8,547.9v-3.6
                        c0-0.8-0.1-1.5-0.5-2.1c-0.4-0.5-0.9-0.8-1.8-0.8s-1.5,0.3-1.8,0.8c-0.4,0.7-0.5,1.3-0.5,2.1v3.6H461.8z"/>
                      <path class="st16" d="M467.6,525.2c0-0.4,0.3-0.7,0.7-0.7h1.6c0.4,0,0.7,0.3,0.7,0.7v10c0,0.4-0.3,0.7-0.7,0.7H455
                        c-0.4,0-0.7-0.3-0.7-0.7l0,0l0,0v-1.8c0-0.4,0.3-0.7,0.7-0.7h12.5L467.6,525.2z"/>
                      <path class="st16" d="M454.4,514.8c0-0.3,0-0.4,0.1-0.5c0.1-0.1,0.4-0.3,0.5-0.3l14.5-5.7h0.1c0.4,0,0.7,0.3,0.7,0.7v1.7
                        c0,0.3,0,0.5-0.1,0.7s-0.3,0.1-0.4,0.3l-2.5,0.9v7.3l2.5,0.9c0.1,0,0.3,0.1,0.4,0.3c0.1,0.1,0.3,0.4,0.1,0.7v1.7
                        c0,0.4-0.3,0.7-0.7,0.7h-0.1l-14.5-5.7c-0.3,0-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.4-0.1-0.5V514.8z M464.5,513.6l-6.6,2.5l6.6,2.5
                        V513.6z"/>
                      <path class="st16" d="M454.2,500.9c0-0.9,0.1-1.8,0.4-2.8c0.3-0.7,0.5-1.3,1.1-2c0.4-0.5,0.8-0.9,1.3-1.2c0.4-0.3,0.9-0.4,1.5-0.4
                        c0.1,0,0.4,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.6c0,0.3,0,0.4-0.1,0.5c-0.1,0.1-0.3,0.3-0.4,0.4c-0.4,0.3-0.8,0.7-1.1,1.1
                        c-0.3,0.7-0.5,1.3-0.4,2.1c0,0.4,0,0.8,0.1,1.2c0.1,0.4,0.1,0.7,0.4,0.9c0.1,0.3,0.4,0.5,0.5,0.7c0.3,0.1,0.5,0.3,0.8,0.3
                        s0.7,0,0.9-0.3c0.3-0.1,0.4-0.4,0.5-0.8c0.1-0.4,0.4-0.9,0.4-1.3c0.1-0.5,0.3-1.3,0.5-2.1c0.1-0.8,0.4-1.6,0.7-2.4
                        c0.3-0.5,0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.7,1.3-0.9c0.5-0.3,1.1-0.3,1.7-0.3c0.7,0,1.3,0.1,2,0.4c0.7,0.3,1.1,0.8,1.5,1.3
                        c0.4,0.7,0.8,1.3,1.1,2.1c0.3,0.9,0.4,1.8,0.4,2.8c0,0.8-0.1,1.7-0.3,2.5c-0.1,0.8-0.5,1.5-0.9,2.1c-0.4,0.5-0.8,1.1-1.5,1.5
                        c-0.5,0.4-1.2,0.5-1.8,0.7c-0.1,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-1.6c0-0.3,0-0.4,0.1-0.7c0.1-0.1,0.3-0.3,0.4-0.4
                        c0.3-0.1,0.4-0.3,0.7-0.4c0.3-0.1,0.4-0.4,0.5-0.7s0.3-0.7,0.4-0.9c0.1-0.5,0.1-0.9,0.1-1.5c0-0.4,0-0.9-0.1-1.3
                        c0-0.4-0.1-0.8-0.4-1.2c-0.1-0.3-0.4-0.5-0.7-0.8s-0.5-0.3-0.9-0.3c-0.3,0-0.7,0.1-0.8,0.3c-0.3,0.3-0.4,0.7-0.5,0.9
                        c-0.1,0.5-0.3,1.1-0.4,1.6c-0.1,0.7-0.3,1.3-0.5,2.2c-0.1,0.7-0.4,1.3-0.7,2c-0.3,0.5-0.5,1.1-0.9,1.5c-0.4,0.4-0.8,0.7-1.3,0.8
                        c-0.5,0.1-1.2,0.3-1.7,0.3c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.3-1.1-0.8-1.5-1.3c-0.4-0.5-0.8-1.2-0.9-2
                        C454.3,502.5,454.2,501.7,454.2,500.9z"/>
                      <path class="st16" d="M454.4,481c0-0.4,0.3-0.7,0.7-0.7h1.6c0.4,0,0.7,0.3,0.7,0.7v4.1h12.5c0.4,0,0.7,0.3,0.7,0.7v1.8
                        c0,0.4-0.3,0.7-0.7,0.7h-12.5v4.1c0,0.4-0.3,0.7-0.7,0.7l0,0h-1.6c-0.4,0-0.7-0.3-0.7-0.7l0,0l0,0V481z"/>
                      <path class="st16" d="M470.6,477.4c0,0.4-0.3,0.7-0.7,0.7h-14.8c-0.4,0-0.7-0.3-0.7-0.7l0,0l0,0v-1.8c0-0.4,0.3-0.7,0.7-0.7l0,0
                        h14.8c0.4,0,0.7,0.3,0.7,0.7V477.4z"/>
                      <path class="st16" d="M465.4,461.4c-0.1,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4v-1.8c0-0.1,0-0.3,0.1-0.4s0.3-0.1,0.4-0.1
                        c0.7,0,1.3,0.3,1.8,0.5c0.7,0.3,1.2,0.7,1.7,1.2c0.5,0.7,0.9,1.3,1.2,2.1c0.4,0.9,0.5,2,0.5,3c0,0.9-0.1,2-0.5,2.8
                        c-0.3,0.8-0.8,1.5-1.3,2.1c-0.5,0.5-1.3,1.1-2,1.3c-0.8,0.3-1.7,0.5-2.5,0.5c-0.5,0-1.2,0-2,0c-0.7,0-1.3,0-2,0
                        c-0.9,0-1.7-0.3-2.5-0.5c-0.8-0.3-1.5-0.8-2-1.3c-0.5-0.5-1.1-1.3-1.3-2.1c-0.4-0.9-0.5-1.8-0.5-2.8c0-1.1,0.1-2.1,0.5-3
                        c0.3-0.8,0.7-1.5,1.2-2.1c0.5-0.5,1.1-0.9,1.7-1.2c0.7-0.3,1.2-0.4,1.8-0.5c0.1,0,0.4,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.8
                        c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.3-0.4,0.3c-0.3,0-0.7,0.1-0.9,0.3c-0.3,0.1-0.5,0.4-0.8,0.7c-0.3,0.4-0.4,0.7-0.5,1.1
                        c-0.1,0.5-0.3,1.1-0.3,1.6s0.1,1.1,0.3,1.6c0.1,0.4,0.4,0.8,0.8,1.1c0.3,0.3,0.7,0.5,1.1,0.7c0.4,0.1,0.9,0.3,1.3,0.3
                        c0.5,0,1.2,0,2,0c0.7,0,1.3,0,2,0c0.5,0,0.9-0.1,1.3-0.3c0.4-0.1,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.7,0.8-1.1
                        c0.1-0.5,0.3-1.1,0.3-1.6c0-0.5,0-1.1-0.3-1.6c-0.1-0.4-0.3-0.8-0.5-1.1C466.7,461.9,466.1,461.5,465.4,461.4L465.4,461.4z"/>
                      <path class="st12" d="M398.8,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C406.5,560.6,403,554.9,398.8,554.9z"/>
                      <path class="st11" d="M389.8,572.2h-42.4c-4.4,0-8.1-3.4-8.3-7.8l-7.7-126.2h74.1l-7.7,126.2C397.9,568.8,394.3,572.2,389.8,572.2
                        z"/>
                      <path class="st10" d="M402,572.2h-42.4c-4.4,0-8.3-0.7-8.3-7.8l-7.7-126.2h74.1L410,564.4C410.2,572.2,406.3,572.2,402,572.2z"/>
                      <path class="st12" d="M332.9,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6H328v-4.6C328,430.9,330.3,428.8,332.9,428.8z"/>
                      <path class="st15" d="M347.4,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6h-79.5v-4.6C342.5,430.9,344.8,428.8,347.4,428.8z"/>
                      <path class="st15" d="M342.4,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C350.1,560.6,346.6,554.9,342.4,554.9z"/>
                      <ellipse class="st12" cx="339.2" cy="567.5" rx="5.5" ry="9.2"/>
                      <path class="st16" d="M377.2,553.2c-0.8,0-1.6-0.3-2.4-0.5c-0.8-0.3-1.5-0.7-2-1.2c-0.5-0.5-1.1-1.3-1.3-2.1c-0.7-1.8-0.7-4,0-5.8
                        c0.3-0.8,0.8-1.5,1.3-2.1c0.5-0.5,1.2-0.9,2-1.3c0.8-0.3,1.6-0.5,2.4-0.5c0.3,0,0.7,0,0.9,0h2.2c0.3,0,0.7,0,0.9,0
                        c0.8,0,1.6,0.3,2.4,0.5c0.8,0.3,1.5,0.7,2,1.2c0.5,0.5,1.1,1.3,1.3,2.1c0.7,1.8,0.7,4,0,5.8c-0.3,0.8-0.8,1.5-1.3,2.1
                        c-0.5,0.5-1.2,0.9-2,1.2c-0.8,0.3-1.6,0.4-2.4,0.5h-0.9h-2.2L377.2,553.2z M377.3,542.8c-2,0-3.7,1.6-3.7,3.7c0,2,1.6,3.7,3.7,3.7
                        c0.5,0,1.2,0,2,0s1.3,0,2,0c0.9,0,1.8-0.4,2.5-1.1c1.2-1.5,1.2-3.7,0-5.2c-0.7-0.7-1.6-1.1-2.5-1.1c-0.5,0-1.2,0-2,0
                        C378.6,542.8,377.9,542.8,377.3,542.8L377.3,542.8z"/>
                      <path class="st16" d="M371.2,530.1c0-2,0.4-3.4,1.3-4.4c0.9-1.1,2.1-1.5,3.7-1.5c1.1,0,2,0.3,2.9,0.8c0.8,0.5,1.5,1.3,1.7,2.2
                        l5.7-3.2c0.1,0,0.1,0,0.3-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v2c0,0.3,0,0.5-0.3,0.7c-0.1,0.1-0.3,0.3-0.4,0.3
                        l-5.4,3v3h5.4c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.8c0,0.4-0.3,0.7-0.7,0.7h-14.8c-0.4,0-0.7-0.3-0.7-0.7L371.2,530.1z
                        M378.5,533.5v-3.3c0-0.7-0.1-1.5-0.4-2.1c-0.3-0.5-0.9-0.8-1.7-0.8s-1.5,0.3-1.7,0.8c-0.3,0.7-0.5,1.3-0.4,2.1v3.3H378.5z"/>
                      <path class="st16" d="M377.2,521.9c-0.9,0-1.7-0.1-2.5-0.5c-0.8-0.3-1.5-0.8-2-1.3c-0.5-0.7-1.1-1.3-1.3-2.1
                        c-0.7-2.1-0.7-4.2,0.1-6.3c0.3-0.8,0.7-1.5,1.2-2.1c0.4-0.5,0.9-0.8,1.5-1.1c0.4-0.1,0.8-0.3,1.3-0.4c0.1,0,0.4,0,0.4,0.1
                        c0.1,0.1,0.1,0.3,0.1,0.4v1.8c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.1,0.1-0.3,0.3c0,0-0.1,0.1-0.4,0.3c-0.3,0.1-0.5,0.4-0.7,0.7
                        c-0.3,0.4-0.4,0.7-0.7,1.1c-0.1,0.5-0.3,1.2-0.3,1.7c0,1.1,0.3,2,0.9,2.8c0.7,0.7,1.6,1.1,2.5,1.1c0.5,0,1.2,0,1.8,0s1.3,0,1.8,0
                        c1.3,0,2.2-0.4,2.8-1.1c0.7-0.8,0.9-1.7,0.9-2.8c0-0.5,0-1.1-0.1-1.6c-0.1-0.4-0.3-0.8-0.7-1.2c-0.3-0.4-0.7-0.7-1.1-0.8
                        c-0.5-0.3-1.1-0.3-1.7-0.3h0.1v2.8c0,0.4-0.3,0.7-0.7,0.7h-1.6c-0.1,0-0.3,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-5.3
                        c0-0.1,0.1-0.3,0.1-0.4c0.1-0.1,0.3-0.1,0.4-0.1h2.5c0.9,0,1.7,0.1,2.5,0.4c0.8,0.3,1.5,0.8,2,1.3c0.5,0.7,1.1,1.3,1.3,2.2
                        c0.7,2,0.7,4.1,0,6.1c-0.3,0.8-0.7,1.6-1.3,2.1c-0.5,0.5-1.2,1.1-2,1.3c-0.8,0.3-1.7,0.5-2.5,0.5c-0.3,0-0.7,0-0.9,0h-2.1
                        C377.8,521.9,377.4,521.9,377.2,521.9z"/>
                      <path class="st16" d="M371.2,497.7c0-0.3,0-0.4,0.1-0.5c0.1-0.1,0.4-0.3,0.5-0.3l14.5-5.7h0.1c0.4,0,0.7,0.3,0.7,0.7v1.7
                        c0,0.3,0,0.5-0.1,0.7s-0.3,0.1-0.4,0.3l-2.5,0.9v7.3l2.5,0.9c0.1,0,0.3,0.1,0.4,0.3c0.1,0.1,0.3,0.4,0.1,0.7v1.7
                        c0,0.1,0,0.3-0.1,0.4c-0.1,0.1-0.3,0.1-0.4,0.1h-0.1l-14.5-5.7c-0.3-0.1-0.4-0.1-0.5-0.3s-0.3-0.4-0.1-0.5L371.2,497.7z
                        M381.3,496.4l-6.6,2.5l6.6,2.5V496.4z"/>
                      <path class="st16" d="M387.3,478.7c0,0.3,0,0.4-0.3,0.7c-0.1,0.1-0.3,0.3-0.3,0.3l-9.5,6.5h9.5c0.4,0,0.7,0.3,0.7,0.7v1.8
                        c0,0.4-0.3,0.7-0.7,0.7H372c-0.4,0-0.7-0.3-0.7-0.7l0,0l0,0V487c0-0.3,0-0.4,0.3-0.7c0.1-0.1,0.3-0.3,0.3-0.3l9.5-6.5h-9.5
                        c-0.4,0-0.7-0.3-0.7-0.7v-1.8c0-0.4,0.3-0.7,0.7-0.7h14.8c0.4,0,0.7,0.3,0.7,0.7V478.7z"/>
                      <path class="st16" d="M387.3,472.2c0,0.4-0.3,0.7-0.7,0.7h-14.8c-0.4,0-0.7-0.3-0.7-0.7l0,0l0,0v-1.8c0-0.4,0.3-0.7,0.7-0.7h14.8
                        c0.4,0,0.7,0.3,0.7,0.7V472.2z"/>
                      <path class="st16" d="M382.2,456.3c-0.1,0-0.4-0.1-0.4-0.3c-0.1-0.1-0.1-0.3-0.1-0.4v-1.8c0-0.1,0-0.3,0.1-0.4
                        c0.1-0.1,0.3-0.1,0.4-0.1c0.7,0,1.3,0.3,1.8,0.5c0.7,0.3,1.2,0.7,1.7,1.2c0.5,0.7,0.9,1.3,1.2,2.1c0.4,0.9,0.5,2,0.5,3
                        c0,0.9-0.1,2-0.5,2.8c-0.5,1.6-1.7,2.8-3.3,3.4c-0.8,0.3-1.7,0.5-2.5,0.5c-0.5,0-1.2,0-2,0c-0.7,0-1.3,0-2,0
                        c-0.9,0-1.7-0.3-2.5-0.5c-1.6-0.7-2.8-1.8-3.3-3.4c-0.4-0.9-0.5-1.8-0.5-2.8c0-1.1,0.1-2.1,0.5-3c0.3-0.8,0.7-1.5,1.2-2.1
                        c0.5-0.5,1.1-0.9,1.7-1.2c0.7-0.3,1.2-0.4,1.8-0.5c0.1,0,0.4,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.8c0,0.1,0,0.3-0.1,0.4
                        s-0.3,0.3-0.4,0.3c-0.3,0-0.7,0.1-0.9,0.3s-0.5,0.4-0.8,0.7c-0.3,0.4-0.4,0.7-0.5,1.1c-0.1,0.5-0.3,1.1-0.3,1.6
                        c0,0.5,0.1,1.1,0.3,1.6c0.1,0.4,0.4,0.8,0.7,1.1c0.3,0.3,0.7,0.5,1.1,0.7c0.4,0.1,0.9,0.3,1.3,0.3c0.5,0,1.2,0,2,0
                        c0.8,0,1.3,0,2,0c0.5,0,0.9-0.1,1.3-0.3c0.4-0.1,0.8-0.4,1.1-0.7c0.3-0.3,0.5-0.7,0.8-1.1c0.1-0.5,0.3-1.1,0.3-1.6
                        c0-0.5,0-1.1-0.3-1.6c-0.1-0.4-0.3-0.8-0.5-1.1c-0.3-0.3-0.5-0.5-0.8-0.7C382.8,456.4,382.6,456.4,382.2,456.3z"/>
                      <path class="st12" d="M316.3,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C323.9,560.6,320.5,554.9,316.3,554.9z"/>
                      <path class="st17" d="M307.3,572.2h-42.4c-4.4,0-8.1-3.4-8.3-7.8l-7.7-126.2H323l-7.7,126.2C315.3,568.8,311.6,572.2,307.3,572.2z
                        "/>
                      <path class="st18" d="M319.4,572.2H277c-4.4,0-8.3-0.7-8.3-7.8L261,438.3h74.1l-7.7,126.2C327.6,572.2,323.8,572.2,319.4,572.2z"
                        />
                      <path class="st12" d="M250.3,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6h-79.5v-4.6C245.5,430.9,247.7,428.8,250.3,428.8z"/>
                      <path class="st15" d="M264.9,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6H260v-4.6C260,430.9,262.1,428.8,264.9,428.8z"/>
                      <path class="st15" d="M259.8,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C267.5,560.6,264.1,554.9,259.8,554.9z"/>
                      <path class="st12" d="M262.2,567.5c0,5.2-2.5,9.2-5.5,9.2c-3,0-5.5-4.1-5.5-9.2s2.5-9.2,5.5-9.2
                        C259.7,558.2,262.2,562.3,262.2,567.5z"/>
                      <path class="st16" d="M295.8,542c-0.9,0-1.7-0.1-2.5-0.5c-0.8-0.3-1.5-0.8-2-1.3c-0.5-0.7-1.1-1.3-1.3-2.1
                        c-0.7-2.1-0.7-4.2,0.1-6.3c0.3-0.8,0.7-1.5,1.2-2.1c0.4-0.5,0.9-0.8,1.5-1.1c0.4-0.1,0.8-0.3,1.3-0.4c0.1,0,0.4,0,0.4,0.1
                        c0.1,0.1,0.1,0.3,0.1,0.4v1.8c0,0.1,0,0.3-0.1,0.4s-0.1,0.1-0.3,0.3c0,0-0.1,0.1-0.4,0.3c-0.3,0.1-0.5,0.4-0.7,0.7
                        c-0.3,0.4-0.4,0.7-0.7,1.1c-0.1,0.5-0.3,1.2-0.3,1.7c0,1.1,0.3,2,0.9,2.8c0.7,0.7,1.6,1.1,2.5,1.1c0.5,0,1.2,0,1.8,0
                        c0.7,0,1.3,0,1.8,0c1.3,0,2.2-0.4,2.8-1.1c0.7-0.8,0.9-1.7,0.9-2.8c0-0.5,0-1.1-0.1-1.6c-0.1-0.4-0.3-0.9-0.7-1.2
                        c-0.3-0.4-0.7-0.7-1.1-0.8c-0.5-0.3-1.1-0.3-1.7-0.3h-0.3v2.8c0,0.1,0,0.3-0.1,0.4s-0.3,0.1-0.4,0.1h-1.6c-0.1,0-0.3,0-0.4-0.1
                        c-0.1-0.1-0.1-0.3-0.1-0.4v-5.3c0-0.4,0.3-0.7,0.7-0.7h2.5c0.9,0,1.7,0.1,2.5,0.4c0.8,0.3,1.5,0.8,2,1.3c0.5,0.7,1.1,1.3,1.3,2.2
                        c0.7,2,0.7,4.1,0,6.1c-0.3,0.8-0.7,1.6-1.3,2.1c-0.5,0.5-1.2,1.1-2,1.3s-1.7,0.5-2.5,0.5c-0.3,0-0.7,0-0.9,0h-2.1
                        C296.4,542,296,542,295.8,542z"/>
                      <path class="st16" d="M303,514.4c0-0.4,0.3-0.7,0.7-0.7h1.6c0.4,0,0.7,0.3,0.7,0.7v10c0,0.4-0.3,0.7-0.7,0.7h-14.8
                        c-0.4,0-0.7-0.3-0.7-0.7v-1.8c0-0.4,0.3-0.7,0.7-0.7l0,0H303V514.4z"/>
                      <path class="st16" d="M289.8,504.1c0-0.3,0-0.4,0.1-0.5s0.4-0.3,0.5-0.3l14.5-5.7h0.1c0.4,0,0.7,0.3,0.7,0.7v1.7
                        c0,0.3,0,0.5-0.1,0.7c-0.1,0.1-0.3,0.1-0.4,0.3l-2.5,0.9v7.3l2.5,0.9c0.1,0,0.3,0.1,0.4,0.3c0.1,0.1,0.3,0.4,0.1,0.7v1.7
                        c0,0.4-0.3,0.7-0.7,0.7H305l-14.5-5.7c-0.3,0-0.4-0.1-0.5-0.3s-0.3-0.4-0.1-0.5V504.1z M299.9,502.8l-6.6,2.5l6.6,2.5V502.8z"/>
                      <path class="st16" d="M289.6,490.1c0-0.9,0.1-1.8,0.4-2.8c0.3-0.7,0.5-1.3,1.1-2c0.4-0.5,0.8-0.9,1.3-1.2c0.4-0.3,0.9-0.4,1.5-0.4
                        c0.1,0,0.4,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.6c0,0.3,0,0.4-0.1,0.5s-0.3,0.3-0.4,0.4c-0.4,0.3-0.8,0.7-1.1,1.1
                        c-0.3,0.7-0.5,1.3-0.4,2.1c0,0.4,0,0.8,0.1,1.2c0.1,0.4,0.1,0.7,0.4,0.9c0.1,0.3,0.4,0.5,0.5,0.7c0.3,0.1,0.5,0.3,0.8,0.3
                        s0.7,0,0.9-0.3c0.3-0.1,0.4-0.4,0.5-0.8c0.1-0.4,0.4-0.9,0.4-1.3c0.1-0.5,0.3-1.3,0.5-2.1c0.1-0.8,0.4-1.6,0.7-2.4
                        c0.3-0.7,0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.8,1.3-0.9c0.5-0.3,1.1-0.3,1.7-0.3c0.7,0,1.3,0.1,2,0.4c0.7,0.3,1.1,0.8,1.5,1.3
                        c0.4,0.7,0.8,1.3,1.1,2.1c0.3,0.9,0.4,1.8,0.4,2.8c0,0.8-0.1,1.7-0.3,2.5c-0.1,0.8-0.5,1.5-0.9,2.1c-0.4,0.5-0.8,1.1-1.5,1.5
                        c-0.5,0.4-1.2,0.5-1.8,0.7c-0.1,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-1.6c0-0.4,0.1-0.8,0.5-0.9c0.3-0.1,0.4-0.3,0.7-0.4
                        c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.3,0.3-0.7,0.4-0.9c0.1-0.5,0.1-0.9,0.1-1.5c0-0.4,0-0.9-0.1-1.3c0-0.4-0.1-0.8-0.4-1.2
                        c-0.1-0.3-0.4-0.5-0.7-0.8c-0.3-0.3-0.5-0.3-0.9-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.4,0.7-0.5,0.9c-0.1,0.5-0.3,1.1-0.4,1.6
                        c-0.1,0.7-0.3,1.5-0.5,2.2c-0.1,0.7-0.4,1.3-0.7,2c-0.3,0.5-0.5,1.1-0.9,1.5s-0.8,0.7-1.3,0.8c-0.5,0.1-1.2,0.3-1.7,0.3
                        c-0.7,0-1.3-0.1-1.8-0.5c-0.5-0.3-1.1-0.8-1.5-1.3c-0.4-0.5-0.8-1.2-0.9-2C289.7,491.7,289.6,490.9,289.6,490.1z"/>
                      <path class="st16" d="M289.6,475.1c0-0.9,0.1-1.8,0.4-2.8c0.3-0.7,0.5-1.3,1.1-2c0.4-0.5,0.8-0.9,1.3-1.2c0.4-0.3,0.9-0.4,1.5-0.4
                        c0.1,0,0.4,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.6c0,0.3,0,0.4-0.1,0.5s-0.3,0.3-0.4,0.4c-0.4,0.3-0.8,0.7-1.1,1.1
                        c-0.3,0.7-0.5,1.3-0.4,2.1c0,0.4,0,0.8,0.1,1.2c0.1,0.4,0.1,0.7,0.4,0.9c0.1,0.3,0.4,0.5,0.5,0.7c0.3,0.1,0.5,0.3,0.8,0.3
                        s0.7,0,0.9-0.3c0.3-0.1,0.4-0.4,0.5-0.8c0.1-0.4,0.4-0.9,0.4-1.3c0.1-0.5,0.3-1.3,0.5-2.1c0.1-0.8,0.4-1.6,0.7-2.4
                        c0.3-0.5,0.5-1.2,0.9-1.6c0.4-0.4,0.8-0.8,1.3-0.9c0.5-0.3,1.1-0.3,1.7-0.3c0.7,0,1.3,0.1,2,0.4c0.7,0.3,1.1,0.8,1.5,1.3
                        c0.4,0.7,0.8,1.3,1.1,2.1c0.3,0.9,0.4,1.8,0.4,2.8c0,0.8-0.1,1.7-0.3,2.5c-0.1,0.8-0.5,1.5-0.9,2.1c-0.4,0.5-0.8,1.1-1.5,1.5
                        c-0.5,0.4-1.2,0.5-1.8,0.7c-0.1,0-0.4,0-0.4-0.1c-0.1-0.1-0.1-0.3-0.1-0.4v-1.6c0-0.3,0-0.4,0.1-0.7c0.1-0.1,0.3-0.3,0.4-0.4
                        c0.3-0.1,0.4-0.3,0.7-0.4c0.3-0.1,0.4-0.4,0.5-0.7c0.1-0.3,0.3-0.7,0.4-0.9c0.1-0.5,0.1-0.9,0.1-1.5c0-0.4,0-0.9-0.1-1.3
                        c0-0.4-0.1-0.8-0.4-1.2c-0.1-0.3-0.4-0.5-0.7-0.8c-0.3-0.3-0.5-0.3-0.9-0.3c-0.3,0-0.5,0.1-0.8,0.3c-0.3,0.3-0.4,0.7-0.5,0.9
                        c-0.1,0.5-0.3,1.1-0.4,1.6c-0.1,0.7-0.3,1.3-0.5,2.2c-0.1,0.7-0.4,1.3-0.7,2c-0.3,0.5-0.5,1.1-0.9,1.5s-0.8,0.7-1.3,0.8
                        c-0.5,0.1-1.2,0.3-1.7,0.3c-0.7,0-1.3-0.1-1.8-0.4c-0.5-0.3-1.1-0.8-1.5-1.3c-0.4-0.5-0.8-1.2-0.9-2
                        C289.7,476.9,289.6,476.1,289.6,475.1z"/>
                      <path class="st12" d="M233.8,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C241.5,560.6,238.1,554.9,233.8,554.9z"/>
                      <path class="st19" d="M224.8,572.2h-42.4c-4.4,0-8.1-3.4-8.3-7.8l-7.7-126.2h74.1l-7.7,126.2C232.9,568.8,229.2,572.2,224.8,572.2
                        z"/>
                      <path class="st20" d="M237,572.2h-42.4c-4.4,0-8.3-0.7-8.3-7.8l-7.7-126.2h74.2l-7.7,126.2C245.2,572.2,241.4,572.2,237,572.2z"/>
                      <path class="st12" d="M167.9,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6H163v-4.6C163,430.9,165.3,428.8,167.9,428.8L167.9,428.8z"/>
                      <path class="st15" d="M182.4,428.8h69.7c2.6,0,4.9,2.2,4.9,4.9v4.6h-79.5v-4.6C177.6,430.9,179.7,428.8,182.4,428.8L182.4,428.8z"
                        />
                      <path class="st15" d="M177.4,554.9h-3.2c-4.2,0-7.7,5.7-7.7,12.7c0,7,3.4,12.7,7.7,12.7h3.2c4.2,0,7.7-5.7,7.7-12.7
                        C185.1,560.6,181.6,554.9,177.4,554.9z"/>
                      <path class="st12" d="M179.8,567.5c0,5.2-2.5,9.2-5.5,9.2c-3,0-5.5-4.1-5.5-9.2s2.5-9.2,5.5-9.2
                        C177.3,558.2,179.8,562.3,179.8,567.5z"/>
                      <path class="st16" d="M206.6,532.6c0-1.8,0.4-3.3,1.3-4.4c0.9-1.1,2.1-1.6,3.8-1.6c1.7,0,3,0.5,3.8,1.6c0.9,1.1,1.3,2.5,1.3,4.4
                        v3.7h5.2c0.4,0,0.7,0.3,0.7,0.7v1.8c0,0.4-0.3,0.7-0.7,0.7h-14.8c-0.4,0-0.7-0.3-0.7-0.7l0,0l0,0L206.6,532.6L206.6,532.6z
                        M214,536.3v-3.6c0-0.8-0.1-1.5-0.5-2.1c-0.4-0.5-0.9-0.8-1.8-0.8c-0.8,0-1.5,0.3-1.8,0.8c-0.4,0.7-0.5,1.3-0.5,2.1v3.6H214z"/>
                      <path class="st16" d="M206.6,517.7c0-0.3,0-0.4,0.1-0.5c0.1-0.1,0.4-0.3,0.5-0.3l14.5-5.7h0.1c0.4,0,0.7,0.3,0.7,0.7v1.7
                        c0,0.3,0,0.5-0.1,0.7c-0.1,0.1-0.3,0.1-0.4,0.3l-2.5,0.9v7.3l2.5,0.9c0.1,0,0.3,0.1,0.4,0.3c0.1,0.1,0.3,0.4,0.1,0.7v1.7
                        c0,0.4-0.3,0.7-0.7,0.7h-0.1l-14.5-5.7c-0.3-0.1-0.4-0.1-0.5-0.3c-0.1-0.1-0.3-0.4-0.1-0.5V517.7z M216.7,516.4l-6.6,2.5l6.6,2.5
                        V516.4z"/>
                      <path class="st16" d="M206.6,502.2c0-1.8,0.4-3.3,1.3-4.4c0.9-1.1,2.1-1.6,3.8-1.6c1.7,0,3,0.5,3.8,1.6c0.9,1.1,1.3,2.5,1.3,4.4
                        v3.7h5.2c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v1.8c0,0.4-0.3,0.7-0.7,0.7h-14.8c-0.4,0-0.7-0.3-0.7-0.7v-6.1L206.6,502.2
                        L206.6,502.2z M214,505.9v-3.6c0-0.8-0.1-1.5-0.5-2.1c-0.4-0.5-0.9-0.8-1.8-0.8c-0.8,0-1.5,0.3-1.8,0.8c-0.4,0.7-0.5,1.3-0.5,2.1
                        v3.6H214z"/>
                      <path class="st16" d="M219.8,483.2c0-0.4,0.3-0.7,0.7-0.7h1.6c0.4,0,0.7,0.3,0.7,0.7v10c0,0.4-0.3,0.7-0.7,0.7h-14.8
                        c-0.4,0-0.7-0.3-0.7-0.7v-9.9c0-0.4,0.3-0.7,0.7-0.7l0,0h1.6c0.4,0,0.7,0.3,0.7,0.7l0,0l0,0v7.4h3.7v-6.9c0-0.4,0.3-0.7,0.7-0.7
                        h1.6c0.4,0,0.7,0.3,0.7,0.7v6.9h3.8v-7.5H219.8z"/>
                      <path class="st16" d="M206.6,473.2c0-1.8,0.4-3.3,1.3-4.4c0.9-1.1,2.1-1.5,3.7-1.5c1.1,0,2,0.3,2.9,0.8c0.8,0.5,1.5,1.3,1.7,2.2
                        l5.7-3.2c0.1,0,0.1,0,0.3-0.1c0.1,0,0.3,0,0.4,0.1c0.1,0.1,0.1,0.3,0.1,0.4v2c0,0.3,0,0.5-0.3,0.7c-0.1,0.1-0.3,0.3-0.4,0.3
                        l-5.4,3v3h5.4c0.4,0,0.7,0.3,0.7,0.7v1.8c0,0.4-0.3,0.7-0.7,0.7h-14.8c-0.4,0-0.7-0.3-0.7-0.7L206.6,473.2L206.6,473.2z
                        M213.9,476.6v-3.3c0-0.7-0.1-1.5-0.4-2.1c-0.3-0.5-0.9-0.8-1.7-0.8s-1.5,0.3-1.7,0.8c-0.3,0.7-0.5,1.3-0.4,2.1v3.3H213.9z"/>
                    </g>
                  </a>
                  <a className='cursor-default women'>
                    <g>
                      <path class="st21" d="M626.5,420.8c-1.3,0-27.8-1.3-27.8-1.3l18.4,19.6l40.3,8.5L626.5,420.8z"/>
                      <path class="st22" d="M644.8,445.9c-0.9,2.7-2.5,11-2.5,11l-21.4-7c0,0,3.4-7.6,4.3-10.5c0.9-2.6,2.7-10.9,2.7-10.9l21.4,7
                        C649.4,435.7,645.7,443.2,644.8,445.9z"/>
                      <path class="st23" d="M644.8,445.9c-0.9,2.7-2.5,11-2.5,11l-15.1-4.9c0,0,3.4-7.6,4.3-10.5c0.9-2.6,2.7-10.9,2.7-10.9l15.1,4.9
                        C649.4,435.7,645.7,443.2,644.8,445.9z"/>
                      
                        <rect x="638.1" y="420.1" transform="matrix(0.311 -0.9504 0.9504 0.311 30.3126 904.4824)" class="st24" width="1.8" height="22.5"/>
                      
                        <rect x="630.6" y="443.1" transform="matrix(0.311 -0.9504 0.9504 0.311 3.2377 913.193)" class="st24" width="1.8" height="22.5"/>
                      <path class="st25" d="M648.9,440l7.8,11.8c0.9,1.5,0.6,3.3-0.9,4.2l-4.8,3.1c-1.5,1-3.5,0.6-4.5-0.9l-7.6-11.7
                        c-1.2-1.9-1.2-4.4,0-6.4c0.1-0.2,0.1-0.6,0-0.8c-0.1-0.1-0.1-0.1-0.1-0.2l-0.4-0.7l3.6-2.4l0.4,0.7c0,0.1,0.1,0.1,0.1,0.2
                        c0.1,0.2,0.3,0.3,0.7,0.3C645.3,437,647.5,438,648.9,440z"/>
                      <path class="st26" d="M648.9,440l7.8,11.8c0.9,1.5,0.6,3.3-0.9,4.2l-3,2c-1.5,1-3.5,0.6-4.5-0.9l0,0l0,0l-7.6-11.7
                        c-1.2-1.9-1.2-4.4,0-6.4c0-0.1,0.1-0.2,0.1-0.3c0-0.2,0-0.3-0.1-0.4s-0.1-0.1-0.1-0.2l-0.4-0.7l1.8-1.1l0.4,0.7
                        c0,0.1,0.1,0.1,0.1,0.2c0.1,0.2,0.3,0.3,0.7,0.3C645.3,437,647.5,438,648.9,440z"/>
                      <path class="st27" d="M646.8,441.3l7.8,11.8c0.9,1.5,0.6,3.3-0.9,4.2l-1,0.7c-1.5,1-3.5,0.6-4.5-0.9l0,0l0,0l-7.6-11.7
                        c-1.2-1.9-1.2-4.4,0-6.4c0-0.1,0.1-0.2,0.1-0.3c0.1,0,0.2,0,0.2,0C643.2,438.3,645.6,439.3,646.8,441.3z"/>
                      
                        <rect x="640.8" y="443.5" transform="matrix(0.8375 -0.5464 0.5464 0.8375 -139.534 426.1548)" class="st28" width="12" height="8.3"/>
                      
                        <rect x="637.3" y="436.2" transform="matrix(0.8365 -0.548 0.548 0.8365 -134.7696 421.9438)" class="st22" width="4.7" height="1.1"/>
                      <path class="st25" d="M622.3,435l2.8,13.8c0.3,1.7-0.7,3.3-2.4,3.6l-5.6,1.1c-1.7,0.3-3.5-0.8-3.8-2.5l-2.8-13.6
                        c-0.4-2.2,0.4-4.6,2.4-6c0.2-0.1,0.3-0.4,0.2-0.8c0-0.1,0-0.1-0.1-0.2l-0.1-0.7l4.2-0.9l0.1,0.7c0,0.1,0,0.2,0,0.3
                        c0,0.2,0.2,0.4,0.4,0.6C620,430.9,621.8,432.6,622.3,435z"/>
                      <path class="st26" d="M622.3,435l2.8,13.8c0.3,1.7-0.7,3.3-2.4,3.6l-3.6,0.8c-1.7,0.3-3.4-0.8-3.8-2.5l-2.8-13.6
                        c-0.4-2.2,0.4-4.6,2.4-6c0.2-0.1,0.3-0.4,0.2-0.8c0-0.1,0-0.1-0.1-0.2l-0.1-0.7l2.1-0.4l0.1,0.7c0,0.1,0,0.2,0,0.3
                        c0,0.2,0.2,0.4,0.4,0.6C620,430.9,621.8,432.6,622.3,435z"/>
                      <path class="st27" d="M620,435.5l2.8,13.8c0.3,1.7-0.7,3.3-2.4,3.6l-1.2,0.2c-1.7,0.3-3.4-0.8-3.8-2.5l-2.8-13.6
                        c-0.4-2.2,0.4-4.6,2.4-6c0.1-0.1,0.1-0.1,0.2-0.2c0.1,0.1,0.1,0.1,0.2,0.1C617.7,431.4,619.5,433.2,620,435.5z"/>
                      
                        <rect x="611.5" y="437.3" transform="matrix(0.9792 -0.2028 0.2028 0.9792 -76.6863 134.4146)" class="st22" width="12" height="8.3"/>
                      
                        <rect x="612.5" y="428.2" transform="matrix(0.9789 -0.2045 0.2045 0.9789 -74.6793 134.7878)" class="st22" width="4.7" height="1.1"/>
                      <path class="st20" d="M671.3,526l-4.6,29l-1.3,8.3l-2.6,16.7h-35.4c0,0-4.6-2.9-10.7-6.6c-10.3-6.5-24.6-15.5-26.1-17.1
                        c-0.9-1-2.6-8.9-4.2-17.6c-2.2-12.6-4.4-27.3-4.4-27.3l13-24.3l-11.2-26.7l14.7-40.8l18.3,19.6l40.3,8.5L671.3,526z"/>
                      <path class="st29" d="M666.6,555l-3.9,25.1h-35.4c0,0-4.6-2.9-10.7-6.6l-2.9-8.7l34.1-15.5l4-0.2L666.6,555z"/>
                      <path class="st21" d="M671.3,526l-4.6,29l-1.3,8.3c-2.2-1.1-18.3-9.4-17.6-13.9C648.4,544.5,671.3,526,671.3,526z"/>
                      <polygon class="st29" points="614.6,543.6 613.7,564.8 647.7,549.4 671.3,526 649.8,497.6 639.4,524.9 		"/>
                      <path class="st21" d="M618.6,520.8c0,0,17.1-4.9,18.5-7.2c1.6-2.2,12.6-16,12.6-16s-2.9,22.1-4.9,24.8
                        C642.8,525.1,618.6,520.8,618.6,520.8z"/>
                      <path class="st29" d="M604.7,504.7l-18.2,34.1c-2.2-12.6-4.4-27.3-4.4-27.3l10.2-12.1L604.7,504.7z"/>
                      <path class="st21" d="M604.7,504.7c0,0-9.9-3.8-22.6,6.7l13-24.3L604.7,504.7z"/>
                      <polygon class="st21" points="583.9,460.3 613.9,468.2 626.5,487.3 		"/>
                      <path class="st21" d="M591.5,555.4c0,0,21.5,6.5,23,8.5c1.6,2,6.1,10.6,6.1,10.6L591.5,555.4z"/>
                      <path class="st30" d="M565.1,315.9c0-0.7-0.6-7.6-11.1-8c0,0,5.2,0.7,6.1,2.9c0,0-3.6-2.8-6.4-1.9c-2.9,0.9-4.6,6-4.6,7.6
                        s1.6,7.1,3.4,7.5c0.8,0.1,1.5,0.1,2.2,0c-0.6,0.8-1,1.7-1.5,2.6c-4.3,8.7,0.1,17.2,3.7,16.2c2.2-0.6,2.9,3.4,2.9,3.4l22.3-18.1
                        C582.2,328,574,311,565.1,315.9z"/>
                      <path class="st15" d="M572.9,572.2l-3.9,2c0,0-21.4-29.4-23.9-54.6c-0.4-4.5-0.3-8.9,0.7-12.8c0,0-0.2-1.1-0.6-3.1
                        c-0.1-0.9-0.3-2.1-0.6-3.4c-0.7-3.8-1.6-9.1-2.7-15.1c-2.5-13.7-5.5-31.4-8-44.3l18-13.9l10.2-2.7c0,0,2.2,4.5,4.4,25.7
                        c0.3,2.8,0.7,5.8,1,9.2c0.9,12.4,0.6,24.8-1,37.2c-0.4,3.7-1,7.5-1.8,11.5c-0.6,2.9-0.7,6.7-0.4,11.2
                        C565.4,539.6,572.9,572.2,572.9,572.2z"/>
                      <path class="st12" d="M567.5,459.3l-22.4,44.4c-0.1-0.9-0.3-2.1-0.6-3.4c-0.7-3.8-1.6-9.1-2.7-15.1c-2.5-13.7-5.5-31.4-8-44.3
                        l18-13.9l10.2-2.7c0,0,2.2,4.5,4.4,25.7C566.8,452.9,567.2,455.9,567.5,459.3z"/>
                      <path class="st15" d="M557.6,430.1l-0.6-4.4c-8.7,2.2-31.8-6.4-31.8-6.4s-8.4,7.4-7.3,25.1c0.6,8.7,1.6,45.3,2.6,56.2
                        c0.3,3.9,0.7,6.7,0.8,7.9l0,0c0,0.3-0.1,0.7-0.2,1c-1.5,2.6-2.7,5.2-3.8,8c-10.1,26.9,0.9,57.4,0.9,57.4l3.6,0.9
                        c0,0,6.7-36.5,12.1-52.7c0.9-2.7,1.8-4.9,2.6-6.2c2.4-3.8,4.5-9.9,6.5-17.3C546.7,485.5,557.6,430.1,557.6,430.1z"/>
                      <path class="st31" d="M516.5,579.5h3.3c1.1-0.2,1.9-0.4,3-0.7c1.7-0.2,2.5,0.4,2.6,0.7l0,0h13.8c0.3-0.4,0-1.7-0.3-2.5
                        c-0.2-0.6-0.7-1-1.2-1.1c-1.5-0.4-4.4-0.8-7-3.1c-1.5-1.3-7.2-4.9-7.2-4.9l0,0c-0.1,0-0.7,0.2-3.3,2.4c-1.3,1.1-3.6-1.6-3.6-1.6
                        c-0.3,0.3-0.6,0.7-0.8,1c-0.3,0.7-0.4,1.3-0.3,2.1c0.2,1.2-0.4,3.6-1,5.1c-0.3,0.9-0.6,1.6-0.6,1.6S513.7,579.2,516.5,579.5z"/>
                      <path class="st21" d="M515.7,569.5c0.8,0.6,2.4,1.6,3.5,1.8c1.5,0.2,4.2-3.6,4.2-3.6l0,0c-0.1,0-0.7,0.2-3.3,2.4
                        c-1.3,1.1-3.6-1.6-3.6-1.6C516.1,568.9,515.9,569.2,515.7,569.5z"/>
                      <path class="st21" d="M516.5,579.5h3.3c1.1-0.2,1.9-0.4,3-0.7c1.7-0.2,2.5,0.4,2.6,0.7l0,0h13.8c0.3-0.4,0-1.7-0.3-2.5
                        c-0.4-0.3-0.6,1.6-0.8,1.9s-11.2,0.1-11.9-0.3c-0.7-0.4-2.6-1.2-5.2-0.7c-2.4,0.6-5.7-0.9-6.6-1.2c-0.3,0.9-0.6,1.6-0.6,1.6
                        S513.7,579.2,516.5,579.5z"/>
                      <path class="st31" d="M565,579.5h3.3c1.1-0.2,1.9-0.4,3-0.7c1.7-0.2,2.5,0.4,2.6,0.7l0,0h13.8c0.3-0.4,0-1.7-0.3-2.5
                        c-0.2-0.6-0.7-1-1.2-1.1c-1.5-0.4-4.4-0.8-7-3.1c-1.5-1.3-7.2-4.9-7.2-4.9l0,0c-0.1,0-0.7,0.2-3.3,2.4c-1.3,1.1-3.6-1.6-3.6-1.6
                        c-0.3,0.3-0.6,0.7-0.8,1c-0.3,0.7-0.4,1.3-0.3,2.1c0.2,1.2-0.4,3.6-1,5.1c-0.3,0.9-0.6,1.6-0.6,1.6S562.2,579.2,565,579.5z"/>
                      <path class="st21" d="M564.2,569.5c0.8,0.6,2.4,1.6,3.5,1.8c1.5,0.2,4.2-3.6,4.2-3.6l0,0c-0.1,0-0.7,0.2-3.3,2.4
                        c-1.3,1.1-3.6-1.6-3.6-1.6C564.7,568.9,564.5,569.2,564.2,569.5z"/>
                      <path class="st21" d="M565,579.5h3.3c1.1-0.2,1.9-0.4,3-0.7c1.7-0.2,2.5,0.4,2.6,0.7l0,0h13.8c0.3-0.4,0-1.7-0.3-2.5
                        c-0.4-0.3-0.6,1.6-0.8,1.9s-11.2,0.1-11.9-0.3c-0.7-0.4-2.6-1.2-5.2-0.7c-2.4,0.6-5.7-0.9-6.5-1.2c-0.3,0.9-0.6,1.6-0.6,1.6
                        S562.2,579.2,565,579.5z"/>
                      <path class="st32" d="M627.4,416.1c-1.1-1.1-7.5-0.8-7.5-0.8s-3.6-1.5-8-3.4c-6.2-2.7-13.8-6.4-15.5-8.2
                        c-3.9-4.4-8.7-18.9-13-28.5c-0.4-0.9-0.8-1.8-1.2-2.6c0-0.1-0.1-0.2-0.1-0.2c-0.2-0.4-0.4-0.8-0.6-1.1c0,0,0-0.1-0.1-0.1
                        c-0.7-1.3-1.5-2.6-2.5-3.7l0,0c-1-1-1.9-1.5-2.8-1.1c-1.3,0.8-2.5,1.7-3.4,2.9c-0.2,0.2-0.3,0.3-0.4,0.6c-1.2,1.6-2.1,3.4-2.7,5.3
                        s-0.7,3.9-0.6,5.8c0.1,3.7,2,8.3,4.6,13c0.1,0.2,0.2,0.4,0.3,0.7c5.1,8.8,12.7,17.5,17.4,20.5c3,1.9,10.8,3,17.3,3.7
                        c4.4,0.4,8.2,0.7,9.8,0.8c0.7,0.8,5.6,6.4,7.5,4.7C628,422.6,628.5,417.3,627.4,416.1z"/>
                      <path class="st33" d="M574.2,376.2c-3.1,1.5-6.5,2.2-10,2.4c-14.9,0.8-18.3-12.3-18.9-20.9c1.7-0.6,3.4-0.8,5.1-0.7
                        c1,0.1,2.1,0.1,3,0.2c1.8-0.9,5.1-5.6,7-13.3l0.7,0.3l10.8,5.1c0,0-1.1,3.5-2,6.9c-0.7,2.6-1.1,5.2-0.8,5.8
                        c0.9,0.3,1.3,0.6,1.3,0.6l0,0C572.2,367,573.4,371.6,574.2,376.2z"/>
                      <path class="st14" d="M577.9,386c-0.1,0.3-0.2,0.6-0.3,0.9c-3.8,8.1-10.8,41.8-10.8,41.8c-1.6,6.1-45.5-1.2-45.6-6.7
                        c-1.7-5.8,5.8-15.3,10-19.9c0.6-0.6,1-1.2,1.5-1.9c1-1.7,1.7-3.6,1.9-5.5c0.8-7.6,0.6-14.8,0.6-19.3v-0.2c0.8-0.6,1.6-1.1,2.4-1.7
                        c1-0.7,2-1.5,2.8-2.4c0.8-0.9,1.3-1.7,1.3-2.7c0-0.8,0-1.5-0.1-2.2c0-0.3-0.1-0.7-0.1-1.1c-0.4-2.5-1.1-4.9-2.1-7.2
                        c4-1.6,11.1-0.7,11.1-0.7v1c0,2.6,0,9.2,0.8,10.6c1,1.6,20.1,8.4,21.1,6.3c1-2.1-1.7-11.9-1.8-12.3l0,0c6.4,2.5,8.7,4.6,8.7,4.6
                        C575.4,369.7,580.9,378.1,577.9,386z"/>
                      <path class="st32" d="M571.9,349.4c0,0-1.1,3.5-2,6.9c-6.3-1.8-8.2-8.3-8.8-11.9L571.9,349.4z"/>
                      <path class="st33" d="M573.2,353.1c0,0-15.1-3.5-12.7-12.8s1.9-16.1,11.6-14.3c9.7,1.8,10.7,5.8,10.7,9.1S577.2,353.8,573.2,353.1
                        z"/>
                      <path class="st30" d="M583.6,329.9c-1.6-3.4-3.5-3.1-4.7-2.6c-7.1-6.5-14.9-1.2-14.9-1.2c-2.7,6.4-7.5,9.1-7.5,9.1s2.4,2.4,9,2.4
                        c2,0,4.2-1,6-2.2c-2.4,2.2-4.9,4.3-7.9,5.8c8.4-1.6,13.9-11.4,14.6-12.4c0.7,1,2,3.3,2.1,5.2c0.1,2.6-0.7,7.5,0.8,7.8
                        C581.1,341.6,586,335,583.6,329.9z"/>
                      <path class="st14" d="M579.2,367.2c0,0,3.6,6.3,4.2,8c0,0-4.7-0.2-6.6,3.4C576.7,378.6,576.3,368.1,579.2,367.2z"/>
                      <path class="st13" d="M554.2,417.4c-9.2-0.1-17-9.7-21.7-17.2c1-1.7,1.7-3.6,1.9-5.5c0.8-7.6,0.6-14.8,0.6-19.3v-0.2
                        c0.8-0.6,1.6-1.1,2.4-1.7c1-0.7,2-1.5,2.8-2.4C547.8,383.6,566.7,417.5,554.2,417.4z"/>
                      <path class="st33" d="M605.4,424.6c0,0-6-4.3-9.6-3.4c-3.1,0.7-9.9-0.7-11.2-1l0,0c-1.6-0.9-3.5-1.9-5.5-3
                        c-4.8-2.7-10.5-6.1-14.2-8.8c-2.1-1.6-3.8-2.9-4.4-3.9c-0.9-1.6-1.9-3.9-3-7c-0.6-1.6-1.2-3.3-1.8-5.2c-1.1-3-2.1-6.3-3.3-9.6
                        c-2-6-4.2-11.9-6.2-16.5c-6.1-2.1-10.7,1.1-12.3,2.5c0,0.3,0,0.7,0,1c0,0.6,0,1.2,0,1.8c0.1,2.1,0.4,4.4,1,6.5
                        c1,4.6,2.4,9.2,4,13.6c0.4,1.2,1,2.6,1.6,3.9c3.8,9.7,8.9,18.5,13,21.6c0.1,0.1,0.3,0.2,0.4,0.3c1.8,1.1,4.4,2.2,7.3,3.3
                        c4.3,1.5,9.2,2.7,13.7,3.8l0,0c2.6,0.6,5.1,1.1,7.1,1.5c0,0,0.4,0.1,1.2,0.2l0,0c3.3,0.8,6.4,1.7,9.4,2.9c5.2,2,8.4-1.3,10.7-2.2
                        C606,425.8,605.4,424.6,605.4,424.6z"/>
                      <path class="st14" d="M546.2,366c-6.9-1.9-12.3,3.5-12.3,3.5c0.1-10.3,5.3-11.8,5.3-11.8C541.4,356.8,543.8,360.5,546.2,366z"/>
                      <path class="st34" d="M604,426.9c-2.2,1-5.5,4.4-10.7,2.2c-3.1-1.1-6.3-2.1-9.6-2.9c-0.8-0.1-1.2-0.2-1.2-0.2
                        c-2-0.4-4.4-0.9-7.1-1.5c2.1-2.7,3.4-6.1,3.8-7.4c2,1.1,3.9,2.1,5.5,3c1.3,0.3,8.1,1.7,11.2,1c3.6-0.8,9.6,3.5,9.6,3.5
                        S606.3,426,604,426.9z"/>
                      <path class="st34" d="M625.7,424.3c-1.9,1.7-6.9-4-7.5-4.7c-1.6-0.1-5.4-0.3-9.8-0.8c1.3-2.2,2.6-5.4,3.1-6.9c4.4,1.9,8,3.4,8,3.4
                        s6.5-0.2,7.5,0.8C628.3,417.3,627.7,422.6,625.7,424.3z"/>
                    </g>
                  </a>
                  <a className='cursor-default child'>
                    <g>
                      <path class="st32" d="M721.6,460.4c-1,2.4-7.4,1.7-12.4-1.7c-4.9-3.4-12-6.4-16-9.2c-3-2-6.2-9.8-8.5-14.6c-0.4-1-1-2-1.7-2.9
                        c-2.9-0.9-2.7-3.8-5.4-4.8c-3.1-1.1-2.2-2.9-2.2-2.9c1.6-0.6,3.4-0.9,5.1-0.7c1,0.1,3,3,4.2,4.8c1.1,0.8,2.5,1.9,3.8,3.3
                        c3.8,3.4,8.2,7.9,10,10.1c2,2.6,15.3,5.4,18.9,8.3C723.9,455.4,721.6,460.4,721.6,460.4z"/>
                      <path class="st32" d="M698.6,496.8l13.8,77l4.8,0.3c0,0,3.8-33.5,0.6-45.1c-3.3-11.6,5.6-26.2,3.1-36.3
                        C718.5,482.8,698.6,496.8,698.6,496.8z"/>
                      <path class="st33" d="M714.6,454.3l-12.1-2c-0.6-2-0.8-4.7-0.8-7.1c0-2.9,0.2-5.5,0.2-5.5l9.8-0.9l4.4-0.4
                        C713.7,443,714.6,454.3,714.6,454.3z"/>
                      <path class="st32" d="M715.1,440.9c0,0-6.5,5.5-13.3,4.4c0-2.9,0.2-5.5,0.2-5.5l9.8-0.9L715.1,440.9z"/>
                      <path class="st33" d="M708,515.8c-2.5,6.9-4.6,13.8-3.6,19.4c0.3,1.7,0.6,3.5,0.7,5.2s0.1,3.3,0,4.9c-0.6,13.4-5.6,27.1-6.4,29.1
                        l-0.1,0.2l-4.3-0.4l-1.8-80.8c0,0,19.4-7.9,20.5,2.5C713.5,501.4,710.6,508.5,708,515.8z"/>
                      <path class="st14" d="M719.6,520.4c0,0-1.2,0.2-3,0.3c-3.5,0.3-9,0.8-12.5,0.3c-1.7-0.2-2.6-2.9-3-6.6c-1-8.1,0.4-20.7,1.8-21.2
                        c1.9-0.8,18.7-0.1,18.7-0.1S725.1,502.1,719.6,520.4z"/>
                      <path class="st13" d="M716.7,520.8c-3.5,0.3-9,0.8-12.5,0.3c-1.7-0.2-2.6-2.9-3-6.6l9.2-15.1l6.9-2.7
                        C717.3,496.7,720.1,508.3,716.7,520.8z"/>
                      <path class="st14" d="M692.4,493.3c0,0-3.6,16-3,28.7c0,0,8.2,1.6,16.6,0c0,0,13.9-20.2,12.7-26.7
                        C717.4,488.7,695.8,491.3,692.4,493.3z"/>
                      <path class="st33" d="M699,437.9c0,0,11.4,9,16.7,1.8c5.5-7.1,10.7-10.8,3.6-16.7c-7-6.1-10.5-4.4-12.9-2.5
                        C704,422.4,696.2,435.3,699,437.9z"/>
                      <path class="st30" d="M729.2,425.6c-0.4-0.1-0.9-0.1-1.3,0l0,0c0.9-1.7,0.2-4.3,0.2-4.3c0.2,1.5-1,3.1-1,3.1
                        c-0.1-5.3-13.3-8.7-14.4-9s-1-2.4-1-2.4c-2.5,0.6-5.2,3.7-5.1,6.9c0.1,3.1,2.7,9.1,4.4,9.2s0.9,1.5-0.2,3.3s0.3,6.9,2.4,8.7
                        c1.7,1.5,2.5,0.2,3.5-0.3c0.2-0.1,0.4-0.2,0.8-0.3c3.8-0.9,7.9-4.6,9.8-9.4c0.6-1.6,0.9-3.1,0.9-4.8
                        C728.3,426,728.8,425.8,729.2,425.6z"/>
                      <path class="st32" d="M705.1,545.5c-0.6,13.4-5.6,27.1-6.4,29.1l-3.1-0.2C695.8,573.9,702,545.8,705.1,545.5L705.1,545.5z"/>
                      <path class="st31" d="M717.6,579.5h-2.7c-0.9-0.1-1.6-0.3-2.5-0.4c-1.3-0.1-2,0.3-2.1,0.4h-11.5c-0.3-0.3,0-1.1,0.2-1.8
                        c0.2-0.4,0.6-0.8,1.1-0.9c1.2-0.3,3.6-0.6,5.7-2.4c1.2-1,6-3.6,6-3.6l0,0c0.1,0,0.6,0.1,2.7,1.7c1.1,0.8,3-1.1,3-1.1
                        c0.2,0.2,0.4,0.4,0.6,0.8c0.2,0.4,0.3,1,0.2,1.6c-0.2,0.9,0.3,2.6,0.8,3.7c0.2,0.7,0.4,1.2,0.4,1.2S720,579.3,717.6,579.5z"/>
                      <path class="st35" d="M718.3,572.2c-0.7,0.4-2,1.2-2.9,1.2c-1.2,0.1-3.5-2.6-3.5-2.6l0,0c0.1,0,0.6,0.1,2.7,1.7
                        c1.1,0.8,3-1.1,3-1.1C717.9,571.7,718.1,572,718.3,572.2z"/>
                      <path class="st35" d="M717.6,579.5h-2.7c-0.9-0.1-1.6-0.3-2.5-0.4c-1.3-0.1-2,0.3-2.1,0.4h-11.5c-0.3-0.3,0-1.1,0.2-1.8
                        c0.3-0.2,0.4,1.2,0.7,1.5c0.2,0.2,9.3,0.1,9.8-0.2c0.6-0.3,2.1-0.9,4.3-0.4c1.9,0.4,4.7-0.7,5.4-0.9c0.2,0.7,0.4,1.2,0.4,1.2
                        S720,579.3,717.6,579.5z"/>
                      <path class="st31" d="M699.7,579.5H697c-0.9-0.1-1.6-0.3-2.5-0.4c-1.3-0.1-2,0.3-2.1,0.4l0,0H681c-0.3-0.3,0-1.2,0.2-1.8
                        c0.2-0.4,0.6-0.8,1-0.9c1.2-0.3,3.6-0.6,5.7-2.4c1.2-1,6-3.6,6-3.6l0,0c0.1,0,0.6,0.1,2.7,1.7c1.1,0.8,3-1.1,3-1.1
                        c0.2,0.2,0.4,0.4,0.6,0.8c0.2,0.4,0.3,1,0.2,1.6c-0.2,0.9,0.3,2.6,0.8,3.7c0.2,0.7,0.4,1.2,0.4,1.2S702,579.3,699.7,579.5z"/>
                      <path class="st35" d="M700.3,572.2c-0.7,0.4-2,1.2-2.9,1.2c-1.2,0.1-3.5-2.6-3.5-2.6l0,0c0.1,0,0.6,0.1,2.7,1.7
                        c1.1,0.8,3-1.1,3-1.1C700,571.7,700.2,571.9,700.3,572.2z"/>
                      <path class="st35" d="M699.8,579.5H697c-0.9-0.1-1.5-0.3-2.4-0.4c-1.3-0.1-2,0.3-2.1,0.4l0,0H681c-0.3-0.3,0-1.2,0.2-1.8
                        c0.3-0.2,0.4,1.2,0.7,1.5c0.2,0.2,9.3,0.1,9.8-0.2c0.4-0.3,2.1-0.9,4.3-0.4c1.9,0.4,4.7-0.7,5.4-0.9c0.2,0.7,0.4,1.2,0.4,1.2
                        S702,579.3,699.8,579.5z"/>
                      <path class="st31" d="M722.1,493.6c0,0.2-0.2,0.3-0.3,0.4c-1.7,0.6-10,3.1-21.7,1.2c-2.6-0.4-5.2-1.1-7.6-1.9l1.7-30.7l1.2-13.9
                        c0,0,4.2-2.1,6.4-2.2c0,0,9.3,1,12.9,0.1c0,0,4.3,1.9,6,4.3C722.1,453.1,725.2,475.2,722.1,493.6z"/>
                      <path class="st36" d="M700,495.3c-2.4-0.4-4.9-0.9-7.6-1.9l1.8-35.3l5.4-0.7C699.5,457.4,704.2,480.3,700,495.3z"/>
                      <path class="st33" d="M702.4,460.4c-1,2.4-7.4,1.7-12.4-1.7c-0.4-0.3-0.9-0.6-1.3-0.9c-4.8-3-11-5.8-14.7-8.3
                        c-3.4-2.4-7.1-12.3-9.6-16.4c-0.2-0.3-0.4-0.7-0.7-1c-2.9-0.9-2.7-3.8-5.4-4.8c-3.1-1.1-2.2-2.9-2.2-2.9c1.6-0.6,3.4-0.8,5.1-0.7
                        c1,0.1,3,3,4.2,4.8c0.8,0.6,1.8,1.3,2.8,2.1c4,3.6,9.2,8.8,11.1,11.1c1.5,1.8,8.8,3.8,14,5.8c2.1,0.8,3.8,1.7,4.8,2.5
                        C704.6,455.4,702.4,460.4,702.4,460.4z"/>
                      <path class="st31" d="M702.5,460.5c-1,2.4-7.6,1.8-12.5-1.6c-0.4-0.3-0.9-0.7-1.3-0.9c5.1-3.8,5.1-8.5,4.8-10.3
                        c2.1,0.8,3.9,1.6,4.9,2.4C704.7,455.2,702.5,460.5,702.5,460.5z"/>
                      <path class="st34" d="M688.6,431.7c-0.4,1.2-1.5,2.6-3.8,3.3c-0.4-1-1-2-1.7-2.9c-2.9-0.9-2.7-3.8-5.4-4.8
                        c-3.1-1.1-2.2-2.9-2.2-2.9c1.6-0.6,3.4-0.9,5.1-0.7c1,0.1,3,3,4.2,4.8C685.8,429.4,687.2,430.5,688.6,431.7z"/>
                      <path class="st15" d="M680.7,429.7c-1.3,1-5.1,4.7-5.1,4.7l-8.4-10.9c0,0,4.3-2.7,5.7-3.8c1.3-1,5.2-4.6,5.2-4.6l8.4,10.9
                        C686.5,426,682,428.6,680.7,429.7z"/>
                      <path class="st12" d="M680.7,429.7c-1.3,1-5.1,4.7-5.1,4.7l-6-7.6c0,0,4.3-2.7,5.7-3.8c1.3-1,5.2-4.6,5.2-4.6l6,7.6
                        C686.5,426,682,428.6,680.7,429.7z"/>
                      
                        <rect x="682.1" y="413.4" transform="matrix(0.7909 -0.6119 0.6119 0.7909 -114.4529 505.5849)" class="st31" width="1.1" height="13.7"/>
                      
                        <rect x="670.4" y="422.5" transform="matrix(0.791 -0.6118 0.6118 0.791 -122.4596 500.263)" class="st31" width="1.1" height="13.7"/>
                      <path class="st37" d="M669.2,425.1l-8.9,10.9c-1,1.3-2.9,1.5-4.3,0.4l-4.5-3.6c-1.3-1.1-1.6-3.1-0.4-4.5l8.8-10.8
                        c1.5-1.8,3.8-2.6,6.1-1.9c0.3,0.1,0.6,0,0.8-0.2c0-0.1,0.1-0.1,0.1-0.2l0.4-0.6l3.4,2.7l-0.4,0.6c-0.1,0.1-0.1,0.1-0.2,0.2
                        c-0.2,0.2-0.2,0.4-0.1,0.7C670.9,420.7,670.7,423.3,669.2,425.1z"/>
                      <path class="st27" d="M669.2,425.1l-8.9,10.9c-1,1.3-2.9,1.5-4.3,0.4l-2.8-2.2c-1.3-1.1-1.6-3.1-0.4-4.5l8.8-10.8
                        c1.5-1.8,3.8-2.6,6.1-1.9c0.1,0,0.2,0,0.3,0c0.2,0,0.3-0.1,0.4-0.3c0-0.1,0.1-0.1,0.1-0.2l0.4-0.6l1.7,1.3l-0.4,0.6
                        c-0.1,0.1-0.1,0.1-0.2,0.2c-0.2,0.2-0.2,0.4-0.1,0.7C670.9,420.7,670.7,423.3,669.2,425.1z"/>
                      <path class="st27" d="M667.3,423.5l-8.9,10.9c-1,1.2-2.9,1.5-4.3,0.4l-1-0.8c-1.3-1.1-1.6-3.1-0.4-4.5l8.8-10.8
                        c1.5-1.8,3.8-2.6,6.1-1.9c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0.1,0.2C669.1,419.3,668.9,421.7,667.3,423.5z"/>
                      
                        <rect x="657" y="419.4" transform="matrix(0.6323 -0.7747 0.7747 0.6323 -86.4963 668.6222)" class="st30" width="8.3" height="12"/>
                      
                        <rect x="668.8" y="413" transform="matrix(0.6307 -0.776 0.776 0.6307 -75.1148 672.8408)" class="st30" width="1.1" height="4.7"/>
                      <path class="st34" d="M668.3,430.7c-0.7,1-1.8,1.8-3.8,2.4c-0.2-0.3-0.4-0.7-0.7-1c-2.9-0.9-2.7-3.8-5.4-4.8
                        c-3.1-1.1-2.2-2.9-2.2-2.9c1.6-0.6,3.4-0.8,5.1-0.7c1,0.1,3,3,4.2,4.8C666.3,429.2,667.3,429.8,668.3,430.7z"/>
                    </g>
                  </a>
                </svg>,

                <svg version="1.1" width="960" height="600" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                  viewBox="0 0 960 600" className='slide4'>
                  <a className="cursor-default bannerbg">
                    <g>
                      <path class="st0" d="M249,369.5c0,0-18.7-17-16.9-39.6c1.7-22.6,7.4-31.2,23.8-50.9c16.4-19.8,28.3-32.3,4-52.6
                        c-24.3-20.3-49.2-37.9-47-82.7c2.3-44.8,46.4-83.3,106.4-92.9s100.8-7.4,191.4,10.8s117.7,19.3,139.2,20.9
                        c21.5,1.6,40.7,26.6,23.2,45.9c-17.5,19.3-49.9,39.1-34,56.1c15.9,17,48.7-1.6,73.5,4c24.8,5.6,44.2,20.9,29.4,38
                        c-14.8,17-55,20.9-57.8,33.4c-2.8,12.4,9,15.9,24.3,21.4s31.1,31.7,23.2,43s-55.5,40.2-71.9,39.1s-74.2,3.4-87.8,0
                        c-13.5-3.4-100.3,2.1-117.9-9.6c-17.5-11.7-82.7-20.9-101.9-13C333.3,348.6,277.3,392.7,249,369.5z"/>
                      <path class="st0" d="M660.6,70.2c0,0-31.3-1.8-38.2-26.8S640.1-1.1,668.8,0s29.8,31.7,29.4,40S680.6,72.9,660.6,70.2z"/>
                      <path class="st0" d="M690.8,94.4c0,0-12.4-1.5-12.4-12.1s12.4-18.2,19.2-15.1c6.8,3,11.3,12.8,9.8,18.2
                        C705.9,90.6,694.5,97.1,690.8,94.4z"/>
                      <path class="st0" d="M716,57.6c0,0-6.8,0-8.2-9.3s8.2-11.1,13-8.2c4.8,2.9,4.8,6.3,4.8,9.9C725.7,53.6,720.5,58.6,716,57.6z"/>
                      <path class="st1" d="M570.6,157.2c0,0-5.1-16.7,8.2-24c13.3-7.4,23,16.7,23,16.7s5.1-29.7,21.5-28.9c16.4,0.8,10,38.5,10,38.5
                        s4.8-8.5,7.9-5.1c3.1,3.4-0.3,8.8-0.3,8.8s10.8-5.1,13.5,0.3c2.8,5.4-4,10.5-10.5,12.2c-6.5,1.6-84.3,2.3-84.3,2.3
                        s-8.5-6.3-5.6-14.8C556.6,154.6,567.7,154.4,570.6,157.2z"/>
                      <path class="st2" d="M278,124.1c0,0-3.5-21.6,6.6-31.2c10.1-9.5,33.9-7.7,35.5,13.5c1.6,21.2-0.2,18.9-0.2,18.9s8.5-7,12.8,0
                        c4.3,7-1.2,15.1-1.2,15.1s9.6-1.7,8.2,4.8c-1.4,6.3-81.2,3.6-81.2,3.6s-3.6-5.3-2.2-15.9C257.7,122.3,271.4,118.9,278,124.1z"/>
                      <path class="st1" d="M479.8,101.9c0,0-4.9-10.6,0.4-16.2S495,95,495,95s-0.7-17.4,6.8-17.4c7.5,0,4.5,23,4.5,23s3.8-10.6,7.9-6.5
                        c4.1,4.1-1.1,12.8-1.1,12.8l-40.4,2.3C472.6,109.4,464.7,99.6,479.8,101.9z"/>
                      <path class="st1" d="M246.9,151.2c0,0-5.3-10.6,0.4-16.2s16.2,9.3,16.2,9.3s-0.8-17.4,7.5-17.4c8.3,0,5,23,5,23s4.1-10.6,8.7-6.5
                        c4.5,4.1-1.2,12.8-1.2,12.8l-44.4,2.3C239,158.6,230.2,149,246.9,151.2z"/>
                      <path class="st1" d="M517.5,236.1c0,0-4.9-11.4,6.2-13s10.1,11.7,10.1,11.7s-0.6-17.7,6.9-17.7c7.5,0,7.8,18.4,7.8,18.4
                        s3.6-10.8,9.1-7.4c5.5,3.4,2.6,11.1,2.6,11.1s6.2-3.4,6.9,0.7c0.6,4-59.6,3.4-59.6,3.4S504.3,232,517.5,236.1z"/>
                      <path class="st1" d="M438.2,188.2c0,0-4.9-13.6,6.2-15.7s10.2,14.1,10.2,14.1s-1.1-15.3,6.4-15.9c11.6-1,8.3,16.7,8.3,16.7
                        s3.6-12.9,9.1-8.8c5.5,4,2.6,13.2,2.6,13.2s7.4-3.4,8,1.4s-60.8,3.4-60.8,3.4S425.1,183.4,438.2,188.2z"/>
                      <path class="st3" d="M540.5,125.6c-6.7-0.2-11.9,4.1-15.1,9.6c-2.8-4.8-7.6-8.3-13.1-9.3c-2.1-0.3-3,2.8-0.8,3.2
                        c5.8,0.9,10.3,5.3,12.6,10.6c0.6,1.4,2.6,0.7,3-0.4c2.3-5.6,7.1-10.6,13.4-10.5c0.9-0.2,1.4-1,1.3-1.9
                        C541.7,126.2,541.2,125.7,540.5,125.6L540.5,125.6z"/>
                      <path class="st3" d="M563.1,101.6c2.1-0.3,1.2-3.5-0.8-3.2c-3.4,0.5-6.1,3.2-8,6.2c-1.9-3.6-5.3-6.1-9.2-7
                        c-2.1-0.5-2.9,2.7-0.8,3.2c4,0.8,7.2,3.9,8.2,7.9c0.3,0.8,1.2,1.3,2.1,1.1c0.4-0.1,0.7-0.4,0.9-0.7
                        C557,106.1,559.4,102.2,563.1,101.6z"/>
                      <path class="st3" d="M571.9,123.9c-2.8,0-5.1,1.9-6.9,4.2c-1-1.8-2.6-3.3-4.5-4.2c-1.8-0.9-3.5,1.9-1.6,2.8c2.2,1,3.6,3,4,5.2
                        c0.3,1.4,2.3,1.4,3,0.4c1.2-1.8,3.5-5.1,5.9-5.1c0.9,0,1.6-0.7,1.6-1.6C573.6,124.6,572.9,123.9,571.9,123.9L571.9,123.9z"/>
                      <path class="st4" d="M268.2,354.9c0,0-13.5-5.1-3.4-20.9c10.2-15.9,49.9-20.4,69.6-17.5c19.8,2.9,64,22.1,67.9,9
                        c4-13,57.9,6.5,82.8,16s50.4,18.6,106.4,11.2s123.4,6.3,127.9,19.3s-10.8,22.5-37.6,29.3c-26.9,6.9-165,12.1-191.6,14.8
                        C463.6,418.8,273.3,380.3,268.2,354.9z"/>
                      <path class="st4" d="M707,351.5c0,0-16.4,5.6-15.3-4.5c1.1-10.2,19.3-9.6,23.2-6.8C718.9,343,717.2,349.7,707,351.5z"/>
                      <path class="st4" d="M727.4,363.3c0,0-9.6,2.3-6.8-4s14.2-4,15.3-1.1C737.1,361,731.9,363.3,727.4,363.3z"/>
                      <path class="st4" d="M243.9,358.8c0,0-17.5,0-15.3-6.8c2.3-6.8,17.5-10.2,20.9-6.8C252.9,348.6,254.2,354.9,243.9,358.8z"/>
                    </g>
                  </a>
                  <a className="cursor-default bannerbg garbage">
                    <g>
                      <path class="st5" d="M210.2,481.5c0,0-13.3-6.6-42.1-16s-46.7-45.3-26-74c20.7-28.6,84.1-50,141.4-57.4
                        c57.4-7.4,114.7,32,174.8,30c60.1-2.1,94-6,84,16s54.8,9.3,94,4.7c39.4-4.6,121.4-7.4,156,14c34.7,21.4,43.4,65.3,24.7,92
                        c-18.7,26.7-19.3,34.7-56.1,40.7c-36.6,5.9-46.7,4-52.7,36.7s13.3,55.4-92.1,61.4c-105.4,6-152.8-9.3-141.4-32.6
                        c11.4-23.3,23.3-50-29.4-35.4c-52.7,14.6-189.5,26-225.5,7.4c-36-18.6-42.1-36.7-20.1-52.8C222.3,500.2,233.6,496.8,210.2,481.5z"
                        />
                      <path class="st5" d="M394.7,582.3c-6.3,0.1-18.7-2.3-21.9,5.3c-1.2,3-0.2,6.5,2.5,8.5c3.1,2.4,6.9,1.9,10.5,1.2l0.6-0.2
                        c6.2,1.1,13.9,0.6,17.6-4.2c1.8-2.2,2.1-5.3,0.6-7.7C402.3,581.7,398.2,582.3,394.7,582.3z"/>
                      <path class="st5" d="M431.2,568c-2.1-1.1-4.4-1.9-6.6-2.5c-7.1-2.3-18.9-1.6-20.2,6.8c-1,6,3.7,8.2,9.2,8.5
                        c3.3,1.2,6.9,1.5,10.4,0.6c3.1-0.5,6.3-1.5,7.6-4.6c0.1-0.4,0.2-0.7,0.4-1.1c0.4-0.6,0.5-1.3,0.4-1.9c1.3-1.5,1.2-3.7-0.2-5.1
                        C431.7,568.4,431.4,568.3,431.2,568z"/>
                      <path class="st5" d="M357.2,588.6c-0.4-1.3-1.3-2.3-2.7-2.7c-3.7-1.5-10.2-0.6-10,4.5c0.1,5.1,5.9,6.6,9.8,5.4
                        c1.9-0.6,3.1-2.5,2.7-4.5C357.3,590.4,357.4,589.4,357.2,588.6z"/>
                      <path class="st5" d="M414.5,599.9c-1.9,0.1-3.6,1.1-4.6,2.8c-0.4,0.2-0.7,0.7-1,1.1c-1,1.8-0.4,4.1,1.3,5.3l1.1,0.7
                        c1.7,1,3.7,0.5,5-1c0.5-0.6,0.8-1.5,0.7-2.3c0.8-0.7,1.2-1.7,1.2-2.8C418.4,601.6,416.7,599.9,414.5,599.9z M416.1,603.8
                        c0.1,0.1,0.2,0.2,0.4,0.5L416.1,603.8z M414.5,607.6c0.2,0,0.5,0,0.7-0.1C415,607.6,414.7,607.6,414.5,607.6L414.5,607.6z"/>
                      <path class="st5" d="M766.3,545.8c-4.8-1.8-10.2-2.3-15.2-1.3H751c-3,0.4-5.8,1.7-7.9,3.9l-0.4,0.5c-0.1,0.1-0.2,0.2-0.4,0.4
                        c-0.1,0.1-0.2,0.2-0.2,0.4c-2.1,2.4-2.1,6-0.1,8.6c3.7,5.3,14.4,6.4,21,4.7c3.5-0.5,6.3-1.9,7.1-5.2
                        C773.2,554.1,771.2,547.9,766.3,545.8z"/>
                      <path class="st5" d="M773.8,536.6c-0.1-1.1-0.8-2.1-1.8-2.5c-1.8-1-4.1-0.4-5.2,1.3c-0.6,0.1-1.2,0.2-1.8,0.5
                        c-2.1,0.6-3.3,2.8-2.7,4.8l0,0c0.1,0.6,0.5,1.1,1,1.6c1.5,1.6,3.4,2.4,5.6,2.5c2.1,0.1,4.1-0.6,5.7-2.1c1.2-1.2,1.5-3.1,0.6-4.7
                        C774.8,537.6,774.3,537.1,773.8,536.6z M767.7,535c-0.2,0.1-0.4,0.1-0.6,0.2l0,0C767.2,535.2,767.4,535,767.7,535L767.7,535z"/>
                      <path class="st5" d="M782.8,545.4c-5,0-5,7.7,0,7.7C787.7,553,787.7,545.4,782.8,545.4z"/>
                      <path class="st5" d="M794.9,532.4c-2.7-0.8-5.6-1-8.2-0.2c-2.1,0.6-3.3,2.8-2.7,4.8l0,0c0.1,0.6,0.5,1.1,1,1.6
                        c2.7,2.8,7,3.4,10.3,1.3c0.4-0.2,0.6-0.5,0.8-0.7c0.1-0.1,0.4-0.2,0.5-0.4C799.1,536.7,797.4,533.2,794.9,532.4z"/>
                      <path class="st2" d="M459.3,486.5c-6.6-1.5-17.8,0-21.9,6.3c-5.1,7.9,8.7,12.3,14,12.1c0.6,0,1.1-0.1,1.6-0.4
                        c1.1,0.2,2.3,0.2,3.4,0c3.5-0.4,6.8-1.7,9.4-4C471.7,494.8,465.6,487.8,459.3,486.5z"/>
                      <path class="st2" d="M478.3,509.5c-0.8-1.3-2.4-2.1-4-1.8l0,0c-0.7,0-1.3,0.1-1.9,0.5c-1.8,0.4-3.1,1.9-3.1,3.7
                        c0,1,0.4,2.1,1.1,2.8c0.7,0.6,1.7,1.1,2.7,1.1c0.5,0,1,0,1.3,0h0.5c1.9-0.1,3.6-1.6,3.9-3.5C479,511.2,478.8,510.3,478.3,509.5z"
                        />
                      <path class="st2" d="M397.3,558.4c1.3-2.1,1.7-4.7,1-7.1c1.2-2.3,0.2-5.1-2.1-6.3c-6-4.2-27.9-5.3-27.6,6.3c0,0.4,0.1,0.7,0.1,1.1
                        c-0.8,0.7-1.2,1.8-1.1,2.9c0.2,3.7,1.6,4.6,4.7,6.3c2.9,1.9,6.2,3.3,9.4,4.2C388,567.1,393.9,563.7,397.3,558.4z"/>
                      <path class="st2" d="M668.8,557.4c-0.8-0.5-1.7-1-2.7-1.2c-1.7-0.5-3.5-0.6-5.3-0.2h-0.6c-1.2-0.1-2.3,0.5-2.9,1.5
                        c-1.7,1.1-3,2.8-3.6,4.7c-1.7,5.8,3,10.3,7.9,12.2c0.7,0.4,1.7,0.7,2.7,1c5.3,3.3,12.2,4.7,15.8,1.7c4.7-4,1.6-11-2.1-14.5
                        C675.4,560.1,672.3,558.4,668.8,557.4z M666.7,566.9l0.7,0.1c0.1,0.2,0.2,0.5,0.2,0.6c-0.6-0.1-1.1-0.4-1.6-0.7H666.7z"/>
                      <path class="st2" d="M616.3,503.6c-3.3,0.6-6.4,1.8-9.3,3.3c-3.7-1.3-8,0.7-9.2,4.6l0,0v0.1c-0.8,0.5-1.5,1.2-1.8,2.1
                        c-1.9,5.3-3.6,11.5-0.4,16.7c3.4,5.2,8.8,5.6,14.5,5.3c6.9-0.2,18.4-0.2,24.3-4.2c4.2-2.9,8.3-10.6,9.5-15.5
                        C647.6,501.8,624.9,502,616.3,503.6z"/>
                      <path class="st2" d="M744.7,483.6c2.8,0.6,5.8,0.2,8.2-1.2c0.8-0.5,1.3-1.2,1.6-2.2c1.3-2.5-0.7-6.6-4.4-5.6
                        c-2.3,0.6-4.7,0.6-6.9,0c-2.2-0.4-4.2,1-4.7,3.1c-0.1,0.6-0.1,1.3,0,1.9v0.2c0,2.2,1.7,3.9,3.9,3.9L744.7,483.6z"/>
                      <path class="st2" d="M781.1,494.3c1.3-1.8,1.7-4.1,0.8-6.2c-0.1-0.7-0.5-1.5-1.1-1.9l-0.5-0.4c-1.5-1.2-3.3-1.9-5.2-2.1
                        c-2.3-0.1-4.7,0.6-6.5,2.2c-1.9,1.8-2.3,4.7-1,7c1.2,1.9,3.3,3.1,5.7,3.3c0.2,0.1,0.5,0.2,0.7,0.4
                        C776.7,497.3,779.5,496.4,781.1,494.3z"/>
                      <path class="st2" d="M711.9,462.3c-2.9,0-5.2,2.4-5.2,5.3c0,2.2,1.7,3.9,3.9,3.9c0.4,0,0.8-0.1,1.2-0.2c0.5,0.2,1,0.2,1.5,0.2
                        c2.1,0,3.7-1.7,3.9-3.9C717.3,464.7,714.9,462.3,711.9,462.3z"/>
                      <path class="st2" d="M387.1,386.1c-3.1-2.7-7.6-3.4-11.5-1.9c-1.2,0.2-2.4,1-3.3,1.9c-0.7,0.6-1.3,1.3-1.8,2.1
                        c-0.8,1.6-0.6,3.5,0.6,4.7c3,3.1,7.7,3.5,11.2,1c1.5-0.4,2.8-1,4-1.7C388.3,391,389.1,387.8,387.1,386.1z"/>
                      <path class="st2" d="M186.9,399.4c-4.1-1.2-8.3-1.5-12.4-0.6c-2.2,0.5-4.2,1.1-6.3,2.1c-2.3,1.1-2.3,4.5-0.8,6
                        c0.2,0.2,0.5,0.5,0.7,0.7c0.2,1.8,1.3,3.5,2.8,4.5c3.1,1.9,6.6,0.7,9.4-1.1l0.4-0.2c3.4-0.8,6.5-2.8,8.6-5.7
                        C190.6,403.1,189.1,400,186.9,399.4z"/>
                      <path class="st2" d="M169,422.6c-1.5-0.7-3.3-1-4.8-0.5c-2.1-0.5-4.1,0.7-4.7,2.7c-0.2,2.4,1,4.7,3.1,5.8c2.2,0.7,4.5,0.5,6.4-0.6
                        c1.5-0.8,2.2-2.4,1.8-4C170.9,424.7,170.3,423.4,169,422.6z"/>
                      <path class="st2" d="M759.3,456.1c0.6,0.4,1.3,0.7,1.9,1c0.8,0.8,1.9,1.2,3,1c3.5,0.8,7.4,0.5,8.7-2.8c0.7-0.6,1.3-1.3,1.8-2.2
                        c1.1-1.8,0.4-4.2-1.5-5.3c-0.6-0.4-1.2-0.5-1.8-0.5c-3.7,0.1-7.5,0.6-11.2,1.6c-2.1,0.6-3.3,2.8-2.7,4.8
                        C757.8,454.8,758.5,455.6,759.3,456.1L759.3,456.1z"/>
                      <path class="st2" d="M190.8,413.2c-1.5,0.2-2.8,1.1-3.5,2.4c-1,0.7-1.5,1.8-1.5,2.9v0.8c-0.1,0.4-0.2,0.7-0.1,1.2
                        c0.1,1.5,1,2.9,2.3,3.5c1,0.8,2.2,1.3,3.4,1.3c3.4,0.1,5.4-3.1,5.3-6.2C196.7,416.1,194.3,412.7,190.8,413.2z"/>
                      <path class="st1" d="M310.2,490.4c-3.5,7.5-7,14.9-10.5,22.4c-0.6,1.1-0.4,2.5,0.7,3.3l26.8,21.8c2.4,1.9,4.8-2.2,2.5-4
                        l-25.1-20.3c3.1-6.8,6.4-13.7,9.7-20.4C315.5,490.2,311.5,487.7,310.2,490.4z"/>
                      <polygon class="st6" points="388,517.9 333.6,511.2 332.1,554.9 386.8,552.3 		"/>
                      
                        <rect x="304.7" y="526.8" transform="matrix(3.472481e-02 -0.9994 0.9994 3.472481e-02 -212.7887 845.2183)" class="st0" width="52.8" height="12"/>
                      <path class="st1" d="M753.2,511.4l-10.6-22.2c-0.5-1.2-1.8-1.8-3-1.5l-33.7,7c-3,0.6-1.5,5.1,1.5,4.5l31.5-6.5l9.7,20.3
                        C749.9,515.6,754.5,514.1,753.2,511.4z"/>
                      <polygon class="st7" points="682.7,554.4 722.2,516.4 689.3,487.7 656.6,531.7 		"/>
                      
                        <rect x="701.1" y="474.5" transform="matrix(0.6583 -0.7528 0.7528 0.6583 -135.3942 703.4628)" class="st2" width="12" height="52.8"/>
                      <path class="st8" d="M248.2,475.8c-20.1-4.5-56.1,1.8-70.7,5.3c-14.6,3.5-31.1,0-29.9,8.9c1.3,8.9,28.9,8.9,28.9,8.9
                        s4.8-0.8,5.3,1.8c0.5,2.7,2.7,24.4,3.1,30.7c0.5,6.2-1.8,12.4-7.1,13.3s-28,2.7-27.2,9.8c0.8,7.1,19.6,6.2,27.2,4
                        c7.6-2.2,52.5-7.5,60.4-8.9c8-1.3,27.6-8.5,29.9-18.7c2.2-10.3,7.1-16.9,7.1-31.2C275.3,485.5,268.1,480.3,248.2,475.8z
                        M178.6,492.7c-0.8,1.8-16.9,0.5-20.2,0c-1.7-0.2-2.9-1.8-2.7-3.5l0,0V489c1.3-4.2,21.4-2.4,22.7-2.1
                        C179.8,487.3,179.4,490.9,178.6,492.7z M178.8,554.6c-3.7,1.8-15.6,2.7-18.3,2.4c-2.7-0.2-1.3-3.6-1.3-3.6
                        c4.2-5.1,24.4-5.3,25.4-2.9C185.5,553,182.6,552.8,178.8,554.6L178.8,554.6z"/>
                      <g class="st9">
                        
                          <ellipse transform="matrix(0.8944 -0.4473 0.4473 0.8944 -194.5636 277.2421)" class="st0" cx="489.8" cy="550.6" rx="17.2" ry="23.7"/>
                        
                          <ellipse transform="matrix(0.8944 -0.4473 0.4473 0.8944 -187.5592 285.6327)" class="st0" cx="511.1" cy="540" rx="17.2" ry="23.7"/>
                        
                          <ellipse transform="matrix(0.8944 -0.4473 0.4473 0.8944 -205.7077 263.8899)" class="st0" cx="456" cy="567.6" rx="17.2" ry="23.7"/>
                        <path class="st0" d="M482.8,580.6c-8.5,4.2-20.1-1.8-26-13.5s-3.7-24.7,4.7-28.9s20.1,1.8,26,13.5
                          C493.4,563.4,491.4,576.4,482.8,580.6z"/>
                      </g>
                      <path class="st3" d="M522.4,526.3l5-2.5c1.7-0.8,3.7-0.1,4.6,1.5l3.4,6.8c0.8,1.7,0.1,3.7-1.5,4.6l-5,2.5
                        c-1.7,0.8-3.7,0.1-4.6-1.5l-3.4-6.8C520.1,529.2,520.7,527.2,522.4,526.3z"/>
                      <path class="st10" d="M583.2,551.8l2.8-7.3c0.8-2.2,3.3-3.3,5.4-2.4l0.6,0.2c2.2,0.8,3.3,3.3,2.4,5.4l-2.8,7.3
                        c-0.8,2.2-3.3,3.3-5.4,2.4l-0.6-0.2C583.5,556.4,582.4,554,583.2,551.8z"/>
                      <path class="st10" d="M589,555.1c13.5,5.2,21.8,11.5,23.2,15.5c1.1,2.4,3,4.4,5.6,5.4c0,0,25.4,8.9,34,11.4c-0.2-1.2-1-2.3-1.9-3
                        c-4.4-4.2-1-5.7,2.4-5.3s4.8-5,0.1-7.5c-4.7-2.5,0.8-3.6,3.4-4.1c2.7-0.5,0.7-2.3-1.2-4c-1.5-1.2,3.7,0.1,5.6-0.6
                        c-8.7-4.2-31.9-13.9-31.9-13.9c-2.5-0.8-5.3-0.7-7.7,0.4c-3.7,1.9-14.9,1.8-28.3-3.4"/>
                      <path class="st11" d="M520.4,595.7c-5,0-5,7.7,0,7.7C525.3,603.5,525.3,595.7,520.4,595.7z"/>
                      <path class="st11" d="M515.2,586.5c-0.6-2.1-2.7-3.3-4.7-2.7l-1.6,0.5c-2.1,0.6-3.3,2.7-2.7,4.7c0.5,1.7,2.1,2.8,3.7,2.8
                        c1.7,0.6,3.6-0.1,4.6-1.7c0.1-0.2,0.2-0.4,0.2-0.6C515.4,588.7,515.5,587.6,515.2,586.5z"/>
                      <path class="st11" d="M557.2,595.7c-5,0-5,7.7,0,7.7C562.2,603.3,562.2,595.7,557.2,595.7z"/>
                      <path class="st11" d="M541.6,590c1.2-1.2,1.2-3.1,0-4.4l-0.5-0.5c-1.2-1.1-3.3-1.1-4.4,0.2c-1.1,1.2-1.1,3,0,4.1l0.4,0.5
                        C538.6,591.2,540.4,591.2,541.6,590z"/>
                      <path class="st11" d="M540.7,613.2c-0.4,0.1-0.6,0.1-0.8,0.4c-1.7,1-2.3,3-1.3,4.7c1,1.6,3,2.2,4.7,1.2c0,0,0,0,0.1,0l0.4-0.2
                        c1.5-1.1,1.9-3.1,1-4.7C543.7,613.2,542,612.8,540.7,613.2z"/>
                      <path class="st11" d="M497.3,606.2c-0.1,0.1-0.2,0.4-0.5,0.5c-1,1.5-0.5,3.4,1,4.4c1.1,0.6,2.3,0.6,3.4,0l0.5-0.5
                        c1-1.5,0.5-3.4-1-4.4C499.6,605.6,498.3,605.6,497.3,606.2z"/>
                      <path class="st11" d="M592.4,575l-1.3-1.2c-1.3-1.1-3.4-0.8-4.5,0.6c-0.7,0.8-0.8,2.1-0.4,3.1c0.6,1.1,1.5,2.1,2.4,2.8
                        c1.5,0.8,3.3,0.4,4.2-1.1c0.4-0.6,0.5-1.2,0.4-1.9C593.3,576.5,593,575.7,592.4,575z"/>
                      <path class="st11" d="M615.1,594.3c-4,0-4,6.2,0,6.2S619.1,594.3,615.1,594.3z"/>
                      <path class="st11" d="M656,477.7l-0.8-1.2c-0.6-1.1-1.8-1.7-3-1.6c-1.7-0.1-3.3,1.2-3.4,2.9c-0.1,1.1,0.4,2.2,1.3,2.8l1.8,1.2
                        c1.2,0.7,2.8,0.5,3.7-0.5C656.5,480.4,656.6,479,656,477.7z"/>
                      <path class="st11" d="M628.9,451.6c-0.1-0.2-0.2-0.6-0.5-0.8c-1.2-1.3-3.1-1.3-4.5-0.2c-1,0.8-1.3,2.2-0.8,3.4l0.5,0.8
                        c0.8,1.5,2.8,1.9,4.2,1.1C629.3,455,629.8,453.1,628.9,451.6z"/>
                      <path class="st11" d="M631.9,466.5c-4,0-4,6.2,0,6.2S635.9,466.5,631.9,466.5z"/>
                      <path class="st11" d="M289.6,533.2h-0.1c-0.2,0-0.5,0-0.7,0c-0.6,0-1.1,0.1-1.6,0.4c-0.7,0.4-1.2,1.1-1.5,1.8
                        c-0.1,1,0,1.9,0.5,2.7c0.1,0.2,0.2,0.4,0.4,0.5c1,1.2,2.7,1.6,4,0.8l1.5-0.7c1.3-1.1,1.6-3,0.6-4.5c-0.7-0.8-1.8-1.3-2.9-1.2
                        L289.6,533.2L289.6,533.2z"/>
                      <path class="st11" d="M288.2,524.8h0.8c1.7-0.2,2.9-1.9,2.7-3.6c-0.2-1.2-1.1-2.2-2.2-2.5h-1.3c-1.7-0.1-3.1,1.2-3.3,2.9
                        s1.2,3.1,2.9,3.3C287.9,524.8,288.1,524.8,288.2,524.8L288.2,524.8z"/>
                      <path class="st11" d="M313.1,535.5c-4,0-4,6.2,0,6.2C317.1,541.7,317.1,535.5,313.1,535.5z"/>
                      <path class="st11" d="M385.2,485.8c-0.6,0.2-1.1,0.6-1.5,1c-1.6,1.1-1.9,3.4-0.8,5l0,0v0.1c0.8,1.1,2.2,1.6,3.5,1.2
                        c0.6-0.1,1.1-0.4,1.6-0.5c1.6-0.5,2.5-2.2,2.2-3.7c-0.5-2.2-2.7-3.4-4.7-2.9L385.2,485.8z"/>
                      <path class="st11" d="M382.9,476.3c-4,0-4,6.2,0,6.2c1.7-0.4,2.8-2.1,2.3-3.7C385,477.5,384,476.7,382.9,476.3z"/>
                      <path class="st11" d="M359.4,487.9l-0.8,0.8c-1,1.5-0.5,3.4,1,4.4c1.1,0.6,2.3,0.6,3.4,0l0.8-0.8c1.2-1.2,1.2-3.1,0-4.4
                        C362.5,486.7,360.6,486.7,359.4,487.9L359.4,487.9z"/>
                      <path class="st11" d="M411.6,497C411.7,496.8,411.7,496.8,411.6,497c-0.6-2.2-2.7-3.4-4.7-2.9c-1,0.2-1.7,1-1.9,1.8
                        c-0.4,0.8-0.4,1.9,0.1,2.8c0.6,1.2,1.5,2.3,2.4,3.1c1.3,1.1,3.4,0.8,4.5-0.6c0.7-0.8,0.8-2.1,0.4-3.1L411.6,497z"/>
                      <path class="st11" d="M391.5,500c-0.5,0.5-0.8,1.2-0.8,1.9c-0.1,0.7,0,1.5,0.4,2.1c1,1.7,3,2.2,4.7,1.3c1.7-1,2.2-3,1.3-4.7
                        c-1-1.6-3-2.2-4.6-1.3C392.1,499.5,391.8,499.7,391.5,500z"/>
                      <path class="st11" d="M400.2,477.3c-4,0-4,6.2,0,6.2C404.2,483.4,404.2,477.3,400.2,477.3z"/>
                      <path class="st11" d="M426.6,481.1c-1-1-2.3-1.3-3.6-1c-1.5,0.4-2.4,1.6-2.7,3c-0.1,0.5,0,1.1,0.1,1.6c0,0.2,0.1,0.5,0.2,0.7
                        c0.1,0.2,0.2,0.4,0.5,0.5c1.2,1.5,3.1,1.7,4.7,0.7c1.1-0.7,1.7-1.9,1.6-3.1C427.7,482.7,427.3,481.7,426.6,481.1z"/>
                      <path class="st11" d="M203.5,451.5v0.4c0,4,6.2,4,6.2,0v-0.4c-0.4-1.7-2.1-2.8-3.7-2.4C204.7,449.3,203.8,450.3,203.5,451.5z"/>
                      <path class="st11" d="M207.9,439.4c-4,0-4,6.2,0,6.2S211.9,439.4,207.9,439.4z"/>
                      <path class="st11" d="M180.6,442.5c-0.7,0.2-1.5,0.7-1.8,1.5c-0.4,0.7-0.5,1.6-0.2,2.4c0.2,0.8,0.8,1.5,1.6,1.9
                        c0.4,0.6,1,1.1,1.7,1.3c1.7,0.4,3.3-0.5,3.7-2.2C186.4,444.6,183.4,441.2,180.6,442.5z"/>
                      <path class="st11" d="M222.1,449c-1.1-1.2-3-1.3-4.4-0.2c-1.2,1.1-1.3,3-0.2,4.4c0,0,0,0,0.1,0.1l0.2,0.2l0.6,0.6
                        c1.2,1.2,3.1,1.2,4.4,0c1.2-1.2,1.2-3.1,0-4.4L222.1,449z"/>
                      <path class="st11" d="M223.6,368.5L223.6,368.5c-2.2-1.2-4.8-0.4-6,1.8l0,0c-0.4,0.6-0.5,1.3-0.5,1.9c-0.2,2.5,1.7,4.8,4.2,5.1
                        h0.7c1.5-0.2,2.7-1,3.5-2.1c1-1.2,1.3-2.9,1-4.5C226.3,369.5,225.1,368.5,223.6,368.5z M220.7,371.7c0,0.2,0,0.4,0,0.6
                        C220.6,372.2,220.6,372,220.7,371.7z"/>
                      <path class="st11" d="M203.9,373.4c-4,0-4,6.2,0,6.2C207.9,379.5,207.9,373.4,203.9,373.4z"/>
                      <path class="st11" d="M214.9,357.1c0.4-1.7-0.7-3.4-2.4-3.7c-1.7-0.4-3.4,0.7-3.7,2.4c-0.1,0.5-0.1,1,0,1.5v1.3
                        c0.4,1.7,2.1,2.8,3.7,2.4c1.2-0.2,2.1-1.2,2.4-2.4V357.1z"/>
                      <path class="st11" d="M276.6,428.1c-1.7,0-3,1.5-3,3v0.6c0.2,1.7,1.9,2.9,3.6,2.7c1.2-0.2,2.2-1.1,2.5-2.2c0-0.1,0-0.2,0-0.5v-0.6
                        C279.6,429.5,278.3,428.2,276.6,428.1z"/>
                      <path class="st11" d="M288.4,422.8c-1.7,0-3,1.5-3,3v0.6c0.2,1.7,1.9,2.9,3.6,2.7c1.2-0.2,2.2-1.1,2.5-2.2c0-0.1,0-0.2,0-0.5v-0.6
                        C291.5,424.2,290.1,422.8,288.4,422.8z"/>
                      <path class="st11" d="M278,415.4c-4,0-4,6.2,0,6.2C282,421.5,281.9,415.4,278,415.4z"/>
                      <path class="st11" d="M222,467c-0.8-1.5-2.8-1.9-4.2-1.1c-1.3,0.7-2.3,2.1-2.7,3.6c-0.4,1.3,0.2,2.8,1.5,3.5
                        c1.9,1.2,4.5,0.5,5.7-1.5c0.7-1.2,0.7-2.7,0.1-3.9C222.2,467.5,222.1,467.2,222,467z"/>
                      <path class="st11" d="M266.1,499.6c-0.2-0.6-0.6-1.2-1.1-1.6c-0.1-0.7-0.4-1.3-0.8-1.8c-1.2-1.2-3.1-1.2-4.4,0
                        c-1.7,1.7-1.7,4.4,0,6c1.1,1.1,2.7,1.5,4.1,1.1C265.5,502.9,266.4,501.3,266.1,499.6z"/>
                      <path class="st11" d="M287.1,494.5v-1.3c-0.2-1.7-1.9-2.9-3.6-2.7c-1.2,0.2-2.2,1.1-2.5,2.2c0,0.1,0,0.2,0,0.5v1.3
                        c0.2,1.7,1.9,2.9,3.6,2.7c1.2-0.2,2.2-1.1,2.5-2.2C287.1,494.8,287.1,494.7,287.1,494.5z"/>
                      <path class="st11" d="M268,514.1c-4,0-4,6.2,0,6.2C271.9,520.3,271.9,514.1,268,514.1z"/>
                      <path class="st11" d="M242.3,564.2v0.5c0,4,6.2,4,6.2,0v-0.5C248.3,560.2,242.3,560.2,242.3,564.2z"/>
                      <path class="st11" d="M260.6,558.2c-1.7,0-3.1,1.3-3.1,3v0.7c0.1,1.7,1.6,3,3.3,2.9c1.6-0.1,2.8-1.3,2.9-2.9v-0.7
                        C263.5,559.6,262.3,558.2,260.6,558.2z"/>
                      <path class="st11" d="M249.6,550.6c-1.8,0-3.3,1.5-3.4,3.4c0,1.7,1.5,3,3.1,3h0.5c1.7,0,3.1-1.3,3.1-3
                        C253.1,552.1,251.6,550.5,249.6,550.6L249.6,550.6L249.6,550.6z"/>
                      <path class="st11" d="M616.6,465.5c-1.7-0.1-3.3,1.3-3.3,3c-0.1,1.5,0.8,2.8,2.3,3.1c0.6,0.1,1.3,0.1,1.9,0
                        c1.7-0.5,2.7-2.3,2.2-3.9C619.4,466.4,618,465.4,616.6,465.5z"/>
                      <path class="st11" d="M627.2,485.1c-0.5-0.7-1.5-1.2-2.4-1.2c-1.2,0-2.4,0.7-3,1.8c-0.7,1.3-0.5,2.9,0.5,4c1.3,1.3,3.6,1.3,5-0.1
                        l0,0c0.4-0.4,0.7-1,0.8-1.5C628.4,487.2,628.1,486,627.2,485.1z"/>
                      <path class="st11" d="M649.3,458.5c-4,0-4,6.2,0,6.2S653.3,458.5,649.3,458.5z"/>
                    </g>
                  </a>
                  <a className="cursor-default truck">
                    <g>
                      <path class="st12" d="M374.7,198.2c0,0,20.9,38.2,34,54.3c13.1,16.2,43,44.7,48.3,48.7c5.3,4.1,6.4,4.2,7.1,3.1s-6.7-8.2-6.7-8.2
                        s7.6,6.7,9.1,5.6c1.4-1.1-6.9-8.8-6.9-8.8s8.5,7.7,9.1,5.8c0.6-1.9-7-8.5-7-8.5s8.9,7.1,9.6,5.8c0.7-1.3-9.6-9.8-9.6-9.8
                        s6.4,4.4,6.5,2.7s-10.5-7-18.6-16.2s-32.9-44.1-43.6-63S374.7,198.2,374.7,198.2z"/>
                      <path class="st13" d="M372.2,205.7c0.2,0.4,14.4,22.6,14.4,22.6s3.1,5.6,4.2,5.4c1.1-0.2,15.8-7.8,20.5-12s5.4-6.5,2.6-10.4
                        c-9.1-12.6-22.3-35.8-26.8-38.4C382.7,170.3,360.3,183.1,372.2,205.7z"/>
                      <path class="st14" d="M445.4,310c0,0,0-11.6,4.2-14.8c4.2-3.1,8.4-17.4,18.8,9.2c10.9,28.1,18,25.8,29.6,31.5s29,8.3,32.9,13.2
                        c3.9,4.9,7.7,9.8,4.8,16.6c-3,6.8-19.1,10.3-21.3,7.9s-22.6,2.8-32.2,3.1c-9.6,0.4-36.8-26.7-37.2-32.2
                        C444.7,339.1,444.5,312.6,445.4,310z"/>
                      <path class="st0" d="M454,329.7c0.2-0.5,0.4-1,0.5-1.6V328c0.3-1.3,0.2-2.7-0.4-3.9c-0.2-0.4-0.5-0.8-0.9-1
                        c-0.3-0.3-0.7-0.6-1.1-0.7c-0.2-0.2-0.4-0.4-0.7-0.5c-2-1.1-4.4-0.5-5.8,1.3c-1.2,1.8-1.3,4.1-0.2,6c0.2,0.3,0.4,0.6,0.6,0.9
                        c0.4,0.9,1.1,1.7,2,2.2C450.4,333.2,453,332.1,454,329.7L454,329.7z M448.4,326.8l0.1,0.2l0,0L448.4,326.8z"/>
                      <path class="st0" d="M457.1,328.5c1.7,0.4,3.4-0.5,3.9-2.1c0.2-0.5,0.4-1,0.4-1.5v-0.7c0-1.8-1.5-3.2-3.2-3.1
                        c-1.4,0-2.6,0.9-3,2.3l-0.3,1.3C454.4,326.3,455.4,328,457.1,328.5z"/>
                      <path class="st0" d="M460.9,337L460.9,337c-0.9-1.9-2.8-3-4.8-2.8h-0.2c-0.5,0-0.9,0-1.4,0c-1.3,0-2.5,0.5-3.5,1.3
                        c-1.3,1.4-1.4,3.5-0.3,5c0.7,1.1,1.7,2,2.9,2.5c1.5,1.4,3.7,1.8,5.5,0.8c2.3-1.3,3.2-4.2,2-6.5C461,337.3,460.9,337.2,460.9,337z"
                        />
                      <path class="st2" d="M497,352.6c0-1.7-1.4-3.2-3.2-3.2c-0.4,0-0.8,0.1-1.1,0.2c-0.6-0.1-1.2-0.1-1.8,0c-1.9,0.5-3.3,2.1-3.5,4
                        c-1,0-1.9,0.4-2.7,1l-0.5,0.1c-3.2,1-3.8,4.3-3.2,7.1c0.6,2.9,3.1,5.4,6.2,4.8c0.8-0.2,1.6-0.6,2.2-1.1c0.2,0,0.4-0.1,0.6-0.1
                        c1.7-0.6,2.9-2.2,2.8-4c0-0.7-0.1-1.5-0.3-2.2c0.4,0,0.7-0.1,1-0.2c1.2-0.4,2.3-1.3,2.9-2.4c0.2-0.3,0.3-0.7,0.4-1.1
                        c0.1-0.3,0.2-0.6,0.2-0.9V352.6L497,352.6z"/>
                      <path class="st2" d="M488.5,329.7c-1.5,1.9-2,4.3-1.3,6.6c0.5,1.8,1.9,3.2,3.7,3.7c1,0.8,2.4,0.9,3.5,0.3c0.5-0.3,0.9-0.7,1.3-1.2
                        c0.9-0.7,1.5-1.8,1.7-2.9v-0.1c0.9-2.5,0.3-5.3-1.6-7.2c-2.1-1.8-5.3-1.5-7.1,0.6l0,0L488.5,329.7z"/>
                      <path class="st2" d="M483.1,328.7c0.3-1.2,0.1-2.4-0.6-3.4c-0.9-1.5-2.8-2-4.4-1.1c-0.8,0.4-1.5,0.9-2.2,1.4
                        c-4.3,1.3-8.1,5.8-4.4,9.5c0.5,0.6,1.2,0.9,2,1.1c0.4,0.7,1,1.2,1.6,1.6C480,341,484.6,333.5,483.1,328.7z"/>
                      <path class="st2" d="M506,343.4v-0.1c-0.4-3.1-2.2-5.5-5.9-4.3c-1.1,0.4-2.1,1.2-2.6,2.3c-0.2,0.2-0.3,0.4-0.4,0.6
                        c-1.6,2.5-1.8,5.6-0.6,8.2c0.7,1.3,1.9,2.2,3.3,2.5c0.4,0.3,0.9,0.5,1.3,0.7c1.7,0.5,3.4-0.5,3.9-2.2c0.1-0.5,0.1-1,0-1.5
                        C505.9,347.6,506.3,345.4,506,343.4z"/>
                      <path class="st2" d="M483.3,321.1c0,1.3,0.8,2.4,1.9,2.9l0.1,0.1c0.9,0.9,2.3,1.2,3.5,0.7s1.9-1.6,2-2.9c0.1-1-0.2-2-0.8-2.9
                        c-0.5-1.4-1.6-2.5-3-2.9c-1.1-0.3-2.3,0-3.1,0.8c-0.8,0.8-1.1,2-0.8,3.1C483,320.4,483.1,320.8,483.3,321.1z"/>
                      <path class="st2" d="M531.3,356.8c-4.6-4.4-12.5,1.6-11.3,7.2c0.6,2.5,3.1,4.1,5.7,3.6c0.2,0,0.4-0.1,0.6-0.2
                        c2.4,0.1,4.7-1.1,6-3.1C533.7,361.9,533.3,358.8,531.3,356.8z"/>
                      <path class="st15" d="M465.4,307.4l0.1-0.5c0.4-1.9,2.2-3.1,4-2.7l6.4,1.2c1.9,0.4,3.1,2.2,2.7,4l-0.1,0.5c-0.4,1.9-2.2,3.1-4,2.7
                        l-6.4-1.2C466.2,311,465,309.2,465.4,307.4z"/>
                      <path class="st15" d="M467.8,309.3c-2.3,11.8-6.2,19.4-9.1,21.1c-1.8,1.2-3.1,3.1-3.6,5.2c0,0-3.6,22-4.2,29.3
                        c1-0.4,1.7-1.1,2.2-2c2.8-4.2,4.5-1.6,4.7,1.2c0.2,2.8,4.8,3.2,6.2-1s3,0.1,3.8,2.2s2,0.3,3.1-1.5c0.8-1.4,0.4,3.1,1.3,4.5
                        c2.2-7.7,6.6-28,6.6-28c0.4-2.2-0.2-4.4-1.4-6.2c-2.1-2.7-3.6-11.8-1.3-23.5"/>
                      <path class="st1" d="M537,328.8l-9.8-4.9c-0.5-0.3-1.1-0.2-1.5,0.3l-9.9,11.6c-0.9,1,0.9,2.2,1.8,1.2l9.3-10.9l8.9,4.5
                        C537,331.1,538.2,329.4,537,328.8z"/>
                      <polygon class="st7" points="514.2,369.8 528,341.8 504.9,332.8 495.9,362.6 		"/>
                      
                        <rect x="513.5" y="321.5" transform="matrix(0.3642 -0.9313 0.9313 0.3642 15.2685 695.3511)" class="st2" width="6.8" height="30"/>
                      <g class="st9">
                        <path class="st0" d="M515.9,365.2c3.6,7-1.3,16.7-10.9,21.6c-9.5,5-20.3,3.4-23.9-3.6s1.2-16.7,10.9-21.6
                          C501.6,356.6,512.3,358.2,515.9,365.2z"/>
                        
                          <ellipse transform="matrix(0.8878 -0.4602 0.4602 0.8878 -109.2546 265.2398)" class="st0" cx="489.4" cy="356.7" rx="19.6" ry="14.2"/>
                        <path class="st0" d="M530.3,392.9c3.6,7-1.3,16.6-10.9,21.6c-9.5,5-20.3,3.4-23.9-3.6s1.3-16.7,10.9-21.6
                          C516,384.4,526.6,386,530.3,392.9z"/>
                        
                          <ellipse transform="matrix(0.8878 -0.4602 0.4602 0.8878 -122.0342 276.3953)" class="st0" cx="505.9" cy="388.5" rx="19.6" ry="14.2"/>
                      </g>
                      <path class="st3" d="M477,339.5l5.6-2.9c1.4-0.7,3.1-0.2,3.8,1.2l2.1,4.1c0.7,1.4,0.2,3.1-1.2,3.8l-5.6,2.9
                        c-1.4,0.7-3.1,0.2-3.8-1.2l-2.1-4.1C475.1,341.9,475.6,340.2,477,339.5z"/>
                      <path class="st10" d="M450.5,303.4c0,0-12.1,34.8,11.3,56s46.1,6.1,53,6.9c6.9,0.8,10.2,5.5,15.2,1.7c5-3.9,5.5-5.8,5.8-10.2
                        c0.3-4.4,4.4,10.2,0.6,14.9c-3.9,4.7-7.2,2.5-5.8,6.1s16.7,28.2,12.6,48c-4.1,19.9-8.4,24.7-23.3,28c-14.9,3.3-29.3-2.6-36.6-2
                        c-7.2,0.6-22.9,5.2-38.1,6.6s-41.1-2.8-48.8-22.1c-7.7-19.3,1.4-46.9,11-60.4c9.7-13.5,23.7-32.8,25.9-45.3
                        c2.2-12.4,12.1-29,12.4-29.8C446,300.9,450.5,303.4,450.5,303.4z"/>
                      <polygon class="st2" points="472.3,399.1 487.8,389.7 484.6,399.3 		"/>
                      <path class="st2" d="M462.1,400.8c0,0,3.5-10.1,7.3-10.5s7.2,6.9,7.2,6.9l8.7-5.4c0,0-6.4-10.9-13.9-11.3s-11.9,2-15.7,7.6
                        c-3.7,5.6-3.3,9-3.3,9L462.1,400.8z"/>
                      <path class="st2" d="M480.5,406.8l10.3-5.7c0,0,5.5,5.7,4.9,15.8s-8.2,12.8-10.1,13.4s-7.3-0.2-7.3-0.2l0.9-10.4
                        c0,0,4.4,2.2,5.7-1.2C486.3,415.3,483.7,408.9,480.5,406.8z"/>
                      <polygon class="st2" points="474.2,425.5 478.2,434.7 479.9,415.9 		"/>
                      <polygon class="st2" points="443.1,404.1 455.2,403.2 459.1,412.3 		"/>
                      <path class="st2" d="M446.9,405.2c-2.4,4.2-3.4,9.1-3.1,13.9c0.2,4.8,3.1,9.2,7.4,11.3c2.2,1.2,12.7,1.9,17,0.4l0.8-10.2
                        c0,0-10,4.7-13.8-1.3c-2.3-3.1-2-7.4,0.6-10.2L446.9,405.2z"/>
                      <polygon class="st16" points="342.6,421.6 348.8,392.3 364,392.5 357.7,424.7 		"/>
                      <polygon class="st1" points="339.7,428.8 341.6,415.7 361,415.7 360.4,428.5 		"/>
                      <polygon class="st7" points="363.4,425.4 336.8,425.5 333.6,451.6 382.2,465 365.2,441 		"/>
                      <path class="st2" d="M348.8,445.1c3.1-1.1,4.8-4.5,3.7-7.7c-1.1-3.1-4.5-4.8-7.7-3.7c-3.1,1.1-4.8,4.5-3.7,7.7l0,0l0,0
                        C342.2,444.6,345.7,446.2,348.8,445.1L348.8,445.1L348.8,445.1z"/>
                      <line class="st17" x1="368.9" y1="446.4" x2="363" y2="449.7"/>
                      <line class="st17" x1="371.4" y1="449.9" x2="364.8" y2="453.3"/>
                      <path class="st3" d="M342.7,408.7c0,0,9.1-59.5,3.9-76.9c-5.1-17.1-17.6-70.4-18.2-74.2c-0.6-3.9,8.3-22.6,16.3-21.5s19,11,19,11
                        l1.4,9.1c0,0,11.5,37.7,13.5,51.9c4.7,33.7-0.3,54.1-4.7,66.5S360.4,411,360.4,411L342.7,408.7z"/>
                      <polygon class="st0" points="337.1,415.1 337.7,406.7 366.4,408.9 365.5,418.3 		"/>
                      <polygon class="st16" points="272.7,399.8 297.1,371 310.5,378.9 284.4,409.8 		"/>
                      <path class="st3" d="M298.7,393.8c3.9-1.4,34.8-30,39.5-35.3c4.7-5.2,17.4-23.5,17.9-39.2c0.6-15.7-7.7-61.5-7.7-61.5
                        s0.8-13.8,0.8-18.2s-24-22.6-24-22.6s-20.4,17.9-19.9,38.1s19.1,65.1,0.1,98.3c-6.2,10.4-13.3,20.2-21.3,29.3L298.7,393.8z"/>
                      <path class="st17" d="M348.3,257.8c0,0,8.3,45.8,7.7,61.5c-0.5,15.7-13.2,34-17.9,39.2c-4.7,5.2-22.7,23.5-26.6,24.9"/>
                      <path class="st0" d="M274.7,387c0.4,0.3,24.6,17.9,24.6,17.9l6.3-8.3l-25.4-19.2L274.7,387z"/>
                      <polygon class="st1" points="271.9,396.8 279.7,388.9 294.3,400.1 286.8,409 		"/>
                      <polygon class="st7" points="291.8,410.1 270.8,393.8 252.2,412.4 282.2,452.9 283.6,423.5 		"/>
                      <path class="st2" d="M268.2,416.6c3.2,1,6.6-0.7,7.6-3.8c1-3.2-0.7-6.6-3.8-7.6c-3.2-1-6.6,0.7-7.6,3.8
                        C263.3,412.2,265,415.6,268.2,416.6L268.2,416.6z"/>
                      <line class="st17" x1="283.1" y1="430" x2="276.5" y2="429"/>
                      <line class="st17" x1="283" y1="434.3" x2="275.7" y2="432.9"/>
                      <polygon class="st7" points="431.1,163.3 423.4,171.3 429.5,172.3 		"/>
                      <path class="st18" d="M433.5,143.9l-7-6.1c-4.7-4-12.3-3-16.9,2.4l-9.4,10.9c-0.3,0.3-0.5,0.6-0.7,0.9c-0.4,0.3-0.7,0.6-1,0.9
                        l-5.7,6.6l-0.3,0.4l-5.7,6.7c-2.1,2.4-1.8,6.1,0.6,8.2l2.8,2.3c2.4,2.1,6.1,1.8,8.2-0.6l5.1-5.9l4.1,3.5c4.7,4,12.3,3,16.9-2.4
                        l9.4-10.9C438.2,155.6,438.2,148,433.5,143.9z"/>
                      <path class="st19" d="M394.2,140.2c2.4-3,6.8-3.5,9.8-1.1h0.1c1.2,1,2,2.5,2.2,4l-7.8,8.7c-1.4-0.1-2.8-0.6-3.9-1.6
                        C391.8,147.5,391.6,143.1,394.2,140.2z"/>
                      <path class="st20" d="M441.6,147.7c1.4-3.3,1.7-6.9,1-10.5l0,0c0,0-7.2-12.3-19.3-16c-12.7-3.9-24.8,1.8-30.9,12.8
                        c-3.4,6.1-3.6,19.7,1.9,23c0.8,0.5,4-3.3,5.5-4.4c2.7-2.2,5.2-4.6,7.6-7.2c0.8,0.2,1.7,0.5,2.6,0.7c3.1,0.9,8.5,1.7,12.7-2.1
                        c1-0.5,1.9-1.2,2.7-1.9c-0.1,2.3,0.3,4.6,1.1,6.8c0.7,2.8,1.9,5.5,3.6,7.8c0.5,0.6,0.9,1.2,1.3,1.6c0.7,1.1,1.5,2.3,2.4,3.3
                        C435.3,159.2,439.5,152.1,441.6,147.7z"/>
                      <path class="st20" d="M421,143.1c0,0,7,17.8,15.8,23.2s15.7-2.6,12.6-10.8c-3-8.1-5.1-10-5.1-10s3.7,8.2,7.2,4.8
                        c3.4-3.3-10.5-23.7-23.5-27.9C419.4,119.7,417.7,136.6,421,143.1z"/>
                      <path class="st18" d="M394.2,140.2c-2.7,3.1-2.3,7.8,0.7,10.4s7.8,2.3,10.4-0.7c2.7-3.1,2.3-7.7-0.7-10.4S396.9,137.1,394.2,140.2
                        L394.2,140.2z"/>
                      <path class="st20" d="M402.8,149c1.5-1.5,2-3.7,1.2-5.6c-0.5-0.9-1.2-1.7-2.1-2.3c-0.4-0.3-1-0.1-1.2,0.3l0,0
                        c-0.2,0.4-0.1,1,0.3,1.2c0.4,0.3,0.8,0.6,1.1,1l0.1,0.1c0,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3v0.1c0,0.2,0.1,0.3,0.1,0.5
                        v0.6l0,0c0,0.2-0.1,0.3-0.1,0.5s-0.1,0.3-0.2,0.4l0,0c0,0.1-0.1,0.1-0.1,0.2c-0.1,0.2-0.2,0.4-0.4,0.6l-0.1,0.1
                        c-0.1,0.1-0.1,0,0,0s-0.1,0.1-0.1,0.2c0,0.1-0.1,0.1-0.1,0.2c-0.3,0.4-0.3,1,0.1,1.3C402,149.3,402.5,149.3,402.8,149L402.8,149z"
                        />
                      <path class="st20" d="M419.9,156.4c-1,1.1-0.9,2.7,0.2,3.7s2.7,0.9,3.7-0.2c1-1.1,0.9-2.7-0.2-3.7l0,0c0,0,0,0,0-0.1
                        C422.5,155.2,420.8,155.3,419.9,156.4z"/>
                      <path class="st21" d="M408.6,158c-0.9,2.4,0.3,5,2.7,5.9c2.4,0.9,5-0.3,5.9-2.7s-0.3-5-2.7-5.9l0,0l0,0
                        C412.2,154.4,409.5,155.6,408.6,158L408.6,158z"/>
                      <path class="st20" d="M420.5,174.6l-0.2-0.2l-0.1-0.1c-0.1,0,0.1,0.1,0,0s-0.3-0.3-0.5-0.4c-0.2-0.1-0.3-0.3-0.4-0.5l0,0l-0.1-0.1
                        c0-0.1-0.1-0.2-0.2-0.3l-0.2-0.3l0,0l-0.1-0.2c-0.1-0.2-0.1-0.5-0.2-0.8l0,0v-0.2c0-0.1,0-0.3,0-0.4v-0.9c0.1-0.4-0.1-0.9-0.6-1
                        s-0.9,0.1-1,0.6c-0.3,1.3-0.2,2.7,0.5,3.9c0.5,0.9,1.3,1.6,2.1,2.2c0.4,0.2,0.9,0.1,1.1-0.3c0.2-0.4,0.1-0.9-0.3-1.1h0.2
                        L420.5,174.6L420.5,174.6z"/>
                      <path class="st20" d="M430.1,121.9c-7,6.6-18,6.3-24.7-0.6c-6.6-7-6.3-18,0.6-24.7c7-6.6,18-6.3,24.7,0.6l0,0
                        C437.4,104.3,437.1,115.3,430.1,121.9L430.1,121.9L430.1,121.9z"/>
                      <path class="st2" d="M381.5,167.9l0.8-0.8c1.9-1.9,4.9-2,6.9-0.1l7.9,7.7c1.9,1.9,2,4.9,0.1,6.9l-0.8,0.8c-1.9,1.9-4.9,2-6.9,0.1
                        l-7.9-7.7C379.7,172.9,379.7,169.8,381.5,167.9z"/>
                      <path class="st13" d="M389.3,173.4c-15.2-11.3-47.7,7.4-70.8,38.4c-2.2,3-4.3,6.1-6.2,9.1l54.4,40.2c2.3-2.6,4.6-5.5,6.8-8.4
                        C396.6,221.6,404.5,184.7,389.3,173.4z"/>
                      <line class="st17" x1="377.5" y1="247" x2="382.8" y2="238.9"/>
                      <path class="st18" d="M371.6,207.2c0,0,19.7,38.8,32.2,55.4s41.6,46,46.7,50.2c5.2,4.2,6.2,4.4,7,3.3c0.7-1.1-6.5-8.5-6.5-8.5
                        s7.4,7,8.9,5.9s-6.6-9-6.6-9s8.3,7.9,9,6.1s-6.6-8.6-6.6-8.6s8.6,7.4,9.4,6.1c0.7-1.3-9.1-10.1-9.1-10.1s6.3,4.6,6.4,2.9
                        c0.2-1.7-10.3-7.4-18-16.7c-7.7-9.4-31.5-45.1-41.6-64.4S371.6,207.2,371.6,207.2z"/>
                      <path class="st13" d="M369,214.7c0.2,0.4,13.7,23,13.7,23s2.9,5.7,4,5.5c1.1-0.1,16.6-8.7,21.4-12.7s3.9-5.8,2.5-9
                        s-19.2-38.1-23.6-40.9C382.5,177.9,357.7,191.6,369,214.7z"/>
                      <path class="st17" d="M397.4,196.4c5.1,12.6,14.2,27.8,14.3,29.7c0.2,2.6-7.4,7-7.4,7s-15.5,9.4-17.3,9.8s-11.9-16.2-14.6-22.3"/>
                      <path class="st17" d="M366,203.7c0,0-1.2-9.3,3-13.9"/>
                    </g>
                  </a>
                  <a className="cursor-default boy">
                    <g>
                      <g id="Layer_2_1_">
                        <g id="Layer_5">
                          <path class="st22" d="M611.5,239c-6.6,3.1-16,32-21.7,40.1c-0.9,1.3-1.8,2.4-2.9,3.6l25.9,24.6c0.1-0.1,0.1-0.1,0.2-0.2
                            c5.7-8.6,12.1-23.5,14.2-48.9S618.2,235.9,611.5,239z"/>
                          <path class="st12" d="M585.7,284c-5.9,5.8-15.1,10.2-31.9,10c-21.3-0.2-24.5-3.5-24.5-3.5c-2.6-0.5-10.9-3-11-0.7
                            c-0.2,2.2-2.3,13.1,1.8,14.7c8,2.8,16.3,4.7,24.7,5.7c9.7,1.2,58.6,5.2,67.4-2.2L585.7,284z"/>
                          <polygon class="st20" points="611.6,311.5 580.7,285.1 586,279.6 616.6,306.1 				"/>
                          <path class="st14" d="M512.5,265.2v-0.5c0.1-1.9,1.7-3.4,3.6-3.2l6.5,0.4c1.9,0.1,3.4,1.7,3.2,3.6v0.5c-0.1,1.9-1.7,3.4-3.6,3.2
                            l-6.5-0.4C513.8,268.8,512.4,267.1,512.5,265.2z"/>
                          <path class="st14" d="M507.1,318.5c2.2-4.5,4.2-2.2,4.8,0.5c0.5,2.8,5.2,2.6,6-1.8c0.8-4.4,3-0.3,4,1.7c1,1.9,2,0,2.8-1.9
                            c0.6-1.5,0.8,3,1.9,4.2c1.2-8,3-28.6,3-28.6c0.1-2.2-0.7-4.4-2.2-6c-2.4-2.4-4.1-6.2-3.4-18.2l-4.8-1.1v-0.2h-0.5l-0.5-0.1v0.2
                            l-4.3,0.5c-0.7,12-2.8,15.6-5.5,17.7c-1.7,1.4-2.7,3.5-2.9,5.7c0,0-0.7,22.2-0.4,29.6C506.1,320.2,506.7,319.4,507.1,318.5z"/>
                          <path class="st12" d="M530.8,296.9c0,0-9.6-4-9.4-6.8c0.2-2.8,9.7,0.5,11.4,1.8S535.5,300.1,530.8,296.9z"/>
                          <polygon class="st12" points="574.7,425.7 579,459.8 561.9,462.2 556.2,425.9 				"/>
                          <path class="st0" d="M533.9,488.1l49.3-10.8c0,0,0.7-0.5,0.7-3.4s-1-22.6-1-22.6l-25.6,3l-2.5,8.2c0,0-13.5,15.7-16.4,18.7
                            C535.7,484.2,531.7,488.1,533.9,488.1z"/>
                          <path class="st20" d="M583.8,473.2c0-1.2-0.2-5.1-0.4-9.4c-3.3,0.4-8.6,1.9-11.4,6.2c-1.7,3-2.2,6.5-1.6,9.8l12.8-2.7
                            C583.8,475.9,583.9,474.6,583.8,473.2z"/>
                          <path class="st20" d="M549.1,466.4l5.9,4.6l0.8,0.7c0.6,0.3,1.4,0.1,1.8-0.6c0.2-0.4,0.2-0.8,0-1.2l-5.9-4.7l-0.8-0.6
                            c-0.6-0.3-1.4-0.1-1.8,0.5C548.9,465.6,548.9,466,549.1,466.4z"/>
                          <path class="st20" d="M545.4,471.9l5.2,4.2l0.7,0.6c0.3,0.6,1.1,0.9,1.8,0.5c0.6-0.3,0.9-1.1,0.5-1.8c-0.1-0.2-0.3-0.4-0.5-0.5
                            l-5.2-4.2l-0.7-0.6c-0.6-0.3-1.4-0.1-1.8,0.5C545.2,471,545.2,471.5,545.4,471.9L545.4,471.9z"/>
                          <path class="st15" d="M557.7,438.6c0,0-10.9-48-12.6-64.9s1.6-32,21.5-41.2s56.9-23.4,61.3-21.3s3.1,17.7,3.1,23.4
                            c0,5.7-8.8,19.9-8.8,19.9l-10.5,2.4c0,0-23.5,8.3-29,11.9c-6.2,4.1-9,12-9.1,23.4c-0.2,11.4,3.3,44.8,3.3,44.8L557.7,438.6z"/>
                          <path class="st17" d="M601.6,360.3c5.5-2.1,11.1-3.8,16.9-5.3"/>
                          <polygon class="st2" points="552.6,437.8 554.5,447 583.8,444.2 582.7,434.2 				"/>
                          <polygon class="st12" points="681.7,411.3 714.3,402.2 719.3,416.6 684.3,427.4 				"/>
                          <path class="st15" d="M627.4,325.1c-5.8,17.6-10.1,35.7-12.9,54.1c-4,28.3-3.3,61.1,19.1,63.7c22.4,2.6,60.5-18.2,60.5-18.2
                            l-3.4-17c0,0-29.9,10.5-35,0.5c-5.1-9.9,8.1-44.9,11.8-56.7s10.7-29.4,8.1-37.2S628.5,322.5,627.4,325.1z"/>
                          <path class="st17" d="M624.9,332.4c0,0-7.5,23.7-9.4,41.9"/>
                          <polygon class="st2" points="688.6,403.3 693.8,430 701.5,427.6 697.7,401.5 				"/>
                          <path class="st0" d="M753.3,437.2l-23.9-44.4c0,0-0.7-0.5-3.5,0.2c-2.8,0.8-21.4,7.1-21.4,7.1l9.9,23.7l8.6,0.2
                            c0,0,18.8,8.7,22.5,10.6S753.8,439.4,753.3,437.2z"/>
                          <path class="st20" d="M725.3,393.3c-1.1,0.3-4.9,1.6-9,2.9c1.3,3,4.2,7.8,9.1,9.2c3.3,0.8,6.8,0.4,9.9-1.2l-6.1-11.5
                            C727.9,392.6,726.6,392.8,725.3,393.3z"/>
                          <path class="st20" d="M728.4,428.3l2.8-6.9l0.4-1c0.2-0.6-0.1-1.3-0.7-1.5c-0.6-0.2-1.3,0.1-1.5,0.7c0,0.1,0,0.1,0,0.2l-2.9,7
                            l-0.4,1c-0.4,0.6-0.2,1.4,0.4,1.8c0.6,0.4,1.4,0.2,1.8-0.4C728.4,428.9,728.4,428.6,728.4,428.3L728.4,428.3z"/>
                          <path class="st20" d="M734.5,430.6l2.6-6.1l0.4-0.9c0.2-0.7-0.2-1.4-0.9-1.6c-0.4-0.1-0.9,0-1.3,0.3l-2.6,6.1l-0.4,0.9
                            c-0.2,0.7,0.2,1.4,0.9,1.6C733.7,431.1,734.2,431,734.5,430.6z"/>
                        </g>
                        <g id="Layer_4">
                          <polygon class="st7" points="581.9,208.6 586.7,219.8 580,218.5 				"/>
                          <path class="st18" d="M587,187.9l9.5-3.5c6.3-2.3,13.7,1.7,16.3,8.9l5.4,14.8c0.2,0.4,0.3,0.9,0.4,1.3c0.3,0.4,0.5,0.8,0.7,1.3
                            l3.3,9l0.2,0.5l3.3,9c1.2,3.3-0.5,6.9-3.8,8.1l-3.8,1.3c-3.3,1.2-6.9-0.5-8.1-3.8l-2.9-8l-5.5,2c-6.3,2.3-13.7-1.7-16.3-8.9
                            l-5.4-14.8C577.6,198,580.7,190.3,587,187.9z"/>
                          <path class="st19" d="M628.5,199.3c-1.3-4-5.6-6.3-9.6-5l0,0h-0.1c-1.6,0.6-2.9,1.8-3.8,3.3l4.6,11.9c1.5,0.5,3.1,0.4,4.6-0.1
                            C628.1,207.8,630.1,203.3,628.5,199.3z"/>
                          <path class="st20" d="M606.2,168.8c-13.8-0.9-25.9,8.8-25.9,8.8s-7.8-6.8-13.1-1.7c-5.2,5.1,3.4,11.4,3.4,11.4
                            s-11.5-4.8-15.7,1.6c-4.2,6.3-2.5,14.9,3.8,19.2c0.9,0.6,1.8,1.1,2.8,1.4c6.9,2.8,15.2-1,21.9-6c0.6-0.3,1.2-0.7,2-1.2
                            c2.7-1.7,5-4,6.8-6.7c1.7-1.9,3-4.1,3.8-6.5c0.5,1.1,1.2,2.1,2,3c2.8,5.5,8.6,6.8,12.2,7c1,0.2,2,0.3,3,0.4
                            c1.4,3.5,3.1,7,5,10.3c1,1.7,2.9,6.8,3.9,6.6c6.9-1.2,12.1-15.2,10.9-22.8C630.8,180.3,620.7,169.8,606.2,168.8z"/>
                          <path class="st20" d="M614.8,172.6c0,0-4.6-11.2,0.5-11.4s2.9,7.4,2.9,7.4s6.2-3.7,5.3-0.5S615.3,174.2,614.8,172.6z"/>
                          <path class="st18" d="M628.5,199.3c1.6,4.2-0.6,8.8-4.8,10.4c-4.2,1.6-8.8-0.6-10.4-4.8c-1.6-4.2,0.6-8.8,4.8-10.4h0.1
                            C622.4,193,627,195.2,628.5,199.3L628.5,199.3L628.5,199.3z"/>
                          <path class="st20" d="M618,204.1C618.1,204.4,618,204.3,618,204.1l-0.1-0.3c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0-0.2-0.1-0.3l0,0v-0.2
                            c0-0.3,0-0.5,0-0.8v-0.2l0.1-0.4l0.1-0.4c0-0.1-0.1,0.2,0,0l0.1-0.2l0.2-0.4l0,0l0.1-0.1l0.2-0.2l0.2-0.2l0.1-0.1
                            c0.2-0.1-0.2,0.1,0,0l0.5-0.3l0.3-0.2h0.1c0.3-0.1,0.6-0.2,0.8-0.3c0.5-0.1,0.8-0.6,0.6-1.1c-0.1-0.5-0.6-0.8-1.1-0.6
                            c-1.8,0.4-3.4,1.6-4,3.4c-0.4,1.1-0.4,2.4,0,3.5c0.1,0.3,0.2,0.6,0.3,0.9c0.3,0.4,0.8,0.6,1.2,0.3
                            C618.1,205.1,618.2,204.6,618,204.1L618,204.1z"/>
                          <path class="st20" d="M596,205.9c0.5,1.5-0.2,3.1-1.7,3.6s-3.1-0.2-3.6-1.7s0.2-3.1,1.7-3.6l0,0l0,0
                            C593.9,203.7,595.5,204.5,596,205.9z"/>
                          <path class="st21" d="M606.9,211.9c0,2.8-2.2,5-5,5.1c-2.8,0-5-2.2-5.1-5c0-2.8,2.2-5,5-5.1l0,0
                            C604.7,206.9,606.9,209.1,606.9,211.9L606.9,211.9z"/>
                          <path class="st20" d="M588.7,225.9c2.4-0.7,4.4-2.4,5.3-4.7c0.2-0.4,0-0.9-0.4-1.1c-0.3-0.1-0.7,0-1,0.3c-0.3,0.6-0.6,1.2-1,1.8
                            l0,0l-0.1,0.1l-0.1,0.2l-0.3,0.3c-0.2,0.2-0.4,0.4-0.6,0.6l0,0l-0.1,0.1l-0.3,0.2l-0.6,0.3h-0.2c-0.1,0.1,0.2,0,0,0l-0.3,0.1
                            l-0.6,0.2c-0.4,0.1-0.7,0.6-0.6,1c0.1,0.4,0.6,0.7,1,0.6L588.7,225.9z"/>
                          <path class="st20" d="M612.5,232.9l12.3-4.1c2.5-0.8,5.3,0.5,6.1,3.1l0.7,2c0.8,2.5-0.5,5.3-3.1,6.1l-12.3,4.1
                            c-2.5,0.8-5.3-0.5-6.1-3.1l-0.7-2C608.6,236.5,610,233.8,612.5,232.9z"/>
                          <path class="st1" d="M604.8,329.9c18.1,4.2,51.3,4.1,75.5-8c-0.7-6.9-1.9-13.7-3.5-20.4c-9.9-41.7-33.8-71.8-53.4-67.1
                            c-19.5,4.7-22.9,41.1-18.6,83.8C605.3,323.6,604.6,325.8,604.8,329.9z"/>
                        </g>
                        <g id="Layer_3">
                          <path class="st18" d="M614,322.4l-25.2-18.7c-5.7,6.4-11.6,9.2-28.6,18.2c-21.8,11.6-24,16.8-27.6,23.5s-5.1,18.3-4.3,19.5
                            s2.6-0.3,4.6-9.9c0,0-2.1,10.6,0.2,11.6c1.3,0.6,2.6-3.8,3.3-7.5c-0.4,3.5-0.6,7.6,1.1,8c1.4,0.3,3.1-5.6,3.9-9.4
                            c-0.7,3.6-1.6,9.1-0.2,9.1c2.1,0,3.4-14.1,10.6-18.6s7.7-5,32.7-9.8C595.9,336.3,606.2,330.7,614,322.4z"/>
                          <path class="st22" d="M634,240.5c-3.7-1.9-17.1-9.4-25.4,12.1c-8.3,21.5-6.3,32.6-14.6,44.4c-1.4,2.1-3,4.1-4.6,6l25.2,18.8
                            c3.2-3.6,6.1-7.5,8.5-11.7c3.2-5.2,13.7-26.3,16.4-40.3C642.2,255.9,640.1,243.7,634,240.5z"/>
                          <polygon class="st20" points="586.1,303.4 615.4,327.8 620.6,320.9 588.9,299.7 				"/>
                          <path class="st17" d="M572.5,315.3c0,0,13.6-5.3,21-17.5c4.6-7.6,7.5-17.9,9.4-25.9"/>
                        </g>
                      </g>
                    </g>
                  </a>
                </svg>,
        ];
    }

    render() {
        return jsx.default.bind(this)();
    }

    componentDidMount() {
        this.getkeyFact();
        this.getResource();
        this.getFacilities();
      
    }



    getFacilities(){
        const dataToBeSend = {}
        AdminService.httpGet('/data-import/facility/getAll', dataToBeSend).then((res)=>{
            if(res){
                this.setState({loader:false})
                let d = res.data;

   
                this.setState({fecData: d})
                console.log(this.state.fecData)
            }

}).catch((err) => {
    this.setState({ loader: false })
    console.log(err)
});
    }
   

    getResource (){
        const dataToBeSend = {}
        AdminService.httpGet('/data-retrieval/resources/get-all-resources', dataToBeSend).then((res)=>{
            if(res){
                this.setState({loader:false})
                let d = res.data;

                console.log("sangeeta somra----------->",d)
   
                this.setState({resData: d, data:d})
           
            }

}).catch((err) => {
    this.setState({ loader: false })
    console.log(err)
});
    }


    getkeyFact(){
        
        const dataToBeSend = [
            {
                "subgroup": "Total",
                "unit": "Percent",
                "indicator": "Waste generation in urban areas"
            },
            {
                "subgroup": "Total",
                "unit": "Tonne/s",
                "indicator": "Total annual waste"
            },
            {
                "subgroup": "Total",
                "unit": "Number",
                "indicator": "Illegal dumpsites"
            },
            {
                "subgroup": "Total",
                "unit": "Gg",
                "indicator": "Biological treatment/composting"
            }
        ]
        let obj = { 'selectedIUS': dataToBeSend, 'areacode': 'SWZ' }

        AdminService.httpUserPost('/data-retrieval/data/get-selected-keyfact', obj).then((res)=>{
            if(res){
                this.setState({loader:false})
                // console.log("res.data:: ", res.data)
                let d = res.data;
                this.setState({keyData: d})
           
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });

    }



}


export default connect(
   
  )(HomePage);