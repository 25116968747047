import React, { useEffect, useRef, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import TextField from '@mui/material/TextField';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-bootstrap/Dropdown';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs from 'dayjs';
import Button from '@mui/material/Button';
import Slider from '@mui/material/Slider';
import adminService from "../../../services/admin.service";
import * as $ from 'jquery';
import CheckboxTree from 'react-checkbox-tree';

// ====================================================== Treeview Checkbox Start ==================================================

var permChe= false;
var checkName = '';
var checkCap = '';
var checkFac = false;
var dataArr = [];
var Checkdatefrom = '';
var CheckdateTo = '';
const datass = [{
    value: 'mars',
    label: 'Mars',
    children: [
      { value: 'phobos', label: 'Phobos' },
      { value: 'deimos', label: 'Deimos' },
    ],
  }];
// ======================================================= Treeview Checkbox End ===================================================
export default function FilterForm(props) {
    const form = useRef()

 

    // ===================================================================== Area Dropdown Start
    const [areaChecked ,setAreaChecked] = useState([])
    const [State, setState] = useState('');
    const [permT, setPerm] = useState(false)
    const [flatTree, setFlatTree] = useState([]);
    const [wasteTree, setWasteTeree] = useState([]);
    const [diss, setDissable] = useState(true);
    const [showList, setAreaListShow] = useState(false);
    const [showDrop, setParentList] = useState(false);
    const [filAreas, setFilArea] = useState([])
    const [callData, setParentAreaData] = useState([]);
    const[mainAreaData, setAreaData]=useState([])
    const[facilityData, setFecData]=useState([])
    const [FormState, setFormState] = useState({
        id: null,
    
        areaList:[],
        wasteData: [],
        facList:[],
  


        facName: '',
        permType: '',
        facType: [],
        wasteType: [],
        dateFrom: null,
        dateTo: null,
        areaSqf: '',
        capacity: ''


    });
    useEffect(() => {

        console.log('wflat', props.masterData )
        if (props.masterData != "" && props.masterData != null) {
        
            let arrayData = [];
            let arrayData2 = []
            let arrayData3 = []
            let facsData = []
            let flatLevelw = [];
        
            props.masterData['Facility Type']?.forEach((item) => {

                var obj = {
                    value: item.value,
                    label: item.label,
                  
                    
                  }

                  facsData.push(obj)


            })
        
            props.masterData['Waste Type']?.forEach((item) => {

                
              
                var obj = {
                    value: item.value,
                    label: item.label,
                    parent: item.parent,
                    level:item.level
                    
                  }

                flatLevelw.push(obj)


            })

            console.log('arrayData3', facsData)

            const data = setLevelDataw(flatLevelw)
            setWasteTeree(data)

            
            setFlatTree(flatLevelw)
            console.log('flatLevelw',  data)


            setFecData(facsData)

        }




                    setAreaData(props.areaList)

                    console.log('chek', props.areaList)
      
                

       

 

       

   
            if(props.areaList?.length > 0){

                setFormState({...FormState, areaList:[]})
                let flatLevel = []
                props.areaList?.forEach((item) => {

                    if(props.parentArea==item.area_code){
                        item.areaSubData = [];
                        item['level']=1;
                        item['parent_id']='';
                    }

                    var obj = {
                        value: item.area_code,
                        label: item.name,
                        parent: item.parent_id,
                        level:item.level
                       
                      }


               
                
    
                    flatLevel.push(obj)
    
    
                })

                const data = setLevelData(flatLevel);

                
                console.log('flatLevel',data)
    
                setParentAreaData(data);

                console.log('assaas', callData, data)
            }
          
         
        
    }, [props.areaList,props.parentArea, props.dumpData, props.sendData,  props.masterData]);





const getAreaList = (e) =>{
    setAreaChecked(e)
    console.log('asas',e)

    var dataArr = [];
    e?.forEach(item=>{

        props.areaList?.forEach(dt=>{
            if(dt.area_code==item){
                dataArr.push({code:dt.area_code, name:dt.name})

            }
        })


    })



    setFormState({...FormState, areaList:dataArr});
 if(dataArr?.length > 0){

 
    setDissable(false)
}
else{
    setDissable(true)
}

}


const getWatsteData = (e) =>{
     dataArr = e;


  

    console.log('filnalData',dataArr)

    setChecked(e)
    setFormState({...FormState, wasteType:dataArr})
    checkTest();
}

 


    const setLevelData = (flat) => {

        var roots = [];

        var all = {};

        flat.forEach(function (item) {

            all[item.value] = item;
        });


        Object.keys(all).forEach(function (value) {
            var item = all[value];
            if (item.parent === null || item.parent === "") {

                roots.push(item);
            } else if (item.parent in all) {
                var p = all[item.parent];
                if (!("children" in p)) {
                 
                    p.children = [];
                    
                }
                p.children.push(item);
            }
        });


        return roots;
    };




    const state = {
        open: false,
    };



    


    const onAreaSubmit = (event) => {
        event.preventDefault();

        const areas = [];

        FormState.areaList?.forEach(item=>{
            areas.push(item.code)
        })


        const dataTo = {
            location: areas,
            facName: FormState.facName,
            permType: FormState.permType != null ? FormState.permType : null,
            facType: FormState.facType,
            wasteType: FormState.wasteType,
            date_awarded_from: FormState.dateFrom?.$d===undefined?"":FormState.dateFrom?.$d,
            date_awarded_to: FormState.dateTo?.$d===undefined?"":FormState.dateTo?.$d,
            area_sq_mtr: FormState.areaSqf,
            capacity: FormState.capacity
        }

        console.log('data', dataTo)



        if(areas?.length > 0 ||  
            FormState.facName!='' ||
             FormState.permType!=null && FormState.permType!=''||
             FormState.facType!='' || 
             FormState.wasteType!='' && FormState.wasteType?.length > 0 || 
             FormState.dateFrom?.$d!=undefined ||
             FormState.dateTo?.$d!=undefined ||
             FormState.areaSqf!='' ||
             FormState.capacity!=''){
                props.filterData(dataTo, false);
        }
     



    

    }


    const resetFilter = () => {

        setFormState({
            ...FormState,
            areaList:[],

            facName: '',
            permType: '',
            facType: [],
            wasteType: [],
            dateFrom: null,
            dateTo: null,
            areaSqf: '',
            capacity: ''
        })
  
        permChe=false;
        checkName = '';
        checkFac = false;
         dataArr = [];
 Checkdatefrom = '';
 CheckdateTo = '';
 checkCap='';

 

        setDissable(true)
        setAreaChecked([])
        setChecked([])
        setFecChecked([])

        const dataTo = {
            location: [],
            facName: '',
            permType: 'All',
            facType: [],
            wasteType: [],
            date_awarded_from: FormState.dateFrom?.$d===undefined?"":FormState.dateFrom?.$d,
            date_awarded_to: FormState.dateTo?.$d===undefined?"":FormState.dateTo?.$d,
            area_sq_mtr: '',
            capacity: '',
            
            
        }

        console.log('dataTo', dataTo)



        props.filterData(dataTo, true);


    }
    // ===================================================================== Area Dropdown End
    const [age, setAge] = useState('');
    const handleSelect = (event) => {
        setAge(event.target.value);
    };

    const [value, setValue] = useState(dayjs(''));
    const handleDate = (newValue) => {
        setValue(newValue);
    };

    const marks = [
        {
            value: 0,
            label: '0',
        },
        {
            value: 100,
            label: '1,00,000',
        },
    ];

    function valuetext(value) {
        return `${value}`;
    }


    const items = ["Eswatini", "Eswatini 2", "Eswatini 3"];
    const [selectedItem, setSelectedItem] = useState("Eswatini");


    const removeObjectWithId =(arr, id)=> {

        const objWithIdIndex = arr.findIndex((obj) => obj.code === id);
        arr.splice(objWithIdIndex, 1);
        return arr;
      }

const selectMultiArea = (item) => {

const arrayDT = FormState.areaList;
let index = -1
arrayDT.forEach((x,i)=>{
    if(x.code == item.area_code){index = i}
})

if(index!=-1){arrayDT.splice(index,1)}

console.log('checkremoves', arrayDT)
    
    if(index!=-1){
        setFormState({
            ...FormState,
           areaList:arrayDT
        });

    }
    else{

            setFormState({
                ...FormState,
                ...FormState.areaList.push({code:item.area_code, name:item.name})
            });
            
    }

     

    checkTest();


}


const checkTest=()=>{




    if(FormState.areaList?.length > 0 ||  
         permChe!=false||
         checkName?.length > 0 || 
         dataArr?.length > 0 || 
          Checkdatefrom!='' ||
          CheckdateTo!='' ||
         checkFac!=false || 
         checkCap!=''){
           setDissable(false)
    }
    else{
        setDissable(true)
    }

 



}


    



const textChange = (event) => {

   
        setFormState({ ...FormState, [event.target.name]: event.target.value });
    
      

        if(event.target.name=='facName' && event.target.value?.length > 0){

            checkName=event.target.value;

        }else{
            checkName='';
        }

        
        if(event.target.name=='capacity' && event.target.value?.length > 0){

            checkCap=event.target.value;

        }else{
            checkCap='';
        }

        checkTest();

}

// ====================================================== Treeview Checkbox Start ==================================================

const [checked, setChecked] = useState([]);
const [expanded, setExpanded] = useState([]);
const [Fchecked, setFecChecked] = useState([]);
const [Fexpanded, setFecExpanded] = useState([]);
const [areaExpand, setAreaExpanded] = useState([]);



const getFacData = (e) => {
    dataArr = e;

    setFecChecked(e)
    setFormState({...FormState, facType:dataArr})
    checkTest();
}

const setLevelDataw = (flat) => {
    console.log("Mohit flatlever", flat);

    var roots = []

    var all = {}

    flat.forEach(function (item) {
        all[item.label] = item
    })
    Object.keys(all).forEach(function (label) {
        var item = all[label]
        if (item.parent === null || item.parent === '') {
            roots.push(item)
        } else if (item.parent in all) {
            var p = all[item.parent]
            if (!('children' in p)) {
                p.children = []
            }
            p.children.push(item)
        }
    })

    return roots
}








// ======================================================= Treeview Checkbox End ===================================================
    return (
        <>
            <div
                class="modal fade maps_popup"
                id="filtermodal"
                tabindex="-1"
                aria-labelledby="staticBackdropLabel"
                aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <div class="modal-title h4" id="staticBackdropLabel">
                                FILTERS
                            </div>
                            {/* <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button> */}
                            <button
                                id="closeFiler"
                                type="button"
                                class="btn-close"
                                data-dismiss="modal"
                                aria-label="Close"
                            ></button>
                        </div>
                        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={onAreaSubmit}>
                            <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                                <div class="modal-body">


                                    <div className="filterForm">
                                        <div className="row mb-30">
                                            <div className="col-lg-6 col-sm-12">
                                                <div className="form-group mb-0 inputform_style ifs_dropdown multiselectArea checkboxRemove">
                                                  


                    


                                                <div className="mat-form-field-wrapper">
                                               
                                               <span>Area</span>
                                               <Dropdown>
                                                   <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                   {FormState.areaList?.length > 0 && FormState.areaList.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d.name} </span></>) })}
                                                   {FormState.areaList?.length > 2 ? <><span>{FormState.areaList?.length - 1}+</span></> : ''}
                                                   </Dropdown.Toggle>
                                                   <Dropdown.Menu className="treeviewCheckbox">
                                                       <CheckboxTree
                                                           nodes={callData}
                                                           checked={areaChecked}
                                                           expanded={areaExpand}
                                                           onCheck={(e) => getAreaList(e)}
                                                           onExpand={(expanded) => setAreaExpanded(expanded)}
                                                       />
                                                   </Dropdown.Menu>
                                               </Dropdown>
                                           </div>
                                                  
                                                  
                                                    {/* <div className="mat-form-field-wrapper">
                                                        <span>Area</span>
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                            { FormState.areaList.slice(0,3)?.map((d,i)=>{ return (<><span className="badge">{ d.name} </span></>)})}
                                                            { FormState.areaList?.length > 3? <><span>{FormState.areaList?.length-1}+</span></>: ''}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                <TreeView
                                                                   aria-label="icon expansion"
                                                                   defaultCollapseIcon={<ExpandMoreIcon />}
                                                                   defaultExpandIcon={<ChevronRightIcon />}
                                                                    sx={{ flexGrow: 1,  }}
                                                                >
                                                                    {renderTree(callData)}
                                                                </TreeView>
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    </div> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style">
                                                    <div className="mat-form-field-wrapper">
                                                        <span>Facility name</span>
                                                        <TextField id="outlined-basic" autoComplete="off" name="facName" value={FormState.facName} onChange={(e) => textChange(e)} variant="outlined" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-30">
                                            <div className="col-md-12">
                                                <div className="form-group mb-0 inputform_style radioform_style">
                                                    <div className="mat-form-field-wrapper">
                                                        <span>Permission type</span>
                                                        <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-row-radio-buttons-group-label"
                                                                name="row-radio-buttons-group"
                                                                value={FormState.permType}
                                                                onChange={(e) =>{permChe=true; setFormState({ ...FormState, permType: e.target.value }); checkTest()}
                                                            }   id="radiocard"
                                                            >
                                                                <FormControlLabel  value="All" control={<Radio />} label="All" />
                                                                <FormControlLabel value="1" control={<Radio />} label="Licensed" />
                                                                <FormControlLabel value="0" control={<Radio />} label="Non-Licensed" />
                                                            </RadioGroup>
                                                        </FormControl>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-30">
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style fp_dropdown checkboxRemove ftRemove">
                                                    <div className="mat-form-field-wrapper">
                                                        <span>Facility Type</span>

                                                        <Dropdown>
                                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                                {FormState.facType?.length > 0 && FormState.facType.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
                                                                {FormState.facType?.length > 2 ? <><span>{FormState.facType?.length - 1}+</span></> : ''}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="treeviewCheckbox">
                                                                    <CheckboxTree
                                                                        nodes={facilityData}
                                                                        checked={Fchecked}
                                                                        expanded={Fexpanded}
                                                                        onCheck={(e) => getFacData(e)}
                                                                        onExpand={(expanded) => setFecExpanded(expanded)}
                                                                    />
                                                                </Dropdown.Menu>
                                                            </Dropdown>



                                                        {/* <FormControl fullWidth>
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    value={FormState.facType}
                                                    onChange={(e)=>setFormState({...FormState,facType:e.target.value })}
                                                >

                                                    
                                                              {FormState.FacData?.map((item) => (
                                                                  <MenuItem

                                                                      key={item.value}
                                                                      value={item.value}

                                                                  >
                                                                      {item.label}
                                                                  </MenuItem>
                                                              ))}
                                                
                                                </Select>
                                            </FormControl> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style fp_dropdown checkboxRemove">
                                                    <div className="mat-form-field-wrapper">
                                               
                                                            <span>Waste Type</span>
                                                            <Dropdown>
                                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                                {FormState.wasteType?.length > 0 && FormState.wasteType.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
                                                                {FormState.wasteType?.length > 2 ? <><span>{FormState.wasteType?.length - 1}+</span></> : ''}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="treeviewCheckbox">
                                                                    <CheckboxTree
                                                                        nodes={wasteTree}
                                                                        checked={checked}
                                                                        expanded={expanded}
                                                                        onCheck={(e) => getWatsteData(e)}
                                                                        onExpand={(expanded) => setExpanded(expanded)}
                                                                    />
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                                        </div>
                                   
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-30">
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style filteDatepicker">
                                                    <div className="mat-form-field-wrapper">
                                                        <span>Date awarded from</span>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={3}>
                                                                <DesktopDatePicker
                                                                    inputFormat="MM/DD/YYYY"


                                                                    value={FormState.dateFrom}
                                                                    onChange={(e) => {Checkdatefrom = e?.$d; setFormState({ ...FormState, dateFrom: e }); checkTest();}}
                                                                    renderInput={(params) => <TextField {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            placeholder: "DD/MM/YYYY"
                                                                        }}
                                                                    />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style filteDatepicker">
                                                    <div className="mat-form-field-wrapper">
                                                        <span>Date awarded to</span>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={3}>
                                                                <DesktopDatePicker
                                                                    inputFormat="MM/DD/YYYY"
                                                                    value={FormState.dateTo}
                                                                    onChange={(e) =>{CheckdateTo = e?.$d;setFormState({ ...FormState, dateTo: e }); checkTest()}}
                                                                    renderInput={(params) => <TextField {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            placeholder: "DD/MM/YYYY"
                                                                        }}
                                                                    />}
                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row mb-30">
                                            {/* <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style">
                                                    <div className="mat-form-field-wrapper">
                                                        <span>Area <small>(sq. meter)</small></span>
                                                        <TextField  value={FormState.areaSqf}
                                                           onChange={(e) => textChange(e)}
                                                           name="areaSqf"
                                                            id="outlined-basic" variant="outlined" />
                                                    </div>
                                                </div>
                                            </div> */}
                                            <div className="col-md-6">
                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                    <div className="mat-form-field-wrapper">
                                                        <span>Waste capacity <small>(Tonne/s)</small></span>
                                                        <TextField  value={FormState.capacity} type="number" InputProps={{  inputProps: {   min: 1  , max :1000000000 } }}
                                                              onChange={(e) => textChange(e)}
                                                            id="outlined-basic" name="capacity" variant="outlined" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* ======================================================================= */}
                                            
                                        {/* ======================================================================= */}



                                        {/* <div className="row">
                                            <div className="col-md-12">
                                                <div className="form-group mb-0 inputform_style fp_dropdown">
                                                    <div className="mat-form-field-wrapper">
                                                        <span className="wc_title">Waste capacity <small>(Tonne/s)</small></span>
                                                        <Slider
                                                      
                                                            aria-label="Default"
                                                            valueLabelDisplay="on"
                                                            min={0}
                                                            max={100000}
                                                            marks={marks}

                                                            value={FormState.capacity}
                                                            onChange={(e) => setFormState({ ...FormState, capacity: e.target.value })}
                                                        />
                                                        <div className="scrollslide_value">
                                                            <span className="sv_0">0</span>
                                                            <span className="sv_l">1,00,000</span>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div> */}



                                    </div>
                                </div>
                                <div class="modal-footer justify-content-center filterBtn">
                                    <Button disabled={diss} variant="text" onClick={resetFilter}><img src='assets/lib/images/home/reset-icon.svg' /> Reset</Button>
                                    <Button disabled={diss} variant="contained" type="submit">Apply <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                </div>
                            </Stack>
                        </ValidatorForm>
                    </div>
                </div>
            </div>
        </>
    );
}
