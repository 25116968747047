
import React, { useEffect, useRef, useState } from "react";
import './modal.css'

import "react-toastify/dist/ReactToastify.css";
import { isEmptyObject } from "jquery";
import $ from 'jquery';

import {omit} from 'lodash'
import Select from 'react-select';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';




import Autocomplete from '@mui/material/Autocomplete';

function MetadataModal(props) {
  const form = useRef()
  const [formError, setError] = useState({error:false});
  // console.log("pros" , props);
  const [indicator , setindicator] = useState('')
  const [unitdata ,  setunitdata] = useState('')
const[ Checked,setChecked] = useState('')

  const [unit, setUnit] = useState({
    unit: '',
    ius: '',
    unitName:''
})
  // const [indLabel, setIndLabel] = useState()
  const [subLabel, setsubLabel] = useState()
  const [subData, setSubGroup] = useState([]);
  const [state, setState] = useState({
    "id": '',
    "indicator":'', 
    "mt1": '',
    "mt2": '',
    "mt3":'',
    "mt4":'',
    "mt5":'',
    "mt6":'',
    "mt7":'',
    "mt8":'',
    "mt9":'',
    "unit":'',
    });



    useEffect(() => {
      if(props.isEdit) {
        console.log(props.indicatorData);
        console.log( "sfd", props.editMode?._id);
        setindicator(props.editMode.name); 
        setunitdata(props.editMode.unit)
        setState({

          "id": props.editMode?._id,
          "indicator": props.editMode?.name,
          "unit":props.editMode?.unit,
          "mt1": props.editMode?.metadata?.mt1,
          "mt2": props.editMode?.metadata?.mt2,
          "mt3": props.editMode?.metadata?.mt3,
          "mt4":props.editMode?.metadata?.mt4,
          "mt5":props.editMode?.metadata?.mt5,
          "mt6":props.editMode?.metadata?.mt6,
          "mt7":props.editMode?.metadata?.mt7,
          "mt8":props.editMode?.metadata?.mt8,
          "mt9":props.editMode?.metadata?.mt9,
              
        });
      }else{
        setindicator('')
        setunitdata('')
        setState({
          "id": '',
          "indicator":'',
          "unit":'',
          "mt1": '',
          "mt2": '',
          "mt3": '',
          "mt4":'',
          "mt5":'',
          "mt6":'',
          "mt7":'',
          "mt8":'',
          "mt9":'',
              
        });
      }
      
    }, [props]);
  




  
  const inputhandle = (e) => {
    let indicator, unit

   
    indicator = e.target.indicator;
    unit= e.target.unit;
    e.persist();
    validate(e,indicator,unit);

    //Let's set these values in state
    setValues({
        ...values,
        [indicator]:unit,
    })
  };

  ///update api
  const updateData = (e) => {
    // const form = useRef()
    e.preventDefault();

    const data = {
      "indicator":indLabel,
      "unit":subLabel,
     }
    
     
   
  
    props.parentCallback(data);
  }


  // useEffect(()=>{
  //   console.log("data" , props);
  //   if(props.editMode){ }
  // } , props.editMode)
  const getSubIndigator = (e,v) => {
  console.log('v',v)
  setindicator(v.label)


  //   setsubLabel(e.label)
    
  //   setSubGroup([{ ...subData, label:v.unit, value:v.unit }]);
 
//   const getSubIndigator = (e) => {
  // setsubLabel(e.label)
    // setSubGroup([...subData, []])
    setSubGroup([{ ...subData, label:v.unit, value:v.unit }]);
 
 }

const [indLabel, setIndLabel] = useState({
  indCode:'',
  indText:'',

})

const [values, setValues] = useState({});
//Errors
const [errors, setErrors] = useState({});
const validate = (e, indicator, value) => {
  //A function to validate each input values


    
    }

  //console.log("test",props.indicatorData)
    
   // ====================================================== Form Validation Start
      const handleSubmit = (e) => {
        e.preventDefault();

       const data = {
        "id": state.id,
          "indicator":indicator,
          "unit":unitdata,
            "mt1": state.mt1,
            "mt2": state.mt2,
            "mt3":state.mt3,
            "mt4":state.mt4,
            "mt5":state.mt5,
            "mt6":state.mt6,
            "mt7":state.mt7,
            "mt8":state.mt8,
            "mt9":state.mt9,
           
          
         }
           
                      
            //  console.log('mt',data)
    
    
             
            props.parentCallback(data); 
                    
       
  
      }
        

      const handleChange = (event) => {
      setState({...state,  [event.target.name]: event.target.value });
      let updatedList = [...Checked];
      // if (checked) { <- this part, checked is the array of checked item right?
      if(event.target.Checked) {
        updatedList = [...Checked, event.target.value];
      } else {
        updatedList.splice(Checked.indexOf(event.target.value), 1);
      }
      setChecked(updatedList);
 
    }
    // if(props.editMode)  setindicator(props.editMode.name)
// console.log("Data===" , props.indicatorData);
    // ====================================================== Form Validation End



      // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
  }
  
    function changedata(e , v){
      console.log("sfsdfg" , e , v);
        setunitdata(v.label)
      
    }
  return (
    <>
      <div className="user-form">
        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit} id="addUserForm">      
          <Stack spacing={2} sx={{ width: 300 }}  className="w-100">
            {/* <form onSubmit={(e)=>updateData(e)} noValidate="" style={{ 'position': 'relative' }} id="addUserForm" className="ng-untouched ng-pristine ng-invalid" > */}
              <div className="gallery-collections mt-0 metadata_form">
                  <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                            <label>Indicator</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style ifs_arrow">
                            <div className="mat-form-field-wrapper">
                             
                            <Autocomplete
                             id="free-solo-demo1"
                            //  freeSolo
                             readOnly = {props.isEdit}
                             value={{label: indicator}}
                             onChange={(event, value)=>getSubIndigator(event, value)}
                            
                             options={props.indicatorData.map((option) => option)}
                              getOptionLabel={(option) => option.label}
                             
                             filterSelectedOptions
                          
                             renderInput={(params) => <TextValidator {...params} label=""
                                          name="indicatorData" 
                                          value={indicator}
                                          validators={['required']}
                                          errorMessages={['Please select a indicator!']}
                                         />}
                            
                            />

                              {/* <Select
                                onChange={getSubIndigator} 
                                name="indicatorData" 
                                options={props.indicatorData != null && props.indicatorData != '' && props.indicatorData}
                              /> */}
                            </div>
                        </div>
                    </div>
                  </div>

                  <div className="row align-items-center mb-30">
                    <div className="col-md-3">
                        <span className="mat-form-field-label-wrapper">
                            <label>Unit</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style ifs_arrow">
                            <div className="mat-form-field-wrapper">
                            <Autocomplete
                             id="free-solo-demo1"
                            //  name = 'unitdata'
                            //  freeSolo
                            readOnly = {props.isEdit}
                             onChange = {(e  ,v)=>{changedata(e , v)}}
                           value={{label: unitdata}}
                           
                        options={subData.map((option) => option)}
                        getOptionLabel={(option) => option.label}
                         filterSelectedOptions
           
                     renderInput={(params) => <TextValidator {...params} label="" 
                    
                     name="unit" 
                   value={unitdata}
                     validators={['required']}
                     errorMessages={['Please select a unit!']}
                    
                     
                     />}
                    //  value={subData != null && subData != '' && subData}
                          
     
 />

                              {/* <Select 
                                onChange = {(e)=>setsubLabel(e.value)}
                                options={subData != null && subData != '' && subData}
                              /> */}
                            </div>
                        </div>
                    </div>
                  </div>   

                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Indicator Definition</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator  name="mt1" multiline rows={3} className="w-100" 
                              onChange={(e) => { handleChange(e) }}  value={state.mt1}
                              // validators={['required']}
                              // errorMessages={['This Field Is Required']}
                              />
                            </div>
                        </div>
                    </div>
                  </div> 

                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Method of Computation</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator autoComplete="off"  name="mt2" multiline rows={3} className="w-100" 
                              onChange={(e) => { handleChange(e) }} id="mt2" value={state.mt2}
                              // validators={['required']}
                              // errorMessages={['This field is required']}
                              />

                            </div>
                        </div>
                    </div>
                  </div> 

                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Overview</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator autoComplete="off"  name="mt3" multiline rows={3} className="w-100" 
                              onChange={(e) => { handleChange(e) }} id="overview" value={state.mt3}
                              // validators={['required']}
                              // errorMessages={['This field is required']}
                              />
                            </div>
                        </div>
                    </div>
                  </div> 
                  
            

                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Comments and Limitations</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator autoComplete="off" name="mt4"  multiline rows={3} className="w-100"
                               onChange={(e) => { handleChange(e) }} id="commentsandlimitations" value={state.mt4}
                              //  validators={['required']}
                              // errorMessages={['This field is required']}
                               />
                            </div>
                        </div>
                    </div>
                  </div>

             

                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Data Collection for Global Monitoring</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator autoComplete="off" name="mt5"  multiline rows={3} className="w-100" 
                              onChange={(e) => { handleChange(e) }} id="datacollectionforglobalmonitoring" value={state.mt5}
                              // validators={['required']}
                              // errorMessages={['This field is required']}
                              />
                            </div>
                        </div>
                    </div>
                  </div>

                 
                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Obtaining Data</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextField autoComplete="off" name="mt6"  multiline rows={3} className="w-100" 
                               onChange={(e) => { handleChange(e) }} id="dataavailability" value={state.mt6} 
                              //  validators={['required']} errorMessages={['This field is required']}
                              />
                              
                            </div>
                        </div>
                    </div>
                  </div>


                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Data Availability</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator autoComplete="off" name="mt7"  multiline rows={3} className="w-100" 
                              onChange={(e) => { handleChange(e) }} id="dataavailability" value={state.mt7}
                              // validators={['required']}
                              // errorMessages={['This field is required']}
                              />
                            </div>
                        </div>
                    </div>
                  </div>

               

                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Treatment of missing values</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator autoComplete="off" name="mt8"  multiline rows={3} className="w-100"
                               onChange={(e) => { handleChange(e) }} id="treatmentofmissingvalues" value={state.mt8}
                              //  validators={['required']}
                              // errorMessages={['This field is required']}
                               />
                            </div>
                        </div>
                    </div>
                  </div>

                  

                  <div className="row mb-30">
                    <div className="col-md-3 pt-2">
                        <span className="mat-form-field-label-wrapper">
                            <label>Regional & Global Estimates</label>
                        </span>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group mb-0 inputform_style">
                            <div className="mat-form-field-wrapper">
                              <TextValidator autoComplete="off" name="mt9"  multiline rows={3} className="w-100" 
                              onChange={(e) => { handleChange(e) }} id="regionalandglobalestimates" value={state.mt9}
                              // validators={['required']}
                              // errorMessages={['This field is required']}
                              />
                            </div>
                        </div>
                    </div>
                  </div>
                  
              

              </div>
      

              <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                <div className="d-flex align-items-center justify-content-center">
                    <Button variant="contained"  onClick={cancelModal} className="cancelbtn">Cancel</Button>
                    <Button variant="contained" className="addbtn" type="submit">
                      {props.isEdit? 'Update': 'Add ' }
                    </Button>
                </div>
              </div>

              {/* <div className="gallery-sidebar-button text-center new_gallery-sidebar-button">
                <button type="submit"  className="btn btn-danger mdsubmitBtn" > {props.isEdit? 'Update': 'Add' }</button>
            
                
                  

                  
                </div> */}
            {/* </form>  */}
          </Stack>
        </ValidatorForm> 

      </div>
    </>
  );
              
}
export default MetadataModal;