import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Breadcrumbs from '../../Layout/BreadCrumbs/Breadcrubms';
import Footer from '../../Layout/footer';
import Header from '../../Layout/header';
import Sidebar from '../../Layout/sidebar';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import DataEntryModal from '../../Modals/dataEntry.modal';


// const handleChange = ({ selectedRows }) => {
//   // You can set state or dispatch with something like Redux so we can use the retrieved data
//   console.log('Selected Rows: ', selectedRows);
// };

const dataapproveTemplate = function () {

  const  openCloseModal = async () => {

        // if(this.state.scrollableModal==false){
        //     this.setState({scrollableModal:true})
        // }
        // else{
            this.setState({scrollableModal:false})
        // }
      
    }

    

    const  closeModalClose = async () => {

        // if(this.state.scrollableModal==false){
        //     this.setState({scrollableModal:true})
        // }
        // else{
            this.setState({disaaprovest:false})
        // }
      
    }

    const  closeModalClosePublis = async () => {

      // if(this.state.scrollableModal==false){
      //     this.setState({scrollableModal:true})
      // }
      // else{
          this.setState({publishModal:false})
      // }
    
  }

  const  closeDeleteModal = async () => {

        console.log("aaaaaaaaaaa")
        this.setState({deleteApprovedData:false})
   
  
}

  return (
    <>

      {/* 
      <Breadcrumbs />

      <Sidebar /> */}

      <div className="main dataApprove" id="main">
        <div className='container-fluid areaCont dataApporve'>
          <section className='areaSection data_approve' id="areaSection">


        
          <div className='col-lg-12 col-md-12'>
            <div className="row header-div new-h-d pt-0 pl-0">
              {/* <div className="col-md-1 ">
              <div class="form-group form-inline">                            
                  <label >Show</label>
                      <select  className='form-control' value={this.state.dataLimit} onChange={this.updateDataLimit}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        </select>
                  </div>
     

              </div>

              <div className="col-md-2">

              <div class="form-group form-inline">                            
                  <label >Status</label>
                      <select id="statusSelect" className='form-control' value={this.state.dataStatus} onChange={this.getDataByStatus}>
                        <option value={2}>Pending</option>
                        <option value={1}>Approved</option>
                        <option value={3}>Disapproved</option>
                        </select>
                  </div>

                  {this.state.dataStatus==2 && 
                  
                  <>
                  <button className="pop-up-btn"  onClick={this.publishAll}>
                            <span _ngcontent-nud-c5="" title="Approve">
                    <img _ngcontent-blx-c5 class="cursor-pointer" src="assets/lib/images/icon/right_icon.svg" />
                    </span></button>
                  
                    
                  
                  </>
 
                  }
          
              </div> */}
              <div className="col-md-12 pl-2">
              <div class="">    

                {/* <SearchComponent.default onSearch={props.onSearch} searchText={props.searchText} /> */}
                <subHeaderComponentMemo.default  parentCallBack={this.sendStatus} 
                                                 parentCallBackApprove={this.approveAllData} 
                                                 statusFilter = {this.state.statusData} 
                                                 onSearch={this.onSearch} 
                                                 PropDeleteBulk={this.deleteBulkData}
                                                 searchText={this.state.searchText} 
                                                 value='41' 
                                                 checkData = {this.state.bulkDelete}
                                                 approveData = {this.state.filteredData}
                                                 nRows={this.state.filteredData?.length}
                                                  dataStatus={this.state.dataStatus}
                                                 /> {/*rowsPerPage={this.state.rowsPerPage} onRowsChange={this.rowsUpdate} */}
         
                </div>
              </div>
            </div>
          </div>

            
              <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50,100]}
             // paginationTotalRows={this.state.pageNumber}
              style={{ display: "flex" }}
              selectableRows={this.state.dataStatus==1 ? true : false}
              onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
              persistTableHead
              className='dataTableScroll'
            />
          </section>
        </div>
      </div>






      {/* modal area begin here============== */}

      
      {/* <div>
      <MDBModal  id='viewmodel'  show={this.state.scrollableModal} onHide={this.state.disscrollableModal} tabIndex='-1' >
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle><center>Reason of Disapproval</center></MDBModalTitle>
              <MDBBtn className='btn-close' color='none' ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>...</MDBModalBody>

          
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      </div> */}
      

        <Modal show={this.state.scrollableModal} onHide={openCloseModal} className='disapprove_popup da_popup'>
          <Modal.Header closeButton>
            <Modal.Title>Reason of Disapproval</Modal.Title>
          </Modal.Header>
          <Modal.Body>{this.state.remarkdata}</Modal.Body>
        </Modal>

        <Modal centered show={this.state.disaaprovest} onHide={closeModalClose} className='disapprove_popup da_popup'>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to disapprove this data?</Modal.Title>
          </Modal.Header>
          <Modal.Body>Enter Remarks:<br /><textarea id="reasoncancel" className='form-control' /></Modal.Body>
          <Modal.Footer>              
              <button class="btn close_btn" onClick={(e)=>closeModalClose()}>Cancel</button>
              <button class="btn yes_btn" onClick={(e)=>{this.disapproveData(this.state.remarkdata,document.getElementById("reasoncancel").value); closeModalClose();}} >OK</button>
          </Modal.Footer>        
        </Modal>

        <Modal show={this.state.publishModal} onHide={closeModalClosePublis}>
          <Modal.Header closeButton>
            <Modal.Title>Confirm to approve all data?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Enter Remarks:<br /><textarea id="reasoncancel" /></Modal.Body> */}
          <Modal.Footer className='justify-content-center'>
              <button onClick={(e)=>{this.publishAllData(); closeModalClosePublis();}} >Yes</button>
              <button onClick={(e)=>closeModalClosePublis()}>No</button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.deleteApprovedData} onHide={closeDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Are you sure you want to delete?</Modal.Title>
          </Modal.Header>
          {/* <Modal.Body>Enter Remarks:<br /><textarea id="reasoncancel" /></Modal.Body> */}
          <Modal.Footer>
              <button onClick={(e)=>{this.deldata(this.state.remarkdata);closeDeleteModal();}}  >Yes</button>
              <button onClick={(e)=>closeDeleteModal()} >No</button>
          </Modal.Footer>       
        </Modal>
        
      






{/* 
      <Footer /> */}

      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>



      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>

    </>
  );
};

export default dataapproveTemplate;