
import React, { useEffect, useRef, useState } from "react";
import dayjs from 'dayjs';
import * as constants from '../../Config/app.constants';
// import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { MDBDropdown, MDBDropdownMenu, MDBDropdownToggle, MDBDropdownItem } from 'mdb-react-ui-kit';
import DatePicker from 'react-date-picker';
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Modal from 'react-bootstrap/Modal';
import * as $ from 'jquery';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Dropdown from 'react-bootstrap/Dropdown';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import CheckboxTree from 'react-checkbox-tree';

import Autocomplete from '@mui/material/Autocomplete';


import SimpleReactValidator from 'simple-react-validator';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { event } from "jquery";

import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';

import ListItemText from '@mui/material/ListItemText';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { toast } from "react-toastify";



const DumpSiteModal = (props) => {
    const form = useRef()
    const simpleValidator = useRef(new SimpleReactValidator({
        messages: {
            required: 'This field is required'
        }
    }));
    const [subLabel, setsubLabel] = useState();
    const [locs, setLocs] = useState({
        lat:'',
        long:''
    });
    const [State, setStatetest] = useState('')
    const [, forceUpdate] = useState();

    const [state, setState] = useState({
        "id": '',
        "location": '',
        "facility_name": '',
        "facility_type": '',
        "latitude": '',
        "longitude": '',
        "license_number": '',
        "date_awarded_from": '',
        "date_awarded_to": '',
        "permissionStatus": '',
        "document": '',
        "waste_types": '',
        'areaName': '',
        "capacity": '',
        "no_working_days": '',
        "operating_hours": '',
        "operating_temperature": '',
        "available_services": '',
        'newWaste_types': []




    });

    const [facilityType, setfacilityType] = useState('');


    // const [wasteTypes ,setwasteTypes] = useState('');
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const names = [
        'Oliver Hansen',
        'Van Henry',
        'April Tucker',
        'Ralph Hubbard',
        'Omar Alexander',
        'Carlos Abbott',
        'Miriam Wagner',
        'Bradley Wilkerson',
        'Virginia Andrews',
        'Kelly Snyder',
    ];



    const [facility, setFacility] = useState([]);

    const animatedComponents = makeAnimated();



    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const areaInputRef = useRef();
    const [flatTree, setFlatTree] = useState([]);
    const [wasteTree, setWasteTeree] = useState([]);
    const [areaTree, setAreaTree] = useState([]);
    const [getTreeData, setTreeData] = useState([]);
    const [FacTypeData, setTypeData] = useState();
    const [cropModal, setCropModal] = useState(false)
    const [image, setImage] = useState();
    const [fileName, setFilename] = useState();
    const [Date, setDate] = useState(dayjs());
    const [value, setValue] = useState(dayjs());
    const [cropData, setCropData] = useState('');
    const [cropData2, setCropData2] = useState('assets/images/placeholder.jpg');
    const [cropper, setCropper] = useState();
    const [cordError, setCordError] = useState();
    const [pkDays, setPkDays] = useState([]);
    const [pkWaste, setPkWaste] = useState([]);

    const [capacity, setCapacity] = useState([{
        label: "0-10 kg", value: "0-10 kg"
    }, {
        label: "10-100 kg", value: "10-100 kg"
    }, {

        label: "100-1000 kg", value: "100-1000 kg"
    }, {
        label: "Above 1000 kg", value: "Above 1000 kg"
    }]);















    useEffect(() => {
            ValidatorForm.addValidationRule('latitudeOutOfBound', (latitude) => {
              if ((latitude < constants.latitude.min || latitude > constants.latitude.max) && latitude!= "" ) {
                  return false;
              }
              return true;
          });
            ValidatorForm.addValidationRule('longitudeOutOfBound', (longitude) => {
              if ((longitude < constants.longitude.min || longitude > constants.longitude.max) && longitude!= "" ) {
                  return false;
              }
              return true;
          });

        console.log("-----------treedata--------", props.treeData);

        const newdfacData = []
        constants.facilityPermissions.map(item => {

            newdfacData.push({ label: item.type, value: item.type })
            console.log("ttttttttttttttttttt===========>", newdfacData)

        })

        setFacility(newdfacData)


        const facilities = props.facilityData?.Facility_Types;
        const operating = props.operatingData?.Operating_Hours;
        const facilities1 = props.facilityData?.Waste_Types;
        // console.log('qqqqqttttt',facilities1)
        setCropData2(null);
        // setfacilityType({...facilityType, ...facilities})

        setfacilityType(props.editMode?.facility_type);
        setfacilityType(props.editMode?.operating_hours);
        setfacilityType(props.editMode?.operating_temperature);
        // setwasteTypes(props.editMode?.waste_types)

        if (props.isEdit) {
            setCropData2(constants.facilityDocPath+props.editMode?.document)
            constants.facilityPermissions.forEach(item => {

                if (item.type == props.editMode?.facility_type) {
                    setTypeData(item.data)
                }
            })

            let loc = '';

            props.areaTree.map(item => {

                if (item.area_code == props.editMode.location) {
                    loc = item.name;
                    //   loc=item.area_code;
                    //     console.log( "sfd1111",loc,item.area_code);
                    console.log("sangeeta by done ", loc)
                }
            })

            // console.log( "sfd",props.editMode);



            setState({

                "id": props.editMode?._id,//fix issue

                "location": props.editMode.location,
                "facility_name": props.editMode?.facility_name,
                "facility_type": props.editMode?.facility_type,
                "latitude": props.editMode?.latitude,
                "longitude": props.editMode?.longitude,
                // "description":props.editMode?.description,

                "license_number": props.editMode?.license_number,
                "date_awarded_from": props.editMode?.date_awarded_from,
                "date_awarded_to": props.editMode?.date_awarded_to,
                "permissionStatus": props.editMode?.permissionStatus,
                "document": props.editMode?.cropData,
                "waste_types": props.editMode?.newWaste_types,
                'areaName': loc,
                "capacity": props.editMode?.capacity,
                "no_working_days": props.editMode?.no_working_days,
                "operating_hours": props.editMode?.operating_hours,
                "operating_temperature": props.editMode?.operating_temperature,
                "available_services": props.editMode?.available_services,
                'newWaste_types': props.editMode?.waste_types
            });


        }
        else {
            // setfacilityType('')
            // setwasteTypes('')
            setState({
                "id": '',
                "location": '',
                "facility_name": '',
                "facility_type": '',
                "latitude": '',
                "longitude": '',
                // "description": '',

                "license_number": '',
                "date_awarded_from": '',
                "date_awarded_to": '',
                "permissionStatus": '',
                "document": '',
                "waste_types": '',
                'areaName': '',
                "capacity": '',
                "no_working_days": '',
                "operating_hours": '',
                "operating_temperature": '',
                "available_services": '',
                'newWaste_types': []

            });

        }

        if (props.areaTree != '' && props.areaTree != null) {
            console.log("test by team ", areaTree)
            let flatLevel = [];


            props.areaTree.forEach((item) => {


                item.areaSubData = [];

                flatLevel.push(item)


            })


            console.log('level1', flatLevel)
            const data = setLevelData(flatLevel)
            const levelCount = getHowManyLevel(data);

            console.log("test by react team", data)

            setAreaTree(data)




        }
    }, [props]);



    const getWatsteData = (e) =>{
        const dataArr = e;
       
    
        setChecked(e)
        setState({...state, newWaste_types:e})
       
    }
    


    const getCropData = () => {

        if (typeof cropper !== "undefined") {

            var dt = cropper.getCroppedCanvas().toDataURL();
            var fields = dt.split('base64,');
            var street = fields[1];
            var path = fileName + ';' + street;
            setCropData(path);
            setCropData2(dt);
            hideTriggerModal();
        }
    };


    const selectFile = (e) => {

        e.preventDefault();
        let files;
        if (e.dataTransfer) {
            files = e.dataTransfer.files;
        } else if (e.target) {
            files = e.target.files;
        }
        setFilename(files[0].name);
        const reader = new FileReader();
        reader.onload = () => {
            setImage(reader.result);
            setCropModal(true)
        };
        reader.readAsDataURL(files[0]);


    }


    const hideTriggerModal = () => {


        if (cropModal == true) {

            setCropModal(false);
        }
        else {
            setCropModal(true);
        }
    }










    const [personName, setPersonName] = React.useState([]);
    const [personItem, setPersonItem] = React.useState([]);
    const handleChange1 = (event) => {
        const {
            target: { value },
        } = event;
        setPersonItem(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    // ====================================================== Form Validation Start
    // const [State, setState] = useState('')

    // useEffect(() => {
    //     // if((constants.latitude.min > state.latitude || constants.latitude.max < state.latitude) && state.latitude !=""){
    //     //     setCordError("Latitude is out of bounds")
    //     // } else if((constants.longitude.min > state.longitude || constants.longitude.max < state.longitude) && state.longitude != ""){
    //     //     setCordError("Longitude is out of bounds")
    //     // } 
    //     // else{
    //     //     setCordError("This field is required")
    //     // }
    //     switch()
    // })

    const getCoordinates = (e) => {
        e.preventDefault();
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                
                // setLocs({...locs, lat:position.coords.latitude, long:})
                setState({...state, latitude: position.coords.latitude, longitude: position.coords.longitude })
                // forceUpdate(1);
            })
        }
        // console.log("CLICKED ON PROMPTTT", navigator.geolocation)
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        console.log("hhhhhh========>", props.isEdit)
        let dataToSend = [];
        if (props.isEdit == true) {

            dataToSend = {

                "id": state.id,
                "location": state.location,
                "areaName": state.areaName,
                "facility_name": state.facility_name,
                "facility_type": state.facility_type,
                "latitude": state.latitude,
                "longitude": state.longitude,
                //   "description": state.description,
                "license_number": state.license_number,
                "date_awarded_from": state.date_awarded_from,
                "date_awarded_to": state.date_awarded_to,
                "permissionStatus": state.permissionStatus,
                "document": cropData,
                "capacity": state.capacity,
                "no_working_days": state.no_working_days,
                "operating_hours": state.operating_hours,
                "operating_temperature": state.operating_temperature,
                "available_services": state.available_services,
                'newWaste_types': state.newWaste_types
            }
            console.log("WHAT IS THIS BEHAVIOUR?--->", state.permissionStatus)

        } else {

            dataToSend = {
                "id": null,
                "location": state.location,
                "areaName": state.areaName,
                "facility_name": state.facility_name,
                "facility_type": state.facility_type,
                "latitude": state.latitude,
                "longitude": state.longitude,
                //   "description": state.description,

                "license_number": state.license_number,
                "date_awarded_from": state.date_awarded_from,
                "date_awarded_to": state.date_awarded_to,
                "permissionStatus": state.permissionStatus,
                "document": cropData,
                //    "capacity":parseFloat(state.capacity),
                "capacity": state.capacity,                
                "no_working_days": state.no_working_days,
                "operating_hours": state.operating_hours,
                "operating_temperature": state.operating_temperature,
                "available_services": state.available_services,
                'newWaste_types': state.newWaste_types
            }


        }

        //      props.parentCallback(dataToSend);
        //      console.log("qqqqq11111=========>",dataToSend)
        if (simpleValidator.current.allValid()) {
            console.log('adm', dataToSend)
            props.parentCallback(dataToSend);
        } else {
            simpleValidator.current.showMessages();
            forceUpdate(1);
        }
    }

    const handleChange = (event) => {

        setState({ ...state, [event.target.name]: event.target.value });
        event.preventDefault();

        // const {
        //     target: { value },
        //   } = event;
        //   setPersonName(
        //     // On autofill we get a stringified value.
        //     typeof value === 'string' ? value.split(',') : value,
        //   );


        if (event.target.name == 'facility_type') {
            constants.facilityPermissions.forEach(item => {

                if (item.type == event.target.value) {
                    setTypeData(item.data)
                }
            })

        }



    }

    // area tree sctructure=========================//

    const getHowManyLevel = (obj) => {
        let res = JSON.stringify(obj).replace(/[^{|^}]/g, '')
        while (/}{/g.test(res)) {
            res = res.replace(/}{/g, '')
        }
        return res.replace(/}/g, '').length
    }




    const setLevelData = (flat) => {

        var roots = []

        var all = {}

        flat.forEach(function (item) {
            all[item.area_code] = item
        })
        Object.keys(all).forEach(function (area_code) {
            var item = all[area_code]
            if (item.parent_id === null || item.parent_id === '') {
                roots.push(item)
            } else if (item.parent_id in all) {
                var p = all[item.parent_id]
                if (!('areaSubData' in p)) {
                    p.areaSubData = []
                }
                p.areaSubData.push(item)
            }
        })

        return roots
    }




    const renderTree = (nodes) => (

        nodes.map((item) => {
            return (<>

                <TreeItem
                    className={item.areaSubData?.length > 0 ? 'has-child' : 'no-child'}
                    key={item.area_code} nodeId={item._id} label={item.name}
                    onClick={() => { setState({ ...state, areaCode: item.area_code, areaName: item.name, location: item.area_code }) }} >

                    {Array.isArray(item.areaSubData) && item.areaSubData != null
                        ? renderTree(item.areaSubData)
                        : null}
                </TreeItem>

            </>);


        })


    );



    const cancelModal = (event) => {
        event.preventDefault();
        props.closeModal(event.target);
    }



    const handleDate = (newValue) => {
        setValue({ ...state, [newValue.target.name]: newValue.target.value });

    };
    const handleDateValue = (newValueTest) => {

        setDate({ ...state, [newValueTest.target.name]: newValueTest.target.value });
    };


    // ====================================================== Form Validation End
    const [Facitlity, setFacilityType] = useState([]);

    const [mainTreeData, setMainTreeData] = useState([]);

    useEffect(() => {
        console.log("Mohit treeData", props.treeData);
        if (props.treeData != '' && props.treeData != null) {
            var flatLevelw = [];
            props.treeData?.forEach((item) => {

                
              
                var obj = {
                    value: item.value,
                    label: item.label,
                    parent: item.parent,
                    level:item.level
                    
                  }

                flatLevelw.push(obj)


            })
            const data = setLevelDataw(flatLevelw)
            setWasteTeree(data)

            setFlatTree(flatLevelw)
            console.log('flatLevelw',  data)

        }
    }, [props.treeData]);






const setLevelDataw = (flat) => {
    console.log("Mohit flatlever", flat);

    var roots = []

    var all = {}

    flat.forEach(function (item) {
        all[item.label] = item
    })
    Object.keys(all).forEach(function (label) {
        var item = all[label]
        if (item.parent === null || item.parent === '') {
            roots.push(item)
        } else if (item.parent in all) {
            var p = all[item.parent]
            if (!('children' in p)) {
                p.children = []
            }
            p.children.push(item)
        }
    })

    return roots
}



    useEffect(() => {

        if (typeof value === 'number') {

            ValidatorForm.addValidationRule('islatitudeMatch', (value) => {



                if (parseFloat(value) < constants.latitude['min'] || parseFloat(value) > constants.latitude['max']) {

                    return false;
                }
                return true;
            });

            ValidatorForm.addValidationRule('islongitudeMatch', (value) => {

                if (parseFloat(value) < constants.longitude['min'] || parseFloat(value) > constants.longitude['max']) {
                    return false;
                }
                return true;
            });

        }

    }, [props]);
















    const renderTreeW = (nodes) => {

        return (
            <>
                {
                    nodes.map((item) => {

                        let classes = '';
                        let isMulti = '';
                        let isExist = null;
                        if (state.newWaste_types?.length > 0) {
                            isExist = state.newWaste_types?.filter(dt => {
                                return dt === item.label;
                            })
                        }

                        if (isExist?.[0] === item.label) {
                            isMulti = 'multi'
                        }

                        if (item.areaSubData?.length < 1) {
                            classes = 'no-child  ' + isMulti
                        }
                        else if (item.areaSubData?.length > 0) {
                            classes = 'has-child  ' + isMulti
                        }

                        return (

                            <TreeItem
                                className={classes}
                                key={item.label} nodeId={item.label} label={item.label}
                                onClick={() => { selectMultiW(item.label) }}
                            >

                                {item.areaSubData != null && Array.isArray(item.areaSubData) && item.areaSubData?.length > 0
                                    ? renderTreeW(item.areaSubData)
                                    : <></>}
                            </TreeItem>

                        );


                    })

                }
            </>
        )


    };



    const selectMultiW = (item) => {

        console.log('checkremoves', state)
        let arrayDT = state.newWaste_types;
        let index = -1
        //   arrayDT?.forEach((x,i)=>{
        //     if(x == item){index = i}
        //   })
        arrayDT?.map((x, i) => {
            if (x == item) { index = i }
            return x;
        })


        if (index != -1) {
            arrayDT?.splice(index, 1);
            setState({
                ...state,
                newWaste_types: arrayDT
            });

        }
        else {
            arrayDT.push(item);
            setState({
                ...state,
                newWaste_types: arrayDT
            });

        }


    }

    return (

        <>


            <div className="user-form">
                <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit} id="addUserForm">
                    <Stack spacing={2} sx={{ width: 300 }} className="w-100">
                        <div className="gallery-collections mt-0 metadata_form">


                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Facility Type</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style fp_dropdown ftDrop">
                                        <div className="mat-form-field-wrapper">

                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                name="facility_type"
                                                value={state.facility_type}
                                                placeholder=''
                                                onChange={handleChange}

                                            >
                                                {facility.map((item) => (
                                                    <MenuItem

                                                        key={item.value}
                                                        value={item.value}

                                                    >
                                                        {item.label}
                                                    </MenuItem>
                                                ))}

                                            </Select>
                                            {/* <Select
                                            name="facility_type"
                                            // value={{label: state.facility_type}}
                                            options={constants.facilityPermissions}
                                            placeholder=''
                                            onChange={(e) => {setState({...state, facility_type:e.value }) }} 
                                        
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                           
                                        />  */}
                                            {/* <Dropdown>
                                                <Dropdown.Toggle>{Facitlity}</Dropdown.Toggle>
                                                <Dropdown.Menu>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Treatment of waste')}}>Treatment of waste</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Designated waste storage')}}>Designated waste storage</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Incinerator')}}>Incinerator</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Recycling plant/facility')}}>Recycling plant/facility</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Waste transfer stations/facilities')}}>Waste transfer stations/facilities</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Composting facilities')}}>Composting facilities</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Remediation of contaminated land')}}>Remediation of contaminated land</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Sanitary landfill')}}>Sanitary landfill</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Composting facilities')}}>Controlled dumpsite</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Wastewater treatment plant')}}>Wastewater treatment plant</Dropdown.Item>
                                                    <Dropdown.Item  onClick={(e)=>{setFacilityType('Buy-back centres /Drop-off points')}}>Buy-back centres/Drop-off points</Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            {FacTypeData?.['Name']['required'] === true &&
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Name</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="mat-form-field-wrapper">
                                                <TextValidator name="facility_name" className="w-100"
                                                    onChange={(e) => { handleChange(e) }} value={state.facility_name}
                                                    validators={['required']}
                                                    errorMessages={['This field is required']}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {FacTypeData?.['Location']['required'] === true &&
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Area</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style ifs_dropdown">
                                            <div className="mat-form-field-wrapper">
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                        <TextValidator
                                                            className=" w-100"
                                                            label=""
                                                            onChange={handleChange}
                                                  
                                                            name="areaName"
                                                            value={state.areaName}
                                                            validators={['required']}
                                                            errorMessages={['Please select a areaName!']}
                                                        />

                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <TreeView

                                                            aria-label="file system navigator"
                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                            defaultExpandIcon={<ChevronRightIcon />}

                                                            sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                        >
                                                            {renderTree(areaTree)}

                                                        </TreeView>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {FacTypeData?.['Capacity']['required'] === true &&
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Capacity <span className="text-muted">(Tonnes)</span></label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style fp_dropdown">
                                            <div className="mat-form-field-wrapper">
                                                <TextValidator InputProps={{  inputProps: {min: 0} }} name="capacity" type="number" className="w-100"
                                                    onChange={(e) => { handleChange(e) }} value={state.capacity}
                                                //  validators={['required']}
                                                //  errorMessages={['This field is required']}
                                                />
                                                {/* <Select
                                            name="capacity"
                                            value={{label:state.capacity}}
                                            placeholder=''
                                            options={capacity} 
                                            onChange={(e) => { setState({...state, capacity:e.value }) }} 
                                           
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                        /> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                               {FacTypeData?.['Waste Type']['required']===true && 
                            <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Waste Type</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style ifs_dropdown multiDrop checkboxRemove">
                                        <div className="mat-form-field-wrapper">
                                        <Dropdown>
                                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                                {state.newWaste_types?.length > 0 && state.newWaste_types.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
                                                                {state.newWaste_types?.length > 2 ? <><span>{state.newWaste_types?.length - 1}+</span></> : ''}
                                                                </Dropdown.Toggle>
                                                                <Dropdown.Menu className="treeviewCheckbox">
                                                                    <CheckboxTree
                                                                        nodes={wasteTree}
                                                                        checked={checked}
                                                                        expanded={expanded}
                                                                        onCheck={(e) => getWatsteData(e)}
                                                                        onExpand={(expanded) => setExpanded(expanded)}
                                                                    />
                                                                </Dropdown.Menu>
                                                            </Dropdown>
                                            {/* <Dropdown>
                                                <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                       <span className="listBud">
                                                        { state.newWaste_types?.length > 0 && state.newWaste_types.slice(0,3).map((d,i)=>{ return (<>{ d }, </>)})}
                                                        
                                                    </span>
                                                    { state.newWaste_types?.length > 3? <><span>{state.newWaste_types?.length-1}+</span></>: ''}
                                                    <TextValidator
                                                    className="d-none w-100"
                                                    label=""
                                                    type='hidden'
                                                    // onChange={handleChange}
                                                    name="newWaste_types"
                                                    value={state.waste_types}
                                                    />

                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <TreeView
                                                    // disabled={props.editData?.[0]?.level == 1 ? true : false}
                                                    aria-label="file system navigator"
                                                    defaultCollapseIcon={<ExpandMoreIcon />}
                                                    defaultExpandIcon={<ChevronRightIcon />}

                                                    sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                                                    >
                                                    {renderTreeW(mainTreeData)}

                                                    </TreeView>
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                            {FacTypeData?.['Coordinates']['required'] === true &&
                                <div className="dumbsite subgroup_indicator mb-30">
                                    <div className="row align-items-center mb-3">
                                        <div className="col-md-12">
                                            <span className="mat-form-field-label-wrapper">
                                                <label className="ds_location">Location</label>
                                            </span>
                                            <button className="target" onClick={(e) => { getCoordinates(e) }}>
                                                <img src="assets/target.svg"/>
                                            </button>
                                            {/* <button onClick={(e) => { getCoordinates(e) }}>Get Coordinates</button> */}
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-3">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Latitude <span className="text-muted">(In Decimal)</span></label>
                                            </span>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style">
                                                <div className="mat-form-field-wrapper">
                                                    <TextValidator name="latitude" multiline rows={3} className="w-100"
                                                        onChange={(e) => { handleChange(e) }} value={state.latitude}
                                                        validators={["latitudeOutOfBound",'required']}
                                                        errorMessages={["Latitude is out of bounds",'This field is required']}
                                                        // errorMessages={cordError}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row align-items-center mb-30">
                                        <div className="col-md-3">
                                            <span className="mat-form-field-label-wrapper">
                                                <label>Longitude <span className="text-muted">(In Decimal)</span></label>
                                            </span>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group mb-0 inputform_style">
                                                <div className="mat-form-field-wrapper">
                                                    <TextValidator name="longitude" multiline rows={3} className="w-100"
                                                        onChange={(e) => { handleChange(e) }} value={state.longitude}
                                                        validators={["longitudeOutOfBound",'required']}
                                                        errorMessages={["Longitude is out of bounds",'This field is required']}
                                                        // errorMessages={cordError}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }
                            {FacTypeData?.['Picture']['required'] === true &&
                                <div className="row align-items-start mb-30">
                                    <div className="col-md-3 pt-2">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Picture</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="form-group filegroup">
                                                <input name="document" type="file" class="form-control " onChange={selectFile}  id="customFile" accept="image/*" />
                                            </div>
                                            <div className="w-15">
                                                {cropData2 && <img className="rounded img-fluid mr-auto h-100 w-100 ml-auto" id="renderImg" style={{ width: "100%" }} src={cropData2} alt="cropped" />}

                                            </div>
                                            {/* <div className="mat-form-field-wrapper">
                                            <TextValidator autoComplete="off" className="w-100" type="file" 
                                           onChange={(e) => { handleChange(e) }}  value={state.document}
                                            name="document" id="document"/>
                                        </div> */}
                                        </div>
                                    </div>
                                </div>
                            }
                            {/* <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Facility Type</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style multidropdown">
                                        <div className="mat-form-field-wrapper"> */}

                            {/* <Autocomplete
                                        id="free-solo-demo1"
                                        freeSolo
                                        onChange={(event, value)=>getSubIndigator(event, value)}
                                        name="facilityData" 
                                        options={props.facilityData.map((option) => option)}
                                        getOptionLabel={(option) => option.label}
                                        filterSelectedOptions
                                        renderInput={(params) => <TextValidator {...params} label="" />}
                                        validators={['required']}
                                        errorMessages={['This Field Is Required']}
                                        /> */}

                            {/* <Select
                                            name="facility_type"
                                            value={{label: state.facility_type}}
                                            options={props.facilityData?.Facility_Types}
                                            placeholder=''
                                            onChange={(e) => {setState({...state, facility_type:e.value }) }} 
                                        
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                           
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                            {FacTypeData?.['Permission Status']['required'] === true &&
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <label>Permission Status</label>
                                        {/* <label>Permission Status:  {} </label> */}
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group dsm_radio">
                                            <div class="form-check form-check-inline pr-3">
                                                <input name="prmStatus" class="form-check-input" type="radio" id="inlineRadio1" value="1" checked={state.permissionStatus == '1' ? 'checked' : ''}
                                                    onChange={(e) => { setState({ ...state, permissionStatus: e.target.value }) }}

                                                />
                                                <label class="form-check-label" for="inlineRadio1">Licensed</label>
                                            </div>
 
                                            <div class="form-check form-check-inline">
                                                <input name="prmStatus" class="form-check-input" type="radio" id="inlineRadio2" value="0" checked={state.permissionStatus == '0' ? 'checked' : ''} onChange={(e) => { setState({ ...state, permissionStatus: e.target.value }) }} />
                                                <label class="form-check-label" for="inlineRadio2">Non-licensed</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {FacTypeData?.['License Number']['required'] === true &&


                                <div className="row align-items-center mb-30">
                                    {
                                        state.permissionStatus =='1' ?
                                            <>
                                                <div className="col-md-3">
                                                    <span className="mat-form-field-label-wrapper">
                                                        <label>License Number</label>
                                                    </span>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group mb-0 inputform_style">
                                                        <div className="mat-form-field-wrapper">
                                                            <TextValidator name="license_number" className="w-100"
                                                                onChange={(e) => { handleChange(e) }} value={state.license_number}
                                                                validators={['required']}
                                                                errorMessages={['This field is required']}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </> : ''  
                                    }
                                    {/* <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>License Number</label>
                                    </span>
                                </div> */}

                                </div>
                            }
                            {FacTypeData?.['License Awarded From']['required'] === true &&
                           state.permissionStatus == '1' ?
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>License Awarded</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="mat-form-field-wrapper">
                                                {/* <DatePicker className="w-100" ref={reportingData} name="reportingData" /> */}
                                                <ul className="rs_formlist">
                                                    <li>
                                                        <span className="date_formto">From</span>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={1}>
                                                                <DesktopDatePicker
                                                                    inputFormat="MM/DD/YYYY"
                                                                    name="date_awarded_from"
                                                                    value={state.date_awarded_from}
                                                                    // maxDate={moment().toDate()}
                                                                    // onChange={handleDate}
                                                                    onChange={(e) => { setState({ ...state, date_awarded_from: String(e.$d) }) }}
                                                                    // onChange={(e) => { setState({...state, date_awarded_from:e.value }) }} 
                                                                    renderInput={(params) => <TextField {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            placeholder: "DD/MM/YYYY"
                                                                        }}

                                                                    />}

                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                    </li>
                                                    <li>
                                                        <span className="date_formto">To</span>
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Stack spacing={1}>
                                                                <DesktopDatePicker
                                                                    inputFormat="MM/DD/YYYY"
                                                                    // value={Date}
                                                                    value={state.date_awarded_to}
                                                                    // maxDate={moment().toDate()}
                                                                    // onChange={handleDateValue}
                                                                    onChange={(e) => { setState({ ...state, date_awarded_to: String(e.$d) }) }}
                                                                    // onChange={(e) => { setState({...state, date_awarded_to:e.value }) }} 
                                                                    renderInput={(params) => <TextField {...params}
                                                                        inputProps={{
                                                                            ...params.inputProps,
                                                                            placeholder: "DD/MM/YYYY"

                                                                        }}

                                                                    />}

                                                                />
                                                            </Stack>
                                                        </LocalizationProvider>
                                                    </li>
                                                </ul>
                                                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Stack spacing={1}>
                                                <DesktopDatePicker                                                   
                                                    inputFormat="DD/MM/YYYY"
                                                    value={value}
                                                    onChange={handleDate}
                                                    renderInput={(params) => <TextField {...params} />}
                                                    />                                                 
                                                </Stack>
                                            </LocalizationProvider> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>: ''
                            }

       

                        

                            {FacTypeData?.['Operating hours']['required'] === true &&
                                <div className="row align-items-center mb-30" >
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Operating hours</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style multidropdown">
                                            <div className="mat-form-field-wrapper">

                                                <Select
                                                    name="Operating hours"
                                                    value={state.operating_hours}
                                                    placeholder=''
                                                    onChange={(e) => { setState({ ...state, operating_hours: e.target.value }) }}


                                                >
                                                    {
                                                        props.facilityData?.map(item => {
                                                            return (
                                                                <MenuItem key={item._id} value={item.label}>{item.label}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {FacTypeData?.['Number of working days']['required'] === true &&
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Number of working days</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style">
                                            <div className="mat-form-field-wrapper">
                                                <TextValidator InputProps={{  inputProps: {   min: 0 } }} name="no_working_days" type='number' className="w-100"
                                                    onChange={(e) => { handleChange(e) }}
                                                    value={state.no_working_days}
                                                // validators={['required']}
                                                // errorMessages={['This field is required']}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {FacTypeData?.['Available services']['required'] === true &&

                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Available services</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style multidropdown">
                                            <div className="mat-form-field-wrapper">
                                                <Select

                                                    name="Available services"
                                                    value={state.available_services}
                                                    placeholder=''
                                                    onChange={(e) => { setState({ ...state, available_services: e.target.value }) }}


                                                >
                                                    {
                                                        props.availableData?.map(item => {
                                                            return (
                                                                <MenuItem key={item._id} value={item.label}>{item.label}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>

                                                {/* <Select
                                                   
                                                    multiple
                                                    value={personName}
                                                    onChange={(e) => { handleChange1(e) }}  
                                                    input={<OutlinedInput label="" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    >
                                                    {names.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                    </Select> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {/* {FacTypeData?.['Operating temperature']['required']===true && 
                              <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Operating temperature</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style multidropdown">
                                        <div className="mat-form-field-wrapper">

                                        {/* <Select
                                            name="operating_temperature"
                                            value={{label: state.operating_temperature}}
                                            options={props.facilityData?.operating_temperature}
                                            placeholder=''
                                            onChange={(e) => {setState({...state, operating_temperature:e.value }) }} 
                                        
                                            validators={['required']}
                                            errorMessages={['This field is required']}
                                           
                                        /> */}
                            {/* </div>
                                    </div>
                                </div>
                            </div> } */}
                            {FacTypeData?.['Operating temperature']['required'] === true &&
                                <div className="row align-items-center mb-30">
                                    <div className="col-md-3">
                                        <span className="mat-form-field-label-wrapper">
                                            <label>Operating Temperature</label>
                                        </span>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group mb-0 inputform_style multidropdown">
                                            <div className="mat-form-field-wrapper">
                                                <Select

                                                    name="operating_temperature"
                                                    value={state.operating_temperature}
                                                    placeholder=''
                                                    onChange={(e) => { setState({ ...state, operating_temperature: e.target.value }) }}


                                                >
                                                    {
                                                        props.operatingtemeperatureData?.map(item => {
                                                            return (
                                                                <MenuItem key={item._id} value={item.label}>{item.label}
                                                                </MenuItem>
                                                            )
                                                        })
                                                    }
                                                </Select>

                                                {/* <Select
                                                   
                                                    multiple
                                                    value={personName}
                                                    onChange={(e) => { handleChange1(e) }}  
                                                    input={<OutlinedInput label="" />}
                                                    renderValue={(selected) => selected.join(', ')}
                                                    MenuProps={MenuProps}
                                                    >
                                                    {names.map((name) => (
                                                        <MenuItem key={name} value={name}>
                                                        <Checkbox checked={personName.indexOf(name) > -1} />
                                                        <ListItemText primary={name} />
                                                        </MenuItem>
                                                    ))}
                                                    </Select> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            {/* } 

                            
                            {/* <div className="row mb-30">
                                <div className="col-md-3 pt-2">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Description</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                        <TextValidator  name="description" multiline rows={3} className="w-100" 
                                        onChange={(e) => { handleChange(e) }}  value={state.description}
                                        validators={['required']}
                                        errorMessages={['This field is required']}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div> */}

                            {/* <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Facility Status</label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 radioform_style">
                                        <div className="mat-form-field-wrapper">
                                            <FormControl>
                                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" defaultValue={getTreeData.default?'legal':'illegal'} name="row-radio-buttons-group"
                                                >
                                                    <FormControlLabel value="legal" control={<Radio id="inlineRadio1" name="prmStatus" />} label="Legal"  />
                                                    <FormControlLabel value="illegal" control={<Radio id="inlineRadio2" name="prmStatus" />} label="Illegal" />                                                
                                                </RadioGroup>
                                            </FormControl>
                                        </div>
                                    </div>
                                </div>
                            </div>     */}

                            {/* <div className="row align-items-center mb-30">
                                <div className="col-md-3">
                                    <span className="mat-form-field-label-wrapper">
                                        <label>Area <span className="text-muted">(sq. meter)</span></label>
                                    </span>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group mb-0 inputform_style">
                                        <div className="mat-form-field-wrapper">
                                        <TextValidator  name="area_sq_mtr" multiline rows={3} className="w-100" 
                                                 onChange={(e) => { handleChange(e) }}  value={state.area_sq_mtr}
                                                 validators={['required']}
                                                 errorMessages={['This field is required']}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div> */}


                        </div>
                        <div className="gallery-sidebar-button text-center new_gallery-sidebar-button mt-0">
                            <div className="d-flex align-items-center justify-content-center">
                                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                                <Button variant="contained" className="addbtn" type="submit">
                                    {props.isEdit ? 'Update' : 'Add'}
                                </Button>
                            </div>
                        </div>
                        {/* <form onSubmit={onClickSubmit} className="data_entry_form" id="dataEntryForm"> */}
                        {/* <div className="col-lg-12">
                                <div className="row">
                                    <div className="col-lg-12">

                                        <div className="bg-white form-data-box">

                                            <div class="row">
                                                <div className="col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Area</label>
                                                        <div class="dropdown treeDropDown">
                                                            <input ref={areaInputRef} name="areaId" type="hidden" value={getTreeData != null && getTreeData.area_code} readOnly className="form-control dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placeholder="Seclect Area" />
                                                            <input   value={getTreeData != null && getTreeData.name} readOnly className="form-control dropdown-toggle" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" placeholder="Seclect Area" />


                                                            <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                                                <input type='text' placeholder="search" className="form-control" />
                                                                {areaTree.map((item, index) => {
                                                                    return (
                                                                        <>
                                                                            <details className="level1">
                                                                                <summary onClick={() => { setTreeData({ 'name': item.name, 'level': item.level, 'area_code': item.area_code, 'id': item._id }) }}>{item.name}</summary>
                                                                                <ul>
                                                                                    {item.areaSubData.map((item2, index) => {

                                                                                        return (
                                                                                            <>
                                                                                                <li onClick={() => { setTreeData({ 'name': item2.name, 'level': item2.level, 'area_code': item2.area_code, 'id': item2._id }) }}>{item2.name}</li>
                                                                                            </>
                                                                                        )



                                                                                    })}
                                                                                </ul>

                                                                            </details>
                                                                        </>
                                                                    )
                                                                })}

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Dump Site Name</label>
                                                        <input ref={siteNameRef} name="dumpSiteName" 
                                                        value={props.editData[0] 
                                                            && props.editData[0]!=null 
                                                            && props.editData[0]!='' 
                                                            && props.editData[0].site_name} 
                                                        className="form-control" type="text" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <label>Location</label>
                                        <div className="bg-light form-data-box">

                                            <div class="row">
                                                <div className="col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Latitude(In Decimal)</label>
                                                        <input ref={lattitudeRef} name="lattitudeRef" type="text" className="form-control" />

                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Longitude(In Decimal)</label>
                                                        <input type="text" ref={longitudeRef} name="longitudeRef" className="form-control" />

                                                    </div>
                                                </div>
                                            </div>

                                        </div>


                                        <div className="bg-white form-data-box">

                                            <div class="row">
                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Description</label>
                                                        <textarea ref={descRef} name="descRef" className="form-control "></textarea>

                                                    </div>
                                                </div>


                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Waste Quantity <span className="text-mute">(in Tonne/s)</span></label>
                                                        <input ref={wasteQtyRef} name="wasteQtyRef" type="number" className="form-control" />
                                                    </div>
                                                </div>


                                                <div className="col-lg-12 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Permission Status: </label>
                                                        <div class="form-check form-check-inline pl-3">
                                                            <input name="prmStatus" class="form-check-input" type="radio" id="inlineRadio1" value="1" />
                                                            <label class="form-check-label" for="inlineRadio1">YES</label>
                                                        </div>

                                                        <div class="form-check form-check-inline">
                                                            <input name="prmStatus" class="form-check-input" type="radio" id="inlineRadio2" value="0" />
                                                            <label class="form-check-label" for="inlineRadio2">NO</label>
                                                        </div>


                                                    </div>
                                                </div>


                                                <div className="col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Reporting Date</label>
                                                        <DatePicker ref={reportingData} name="reportingData" className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-sm-12">
                                                    <div className="form-group">
                                                        <label>Type Of Waste</label>
                                                        <Select
                                                            ref={wateTypeRef}
                                                        
                                                            name="wateTypeRef"
                                                            options={wasteTypes}
                                                            isMulti={true}
                                                        />
                                                    </div>
                                                </div>


                                                <div className="col-lg-12">
                                                    <div className="row">
                                                        <div className="col-lg-10">
                                                        <div className="form-group filegroup">
                                                        <input name="dumpFile" type="file" class="form-control " onChange={selectFile} id="customFile" />
                                                        </div>
                                                        </div>
                                                        <div className="col-lg-2">
                                                        <img className="rounded img-fluid mr-auto w-100 ml-auto" id="renderImg" style={{ width: "100%" }} src={cropData} alt="cropped" />

                                                        </div>
                                                    </div>
                                                
                                                </div>




                                            </div>

                                        </div>


                                        <label>Waste Picker</label>
                                        <div className="form-next-box">
                                            <div class="col-lg-12 col-sm-12">
                                                {wpRow && wpRow.length && <span>Total Waste Picker {wpRow.length}</span>}
                                                {wpRow && wpRow.length > 0 && wpRow.map((item, index) => {

                                                    return (
                                                        <>


                                                            <div class="map_box row bg-light mb-4 pt-3 pb-4">
                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Wate Picker Name</label>
                                                                        <input ref={wastepickerNameRef} name="wastePicker" type="text" className="form-control" />

                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Allocated Week Days</label>
                                                                        <Select
                                                                            onChange={(e)=>getWeekDay(e,index)}
                                                                            components={animatedComponents}
                                                                            ref={weekDaysRef}
                                                                            name="weekDaysRef"
                                                                            options={daysOption}
                                                                            isMulti={true}

                                                                        />

                                                                    </div>
                                                                </div>


                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Type Of Waste</label>
                                                                        <Select
                                                                        onChange={(e)=>getPikWaste(e, index)}
                                                                            ref={PickWasteTypeRef}
                                                                            name="PickWasteTypeRef"
                                                                            options={wasteTypes}
                                                                            isMulti={true}
                                                                        />

                                                                    </div>
                                                                </div>


                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Waste Quantity (IN TONNE/s)</label>
                                                                        <input ref={pickQtyRef} name="pickQtyRef" className="form-control" type="number" />

                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-12 text-right">
                                                                    {index > 0 &&
                                                                        <button className="btn btn-danger remBtn btn-sm" type="button" onClick={(e) => removeAddonRow(index)} style={{ 'borderRadius': '50%' }}><i className="fa fa-times"></i></button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </>
                                                    )

                                                })}

                                                <div className="col-lg-12 col-sm-12 text-right mb-2" >
                                                    <span>ADD NEW WASTE PICKER </span> <button type="button" onClick={addMoreWastePicker} className="btn btn-danger btn-round"><i className="fa fa-add"></i></button>

                                                </div>

                                            </div>
                                        </div>






                                         <label className="mt-4">Vehicle Registration</label>
                                        <div className="form-next-box mt-3">
                                            <div class="col-lg-12 col-sm-12">
                                                {vhRow && vhRow.length && <span>Total Vehicle Added {vhRow.length}</span>}
                                                {vhRow && vhRow.length > 0 && vhRow.map((item, index) => {

                                                    return (
                                                        <>
                                                            <div class="map_box row bg-light mb-4 pt-3 pb-4 p">

                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Vehicle Registration Number</label>
                                                                        <input ref={vchRegNoRef} name="vchRegNoRef" type="text" className="form-control" />

                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Vehicle Role</label>
                                                                        <Select
                                                                            components={animatedComponents}
                                                                            ref={vchRoleRef} name="vchRoleRef"
                                                                            options={[{ label: "Carry In", value: "Carry In" },
                                                                            { label: "Carry Out", value: "Carry Out" },
                                                                            { label: "Carry In/Out", value: "Carry In/Out" }]}
                                                                        />

                                                                    </div>
                                                                </div>


                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Type Of Waste</label>
                                                                        <Select
                                                                            ref={vchWasteRef}
                                                                            name="lattitude"
                                                                            options={vchWasteRef}
                                                                            isMulti={true}
                                                                        />

                                                                    </div>
                                                                </div>


                                                                <div className="col-lg-6 col-sm-12">
                                                                    <div className="form-group">
                                                                        <label>Waste Quantity (IN TONNE/s)</label>
                                                                        <input ref={vchWasteQtyRef} name="vchWasteQtyRef" className="form-control" type="number" />

                                                                    </div>
                                                                </div>

                                                                <div className="col-lg-12 text-right">
                                                                    {index > 0 &&
                                                                        <button type="button" className="btn btn-danger remBtn btn-sm" onClick={(e) => removeVHRow(index)} style={{ 'borderRadius': '50%' }}><i className="fa fa-times"></i></button>
                                                                    }
                                                                </div>






                                                            </div>


                                                        </>
                                                    )

                                                })}

                                                <div className="col-lg-12 col-sm-12 text-right mt-2 mb-1">
                                                    <span>ADD NEW VEHICLE REGISTRATION </span> <button type="button" onClick={addMoreVR} className="btn btn-danger btn-round"><i className="fa fa-add"></i></button>

                                                </div>

                                            </div>
                                        </div> 

                                        <div className="col-lg-12 col-sm-12 text-center pt-5 pb-5">
                                            <button type="submit" className="mr-auto ml-auto btn btn-danger mdsubmitBtn">Add</button>
                                        </div>

                                    </div>
                                </div>


                            </div> */}
                        {/* </form> */}




                    </Stack>
                </ValidatorForm>

            </div>



            {/* cropper modal */}

            <Modal show={cropModal} onHide={hideTriggerModal} className="cropperModal">
                <Modal.Header closeButton>
                    <Modal.Title>Crop Image</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-lg-12">
                                <Cropper
                                    style={{ height: 400, width: "100%" }}
                                    zoomTo={0.5}
                                    initialAspectRatio={1}

                                    src={image}
                                    viewMode={1}
                                    minCropBoxHeight={10}
                                    minCropBoxWidth={10}
                                    background={false}
                                    responsive={true}
                                    autoCropArea={1}
                                    checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                    onInitialized={(instance) => {
                                        setCropper(instance);
                                    }}
                                    guides={true}
                                />


                            </div>

                            <div className="col-lg-12 text-center ">



                                <button className="btn btn-danger btn-medium mt-3 mr-auto ml-auto w-100" style={{ float: "right" }} onClick={getCropData}>
                                    Crop & Render
                                </button>
                            </div>
                        </div>

                    </div>



                </Modal.Body>

            </Modal>



        </>


    );


}


export default DumpSiteModal