import React from 'react'
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom'
import { Tooltip } from '@material-ui/core'

function Sidebar() {
  const location = useLocation();
  const route = location.pathname;

  const userData = JSON.parse(localStorage.getItem('userInfo'));
 const role =  userData['role'];


  return (
    <>


<section className='sidebar_area'>
  <div className="inner_siderbar">
  {role==1 &&   <ul >

      <Link  to="/admin/summary" ><Tooltip title="Summary" placement="right" arrow><li className={route!='/admin/summary'? '':'active_bg'}><img src="assets/lib/images/sidebars/summary.svg"/></li></Tooltip></Link>
      <Link to="/admin/import-area"><Tooltip title="Manage Area" placement="right" arrow><li className={route!='/admin/import-area'? '':'active_bg'}><img src="assets/lib/images/sidebars/area.svg"/></li></Tooltip></Link>
      <Link to="/admin/manage-indicator"><Tooltip title="Manage Indicator" placement="right" arrow><li className={route!='/admin/manage-indicator'? '':'active_bg'}><img src="assets/lib/images/sidebars/manage-indi.svg"/></li></Tooltip></Link>
      <Link to="/admin/import-metadata"><Tooltip title="Manage Metadata" placement="right" arrow><li className={route!='/admin/import-metadata'? '':'active_bg'}><img src="assets/lib/images/sidebars/indi-metadata.svg"/></li></Tooltip></Link>
     <Link to="/admin/data-entry"><Tooltip title="Manage Data Entry" placement="right" arrow><li className={route!='/admin/data-entry'? '':'active_bg'}><img src="assets/lib/images/sidebars/data-entry.svg"/></li></Tooltip></Link>
      <Link to="/admin/data-approve"> <Tooltip title="Manage Data Approve" placement="right" arrow><li className={route!='/admin/data-approve'? '':'active_bg'} ><img src="assets/lib/images/sidebars/data-approve.svg"/></li></Tooltip></Link>
      <Link to="/admin/app-facility"> <Tooltip title="Facility Management" placement="right" arrow><li className={route!='/admin/app-facility'? '':'active_bg'}><img src="assets/lib/images/sidebars/dump-site.svg"/></li></Tooltip></Link>
      <Link to="/admin/manage-resource"> <Tooltip title="Manage Resource" placement="right" arrow><li className={route!='/admin/manage-resource'? '':'active_bg'}><img src="assets/lib/images/sidebars/data-resources.svg"/></li></Tooltip></Link>
      <Link to="/admin/master-list"> <Tooltip title="Manage Master List" placement="right" arrow><li className={route!='/admin/master-list'? '':'active_bg'}><img src="assets/lib/images/sidebars/masterlist.svg"/></li></Tooltip></Link>
      <Link to="/admin/user-management"> <Tooltip title="User" placement="right" arrow><li className={route!='/admin/user-management'? '':'active_bg'}><img src="assets/lib/images/sidebars/user.svg"/></li></Tooltip></Link>
      <Link to="/admin/manage-actor"> <Tooltip title="Manage Actor" placement="right" arrow><li className={route!='/admin/manage-actor'? '':'active_bg'}><img src="assets/lib/images/sidebars/actor.svg"/></li></Tooltip></Link>
      <Link to="/admin/manage-activity"> <Tooltip title="Manage Activity" placement="right" arrow><li className={route!='/admin/manage-activity'? '':'active_bg'}><img src="assets/lib/images/sidebars/activity-manage.svg"/></li></Tooltip></Link>
      <Link to="/admin/generate-report"> <Tooltip title="Generate Report" placement="right" arrow><li className={route!='/admin/generate-report'? '':'active_bg'}><img src="assets/lib/images/sidebars/report.svg"/></li></Tooltip></Link>
      <Link to="/admin/import-log"> <Tooltip title="Log" placement="right" arrow><li className={route!='/admin/import-log'? '':'active_bg'}><img src="assets/lib/images/sidebars/manage-resources.svg"/></li></Tooltip></Link>
    
    
      </ul>}

      {role==2  &&   <ul >
        
<Link to="/admin/data-entry"><Tooltip title="Manage Data Entry" placement="right" arrow><li className={route!='/admin/data-entry'? '':'active_bg'}><img src="assets/lib/images/sidebars/data-entry.svg"/></li></Tooltip></Link>
<Link to="/admin/import-log"> <Tooltip title="Log" placement="right" arrow><li className={route!='/admin/import-log'? '':'active_bg'}><img src="assets/lib/images/sidebars/manage-resources.svg"/></li></Tooltip></Link>


</ul>}


{role==4 &&  <ul >
  <Link to="/admin/data-approve"> <Tooltip title="Manage Data Approve" placement="right" arrow><li className={route!='/admin/data-approve'? '':'active_bg'} ><img src="assets/lib/images/sidebars/data-approve.svg"/></li></Tooltip></Link>
</ul>}
  </div>
</section>

      {/* <aside  className="main-sidebar admin_side ng-star-inserted" id="sidebar-coll" style={{'backgroundColor': '#222d32' }}>

        <div  className="navigation intro" id="test">

          <ul  className="d-none" style={{'display': 'none'}}>

            <li  className="dropdown main-drop open ng-star-inserted">

              <ul  className="dropdown-menu" id="not-clicked" role="menu">

                <li  className=" animated fadeInDown"  >
                  <Link to='/admin/summary'>
                  <span  className="drop-child-icon">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/first.svg" title="Summary" />

                  </span>

                  <span className="text-capitalize">

                    Summary</span>
                </Link>
                </li>

                <li  className=" animated fadeInDown"  >
                <Link to='/admin/import-area'>
                  <span  className="drop-child-icon">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/second.svg" title="Manage Area" />

                  </span>

                  <span className="text-capitalize">

                    Area</span>
                  </Link>
                </li>

                <li  className=" animated fadeInDown"  >
                <Link to='/admin/manage-indicator'>
                  <span  className="drop-child-icon ">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/third.svg" title="Manage Indicator" />

                  </span>

                  <span className="text-capitalize">

                    Indicators</span>
                    </Link>

                </li>

                <li  className=" animated fadeInDown"  >

                <Link to=''>

                  <span  className="drop-child-icon ">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/fourth.svg" title="Data-Entry" /> 
 
                  </span>

                  <span className="text-capitalize">

                    Data Import</span>

                    </Link>

                </li>

                
                <li  className=" animated fadeInDown"  >
                <Link to='/admin/import-metadata'>
                  <span  className="drop-child-icon">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/second.svg" title="Manage Indicator Metadata" />

                  </span>

                  <span className="text-capitalize">

                    Area</span>
                  </Link>
                </li>




                <li  className=" animated fadeInDown"  >
                <Link to=''>

                  <span  className="drop-child-icon ">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/fifth.svg" title="Manage Indicator Metadata" />

                  </span>

                  <span className="text-capitalize">

                    Resources</span>

                    </Link>

                </li>

                <li  className=" animated fadeInDown"  >
                <Link to=''>

                  <span  className="drop-child-icon ">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/sixth.svg" title="Manage Resources" />

                  </span>

                  <span className="text-capitalize">

                    Indicators <br  />

                      Metadata</span>

                      </Link>

                </li>

                <li  className=" animated fadeInDown"  >
                <Link to=''>
                  <span  className="drop-child-icon">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/seventh.svg" title="Manage Users" />

                  </span>

                  <span className="text-capitalize">

                    Departments</span>
                 </Link>
                </li>

                <li  className=" animated fadeInDown"  >
                <Link to=''>

                  <span  className="drop-child-icon">

                    <img  className="drop-child-icon" src="assets/lib/images/sidebars/eighth.svg" title="" />

                  </span>

                  <span  className="text-capitalize">

                    Schemes</span>
                </Link>

                </li>

              </ul>

            </li>

          </ul>

          <ul >

            <li  mattooltip="Summary"    className={route!='/summary'? 'ng-star-inserted sidebarList ' : 'ng-star-inserted sidebarList active-link'} aria-describedby="cdk-describedby-message-1" cdk-describedby-host="" >
            <Link to='/admin/summary'>
              <img  src="assets/lib/images/sidebars/first.svg" />

               </Link>

            </li>
            <Link to='/admin/import-area'>
            <li  mattooltip="Manage Area"    className={route!='/import-area'? 'ng-star-inserted sidebarList' : 'ng-star-inserted sidebarList  active-link'} aria-describedby="cdk-describedby-message-2" cdk-describedby-host="" >
            
              <img  src="assets/lib/images/sidebars/second.svg" />

           

            </li> </Link>

            <Link to='/admin/manage-indicator'><li  mattooltip="Manage Indicator"    className={route!='/manage-indicator'? 'ng-star-inserted sidebarList' : 'ng-star-inserted sidebarList  active-link'} aria-describedby="cdk-describedby-message-3" cdk-describedby-host="" >
            
              <img  src="assets/lib/images/sidebars/third.svg" />
          


            </li>  </Link>
            <Link to='/admin/import-metadata'>

            <li  mattooltip="Indicator Metadata"    className="ng-star-inserted sidebarList" aria-describedby="cdk-describedby-message-4" cdk-describedby-host="" >

              <img  src="assets/lib/images/sidebars/meta_new.svg" />



            </li>
            </Link>

            <li  mattooltip="Data Entry"    className="ng-star-inserted sidebarList" aria-describedby="cdk-describedby-message-5" cdk-describedby-host="" >

              <img  src="assets/lib/images/sidebars/fourth.svg" />



            </li>

            <li  mattooltip="Data Approve"    className="ng-star-inserted sidebarList" aria-describedby="cdk-describedby-message-6" cdk-describedby-host="" >

              <img  src="assets/lib/images/sidebars/dataapprove.svg" />



            </li>

            <li  mattooltip="Manage Resource"    className="ng-star-inserted sidebarList" aria-describedby="cdk-describedby-message-7" cdk-describedby-host="" >

              <img  src="assets/lib/images/sidebars/sixth.svg" />

            </li>
            <Link to='/admin/manage-user'>
            <li  mattooltip="Manage User"    className="ng-star-inserted sidebarList" aria-describedby="cdk-describedby-message-8" cdk-describedby-host="" >

              <img  src="assets/lib/images/sidebars/seventh.svg" />

            </li>
            </Link>
            <li  mattooltip="Manage Log"    className="ng-star-inserted sidebarList sidebarList" aria-describedby="cdk-describedby-message-9" cdk-describedby-host="" >

              <img  src="assets/lib/images/sidebars/eighth.svg" />



            </li>

          </ul>

        </div>

      </aside> */}




    </>



  );
}

export default Sidebar;