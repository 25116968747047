import React from 'react';
import lazyLoader from './Common/components/lazy/lazyLoader.js';
// import { Redirect } from 'react-router';
import './Common/custom.js'
import { HashRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import './App.css';
import Area from './admin/pages/area/area';
import Masterlist from './admin/pages/master-list/masterlist';
import DataApprove from './admin/pages/data_approve/data_approve';
import Resources from './admin/pages/manage-resource/resources';
import ManageActivity from './admin/pages/manage-activity/manage-activity';
import Actor from './admin/pages/manage-actor/actor.js';
import Login from './Common/pages/login';
import Indicator from './admin/pages/indicator';
import User from './admin/pages/user';
import Popper from 'popper.js';
import SummaryPage from './admin/pages/summary';
import { ToastContainer } from 'react-toastify';
import DashboardComponent from './dashboard/dashboard.jsx';
import DataEntry from './admin/pages/data-entry/index.js';
import DumpSite from './admin/pages/dump-site/index.js';
import Metadata from './admin/pages/metadata/index.js';
import Generatereport from './admin/pages/generatereport/index.js';
import history from './services/history';
import Log from './admin/pages/log/index.js';
import { store } from './store';
import { Provider } from 'react-redux';
import { login } from './features/loginSlice';
import GeoData from './dashboard/pages/geo-data/geo-data.js';
import HomePage from './dashboard/pages/home/home.js';
import InformationPage from './dashboard/pages/information-kiosk/information-kiosk.js';
import DataanalyticsPage from './dashboard/pages/data-analytics/data-analytics.js';
import ContactPage from './dashboard/pages/contact/contact.js';
import ProfilePage from './dashboard/pages/profile/profile.js';
import TrackerPage from './dashboard/pages/tracker/tracker.js';
import DashboardPage from './dashboard/pages/dashboard/dashboard.js';
import ConfirmEmail from './Common/pages/confirmEmail/ConfirmEmail.js';
import { RolesAuthRoute } from './Common/components/auth/rolesAuthGuard.js';
import ForgotPassword from './Common/pages/forgotPassword/ForgotPassword.js';
import Activity from './dashboard/pages/Activity/activity.js';
import AddActivityModal from './dashboard/pages/Activity/addActivity.modal.js';
// import AdminHomeComponent from './admin/pages/adminHome.jsx';
const AdminHomeComponent = lazyLoader(() => import('./admin/pages/adminHome.jsx'));


function App() {

  // const jsonUser =  JSON.parse(localStorage.getItem('userInfo'));


  const getLoginInfoFrmLocalStorage = () => {
    try {
      const token = localStorage.getItem('token');
      const email = localStorage.getItem('email');
      const userInfo = localStorage.getItem('userInfo');
     
     
      if (token && token != '' && email && email != '' && userInfo && userInfo != '') {
        return {
          email: email,
          token: token,
          userInfo: JSON.parse(userInfo)
        };
      }else{
        // history.push('/home');
        return null;
      }

    }
    catch (e) {
      console.log(e)
    }
  }

  const loginInfo = getLoginInfoFrmLocalStorage()
  if (loginInfo) {
    store.dispatch(login(loginInfo));
  }




  return (
    <Provider store={store}>
      <Router history={history}>
        <div className="App">

          <Routes>
            <Route path='/' element={<Navigate to="/home" />} />
            <Route path='/login' element={<Login />} />
            <Route path='/forgetPassword' element={<ForgotPassword />}/>
            <Route path='/confirmEmail' element={<ConfirmEmail />} />
            <Route path='/home' element={<HomePage />} />
            <Route path='/information-kiosk' element={<InformationPage/>} />
            <Route path='/contact' element={<ContactPage/>} />
            <Route path='/data-analytics' element={<DataanalyticsPage/>} />

            <Route path='/admin' element={
              <RolesAuthRoute roles={[1,2,4]}>
                <AdminHomeComponent />
              </RolesAuthRoute>   
              
              
              } >
              <Route path='Summary' element= {<RolesAuthRoute roles={[1,2]}><SummaryPage /></RolesAuthRoute>} />
              <Route path='import-area' element={<RolesAuthRoute roles={[1]}><Area /></RolesAuthRoute>} />
              <Route path='manage-indicator' element={<RolesAuthRoute roles={[1]}><Indicator /></RolesAuthRoute>} />
              <Route path='import-metadata' element={<RolesAuthRoute roles={[1]}><Metadata /></RolesAuthRoute>} />
              <Route path='user-management' element={<RolesAuthRoute roles={[1]}><User/></RolesAuthRoute>} />
              <Route path='data-entry' element={<RolesAuthRoute roles={[2,1]}><DataEntry/></RolesAuthRoute>} />
              <Route path='data-approve' element={<RolesAuthRoute roles={[4,1]}><DataApprove/></RolesAuthRoute>} />
              <Route path='app-facility' element={<RolesAuthRoute roles={[1]}><DumpSite/></RolesAuthRoute>} />
              <Route path='manage-resource' element={<RolesAuthRoute roles={[1]}><Resources/></RolesAuthRoute>} />
              <Route path='master-list' element={<RolesAuthRoute roles={[1]}><Masterlist/></RolesAuthRoute>} />
              <Route path='generate-report' element={<RolesAuthRoute roles={[1]}><Generatereport/></RolesAuthRoute>} />
              <Route path='import-log' element={<RolesAuthRoute roles={[1,2,4]}><Log/></RolesAuthRoute>} />
              <Route path='manage-actor' element={<RolesAuthRoute roles={[1]}><Actor/></RolesAuthRoute>} />
              <Route path='manage-activity' element={<RolesAuthRoute roles={[1]}><ManageActivity/></RolesAuthRoute>} />
            </Route>

            {/* dahboard routes */}

            <Route path='/dashboard' element={
                <RolesAuthRoute roles={[1,2,4,5]}>
                  <DashboardComponent /> 
                </RolesAuthRoute>                              
              }>
              <Route path='dashboard' element={<DashboardPage />} />
              <Route path='tracker' element={<TrackerPage />} />
              <Route path='activity' element={<Activity />} />
             <Route path='profile' element={ <RolesAuthRoute roles={[5]}><ProfilePage /></RolesAuthRoute>}  />              
              
            </Route>


            <Route path='*' element={<HomePage />} />
          </Routes>

        </div>

        <ToastContainer />
      </Router>
    </Provider>

  );
}

export default App;
