import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import * as constants from '../../../Config/app.constants';

import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import AdminService from '../../../services/admin.service';

import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';


import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import Slider from '@mui/material/Slider';

import Checkbox from '@mui/material/Checkbox';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import ProgressBar from 'react-bootstrap/ProgressBar';
import { json, useLocation, useNavigate, useParams } from 'react-router';
import swal from 'sweetalert';
import { useDispatch } from 'react-redux';
import Dropdown from 'react-bootstrap/Dropdown';
import TreeView from '@mui/lab/TreeView';
import TreeItem, { useTreeItem } from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { isArray } from 'lodash';
import { setUserName } from '../../../features/usersName';
import history from '../../../services/history';
import CheckboxTree from 'react-checkbox-tree';


// =========================================================================== Treeview Start
import clsx from 'clsx';
import { Link } from 'react-router-dom';
// const CustomContent = React.forwardRef(function CustomContent(props, ref) {
//     const {
//         classes,
//         className,
//         label,
//         nodeId,
//         icon: iconProp,
//         expansionIcon,
//         displayIcon,
//     } = props;

//     const {
//         disabled,
//         expanded,
//         selected,
//         focused,
//         handleExpansion,
//         handleSelection,
//         preventSelection,
//     } = useTreeItem(nodeId);

//     const icon = iconProp || expansionIcon || displayIcon;

//     const handleMouseDown = (event) => {
//         preventSelection(event);
//     };

//     const handleExpansionClick = (event) => {
//         handleExpansion(event);
//     };

//     const handleSelectionClick = (event) => {
//         handleSelection(event);
//     };

//     return (
//         <div
//             className={clsx(className, classes.root, {
//                 [classes.expanded]: expanded,
//                 [classes.selected]: selected,
//                 [classes.focused]: focused,
//                 [classes.disabled]: disabled,
//             })}
//             onMouseDown={handleMouseDown}
//             ref={ref}
//         >
//             <div onClick={handleExpansionClick} className={classes.iconContainer}>
//                 {icon}
//             </div>
//             <Typography
//                 onClick={handleSelectionClick}
//                 component="div"
//                 className={classes.label}


//             >
//                 {label}
//             </Typography>
//         </div>
//     );
// });

// CustomContent.propTypes = {
//     /**
//      * Override or extend the styles applied to the component.
//      */
//     classes: PropTypes.object.isRequired,
//     className: PropTypes.string,
//     displayIcon: PropTypes.node,
//     expansionIcon: PropTypes.node,
//     key: PropTypes.node,
//     icon: PropTypes.node,
//     label: PropTypes.node,
//     nodeId: PropTypes.string.isRequired,
//     onClick: PropTypes.node




// };

// function CustomTreeItem(props) {
//     return <TreeItem ContentComponent={CustomContent} {...props} />;
// }

// =========================================================================== Treeview End

export default function ProfileForm(props) {


    const [date, setDate] = useState({


        awTo: {
            "Collector_awTo": null,
            "Recycler/reclaimer_awTo": null,
            "Importer_awTo": null,
            "Exporter_awTo": null,
            "Disposer_awTo": null,
            "Remediator_awTo": null,

        },

        awFrom: {
            "Collector_awFrom": null,
            "Recycler/reclaimer_awFrom": null,
            "Importer_awFrom": null,
            "Exporter_awFrom": null,
            "Remediator_awFrom": null,

        },

        dor: dayjs('')
    });


    const [mainPercen, setPercen] = useState()
    const [countryList, setCountryList] = useState([])
    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);
    const [, forceUpdate] = useState()
    const [isExist, setExist] = useState(false)
    const [loader, setLoader] = useState(false)
    const form = useRef()
    const [mainTreeData, setMainTreeData] = useState([]);
    const [checkSet, setCheckSet] = useState([]);
    const [areaData, setParentAreaData] = useState([])
    const [flatTree, setFlatTree] = useState([]);
    const [valChecker, setChecker] = useState({



        "Name": false,
        "Phone Number": false,
        "Email ID": false,
        "Type of waste": false,
        "Location": false,
        "Generation rate (Tonnes/month)": false,
        "Collection Vehicle": false,
        // "Capacity": false,
        "Frequency": false,
        "Facility Type": false,
        "Waste licence No.": false,
        "License Awarded From (Date)": false,
        "License Awarded To (Date)": false,
        "Destination": false,
        "Origin": false,
        "Date of registration": false,




    });

    const [invalid, setInvalid] = useState('')
    const [invalid2, setInvalid2] = useState('')
    const [completion, setCompletion] = useState('')
    const [FormState, setFormState] = useState(
        {
            // step 1
            typeOfProfile: [],

            name: '',
            phone: '',
            email: '',
            location: '',
            areaName: '',
            wasteTypes: [],
            genRate: '',
            colVehicle: [],
            // capacity: '',
            frequency: '',
            facilityType: [],
            watseLicNo: '',
            colVech: [],
            destination: '',
            origin: '',
            newWaste_types: [],
            actorType: [],
            actorList: [],
            facilityList: [],
            freqList: [],

            // dates
            awFrom: {
                "Collector_awFrom": null,
                "Recycler/reclaimer_awFrom": null,
                "Importer_awFrom": null,
                "Exporter_awFrom": null,
                "Disposer_awFrom": null,

            },
            awTo: {
                "Collector_awTo": '',
                "Recycler/reclaimer_awTo": null,
                "Importer_awTo": null,
                "Exporter_awTo": null,
                "Disposer_awTo": null,

            },
            dor: null,
            licenceData: {
                "Collector_licence": '',
                "Recycler/reclaimer_licence": "",
                "Importer_licence": "",
                "Exporter_licence": "",
                "Disposer_licence": "",

                "Remediator_licence": ""
            }




        });






    const renderTreeW = (nodes) => {

        return (
            <>
                {
                    nodes.map((item) => {

                        let classes = '';
                        let isMulti = '';
                        let isExist = null;
                        if (FormState.newWaste_types?.length > 0) {
                            isExist = FormState.newWaste_types?.filter(dt => {
                                return dt === item.label;
                            })
                        }

                        if (isExist?.[0] === item.label) {
                            isMulti = 'multi'
                        }

                        if (item.areaSubData?.length < 1) {
                            classes = 'no-child  ' + isMulti
                        }
                        else if (item.areaSubData?.length > 0) {
                            classes = 'has-child  ' + isMulti
                        }

                        return (

                            <TreeItem
                                className={classes}
                                key={item.label} nodeId={item.label} label={item.label}
                                onClick={() => { selectMultiW(item.label) }}
                            >

                                {item.areaSubData != null && Array.isArray(item.areaSubData) && item.areaSubData?.length > 0
                                    ? renderTreeW(item.areaSubData)
                                    : <></>}
                            </TreeItem>

                        );


                    })

                }
            </>
        )


    };

    const selectMultiW = (item) => {


        let arrayDT = FormState.wasteTypes;
        let index = -1
        //   arrayDT?.forEach((x,i)=>{
        //     if(x == item){index = i}
        //   })
        arrayDT?.map((x, i) => {
            if (x == item) { index = i }
            return x;
        })


        if (index != -1) {
            arrayDT?.splice(index, 1);
            setFormState({
                ...FormState,
                wasteTypes: arrayDT
            });

        }
        else {
            arrayDT.push(item);
            setFormState({
                ...FormState,
                wasteTypes: arrayDT
            });

        }

        setInvalid2('')
    }

    const updateCompletion = (data) => {
        let completion = 0;
        // let percentageAmount= 9.09090909091;








        let stepKeys = [];
        let mainObj = Object.keys(data).length > 0 ? data : valChecker;




        for (const [key, value] of Object.entries(mainObj)) {
            console.log('stepKeys', key)
            if (value == true) {
                if (key == 'Name') {
                    stepKeys.push('name')
                }
                if (key == 'Location') {
                    stepKeys.push('location')
                }
                if (key == 'Phone Number') {
                    stepKeys.push('phone')
                }
                if (key == 'Email ID') {
                    stepKeys.push('email')
                }
                if (key == 'Type of waste') {
                    stepKeys.push('wasteTypes')
                }
                if (key == 'Generation rate (Tonnes/month)') {
                    stepKeys.push('genRate')
                }
                if (key == 'Collection Vehicle') {
                    stepKeys.push('colVech')
                }
                // if (key == 'Capacity') {
                //     stepKeys.push('capacity')
                // }
                if (key == 'Frequency') {
                    stepKeys.push('frequency')
                }
                if (key == 'Facility Type') {
                    stepKeys.push('facilityType')
                }

                if (key == 'Destination') {
                    stepKeys.push('destination')
                }
                if (key == 'Origin') {
                    stepKeys.push('origin')
                }






            }

        }


        for (const [key, value] of Object.entries(FormState.licenceData)) {

            FormState.actorType?.forEach(item => {
                var kywn = key.replace('_licence', '')
                if (kywn == item) {



                    stepKeys.push(kywn + '_licence')
                    stepKeys.push(kywn + '_awFrom')
                    stepKeys.push(kywn + '_awTo')
                }

            })
        }



        console.log('stepKeys', stepKeys)
        let percentageAmount = 100 / parseInt(stepKeys?.length);

        // const stepKeys = ['name', "phone", "email", "location", "genRate", "colVehicle", "capacity", "frequency", "wasteLicNo", "destination", "origin"];

        console.log('FormStatestepKeys', FormState)
        stepKeys.forEach(key => {
            if (FormState != null && (key in FormState && (FormState[key] != '' && FormState[key] != null && FormState[key] != 'null')
                || (FormState?.licenceData != null && key in FormState?.licenceData && (FormState?.licenceData?.[key] != '' && FormState?.licenceData?.[key] != null && FormState?.licenceData?.[key] != 'null'))
                || (FormState?.awFrom != null && key in FormState?.awFrom && (FormState?.awFrom[key] != '' && FormState?.awFrom?.[key] != null && FormState?.awFrom?.[key] != 'null'))
                || (FormState?.awTo != null && key in FormState?.awTo && (FormState?.awTo?.[key] != '' && FormState?.awTo?.[key] != null && FormState?.awTo?.[key] != 'null')))) {

                if (FormState?.[key] && typeof FormState?.[key] == 'string' ? FormState?.[key]?.trim() : FormState?.[key] !== '' && FormState?.[key] !== null
                ) {
                    return completion += percentageAmount;
                }
            }
            // else if(FormState.awFrom[key]!= '' && FormState.awFrom[key]!= null && FormState.awFrom[key] != 'null'){
            //     if (FormState.awFrom[key] && typeof FormState.awFrom[key] == 'string' ? FormState.awFrom[key].trim() : FormState.awFrom[key] !== '' && FormState.awFrom[key] !== null) {
            //         return completion += percentageAmount;
            //     }
            // }

            // else if(FormState.awTo[key]!= '' && FormState.awTo[key]!= null && FormState.awTo[key] != 'null'){
            //     if (FormState.awTo[key] && typeof FormState.awTo[key] == 'string' ? FormState.awTo[key].trim() : FormState.awTo[key] !== '' && FormState.awTo[key] !== null) {
            //         return completion += percentageAmount;
            //     }
            // }
        })

        setCompletion(completion);

        console.log('completion', completion)
        props.sendCompletion(completion);
        forceUpdate();
    }


    const setCheckData = (value) => {

        console.log('checkActorFirst', value)
        setCheckSet([]);
        let dataActors = value?.length > 0 ? value : FormState.actorType;

        let finalTypes = [];

        for (const [key, value] of Object.entries(constants.actortype)) {


            dataActors?.forEach(item => {

                if (item === key) {


                    finalTypes.push({ value })
                }
            })

        }

        let FormFeildCheck = [];


        finalTypes.forEach(item => {
            let obj = {};


            obj = item['value'];

            FormFeildCheck.push(obj)

        })

        setCheckSet(FormFeildCheck);


        return FormFeildCheck;


    }


    useEffect(() => {




        let flatLevel = [];
        let areaName = '';
        let data = '';
        let arrayData = [];
        let arrayData2 = []
        let arrayData3 = []
        let arrayData4 = []
        let starter1 = []
        let starter2 = []
        let countries = []


        let awFromDate = null;
        let awToDate = null;
        let DorDate = null;

        let awFromObj = {};
        let awToObj = {};



        const userInfo = localStorage.getItem('userInfo')
        const gData = JSON.parse(userInfo)

        let licenceDataObj = FormState.licenceData;

        var todaay = dayjs().format('DD-MM-YYYY');


        if (props.treeData != '' && props.treeData != null) {

            let flatLevel = [];


            props.treeData.forEach((item) => {


                delete item.areaSubData;

                var obj = {
                    value: item.value,
                    label: item.label,
                    parent: item.parent,
                    level: item.level

                }

                flatLevel.push(item)


            })
            setFlatTree(flatLevel)
            const data = setLevelDataW(flatLevel)

            console.log('flats', flatLevel)
            console.log('flats nested', data)
            setMainTreeData(data);


        }


        const countryData = constants.countryList;

        countryData?.forEach(data => {


            countries.push(data)
        })

        setCountryList(countries)

        if (props.profileData != '' && props.profileData != null) {


            data = props.profileData[0];

            props.sendAreaData?.forEach(item => {

                if (item.area_code == data?.location) {
                    areaName = item.name
                }
            })
            console.log("Mohit data", data?.profile_type);

            console.log('sssss', areaName)



            data?.licenceData?.forEach(item => {


                awFromObj[item.actor_type + '_awFrom'] = item.licence_awarded_from;
                awToObj[item.actor_type + '_awTo'] = item.licence_awarded_to;
                licenceDataObj[item.actor_type + '_licence'] = item.licence_no;


            })




            DorDate = dayjs(data?.date_of_registration).format('DD-MM-YYYY')

            setDate({
                ...date,
                awFrom: awFromObj,
                awTo: awToObj,
                dor: data?.licence_awarded_from != null ? DorDate : null
            });


        }


        if (props.sendAreaData != "" && props.sendAreaData != null) {


            let flatLevel = [];

            props.sendAreaData?.forEach((item) => {
                item.areaSubData = [];

                flatLevel.push(item);
            });


            const data = setAreaLevelData(flatLevel);
            console.log("level1Area", data);

            setParentAreaData(data);
        }




        if (props.masterData != "" && props.masterData != null) {

            props.masterData['Actors']?.map(item => {
                arrayData3.push(item.value)

            })


            props.masterData['Frequency']?.map(item => {
                arrayData4?.push(item.value)

            })

            props.masterData['Facility Type']?.map(item => {
                arrayData?.push(item.value)

            })


            props.masterData['Collection Vehicle']?.map(item => {
                arrayData2?.push(item.value)

            })

            console.log('arrayData2', arrayData2)

        }




        if (props.sendStartData != '' && props.sendStartData != null) {

            starter1 = props.sendStartData?.profile_type?.length > 0 && props.sendStartData?.profile_type?.[0] != '' ? props.sendStartData?.profile_type : [];
            starter2 = props.sendStartData?.waste_type?.length > 0 && props.sendStartData?.waste_type?.[0] != '' ? props.sendStartData?.waste_type : [];




        }


        setFormState({
            ...FormState,
            name: data?.name,
            phone: data?.phone_number,
            email: data?.email_id == '' || data?.email_id == null ? gData?.email : data?.email_id,
            location: data?.location,
            wasteTypes: data?.waste_type?.[0] != '' && data?.waste_type?.length > 0 ? data?.waste_type : starter2,
            genRate: data?.generation_rate,
            areaName: areaName,
            // capacity: data?.capacity,
            frequency: data?.frequency,
            facilityType: data?.facility_type != null ? data?.facility_type : [],
            watseLicNo: data?.waste_licence_no,
            colVech: data?.Collection_Vehicle != null ? data?.Collection_Vehicle : [],
            destination: data?.destination,
            origin: data?.origin,
            actorType: data?.profile_type?.[0] != '' && data?.profile_type?.length > 0 ? data?.profile_type : starter1,
            licenceData: licenceDataObj,
            typeOfProfile: arrayData,
            colVehicle: arrayData2,
            actorList: arrayData3,
            facilityList: arrayData,
            freqList: arrayData4,

            awFrom: awFromObj,
            awTo: awToObj,
            dor: data?.date_of_registration == null || data?.date_of_registration == '' ? todaay : DorDate
        })

        setPercen(data?.profile_percentage)

        setChecked(data?.waste_type?.[0] != '' && data?.waste_type?.length > 0 ? data?.waste_type : starter2)

        let prType = props.sendStartData?.profile_type?.length > 0 && props.sendStartData?.profile_type?.[0] != '' ? props.sendStartData?.profile_type : data?.profile_type;;

        let checkData = setCheckData(prType);


        console.log('dddddd', data?.date_of_registration != null ? DorDate?.$d : null)

        console.log('retData', checkData, props.sendStartData, data?.profile_type)
        if (checkData != null && checkData != '' && checkData?.length > 0) {
            setLoader(true)
            checkFieldShow(checkData)

        }




    }, [props.treeData, props.masterData, props.sendStartData, props.profileData, props.sendAreaData]);







    useEffect(() => {
        let data = {}

        updateCompletion(data);


    }, [FormState])



    const checkFieldShow = (checkData) => {
        setLoader(true)




        let items = [
            "Name", "Phone Number",
            "Email ID",
            "Type of waste",
            "Location",
            "Generation rate (Tonnes/month)",
            "Collection Vehicle",
            // "Capacity",
            "Frequency",
            "Facility Type",
            "Waste licence No.",
            "License Awarded From (Date)",
            "License Awarded To (Date)",
            "Destination",
            "Origin",
            "Date of registration"]


        console.log('firstcheck', checkData)

        let testObj = {}
            ;

        items.forEach(item => {
            if (checkData?.length < 1) {
                return;
            }

            checkData?.map(data => {

                if (data[item]?.['required'] == true) {
                    if (!(item in testObj)) {
                        testObj[item] = true;
                    }
                }

            })

        })

        setChecker(testObj)
        console.log('testObj', testObj)

        if (Object.keys(testObj).length > 0) {

            setLoader(false)
            updateCompletion(testObj);


        }






    }



    const handleMultiSelectChange = (event) => {

        console.log("sddssdsdsdsd", event.target.value);
        const {
            target: { value },
        } = event;

        setFormState({ ...FormState, actorType: value });


        setInvalid('')

    };







    const handleMultiSelectChange2 = (event) => {
        console.log("sddssdsdsdsd", event.target.value);
        const {
            target: { value },
        } = event;

        setFormState({ ...FormState, colVech: value });
    };

    const handleMultiSelectChange3 = (event) => {
        console.log("sddssdsdsdsd", event.target.value);
        const {
            target: { value },
        } = event;

        setFormState({ ...FormState, facilityType: value });
    };



    const handleMultiSelectChange4 = (event) => {
        console.log("sddssdsdsdsd", event.target.value);
        const {
            target: { value },
        } = event;

        setFormState({ ...FormState, frequency: value });
    };


    const goToProfile = () => {


        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)
        const proData = [];
        if (isArray(FormState.typeOfProfile)) {
            FormState.typeOfProfile.forEach(item => {

                if (item != null) {

                    proData?.push(item)
                }

            })
        }

        const data = {
            user_id: gData?.id,
            profile_type: FormState.actorType,
            waste_type: FormState.wasteTypes,

        }

        console.log('datatest', data)

        if (FormState.actorType == null || FormState.actorType == '' && FormState.wasteTypes == '' || FormState.wasteTypes == null) {
            setInvalid('<p style="color:red">Please select profile types.</p>')
            setInvalid2('<p style="color:red">Please select a waste type.</p>')
            return;
        }
        else if (FormState.actorType == null || FormState.actorType == '' && FormState.wasteTypes != '') {
            setInvalid('<p style="color:red">Please select profile types.</p>')
            setInvalid2('')
            return;
        }
        else if (FormState.wasteTypes == null || FormState.wasteTypes == '' && FormState.actorType != '') {
            setInvalid2('<p style="color:red">Please select a waste type.</p>')
            setInvalid('');
            return;
        }
        else {

            props.step1Call(data, false)



            // console.log('adding',data);

            setInvalid('');
            setInvalid2('')
            // props.startData(data)

            let checkData = setCheckData(FormState.actorType);

            let recdata = FormState.actorType;

            console.log('recdata', recdata);

            if (recdata != null && recdata != '' && recdata?.length > 0) {

                checkFieldShow(checkData)


            }
        }




    }




    const textChange = (event) => {

        setFormState({ ...FormState, [event.target.name]: event.target.value });

    }

    const licenceChange = (event, type) => {

        let tstObj = FormState.licenceData;

        if (tstObj[type] !== undefined) {
            tstObj[type] = event.target.value;
            setFormState({ ...FormState, licenceData: tstObj });


        }
    }


    const handleSubmit = (e) => {

        const savedItem = JSON.parse(localStorage.getItem("userInfo"));
        console.log('sssssss', date.dor)
        let curDate = new Date();
        let id = savedItem.id;

        const wasteLicData = [];


        for (const [key, value] of Object.entries(FormState.licenceData)) {

            var obj = {

                "licence_no": value,
                "licence_awarded_from": "",
                "licence_awarded_to": "",
                "actor_type": key.replace('_licence', '')

            }
            wasteLicData.push(obj)
        }

        console.log('wasteLicData', wasteLicData)

        wasteLicData?.forEach(item => {
            for (const [key, value] of Object.entries(FormState.awFrom)) {


                if (key == item.actor_type + '_awFrom') {

                    item['licence_awarded_from'] = value;

                }

            }


            for (const [key, value] of Object.entries(FormState.awTo)) {

                if (key == item.actor_type + '_awTo') {

                    item['licence_awarded_to'] = value;

                }

            }



        })



        console.log('wasteLicData', wasteLicData)


        const dataToSend = {

            "name": FormState.name == '' || FormState.name == null ? 'null' : FormState.name,
            "phone_number": FormState.phone == '' || FormState.phone == null ? 'null' : FormState.phone,
            "location": FormState.location == '' || FormState.location == null ? 'null' : FormState.location,
            "profile_type": FormState.actorType == '' || FormState.actorType == null ? 'null' : FormState.actorType,
            "waste_type": FormState.wasteTypes == '' || FormState.wasteTypes == null ? 'null' : FormState.wasteTypes,
            "generation_rate": FormState.genRate == '' || FormState.genRate == null ? 'null' : FormState.genRate,
            "Collection_Vehicle": FormState.colVech == '' || FormState.colVech == null ? 'null' : FormState.colVech,
            "profile_percentage": completion,
            // "capacity": FormState.capacity == '' || FormState.capacity == null ? 'null' : FormState.capacity,
            "frequency": FormState.frequency == '' || FormState.frequency == null ? 'null' : FormState.frequency,
            "facility_type": FormState.facilityType == '' || FormState.facilityType == null ? 'null' : FormState.facilityType,
            "user_id": id,
            "destination": FormState.destination == '' || FormState.destination == null ? 'null' : FormState.destination,
            "origin": FormState.origin == '' || FormState.origin == null ? 'null' : FormState.origin,
            "area_name": FormState.areaName,
            "licenceData": wasteLicData



        }

        console.log('formData', mainPercen, completion)

        if (mainPercen > 99.99 && completion < 99.99) {

            swal(
                "Please complete your details to save profile!",
                "",
                "warning"
            ).then((ok) => {
                return false;
            })
            return false;

        } else {

            if (isExist == true) {

                props.step1Call(dataToSend, isExist)


            } else {

                props.step1Call(dataToSend, isExist)


            }
        }




    }




    const profileType = [
        "Generator",
        "Collector",
        "Recycler/reclaimer",
        "Importer",
        "Exporter",
        "Disposer",
        "Visitor/Research"
    ]




    // ======================================================== DatePicker Start


    const handleDate = (event) => {

        setDate({ ...date, dor: event });

        setFormState({ ...FormState, dor: event });

    };

    const handleDate2 = (event, type) => {

        let tstObj = date.awTo;

        tstObj[type] = event;

        setDate({ ...date, awTo: tstObj });
        setFormState({ ...FormState, awTo: tstObj });

        console.log("licData", tstObj)
    };

    const handleDate3 = (event, type) => {

        let tstObj = date.awFrom;

        tstObj[type] = event;

        setDate({ ...date, awFrom: tstObj });
        setFormState({ ...FormState, awFrom: tstObj });

        console.log("licData", tstObj)

    };
    // ======================================================== DatePicker Start






    const setLevelDataW = (flat) => {
        console.log("flats Mohit flatlever", flat);

        var roots = []

        var all = {}

        flat.forEach(function (item) {
            all[item.value] = item
        })
        var dmData = [];
        Object.keys(all).forEach(function (value) {

            var item = all[value]
            if (item.parent === null || item.parent === '') {
                roots.push(item)
            } else {

                var p = all[item.parent]
                if (!('children' in p)) {
                    p.children = []
                }
                let exist = p.children.filter(i => {
                    return i._id == item._id;
                })

                console.log('exist', exist)
                if (exist?.length < 1) {
                    p.children.push(item)
                }


            }
        })




        return roots
    }

    const getWatsteData = (e) => {
        const dataArr = e;
        var parentItem = '';
        var children = 0;
        let filnalData = [];



        if (e?.length > 0) {
            setChecked(e)
            setFormState({ ...FormState, wasteTypes: e })
        }
        else {
            setChecked([])
            setFormState({ ...FormState, wasteTypes: [] })
        }



    }



    const setAreaLevelData = (flat) => {
        var roots = [];

        var all = {};

        flat.forEach(function (item) {
            all[item.area_code] = item;
        });
        Object.keys(all).forEach(function (area_code) {
            var item = all[area_code];
            if (item.parent_id === null || item.parent_id === "") {
                roots.push(item);
            } else if (item.parent_id in all) {
                var p = all[item.parent_id];
                if (!("areaSubData" in p)) {
                    p.areaSubData = [];
                }
                p.areaSubData?.push(item);
            }
        });

        return roots;
    };
    const state = {
        open: false,
    };


    const setAreaProps = (code, name) => {

        setFormState({
            ...FormState,
            location: code,
            areaName: name

        });
    }

    const renderAreaTree = (nodes) => {
        return (
            <>
                {
                    nodes.map((item) => {
                        return (
                            <>
                                <TreeItem
                                    className={item.areaSubData?.length > 0 ? 'has-child' : 'no-child'}
                                    key={item.area_code}
                                    nodeId={item._id}
                                    label={item.name}

                                    onClick={() => setAreaProps(item.area_code, item.name)}
                                >
                                    {item.areaSubData != null && Array.isArray(item.areaSubData) && item.areaSubData?.length > 0
                                        ? renderAreaTree(item.areaSubData)
                                        : null}
                                </TreeItem >

                            </>);


                    })

                }
            </>
        )


    };


    return (<>

        <div className='profileForm_page'>
            <div className='profileInit pi_profiledrop'>
                <div className='container'>
                    <div className="row mx-0">
                        <div className="col-md-5">
                            <div className="form-group mb-0 inputform_style profileType">
                                <div className="mat-form-field-wrapper pt_dropDown">
                                    <span>Profile Type</span>
                                    <Select
                                        id="profileType"
                                        name='profileType'
                                        multiple
                                        value={FormState.actorType}
                                        className='profilratee-select'
                                        onChange={(e) => { handleMultiSelectChange(e) }}
                                    >
                                        {FormState.actorList?.map((actorList) => (

                                            <MenuItem
                                                key={actorList}
                                                value={actorList}
                                                className="multiselectCheckbox"
                                            >
                                                {actorList}
                                            </MenuItem>

                                        )
                                        )}
                                    </Select>

                                    <div dangerouslySetInnerHTML={{ __html: invalid ? invalid : '' }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-5">
                            <div className="form-group mb-0 inputform_style ifs_dropdown checkboxRemove">
                                <div className="mat-form-field-wrapper pt_dropDown">

                                    <span>Waste Type</span>
                                    <Dropdown className="dropWaste">
                                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                                            {FormState.wasteTypes?.length > 0 && FormState.wasteTypes.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
                                            {FormState.wasteTypes?.length > 2 ? <><span>{FormState.wasteTypes?.length - 1}+</span></> : ''}
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="treeviewCheckbox">
                                            <CheckboxTree
                                                nodes={mainTreeData}
                                                checked={checked}
                                                expanded={expanded}
                                                onCheck={(e) => getWatsteData(e)}
                                                onExpand={(expanded) => setExpanded(expanded)}
                                            />
                                        </Dropdown.Menu>
                                    </Dropdown>


                                    {/* <div className="mat-form-field-wrapper ">
                                    <span>Waste Type</span>
                                    <Dropdown className="dropWaste">
                                        <Dropdown.Toggle variant="white" id="dropdown-basic">
                                            {FormState.wasteTypes?.length > 0 && FormState.wasteTypes.slice(0, 3).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
                                            {FormState.wasteTypes?.length > 3 ? <><span>{FormState.wasteTypes?.length - 1}+</span></> : ''}
                                            {/* <TextField
                                                className=" w-100"
                                                label=""
                                                type='', hidden'
                                                onChange={textChange}
                                                name="newWaste_types"
                                                value={FormState.wasteTypes}
                                            /> */}

                                    {/* </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            <TreeView
                                                aria-label="file system navigator"
                                                defaultCollapseIcon={<ExpandMoreIcon />}
                                                defaultExpandIcon={<ChevronRightIcon />}
                                                sx={{ flexGrow: 1, }}
                                            >
                                                {renderTreeW(mainTreeData)}
                                            </TreeView>
                                        </Dropdown.Menu>
                                    </Dropdown> */}
                                    {/* <div dangerouslySetInnerHTML={{ __html: invalid2 ? invalid2 : '' }}></div>
                                </div> */}
                                </div>
                            </div>
                        </div>
                        <div className=' col-md-2 d-flex flex-wrap'>
                            <button type='submit' onClick={goToProfile} className='goBtn'> Go </button>
                        </div>
                    </div>
                </div>
            </div>


            <div className='custom_container'>
                <div className='row myProfile w-100 container mx-auto'>
                    <div className='col-md-12'>
                        <div className='d-flex align-items-center justify-content-between'>
                            <h1 className='profile-text'>My Profile </h1>
                            <div className='progressBar'>
                                <div className='progress_number'>{Math.round((completion + Number.EPSILON) * 1) / 1 + '%'}</div>
                                <ProgressBar now={Math.round((completion + Number.EPSILON) * 1) / 1} max={100} />
                                <p class="mb-0 update-progress">Update in Progress</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row profile-tab1'>
                    <div className='col-md-12'>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <div className='my_rofile w-100'>
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-lg-12'>

                                            <div className='mp_form'>
                                                <div className='col-12'>
                                                    <h2>Profile Information</h2>

                                                </div>
                                                <div className='w-100 px-110'>
                                                    <ValidatorForm ref={form} onSubmit={handleSubmit} className="main_react_form w-100" id="addUserForm">
                                                        <Stack className="w-100">
                                                            <div className="row align-items-center mb-15 profileFormDisable">
                                                                <div className="col-md-12 mb-15">
                                                                    <div className="profileFormSection">
                                                                        <span className="mat-form-field-label-wrapper">
                                                                            <div className='profileImg'><img src='assets/lib/images/home/emailid.svg' /></div><label className="mat-form-field-label"> Email ID - <p>{FormState.email}</p></label>
                                                                        </span>
                                                                        <span className="mat-form-field-label-wrapper">
                                                                            <div className='profileImg'><img src='assets/lib/images/home/date.svg' /></div><label className="mat-form-field-label"> Date of Registration - <p> {FormState.dor}</p></label>
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className='row w-100 container mx-auto px-0'>
                                                                <div className='col-md-12 px-0'>
                                                                    <div className='profileBorder'></div>
                                                                </div>
                                                            </div>

                                                            <div className="row">

                                                                {valChecker['Name'] == true &&
                                                                    <div className="col-md-6 mb-30">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Name</span>
                                                                                <TextValidator
                                                                                    onChange={textChange}
                                                                                    InputProps={{ inputProps: { maxLength: 30 } }}
                                                                                    value={FormState.name}
                                                                                    autoComplete="off"
                                                                                    className='w-100' name="name"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {valChecker['Phone Number'] == true &&
                                                                    <div className="col-md-6 mb-30">
                                                                        <div className="form-group mb-0 inputform_style">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Phone Number</span>
                                                                                <TextField
                                                                                    type="number"
                                                                                    inputProps={{ maxLength: 10, min: 1 }}
                                                                                    onChange={textChange}

                                                                                    autoComplete="off"
                                                                                    value={FormState.phone?.toString().slice(0, 10)}
                                                                                    className='w-100' name="phone"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }

                                                                {/* {valChecker['Name']==true && 

                                                                <div className="col-md-6 mb-30">
                                                                    <div className="form-group mb-0 inputform_style">
                                                                        <div className="mat-form-field-wrapper">
                                                                            <span>Email ID</span>
                                                                            <TextValidator
                                                                                type='Email ID'
                                                                                disabled={true}
                                                                                onChange={textChange}
                                                                                InputProps={{ inputProps: { maxLength: 70 } }}
                                                                                autoComplete="off"
                                                                                value={FormState.email}
                                                                                className='w-100' name="email"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                } */}
                                                                {valChecker['Location'] == true &&

                                                                    <div className="col-md-6 mb-30">
                                                                        <div className="form-group mb-0 inputform_style ifs_dropdown">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Location</span>
                                                                                <Dropdown>
                                                                                    <Dropdown.Toggle variant="white" id="dropdown-basic">
                                                                                        <TextValidator
                                                                                            className=" w-100"
                                                                                            label=""
                                                                                            onChange={textChange}
                                                                                            name="areaName"
                                                                                            value={FormState.areaName}
                                                                                        />
                                                                                    </Dropdown.Toggle>
                                                                                    <Dropdown.Menu>
                                                                                        <TreeView
                                                                                            aria-label="file system navigator"
                                                                                            defaultCollapseIcon={<ExpandMoreIcon />}
                                                                                            defaultExpandIcon={<ChevronRightIcon />}
                                                                                            sx={{ flexGrow: 1, }}
                                                                                        >
                                                                                            {renderAreaTree(areaData)}
                                                                                        </TreeView>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }






                                                                {valChecker['Facility Type'] == true &&

                                                                    <div className="col-md-6 mb-30">
                                                                        <div className="form-group mb-0 inputform_style pformDropdown">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Facility Type</span>
                                                                                <Select

                                                                                    name='facilityType'
                                                                                    multiple
                                                                                    value={FormState.facilityType}
                                                                                    className='profile-select'
                                                                                    onChange={(e) => { handleMultiSelectChange3(e) }}
                                                                                >
                                                                                    {FormState.facilityList?.map((facilityList) => (

                                                                                        <MenuItem
                                                                                            key={facilityList}
                                                                                            value={facilityList}
                                                                                            className="multiselectCheckbox"
                                                                                        >
                                                                                            {facilityList}
                                                                                        </MenuItem>

                                                                                    )
                                                                                    )}
                                                                                </Select>
                                                                            </div>
                                                                        </div>
                                                                    </div>}


                                                                {/* {valChecker['Capacity'] == true && <div className="col-md-6 mb-30">
                                                                    <div className="form-group mb-0 inputform_style">
                                                                        <div className="mat-form-field-wrapper">
                                                                            <span>Capacity (Tones)</span>
                                                                            <TextValidator
                                                                                type="number"
                                                                                InputProps={{ inputProps: { min: 1 } }}
                                                                                onChange={textChange}
                                                                                value={FormState.capacity}
                                                                                autoComplete="off"
                                                                                className='w-100' name="capacity"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>} */}





                                                                {/* {valChecker['Waste licence No.'] == true && <div className="col-md-6 mb-30">
                                                                    <div className="form-group mb-0 inputform_style">
                                                                        <div className="mat-form-field-wrapper">
                                                                            <span>Waste licence No.</span>
                                                                            <TextValidator
                                                                                onChange={textChange}
                                                                                value={FormState.watseLicNo}
                                                                                autoComplete="off"
                                                                                className='w-100' name="watseLicNo"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {valChecker['License Awarded From (Date)'] == true && <div className="col-md-6 mb-30">
                                                                    <div className="form-group mb-0 inputform_style datePicker">
                                                                        <div className="mat-form-field-wrapper">
                                                                            <span>License Awarded From (Date)</span>


                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <Stack spacing={3}>
                                                                                    <DesktopDatePicker
                                                                                        inputFormat="MM/DD/YYYY"
                                                                                        name="awFrom"
                                                                                        value={date.awFrom}
                                                                                        maxDate={new Date()}
                                                                                        onChange={(e) => { handleDate3(e) }}
                                                                                        renderInput={(params) => <TextField {...params}
                                                                                            inputProps={{
                                                                                                ...params.inputProps,
                                                                                                placeholder: "DD/MM/YYYY"
                                                                                            }}
                                                                                        />}
                                                                                    />
                                                                                </Stack>
                                                                            </LocalizationProvider>
                                                                        </div>
                                                                    </div>
                                                                </div>}

                                                                {valChecker['License Awarded To (Date)'] == true && <div className="col-md-6 mb-30">
                                                                    <div className="form-group mb-0 inputform_style datePicker">
                                                                        <div className="mat-form-field-wrapper">
                                                                            <span>License Awarded To (Date)</span>
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <Stack spacing={3}>
                                                                                    <DesktopDatePicker
                                                                                        inputFormat="MM/DD/YYYY"
                                                                                        name="awFrom"
                                                                                        minDate={date.awFrom}
                                                                                        value={date.awTo}
                                                                                        onChange={(e) => { handleDate2(e) }}
                                                                                        renderInput={(params) => <TextField {...params}
                                                                                            inputProps={{
                                                                                                ...params.inputProps,
                                                                                                placeholder: "DD/MM/YYYY"
                                                                                            }}
                                                                                        />}
                                                                                    />
                                                                                </Stack>
                                                                            </LocalizationProvider>
                                                                        </div>
                                                                    </div>
                                                                </div>} */}


                                                                {valChecker['Destination'] == true &&

                                                                    <div className="col-md-6 mb-30">
                                                                        <div className="form-group mb-0 inputform_style arrowRemove dropBorder">
                                                                            <div className="mat-form-field-wrapper">
                                                                                <span>Destination</span>
                                                                                {/* <TextValidator
                                                                                onChange={textChange}
                                                                                value={FormState.destination}
                                                                                autoComplete="off"
                                                                                className='w-100' name="destination"
                                                                            /> */}

                                                                                <Select

                                                                                    name='destination'

                                                                                    value={FormState.destination}
                                                                                    className='profile-select'
                                                                                    onChange={(e) => { textChange(e) }}
                                                                                >
                                                                                    {countryList?.map((countryList) => (

                                                                                        <MenuItem
                                                                                            key={countryList}
                                                                                            value={countryList}

                                                                                        >
                                                                                            {countryList}
                                                                                        </MenuItem>

                                                                                    )
                                                                                    )}
                                                                                </Select>


                                                                            </div>
                                                                        </div>



                                                                    </div>




                                                                }


                                                                {valChecker['Origin'] == true && <div className="col-md-6 mb-30">
                                                                    <div className="form-group mb-0 inputform_style arrowRemove dropBorder">
                                                                        <div className="mat-form-field-wrapper">
                                                                            <span>Origin</span>
                                                                            <Select

                                                                                name='origin'

                                                                                value={FormState.origin}
                                                                                className='profile-select'
                                                                                onChange={(e) => { textChange(e) }}
                                                                            >
                                                                                {countryList?.map((originList) => (

                                                                                    <MenuItem
                                                                                        key={originList}
                                                                                        value={originList}

                                                                                    >
                                                                                        {originList}
                                                                                    </MenuItem>

                                                                                )
                                                                                )}
                                                                            </Select>
                                                                        </div>
                                                                    </div>
                                                                </div>}


                                                                {/* {valChecker['Date of registration']==true && <div className="col-md-6 mb-30">
                                                                    <div className="form-group mb-0 inputform_style datePicker dorDate">
                                                                        <div className="mat-form-field-wrapper">
                                                                            <span>Date of registration</span>
                                                            
                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                <Stack spacing={3}>
                                                                                    <DesktopDatePicker
                                                                                    className='daorDateBox'
                                                                                        inputFormat="MM/DD/YYYY"
                                                                                        value={date.dor}
                                                                                        maxDate={new Date()}
                                                                                        dissabled={true}
                                                                                        disableOpenPicker={true}
                                                                                        renderInput={(params) => <TextField {...params}
                                                                                            inputProps={{
                                                                                                ...params.inputProps,
                                                                                                disabled:true,
                                                                                                placeholder: "DD/MM/YYYY"
                                                                                            }}
                                                                                        />}
                                                                                    />
                                                                                </Stack>
                                                                            </LocalizationProvider>
                                                                        </div>
                                                                    </div>
                                                                </div>} */}


                                                            </div>



                                                            {FormState.actorType?.map(item => {
                                                                if (item == 'Generator') return <div>
                                                                    <div className='row w-100 container mx-auto px-0 mt-4'>
                                                                        <div className='col-md-12 px-0'>
                                                                            <h2 className=''>Generator Specific</h2>
                                                                            {/* <div className='profileBorder'></div> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">



                                                                        {valChecker['Generation rate (Tonnes/month)'] == true &&
                                                                            <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>Generation rate (Tonnes/month)</span>
                                                                                        <TextValidator
                                                                                            type="number"
                                                                                            InputProps={{ inputProps: { min: 1 } }}
                                                                                            onChange={textChange}
                                                                                            value={FormState.genRate}
                                                                                            autoComplete="off"
                                                                                            className='w-100' name="genRate"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        }




                                                                    </div>
                                                                </div>
                                                            })}




                                                            {/* ============================================================ Form 2 ====================================================== */}
                                                            {FormState.actorType?.map(item => {
                                                                if (item == 'Collector') return <div>
                                                                    <div className='row w-100 container mx-auto px-0 mt-4'>
                                                                        <div className='col-md-12 px-0'>
                                                                            <h2 className=''>Collector Specific</h2>
                                                                            {/* <div className='profileBorder'></div> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">




                                                                        {valChecker['Collection Vehicle'] == true &&

                                                                            <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style collectionvehicle dropBorder">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>Collection Vehicle</span>
                                                                                        <Select

                                                                                            name='colVech'
                                                                                            multiple
                                                                                            value={FormState.colVech}
                                                                                            className='profile-select'
                                                                                            onChange={(e) => { handleMultiSelectChange2(e) }}
                                                                                        >
                                                                                            {FormState.colVehicle?.map((colVehicle) => (

                                                                                                <MenuItem
                                                                                                    key={colVehicle}
                                                                                                    value={colVehicle}
                                                                                                    className="multiselectCheckbox"
                                                                                                >
                                                                                                    {colVehicle}
                                                                                                </MenuItem>

                                                                                            )
                                                                                            )}
                                                                                        </Select>
                                                                                    </div>
                                                                                </div>
                                                                            </div>}


                                                                        {valChecker['Frequency'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style pformDropdown">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>Frequency</span>
                                                                                    <Select

                                                                                        name='frequency'

                                                                                        value={FormState.frequency}
                                                                                        className='profile-select'
                                                                                        onChange={(e) => { handleMultiSelectChange4(e) }}
                                                                                    >
                                                                                        {FormState.freqList?.map((freqList) => (

                                                                                            <MenuItem
                                                                                                key={freqList}
                                                                                                value={freqList}
                                                                                                className="multiselectCheckbox"
                                                                                            >
                                                                                                {freqList}
                                                                                            </MenuItem>

                                                                                        )
                                                                                        )}
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {valChecker['Waste licence No.'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>Waste licence No.</span>
                                                                                    <TextValidator
                                                                                        onChange={(e) => licenceChange(e, "Collector_licence")}
                                                                                        value={FormState.licenceData?.Collector_licence}
                                                                                        autoComplete="off"
                                                                                        className='w-100 multiLic'
                                                                                        name="Collector_licence"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {valChecker['License Awarded From (Date)'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>License Awarded From (Date)</span>


                                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                        <Stack spacing={3}>
                                                                                            <DesktopDatePicker
                                                                                                inputFormat="MM/DD/YYYY"
                                                                                                name="Collector_awFrom"
                                                                                                value={date.awFrom?.["Collector_awFrom"] == undefined ? null : date.awFrom?.["Collector_awFrom"]}
                                                                                                maxDate={new Date()}
                                                                                                onChange={(e) => { handleDate3(e, "Collector_awFrom") }}
                                                                                                defaultValue={null}
                                                                                                renderInput={(params) => <TextField {...params}
                                                                                                    inputProps={{
                                                                                                        ...params.inputProps,
                                                                                                        placeholder: "DD/MM/YYYY"
                                                                                                    }}
                                                                                                />}
                                                                                            />
                                                                                        </Stack>
                                                                                    </LocalizationProvider>
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {valChecker['License Awarded To (Date)'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>License Awarded To (Date)</span>
                                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                        <Stack spacing={3}>
                                                                                            <DesktopDatePicker
                                                                                                inputFormat="MM/DD/YYYY"
                                                                                                name="Collector_awTo"
                                                                                                minDate={date.awFrom?.["Collector_awFrom"]}
                                                                                                value={date.awTo?.["Collector_awTo"] == undefined ? null : date.awTo?.["Collector_awTo"]}
                                                                                                onChange={(e) => { handleDate2(e, "Collector_awTo") }}
                                                                                                defaultValue={null}
                                                                                                renderInput={(params) => <TextField {...params}
                                                                                                    inputProps={{
                                                                                                        ...params.inputProps,
                                                                                                        placeholder: "DD/MM/YYYY"
                                                                                                    }}
                                                                                                />}
                                                                                            />
                                                                                        </Stack>
                                                                                    </LocalizationProvider>
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {/* 
                                                                        {valChecker['Origin'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style arrowRemove dropBorder">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>Origin</span>
                                                                                    <Select

                                                                                        name='origin'

                                                                                        value={FormState.origin}
                                                                                        className='profile-select'
                                                                                        onChange={(e) => { textChange(e) }}
                                                                                    >
                                                                                        {countryList?.map((originList) => (

                                                                                            <MenuItem
                                                                                                key={originList}
                                                                                                value={originList}

                                                                                            >
                                                                                                {originList}
                                                                                            </MenuItem>

                                                                                        )
                                                                                        )}
                                                                                    </Select>
                                                                                </div>
                                                                            </div>
                                                                        </div>} */}



                                                                    </div>
                                                                </div>
                                                            })}
                                                            {/* ============================================================ Form 2 ====================================================== */}


                                                            {/* ============================================================ Form 3 ====================================================== */}
                                                            {FormState.actorType?.map(item => {
                                                                if (item == 'Recycler/reclaimer') return <div>
                                                                    <div className='row w-100 container mx-auto px-0 mt-4'>
                                                                        <div className='col-md-12 px-0'>
                                                                            <h2>Recycler/reclaimer Specific</h2>
                                                                            {/* <div className='profileBorder'></div> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">

                                                                        {valChecker['Waste licence No.'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>Waste licence No.</span>
                                                                                    <TextValidator
                                                                                        onChange={(e) => licenceChange(e, "Recycler/reclaimer_licence")}
                                                                                        value={FormState.licenceData?.["Recycler/reclaimer_licence"]}
                                                                                        autoComplete="off"
                                                                                        className='w-100 multiLic'
                                                                                        name="Recycler/reclaimer_licence"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {valChecker['License Awarded From (Date)'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>License Awarded From (Date)</span>


                                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                        <Stack spacing={3}>
                                                                                            <DesktopDatePicker
                                                                                                inputFormat="MM/DD/YYYY"
                                                                                                name="Recycler/reclaimer_awFrom"
                                                                                                value={date.awFrom?.["Recycler/reclaimer_awFrom"] == undefined ? null : date.awFrom?.["Recycler/reclaimer_awFrom"]}
                                                                                                maxDate={new Date()}
                                                                                                onChange={(e) => { handleDate3(e, "Recycler/reclaimer_awFrom") }}
                                                                                                defaultValue={null}
                                                                                                renderInput={(params) => <TextField {...params}
                                                                                                    inputProps={{
                                                                                                        ...params.inputProps,
                                                                                                        placeholder: "DD/MM/YYYY"
                                                                                                    }}
                                                                                                />}
                                                                                            />
                                                                                        </Stack>
                                                                                    </LocalizationProvider>
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {valChecker['License Awarded To (Date)'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>License Awarded To (Date)</span>
                                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                        <Stack spacing={3}>
                                                                                            <DesktopDatePicker
                                                                                                inputFormat="MM/DD/YYYY"
                                                                                                name="Recycler/reclaimer_awTo"
                                                                                                minDate={date.awFrom?.["Recycler/reclaimer_awFrom"]}
                                                                                                value={date.awTo?.["Recycler/reclaimer_awTo"] == undefined ? null : date.awFrom?.["Recycler/reclaimer_awTo"]}
                                                                                                onChange={(e) => { handleDate2(e, "Recycler/reclaimer_awTo") }}
                                                                                                defaultValue={null}
                                                                                                renderInput={(params) => <TextField {...params}
                                                                                                    inputProps={{
                                                                                                        ...params.inputProps,
                                                                                                        placeholder: "DD/MM/YYYY"
                                                                                                    }}
                                                                                                />}
                                                                                            />
                                                                                        </Stack>
                                                                                    </LocalizationProvider>
                                                                                </div>
                                                                            </div>
                                                                        </div>}






                                                                    </div>
                                                                </div>
                                                            })}
                                                            {/* ============================================================ Form 3 ====================================================== */}

                                                            {/* ============================================================ Form 4 ====================================================== */}
                                                            {FormState.actorType?.map(item => {
                                                                if (item == 'Importer') return <div>
                                                                    <div className='row w-100 container mx-auto px-0 mt-4'>
                                                                        <div className='col-md-12 px-0'>
                                                                            <h2>Importer Specific</h2>
                                                                            <div className='profileBorder'></div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row">


                                                                        {valChecker['Waste licence No.'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>Waste licence No.</span>
                                                                                    <TextValidator
                                                                                        onChange={(e) => licenceChange(e, "Importer_licence")}
                                                                                        value={FormState.licenceData?.["Importer_licence"]}
                                                                                        autoComplete="off"
                                                                                        className='w-100 multiLic' name="Importer_licence"
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {valChecker['License Awarded From (Date)'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>License Awarded From (Date)</span>


                                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                        <Stack spacing={3}>
                                                                                            <DesktopDatePicker
                                                                                                inputFormat="MM/DD/YYYY"
                                                                                                name="Importer_awFrom"
                                                                                                value={date.awFrom?.["Importer_awFrom"] == undefined ? null : date.awFrom?.["Importer_awFrom"]}
                                                                                                maxDate={new Date()}
                                                                                                defaultValue={null}
                                                                                                onChange={(e) => { handleDate3(e, "Importer_awFrom") }}
                                                                                                renderInput={(params) => <TextField {...params}
                                                                                                    inputProps={{
                                                                                                        ...params.inputProps,
                                                                                                        placeholder: "DD/MM/YYYY"
                                                                                                    }}
                                                                                                />}
                                                                                            />
                                                                                        </Stack>
                                                                                    </LocalizationProvider>
                                                                                </div>
                                                                            </div>
                                                                        </div>}

                                                                        {valChecker['License Awarded To (Date)'] == true && <div className="col-md-6 mb-30">
                                                                            <div className="form-group mb-0 inputform_style datePicker">
                                                                                <div className="mat-form-field-wrapper">
                                                                                    <span>License Awarded To (Date)</span>
                                                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                        <Stack spacing={3}>
                                                                                            <DesktopDatePicker
                                                                                                inputFormat="MM/DD/YYYY"
                                                                                                name="Importer_awTo"
                                                                                                minDate={date.awFrom?.["Importer_awFrom"]}
                                                                                                defaultValue={null}
                                                                                                value={date.awTo?.["Importer_awTo"] == undefined ? null : date.awTo?.["Importer_awTo"]}
                                                                                                onChange={(e) => { handleDate2(e, "Importer_awTo") }}
                                                                                                renderInput={(params) => <TextField {...params}
                                                                                                    inputProps={{
                                                                                                        ...params.inputProps,
                                                                                                        placeholder: "DD/MM/YYYY"
                                                                                                    }}
                                                                                                />}
                                                                                            />
                                                                                        </Stack>
                                                                                    </LocalizationProvider>
                                                                                </div>
                                                                            </div>
                                                                        </div>}







                                                                    </div>
                                                                </div>
                                                            })}
                                                            {/* ============================================================ Form 4 ====================================================== */}


                                                            {/* ============================================================ Form 5 ====================================================== */}
                                                            <div>
                                                                {FormState.actorType?.map(item => {
                                                                    if (item == 'Exporter') return <div>
                                                                        <div className='row w-100 container mx-auto px-0 mt-4'>
                                                                            <div className='col-md-12 px-0'>
                                                                                <h2>Exporter Specific</h2>
                                                                                {/* <div className='profileBorder'></div> */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">



                                                                            {valChecker['Waste licence No.'] == true && <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>Waste licence No.</span>
                                                                                        <TextValidator
                                                                                            onChange={(e) => licenceChange(e, "Exporter_licence")}
                                                                                            value={FormState.licenceData?.["Exporter_licence"]}
                                                                                            autoComplete="off"
                                                                                            className='w-100 multiLic' name="Exporter_licence"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>}

                                                                            {valChecker['License Awarded From (Date)'] == true && <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style datePicker">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>License Awarded From (Date)</span>


                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                            <Stack spacing={3}>
                                                                                                <DesktopDatePicker
                                                                                                    inputFormat="MM/DD/YYYY"
                                                                                                    name="Exporter_awFrom"
                                                                                                    value={date.awFrom?.["Exporter_awFrom"] == undefined ? null : date.awFrom?.["Exporter_awFrom"]}
                                                                                                    maxDate={new Date()}
                                                                                                    onChange={(e) => { handleDate3(e, "Exporter_awFrom") }}
                                                                                                    renderInput={(params) => <TextField {...params}
                                                                                                        inputProps={{
                                                                                                            ...params.inputProps,
                                                                                                            placeholder: "DD/MM/YYYY"
                                                                                                        }}
                                                                                                    />}
                                                                                                />
                                                                                            </Stack>
                                                                                        </LocalizationProvider>
                                                                                    </div>
                                                                                </div>
                                                                            </div>}

                                                                            {valChecker['License Awarded To (Date)'] == true && <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style datePicker">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>License Awarded To (Date)</span>
                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                            <Stack spacing={3}>
                                                                                                <DesktopDatePicker
                                                                                                    inputFormat="MM/DD/YYYY"
                                                                                                    name="Exporter_awTo"
                                                                                                    minDate={date.awFrom?.["Exporter_awFrom"]}
                                                                                                    value={date.awTo?.["Exporter_awTo"] == undefined ? null : date.awTo?.["Exporter_awTo"]}
                                                                                                    onChange={(e) => { handleDate2(e, "Exporter_awTo") }}
                                                                                                    renderInput={(params) => <TextField {...params}
                                                                                                        inputProps={{
                                                                                                            ...params.inputProps,
                                                                                                            placeholder: "DD/MM/YYYY"
                                                                                                        }}
                                                                                                    />}
                                                                                                />
                                                                                            </Stack>
                                                                                        </LocalizationProvider>
                                                                                    </div>
                                                                                </div>
                                                                            </div>}





                                                                        </div>
                                                                    </div>
                                                                })}

                                                            </div>

                                                            {/* =============================Form5===================== */}



                                                            {/* ============================================================ Form 6 ====================================================== */}
                                                            <div>
                                                                {FormState.actorType?.map(item => {
                                                                    if (item == 'Disposer') return <div>
                                                                        <div className='row w-100 container mx-auto px-0 mt-4'>
                                                                            <div className='col-md-12 px-0'>
                                                                                <h2>Disposer Specific</h2>
                                                                                {/* <div className='profileBorder'></div> */}
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">





                                                                            {valChecker['Waste licence No.'] == true && <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>Waste licence No.</span>
                                                                                        <TextValidator
                                                                                            onChange={(e) => licenceChange(e, "Disposer_licence")}
                                                                                            value={FormState.licenceData?.Disposer_licence}
                                                                                            autoComplete="off"
                                                                                            className='w-100 multiLic' name="Disposer_licence"
                                                                                        />
                                                                                    </div>
                                                                                </div>
                                                                            </div>}

                                                                            {valChecker['License Awarded From (Date)'] == true && <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style datePicker">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>License Awarded From (Date)</span>


                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                            <Stack spacing={3}>
                                                                                                <DesktopDatePicker
                                                                                                    inputFormat="MM/DD/YYYY"
                                                                                                    name="Disposer_awFrom"
                                                                                                    value={date.awFrom?.["Disposer_awFrom"] == undefined ? null : date.awFrom?.["Disposer_awFrom"]}
                                                                                                    maxDate={new Date()}
                                                                                                    onChange={(e) => { handleDate3(e, "Disposer_awFrom") }}
                                                                                                    renderInput={(params) => <TextField {...params}
                                                                                                        inputProps={{
                                                                                                            ...params.inputProps,
                                                                                                            placeholder: "DD/MM/YYYY"
                                                                                                        }}
                                                                                                    />}
                                                                                                />
                                                                                            </Stack>
                                                                                        </LocalizationProvider>
                                                                                    </div>
                                                                                </div>
                                                                            </div>}

                                                                            {valChecker['License Awarded To (Date)'] == true && <div className="col-md-6 mb-30">
                                                                                <div className="form-group mb-0 inputform_style datePicker">
                                                                                    <div className="mat-form-field-wrapper">
                                                                                        <span>License Awarded To (Date)</span>
                                                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                            <Stack spacing={3}>
                                                                                                <DesktopDatePicker
                                                                                                    inputFormat="MM/DD/YYYY"
                                                                                                    name="Disposer_awTo"
                                                                                                    minDate={date.awFrom?.["Disposer_awFrom"]}
                                                                                                    value={date.awTo?.["Disposer_awTo"] == undefined ? null : date.awTo?.["Disposer_awTo"]}
                                                                                                    onChange={(e) => { handleDate2(e, "Disposer_awTo") }}
                                                                                                    renderInput={(params) => <TextField {...params}
                                                                                                        inputProps={{
                                                                                                            ...params.inputProps,
                                                                                                            placeholder: "DD/MM/YYYY"
                                                                                                        }}
                                                                                                    />}
                                                                                                />
                                                                                            </Stack>
                                                                                        </LocalizationProvider>
                                                                                    </div>
                                                                                </div>
                                                                            </div>}





                                                                        </div>
                                                                    </div>
                                                                })}

                                                            </div>

                                                            {/* =============================Form6===================== */}



                                                            {/* ============================================================ Form 7 ====================================================== */}
                                                            <div>
                                                                <div>
                                                                    {FormState.actorType?.map(item => {
                                                                        if (item == 'Disposer') return <div>
                                                                            <div className='row w-100 container mx-auto px-0 mt-4'>
                                                                                <div className='col-md-12 px-0'>
                                                                                    <h2>Remediator Specific</h2>
                                                                                    {/* <div className='profileBorder'></div> */}
                                                                                </div>
                                                                            </div>

                                                                            <div className="row">





                                                                                {valChecker['Waste licence No.'] == true && <div className="col-md-6 mb-30">
                                                                                    <div className="form-group mb-0 inputform_style">
                                                                                        <div className="mat-form-field-wrapper">
                                                                                            <span>Waste licence No.</span>
                                                                                            <TextValidator
                                                                                                onChange={(e) => licenceChange(e, "Remediator_licence")}
                                                                                                value={FormState.licenceData?.Remediator_licence}
                                                                                                autoComplete="off"
                                                                                                className='w-100 multiLic' name="Remediator_licence"
                                                                                            />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}

                                                                                {valChecker['License Awarded From (Date)'] == true && <div className="col-md-6 mb-30">
                                                                                    <div className="form-group mb-0 inputform_style datePicker">
                                                                                        <div className="mat-form-field-wrapper">
                                                                                            <span>License Awarded From (Date)</span>


                                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                                <Stack spacing={3}>
                                                                                                    <DesktopDatePicker
                                                                                                        inputFormat="MM/DD/YYYY"
                                                                                                        name="Remediator_awFrom"
                                                                                                        value={date.awFrom?.["Remediator_awFrom"] == undefined ? null : date.awFrom?.["Remediator_awFrom"]}
                                                                                                        maxDate={new Date()}
                                                                                                        onChange={(e) => { handleDate3(e, "Remediator_awFrom") }}
                                                                                                        renderInput={(params) => <TextField {...params}
                                                                                                            inputProps={{
                                                                                                                ...params.inputProps,
                                                                                                                placeholder: "DD/MM/YYYY"
                                                                                                            }}
                                                                                                        />}
                                                                                                    />
                                                                                                </Stack>
                                                                                            </LocalizationProvider>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}

                                                                                {valChecker['License Awarded To (Date)'] == true && <div className="col-md-6 mb-30">
                                                                                    <div className="form-group mb-0 inputform_style datePicker">
                                                                                        <div className="mat-form-field-wrapper">
                                                                                            <span>License Awarded To (Date)</span>
                                                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                                                <Stack spacing={3}>
                                                                                                    <DesktopDatePicker
                                                                                                        inputFormat="MM/DD/YYYY"
                                                                                                        name="Remediator_awTo"
                                                                                                        minDate={date.awFrom?.["Remediator_awFrom"]}
                                                                                                        value={date.awTo?.["Remediator_awTo"] == undefined ? null : date.awTo?.["Remediator_awTo"]}
                                                                                                        onChange={(e) => { handleDate2(e, "Remediator_awTo") }}
                                                                                                        renderInput={(params) => <TextField {...params}
                                                                                                            inputProps={{
                                                                                                                ...params.inputProps,
                                                                                                                placeholder: "DD/MM/YYYY"
                                                                                                            }}
                                                                                                        />}
                                                                                                    />
                                                                                                </Stack>
                                                                                            </LocalizationProvider>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>}





                                                                            </div>
                                                                        </div>
                                                                    })}

                                                                </div>

                                                                {/* =============================Form6===================== */}




                                                                {/* =============================Form7===================== */}


                                                            </div>
                                                            {/* ============================================================ Form 5 ====================================================== */}


                                                            <div className="row mb-30">
                                                                <div className="col-md-12 d-flex align-items-center justify-content-center mt-4">
                                                                    <div className="form-group mb-0 exit_btn exit_save">
                                                                        <div className="mat-form-field-wrapper text-center">
                                                                            <Link to="/home"> <Button id='exitBtn' variant="contained" type="button" className=''>Cancel </Button></Link>
                                                                        </div>
                                                                    </div>
                                                                    <div className="form-group mb-0 save_next exit_save">
                                                                        <div className="mat-form-field-wrapper text-center">
                                                                            <Button id='nameBtn' variant="contained" onClick={() => setExist(true)} type="submit">Save <img src='assets/lib/images/home/arrow_right.svg' /></Button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </Stack>
                                                    </ValidatorForm>
                                                </div>


























                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Box>
                    </div>
                </div>
            </div>
        </div>

        <div className={loader ? 'loader text-center ' : 'loader text-center hide'}>
            <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
        </div>

    </>)

}














