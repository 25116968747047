import React, { useEffect, useRef, useState } from 'react';
import './addidata.css'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import * as $ from 'jquery';
import CheckboxTree from 'react-checkbox-tree';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import Dropdown from 'react-bootstrap/Dropdown';
import Modal from 'react-bootstrap/Modal';
import AdminService from '../../services/admin.service';
import { Update } from '@mui/icons-material';
import * as constants from '../../Config/app.constants';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

const Label = ({children}) => <span>{children}</span>;

class AddiDataScreenModal extends React.Component {

constructor(props) {
    super(props);


    this.state = {
        checked: [],
        checkedTimeperiod : [],
        checkedIndicator : [],
        checkedArea : [],
        checkedSource : [],

        nodesWithData:[], //indicatorWithdata
        areasWithData:[],
        timePeriodsWithData:[],
        sourcesWithData:[],
        
        allareaList: [],
        viewSelectedIndicator:false,
        viewSelectedArea:false,
        viewSelectedTime:false,
        viewSelectedSource:false,

        showIndicatorWithData:false,
        showAreaWithData:false,
        showTimeperiodWithData:false,
        showSourceWithData:false,


        activeMetadata:{},
        activeIndicator:{name:'',unit:''},

        expanded: [],
        clicked: [],
        value: '1',
        checkOn: 'on',
        checkTimeperiod: 'on',
        checkIndicator: false,
        checkArea: false,
        show: false,
        data: [],
        areaData: [],
        indicatorData: [],
        sourceData: [],
        timeperiodData: [],
        indicatorByGoal: [],
        searchText: '',
        setScrollableModal:false,
        // mainData: [],
        isEdit: false,
        
        loader: false,
    
        bulkDelete :[],
        indicatorData:[],
        nodesIndicator: [],

        nodesArea: [
            // {
            // value: 'adolescentTonnes',
            // label: <><Label>adolescent, Tonne/s  <img className='adp_indicator' onClick={this.handleShow} src='assets/lib/images/home/indicator.svg' /></Label></>,
            // children: [
            //     { value: 'selectall', label: 'Select All' ,
            
            //     children: [
                    
            //         { value: 'employees', label: 'employees' 
                  
            //     },
            //     ]
            // },
                
            //     ],
            // },
    
            ],

        nodesTimeperiod: [],


        nodesSource: []
        
      };

    //   console.log('props===================>', this.props.dataCleared)

    this.getData();
      
}

// componentDidMount() {
//     this.getData();

// };

componentDidMount(){   
    $('.caret').on('click', function() {
        // console.log("---", this.parentElement.querySelector(".nested").classList.toggle("active"))
        this.parentElement.querySelector(".nested").classList.toggle("active");
        this.classList.toggle("caret-down");
    })
    // $(document).on('click', '.caret', function(){
    //     alert()
    //     // console.log("===",this.parentElement, this.parentElement.querySelector(".nested").classList.toggle("active"))
    //     this.parentElement.querySelector(".nested").classList.toggle("active");
    //   this.classList.toggle("caret-down");
    // })  
}


 sourcefilter=[];
 timedata1=[];
 indi = [];
 areaFather = [];
 assignedArea = []
 userRole = 0
getData = async () => {
    
    this.setState({ loader: true })

    AdminService.httpGet('/data-retrieval/area/getAreaData').then((res)=>{ 
        if(res){
            let d = res;
            this.setState({filteredData: d, data:d})
            this.state.allareaList = this.state.filteredData.data
            // console.log("this.state.allareaList:: ", this.state.allareaList)
        }
    })

    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const role = userInfo.role;
    this.userRole = role
    if(role != 1){
        this.assignedArea = userInfo.area;
        
        // console.log(this.assignedArea);
    }

    AdminService.httpGet('/data-retrieval/data/get-filter-data').then((res)=>{
        
                if(res){
                    this.setState({loader:false})
                    let d = res;
                    // d.forEach((ele  , index)=>{ele.index = index })
                    // this.setState({filteredData: d, areaData: d, indicatorData: d1, sourceData: d2, timePeriodData: d3, data:d})
                    
                    this.setState({filteredData: d, data:d})
                    // console.log("d-----"+JSON.stringify(this.state.filteredData.areaData))

                    this.state.filteredData.areaData.sort(function (a, b) {
                        var ax = [], bx = [];
                    
                        a.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
                        b.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
                    
                        while (ax.length && bx.length) {
                          var an = ax.shift();
                          var bn = bx.shift();
                          var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                          if (nn) return nn;
                        }
                    
                        return ax.length - bx.length;
                      });

                      let that = this;
                      $(this.state.filteredData.areaData).each(function (i, item) {
                
                        that.state.filteredData.areaData[i].areaSubData.sort(function (a, b) {
                          var ax = [], bx = [];
                
                          a.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
                          b.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
                
                          while (ax.length && bx.length) {
                            var an = ax.shift();
                            var bn = bx.shift();
                            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                            if (nn) return nn;
                          }
                
                          return ax.length - bx.length;
                        });
                
                      });

                      
                    //   $(this.state.filteredData.areaData).each(function (i, item) {

                    //     $(this.state.filteredData.areaData[i].areaSubData[i]).each(function (i, item) {

                
                    //     that.state.filteredData.areaData[i].areaSubData.sort(function (a, b) {
                    //       var ax = [], bx = [];
                
                    //       a.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
                    //       b.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
                
                    //       while (ax.length && bx.length) {
                    //         var an = ax.shift();
                    //         var bn = bx.shift();
                    //         var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                    //         if (nn) return nn;
                    //       }
                
                    //       return ax.length - bx.length;
                    //     });
                
                    //   }
                      
                      
                    // }
                      
                      
                    //   );
                
                      this.state.areaData = this.state.filteredData.areaData;
                    //   this.state.nodesArea[0].label=this.state.areaData[0].name;

                    
             

                    //   children: [
                    //     { value: 'selectall', label: 'Select All' ,
                    
                    //     children: [
                            
                    //         { value: 'employees', label: 'employees' 
                          
                    //     },
                    //     ]
                    // },
                        
                    //     ],

                    //   let areachild;
                        let newArr = [] 
                        newArr.push({ 
                                'value': this.state.areaData[0].area_code,
                                'label': <><Label>{this.state.areaData[0].name}  </Label></>,
                                'children': [{ 'value': 'selectall-area', 'label': 'Select All' }]
                        })
                    // //   console.log("this.state.areaData:: ", this.state.areaData)
                      this.state.areaData.forEach((element,k) => {
                        element.areaSubData.forEach((it,ky1) =>{
                            let innerChild2 =[]
                            
                            it.areaSubData.forEach((elem , ky)=>{
                                innerChild2.push({ value: elem.area_code, label: elem.name })
                            })
                            innerChild2.unshift({ 'value': 'selectall-area'+ky1, 'label': 'Select All' })
                            newArr[0].children.push({ value: it.area_code, label: <><Label>{it.name}  </Label></> , 'children' : innerChild2 })
                        })
                      });

                      this.areaFather = newArr
                      this.setState({nodesArea: this.areaFather})
                      this.assignedArea = userInfo.area;

                      newArr[0].children.forEach(area=>{
                        if(!area.value.includes('selectall')){
                            area.children.forEach(areaChild=>{
                                if(userInfo.area.includes(areaChild.value) && !this.assignedArea.includes(area.value)  ){
                                    this.assignedArea.push(area.value)
                                }
                            })
                        }
                      })

                        
                        // console.log(this.assignedArea);
                      console.log("this.areaFather:: ", newArr, this.state )


                      this.state.filteredData.indicatorData.sort(function (a, b) {
                        var ax = [], bx = [];
                        if (a.name != undefined && b.name != undefined) {
                
                          a.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
                          b.name.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
                
                          while (ax.length && bx.length) {
                            var an = ax.shift();
                            var bn = bx.shift();
                            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                            if (nn) return nn;
                          }
                
                          return ax.length - bx.length;
                
                        }
                      });
                
                      this.state.indicatorData = this.state.filteredData.indicatorData;
                    //   console.log("this.state.indicatorData:: ", this.state.indicatorData)

             
                      let child;
                      let k=0;
                      this.state.indicatorData.forEach(element => {


                                this.indi.push(  {
                                    
                                    value: element.name+element.unit,
                                    
                                    label: <><Label>{element.name+',' +element.unit}  <img className='adp_indicator' onClick={()=>this.handleShow(element.metadata,{name:element.name,unit:element.unit})} src='assets/lib/images/home/indicator.svg' /></Label></>,

                                    children :[ { value: 'selectall'+k, label: 'Select All' }]
                                    
                                    })   
                                    
                                    element.subgroup.forEach(it=>{
                                        this.indi[k].children.push({ value: it._id, label: it.name })
                                    })

                                    k+=1;
                                 
                            
                        });
    
                        console.log("this.indi:: ", this.indi)
                        this.setState({nodesIndicator: this.indi})
                        
                      this.state.filteredData.sourceData.sort(function (a, b) {
                        var ax = [], bx = [];
                
                        a.publisher.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
                        b.publisher.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
                
                        while (ax.length && bx.length) {
                          var an = ax.shift();
                          var bn = bx.shift();
                          var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                          if (nn) return nn;
                        }
                
                        return ax.length - bx.length;
                      });
                
                      this.state.sourceData = this.state.filteredData.sourceData;

                      this.state.sourceData.forEach(element => {
                        
                        this.sourcefilter.push(  {
                                  
                            value: element._id,
                            
                            label: <><Label>{element.publisher} </Label></>,

                            })  

                      });
                      

                      this.setState({nodesSource:this.sourcefilter})

                      
                      this.state.filteredData.timePeriodData.sort(function (a, b) {
                        var ax = [], bx = [];
                
                        a.time_period.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
                        b.time_period.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
                
                        while (ax.length && bx.length) {
                          var an = ax.shift();
                          var bn = bx.shift();
                          var nn = (bn[0] - an[0]) || bn[1].localeCompare(an[1]);//for descending order
                          if (nn) return nn;
                        }
                
                        return ax.length - bx.length;
                      });
                
                      
                      //time period list sort
                      this.state.timeperiodData = this.state.filteredData.timePeriodData;

                      
                      this.state.timeperiodData.forEach(element => {
                        
                        this.timedata1.push(  {
                                    
                                    
                            value: element._id,
                            
                            label: <><Label>{element.time_period}  </Label></>,

                            })  

                      });
                      

                      this.setState({nodesTimeperiod:this.timedata1})
                      this.indicatorByGoal = this.state.filteredData.indicatorByGoal;

                      setTimeout(() => {
                        $(document).on('click', '.caret', function(){
                            this.parentElement.querySelector(".nested").classList.toggle("active");
                            this.classList.toggle("caret-down");
                          }) 
                      }, 1000);

                      



                  


                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });
}

AllSourceselect=(e)=>{
    
    if(e.target.checked){
        document.querySelectorAll('.ind-souAll').forEach(e => e.setAttribute('checked', true) )
    }else{
        document.querySelectorAll('.ind-souAll').forEach(e => e.removeAttribute('checked') )
    }

    this.setState({checkOn : e.target.value=='on'? 'off' : 'on'})

    const finalArray = [];

        this.sourcefilter?.forEach(element => {
            finalArray.push(element.value)
    });
    
    this.setState({checkedSource : e.target.value=='on'? finalArray : [] })

}

AllTimeperiodselect=(e)=>{
    
    if(e.target.checked){
        document.querySelectorAll('.ind-tpAll').forEach(e => e.setAttribute('checked', true) )
    }else{
        document.querySelectorAll('.ind-tpAll').forEach(e => e.removeAttribute('checked') )
    }

    this.setState({checkTimeperiod : e.target.value=='on'? 'off' : 'on'})

    const finalArray = [];

        this.timedata1?.forEach(element => {
            finalArray.push(element.value)
    });
  
    this.setState({checkedTimeperiod : e.target.value=='on'? finalArray : [] })

}

AllIndicatorselect=(e)=>{

    const finalArray = [];
    
    if(e.target.checked){
        this.indi?.forEach(element => {
            element.children.forEach(element1 => {
                finalArray.push(element1.value)
            });
            
        });
    }
    this.setState({checkedIndicator :finalArray,checkIndicator:e.target.checked})
    
}

AllAreaselect=(e)=>{
    const finalArray = [];
    
    if(e.target.checked){
        this.areaFather?.forEach(element => {
            element.children.forEach(element1 => {
                // console.log("element1:: ", element1 )
                if(element1.children){
                    element1.children.forEach(element2 => {
                        finalArray.push(element2.value)    
                    })
                }
                finalArray.push(element1.value)
            });
            finalArray.push(element.value)
        });  
    }else{
        
    }
    this.setState({checkedArea :finalArray,checkArea: e.target.checked})

    

}


selectedDownload = () =>{
    console.log("selectedState:: ", this.state)

    let selectDArea = ""
    let selectDIndic = ""
    let selectDTP = ""
    let selectDSource = ""

    let selectedAreas = this.state['checkedArea'].length ? this.state['checkedArea'] : []
    if(selectedAreas.length){
        let selAreas = []
        selectedAreas.forEach(el => {
            if(!el.includes('selectall')){
                selAreas.push(el)
            }
        })
        selectDArea = selAreas.join(',')
    }

    let selectedIndicat = this.state['checkedIndicator'] ? this.state['checkedIndicator'] : []
    if(selectedIndicat.length){
        let selind = []
        selectedIndicat.forEach(el => {
            if(!el.includes('selectall')){
                selind.push(el)
            }
        })
        selectDIndic = selind.join(',')
    }

    
    let selectedTiperd = this.state['checkedTimeperiod'].length ? this.state['checkedTimeperiod'] : []
    if(selectedTiperd.length){
        let selTp = []
        selectedTiperd.forEach(el => {
            if(!el.includes('selectall')){
                selTp.push(el)
            }
        })
        selectDTP = selTp.join('|')
    }
    
    let selectedSource = this.state['checkedSource'].length ? this.state['checkedSource'] : []
    if(selectedSource.length){
        let selSou = []
        selectedSource.forEach(el => {
            if(!el.includes('selectall')){
                selSou.push(el)
            }
        })
        selectDSource = selSou.join('|')
    }    
    

    let obj = {
            "ius": selectDIndic,
            "area": selectDArea,
            "timePeriod": selectDTP,
            "source": selectDSource,
            "download_type": "with_data"
        }

    // console.log("sel:: ", obj)

    AdminService.httpPost('/data-retrieval/data/download-data', obj).then((res)=>{
        // console.log("res:: ", res)
        let filepath = constants.logFilePath + res.filepath;
        // console.log("path:: ", filepath)
        let a = document.createElement("a");
        a.href = filepath;
        // a.download = 'WMIS_' + 'Data_template' + this.todaysDate + '.csv';
        document.body.appendChild(a);
        // return
        // setTimeout(() => {
        a.click();
        //   return
        // }, 1000);
        // return
    })



} 

searchData=(e)=>{
    // console.log("text:: ", e.target.value)
    let textSearch = e.target.value.toLowerCase()
    if(textSearch != '' ){
        document.querySelectorAll('span.ind-ser').forEach(el => {
            if(el.textContent.toLowerCase().includes(textSearch)){
                el.parentNode.parentNode.setAttribute('style', 'display: block;')
            }else{
                el.parentNode.parentNode.setAttribute('style', 'display: none;')
            }
        })
    }else{
        // Empty / Restore
        document.querySelectorAll('span.ind-ser').forEach(el => {
            el.parentNode.parentNode.setAttribute('style', 'display: block;')
        })
    }
}
  

searchAreaData=(e)=>{
    // console.log("text:: ", e.target.value)
    let textSearch = e.target.value.toLowerCase()
    if(textSearch != '' ){
        document.querySelectorAll('span.area-ser').forEach(el => {
            if(el.textContent.toLowerCase().includes(textSearch)){
                el.parentNode.parentNode.setAttribute('style', 'display: block;')
            }else{
                el.parentNode.parentNode.setAttribute('style', 'display: none;')
            }
        })
    }else{
        // Empty / Restore
        document.querySelectorAll('span.area-ser').forEach(el => {
            el.parentNode.parentNode.setAttribute('style', 'display: block;')
        })
    }
}

searchTimeData=(e)=>{
    let textSearch = e.target.value.toLowerCase()
    if(textSearch != '' ){
        document.querySelectorAll('span.time-ser').forEach(el => {
            if(el.textContent.toLowerCase().includes(textSearch)){
                el.parentNode.parentNode.setAttribute('style', 'display: block;')
            }else{
                el.parentNode.parentNode.setAttribute('style', 'display: none;')
            }
        })
    }else{
        // Empty / Restore
        document.querySelectorAll('span.time-ser').forEach(el => {
            el.parentNode.parentNode.setAttribute('style', 'display: block;')
        })
    }
}

searchSourceData=(e)=>{
    let textSearch = e.target.value.toLowerCase()
    if(textSearch != '' ){
        document.querySelectorAll('span.source-ser').forEach(el => {
            if(el.textContent.toLowerCase().includes(textSearch)){
                el.parentNode.parentNode.setAttribute('style', 'display: block;')
            }else{
                el.parentNode.parentNode.setAttribute('style', 'display: none;')
            }
        })
    }else{
        // Empty / Restore
        document.querySelectorAll('span.source-ser').forEach(el => {
            el.parentNode.parentNode.setAttribute('style', 'display: block;')
        })
    }
}


hideIndicator = (section)=>{
    if(this.state.viewSelectedIndicator){
        let hide  =  true
        this.state.nodesIndicator[section].children.forEach(child=>{
           if(this.state.checkedIndicator.includes(child.value)){hide = false} 
        })
        return hide
    }else{
        return false 
    }
}
iusWithData = (e)=>{
    if(e.target.checked){
        let obj = {
            "parameter": "ius",
            "parameter_value_1":this.state.checkedArea.toString(),
            "parameter_value_2":this.state.checkedTimeperiod.toString(),
            "parameter_value_3":this.state.checkedSource.toString()
        }
        AdminService.httpPost('/data-retrieval/data/get-show-exist-data',obj).then((res)=>{
            let filteredData = this.state.nodesIndicator
            let test = []
            res.data.forEach(x=>{
                test.push(x.ius_id)
            });
            console.log(filteredData);
            let nodeData = []
            filteredData.forEach(data=>{
                data.children.forEach(child=>{
                    res.data.forEach(withData=>{
                        if(withData.ius_id==child.value){
                            nodeData.push(withData.ius_id)
                            child.disabled = false
                        }else{
                            child.disabled = true
                        };
                    })
                })
            })
            // console.log(nodeData);
            this.setState({nodesWithData:nodeData,showIndicatorWithData:true})
        }).catch(err=>{
            console.log(err);
        })
    }else{
        this.setState({nodesWithData:[],showIndicatorWithData:false})
    }
}

areaWithData = (e)=>{
    if(e.target.checked){
        let obj = {
            "parameter": "area",
            "parameter_value_1":this.state.checkedIndicator.toString(),
            "parameter_value_2":this.state.checkedTimeperiod.toString(),
            "parameter_value_3": this.state.checkedSource.toString()
        }
        AdminService.httpPost('/data-retrieval/data/get-show-exist-data',obj).then((res)=>{
            let data = []
            res.data.forEach(x=>{
                data.push(x.area_code)
            })
            this.setState({areasWithData:data,showAreaWithData:true})
        }).catch(err=>{
            console.log(err);
        })
    }else{
        this.setState({showAreaWithData:false,showAreaWithData:false})
    }
}

timePeriodWithData = (e)=>{
    if(e.target.checked){
        let obj = {
            "parameter": "timeperiod",
            "parameter_value_1": this.state.checkedArea.toString(),
            "parameter_value_2": this.state.checkedIndicator.toString(),
            "parameter_value_3": this.state.checkedSource.toString()
        }
        AdminService.httpPost('/data-retrieval/data/get-show-exist-data',obj).then((res)=>{
            let data = []
            res.data.forEach(x=>{
                data.push(x.timeperiod.start_time_period)
            })
            this.setState({timePeriodsWithData:data,showTimeperiodWithData:true})
        }).catch(err=>{
            console.log(err);
        })
    }else{
        this.setState({showTimeperiodWithData:false})

    }
}

sourceWithData = (e)=>{
    if(e.target.checked){
        let obj = {
            "parameter": "source",
            "parameter_value_1": this.state.checkedArea.toString(),
            "parameter_value_2": this.state.checkedIndicator.toString(),
            "parameter_value_3": this.state.checkedTimeperiod.toString()
        }
        AdminService.httpPost('/data-retrieval/data/get-show-exist-data',obj).then((res)=>{
            let data = []
            res.data.forEach(x=>{
                data.push(x.source.publisher)
            })
            this.setState({sourcesWithData:data,showSourceWithData:true})
        }).catch(err=>{
            console.log(err);
        })
    }else{
        this.setState({showSourceWithData:false})

    }
}

checkBoxClick = (action,value,tab,section )=>{
    if(tab==1){
        let preValue = this.state.checkedIndicator
        if(action){
            if (value.includes('selectall')) {
                // console.log(section,this.state.nodesIndicator);
                this.state.nodesIndicator[Number(section)].children.forEach(child=>{
                    if(!preValue.includes(child.value)){
                        if(this.state.showIndicatorWithData){
                            if(this.state.nodesWithData.includes(child.value) || child.value.includes('selectall') ){
                                preValue.push(child.value)
                            }
                        }else{
                            preValue.push(child.value)
                        }
                    }
                })
            }else{
                preValue.push(value)
                let allSelected = true
                this.state.nodesIndicator[Number(section)].children.forEach(child=>{
                    if(this.state.showIndicatorWithData){
                        if(!preValue.includes(child.value) && !child.value.includes('selectall') && this.state.nodesWithData.includes(child.value) ){allSelected=false}
                    }else{
                        if(!preValue.includes(child.value) && !child.value.includes('selectall')){allSelected=false}
                    }
                })
                if(allSelected){preValue.push('selectall'+section)}
            }
        }else{
            if(value.includes('selectall')){
                this.state.nodesIndicator[Number(section)].children.forEach(child=>{
                    // console.log(child.value,preValue);
                    let index = preValue.indexOf(child.value)
                    if(index>-1){
                        preValue.splice(index,1)
                    }
                })
            }else{
                let index = preValue.indexOf(value)
                if(index>-1){
                    preValue.splice(index,1)
                }
                let indexOfSelectAll = preValue.indexOf('selectall'+section)
                if(indexOfSelectAll>-1){
                    preValue.splice(indexOfSelectAll,1)
                }
            }

            this.setState({checkIndicator:false})
        }
        this.setState({checkedIndicator:preValue},()=>{console.log(this.state.checkedIndicator);})
    }else if(tab ==2){
        let preValue = this.state.checkedArea
        if(action){
            if(value.includes('selectall')){
                if(section==-1){
                    this.state.nodesArea[0].children.forEach(child=>{
                        if(!preValue.includes(child.value)){
                            if(this.state.showAreaWithData){
                                if((this.state.areasWithData.includes(child.value) && (this.assignedArea.includes(child.value) ||  this.userRole==1 ) || child.value.includes('selectall') )){
                                    preValue.push(child.value)
                                }
                            }else{
                                if((this.assignedArea.includes(child.value) ||  this.userRole==1 )){
                                    preValue.push(child.value)
                                }
                            }
                        }
                    })
                }else if(section>-1){
                    this.state.nodesArea[0].children[section].children.forEach(child=>{
                        if(!preValue.includes(child.value)){
                            if(this.state.showAreaWithData){
                                if((this.state.areasWithData.includes(child.value) && (this.assignedArea.includes(child.value) || this.userRole==1 )) || child.value.includes('selectall') ){
                                    preValue.push(child.value)
                                }
                            }else{
                                if((this.assignedArea.includes(child.value) || this.userRole==1 ) || child.value.includes('selectall')){
                                    preValue.push(child.value)
                                }
                            }
                        }
                    })
                }
            }else{
                preValue.push(value)
                if(section==-1){
                    let allSelected = true
                    this.state.nodesArea[0].children.forEach(child=>{
                        if(this.state.showAreaWithData){
                            if(!preValue.includes(child.value) && !child.value.includes('selectall') && this.state.areasWithData.includes(child.value) && (this.assignedArea.includes(child.value) || this.userRole==1 ) ){allSelected = false}
                        }else{
                            if(!preValue.includes(child.value) && !child.value.includes('selectall') && (this.assignedArea.includes(child.value) || this.userRole==1 ) ){allSelected = false}
                        }
                    })
                    if(allSelected){
                        preValue.push('selectall-area')
                    }
                }else if(section>-1){
                    let allSelected = true
                    this.state.nodesArea[0].children[section].children.forEach(child=>{
                        if(this.state.showAreaWithData){
                            if(!preValue.includes(child.value) && !child.value.includes('selectall') && this.state.areasWithData.includes(child.value) && (this.assignedArea.includes(child.value) || this.userRole==1 ) ){allSelected = false}
                        }else{
                            if(!preValue.includes(child.value) && !child.value.includes('selectall') && (this.assignedArea.includes(child.value) || this.userRole==1 ) ){allSelected = false}
                        }
                    })
                    if(allSelected){ 
                        preValue.push('selectall-area'+(section-1))
                    }
                }
            }
        }else{
            if(value.includes('selectall')){
               if(section==-1){
                this.state.nodesArea[0].children.forEach(child=>{
                    let index = preValue.indexOf(child.value)
                    if(index>-1){
                        preValue.splice(index,1)
                    }
                })
               }else if(section>-1){
                this.state.nodesArea[0].children[section].children.forEach(child=>{
                    let index = preValue.indexOf(child.value)
                    if(index>-1){
                        preValue.splice(index,1)
                    }
                })
               } 
            }else{
                let index = preValue.indexOf(value)
                if(index>-1){
                    preValue.splice(index,1)
                }
                let indexOfSelectAll = section==-1?preValue.indexOf('selectall-area'):preValue.indexOf('selectall-area'+(section-1))
                if(indexOfSelectAll>-1){
                    preValue.splice(indexOfSelectAll,1)
                }
            }
            this.setState({checkArea:false})
        }
        this.setState({checkedArea:preValue},()=>{console.log(this.state.checkedArea);})
    }
    else if(tab ==3){
        let preValue = this.state.checkedTimeperiod
        if(action){
            preValue.push(value)
        }else{
            let index = preValue.indexOf(value)
            if(index>-1){
                preValue.splice(index,1)
            }
        }
        this.setState({checkedTimeperiod:preValue},()=>{console.log(this.state.checkedTimeperiod);})
    }
    else if(tab ==4){
        let preValue = this.state.checkedSource
        if(action){
            preValue.push(value)
        }else{
            let index = preValue.indexOf(value)
            if(index>-1){
                preValue.splice(index,1)
            }
        }
        this.setState({checkedSource:preValue},()=>{console.log(this.state.checkedSource);})
    }
}

handleClose = () => this.setState({show: false,activeMetadata:{}});
handleShow = (data,indicator) => {
    this.setState({activeMetadata:data,show: true,activeIndicator:indicator})
};
handleChange = (event, newValue) => {
    // console.log(newValue);
    this.setState({value: newValue});
}
changeTab = (action) => {
    // console.log(action,this.state.value);
    if(action){

        this.setState({value:(Number(this.state.value)+1).toString()})
    }else{
        this.setState({value:(Number(this.state.value)-1).toString()})
    }
}


render() {
    return (
        <>
            <button onClick={()=> this.setState({checkedTimeperiod : [],
                                            checkedIndicator : [],
                                            checkedArea : [],
                                            checkedSource : [],
                                            viewSelectedIndicator:false,
                                            viewSelectedArea:false,
                                            viewSelectedTime:false,
                                            viewSelectedSource:false,

                                            showIndicatorWithData:false,
                                            showAreaWithData:false,
                                            showTimeperiodWithData:false,
                                            showSourceWithData:false,
                                            value:'1'
                                            })} type="button" class="close" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt="" /></span>
            </button>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={this.state.value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={this.handleChange} aria-label="" className='tabsList '>
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>1</div>
                                        <div className='adph_text'>Indicator</div>
                                        <div className='adph_count'>{this.state.checkedIndicator.length ? this.state.checkedIndicator.filter(e => !e.includes('selectall')).length : '0' }</div>
                                    </div>
                                    } value="1" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>2</div>
                                        <div className='adph_text'>Area</div>
                                        <div className='adph_count'>{this.state.checkedArea.length ? this.state.checkedArea.filter(e => !e.includes('selectall')).length : '0' }</div>
                                    </div>
                                    } value="2" />
                                <Tab label={
                                    <div className='d-flex align-item-center'>
                                        <div className='adph_number'>3</div>
                                        <div className='adph_text'>Time Period</div>
                                        <div className='adph_count'>{this.state.checkedTimeperiod.length ? this.state.checkedTimeperiod.filter(e => !e.includes('selectall')).length : '0' }</div>
                                    </div>
                                    } value="3" />
                                <Tab label={
                            <div className='d-flex align-items-end'>
                                <div className='adph_number'>4</div>
                                <div className='adph_text'>Source</div>
                                <div className='adph_count'>{this.state.checkedSource.length ? this.state.checkedSource.filter(e => !e.includes('selectall')).length : '0' }</div>
                            </div>
                            } value="4" />                       
                        </TabList>
                        <div className='info-rest-icons pr-4'>
                                <ul>
                                    <li>
                                        <div class="dropdown">
                                            <button className="btn btn-primary" type="button" data-toggle="collapse" data-target="#information" aria-expanded="false" aria-controls="information">
                                                <img src="assets/lib/images/home/information.svg" />
                                            </button>
                                        </div>


                                        {/* <Dropdown>
                                            <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                <img src="assets/lib/images/home/information.svg" />
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                <div className='d-flex flex-wrap'>
                                                    <div className='ius_information'>
                                                        <h4>IUS</h4>
                                                        <ul>
                                                            {
                                                                this.state.checkedIndicator.map(ind=>{
                                                                    let in1 = this.state.indicatorData
                                                                    let ius = []
                                                                    in1.forEach(el => {
                                                                        // console.log("iioos:: ", ind , el)
                                                                        let nv = el.subgroup.filter(e => e._id == ind)
                                                                        if(nv.length){
                                                                            let obj = {
                                                                                'ind': el.name,
                                                                                'unit': el.unit,
                                                                                'subgroup': nv[0].name
                                                                            }
                                                                            ius.push(obj)
                                                                        }
                                                                    })
                                                                    if(ius.length){
                                                                        return (
                                                                            <>
                                                                            <li>{ ius[0].ind +' | '+ ius[0].unit +' | '+ ius[0].subgroup }</li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Area</h4>
                                                        <ul>
                                                            {
                                                                this.state.checkedArea.map(ind=>{
                                                                    let in1 = this.state.allareaList
                                                                    let areaName = []
                                                                    in1.forEach(el => {
                                                                        if(el.area_code == ind){
                                                                            areaName.push({ name: el.name })
                                                                        }
                                                                    })
                                                                    if(areaName.length){
                                                                        return (
                                                                            <>
                                                                            <li>{ areaName[0].name }</li>
                                                                            </>
                                                                        )
                                                                    }
                                                                    
                                                                })
                                                            }

                                                        </ul>
                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Time Period</h4>
                                                        <ul>
                                                            {
                                                                this.state.checkedTimeperiod.map(tp=>{
                                                                    return (
                                                                        <>
                                                                        <li>{tp}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                    <div className='ius_information'>
                                                        <h4>Source</h4>                                                        
                                                        <ul>
                                                            {
                                                                this.state.checkedSource.map(source=>{
                                                                    return (
                                                                        <>
                                                                        <li>{source}</li>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </ul>
                                                    </div>
                                                </div>
                                            </Dropdown.Menu>
                                        </Dropdown> */}
                                    </li>
                                    <li><img src="assets/lib/images/home/reset-icon.svg" onClick={()=>{this.setState({checkedIndicator:[],checkedArea:[],checkedTimeperiod:[],checkedSource:[]})}} /></li>
                                </ul>
                            </div>
                    </Box>

                    <div className="collapse addiDataInformation" id="information">
                        <div className="card card-body">
                            <div className='d-flex flex-wrap'>
                                <div className='ius_information'>
                                    <h4>IUS</h4>
                                    <ul>
                                        {
                                            this.state.checkedIndicator.map(ind=>{
                                                let in1 = this.state.indicatorData
                                                let ius = []
                                                in1.forEach(el => {
                                                    // console.log("iioos:: ", ind , el)
                                                    let nv = el.subgroup.filter(e => e._id == ind)
                                                    if(nv.length){
                                                        let obj = {
                                                            'ind': el.name,
                                                            'unit': el.unit,
                                                            'subgroup': nv[0].name
                                                        }
                                                        ius.push(obj)
                                                    }
                                                })
                                                if(ius.length){
                                                    return (
                                                        <>
                                                        <li>{ ius[0].ind +' | '+ ius[0].unit +' | '+ ius[0].subgroup }</li>
                                                        </>
                                                    )
                                                }
                                                
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='ius_information'>
                                    <h4>Area</h4>
                                    <ul>
                                        {/* <li>No Area selected</li> */}
                                        {
                                            this.state.checkedArea.map(ind=>{
                                                let in1 = this.state.allareaList
                                                let areaName = []
                                                in1.forEach(el => {
                                                    if(el.area_code == ind){
                                                        areaName.push({ name: el.name })
                                                    }
                                                })
                                                if(areaName.length){
                                                    return (
                                                        <>
                                                        <li>{ areaName[0].name }</li>
                                                        </>
                                                    )
                                                }
                                                
                                            })
                                        }

                                    </ul>
                                </div>
                                <div className='ius_information'>
                                    <h4>Time Period</h4>
                                    <ul>
                                        {/* <li>No Time Period selected</li> */}
                                        {
                                            this.state.checkedTimeperiod.map(tp=>{
                                                return (
                                                    <>
                                                    <li>{tp}</li>
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='ius_information'>
                                    <h4>Source</h4>                                                        
                                    <ul>
                                        {/* <li>No Source selected</li> */}
                                        {
                                            this.state.checkedSource.map(source=>{
                                                return (
                                                    <>
                                                    <li>{source}</li>
                                                    </>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>


                    <TabPanel value="1">
                    
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox checked={this.state.checkIndicator} onChange={(e)=>this.AllIndicatorselect(e)} />} label="Select All" />
                                    <FormControlLabel control={<Checkbox checked={this.state.viewSelectedIndicator} onChange={(e)=>{this.setState({viewSelectedIndicator:e.target.checked})}} />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox checked={this.state.showIndicatorWithData} onChange={(e)=>{ this.iusWithData(e)}} />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autoComplete="off" onKeyUp={(e)=> this.searchData(e)} name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        {/* <CheckboxTree
                            nodes={this.state.nodesIndicator}
                            checked={this.state.checkedIndicator}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.checkIfDisabled(checked)}
                            onClick={(clicked) => {this.setState({ clicked });console.log(clicked);}}
                            onExpand={(expanded) => this.setState({ expanded })}
                            showNodeIcon={false}
                        />
                         */}

                    
                    <ul className='indicatorULlist' id="myUL">
                        {
                            
                            this.state.nodesIndicator.map((indicator,section)=>{
                                return (

                                    <li><span class={this.hideIndicator(section)?'d-none' :"caret"}></span> <div className='iull_heading'>
                                        <span class={this.hideIndicator(section)?'d-none':"ind-ser"}>{indicator.label}</span></div>
                                            <ul class="nested ">
                                                    {
                                                        indicator.children.map(child=>{
                                                            return (
                                                                    <li> 
                                                                        <div className={this.state.viewSelectedIndicator && !this.state.checkedIndicator.includes(child.value)?'d-none':this.state.showIndicatorWithData && !this.state.nodesWithData.includes(child.value) && !child.value.includes('selectall') ? 'text-gray':''}  >
                                                                            <input
                                                                                disabled={this.state.showIndicatorWithData && !this.state.nodesWithData.includes(child.value) && !child.value.includes('selectall')}
                                                                                checked={this.state.checkedIndicator.includes(child.value)}
                                                                                onChange={(e)=>{this.checkBoxClick(e.target.checked,child.value,1,section)}}
                                                                                class= "area-sel"
                                                                                name= {child.label}
                                                                                type="checkbox" /> 
                                                                            <span
                                                                            // class={ !child.value.includes('selectall') ? 'ind-ser' : '' } 
                                                                            >{child.label}</span>
                                                                        </div>
                                                                    </li>
                                                                )
                                                        })
                                                    }
                                            </ul>

                                    </li>
                                )
                            })
                        }

                    </ul>
                    </TabPanel>

                    <TabPanel value="2">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    {/* <FormControlLabel control={<Checkbox defaultChecked />} label="Select All" /> */}
                                    <FormControlLabel control={<Checkbox checked={this.state.checkArea} onChange={(e)=>this.AllAreaselect(e)} />} label="Select All" />
                                    <FormControlLabel control={<Checkbox checked={this.state.viewSelectedArea} onChange={(e)=> this.setState({viewSelectedArea:e.target.checked}) } />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox checked={this.state.showAreaWithData} onChange={(e)=>{this.areaWithData(e)}}  />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autoComplete="off" onKeyUp={(e)=> this.searchAreaData(e)} name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        {/* <CheckboxTree
                            nodes={this.state.nodesArea}
                            checked={this.state.checkedArea}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.setState({ checked })}
                            onClick={(clicked) => this.setState({ clicked })}
                            onExpand={(expanded) => this.setState({ expanded })}
                        /> */}

                    <ul id="myUL1">
                        {
                            this.state.nodesArea.map(area=>{
                                return (
                                    <li>
                                        <span class="caret caret-down"></span>
                                        <div className={this.state.viewSelectedArea && !this.state.checkedArea.includes(area.value)?'':this.state.showAreaWithData && !this.state.areasWithData.includes(area.value)?'text-gray':'' } >
                                            <input disabled={this.state.showAreaWithData && !this.state.areasWithData.includes(area.value) } checked={this.state.checkedArea.includes(area.value)} onChange={(e)=>{this.checkBoxClick(e.target.checked,area.value,2,-2)}} class="ind-selAll" type="checkbox" /> 
                                            <span>{area.label}</span></div>
                                        <ul class="nested active">
                                            {
                                                area.children.map((child1,section)=>{
                                                    return (
                                                        <li>
                                                            {child1.label!='Select All'?<span class="caret caret-down"></span>:null}
                                                            <div className={this.state.viewSelectedArea && !this.state.checkedArea.includes(child1.value) && !child1.value.includes('selectall') ?'':this.state.showAreaWithData && !this.state.areasWithData.includes(child1.value) && !child1.value.includes('selectall') || !this.assignedArea.includes(child1.value) && (!child1.value.includes('selectall') && this.userRole!=1  ) ?'text-gray':'' } >
                                                                <input disabled={this.state.showAreaWithData && !this.state.areasWithData.includes(child1.value) && !child1.value.includes('selectall') || !this.assignedArea.includes(child1.value) && !child1.value.includes('selectall') && this.userRole!=1 } checked={this.state.checkedArea.includes(child1.value)} onChange={(e)=>{this.checkBoxClick(e.target.checked,child1.value,2,-1)}} type="checkbox" class="ind-selAll"  />
                                                                 <span class={ !child1.value.includes('selectall') ? 'area-ser' : '' } >{child1.label}</span>
                                                            </div>
                                                            <ul class="nested active">
                                                                {
                                                                    child1.children!=undefined?child1.children.map(child2=>{
                                                                        return (
                                                                            <li><div className={this.state.viewSelectedArea && !this.state.checkedArea.includes(child2.value) && !child2.value.includes('selectall') ?'d-none':this.state.showAreaWithData && !this.state.areasWithData.includes(child2.value) && !child2.value.includes('selectall') || !this.assignedArea.includes(child2.value) && !child2.value.includes('selectall') && this.userRole!=1 ?'text-gray':'' } >
                                                                                <input disabled={this.state.showAreaWithData && !this.state.areasWithData.includes(child2.value) && !child2.value.includes('selectall') || !this.assignedArea.includes(child2.value) && !child2.value.includes('selectall') && this.userRole!=1 } checked={this.state.checkedArea.includes(child2.value)} onChange={(e)=>{this.checkBoxClick(e.target.checked,child2.value,2,section)}} type="checkbox" class="ind-selAll" />
                                                                             <span>{child2.label}</span></div>
                                                                            </li>
                                                                        )
                                                                    }):null
                                                                }
                                                                
                                                            </ul>
                                                        </li> 
                                                    )
                                                })
                                            }
 
                                        </ul>
                                    </li>
                                )
                            })
                        }
                    </ul>
                    </TabPanel>
                    <TabPanel value="3">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox value={this.state.checkTimeperiod} onChange={(e)=>this.AllTimeperiodselect(e)} />} label="Select All" />
                                    <FormControlLabel control={<Checkbox checked={this.state.viewSelectedTime} onChange={(e)=>{this.setState({viewSelectedTime:e.target.checked})}}  />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox checked={this.state.showTimeperiodWithData} onChange={(e)=>this.timePeriodWithData(e)} />} label=" Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autoComplete="off" onKeyUp={(e)=> this.searchTimeData(e)} name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        {/* <CheckboxTree
                            nodes={this.state.nodesTimeperiod}
                            checked={this.state.checkedTimeperiod}
                            expanded={this.state.expanded}
                            onCheck={(checked) => this.setState({ checked })}
                            onClick={(clicked) => this.setState({ clicked })}
                            onExpand={(expanded) => this.setState({ expanded })}
                        /> */}
                          <div className='ev_treeList'>
                            <div className='evSubgroup'>                                
                                <ul id="myUL">
                                    {
                                    this.state.nodesTimeperiod.map(tp=>{
                                        return (
                                            <li>
                                                <div className={   this.state.viewSelectedTime && !this.state.checkedTimeperiod.includes(tp.value) ?'d-none':this.state.showTimeperiodWithData && !this.state.timePeriodsWithData.includes(tp.value) ?'text-gray':''} >
                                                    <input disabled={this.state.showTimeperiodWithData && !this.state.timePeriodsWithData.includes(tp.value)} type="checkbox" checked={this.state.checkedTimeperiod.includes(tp.value)} onChange={(e)=>{this.checkBoxClick(e.target.checked,tp.value,3)}} class="ind-tpAll"  />
                                                        <span class="time-ser">{tp.label}</span>
                                                </div>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                             
                            </div> 
                        </div>
                    </TabPanel>
                    <TabPanel value="4">
                        <div className='ads_allselect d-flex align-items-center position-relative'>
                            <div className='ads_as_checkbox'>
                                <FormGroup>
                                    <FormControlLabel control={<Checkbox value={this.state.checkOn} onChange={(e)=>this.AllSourceselect(e)} />} label="Select All" />
                                    <FormControlLabel control={<Checkbox checked={this.state.viewSelectedSource}  onChange={(e)=>this.setState({viewSelectedSource:e.target.checked})}  />} label="View Selected" />
                                    <FormControlLabel control={<Checkbox checked={this.state.showSourceWithData} onChange={(e)=> this.sourceWithData(e) } />} label="Show where data exist" />
                                </FormGroup>
                            </div>
                            <input autoComplete="off" onKeyUp={(e)=> this.searchSourceData(e)} name="search" type="text" className='ads_search' placeholder='Search...' />
                        </div>                        
                        <div className='ev_treeList'>
                            <div className='evSubgroup'>                                
                                <ul id="myUL">
                                    {
                                    this.state.nodesSource.map(sou=>{
                                        return (
                                            <li>
                                                <div className={this.state.viewSelectedSource && !this.state.checkedSource.includes(sou.value) ?'d-none':this.state.showSourceWithData && !this.state.sourcesWithData.includes(sou.value)?'text-gray':''} >
                                                    <input disabled={this.state.showSourceWithData && !this.state.sourcesWithData.includes(sou.value)} type="checkbox" checked={this.state.checkedSource.includes(sou.value)} onChange={(e)=>{this.checkBoxClick(e.target.checked,sou.value,4)}} class="ind-souAll"  />
                                                        <span class="source-ser">{sou.label}</span>
                                                </div>
                                            </li>
                                            )
                                        })
                                    }
                                </ul>
                            </div> 
                        </div>
                        
                    </TabPanel>
                </TabContext>
            </Box>
            <div className='addiDataFooter'>
                <div onClick={(e)=>{ this.changeTab(false)}} className={this.state.value>1?'adfBtn adfleft':'adfBtn adfleft disabled'}>
                    <img src="assets/lib/images/home/arrow_right2.svg" />
                </div>
                <div onClick={this.selectedDownload}  className= {this.state.checkIndicator.length==0 || this.state.checkedArea.length==0 || this.state.checkedTimeperiod.length==0 || this.state.checkedSource.length==0 ?'adfok disabled':'adfok'}>
                    OK
                </div>
                <div onClick={(e)=>{this.changeTab(true)}} className={this.state.value<4?'adfBtn adfRight ':'adfBtn adfRight disabled '}>
                    <img src="assets/lib/images/home/arrow_right2.svg" />
                </div>
            </div>



      {/* ================================================================ Modal ======================================================= */}
        <Modal centered show={this.state.show} onHide={this.handleClose} className="addiDataPopup indicator_popup icPopupList">
            <Modal.Header closeButton>
              <Modal.Title> {this.state.activeIndicator.name} <span>| {this.state.activeIndicator.unit}</span></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    this.state.activeMetadata.hasOwnProperty('mt1')?
                    <div className='indictorModalContent'>
                        <span>
                            <h4>Indicator Definition</h4>
                            <p>{this.state.activeMetadata.mt1==''?'--':this.state.activeMetadata.mt1}</p>
                        </span>
                        <span>
                            <h4>Method of Computation</h4>
                            <p>{this.state.activeMetadata.mt2==''?'--':this.state.activeMetadata.mt2}</p>
                        </span>
                        <span>
                            <h4>Overview</h4>
                            <p>{this.state.activeMetadata.mt3==''?'--':this.state.activeMetadata.mt3}</p>
                        </span>
                        <span>
                            <h4>Comments and Limitations</h4>
                            <p>{this.state.activeMetadata.mt4==''?'--':this.state.activeMetadata.mt4}</p>
                        </span>
                        <span>
                            <h4>Data Collection for Global Monitoring</h4>
                            <p>{this.state.activeMetadata.mt5==''?'--':this.state.activeMetadata.mt5}</p>
                        </span>
                        <span>
                            <h4>Obtaining Data</h4>
                            <p>{this.state.activeMetadata.mt6==''?'--':this.state.activeMetadata.mt6}</p>
                        </span>
                        <span>
                            <h4>Data Availability</h4>
                            <p>{this.state.activeMetadata.mt7==''?'--':this.state.activeMetadata.mt7}</p>
                        </span>
                        <span>
                            <h4>Treatment of Missing Values</h4>
                            <p>{this.state.activeMetadata.mt8==''?'--':this.state.activeMetadata.mt8}</p>
                        </span>
                        <span>
                            <h4>Regional and Global Estimates</h4>
                            <p>{this.state.activeMetadata.mt9==''?'--':this.state.activeMetadata.mt9}</p>
                        </span>
                    </div>:
                    <div className='no_data'><p>No Data</p></div>

                } 
            </Modal.Body>            
        </Modal>
      {/* ================================================================ Modal ======================================================= */}












        </>
    );
                        }
}

export default AddiDataScreenModal;