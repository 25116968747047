import React, {useEffect, useState } from "react"; 


import $ from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import FileUploader from 'devextreme-react/file-uploader';
import axios from "axios";
import * as constant from '../../../Config/app.constants'
import AdminService from '../../../services/admin.service';
import ProgressBar from 'react-bootstrap/ProgressBar';

 function ImportData({value}) {
     const [file1, setFile] = useState();
     const [importData, SetImportData] = useState();
     const [percent, setPercent] = useState();
     const [errorpercent, setErrorPercent] = useState();
     const [errorData, SetErrorData] = useState();
     const [statusData, setStatusData] = useState();
     const fileReader = new FileReader();
     const formdata = new FormData();
     
    console.log("type"+value)
    const now = 0;
    useEffect(() => { setPercent(now); setErrorPercent(now); }, [])
     const handleOnChange = (e) => {
         setFile(e.target.files[0]);
         
        
         $("button").removeClass("disable_bg");
         
       
     };
    //  setPercent(0);

     if(value==4){
      
       AdminService.httpGet('/data-import/uploadedFile/last-upload-file/'+value).then((res)=>{
        
          if(res){
              
              let d = res.data;
              
              

                          
                  let filepath = d[0]?.file_name;
                  let createdate = d[0]?.createdAt;
                  let status = d[0]?.status;

                  
                  console.log("filepath:"+filepath);
                  $(".fileName").html(filepath);
                  $(".currentDate").html(createdate);
                  if(status==1){
                    $(".animate-text").html("Completed");
                  }
  
  
       
            
          }
      
      }).catch((err)=>{
      
      console.log(err)
      })

     }

     if(value==5){

      AdminService.httpGet('/data-import/uploadedFile/last-upload-file/'+value).then((res)=>{
       
         if(res){
             
             let d = res.data;
             
             

                         
                 let filepath = d[0]?.file_name;
                 let createdate = d[0]?.createdAt;
                 let status = d[0]?.status;

                 
                 console.log("filepath:"+filepath);
                 $(".fileName").html(filepath);
                 $(".currentDate").html(createdate);
                 if(status==1){
                   $(".animate-text").html("Completed");
                 }
 
 
      
           
         }
     
     }).catch((err)=>{
     
     console.log(err)
     })

    }

    if(value==6){

      AdminService.httpGet('/data-import/uploadedFile/last-upload-file/7').then((res)=>{
       
         if(res){
             
             let d = res.data;
             
             

                         
                 let filepath = d[0]?.file_name;
                 let createdate = d[0]?.createdAt;
                 let status = d[0]?.status;

                 
                 console.log("filepath:"+filepath);
                 $(".fileName").html(filepath);
                 $(".currentDate").html(createdate);
                 if(status==1){
                   $(".animate-text").html("Completed");
                 }
 
 
      
           
         }
     
     }).catch((err)=>{
     
     console.log(err)
     })

    }

    if(value==1){

      AdminService.httpGet('/data-import/uploadedFile/last-upload-file/1').then((res)=>{
       
         if(res){
             
             let d = res.data;
             
             

                         
                 let filepath = d[0]?.file_name;
                 let createdate = d[0]?.createdAt;
                 let status = d[0]?.status;

                 
                 console.log("filepath:"+filepath);
                 $(".fileName").html(filepath);
                 $(".currentDate").html(createdate);
                 if(status==1){
                   $(".animate-text").html("Completed");
                 }
 
 
      
           
         }
     
     }).catch((err)=>{
     
     console.log(err)
     })

    }


     
     const handleOnSubmit = (e) => {         e.preventDefault();     
     
        const myFile = $('input[type=file]')[0].files[0];
        
        console.log("reader--"+fileReader.readAsText(myFile))
        
        formdata.append('files', file1);

        if(value==1){
            const savedItem = JSON.parse(localStorage.getItem("userInfo"));
            console.log("saved--"+savedItem)
            let id = savedItem.id;
            console.log("id--"+id)
            // const parsedItem = JSON.parse(savedItem);
            //     // formdata.append('user_id',)
            formdata.append('import_for', value);
            formdata.append('user_id', id);
        }else if(value==6){
            value=7;
            console.log("print--"+value);
            formdata.append('import_for', value);
        }else{
            formdata.append('import_for', value);
        }
        
        console.log("myFile"+file1)

        //    const dataToBeSend = {
        //     'import_for' : 4
        //     "files[]" : 6
        
        // }
        
        if(value==4){

        AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
            if(res){
                
                let d = res.data;
                $(".animate-text").html("Progress");
                console.log("data------"+res.import_id)
                $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
                console.log("console",res.message)

                if(res.message!=undefined){
                  if(  (res?.message).split(":")[0] == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error(res?.message);
                    
                  }
                }
                
              
              // else{
              //     toast.success("Failed to approve data");

              // }
                    
                          
                var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';

                      var  logInterval =  setInterval(async function () {

                        // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                              
                        await $.ajax({
                               url: fileName, success:  function (response) {

                                if (response) {
                                    $(".animate-text").html("Completed");
                                    $(".fileName").html(res.name);
                                    $(".currentDate").html(res.currentDate);
                
                                  var result = response.split('=');
                
                                  var total = result[0].split(':');
                                  
                                  
                                  var totalData = total[1];
                                  $(".total_record").html(totalData);
                
                                  //import record count
                                  var importD = result[1].split(':');
                                  var importData = importD[1];
                                  
                                  
                                  var width = ((parseInt(importData)  * 100) / parseInt(totalData)) ;
                                  
                                  width = Math.round(width);
                                  
                                  setPercent(width)
                                  //error count
                                  var errorD = result[2].split(':');
                                  var errorData = errorD[1];
                                  var width1 = (errorData * 100) / totalData;
                                  width1 = Math.round(width1);
                                  setErrorPercent(width1);
                
                                  //update count
                                  var updateD = result[3].split(':');
                                  var updateData = updateD[1];
                                  var width2 = (updateData * 100) / totalData;
                                  width2 = Math.round(width2);
                                  $(".count_imported").html(parseInt(importData));
                                  $(".update_imported").html(updateData);
                                  $(".error_imported").html(errorData);
                                  SetErrorData(errorData)
                                  SetImportData(parseInt(importData) + parseInt(updateData))
                                 
                                  var statusComplete = result[4].split(':');
                                  var statusCompleteGet = statusComplete[1];
                                  $(".mt-1").show();
                                  $(".mt-2").show();
                                  setStatusData("completed");
                
                                  if (statusCompleteGet == 1) {
                
                                    // $(".statusImport").hide();
                                    // $(".statusFailed").hide();
                                    // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                    // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                    clearInterval(logInterval);
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                    // $(document).on('hidden.bs.modal', '#importModal', function () {
                                    //   location.reload();
                                    // });
                                  } else {
                                    // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                  }
                
                
                                    
                                }
                              
                            // })

                        }
                    });
                    setTimeout(()=>{
                      window.location.reload(false);
                  }, 3000);
                          }, 3000);


                        
                      
                  
               

            }

})  
.catch((err)=>{
  if(  err?.message == 'Invalid file format'){
   
    toast.error("Invalid file format");
    
    }
    console.log(err)
});

        }

       else if(value==5){
        
            AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                if(res){
                    
                    let d = res.data;
                    $(".animate-text").html("Progress");
                    console.log("data------"+res.import_id)
                    $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
                if(res.message!=undefined){
                  if(  (res?.message).split(":")[0] == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error(res?.message);
                    
                  }
                }
                        
                              
                    var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress_ius/progress_' + res.import_id + '.txt';
    
                          var  logInterval =  setInterval( function () {
    
                            // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                  
                            $.ajax({
                                url: fileName, success: function (response) {
    
                                    if (response) {
                                        $(".animate-text").html("Completed");
                                        $(".fileName").html(res.name);
                                        $(".currentDate").html(res.currentDate);
                    
                                      var result = response.split('=');
                    
                                      var total = result[0].split(':');
                                      
                                      
                                      var totalData = total[1];
                                      $(".total_record").html(totalData);
                    
                                      //import record count
                                      var importD = result[1].split(':');
                                      var importData = importD[1];
                                      var width = (importData * 100) / totalData;
                                      width = Math.round(width);
                                      setPercent(width)
                                      //error count
                                      var errorD = result[3].split(':');
                                      var errorData = errorD[1];
                                      var width1 = (errorData * 100) / totalData;
                                      width1 = Math.round(width1);
                                      setErrorPercent(width1);
                    
                                      //update count
                                      var updateD = result[4].split(':');
                                      var updateData = updateD[1];
                                      var width2 = (updateData * 100) / totalData;
                                      width2 = Math.round(width2);
                                      $(".count_imported").html(parseInt(importData) );
                                      $(".update_imported").html(updateData);
                                      $(".error_imported").html(errorData);
                                      var statusComplete = result[5].split(':');
                                      var statusCompleteGet = statusComplete[1];
                                      SetErrorData(errorData)
                                  SetImportData(parseInt(importData) + parseInt(updateData))
                                      $(".mt-1").show();
                                      $(".mt-2").show();
                                      setStatusData("completed");
                    
                                      if (statusCompleteGet == 1) {
                    
                                        // $(".statusImport").hide();
                                        // $(".statusFailed").hide();
                                        // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                        // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                        clearInterval(logInterval);
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                        // $(document).on('hidden.bs.modal', '#importModal', function () {
                                        //   location.reload();
                                        // });
                                      } else {
                                        // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                      }
                    
                    
                    
                                    }
                                  
                                // })
    
                            }
                        });
                        setTimeout(()=>{
                          window.location.reload(false);
                      }, 3000);
                    
                              }, 3000);
    
    
                            
                          
                      
                   
    
                }
    
    })  
    .catch((err)=>{
        
        console.log(err)
    });
    
            }


            else if(value==1){
        
                AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                    if(res){
                        
                        let d = res.data;
                        $(".animate-text").html("Progress");
                        console.log("data------"+res.import_id)
                        $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
                if(res.message!=undefined){
                  if(  (res?.message).split(":")[0] == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error(res?.message);
                    
                  }
                }
                                  
                        var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
        
                              var  logInterval =  setInterval(async function () {
        
                                // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                      
                            await    $.ajax({
                                    url: fileName, success: function (response) {
        
                                        if (response) {
                                            $(".animate-text").html("Completed");
                                            $(".fileName").html(res.name);
                                            $(".currentDate").html(res.currentDate);
                        
                                          var result = response.split('=');
                        
                                          var total = result[0].split(':');
                                          
                                          
                                          var totalData = total[1];
                                          if(totalData=="null"){
                                            $(".total_record").html("0");
                                          }else{
                                            $(".total_record").html(totalData);
                                          }
                                          
                        
                                          //import record count
                                          var importD = result[1].split(':');
                                          var importData = importD[1];
                                          var width = (importData * 100) / totalData;
                                          width = Math.round(width);
                                          setPercent(width)
                                          //error count
                                          var errorD = result[2].split(':');
                                          var errorData = errorD[1];
                                          var width1 = (errorData * 100) / totalData;
                                          width1 = Math.round(width1);
                                          setErrorPercent(width1);
                        
                                          //update count
                                          var updateD = result[3].split(':');
                                          var updateData = updateD[1];
                                          var width2 = (updateData * 100) / totalData;
                                          width2 = Math.round(width2);
                                          if(parseInt(importData) + parseInt(updateData)=="null"){
                                            $(".count_imported").html("0");
                                          }else{
                                            $(".count_imported").html(parseInt(importData) );
                                          }
                                          if(updateData=="null"){
                                            $(".update_imported").html("0");
                                          }else{
                                            $(".update_imported").html(updateData);
                                          }
                                          if(errorData=="null"){
                                            $(".error_imported").html("0");
                                          }else{
                                            $(".error_imported").html(errorData);
                                          }
                                        //   $(".count_imported").html(parseInt(importData) + parseInt(updateData));
                                        //   $(".update_imported").html(updateData);
                                        //   $(".error_imported").html(errorData);
                                          var statusComplete = result[4].split(':');
                                          var statusCompleteGet = statusComplete[1];
                                          SetErrorData(errorData)
                                          SetImportData(parseInt(importData) + parseInt(updateData))
                                              $(".mt-1").show();
                                              $(".mt-2").show();
                                              setStatusData("completed");
                                          if (statusCompleteGet == 1) {
                        
                                            // $(".statusImport").hide();
                                            // $(".statusFailed").hide();
                                            // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                            // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                            clearInterval(logInterval);
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                            // $(document).on('hidden.bs.modal', '#importModal', function () {
                                            //   location.reload();
                                            // });
                                          } else {
                                            // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                          }
                        
                        
                        
                                        }
                                      
                                    // })
        
                                }
                            });
                            setTimeout(()=>{
                              window.location.reload(false);
                          }, 3000);
                                  }, 3000);
        
        
                                
                              
                          
                       
        
                    }
        
        })  
        .catch((err)=>{
            
            console.log(err)
        });
        
                }

                else if(value==7){
        
                    AdminService.httpPostFile('/data-import/file-upload',formdata).then((res)=>{
                        if(res){
                            
                          console.log("rishav")
                            let d = res.data;
                            $(".animate-text").html("Progress");
                            console.log("data------"+res.import_id)

                            $(".mt-1").hide();
                $(".mt-2").hide();
                setStatusData("progress");
                if(res.message!=undefined){
                  if(  (res?.message).split(":")[0] == 'Invalid file format' || res?.message == 'Invalid file format: areaid'){
                    $('#importClose').trigger('click');
                    toast.error(res?.message);
                    
                  }
                }
                                      
                            var fileName = constant.server + 'api/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt';
            
                                  var  logInterval =  setInterval( function () {
            
                                    // AdminService.httpGetImport('/data-import/uploadedFile/get_import_progress/progress_' + res.import_id + '.txt').then((response)=>{
                                          
                                    $.ajax({
                                        url: fileName, success: function (response) {
            
                                            if (response) {
                                                $(".animate-text").html("Completed");
                                                $(".fileName").html(res.name);
                                                $(".currentDate").html(res.currentDate);
                            
                                              var result = response.split('=');
                            
                                              var total = result[0].split(':');
                                              
                                              
                                              var totalData = total[1];
                                              $(".total_record").html(totalData);
                            
                                              //import record count
                                              var importD = result[1].split(':');
                                              var importData = importD[1];
                                              var width = (importData * 100) / totalData;
                                              width = Math.round(width);
                                              setPercent(width)
                                              //error count
                                              var errorD = result[2].split(':');
                                              var errorData = errorD[1];
                                              var width1 = (errorData * 100) / totalData;
                                              width1 = Math.round(width1);
                                              setErrorPercent(width1);
                            
                                              //update count
                                              var updateD = result[3].split(':');
                                              var updateData = updateD[1];
                                              var width2 = (updateData * 100) / totalData;
                                              width2 = Math.round(width2);
                                              $(".count_imported").html(parseInt(importData));
                                              $(".update_imported").html(updateData);
                                              $(".error_imported").html(errorData);
                                              var statusComplete = result[4].split(':');
                                              var statusCompleteGet = statusComplete[1];
                            
                                              SetErrorData(errorData)
                                              SetImportData(parseInt(importData) + parseInt(updateData))
                                                  $(".mt-1").show();<p>Update Record : <span class="update_imported"></span></p>
                                                  $(".mt-2").show();
                                                  setStatusData("completed");

                                              if (statusCompleteGet == 1) {
                            
                                                // $(".statusImport").hide();
                                                // $(".statusFailed").hide();
                                                // $("#filestatus").html(that.translate.instant('completed')).css({ 'color': '#64B85B !important;', 'font-size': '21px;', 'font-weight': 'bold;' });
                                                // $("#statusProgress").css({ 'width': '100%', 'display': 'block' });
                                                clearInterval(logInterval);
                                                // that.getImportLog('progress_' + data.result.import_id + '.txt', 1);
                                                // $(document).on('hidden.bs.modal', '#importModal', function () {
                                                //   location.reload();
                                                // });
                                              } else {
                                                // that.getImportLog('progress_' + data.result.import_id + '.txt', 0);
                                              }
                            
                            
                            
                                            }
                                          
                                        // })
            
                                    }
                                });
                                setTimeout(()=>{
                                  window.location.reload(false);
                              }, 3000);
                                      }, 3000);
            
            
                                    
                                  
                              
                           
            
                        }
            
            })  
            .catch((err)=>{
                
                console.log(err)
            });
            
                    }
             

     };


     

     return (
         <div style={{ textAlign: "center" }}>
            
             <form>

                            <div>
                                <div className="button_box_3 d-flex justify-content-center flex-wrap mt-5 mb-40">
                                    <input type="file" name="file-input" id="file-input" accept=".csv" onChange={handleOnChange} class="file-input__input"/>
                                    <label class="file-input__label popup_btn" for="file-input"><span> Browse File </span></label>
                                    <button  class="btn btn_upload w-25 disable_bg mx-0" id="download_icius_master_template_with_data"    onClick={(e) => {
                            handleOnSubmit(e);}}>Upload</button>
                                </div>
                                {/* <ImportData value={value} /> */}                                

                                <div className='last-update-text text-left mb-3'>
                                    <h4>Last Upload</h4>
                                    <p>File Name : <span class="fileName"></span></p>
                                    <p>Status : <span  className='animate-text'>Completed</span></p>
                                    <p>Total : <span class="total_record"></span></p>
                                    <p>Total Imported : <span class="count_imported"></span></p>
                                    {/* <p>Update Record : <span class="update_imported"></span></p> */}
                                    <p>Error Record : <span class="error_imported"></span></p>
                                    <p>Date & Time : <span class="currentDate"></span></p>
                                    <p>Progress 
                                      <ProgressBar animated striped variant="success" now={percent}  label={`${percent}`} visuallyHidden className="mt-1" />
                                    </p>
                                    <p>Error 
                                      <ProgressBar animated striped variant="danger" now={errorpercent}  label={`${errorpercent}`} visuallyHidden className="mt-2" />
                                    </p>
                                    <p className="mt-3">
                                      <ProgressBar variant="success" label={statusData} now={100} />
                                    </p>
                                </div>
                            </div>


             </form>
         </div>
     );
 }
export default ImportData;
