import React, { Component } from 'react'
import './metadata.css';
import * as jsx from './metadata.module.jsx';
import * as $ from 'jquery';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as constants from '../../../Config/app.constants';
import AdminService from '../../../services/admin.service';
import swal from 'sweetalert';
import Tooltip from '@mui/material/Tooltip';
class Metadata extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            // mainData: [],
            isEdit: false,
            editModeId: null,
            modal: false,
            loader: false,
            dataLimit: 10,
            dataStatus: 2,
            indicatorData: [],
            rowIndData: [],
            subIndData: [],
            areaData: [],
            bulkDelete :[],
            editEntry:[],

        }
    
        // this.globalState = stateUtilityInstance.default;



    }


   
    componentDidMount() {
        this.setState({
            role: [
                {id: 'DE', name: 'DataEntry'},
                {id: 'DA', name: 'DataApprove'}
               
            ]
        });
        this.getData();
        this.getAllIndicators();
        AdminService.checkToken();


      
    }

    render() {
        return jsx.default.bind(this)();
    }



    // update data limit===================//

    updateDataLimit = (e) => {


        this.setState({ dataLimit: e.target.value })
        this.getData();

    }



    openEditModal(){
        if($('#EditAreaModal').hasClass('width350')){
            $('#EditAreaModal').removeClass('width350')
            $('#EditAreaModal').addClass('width0')
            $('.overlay').removeClass('dblock')
        }
        else{
            $('#EditAreaModal').addClass('width350')
            $('#EditAreaModal').removeClass('width0')
            $('.overlay').addClass('dblock')
        }
    }

/////open edit model function

editMode (event, row_id) {
    console.log("callllllllll");
    event.preventDefault();
    this.setState({isEdit:true,
                    modal: true});

    this.state.data.forEach((item)=>{
        if(item._id==row_id){
            this.setState({editModeId:item});
        }
    });
                    
    this.openEditModal()
    
}



openAddModal = () => {

    if (this.state.modal == false) {

        this.setState({ modal: true })

    } else {
        this.setState({ modal: false,
                        isEdit: false,
                        editModeId: null })
    }

}



    // get data by status=================//


    getDataByStatus = (e) => {

        this.setState({ dataStatus: e.target.value })
        this.getData();
        
    }

    // filter table and get data====================//



    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) =>row.index+1,
        //         // <>{index+1}</>,
        //     sortable: true,
        //     width: "50px"
        // },
        {
            name: 'Indicator',
            selector: row => row.name,
            // selector: row => <Tooltip title={row.name} placement="top">
            //     <p>{row.name}</p>
            // </Tooltip>,            
            sortable: true,
            width: "350px"
        },
        {
            name: 'Unit',
            selector: row => row.unit,
            sortable: true,
            width: "100px"
        },
        {
            name: 'Indicator Definition',
            selector: row => <Tooltip title={row.metadata.mt1} placement="top">
            <p>{row.metadata.mt1}</p>
            </Tooltip>, 
            sortable: true,
            width: "300px"
        },
        {
            name: 'Method of Computation',
            selector: row => <Tooltip title={row.metadata.mt2} placement="top">
            <p>{row.metadata.mt2}</p>
            </Tooltip>,
            sortable: true,
            width: "150px"
        },

        {
            name: 'Overview',            
            selector: row => <Tooltip title={row.metadata.mt3} placement="top">
            <p>{row.metadata.mt3}</p>
            </Tooltip>,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Comments and Limitations',            
            selector: row => <Tooltip title={row.metadata.mt4} placement="top">
            <p>{row.metadata.mt4}</p>
            </Tooltip>,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Data Collection for Global Monitoring',
            selector: row => <Tooltip title={row.metadata.mt5} placement="top">
            <p>{row.metadata.mt5}</p>
            </Tooltip>,
            sortable: true,
            width: "180px"
        },
        {
            name: 'Obtatning Data',
            selector: row => <Tooltip title={row.metadata.mt6} placement="top">
            <p>{row.metadata.mt6}</p>
            </Tooltip>,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Data Availability',
            selector: row => <Tooltip title={row.metadata.mt7} placement="top">
            <p>{row.metadata.mt7}</p>
            </Tooltip>,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Treatment of Missing Values',
            selector: row => <Tooltip title={row.metadata.mt8} placement="top">
            <p>{row.metadata.mt8}</p>
            </Tooltip>,
            sortable: true,
            width: "150px"
        },
        {
            name: 'Regional and Global Estimates',
            selector: row => <Tooltip title={row.metadata.mt9} placement="top">
            <p>{row.metadata.mt9}</p>
            </Tooltip>,
            sortable: true,
            width: "170px"
        },
        {
            name: 'Action',
            width: "200px",
            cell: row =>
                <>
                  {/* <span className="action_specific ng-star-inserted">
                        <img   onClick={(e) => this.editMode(e, row)} className="cursor-pointer modal-btn" data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host=""

                        />
                          <label  className="switchone switch-checked" 
                        mattooltipposition="above" ng-reflect-position="above" 
                        ng-reflect-message="Status" aria-describedby="cdk-describedby-message-26" 
                        cdk-describedby-host="" 
                        style={{'touchAction': 'none', 'userSelect': 'none', '-webkitUserDrag': 'none', 
                        'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)', 'marginRight': '20px'}}>
                <input onChange={(e) => this.changeStatus(e, row._id)} 
                    data-size="mini" 
                    type="checkbox" 
                    defaultChecked={row.status? row.status == 1: false}/>
                    <div>
                    </div>
                </label>              
                        <span class="deleteIcon text-success" onClick={(e)=>{this.deleteEntry(row._id)}}><i class="fa fa-trash"></i></span>
                    </span> */}

                    <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    {/* <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id)}  > {row.unitStatus==0?<>Show</>:<>Hide</>}</a> */}
                        <a class="dropdown-item" onClick={(e) => this.editMode(e, row._id)} href="javascript:;">Edit</a>
                        <a class="dropdown-item" onClick={(e)=>{this.deleteEntry(row._id)}} href="javascript:;">Delete</a>
                    </div>
                </div> 
                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];





   







    render() {
        return jsx.default.bind(this)();
    }



    editDataEntry = (id) => {


        const edData = [];
        this.state.filteredData.forEach((item)=>{

            if(item._id==id){
                edData.push(item)
               return;
            }

        });

        this.setState({editEntry:edData})
        this.setState({ isEdit: true })
        this.openAddModal();

    }
    


      
////=========get data api==========
getData = async () => {
    this.setState({ loader: true, searchText: "" });
    const dataToBeSend = {
        "size": "25",
        "totalElements": 0,
        "totalPages": 0,
        "pageNumber": 0,
        "filterKeyWord": "",
        "startOffset": 0,
        "endOffset": 0
    }


    AdminService.httpPost('/data-retrieval/metadata/get-list', dataToBeSend).then((res)=>{
                if(res){
                    this.setState({loader:false})
                    let d = res.data;
                    d.forEach((ele  , index)=>{ele.index = index })
                    this.setState({filteredData: d, data:d})
                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });






}



 // get sub indi cators ===================//

 getSubIndicators = async (id) => {

 console.log(  this.state.rowIndData.data)
    const bindData = [];
  
    this.state.rowIndData.data.forEach(item=>{
      
       if(item.name==id){
     
        bindData.push({label:item.unit, value:item.unit})
     
       }
    })
      
      
        

    console.log("qqqq",bindData)

this.setState({ subIndData: bindData })
}


 // delete entry===========//
        deleteEntry = (id) =>{

            swal({
                title:"Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this metadata!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if(willDelete) {
                    const dataToBeSend = {
                        "id": id
                    }
                
                    AdminService.httpPost('/data-import/metadata/delete-indicator-metadata', dataToBeSend).then((res) => {
        
                        if (res) {
                            this.setState({ loader: false })
                            let d = res;
                
                            console.log(res)
                
                            if(  res.success==true){
                                toast.success("Metadata deleted successfully!");
                                this.getData();
                            }
                            else{
                                toast.error("Failed to delete metadata");
                
                            }
                       
                        }
                
                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                        toast.error(err);
                    });
                  }                
                });     
        
        
        
        
        
        
          }
        

    


/////indicator list=========>

getAllIndicators = async () => {


    AdminService.httpGet('/data-retrieval/ius/get-ius-maping').then((res) => {
        if (res) {

            let d = res.data;
            console.log(d)

            let bindData = [];
            d.forEach(item => {

                bindData.push({ label: item.name, value: item._id, unit:item.unit})
       

            });

            console.log('ind',bindData)
            this.setState({ indicatorData: bindData, rowIndData:d })


        }

    }).catch((err) => {

        console.log(err)
    });






}



//==========add and edit api user=========///
saveMetaData = (saveData) => {


    console.log('svdata',this.state.isEdit , saveData)

    if( this.state.isEdit && saveData.id!=null){
    const data= {
            "id": saveData.id,
           "indicator":saveData.indicator,
           "unit":saveData.unit,
            "mt1": saveData.mt1,     
            "mt2": saveData.mt2,
            "mt3": saveData.mt3,
            "mt4": saveData.mt4,
            "mt5": saveData.mt5,
            "mt6": saveData.mt6,
            "mt7": saveData.mt7,
            "mt8": saveData.mt8,
            "mt9": saveData.mt9, 
           
    }
    console.log("hhhhhh",data)
    AdminService.httpPut('/data-import/metadata/update/'+saveData.id,data).then((res)=>{
        if(res){
            // console.log("data" , res );
            this.setState({loader:false})
            let d = res.data;
            if(  res.status != 0 && res.status==1){
                this.setState({ loader: false })
                toast.success("Metadata updated successfully!");
                this.openAddModal();
                this.getData();
            }

            else if(res.status == 0 ){
                
                this.setState({ loader: false })
                toast.error('Failed to update metadata');

            }
     
            else{
                this.getData();
                this.openAddModal();
                this.setState({ loader: false })
                toast.success(res.message);

            }
       
        }

}).catch((err) => {
this.setState({ loader: false })
toast.error("Failed to update metadata!");
console.log(err)
});
  
}
else{
        const getdata = {
            "id": null,
            "indicator": saveData.indicator,
            "unit": saveData.unit,
            "mt1": saveData.mt1,
            "mt2": saveData.mt2,
            "mt3": saveData.mt3,
            "mt4": saveData.mt4,
            "mt5": saveData.mt5,
            "mt6": saveData.mt6,
            "mt7": saveData.mt7,
            "mt8": saveData.mt8,
            "mt9": saveData.mt9
        }



    console.log("test by done",saveData.mt2)
    AdminService.httpPost('/data-import/metadata/add',getdata).then((res)=>{
        if(res){
            console.log('data--------->'  , res);
             let d = res.getdata;
             if(  res.status ==200){
                this.setState({ loader: false })
                toast.success("Metadata added successfully!");
                this.openAddModal();
                this.getData();
            }
     
            else{
                this.openAddModal();
                this.getData();
                this.setState({ loader: false })

                toast.error('Failed to add metadata');


            }
        }
        }).catch((err) => {
            this.setState({ loader: false })
            toast.error('Failed to add metadata!');

            console.log(err)
        });
 }
    //   console.log("function called with state");
    //   console.log(saveData);
    //   this.getData();
    //   this.openAddModal();

  
   

}



    // open add modal=====================//
    openAddModal = () => {
            console.log("data" , this.state.modal);
        if (this.state.modal == false) {

            this.setState({ modal: true })
           

        } else {
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            
            this.setState({ editEntry: [] })
        }

    }


    ////<==============serach function=====>////
    onSearch = (val) => {
        this.setState({searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => ((item.name && item.name.toLowerCase().includes(val.toLowerCase()))
                || (item.unit && item.unit.toLowerCase().includes(val.toLowerCase()))
                || (item.metadata.mt1 && item.metadata.mt1.toLowerCase().includes(val.toLowerCase()))
             
               
                
            ));
        }
        
        this.setState({filteredData : fd});
    }
///multiple delete

    handleChange = ({ selectedRows }) => {
  
        this.setState({bulkDelete:selectedRows})
    
      };
      
      
    
        handleChangeData = ({ toggleCleared }) => {
            this.setState({bulkDelete:toggleCleared})
    
      };
      
    
    
    
    
      
    
      //////////=========checkbox delete============/////////
    
      deleteBulkUser = (e) =>{
    let ids = [];
    
    this.state.bulkDelete.forEach(item=>{
        ids.push(item._id)
    })
    
    if(ids?.length > 0){
                swal({
                    title:"Are you sure you want to delete?",
                    text: "Once deleted, you will not be able to recover this metadata!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    })
                    .then((willDelete) => {
                      if(willDelete) {
                        const data= {
                            "id": ids.toString()
                        }
                        AdminService.httpPost('/data-import/metadata/multiple-delete', data).then((res) => {
            
                            if (res) {
                                this.setState({ loader: false })
                                let d = res;
                    
                                console.log(res)
                    
                                if(res.status==1){
                                    toast.success("Metadata deleted successfully!");
                                    this.getData();
                                }
                                else{
                                    toast.error("Failed to delete metadata");
                    
                                }
                           
                            }
                    
                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                      }                
                    });     
                }
            
                else{
                    swal({
                        title:"No Record Selected!",
                        text: "Please select at a record to delete.",
                          icon: "warning",
                          button: true,
                          
                        })
                
                        return false;
                
                
                }
            }




}

export default Metadata;