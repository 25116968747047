import React, { Component } from 'react'
import './manage-activity.css';
import * as jsx from './manage-activity.module.jsx';
// import * as js from '../../Modals/resources.modal.js';
// import * as stateUtilityInstance from '../../../sharedData';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import AddAreaModal from '../../Modals/addArea.modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import Tooltip from '@mui/material/Tooltip';
import ModalImage from "react-modal-image";
class ManageActivity extends Component {
	arr = ["dxgf"]


	constructor(props) {
		super(props);
		this.state = {
			data: [],
			filteredData: [],
			searchText: '',
			filterLocationArea : [],
			// mainData: [],
			isEdit: false,
			editModeId: null,
			modal: false,
			loader: false,
			dataLimit: 10,
			// image:"",
			// document:"",
			// innovationLogoBase64:"",
			// filename:"",
			dataStatus: 2,
			indicatorData: [],
			rowIndData: [],
			subIndData: [],
			areaData: [],
			bulkDelete: [],
			editEntry: [],
			pendingActivities: [],
			actorDetails: {}

		}
		// this.globalState = stateUtilityInstance.default;



	}

	componentDidMount() {
		this.getData();
		// this.getAllIndicators();
		// this.getAllAreaData()

		AdminService.checkToken();

	}


	render() {
		return jsx.default.bind(this)();
	}



	// update data limit===================//
	openSwal = (e) => {
		swal({

			title: "Are you sure you want to Approve All?",
			className: "approve_popup",
			icon: "success",
			buttons: true,
			dangerMode: true,
		})
		.then((willDelete) => {
			if(willDelete==true){
				let obj = { actor: { _id: this.state.pendingActivities[0].actor_id, name: this.state.pendingActivities[0].actor_name } }
				let prevStatus = this.state.pendingActivities[0].status
				let ids = []
				this.state.pendingActivities.forEach(x=>{
					ids.push(x.id)
				})
				console.log(ids.toString())
				AdminService.httpPost('/data-import/actor-activity/approve-all',{selectedIds:ids.toString(),status:1}).then(res=>{
					toast.success(res.message)
					this.getData()
					this.handleModal(obj, prevStatus)
				}).catch(err=>{
					toast.error('Something went wrong')
				})
			};
		});
	}

	delectSwal = (row, e) => {
		console.log("dell:: ", row, e)
		// return
		let obj = { actor: { _id: row.actor_id, name: row.actor_name } }
		let prevStatus = row.status

		let type = "pending"
		if (e == '1') {
			type = "approved"
		} else if (e == '3') {
			type = "disapproved"
		} else if (e == '2') {
			type = "pending"
		} else {
			type = "deleted"
		}

		swal({

			title: "Are you sure you want to " + type + "?",
			// text: "Once deleted, you will not be able to recover this master-list!",
			className: "approve_popup",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {

				if (willDelete != null) {
					if (e != '5') {
						const dataToBeSend = {
							"id": row.id,
							"status": e
						}
						AdminService.httpPost('/data-import/actor-activity/update-status', dataToBeSend).then((res) => {
							// console.log("res:: ", res)
							if (res.success == true) {
								toast.success("Activity " + type + " successfully");
								this.getData();
								this.handleModal(obj, prevStatus)
								// this.setState({actorDetails: {'name': row.actor_name, 'status': row.status}})
							} else {
								toast.error(res.message);
							}

						})
					} else {
						const dataToBeSend = {
							"selectedIds": row.id
						}
						AdminService.httpPost('/data-import/actor-activity/delete', dataToBeSend).then((res) => {
							// console.log("res:: ", res)
							if (res.success == true) {
								toast.success("Activity deleted successfully");
								this.getData();
								this.handleModal(obj, prevStatus)
							} else {
								toast.error(res.message);
							}

						})
					}
				}

			});
	}



	updateDataLimit = (e) => {


		this.setState({ dataLimit: e.target.value })
		this.getData();

	}

	//  validateImage = (event)=> {


	//     let files = event;
	//     console.log("uuuu---"+files)
	//     // if (files.length) {
	//     //   this.isImgSelected = true
	//     // }
	//     // else {
	//     //   this.isImgSelected = false
	//     // }

	//     let file = files[0];



	//      this.setState({filename:files[0].name})


	//     if (files && file) {
	//       var reader = new FileReader();
	//       console.log("image", file)
	//       reader.onload = this._handleReaderLoaded.bind(file);

	//     //   reader.readAsBinaryString(file);
	//       //this.image = "";
	//     }
	//   };

	//   _handleReaderLoaded = (readerEvt) => {
	//     console.log("------a------"+readerEvt)
	//     let binaryString = readerEvt.target.result;
	//     // setInnovationLogoBase64(btoa(binaryString));
	//     this.setState({innovationLogoBase64:btoa(binaryString)})
	//     console.log("innnno", this.state.innovationLogoBase64);

	//     this.setImage(this.state.filename + ';' + this.state.innovationLogoBase64)


	// };



	// get data by status=================//


	getDataByStatus = (e) => {

		this.setState({ dataStatus: e.target.value })
		this.getData();

	}







	// filter table and get data====================//

	onSearch = (val) => {
		this.setState({ searchText: val });
		let d = this.state.data;
		let fd = null;
		if (val == '') {
			fd = d;
		} else {
			// fd = d.filter(
			//     item => (item.iusData[0].name && item.areaData[0].area_code.toLowerCase().includes(val.toLowerCase())
			//         || (item.areaData[0].name && item.source?.publisher?.toLowerCase().includes(val.toLowerCase()))
			//     ));
			fd = d.filter(
				item => (item.actor.name && item.actor.name.toLowerCase().includes(val.toLowerCase())
                    // || (item.actor.profile_type.length && item.actor.profile_type.filter(e => e.toLowerCase().includes(val.toLowerCase()) ) )
                    || (item.actor.areaData.name && item.actor.areaData.name.toLowerCase().includes(val.toLowerCase()))
                ));
		}

		this.setState({ filteredData: fd });
	}
    onSearchModal = (val) => {
		
        this.setState({searchText: val});
        let d = this.state.pendingActivities;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            console.log("ddd-modal:: ", d )
            fd = d.filter(
                item => (item.activity_name && item.activity_name.toLowerCase().includes(val.toLowerCase())
                    || (item.activity_type && item.activity_type.toLowerCase().includes(val.toLowerCase()))
                    || (item.type_of_waste && item.type_of_waste.toLowerCase().includes(val.toLowerCase()))
                    || (item.facility_name && item.facility_name.toLowerCase().includes(val.toLowerCase()))
					|| (item.origin && item.origin.toLowerCase().includes(val.toLowerCase()))
                    || (item.destination && item.destination.toLowerCase().includes(val.toLowerCase()))
                    || (item.actor_type && item.actor_type.toLowerCase().includes(val.toLowerCase()))
					|| (item.facility_type && item.facility_type.toLowerCase().includes(val.toLowerCase()))
                    || (item.unit && item.unit.toLowerCase().includes(val.toLowerCase()))
                    || (item.description && item.description.toLowerCase().includes(val.toLowerCase()))
                    // || (item.quantity && item.quantity.includes(val))
                ));
        }

        console.log("fd-modal:: ", fd)
       
        this.setState({ pendingActivities: fd });
    }


	columns = [
		{
			name: '#',
			selector: (row, index) => row.index + 1,
			sortable: false,
			width: "50px"
		},
		{
			name: 'Actor Name',
			selector: row =>row?.actor.name,
			sortable: true,

		},
		{
			name: 'Actor Type',
			selector: row =>
				row?.actor.profile_type.map((d, i) => { return (<><span className="badge">{d} </span></>) }),

			//     selector: row =>  <Tooltip title={row.actor.profile_type} placement="top">
			//     <p>{row.actor.profile_type}</p>
			// </Tooltip>,
			sortable: true,

		},
		{
			name: 'Location',
			selector: row => row?.area.name,
			sortable: true,
			width: "300px"
		},
		{
			name: 'Pending',
			selector: row => <div>{row?.pending} <span className='custom-arrowimg' onClick={(e) => this.handleModal(row, 2)} data-toggle="modal" data-target="#exampleModal"><img src='assets/lib/images/home/arrow_right2.svg' /></span></div>,
			sortable: false,

		},
		{
			name: 'Approved',
			selector: row => <div>{row?.approved} <span className='custom-arrowimg' onClick={(e) => this.handleModal(row, 1)} data-toggle="modal" data-target="#exampleModal"><img src='assets/lib/images/home/arrow_right2.svg' /></span></div>,
			sortable: false,

		},
		{
			name: 'Disapproved',
			selector: row => <div>{row?.rejected} <span className='custom-arrowimg' onClick={(e) => this.handleModal(row, 3)} data-toggle="modal" data-target="#exampleModal"><img src='assets/lib/images/home/arrow_right2.svg' /></span></div>,
			sortable: false,

		},


	];


	closeLightbox = () => {
		this.state.open = true;
	  };

	// ======================================================= Data Table Activity Modal 

	columns_activity = [
		{
			name: '#',
			selector: (row, index) => index + 1,
			sortable: false,
			width: "50px"
		},
        // {
        //     name: 'Activity Name',
        //     selector: row => <div className='activity_box'>{row?.activity_name}</div> ,
        //     sortable: true,
        //     width: '150px',
      
        // },
        {
            name: 'Activity Type',
            selector: row => <div className='activity_box'>{row?.activity_type}</div> ,
            sortable: true,
            width: '150px',

        },

        {
            name: 'Origin',
            selector: row => row?.origin,
            sortable: true,
            width: "100px"  
        },
        {
            name: 'Destination',
            selector: row => row?.destination,
            sortable: true,
            width: "130px"  
        },
        {
            name: 'Collection Vehicle',
            selector: row => row?.Collection_Vehicle,
            sortable: true,
            width: "200px"  
        },
        {
            name: 'Waste Type',
            selector: row =>  <span className='badge'>{row?.type_of_waste}</span>,
            sortable: true,
            width: "150px"
        },

        {
            name: 'Image',
            // selector: row => row?.unit {row.document},
			// selector: row => <div className='documentImg'><span><img src={`${constants.actorDocPath}${row.document}`} /></span></div>,
			selector: row => <div className='documentImg'><span>
				{row.document != ''?
					<ModalImage
					small={`${constants.actorDocPath}${row.document}`}
					large={`${constants.actorDocPath}${row.document}`}
					hideDownload
					hideZoom
					/>
					: ''
				}
				{/* <img src={`${constants.actorDocPath}${row.document}`} /> */}
				</span>
				</div>,
            sortable: true,
            width: '150px'
        },

        {
            name: <div className='extra_content_thead d-flex flex-column align-item-center'>Quantity</div>,
            selector: row => <div>{row?.quantity} {row?.unit}</div>,
            sortable: true,
            width: '100px'
        },

        {
            name: 'Date & Time',
			selector: row => row?.activity_date_time,
            // selector: row => dayjs(row?.activity_date_time).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            
            sortable: true,
            width: '200px'
        },
        // {
        //     name: 'Actor Type',
        //     selector: row => <span className='badge'>{row?.actor_type}</span>,
        //     sortable: true,
        //     width: '150px'
        // },
        {
            name: 'Actor Location',
            // selector: row => row?.actor_location, 
            selector: row => row?.actor_location,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Name',
            selector: row => row?.facility_name,
            sortable: true,
            width: '150px'
        },
        {
            name: 'Facility Type',
            selector: row => <span className='badge'>{row?.facility_type}</span>,
            sortable: true,
            width: '200px'
        },
        {
            name: 'Facility Location',
            // selector: row => row?.facility_location,
            selector: row => row?.facility_location,
            sortable: true,
            width: '200px'
        },
        
        {
            name: 'Description',
            selector: row => <div className='actor_description_box'>{row?.description}</div> ,
            sortable: true,
            width: '350px'
        },


        {
			name: 'Action',
			width: "170px",
			cell: row =>
				<>
					<div class="dropdown table-action">
						<button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
							Select
						</button>
						<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
							{row.status == 2 &&
								<a className="pop-up-btn dropdown-item" onClick={(e) => { this.delectSwal(row, 1) }}  >
									<span _ngcontent-nud-c5="" title="Approve">
										Approve
									</span>
								</a>
							}
							

							{row.status == 2 &&
								<a className="pop-up-btn dropdown-item" onClick={(e) => { this.delectSwal(row, 3) }}  >
									<span _ngcontent-nud-c5="" title="Disapprove">
										Disapprove
									</span>
								</a>
							}
							<a className="dropdown-item  text-bold" onClick={(e) => { this.delectSwal(row, 5) }} >Delete</a>

						</div>
					</div>
				</>,
			sortable: false,
			ignoreRowClick: true,
			allowOverflow: true,
			button: true
		},



		
		// {
		// 	name: 'Activity',
		// 	selector: row => <div className='activity_box'>{row.activity}</div>,
		// 	sortable: true,
		// 	// width: '450px',

		// },
		// {
		// 	name: 'Location',
		// 	selector: row => row.location,
		// 	sortable: true,
		// 	// width: "350px"  
		// },
		// {
		// 	name: 'Waste Type',
		// 	selector: row => <span className='badge'>{row.wastetype}</span>,
		// 	sortable: true,
		// 	// width: "300px"   
		// },

		// {
		// 	name: <div className='extra_content_thead d-flex flex-column align-item-center'>Quantity <span>(Tonne/s)</span></div>,
		// 	selector: row => row.quantity,
		// 	sortable: true,
		// 	width: '150px'
		// },
		// {
		// 	name: 'Description',
		// 	selector: row => <div className='actor_description_box'>{row.description}</div>,
		// 	sortable: true,
		// 	width: '350px'
		// },


		
	];


	// data = [
	// 	{
	// 		id: 1,
	// 		title: '1',
	// 		activity: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
	// 		location: 'Dolpha',
	// 		wastetype: 'Hazardous waste',
	// 		quantity: '26',
	// 		datetime: '15 Feb, 2023 | 10:45 Am',
	// 		actortype: 'Importer',
	// 		description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
	// 	},
	// 	{
	// 		id: 2,
	// 		title: '2',
	// 		activity: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
	// 		location: 'Mtsambama',
	// 		wastetype: 'Biodegradable waste',
	// 		quantity: '55',
	// 		datetime: '15 Feb, 2023 | 10:45 Am',
	// 		actortype: 'Recycler',
	// 		description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
	// 	},
	// ]
	// ======================================================= Data Table Activity Modal 
	editDataEntry = (id) => {


		const edData = [];
		this.state.filteredData.forEach((item) => {

			if (item._id == id) {
				edData.push(item)
				return;
			}

		});

		this.setState({ editEntry: edData })
		this.setState({ isEdit: true })
		this.openAddModal();

	}

	handleModal = (data, sid) => {
		console.log("aa", data, sid)
		this.setState({ loader: true })
		// this.setState({pendingActivities: ['a','b']})
		AdminService.httpGet('/data-import/actor-activity/getByActorId/' + data.actor._id + '/' + sid).then((res) => {
			let result = [];
			const arrdata = [];
			console.log("sss", res.data, res.data.length)
			if (res.data.length) {
				

				// console.log(res.data[0])

				res.data[0].data.forEach(e1 => {
					let date = new Date(e1.activity_date_time)
					let dtObj = {
						day: date.getUTCDate(),
						month: date.toLocaleString('en', { month: 'short' }),
						year: date.getFullYear(),
						time: date.toLocaleString('en', { hour: 'numeric', minute: 'numeric', hour12: true })
					}
					let obj = {
						id: e1._id,

						actor_id: res.data[0]._id,
						actor_name: res.data[0].actor.name,
						actor_status: res.data[0].actor.status,

						title: '1',
						activity_name: e1.activity_name,
						activity_type: e1.activity_type,
						origin: e1.origin,
						destination: e1.destination,
						Collection_Vehicle: e1.Collection_Vehicle,
						type_of_waste: e1.type_of_waste,
						quantity: (e1.unit === null || e1.unit === undefined) ? '' : ( (e1.unit).toLowerCase() == "kg" ) ? (e1.quantity/1000) : e1.quantity,
						activity_date_time: dtObj.day +' '+dtObj.month+', '+dtObj.year+' | '+dtObj.time,
						
						actor_location: (e1.actor_type === null || e1.actor_type === undefined) ? '' : ( (e1.actor_type).toLowerCase() == "generator" && e1.actor_location == "") ? '' : ( (e1.actor_type).toLowerCase() == "generator" && e1.actor_location != "") ? e1.actorArea.name : '',
						// actor_location: (e1.actor_type === null || e1.actor_type === undefined) ? '+' : '-',
						// datetime: '15 Feb, 2023 | 10:45 Am',
						actor_type: e1.actor_type,
						facility_name: e1.facility_name,
						facility_type: e1.facility_type,
						description: e1.description,
						facility_location: (e1.facility_location == "" || e1.facility_location === null || e1.facility_location === undefined) ? '' : this.showLocation(e1?.facilityArea),
						// facility_location: '',
						unit: e1.unit,
						status: res.data[0].status,
						document: e1.document

					}
					result.push(obj)
				})



				console.log("==", result)


				this.setState({ loader: false, actorDetails: { 'name': data.actor.name, 'status': sid }, pendingActivities: result })
			} else {
				console.log("Empty", result)
				this.setState({ loader: false, actorDetails: { 'name': data.actor.name, 'status': sid }, pendingActivities: result })
			}

		}).catch((err) => {
			this.setState({ loader: false })
			console.log(err)
		});
	}

	showLocation =  (e) => {
		const dtarr = []
		const demo = e;
		// filterLocationArea
		demo?.forEach(element => {
			dtarr.push(element.name);
		})
		// return dtarr;
		return <>{dtarr?.length > 0 && dtarr.slice(0, 2).map((d, i) => { console.log('dash', d); return (<><span className="badge">{d} </span></>) })}
        {dtarr?.length > 2 ? <><span>{dtarr?.length - 1}+</span></> : ''}</> ;
	}

	getData = async () => {
		this.setState({ loader: true, searchText: "" });
		const dataToBeSend = {
			// "size": this.state.dataLimit,
			// "totalElements": 0,
			// "totalPages": 0,
			// "pageNumber": 0,
			// "filterKeyWord": "",
			// "userId": "618a57dcd7854836b1c28ac2",
			// "startOffset": 0,
			// "endOffset": 0,
			// "level": [
			//     this.state.dataStatus
			// ]
		}


		AdminService.httpGet('/data-import/actor-activity/getAll', dataToBeSend).then((res) => {
			if (res) {
				this.setState({ loader: false })
				let d = res.data;
				d.sort((a, b) => {
					if (a.actor.name < b.actor.name) {
						return -1
					} else if (a.actor.name > b.actor.name) {
						return 1
					} else {
						return 0
					}
				})
				d.forEach((ele, index) => { ele.index = index })
				this.setState({ filteredData: d, data: d })
			}

		}).catch((err) => {
			this.setState({ loader: false })
			console.log(err)
		});






	}




	// save data===========================//

	saveData = async (e) => {

		if (e.id != null) {

			this.updateEntry(e)

		}
		else {
			this.addNewEntry(e)
		}

	}


	updateEntry = async (e) => {


		// this.setState({ loader: true })
		const dataToBe2 = {

			"id": e.id,
			"description": e.description,
			"title": e.title,
			"url": e.url,
			"type": e.type,
			"doc": e.document,
			"image": e.image


		}

		console.log('dten', dataToBe2)

		AdminService.httpPut('/data-import/resources/update/' + e.id, dataToBe2).then((res) => {
			if (res) {
				this.setState({ loader: false })
				let d = res.data;

				console.log(res)
				if (res.success == true) {
					toast.success("Manage-activity updated successfully!");
					this.openAddModal();
					this.getData();
					this.setState({ isEdit: false })

					this.setState({ editEntry: [] })
				}
				else {
					toast.error(res.message);

				}


			}

		}).catch((err) => {
			this.setState({ loader: false })
			console.log(err)
		});




	}

	// add new entry=============================///


	addNewEntry = async (e) => {

		console.log('dten', e)
		this.setState({ loader: true })
		const dataToBeSend = {


			"description": e.description,
			"title": e.title,
			"url": e.url,
			"type": e.type,
			"doc": e.document,
			"image": e.image
		}


		console.log('check', dataToBeSend)

		AdminService.httpPost('/data-import/resources/add-resource', dataToBeSend).then((res) => {
			if (res) {
				this.setState({ loader: false })
				let d = res.data;

				console.log(res)

				if (res.success == true) {
					toast.success("Manage-activity created successfully!");
					this.openAddModal();
					this.getData();
				}
				else {
					toast.error(res.message);

				}

			}

		}).catch((err) => {
			this.setState({ loader: false })
			console.log(err)
		});




	}



	// update status==================//
	changeDataStatus(e, id, status) {
		this.setState({ loader: true })
		let changed = 0;

		if (status == 1) {
			changed = 0;
		}
		else {
			changed = 1;
		}

		let statusData = { "id": id, "resStatus": changed };


		AdminService.httpPost('/data-import/resources/update-status', statusData).then((res) => {

			if (res) {
				this.setState({ loader: false })
				let d = res;

				console.log(res)

				if (res.success == true) {
					toast.success("Status updated successfully!");
					this.getData();
				}
				else {
					toast.error("Failed to update status");

				}

			}

		}).catch((err) => {
			this.setState({ loader: false })
			console.log(err)
		});


	}


	// delete entry===========//


	deleteEntry = (id) => {

		swal({
			title: "Are you sure you want to delete?",
			text: "Once deleted, you will not be able to recover this resource entry!",
			icon: "warning",
			buttons: true,
			dangerMode: true,
		})
			.then((willDelete) => {
				this.setState({ loader: true })
				if (willDelete) {
					const dataToBeSend = {
						"id": id
					}

					AdminService.httpPost('/data-import/resources/delete-resource', dataToBeSend).then((res) => {

						if (res) {

							let d = res;

							console.log(res)
							this.setState({ loader: false })

							if (res.success == true) {
								toast.success("Manage-activity deleted successfully!");
								this.getData();

							}
							else {
								toast.error(res.message);

							}

						}

					}).catch((err) => {
						this.setState({ loader: false })
						console.log(err)
					});
				}
			});

	}


	// open add modal=====================//


	openAddModal = () => {

		if (this.state.modal == false) {

			this.setState({ modal: true })


		} else {
			this.setState({ modal: false })
			this.setState({ isEdit: false })

			this.setState({ editEntry: [] })
		}

	}



	enableDis = (e, value) => {
		this.setState({ loader: true })
		console.log("e--" + e)
		if (value == 0) {
			value = 1;
		} else {
			value = 0;
		}



		// this.setState({loader:true})
		const dataToBeSend = {

			id: e,
			resStatus: value

		}


		AdminService.httpPost('/data-import/resources/update-status', dataToBeSend).then((res) => {
			if (res) {

				let d = res.data;
				// this.setState({filteredData: d, data:d})
				this.getData(this.state.dataStatus);

				if (res.message == 'Status successfully updated!') {
					toast.success("Status  successfully updated!");
					// this.openAddModal();
					// this.getData();
				}
				else {
					toast.error("Failed to update status ");

				}
			}

		}).catch((err) => {
			this.setState({ loader: false })
			console.log(err)
		});


	}


	///multiple delete

	handleChange = ({ selectedRows }) => {
		console.log(selectedRows);
		this.setState({ bulkDelete: selectedRows })

	};



	handleChangeData = ({ toggleCleared }) => {
		this.setState({ bulkDelete: toggleCleared })

	};







	//////////=========checkbox delete============/////////

	deleteBulkUser = (e) => {
		let ids = [];
		let obj = { actor: { _id: this.state.bulkDelete[0].actor_id, name: this.state.bulkDelete[0].actor_name } }
		let prevStatus = this.state.bulkDelete[0].status

		this.state.bulkDelete.forEach(item => {
			ids.push(item.id)
		})

		if (ids?.length > 0) {

			swal({
				title: "Are you sure you want to delete?",

				icon: "warning",
				buttons: true,
				dangerMode: true,
			})
				.then((willDelete) => {
					if (willDelete) {
						const data = {
							"selectedIds": ids.toString()
						}
						console.log(data);
						// return false
						AdminService.httpPost('/data-import/actor-activity/delete', data).then((res) => {

							if (res) {
								this.setState({ loader: false })
								let d = res;

								console.log(res)

								if (res.success == true) {
									toast.success("Manage-activity deleted successfully!");
									this.getData();
									this.handleModal(obj, prevStatus)
								}
								else {
									toast.error("Failed to delete manage-activity");

								}

							}

						}).catch((err) => {
							this.setState({ loader: false })
							console.log(err)
						});
					}
				});
		}


		else {
			swal({
				title: "No Record Selected!",
				text: "Please select at a record to delete.",
				icon: "warning",
				button: true,

			})

			return false;


		}

	}




}






export default ManageActivity;
