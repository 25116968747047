import React, { useEffect, useState } from 'react'
import { useNavigate, NavLink } from 'react-router-dom'
import '../Layout/layout.css';
import '../../App.css';
import { logout } from '../../features/loginSlice';
import { useDispatch } from 'react-redux';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from "react-bootstrap/Dropdown";

import Button from 'react-bootstrap/Button';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import adminService from '../../services/admin.service';

function Header() {

  const userInfo = localStorage.getItem('userInfo');
  // const role = JSON.parse(userInfo);
  var role = null;
  if(userInfo!=null){
     role = JSON.parse(userInfo);

     
  }


  const logClass = 'dropdown-menu log_out_specific logout_box open'
  const [dropClass, changeClasses] = useState(false);
  const dispatch = useDispatch();
  const openDropdownLogout = () => {

    if (dropClass) {
      changeClasses(false)
    }
    else {
      changeClasses(true)
    }
  };

  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(logout())

    navigate('/')
  }



  useEffect(() => {


    updateUserData();
  
  })


  const updateUserData = () =>{
    
    let data = {
      "userId" : role?.id
     }
     adminService.httpPost('/data-import/users/getUserData', data).then(res=>{
if(res?.success==true){

  const data = res?.data?.[0];

  if(data?.length > 0){

  

    var obj = {
      "name": data?.name,
      "email": data?.email,
      "role": data?.role,
      "area": data?.area?.[0]?.area_code,
      "designation":  data?.designation,
      "id": data?._id,
      "last_login_time": data?.login_time,
      "profile_percentage":data?.profile_percentage,
      "status":data?.status,
      "profile_status": data?.profile_status
      
  }
localStorage.setItem('userInfo', JSON.stringify(obj))
}


}




    })

  }

  // ================================================================================
  const [state, setState] = useState({
    bottom: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleDrawerClose = () => {
    setState(false);
  };



  var dataMan = '';

  switch (role?.role) {
    case 1: 
    dataMan = '/admin/Summary'

      break;
    case 2:
      dataMan = '/admin/data-entry'
      break;

    case 4:
      dataMan = '/admin/data-approve'
      break;

    default:
      dataMan = '/'
      break;
  }

  // ================================================================================
  return (
    <>
      <section className="admin_header">
        <div className="container-fluid">
          <div className="row justify-content-between">
            <div className="col-md-4 col-4">
              <div className="admin_logo">
                <img src="assets/lib/images/admin/admin_logo.png" />
              </div>
            </div>
            <div className="col-md-6  col-8 d-flex justify-content-end align-items-center">
              {/* <div class="humburger">
                            <img src="assets/lib/images/admin/humburger.svg"/>
                          </div> */}

              <>
                {['right'].map((anchor) => (
                  <React.Fragment key={anchor} className="humburgerMenu">
                    <div className='humburger hsearch' onClick={toggleDrawer(anchor, true)}>
                      <img src="assets/lib/images/home/humburger.svg" />
                    </div>
                    <SwipeableDrawer className="hm_width" anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)} onOpen={toggleDrawer(anchor, true)}>
                      <Button variant="secondary" className='hmClose' onClick={handleDrawerClose}>
                        <img src='assets/lib/images/close.svg' />
                      </Button>
                      <Navbar expand="lg" className='hmMenu'>
                        {/* <Navbar.Toggle aria-controls="basic-navbar-nav" /> */}
                        <Navbar.Collapse id="basic-navbar-nav">
                          <Nav className="me-auto">
                            <li><NavLink to='/home' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Home</NavLink></li>
                            <li><NavLink to='/information-kiosk' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Information Kiosk</NavLink></li>
                            <li><NavLink to='/dashboard/tracker' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Tracker</NavLink></li>
                            <li><NavLink to='/data-analytics' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Data Analytics</NavLink></li>
                            {/* <li><NavLink to='/dashboard/activity' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Activity</NavLink></li> */}
                            {role?.role =='5' ? <li><NavLink to='/dashboard/activity' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Activity</NavLink></li> : ''}
                            {role?.role =='5' ? <li><NavLink to='/dashboard/profile' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Profile</NavLink></li> : ''}
                            <li><NavLink to='/contact' className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Contact</NavLink></li>
                            {role?.role != '5' && <li><NavLink to={dataMan} className='nav-link' activeClassName="active" onClick={handleDrawerClose}>Data Manager</NavLink></li>}
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                    </SwipeableDrawer>
                  </React.Fragment>
                ))}
              </>

              <div class="user_login">
                <button class="btn dropdown-toggle" type="button" data-toggle="dropdown" aria-expanded="false">
                  <img src="assets/lib/images/admin/user_login.svg" />
                </button>
                <div class="dropdown-menu cursor" >
                  <a class="dropdown-item" onClick={handleLogout}> Logout</a>
                </div>
              </div>


            </div>
          </div>
        </div>
      </section>



      {/* <header className="main-header header1 clearfix" >

        <div className="container-fluid">
     


        <div className='nomNav'>
  <Link to="/admin/summary">
  <p>Summary</p>
  </Link>

  <Link to="/admin/import-area">f
  <p>Area</p>
  </Link>

  <Link to="/admin/manage-indicator">
  <p>Indicator</p>
  </Link>

  <Link to="/admin/data-entry">
  <p>Data Entry</p>
  </Link>

  <Link to="/admin/data-approve">
  <p>Data Approve</p>
  </Link>

  <Link to="/admin/import-metadata">
  <p>Indicator Metadata</p>
  </Link>

  <Link to="/admin/dump-site">
  <p>Dump Sites</p>
  </Link>

  <Link to="/admin/user-management">
  <p>User</p>
  </Link>

  <Link to="/admin/manage-resource">
  <p>Resources</p>
  </Link>

  <Link to="/admin/import-log">
  <p>Logs</p>
  </Link>
  </div>

        </div>
      </header> */}

      {/* mohit */}


    </>

  );
}

export default Header;