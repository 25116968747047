import React, { useEffect, useRef, useState } from "react";
import './modal.css'
import * as constants from '../../Config/app.constants';
import "react-toastify/dist/ReactToastify.css";
import $ from 'jquery';
import { useSelector } from 'react-redux';
import Button from '@mui/material/Button';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';

import SimpleReactValidator from 'simple-react-validator';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';
import Stack from '@mui/material/Stack';
import Dropdown from 'react-bootstrap/Dropdown';

import { Label } from "devextreme-react/bar-gauge";

function MasterListModal(props) {

  const form = useRef()

  $(document).on('click', 'someyourContainer .dropdown-menu', function (e) {
    e.stopPropagation();
  });

  const simpleValidator = useRef(new SimpleReactValidator({
    messages: {
      required: 'This field is required'
    }
  }));
  
  const [, forceUpdate] = useState();

  const [mainTreeData, setMainTreeData] = useState([]);
  const [selectedParent, setSelectedParent] = useState('');

  const [FormState, setFormState] = useState(
    {
      id: '',
      facility_type: '',
      parent: '',
      types: '',
      name: '',
    });

  useEffect(() => {
    if (props.isEdit) {
      console.log('check data', props.editMode)
      setFormState({
        ...FormState,
        "id": props.editMode?._id,//fix issue
        "facility_type": props.editMode?.value,
        'parent': (props.editMode?.parent? props.editMode?.parent: '')
      });
      if(props.columnsState == constants.masterListType[3]) {        
        setSelectedParent(props.editMode?.parent);
      }

    } else {
      setFormState({
        "id": '',
        "facility_type": '',
        "parent": ''
      });
      setSelectedParent('');
    }

  }, [props]);




  //  if(this.columnsState == 'FacilityTypes')


  const handleChange = (event) => {

    setFormState({ ...FormState, [event.target.name]: event.target.value });


  }



  // form submit function end============//

  const cancelModal = (event) => {
    event.preventDefault();
    props.closeModal(event.target);
    console.log("column hai hindustani---->", props.columnsState)
  }

  // show area list===============//

  const handleSubmit = (e) => {
    e.preventDefault();
    let dataToSend = [];
    if (props.isEdit == true) {

      dataToSend = {
        "id": FormState.id,
        "facility_type": FormState.facility_type,
        "parent": selectedParent
      }

    } else {

      dataToSend = {
        "id": null,
        "facility_type": FormState.facility_type,
        "parent": selectedParent
      }


    }
    if (simpleValidator.current.allValid()) {
      console.log('adm', dataToSend)
      props.parentCallback(dataToSend);
    } else {
      simpleValidator.current.showMessages();
      forceUpdate(1);
    }
  }


  useEffect(()=>{
    console.log("Mohit treeData", props.treeData);
    if (props.treeData != '' && props.treeData != null) {

      let flatLevel = [];


      props.treeData.forEach((item) => {


        item.areaSubData = [];

        flatLevel.push(item)


      })
      const data = setLevelData(flatLevel)

      setMainTreeData(data);
      console.log("Mohit data", data);
    }
  }, [props.treeData]);

  

  const setLevelData = (flat) => {
    console.log("Mohit flatlever", flat);

    var roots = []

    var all = {}

    flat.forEach(function (item) {
      all[item.label] = item
    })
    Object.keys(all).forEach(function (label) {
      var item = all[label]
      if (item.parent === null || item.parent === '') {
        roots.push(item)
      } else if (item.parent in all) {
        var p = all[item.parent]
        if (!('areaSubData' in p)) {
          p.areaSubData = []
        }
        p.areaSubData.push(item)
      }
    })

    return roots
  }

  const renderTree = (nodes) => {

    return (
      <>
      {
        nodes.map((item) => {   

          return (

            <TreeItem
              className={item.areaSubData?.length > 0 ? 'has-child' : 'no-child'}
              key={item.label} nodeId={item.label} label={item.label}
              onClick = {()=> setSelectedParent(item.label)}
            >

              {item.areaSubData != null && Array.isArray(item.areaSubData) && item.areaSubData?.length > 0
                ? renderTree(item.areaSubData)
                : <></>}
            </TreeItem>

          );


        })

      }
      </>
    )
    

  };

  return (
    <>

      <div className="area-form">

        <ValidatorForm className="main_react_form w-100" ref={form} onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ width: 300 }} className="w-100">
            <div className="gallery-collections">

              <div className="row align-items-center mb-30">
                <div className="col-md-3 popup_input">
                  <span className="mat-form-field-label-wrapper">
                    <label className="mat-form-field-label ng-tns-c5-39 mat-empty mat-form-field-empty ng-star-inserted" id="mat-form-field-label-33" htmlFor="mat-input-16" aria-owns="mat-input-16">
                      {props.columnsState} </label>
                  </span>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style">
                    <div className="mat-form-field-wrapper">
                    
                     <TextValidator
                        className="w-100"
                        onChange={handleChange}
                        name='facility_type'
                        value={FormState.facility_type}
                        validators={['required']}
                        errorMessages={['This field is required']}
                      /> 
                    </div> 
                  </div>
                </div>
              </div>
              <div className={props.columnsState == constants.masterListType[3] ? 'row align-items-center mb-30 d-flex': 'row align-items-center mb-30 d-none'}>
                <div className="col-md-3 popup_input">
                  <span className="mat-form-field-label-wrapper">
                    <label className="mat-form-field-label ng-tns-c5-39 mat-empty mat-form-field-empty ng-star-inserted" id="mat-form-field-label-33" htmlFor="mat-input-16" aria-owns="mat-input-16">
                      Parent </label>
                  </span>
                </div>

                <div className="col-md-6">
                  <div className="form-group mb-0 inputform_style ifs_dropdown">
                    <div className="mat-form-field-wrapper">
                      {console.log('checstate',mainTreeData)}
                        { props.columnsState == constants.masterListType[3] && <Dropdown>
                          <Dropdown.Toggle variant="white" id="dropdown-basic">
                            <TextValidator
                              className=" w-100"
                              label=""
                              onChange={handleChange}
                              name="parent"
                              value={selectedParent}
                            />

                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <TreeView
                              // disabled={props.editData?.[0]?.level == 1 ? true : false}
                              aria-label="file system navigator"
                              defaultCollapseIcon={<ExpandMoreIcon />}
                              defaultExpandIcon={<ChevronRightIcon />}

                              sx={{ flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
                            >
                              {renderTree(mainTreeData)}

                            </TreeView>
                          </Dropdown.Menu>
                        </Dropdown>
                        }
                    </div>
                  </div>
                </div>
              </div>


            </div>


            <div className="gallery-sidebar-button text-center new_gallery-sidebar-button">
              <div className="d-flex align-items-center justify-content-center">
            
                <Button variant="contained" onClick={cancelModal} className="cancelbtn">Cancel</Button>
                <Button variant="contained" type="submit" className="addbtn">
                  {/* {FormState.id != null ? 'Update' : 'Add'} */}
                  {props.isEdit? 'Update': 'Add' }
                </Button>
              </div>
            </div>
          </Stack>
        </ValidatorForm>
      </div>

    </>

  );
}

export default MasterListModal;