import { Component, useRef } from 'react'
import './login.css';
import * as jsx from './login.module.jsx';
// import * as constants from '../../../Config/app.constants';
// import { connect } from "react-redux";
// import { login, logout } from '../../../features/loginSlice';
// import history from '../../../services/history';
// import { ToastContainer, toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

class Login extends Component {

  constructor() {
    super();

    this.state = {

    }



    }


    render() {
      // const [showModal, setShowModal] = useState(false);
      return jsx.default.bind(this)();
    }
  

  }
//   const mapDispatchToProps = { login, logout };

// export default connect(
//   null,
//   mapDispatchToProps
// )(Login);

export default Login;