import * as SearchComponent from './Search';

const subHeaderComponentMemo = ({checkData, PropDeleteBulk, onSearch, searchText, value, statusFilter, parentCallBack, parentCallBackApprove, nRows, currStatus, dataStatus, approveData}) => { 
console.log("table--"+value)



const parentCall = (e) => {

  parentCallBack(e)
}

const parentCallApprove = (e) => {
  console.log("THIS IS CONSOLE---->", e)
  parentCallBackApprove(e)
}


const deleteBulk = (e) => {
  PropDeleteBulk(e)
}

    return (
        <>
          <SearchComponent.default mainCallBack = {parentCall} 
                                    mainCallBackApprove = {parentCallApprove} 
                                    statusFilter={statusFilter} 
                                    onSearch={onSearch} 
                                    searchText={searchText} 
                                    value ={value} 
                                    className="form-control"
                                    nRows={nRows}
                                    currStatus={currStatus}
                                    dataStatus={dataStatus}
                                    deleteBulk={deleteBulk}
                                    checkData={checkData}
                                    approveData={approveData}
                                    />
        </>
    );

};



export default subHeaderComponentMemo;