import { Link, useLocation } from 'react-router-dom'
import useBreadcrumbs from 'use-react-router-breadcrumbs'
import "./Breadcrumbs.css";
import $ from 'jquery';
const routes = [
  { path: '/admin/users/:userId', breadcrumb: 'Example 1' },
  { path: '/admin/summary', breadcrumb: ' Summary' },
  { path: '/admin/import-area', breadcrumb: 'Manage Area' },
  { path: '/admin/manage-indicator', breadcrumb: 'Manage Indicator' },
  { path: '/admin/import-metadata', breadcrumb: 'Manage Metadata' },
  { path: '/admin/data-entry', breadcrumb: 'Manage Data Entry' },
  { path: '/admin/data-approve', breadcrumb: 'Manage Data Approve' },
  { path: '/admin/manage-resource', breadcrumb: 'Manage Resource' },
  { path: '/admin/master-list', breadcrumb: 'Manage Master List' },
  { path: '/admin/generate-report', breadcrumb: 'Generate Report' },
  { path: '/admin/user-management', breadcrumb: 'User' },
  { path: '/admin/app-facility', breadcrumb: ' Facility Management' },
  { path: '/admin/import-log', breadcrumb: 'Log' },
  {path:'/admin/manage-actor',breadcrumb:'Manage Actor'},
  {path:'/admin/manage-activity',breadcrumb:'Manage Activity'}
]; 
    
function Breadcrumbs() {
  let pageTitle;
  const newBred = [];
  const breadcrumbs = useBreadcrumbs(routes).forEach((item)=>{
    newBred.push(item)
  if(item.match.key==item.match.url){

    pageTitle = item.match.route;

  }


  })




  const location = useLocation()
  return (


    <>

<section className="bredcrum_area">
          <div className="container-fluid">
               <div className="row">
                   {/* <div className="col-md-4">
                         <div className="bred_crum_box">
                            <nav aria-label="breadcrumb">
                              <ol class="breadcrumb align-items-end">
                                <li class="breadcrumb-item"><a href="#">Home</a></li>
                                <li class="breadcrumb-item active" aria-current="page">{pageTitle.breadcrumb}</li>




                              </ol>
                            </nav>
                         </div>
                        
                   </div> */}
                     <div className="col-md-12 text-center d-flex align-items-center justify-content-center">
                        <div className="admin_page_name">
                                  {/* <h3>area management</h3> */}
                                  <h3 className="page-name">{pageTitle.breadcrumb}</h3>
                        </div>
                      </div>
               </div>
          </div>
    </section>
      {/* <section className="main-breadcrum">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-4 py-2 pl">
              <nav >
                <ol className="cd-breadcrumb custom-separator custom-icons">

                  {newBred.map(({ match, breadcrumb }) => (
                    <li >
                      <Link


                        key={match.url}
                        to={match.url}
                        className={match.pathname === location.pathname ? "breadcrumb-active" : "breadcrumb-not-active"}
                      >  <a className="change-clr">
                          {breadcrumb} 

                        </a>
                      </Link>
                    </li>
                  ))
                  }



                </ol>
              </nav>
            </div>
            <div className="col-md-4 text-center">
                        <div className="admin_page_name">
                                  <h3>area management</h3>
                        </div>
                      </div>
       
          </div>
        </div>
      </section> */}

    </>
  );
}
export default Breadcrumbs;
