import React from 'react';
import { useEffect } from 'react';
import { Outlet } from "react-router-dom";
import Header from './layout/header';
import Footer from './layout/footer';
import { useLocation } from 'react-router-dom'
function DashboardComponent() {
  const location = useLocation();
  const route = location.pathname;

  const testData =(e)=>{
    console.log(e,"HELLO BROTHERRERR")
  }

  return (
      <>

      <Header />
      <Outlet testData={testData}/>
      {route!='/data-analytics' &&  route!='/dashboard/tracker' &&
       <Footer />
      }
     
    
    </>
  );
}

export default DashboardComponent;


