import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component';
// import AddAreaModal from '../../Modals/addArea.modal';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
// import Sonnet from '../../components/Sonnet';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
import { Tooltip } from '@material-ui/core'
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import ProfileModal from '../../../Common/components/modal/profile.modal';

import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import Stack from '@mui/material/Stack';

import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import Button from '@mui/material/Button';

import Collapse from 'react-bootstrap/Collapse';

import AddActivityModal from "./addActivity.modal";


import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import ActivityViewModal from "./viewModal";

import FixedHeaderStory from 'react-data-table-component';

const activityTemplate = function () {
  const  openCloseModal = async () => {

        this.setState({scrollableModal:false})
  
}
const handleSubmit = (e) => {
  e.preventDefault();
  let dataToSend = [];

  dataToSend = {
 
          "id": this.state.id,
          "registrationdate":this.state.date_of_registration,
          "approvaldate":this.state.approvaldate,
          "role":this.state.role,
          "location":this.state.location,
            
         }
 
    
 console.log("test done by itm",dataToSend)
 
     
    
    }


const  closeModalClose = async () => {

  
        this.setState({disaaprovest:false})
    
  
}

const  closeModalClosePublis = async () => {


      this.setState({publishModal:false})
  

}

const  closeDeleteModal = async () => {

  
    this.setState({deleteApprovedData:false})


}


const data = [
  {
      id: 1,
      title: '1',
      activity: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
      location: 'Dolpha',
      wastetype: 'Hazardous waste',
      quantity: '26',
      datetime: '15 Feb, 2023 | 10:45 Am',
      actortype: 'Importer',
      description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  },
  {
    id: 2,
    title: '2',
    activity: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
    location: 'Mtsambama',
    wastetype: 'Biodegradable waste',
    quantity: '55',
    datetime: '15 Feb, 2023 | 10:45 Am',
    actortype: 'Recycler',
    description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  },
]



  return (
    <>

      
      <div className="activityPage">
        <div className='container-fluid areaCont'>
          <section className='areaSection area_margin'>
              
                                                     
                <div className="tab_box ma_tables">
                  <div className="activityTabsLists">
                
                    <div className="atl_lists">
                      <ToggleButtonGroup
                        color="primary"
                        value={this.state.alignment}
                        exclusive
                        onChange={this.handleChange}
                        aria-label="Platform"
                      >
                        <ToggleButton value="Pending">Pending <span className="aNumber">{this.state.filteredData?this.state.pendingCount: 0}</span></ToggleButton>
                        <ToggleButton value="Approved">Approved <span className="aNumber">{this.state.filteredData?this.state.approvedCount: 0}</span></ToggleButton>
                        <ToggleButton value="Rejected">Disapproved <span className="aNumber">{this.state.filteredData?this.state.disapprovedCount: 0}</span></ToggleButton>
                       
                      </ToggleButtonGroup>
                      <div className="d-flex align-items-center">
                        <Button variant="contained" className="download cancelbtn"  onClick={(e) => { this.fetchData() }} >
                          <img src="assets/lib/images/home/download.svg" />
                        </Button>
                        <div className="atl_search">
                          <input type="search" placeholder="Search..."/>
                        </div>
                      </div>
                    </div>
                  </div>              
                  

                  

                  <div className="manageActor">
                    <FixedHeaderStory
                      columns={this.state.openTab =='Pending' && this.columnsPending 
                      ||  this.state.openTab =='Approved'  && this.columnsApproved 
                      ||   this.state.openTab =='Rejected' && this.columnsRejected ||  this.columnsPending}
                      data={this.state.filteredData}
                      direction="auto"
                      fixedHeaderScrollHeight="500px"
                      pagination
                      paginationRowsPerPageOptions={[10, 25, 50,100]}
                      style={{ display: "flex" }}
                      persistTableHead                      
                      clearSelectedRows={this.handleChangeData}

                      fixedHeader
                      className="activityTablefix"
                    />

                  </div>
                      
               </div>
              
                  
          
          </section>
        </div>
        
      </div>






      



      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350 activitySidebar': 'gallery-sidebar add-area-modal add-form activitySidebar width0'}>

      <div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
              <img
                src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
            </div>
            <div class="right-tab-heading are_head">
              <h2 class="form-heading form-heading text_trnsfrm-all-labels">
                <span class="form-heading_he">
                  <span class="ddf">
                    {this.state.isEdit==true ? 'Edit Activity': 'Add Activity'}
                    </span>
                </span>
              </h2>
            </div>
        <AddActivityModal
               atTreeData = {this.state.atTreeData} 
               indicatorId={this.getSubIndicators} 
               subGroupData={this.state.subIndData}
               areaTree = {this.state.areaTree}
               flatArea={this.state.flatArea}
               WasteTypeOrg={this.state.WasteTypeOrg}
               newDataEntry ={this.saveData}
               areaData ={this.state.areaData}
               editData = {this.state.editEntry}
               isEdit = {this.state.isEdit}
               closeModal={this.openAddModal}
               filterTypeData = {this.state.filterTypeData}
               actorProfileData = {this.state.actorProfileData} 
               masterData = {this.state.masterData} 
               actorsTreeData = {this.state.actorsTreeData} 
               ftTreeData = {this.state.ftTreeData} 
               actorAreaData = {this.state.actorAreaData} 
               sourceType = {this.state.sourceType} 
               cvTreeData = {this.state.cvTreeData} 
               licenceData = {this.state.licenceData} 
               wtTreeData = {this.state.wtTreeData} 
        />

      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>

      </div>



      <div className={this.state.modal ? 'overlay dblock':'overlay'} ></div>


      {/* <ProfileModal profileData={this.state.profileData}/> */}

      <ActivityViewModal profileData={this.state.profileData} flatArea={this.state.flatArea}/>
      
    </>
  );
};

export default activityTemplate;