import { Component } from 'react'
import './information-kiosk.css';
import * as jsx from './information-kiosk.module.jsx';
import * as $ from 'jquery';
import * as constants from '../../../Config/app.constants';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import { connect } from "react-redux";

class InformationPage extends Component {
    constructor() {
        super()
        this.state = {
            value: 0,
            loader: false,
            shortItem: false,
            data: [],
            shortAbc: false,
            resData: [],
            tab: 'All',
            currentPage: 1,
            backupRes: [],
            pagerCount: 0,
            pageLimit: 1,
            backupLimit: 1,
            nonFilterData:[]
        }
    }

    handlePageChange = (event, page) => {
        this.setState({ currentPage: page });

    };

    componentDidMount() {

        this.getResource();



    }

    render() {
        return jsx.default.bind(this)();
    }








    chunkArray(myArray, chunk_size) {
        var index = 0;
        var arrayLength = myArray.length;
        var tempArray = [];

        for (index = 0; index < arrayLength; index += chunk_size) {
            const myChunk = myArray.slice(index, index + chunk_size);
            // Do something if you want with the group
            tempArray.push(myChunk);
        }

        return tempArray;
    }


    filterData(e) {

        let key = e.target.value;
        const storeData = this.state.resData;
        console.log('backup',this.state.nonFilterData)
        if (key.length > 0) {

            


            if (this.state.nonFilterData != null && this.state.nonFilterData != '') {
                const results = this.state.nonFilterData.filter(item =>
                    item.title.toLowerCase().includes(key.toLowerCase()) 
                 || item.description.toLowerCase().includes(key.toLowerCase()) 
                );

                

                if (results.length > 5) {
                    const result = this.chunkArray(results, 5);



                    // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
                    const preRes = result[this.state.pagerCount] != undefined ? result[this.state.pagerCount] : result;
                 

                    this.setState({ resData: preRes, pageLimit: result })

                }
                else {
                    const result = this.chunkArray(results, 5);
                    console.log('cherck2', result)

                    this.setState({ resData: results, pageLimit: result?.length})
                }



            }
            else {
                const results = this.state.backupRes.filter(item =>
                    item.title.toLowerCase().includes(key.toLowerCase())
                || item.description.toLowerCase().includes(key.toLowerCase())
                );

                if (results.length > 5) {
                    const result = this.chunkArray(results, 5);



                    // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
                    const preRes = result[this.state.pagerCount] != undefined ? result[this.state.pagerCount] : result;
                    console.log('check', result)


                    this.setState({ resData: preRes, pageLimit: result?.length })

                }
                else {

                    const result = this.chunkArray(results, 5);
                    console.log('cherck3', result)
                    this.setState({ resData: results, pageLimit: result?.length})
                }





            }

        }
        else {

            this.tabDataChange(this.state.tab)
        }
      

    }


    paginate = (e, page) => {
        const cnt = page - 1;
        console.log(cnt)
        this.setState({ pagerCount: cnt })
        this.getResource()


    }



    tabDataChange(d) {
      
        this.setState({ tab: d });
        const storeData = [];
        if (d != 'All') {
            
            if(  this.state.nonFilterData?.length > 0){
                this.state.nonFilterData.filter(data => data.type.includes(d)).map(item => (

                    storeData.push(item)
                ))
            }else{
                this.state.data.filter(data => data.type.includes(d)).map(item => (

                    storeData.push(item)
                ))
            }
               
            

            console.log('cherck',  this.state.data)

            if (storeData.length > 5) {
                const result = this.chunkArray(storeData, 5);



                // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
                const preRes = result[this.state.pagerCount] != undefined ? result[this.state.pagerCount] : result;
                
                this.setState({ resData: preRes, backupRes: preRes, pageLimit: result?.length })


            }
            else {
                const result = this.chunkArray(storeData, 5);

                this.setState({ resData: storeData, backupRes: storeData, pageLimit: result?.length })
            }


        } else {
            this.getResource();
            const result = this.chunkArray(  this.state.data, 5);
    
            console.log('sssd',   this.state.data, result)

            this.setState({ resData: this.state.data, backupRes: this.state.data, pageLimit: result?.length })
        }

    }





    getResource() {
       
        const dataToBeSend = {}
        AdminService.httpGet('/data-retrieval/resources/get-all-resources', dataToBeSend).then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;
              
                if (d.length > 5) {
                    const result = this.chunkArray(d, 5);



                    // Outputs : [ [1,2,3] , [4,5,6] ,[7,8] ]
                    const preRes = result[this.state.pagerCount] != undefined ? result[this.state.pagerCount] : result;
                   
                    this.setState({nonFilterData:d, resData: preRes, backupRes: preRes, data: preRes, pageLimit: result?.length, backupLimit: result?.length })


                }
                else {
                    const result = this.chunkArray(d, 5);

                    this.setState({ resData: d, backupRes: d, data: d, pageLimit: result, backupLimit: d?.length })
                }



            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }




    shortData = () => {
        if (this.state.shortItem == false) {
            this.setState({ shortItem: true });
            const short = this.state.resData.reverse();

            this.setState({ resData: short, backupRes: short })

        } else {
            const short = this.state.resData.reverse();

            this.setState({ resData: short, backupRes: short })
            this.setState({ shortItem: false });
        }

    };



    shortAbc = () => {
        if (this.state.shortAbc == false) {

            this.setState({ shortAbc: true });

            const short = this.state.resData.sort((a, b) => a.title.localeCompare(b.title))

            this.setState({ resData: short, backupRes: short })
        } else {
            this.setState({ shortAbc: false });

            const short = this.state.resData.sort((a, b) => b.title.localeCompare(a.title))

            this.setState({ resData: short, backupRes: short })
        }
    };

}

export default connect(

)(InformationPage);