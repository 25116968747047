import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  loggedIn: false,
  userEmail: '',
  token: '',
  userInfo: {}
}

export const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
      login: (state, action) => {
        state.loggedIn = true;
        state.token = action.payload.token;
        state.userEmail = action.payload.email;
        state.userInfo = action.payload.userInfo;
        localStorage.setItem('token', action.payload.token);
        localStorage.setItem('email', action.payload.email);
        localStorage.setItem('userInfo', JSON.stringify(action.payload.userInfo));
      },
      logout: (state) => {
        state.loggedIn = false;
        state.token = '';
        state.userEmail = '';
        state.userInfo = '';
        localStorage.clear('token');
        localStorage.clear('email');
        localStorage.clear('userInfo');
        localStorage.clear('perc')
      },
    },
  })
  
  // Action creators are generated for each case reducer function
  export const { login, logout } = loginSlice.actions
  
  export default loginSlice.reducer