import React from 'react';
import DataTable from 'react-data-table-component';
import Breadcrumbs from '../../Layout/BreadCrumbs/Breadcrubms';
import Footer from '../../Layout/footer';
import Header from '../../Layout/header';
import Sidebar from '../../Layout/sidebar';
import AddAreaModal from '../../Modals/addArea.modal';
import * as constants from '../../../Config/app.constants';
import * as subHeaderComponentMemo from '../../../Common/components/TableSubHeader';
// import ManageActivity from '../../Modals/resources.modal';


const manageactivityTemplate = function () {
  // const handleChange = ({ selectedRows }) => {
  //   // You can set state or dispatch with something like Redux so we can use the retrieved data
  //   console.log('Selected Rows: ', selectedRows);
  // };



  return (
    <>

      {/* 
      <Breadcrumbs />

      <Sidebar /> */}

      <div className="main manage-resource manage_activity_section" id="main">
        <div className='container-fluid areaCont'>
          <section className='areaSection' id="areaSection">



            {/* <div className='col-lg-12 col-md-12'>
            <div className="row header-div new-h-d pt-0 pl-0"> */}
            {/* <div className="col-md-1 ">
              <div class="form-group form-inline">                            
                  <label >Show</label>
                      <select  className='form-control' value={this.state.dataLimit} onChange={this.updateDataLimit}>
                        <option value={10}>10</option>
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                        </select>
                  </div>
     

              </div> */}

            {/* <div className="col-md-2">

              <div class="form-group form-inline">                            
                  <label >Status</label>
                      <select id="statusSelect" className='form-control' value={this.state.dataStatus} onChange={this.getDataByStatus}>
                        <option value={2}>Pending</option>
                        <option value={1}>Approved</option>
                        <option value={3}>Disapproved</option>
                        </select>
                  </div>
              </div> */}
            {/* <div className="col-md-12 pl-2">
              <div class="">     */}

            {/* <SearchComponent.default onSearch={props.onSearch} searchText={props.searchText} /> */}
            <subHeaderComponentMemo.default onSearch={this.onSearch}
              searchText={this.state.searchText}
              PropDeleteBulk={this.deleteBulkUser}
              nRows={this.state.filteredData?.length}
              checkData={this.state.bulkDelete}
            /> {/*rowsPerPage={this.state.rowsPerPage} onRowsChange={this.rowsUpdate} */}

            {/* </div>
              </div>
            </div>
          </div> */}

            <DataTable
              columns={this.columns}
              data={this.state.filteredData}
              direction="auto"
              fixedHeaderScrollHeight="500px"
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              style={{ display: "flex" }}
              // subHeader
              // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
              persistTableHead
              // selectableRows
              // onSelectedRowsChange={this.handleChange}
              clearSelectedRows={this.handleChangeData}
              className='dataTableScroll'
            />
          </section>
        </div>
      </div>






      {/* modal area begin here============== */}



      <div id="AddAreaModal" className={this.state.modal ? 'gallery-sidebar add-area-modal add-form width350' : 'gallery-sidebar add-area-modal add-form width0'}>

        {/* <div id="openDataModal" onClick={this.openAddModal} ariaTooltip="Button that displays and hides a tooltip triggered by other buttons" className=" gallery-toggler maroon_bg add-user " style={{ 'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)' }} aria-describedby="cdk-describedby-message-38" cdk-describedby-host="">
              <img
                src="assets/lib/images/svg/add1.svg" style={{ 'width': '17px' }} />
            </div> */}
        <div class="right-tab-heading are_head">
          <h2 class="form-heading">
            <span class="form-heading_he">
              <span class="ddf">
                {this.state.isEdit == true ? 'Edit Resource' : 'Add Resource'}
              </span>
            </span>
          </h2>
        </div>
        {/* <ResourceEntryModal 
            indicatorData = {this.state.indicatorData} 
            indicatorId={this.getSubIndicators} 
            subGroupData={this.state.subIndData}
            areaTree = {this.state.areaData}
            newDataEntry ={this.saveData}
            editData = {this.state.editEntry}
            isEdit = {this.state.isEdit}
            /> */}



      </div>


      <div class="modal fade pending_list_popup" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">

            <h5 class="modal-title" id="exampleModalLabel">{this.state.actorDetails.name} <span>({this.state.actorDetails.status == 2 ? 'Pending' : this.state.actorDetails.status == 1 ? 'Approved' : 'Disapproved'} Activity)</span></h5>
              <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true"><img src="assets/lib/images/cross.svg" alt="" /></span>
              </button>
            </div>
            <div class="modal-body pt-0 px-0 position-relative">
              {
                this.state.actorDetails.status == 2?
                <button type="button" disabled={this.state.pendingActivities.length<1} class="btn btn-primary approve_popup_btn" onClick={this.openSwal}>
               Approve All <img src='assets/lib/images/home/double_tick.svg'/>
              </button>:null
              }

              <subHeaderComponentMemo.default onSearch={this.onSearchModal}
                searchText={this.state.searchText}
                PropDeleteBulk={this.deleteBulkUser}
                nRows={this.state.pendingActivities?.length}
                checkData={this.state.bulkDelete}
              />
              <div className='pending_list_table'>
                <DataTable
                  columns={this.columns_activity}
                  data={this.state.pendingActivities}
                  direction="auto"
                  fixedHeaderScrollHeight="500px"
                  pagination
                  paginationRowsPerPageOptions={[10, 25, 50, 100]}
                  style={{ display: "flex" }}
                  // subHeader
                  // subHeaderComponent={subHeaderComponentMemo.default(this.onSearch, this.searchText)}
                  persistTableHead
                  selectableRows
                  onSelectedRowsChange={this.handleChange}
                  clearSelectedRows={this.handleChangeData}
                />
              </div>
            </div>
            {/* <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary">Save changes</button>
          </div> */}
          </div>
        </div>
      </div>


      {/* <div class="modal fade" id="approve_popup" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header border-none">
              <button type="button" class="close headcross-btn" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body text-center">
               <h3>Are you sure you want to Approve All?</h3>

            </div>
            <div class="modal-footer border-none justify-content-center approve_footer">
              <button type="button" class="btn btn_grey" data-dismiss="modal">Cancle</button>
              <button type="button" class="btn">Ok</button>
            </div>
          </div>
        </div>
      </div> */}




      {/* 
      <Footer /> */}

      <div className={this.state.loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'marginTop': '12%', 'width': '30%' }} />
      </div>



      <div className={this.state.modal ? 'overlay dblock' : 'overlay'} ></div>

    </>
  );
};

export default manageactivityTemplate;