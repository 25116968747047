import React, { useState } from 'react'
import { useNavigate, Link, NavLink, useLocation } from 'react-router-dom'

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './layout.css';
import '../../App.css';
function Footer() {

    const currentYear = new Date().getFullYear(); // 2020

    const previousYear =  currentYear-1;

    const location = useLocation();
    const route = location.pathname;

    const userInfo = localStorage.getItem('userInfo');
    const role = JSON.parse(userInfo);
    

  return (
    <>
       <footer className={route != '/dashboard/tracker' && route != '/data-analytics' && route != '/dashboard/profile' && route != '/dashboard/activity'  ? 'footer_Section' : 'footer_Section footer_dashboard'}>

            {(route != '/dashboard/tracker')  && route != '/data-analytics' && route != '/dashboard/profile' && route != '/dashboard/activity' && route != '/dashboard/data-search-result' &&
                <div className='footer_top'>
                    <div className='custom_container'>
                        <div className='row'>
                            <div className='col-md-3'>
                                <img className='footerLogo' src="assets/lib/images/home/eea_undp_logo.svg"/>
                            </div>
                            <div className='col-md-9'>
                            <div className='headerright'>
                                <Navbar expand="lg">
                                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                                    <Navbar.Collapse id="basic-navbar-nav">
                                        <Nav className="me-auto">
                                        <NavLink to='/home' className='nav-link' activeClassName="active">Home</NavLink>
                                        <NavLink to='/information-kiosk'  className='nav-link' activeClassName="active">Information Kiosk</NavLink>
                                        <NavLink to='/dashboard/tracker'  className='nav-link' activeClassName="active">Tracker</NavLink>
                                        <NavLink to='/data-analytics'  className='nav-link' activeClassName="active">Data Analytics</NavLink>
                                        {/* { (role?.role == 0 || role?.role == 5 || userInfo===null) && (route == '/home' || route == '/' ) ?  
                                        <NavLink to='/dashboard/profile'  className='nav-link' activeClassName="active">Profile</NavLink> : ''
                                        } */}
                                        {role?.role == '5' ? <li><NavLink to='/dashboard/profile'  className='nav-link' >Profile</NavLink></li> : ''}
                                        <NavLink to='/contact'  className='nav-link' activeClassName="active">Contact</NavLink>
                                        </Nav>
                                    </Navbar.Collapse>
                                </Navbar>
                            </div>
                            <ul className='sociallink'>
                                {/* <a href='javascript:void(0)'><img src="assets/lib/images/home/twitter.svg"/></a> */}
                                <a href='https://www.facebook.com/EswatiniEnvironmentAuthority/' target="_blank"><img src="assets/lib/images/home/facebook.svg"/></a>
                                <a href='https://www.instagram.com/eswatinienvironmentauthority/' target="_blank"><img src="assets/lib/images/home/instagram.svg"/></a>
                                <a href='https://www.youtube.com/channel/UC9VhHuFiUX9k6c9K0bMyqkw' target="_blank"><img src="assets/lib/images/home/youtube.svg"/></a>
                            </ul>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <div className='footer_bottom'>
                <div className='custom_container'>
                    <div className='row align-items-center'>
                        <div className='col-sm-8'>
                            <p className='text-center text-sm-left'>Copyright © {previousYear}-{currentYear}. All rights reserved.</p>
                        </div>
                        <div className='col-sm-4 text-center text-sm-right'>
                            <p className='text-center text-sm-right'><span>Powered by</span> <a href="http://www.weindic.com/" target="_blank">WEindic Inc.
                                    {/* <img src="assets/lib/images/home/itm_logo.svg"/> */}
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
       </footer>
    </>

  );
}

export default Footer;