import React, { useState, useEffect } from 'react';

function MetaDataModal(props) {
  const {sector, iuId, metaData, indicator, unit, type} = props;

  useEffect(()=> {
    console.log("MetaData", metaData);
  }, [metaData]);

  return (
    <>
      <span className='metadata' data-toggle="modal" data-target={"#metadatamodal"+sector+iuId+type}><img src='assets/lib/images/home/indicator.svg' /></span>
          <div class="modal fade maps_popup metadatamodal" id={"metadatamodal"+sector+iuId+type} tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div class="modal-dialog modal-dialog-centered">
                <div class="modal-content">
                  <div class="modal-header">
                    <div class="modal-title h4">{indicator} <span>| {unit}</span></div>
                    <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body">
                    <h4>Indicator Definition</h4>
                    <p>{metaData?.mt1? metaData?.mt1: '--'}</p>
                    <h4>Method of Computation</h4>
                    <p>{metaData?.mt2? metaData?.mt2: '--'}</p>
                    <h4>Overview</h4>
                    <p>{metaData?.mt3? metaData?.mt3: '--'}</p>
                    <h4>Comments and Limitations</h4>
                    <p>{metaData?.mt4? metaData?.mt4: '--'}</p>
                    <h4>Data Collection for Global Monitoring</h4>
                    <p>{metaData?.mt5? metaData?.mt5: '--'}</p>
                    <h4>Obtaining Data</h4>
                    <p>{metaData?.mt6? metaData?.mt6: '--'}</p>
                    <h4>Data Availability</h4>
                    <p>{metaData?.mt7? metaData?.mt7: '--'}</p>
                    <h4>Treatment of Missing Values</h4>
                    <p>{metaData?.mt8? metaData?.mt8: '--'}</p>
                    <h4>Regional and Global Estimates</h4>
                    <p>{metaData?.mt9? metaData?.mt9: '--'}</p>
                  </div>              
                </div>
              </div>
            </div>

    </>
  );
}


export default MetaDataModal;