import React, { Component, useEffect, useRef, useState } from 'react';
import { Link } from '@material-ui/core';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import Slider from '@mui/material/Slider';
import { Label } from '@mui/icons-material';
import FixedHeaderStory from 'react-data-table-component';

import Accordion from 'react-bootstrap/Accordion';
import MapView from '../mapview/mapview';
import ProfileModal from '../../../Common/components/modal/profile.modal';
import ActorChart from '../../actor-chart/actor.chart';
import Footer from '../../layout/footer';

import * as $ from 'jquery';

import * as htmlToImage from 'html-to-image';
var trackerTemplate = function () {

 


  const top100Films = [
    { label: 'The Shawshank Redemption', year: 1994 },
    { label: 'The Godfather', year: 1972 },
    { label: 'The Godfather: Part II', year: 1974 },
    { label: 'The Dark Knight', year: 2008 },
    { label: '12 Angry Men', year: 1957 },
    { label: "Schindler's List", year: 1993 },
    { label: 'Pulp Fiction', year: 1994 },
    {
      label: 'The Lord of the Rings: The Return of the King',
      year: 2003,
    },
    { label: 'The Good, the Bad and the Ugly', year: 1966 },
    { label: 'Fight Club', year: 1999 },
    {
      label: 'The Lord of the Rings: The Fellowship of the Ring',
      year: 2001,
    },
    {
      label: 'Star Wars: Episode V - The Empire Strikes Back',
      year: 1980,
    },
    { label: 'Forrest Gump', year: 1994 },
    { label: 'Inception', year: 2010 },
    {
      label: 'The Lord of the Rings: The Two Towers',
      year: 2002,
    },
    { label: "One Flew Over the Cuckoo's Nest", year: 1975 },
    { label: 'Goodfellas', year: 1990 },
    { label: 'The Matrix', year: 1999 },
    { label: 'Seven Samurai', year: 1954 },
    {
      label: 'Star Wars: Episode IV - A New Hope',
      year: 1977,
    },
    { label: 'City of God', year: 2002 },
    { label: 'Se7en', year: 1995 },
    { label: 'The Silence of the Lambs', year: 1991 },
    { label: "It's a Wonderful Life", year: 1946 },
    { label: 'Life Is Beautiful', year: 1997 },
    { label: 'The Usual Suspects', year: 1995 },
    { label: 'Léon: The Professional', year: 1994 },
    { label: 'Spirited Away', year: 2001 },
    { label: 'Saving Private Ryan', year: 1998 },
    { label: 'Once Upon a Time in the West', year: 1968 },
    { label: 'American History X', year: 1998 },
    { label: 'Interstellar', year: 2014 },
    { label: 'Casablanca', year: 1942 },
    { label: 'City Lights', year: 1931 },
    { label: 'Psycho', year: 1960 },
    { label: 'The Green Mile', year: 1999 },
    { label: 'The Intouchables', year: 2011 },
    { label: 'Modern Times', year: 1936 },
    { label: 'Raiders of the Lost Ark', year: 1981 },
    { label: 'Rear Window', year: 1954 },
    { label: 'The Pianist', year: 2002 },
    { label: 'The Departed', year: 2006 },
    { label: 'Terminator 2: Judgment Day', year: 1991 },
    { label: 'Back to the Future', year: 1985 },
    { label: 'Whiplash', year: 2014 },
    { label: 'Gladiator', year: 2000 },
    { label: 'Memento', year: 2000 },
    { label: 'The Prestige', year: 2006 },
    { label: 'The Lion King', year: 1994 },
    { label: 'Apocalypse Now', year: 1979 },
    { label: 'Alien', year: 1979 },
    { label: 'Sunset Boulevard', year: 1950 },
    {
      label: 'Dr. Strangelove or: How I Learned to Stop Worrying and Love the Bomb',
      year: 1964,
    },
    { label: 'The Great Dictator', year: 1940 },
    { label: 'Cinema Paradiso', year: 1988 },
    { label: 'The Lives of Others', year: 2006 },
    { label: 'Grave of the Fireflies', year: 1988 },
    { label: 'Paths of Glory', year: 1957 },
    { label: 'Django Unchained', year: 2012 },
    { label: 'The Shining', year: 1980 },
    { label: 'WALL·E', year: 2008 },
    { label: 'American Beauty', year: 1999 },
    { label: 'The Dark Knight Rises', year: 2012 },
    { label: 'Princess Mononoke', year: 1997 },
    { label: 'Aliens', year: 1986 },
    { label: 'Oldboy', year: 2003 },
    { label: 'Once Upon a Time in America', year: 1984 },
    { label: 'Witness for the Prosecution', year: 1957 },
    { label: 'Das Boot', year: 1981 },
    { label: 'Citizen Kane', year: 1941 },
    { label: 'North by Northwest', year: 1959 },
    { label: 'Vertigo', year: 1958 },
    {
      label: 'Star Wars: Episode VI - Return of the Jedi',
      year: 1983,
    },
    { label: 'Reservoir Dogs', year: 1992 },
    { label: 'Braveheart', year: 1995 },
    { label: 'M', year: 1931 },
    { label: 'Requiem for a Dream', year: 2000 },
    { label: 'Amélie', year: 2001 },
    { label: 'A Clockwork Orange', year: 1971 },
    { label: 'Like Stars on Earth', year: 2007 },
    { label: 'Taxi Driver', year: 1976 },
    { label: 'Lawrence of Arabia', year: 1962 },
    { label: 'Double Indemnity', year: 1944 },
    {
      label: 'Eternal Sunshine of the Spotless Mind',
      year: 2004,
    },
    { label: 'Amadeus', year: 1984 },
    { label: 'To Kill a Mockingbird', year: 1962 },
    { label: 'Toy Story 3', year: 2010 },
    { label: 'Logan', year: 2017 },
    { label: 'Full Metal Jacket', year: 1987 },
    { label: 'Dangal', year: 2016 },
    { label: 'The Sting', year: 1973 },
    { label: '2001: A Space Odyssey', year: 1968 },
    { label: "Singin' in the Rain", year: 1952 },
    { label: 'Toy Story', year: 1995 },
    { label: 'Bicycle Thieves', year: 1948 },
    { label: 'The Kid', year: 1921 },
    { label: 'Inglourious Basterds', year: 2009 },
    { label: 'Snatch', year: 2000 },
    { label: '3 Idiots', year: 2009 },
    { label: 'Monty Python and the Holy Grail', year: 1975 },
  ];

  const marks = [
    {
      value: 0,
      label: '0',
    },
    
    {
      value: 100,
      label: '1,00,000',
    },
  ];


   

  

 
  
  
  return (
    <>

      <section className='tracker_section'>
      <div className='mapBox tracker_Map'  style={{ background: '#fff' }}>

        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-lg-5 pr-30">
              <MapView 
                masterData={this.state.masterData} 
                parentArea = {this.currentAreaCode} 
                dumpDataMain={this.state.fecData}
                enableFilter={this.state.viewProfile}
                areaName = {this.currentAreaName}
                areaList = {this.state.mainAreaData}
                
           
              />
            </div>
            <div className="col-lg-7 h-100">
              <div className="tracker_right_box detail_box barchart" id="actorChart" >
                <div className="map_value d-flex align-items-center justify-content-between">
                  <h3>Number of Actors: <span >{this.state.actorData?.length}</span><span className='countryName'>| {this.currentAreaName}</span></h3>
                  <div className='numberActor-dlt' onClick={this.downloadMap}>
                       <img src='assets/lib/images/home/download.svg' />
                  </div>
                </div>

                <div className="map_area text-center">    
                    <ActorChart actorData = {this.state.actorData}/>
                </div>
              </div>
              <div className='tableTracker actor_details'>
                <FixedHeaderStory
                  columns={this.columns}
                  data={this.state.actorData}         
                  showPagination={false}               
                  fixedHeader
                  fixedHeaderScrollHeight="300px"
                  persistTableHead
                  className='trackTablefix'
                />
              </div>
            </div>

          </div>
        </div>
        </div>
        {/* <div className="container-fluid actor_details">
          <div className='row'>
            <div className='col-md-12'>
              <h3>Actor Details</h3>
            </div>
            <div className='col-md-12'>
              <FixedHeaderStory
                columns={this.columns}
                data={this.state.actorData}         
                showPagination={false}               
                fixedHeader
                fixedHeaderScrollHeight="300px"
              />
            </div>
          </div>
        </div> */}
      </section>

      <Footer />



      {/* ================================================================ Modal ======================================================= */}
          {/* <Modal centered show={show} onHide={handleClose} className="maps_popup">
            <Modal.Header closeButton>
              <Modal.Title>Facility Name | <div className='mp_title'>Manzini <span>(Permitted)</span></div></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='facility_details'>
                <div className='fd_img'>
                  <img src='assets/lib/images/home/facility_details_img.jpg' />
                </div>
                <div className='fd_text'>
                  <ul className='fd_text_box'>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/license_number.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>License Number</h5>
                        <h4>6598563565</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/date.svg' />
                      </div>
                      <div className='fdt_text fdt_date'>
                        <div>
                          <h5>Date Awarded From</h5>
                          <h4>19 Nov, 2022</h4>
                        </div>
                        <div>
                          <h5>Date Awarded To</h5>
                          <h4>23 Dec, 2022</h4>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/location.svg' />
                      </div>
                      <div className='fdt_text fdt_date'>
                        <div>
                          <h5>Longitude</h5>
                          <h4>256454555</h4>
                        </div>
                        <div>
                          <h5>Latitude</h5>
                          <h4>-276782672</h4>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/file.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Description</h5>
                        <h4>There are many variations of passages of Lorem Ipsum available.</h4>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/area.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Area (sq.meter)</h5>
                        <h4>97,876</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/facility-type.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Facility Type</h5>
                        <h4>Dumpsites</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/delete.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Waste Type</h5>
                        <h4>Hazardous</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/document.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Document</h5>
                        <h4>file001.pdf</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion 1</Accordion.Header>
                  <Accordion.Body>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Modal.Body>            
          </Modal> */}
  




          {/* <Modal centered show={this.state.viewprofilemodal} onHide={this.state.handleClose} className="maps_popup">
            <Modal.Header closeButton>
              <Modal.Title>Actor Profile Detail</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className='facility_details'>
                <div className='fd_text'>
                  <ul className='fd_text_box'>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/license_number.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>License Number</h5>
                        <h4>6598563565</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/date.svg' />
                      </div>
                      <div className='fdt_text fdt_date'>
                        <div>
                          <h5>Date Awarded From</h5>
                          <h4>19 Nov, 2022</h4>
                        </div>
                        <div>
                          <h5>Date Awarded To</h5>
                          <h4>23 Dec, 2022</h4>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/location.svg' />
                      </div>
                      <div className='fdt_text fdt_date'>
                        <div>
                          <h5>Longitude</h5>
                          <h4>256454555</h4>
                        </div>
                        <div>
                          <h5>Latitude</h5>
                          <h4>-276782672</h4>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/file.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Description</h5>
                        <h4>There are many variations of passages of Lorem Ipsum available.</h4>
                      </div>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/area.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Area (sq.meter)</h5>
                        <h4>97,876</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/facility-type.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Facility Type</h5>
                        <h4>Dumpsites</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/delete.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Waste Type</h5>
                        <h4>Hazardous</h4>
                      </div>
                    </li>

                    <li>
                      <div className='fdt_img'>
                        <img src='assets/lib/images/home/document.svg' />
                      </div>
                      <div className='fdt_text'>
                        <h5>Document</h5>
                        <h4>file001.pdf</h4>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header>Accordion 1</Accordion.Header>
                  <Accordion.Body>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>
                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="1">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="2">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
                <Accordion.Item eventKey="3">
                  <Accordion.Header>Accordion 2</Accordion.Header>
                  <Accordion.Body>
                  <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>

                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
                    minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip ex ea commodo consequat. Duis aute irure dolor in
                    reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
                    pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id est laborum.</p>                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Modal.Body>            
          </Modal> */}
      {/* ================================================================ Modal ======================================================= */}
      {/* ================================FILTER MODAL============================== */}

      


      <div id="copy" className='col-md-6' style={{ 'display': 'none' }} > </div>
      
     

          
       <ProfileModal profileData={this.state.profileData} activityData={this.state.activityData} flatArea={this.state.flatArea} />
    
       <div className={this.state.loader==true ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
      </div>
    </>
  );


};


export default trackerTemplate;
