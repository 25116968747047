import React, { Component, useEffect, useState } from 'react';

import ReactEChartsCore from 'echarts-for-react/lib/core';

import * as echarts from 'echarts/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TooltipComponent,
  TitleComponent,
  DatasetComponent,
} from 'echarts/components';
import { CanvasRenderer } from 'echarts/renderers';



var ActorChart = function (props) {

const [state, setState] = useState([]);



useEffect(()=>{

    
  
    const arr2 = [];
    console.log( 'newAct', props.actorData)
    
            props.actorData.forEach(element => {
                if(element.profile_type!=null){
                  if(element.profile_type!=''){
                    arr2.push(...element.profile_type)
                  }

                }
              
            });


            var map = arr2.reduce(function(obj, b) {
                obj[b] = ++obj[b] || 1;
                return obj;
              }, {});

             
              console.log('checkcounter', map)
      
    setState(map)


},[props])


  echarts.use([
    TitleComponent,
    TooltipComponent,
    GridComponent,
    LegendComponent,
    BarChart,
    CanvasRenderer,
  ]);

  

  
  





const data = [];

for (const [key, value] of Object.entries(state)) {
  data.push({
    name: key,
    value:value? value:'',
  })
}

  console.log('sjhjhjhjhs', data)

  const colors = {
    0: '#E0FFE6',
    1: '#A4E5B0',
    2: '#77D488',
    3: '#42AF56',
    4: '#208B34',
    5: '#0C6638',
    6: '#01391d',
  };




  
  const option = {
    title: {
      text: '',
    },

    grid:{
        show: false,
        containLabel: true,
        left: '2%',
        bottom: '3%',
        top: '3%'
       },       
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },

    strokeWidth: 0,
    xAxis: {
        show:false,
      

     splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'category',
      symbol: 'none',
      lineThickness: 0,
      gridThickness: 0,
      tickLength: 0,
      splitLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLine: {
        show: false
      },
  
      data: data.sort((a, b) => { return a.value - b.value;}).map((item) => item.name),
    },
    series: [
      {
        type: 'bar',
        data: data.sort((a, b) => { return a.value - b.value;}).map((item) => item.value),
        itemStyle: {
          color: function (param) {
            console.log(param);
            return colors[param.dataIndex];
          },          
        },
        label: {
          show: true,
          position: 'right',
          valueAnimation: true,
          fontSize:16,
          fontFamily:'Mulish',
          fontWeight:500,
          color: '#666666',
        }
      },
    ],
  };
  


 
  
  return (
    <>
              <ReactEChartsCore
                    echarts={echarts}
                    option={option}
                    notMerge={true}
                    lazyUpdate={true}
                    withInnerLines={false}
                    theme={'theme_name'}
                    
                    opts={{}}
                  />
    </>
  );


};


export default ActorChart;
