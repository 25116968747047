import "./ExportArea.css";
import * as constants from '../../../Config/app.constants';


import { useEffect, useRef, useState } from "react";


import AdminService from '../../../services/admin.service';








const ExportWithData = ({value}) => {
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  
 
 console.log("value--"+value)
  

 const today = new Date();
 const currentYear = today.getFullYear();
 const currentMonth = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
 const currentDate = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
 const todaysDate = String(currentYear) + String(currentMonth) + String(currentDate);






  const fetchData = async (e) => {
    e.preventDefault();


     await AdminService.httpGet('/data-retrieval/data/exportData/'+value).then((res)=>{
      e.preventDefault();
        if(res){
            
            let d = res;
            
            

                        
                let filepath = constants.logFilePath + d.filepath;
                
                console.log("filepath:"+filepath);
                let filename = d.filepath.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'WMIS_' + filename + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);


     
          
        }
    
    }).catch((err)=>{
    
    console.log(err)
    })


    

  



};








  return (
    <>
    
    <div className="w-100"> 
      <button type="button" onClick={(e)=>fetchData(e)} class="btn btn_browse w-100 popup_btn" id="download_icius_master_template_with_data">
      With Data </button>
    </div>
    
    
    
    </>
  );
};

export default ExportWithData;