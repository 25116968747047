import { Component } from 'react'
import './dump-site.css';
import * as jsx from './dump-site.module.jsx';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import Tooltip from '@mui/material/Tooltip'; 
import dayjs from 'dayjs';
import { connect } from "react-redux";

class DumpSite extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            availableData:[],
            operatingtemeperatureData:[],
            searchText: '',
            isEdit: false,
            modal: false,
            loader: false,
            dataLimit: 10,
            editEntry:[],
            areaData: [],
            bulkDelete :[],
            treeData : []
        }
  



    }

    componentDidMount() {
        this.getData();
        // this.getWasteData();
        this.getOperationData();
        this.getAvailableServicesData();
        this.getOperatingTemeperatureData();
        this.getAllAreaData();
        this.getfacitlityData();
        AdminService.checkToken();

      
    }


    render() {
        return jsx.default.bind(this)();
    }



    // update data limit===================//


    updateDataLimit = (e) => {


        this.setState({ dataLimit: e.target.value })
        this.getData();

    }






    // filter table and get data====================//

    onSearch = (val) => {
        this.setState({searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            fd = d.filter(
                item => (item.location && item.location.toLowerCase().includes(val.toLowerCase())
                       || (item.facility_name && item.facility_name.toLowerCase().includes(val.toLowerCase()))
                       || (item.description && item.description.toLowerCase().includes(val.toLowerCase()))
                       || (item.facility_type && item.facility_type.toLowerCase().includes(val.toLowerCase()))
                    //    || (item.waste_types && item.waste_types.toLowerCase().includes(val.toLowerCase()))
                      
                ));
        }
        this.setState({ filteredData: fd });
    }


    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) => row.index+1,
        //     sortable:true,
        //     width: "50px"  
        // },
        {
            name:'Location',
            selector:row =>row.location,
            sortable:true,
            width:"150px"
        },
        // {
        //     name: 'Location',
        //     selector: (row, index) =>
        //         <> <b>{row.dumpSiteId}</b></>,
        //     sortable: false,
        //     width: "300px"
        // },
        {
            name: 'Name',
            selector: row => row.facility_name,
            sortable: true,
            width: "200px"
        },
        // {
        //     name: 'Description',
        //     // selector: row => row.description,
        //     selector: row =>  <Tooltip title={row.description} placement="top">
        //     <p>{row.description}</p>
        //     </Tooltip>,
        //     sortable: true,
        //     width: "250px"
        // },
        // {
        //     name: 'Description',
        //     selector: row => row.description,
        //     sortable: true,
        //     width: "200px"
        // },
        {
            name: 'License Number',
            selector: row => row.license_number,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Latitude',
            selector: row => row.latitude,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Longitude',
            selector: row => row.longitude,
            sortable: true,
            width: "200px"
        },
        // {
        //     name: 'Longitude',
        //     selector: row => <>{row.permissionStatus==1? <span className='badge badge-success'>YES</span>:<span className='badge badge-danger'>NO</span>}</>,
        //     sortable: true,
        //     width: "200px"
        // },

         {
            name: 'Facility Type',
            selector: row => row.facility_type,
            sortable: true,
            width: "200px"
        },
        // {

        //     name: 'Facility Type',
        //     selector: row => <><img className='w-50' src="https://itmcloud.org/wmis-staging/datamanager/assets/lib/images/icon/wastepicker.svg" /></>,
        //     sortable: true,
        //     width: "200px"
        // },


        {
            name: 'Waste Type',
            selector: row => row.waste_types,
            // <><img className='w-50' src="https://itmcloud.org/wmis-staging/datamanager/assets/lib/images/icon/vehiclelist.svg" /></>,
            sortable: true,
            width: "200px"
        },
        {
            name: 'License Awarded From',
            // selector: row => row.date_awarded_from,
            selector: row=> !row.date_awarded_from ? '' : dayjs(row.date_awarded_from).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            sortable: true,
            width: "200px"
        },
        // {
        //     name: 'Date Awarded From',
        //     selector: row => <><img className='w-50' src="https://itmcloud.org/wmis-staging/datamanager/assets/lib/images/icon/vehiclelist.svg" /></>,
        //     sortable: true,
        //     width: "200px"
        // },
        // {
        //     name: 'Date Awarded To',
        //     selector: row => <><img className='w-50' src="https://itmcloud.org/wmis-staging/datamanager/assets/lib/images/icon/vehiclelist.svg" /></>,
        //     sortable: true,
        //     width: "200px"
        // },
          {
            name: 'License Awarded To',
            // selector: row => row.date_awarded_to,
            selector: row => !row.date_awarded_to ? '' : dayjs(row.date_awarded_from).format('DD/MM/YYYY' + ' ' + 'hh:mm:ss  A'),
            sortable: true,
            width: "200px"
        },
        {
            name: 'Picture',
            selector: row => row.document,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Permission Status',
            // selector: row => row.permissionStatus,
            selector: row => row.permissionStatus==1? <span className='badge badge-success'>Licensed</span>:<span className='badge badge-danger'>Non-Licensed</span> ,

            sortable: true,
            width: "200px"
        },
        // {
        //     name: 'number_working',
        //     selector: row => row.number_working,
        //     sortable: true,
        //     width: "200px"
        // },
        {
            name: 'Capacity',
            selector: row => row.capacity,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Operating Hours',
            selector: row => row.operating_hours,
            sortable: true,
            width: "200px"
        },
        {
            name: 'Operating Temperature',
            selector: row => row.operating_temperature,
            sortable: true,
            width: "200px"
        },
        {
            name:'No. Of Working Days',
            selector:row => row.no_working_days,
            sortable:true,

        },
        {
            name:'Available Services',
            selector:row => row.available_services,
            sortable:true,
            
        },
        // {
        //     name: 'Document',
        //     selector: row => <><img className='w-50' src="https://itmcloud.org/wmis-staging/datamanager/assets/lib/images/icon/vehiclelist.svg" /></>,
        //     sortable: true,
        //     width: "200px"
        // },
        // {
        //     name: 'Permission Status',
        //     selector: row => <><img className='w-50' src="https://itmcloud.org/wmis-staging/datamanager/assets/lib/images/icon/vehiclelist.svg" /></>,
        //     sortable: true,
        //     width: "200px"
        // },
        // {
        //     name: 'Area (sq. meter)',
        //     selector: row => <><img className='w-50' src="https://itmcloud.org/wmis-staging/datamanager/assets/lib/images/icon/vehiclelist.svg" /></>,
        //     sortable: true,
        //     width: "200px"
        // },
    
        {
            name: 'Action',
            width: "150px",
            cell: row =>
                <>
                    <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                    <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.status)}> {row.status ==1? 'Hide':'Show'}</a>
                    {/* <a class="dropdown-item" href="javascript:;" onClick={(e) => this.changeStatus(e, row._id, row.status)}> {row.status==1? 'Hide':'Show'}</a> */}
                        <a class="dropdown-item text-bold" onClick={(e)=>{this.editMode(e,row._id)}} data-target="#EditAreaModal" src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host="">Edit</a>
                     
                        <a class="dropdown-item" onClick={(e)=>{this.deleteEntry(e, row._id)}} href="javascript:;">Delete</a>
                    </div>
                </div> 



                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];






    // editDumpSite = async (e, id) => {

    //     const edData = [];
    //     this.state.filteredData.forEach((item)=>{
     
    //         if(item._id==id){
    //             console.log('ed1',id, item)
    //             edData.push(item)
    //            return;
    //         }

    //     });


    //     this.setState({editEntry:edData})
    //     this.setState({ isEdit: true })
    //     this.openAddModal();


    // }



    // changeSatatus = async (e,id) => {



    //     const dataTobe = {
    //         "id": id,
    //         "status": e.target.checked
    //     }
    //     AdminService.httpPost('/data-import/dumpsite/update-status', dataTobe).then((res) => {

    //         if (res) {
    //             this.setState({ loader: false })
    //             let d = res;
    
    //             console.log(res)
    
    //             if(  res.success == true){
    //                 toast.success("Status Updated Successfuly!");
    //                 this.getData();
    //             }
    //             else{
    //                 toast.error("Failed to update data!");
    
    //             }
           
    //         }
    
    //     }).catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     });

    // }


    // getData = async () => {
    //     this.setState({ loader: true })


    //     AdminService.httpGet('/data-retrieval/dumpsite/get-all-dumpSite').then((res)=>{
    //                 if(res){
    //                     this.setState({loader:false})
    //                     let d = res.data;
    //                     this.setState({filteredData: d, data:d})
    //                 }

    //     }).catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     });


    // }




    // deleteEntry = (e, id) =>{

    //     swal({
    //         title:"Are you sure?",
    //         text: "Once deleted, you will not be able to recover this dump site!",
    //           icon: "warning",
    //           buttons: true,
    //           dangerMode: true,
    //         })
    //         .then((willDelete) => {
    //           if(willDelete) {
    //             const dataToBeSend = {
    //                 "id": id
    //             }

    //             console.log(id)
            
    //             AdminService.httpPost('/data-import/dumpsite/delete-dump-site', dataToBeSend).then((res) => {
    
    //                 if (res) {
    //                     this.setState({ loader: false })
    //                     let d = res;
            
    //                     console.log(res)
            
    //                     if(  res.success == true){
    //                         toast.success("Data Deleted Successfuly!");
    //                         this.getData();
    //                     }
    //                     else{
    //                         toast.error("Failed to delete data!");
            
    //                     }
                   
    //                 }
            
    //             }).catch((err) => {
    //                 this.setState({ loader: false })
    //                 console.log(err)
    //             });
    //           }                
    //         });     
    
    
    
    
    
    
    //   }
    
    


 

    // save data to server======================./


    // saveData = async (e) => {

   
    //         this.addNewSite(e)
        

    // }




    // add new dump site=====================//


    // addNewSite = async (e) =>{
    //     this.setState({ loader: true })
    //     AdminService.httpPost('/data-import/dumpsite/add', e).then((res) => {
    
    //         if (res) {
    //             this.setState({ loader: false })
    //             let d = res;
    
    //             console.log("test===============>",res)
    
    //             if(  res.status == true){
    //                 toast.success("Data added Successfuly!");
    //                 this.getData();
    //             }
    //             else{
    //                 toast.error("Failed to add data!");
    
    //             }
           
    //         }
    
    //     }).catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     });
        
    // }

    // open add modal=====================//


    // openAddModal = () => {

    //     if (this.state.modal == false) {

    //         this.setState({ modal: true })
           

    //     } else {
    //         this.setState({ modal: false })
         
    //     }

    // }

/////////================================facilites  all apis================== /////






// editDataEntry = (id) => {


//     const edData = [];
//     this.state.filteredData.forEach((item)=>{

//         if(item._id==id){
//             edData.push(item)
//            return;
//         }

//     });

//     this.setState({editEntry:edData})
//     this.setState({ isEdit: true })
//     this.openAddModal();

// }


//    // open add modal=====================//
//    openAddModal = () => {
//     console.log("data" , this.state.modal);
// if (this.state.modal == false) {

//     this.setState({ modal: true })
   

// } else {
//     this.setState({ modal: false })
//     this.setState({ isEdit: false })
    
//     this.setState({ editEntry: [] })
// }

// }



////edit function//////////////

editMode (event, row_id) {
    event.preventDefault();
    this.setState({isEdit:true,
                    modal: true});

    this.state.data.forEach((item)=>{
        if(item._id==row_id){
            this.setState({editModeId:item});
        }
    });
                    
    this.openEditModal()
    
}


//////=========== open model =============
openAddModal = () => {

    if (this.state.modal == false) {

        this.setState({ modal: true })

    } else {
        this.setState({ modal: false,
                        isEdit: false,
                        editModeId: null })
    }

}

///////=============open edit model================

openEditModal(){
    if($('#EditAreaModal').hasClass('width350')){
        $('#EditAreaModal').removeClass('width350')
        $('#EditAreaModal').addClass('width0')
        $('.overlay').removeClass('dblock')
        this.setState({ modal: false })
    }
    else{
        $('#EditAreaModal').addClass('width350')
        $('#EditAreaModal').removeClass('width0')
        $('.overlay').addClass('dblock')
        this.setState({ modal: true })
    }
}



//////////////////1.=============get api=====================////////////////

getData = async () => {
    this.setState({ loader: true })


    AdminService.httpGet('/data-import/facility/getAll').then((res)=>{
                if(res){
                    this.setState({loader:false})
                    let d = res.data;
                    // d.forEach((ele  , index)=>{ele.index = index })
                    this.setState({filteredData: d, data:d})
                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });


}



///////=============delete api ====================/////////////
deleteEntry = (e, id) =>{

    swal({
        title:"Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this facility!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
          if(willDelete) {
            const dataToBeSend = {
                "selectedIds": id
            }
            AdminService.httpPost('/data-import/facility/delete', dataToBeSend).then((res) => {

                if (res) {
                    this.setState({ loader: false })
                    let d = res;
                    if(  res.success == true){
                        toast.success("Facility deleted successfully!");
                        this.getData();
                    }
                    else{
                        toast.error("Failed to delete facility!");
        
                    }
               
                }
        
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
          }                
        });     






  }

   ////=============== get all area data==========================//

   getAllAreaData = async () => {
    

    await AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {

        
        if (res) {
            let d = res.data;
            this.setState({ areaData: d})
        }



    }).catch((err) => {
        
        console.log('errrr',err)
    });

}



  ////////////===============change status =============////////////



changeStatus(event, id, status) {
    let changed = 0;
    if(status==1){
        changed = 0;
    }
    else{
        changed = 1;
    }
    let statusData = { "_id": id, "status": changed };

    AdminService.httpPost('/data-import/facility/update-status', statusData).then((res) => {
        
        if (res) {
            this.setState({ loader: false })
            let d = res;

            console.log(res)

            if(  res.success == true){
                toast.success("Status updated successfully!");
                this.getData();
            }
            else{
                toast.error("Failed to update status");

            }
       
        }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });


}

//     fetch(constants.server+"api/data-import/facility/update-status", {
//         method: 'POST',
//         body: JSON.stringify(statusData),
//         headers: {
//             'Authorization': this.props.token, 
//             'Content-type': 'application/json; charset=UTF-8',
//          },
//     })
//     .then((res) => res.json())
//     .then((data) => {
//         //toastr
//         toast.success("status updated successfully!");
//         this.getData();
//     })
//     .catch((err) => {
//         //toastr
//         toast.error("Error in status update");
//     });
// }










    // add new dump site=====================//

    saveDumpData = (saveData) => {
        this.setState({loader:true})
        if( saveData.id!=null){
          console.log("balleballe======>",saveData)
        const data= {
                         "id": saveData.id,
                         "location":saveData.location,
                         "area":saveData.areaName,
                         "facility_name": saveData.facility_name,
                          "facility_type": saveData.facility_type,
                          "latitude": saveData.latitude,
                          "longitude":saveData.longitude,
                         "license_number": saveData.license_number,
                         "date_awarded_from":saveData.date_awarded_from,
                          "date_awarded_to":saveData.date_awarded_to,
                           "document": saveData.document,
                            "waste_types": saveData.newWaste_types,
                            "permissionStatus":saveData.permissionStatus,
                            "capacity":saveData.capacity,
                            "operating_hours":saveData.operating_hours,
                            "no_working_days":saveData.no_working_days,
                            "available_services":saveData.available_services,
                            "operating_temperature":saveData.operating_temperature,
        }

        
        AdminService.httpPut('/data-import/facility/update/'+saveData.id,data).then((res)=>{
            if(res){
                // console.log("data" , res );
        
                let d = res.data;
                if(  res.status != 0 && res.success==true){
                    this.setState({ loader: false })
                    toast.success("Facility updated successfully!");
                    this.openAddModal();
                    this.getData();
                    this.setState({loader:false})
                }
    
                else if(res.status == 0 ){
                    
                    this.setState({ loader: false })
                    toast.error('Failed To update facility');
    
                }
         
                else{
                    this.getData();
                    this.openAddModal();
                    this.setState({ loader: false })
                    toast.success(res.message);
    
                }
           
            }

    
    }).catch((err) => {
    this.setState({ loader: false })
    toast.error("Failed to update facility");
    });
      
    }
    else{
            const getdata = {
                "id": "",
                "location":saveData.location,
                "area":saveData.areaName,
                "facility_name": saveData.facility_name,
                "facility_type": saveData.facility_type,
                "latitude": saveData.latitude,
                "longitude":saveData.longitude,
                "license_number": saveData.license_number,
                "date_awarded_from":saveData.date_awarded_from,
                "date_awarded_to":saveData.date_awarded_to,
                "document": saveData.document,
                "waste_types": saveData.newWaste_types,
                "permissionStatus":saveData.permissionStatus,
                "capacity":saveData.capacity,
                "operating_hours":saveData.operating_hours,
                "no_working_days":saveData.no_working_days,
                "available_services":saveData.available_services,
                "operating_temperature":saveData.operating_temperature,
            }
    
    
        console.log("test by done",getdata)
        // return false
        AdminService.httpPost('/data-import/facility/add',getdata).then((res)=>{
            if(res){
                console.log('data--------->'  , res);
                let d = res.getdata;
                if(  res.success == true){
                   this.setState({ loader: false })
                   toast.success("Facility added successfully!");
                   this.openAddModal();
                   this.getData();
               }
        
               else{
                   this.openAddModal();
                   this.getData();
                   this.setState({ loader: false })
   
                   toast.error('Failed to add facility');
   
   
               }

    
            }
            }).catch((err) => {
                this.setState({ loader: false })
                toast.error('Failed to add facility');
    
                console.log(err)
            });
     }
        //   console.log("function called with state");
        //   console.log(saveData);
        //   this.getData();
        //   this.openAddModal();
    
      
        }
    
    
      ///gettemepeatureData///
      getOperatingTemeperatureData = async () => {
        this.setState({ loader: true })
    
    
        AdminService.httpGet('/data-import/master/getAll').then((res)=>{
                    if(res){
                        
                        let d = res.data;
                        let bindData = d['Operating Temeperature'];
                        console.log("Sangeeta12", bindData);
                        // d.forEach(item => {
            
                        //     bindData.push({ label: item.['Operating hours']})
                   
            
                        // });
                        console.log('testfacility',JSON.stringify(bindData) )
                        
                        this.setState({ operatingtemeperatureData: bindData, rowIndData:d, treeData: d[constants.masterListType[3]] })
                    
                       
                    }
    
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    
    
    } 



      ///getAvailableServicesData///
      getAvailableServicesData = async () => {
        this.setState({ loader: true })
    
    
        AdminService.httpGet('/data-import/master/getAll').then((res)=>{
                    if(res){
                        
                        let d = res.data;
                        
                        console.log("Sangeeta data111", d);
                        let bindData = d['Available Services'];
                        console.log("Sangeeta1", bindData);
                        // d.forEach(item => {
            
                        //     bindData.push({ label: item.['Operating hours']})
                   
            
                        // });
                        console.log('testfacility',JSON.stringify(bindData) )
                        this.setState({ availableData: bindData, rowIndData:d })
                    
                       
                    }
    
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    
    
    }

///facility data /////
getfacitlityData = async () => {
    this.setState({ loader: true })


    AdminService.httpGet('/data-import/master/getAll').then((res)=>{
                if(res){
                    
                    let d = res.data;
                    let bindData = d['Facility Type'];
                    console.log("Sangeeta444444", bindData);
                
                    // console.log('testfacility',JSON.stringify(bindData) )
                    this.setState({ facilityDatatest: bindData, rowIndData:d })
                
                   
                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });


}






getOperationData = async () => {
    this.setState({ loader: true })


    AdminService.httpGet('/data-import/master/getAll').then((res)=>{
                if(res){
                    
                    let d = res.data;
                    let bindData = d['Operating hours'];
                    console.log("Sangeeta", bindData);
                
                    // console.log('testfacility',JSON.stringify(bindData) )
                    this.setState({ facilityData: bindData, rowIndData:d })
                
                   
                }

    }).catch((err) => {
        this.setState({ loader: false })
        console.log(err)
    });


}

    //waste and facitlity type data get


    // getWasteData = async () => {
    //     this.setState({ loader: true })
    
    
    //     AdminService.httpGet('/data-retrieval/data/masterData').then((res)=>{
    //                 if(res){
                        
    //                     let d = res[0].data;
    //                     this.setState({ facilityData: d})
                       
    //                 }
    
    //     }).catch((err) => {
    //         this.setState({ loader: false })
    //         console.log(err)
    //     });
    
    
    // }


/////multiple delete 

    handleChange = ({ selectedRows }) => {
  
        this.setState({bulkDelete:selectedRows})
    
      };
      
      
    
        handleChangeData = ({ toggleCleared }) => {
            this.setState({bulkDelete:toggleCleared})
    
      };
      
    
    
    
    
      
    
      //////////=========checkbox delete============/////////
    
      deleteBulkUser = (e) =>{
    let ids = [];
    
    this.state.bulkDelete.forEach(item=>{
        ids.push(item._id)
    })
    
    if(ids?.length > 0){
                swal({
                    title:"Are you sure you want to delete?",
                    text: "Once deleted, you will not be able to recover this facility!",
                      icon: "warning",
                      buttons: true,
                      dangerMode: true,
                    })
                    .then((willDelete) => {
                      if(willDelete) {
                        const data= {
                            "id": ids.toString()
                        }
                        AdminService.httpPost('/data-import/facility/delete-facility', data).then((res) => {
            
                            if (res) {
                                this.setState({ loader: false })
                                let d = res;
                    
                                console.log(res)
                    
                                if(res.success==true){
                                    toast.success("Facility deleted successfully!");
                                    this.getData();
                                }
                                else{
                                    toast.error("Failed to deleted facility");
                    
                                }
                           
                            }
                    
                        }).catch((err) => {
                            this.setState({ loader: false })
                            console.log(err)
                        });
                      }                
                    });     
                }
            

                else{
                    swal({
                        title:"No Record Selected!",
                        text: "Please select at a record to delete.",
                          icon: "warning",
                          button: true,
                          
                        })
                
                        return false;
                
                
                }
            }



}

const mapStateToProps = (state) => ({
    token: state.login.token
});

export default connect(
    mapStateToProps
  )(DumpSite);