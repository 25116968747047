import React from 'react'  
import './Footer.jsx'
import {
  MDBFooter
} from 'mdb-react-ui-kit';
import { Link } from 'react-router-dom';

function Footer(){

    return (
        <>

          <section className='footer_area'>
              <div className="container-fluid">
                   <div className="row align-items-center">
                       <div className="col-md-6 footer_left">
                         <p> @<span>{new Date().getFullYear()}</span>. WMIS Eswatini. All Rights Reserved</p>
                       </div>
                       <div className="col-md-6 footer_right">
                        <p>Powered by  <span><a className='text-white' style={{'textDecoration':'none'}} href="http://www.itechmission.org/" target={'_blank'}><strong>iTech Mission</strong></a></span></p>
                       </div>
                   </div>
              </div>
          </section>

        
        {/* <MDBFooter className='bg-dark text-center text-white'>
          <div className="col-lg-12">
          <div className="row">
          <div className="col-lg-6 text-left">
          <div className='text-left pt-3 pr-3 pb-3 pl-5' style={{ 'backgroundColor': 'rgba(0, 0, 0, 0.2)', 'textAlign':'left','paddingLeft':'7rem'  }}>
          &copy; {new Date().getFullYear()} Copyright:{' '} All rights reserved

          </div>
          </div>

          <div className="col-lg-6 text-right">
          <div className='text-right p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)', 'textAlign':'right' }}>

          <span><small>Developed by: </small><a className='text-white' style={{'textDecoration':'none'}} href="http://www.itechmission.org/" target={'_blank'}><strong>iTech Mission</strong></a></span>

          </div>
          </div>
          </div>
          </div>

        </MDBFooter> */}
        </>
    );
}

export default Footer;