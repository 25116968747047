import { Component } from 'react'
import React, { useState } from 'react'
import './profile.css';
import * as jsx from './profile.module.jsx';
import * as constants from '../../../Config/app.constants';
import {toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AdminService from '../../../services/admin.service';
import { connect, useDispatch } from "react-redux";
import eventBus from '../../../features/eventBus';
import { useNavigate } from 'react-router';
import history from '../../../services/history';
import { NavHashLink as NavLink } from 'react-router-hash-link';
import swal from 'sweetalert';
import * as $ from 'jquery';
class ProfilePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            value:0,
            loader:false,
            sendStartData:null,
            profileOpen:false,
            pData:[],
            sendAreaData:[],
            isSaveAndExit: false,
            treeData:[],
            masterData:[],
            status: '',
        }
    }


    componentDidMount() {
        this.profileDone()
        this.getAreaData()
        this.getMasterData()
        this.getstatus()
        this.showModal();
      
    }



    render() {
        return jsx.default.bind(this)();
    }

   



    handleChange = (event, newValue) => {
        this.setState({value : newValue} );
    };

    completion =(e)=>{
    localStorage.setItem('perc', e);
       console.log('dsdsdsdsdsdsdsds',e)
        eventBus.dispatch("completion", { message: e });
    }

    isSaveAndExit =(e)=>{
       
        if(e==true){
            this.setState({isSaveAndExit: true})
        } else{
            this.setState({isSaveAndExit: false})
        }
    }
    // applyCoupon = () => {
       
    //     eventBus.dispatch("couponApply", { message: "coupone applied" });
    //   };

    getAreaData = async () => {
        this.setState({ loader: true })
    
        AdminService.httpGet('/data-retrieval/area/getAreaData').then((res)=>{
            
                    if(res){
                        this.setState({loader:false})
                        let d = res.data;
                        this.setState({sendAreaData: d})
                    }
    
        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    
    
    }


getstatus = () => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    // const data  = {};
    if (userInfo != null && userInfo != 'null' && userInfo != undefined && userInfo != '') {
     const  data = {
        "user_id": userInfo?.id
      }
    AdminService.httpPost('/data-import/actor/getById', data).then((res) => {
        console.log("responsee11-----", res?.data?.[0]?.status)

    
                this.setState({status : res?.data?.[0]?.status})

      })}

}

showModal = () =>{

    let perc =  localStorage.getItem('perc');
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
 
    // console.log('tester',  Math.round(parseInt(perc)),  parseInt(userInfo?.profile_percentage), userInfo?.profileStatus != 1, userInfo  )
    
 //    if(( Math.round(parseInt(perc)) < 100 || Math.round(parseInt(userInfo?.profile_percentage)) < 100 ) && this.state.status != 1 ){
     
 if(parseInt(userInfo?.profile_percentage) < 100 && userInfo?.profileStatus != 1  ){
     swal({
         title:"Please complete your profile for approval.",
         className: "profileLoad_modal" 
     })
    }
  }



    getMasterData = () => {
        AdminService.httpGet('/data-import/master/getAll').then((res) => {
            if (res) {
                this.setState({ loader: false })
                let d = res.data;



                // d.forEach((ele  , index)=>{ele.index = index })
         
                this.setState({treeData: d['Waste Type'], masterData: d })
              


                console.log('wstyp', d['Waste Type'])
                // if(this.state.filterCat=="actor"){
                //     this.setState({ filteredData: d.Actors })
                // }else if(this.state.filterCat=="waste"){
                //     this.setState({ filteredData: d.Waste_Types })
                // }else {
                //     this.setState({ filteredData: d.Facility_Types })
                // }
                
                
                this.setState({ loader: false })
            }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });
    }



    profileDone = () => {
        this.setState({loader:true})
        const id1 = localStorage.getItem('userInfo')
        const gData = JSON.parse(id1)
        console.log('dsaasasassasa', gData.id)
      
        let d;
        AdminService.httpGet(`/data-import/actor/getByUserId/${gData.id}`)
        .then(data=>{
            if(data.success === true){
                this.setState({loader: false, status: data.data[0]?.status})
                console.log('GET DATA CHECKUP FOR PROGRESS-----',localStorage.getItem(data.data[0]))
             this.setState({pData: [data.data[0], data.data[0]]})
             if(data.data!=null && data.data!=''){

                if( data.data[0]?.profile_type?.length == 0 ||  data.data[0]?.profile_type?.[0] ==''){
                  this.setState({profileOpen:false})
                }
                else{
                    this.setState({profileOpen:true})
                }


               
                // eventBus.dispatch("completion", { message: data.data[0] });
                this.setState({loader: false})
             }
         
             console.log(this.state.profileDone)
            }
            else {
                throw new Error("Error in getting actor info")
            }
        })
    }



    getStartData =(e)=>{
        
        this.setState({sendStartData:e})
    
  
      }

      inComplete = (e) => {

        if(e==true){
            this.setState({profileOpen:false})
        }

      }

    saveStep1 = (data, isExist) => {
        console.log('data', data)

        this.setState({loader: true})

        AdminService.httpUserPost('/data-import/actor/add', data).then((res)=>{

            console.log(res['success'])
            if(res['success']===true){
                
                    // this.getDataAgain()
                    
          
                        this.setState({loader: false})
                        toast.success('Profile updated successfully!') 
                        this.setState({profileOpen:true})

                     if(isExist==true){
                        swal(
                            "Your profile saved successfully!",
                            "",
                            "assets/lib/images/home/tick.svg"
                        ).then((ok) => {
                            if (ok) {
                        history.push('/home');
                        $(window).scrollTop(0);

                            }});
                     }
                    //  sas
                        
                  



            }else if(res['success']==false){
                this.setState({loader: false})
                this.getDataAgain()
                toast.error('Profile updation failed!') 
            }
            
        }).catch((err)=>{
            this.setState({loader: false})
            toast.error(err) 
            console.log('Profile js updation failed error:---',err)
        })

    }





getDataAgain =()=>{
    const id1 = localStorage.getItem('userInfo')
    const gData = JSON.parse(id1)
    AdminService.httpGet(`/data-import/actor/getByUserId/${gData.id}`)
    .then(data=>{

        console.log('tab data is', data.data[0])

        console.log('testcor', data.data[0]?.profile_type?.length ,  data.data[0]?.profile_type?.[0] )
        if( data.data[0]?.profile_type?.length == 0 ||  data.data[0]?.profile_type?.[0] ==''){
          this.setState({profileOpen:false})
        }


        if(data.success === true){
          let dt =   data?.data?.[0]
          this.setState({pData:dt})

      
          this.forceUpdate();
        }
        else {
            throw new Error("Error in getting actor info")
        }
    })
}


}


export default connect(
   
  )(ProfilePage);





























// import { Component } from 'react'
// import './profile.css';
// import * as jsx from './profile.module.jsx';
// import * as constants from '../../../Config/app.constants';
// import {toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";
// import AdminService from '../../../services/admin.service';
// import { connect, useDispatch } from "react-redux";
// import eventBus from '../../../features/eventBus';
// import { useNavigate } from 'react-router';
// import history from '../../../services/history';

// class ProfilePage extends Component {
//     constructor(props) {
//         super(props)
//         this.state = {
//             value:0,
//             loader:false,
//             sendStartData:null,
//             profileOpen:false,
//             pData:[],
//             sendAreaData:[],
//             isSaveAndExit: false,
//             treeData:[],
//             masterData:[]
//         }
//     }


//     componentDidMount() {
//         this.profileDone()
//         this.getAreaData()
//         this.getMasterData()
      
//     }



//     render() {
//         return jsx.default.bind(this)();
//     }
//     handleChange = (event, newValue) => {
//         this.setState({value : newValue} );
//     };

//     completion =(e)=>{
//     localStorage.setItem('perc', e);
//        console.log('dsdsdsdsdsdsdsds',e)
//         eventBus.dispatch("completion", { message: e });
//     }

//     isSaveAndExit =(e)=>{
       
//         if(e==true){
//             this.setState({isSaveAndExit: true})
//         } else{
//             this.setState({isSaveAndExit: false})
//         }
//     }
//     // applyCoupon = () => {
       
//     //     eventBus.dispatch("couponApply", { message: "coupone applied" });
//     //   };

//     getAreaData = async () => {
//         this.setState({ loader: true })
    
//         AdminService.httpGet('/data-retrieval/area/getAreaData').then((res)=>{
            
//                     if(res){
//                         this.setState({loader:false})
//                         let d = res.data;
//                         this.setState({sendAreaData: d})
//                     }
    
//         }).catch((err) => {
//             this.setState({ loader: false })
//             console.log(err)
//         });
    
    
//     }



//     getMasterData = () => {
//         AdminService.httpGet('/data-import/master/getAll').then((res) => {
//             if (res) {
//                 this.setState({ loader: false })
//                 let d = res.data;


//                 // d.forEach((ele  , index)=>{ele.index = index })
         
//                 this.setState({treeData: d['Waste Type'], masterData: d })
              


//                 console.log('wstyp', d['Waste Type'])
//                 // if(this.state.filterCat=="actor"){
//                 //     this.setState({ filteredData: d.Actors })
//                 // }else if(this.state.filterCat=="waste"){
//                 //     this.setState({ filteredData: d.Waste_Types })
//                 // }else {
//                 //     this.setState({ filteredData: d.Facility_Types })
//                 // }
                
//                 console.log('mc stan', d)
//                 this.setState({ loader: false })
//             }

//         }).catch((err) => {
//             this.setState({ loader: false })
//             console.log(err)
//         });
//     }



//     profileDone = () => {
//         this.setState({loader:true})
//         const id1 = localStorage.getItem('userInfo')
//         const gData = JSON.parse(id1)
//         console.log('dsaasasassasa', gData.id)
      

//         AdminService.httpGet(`/data-import/actor/getByUserId/${gData.id}`)
//         .then(data=>{

//             if(data.success === true){
//                 this.setState({loader: false})
//                 console.log('GET DATA CHECKUP FOR PROGRESS-----',localStorage.getItem(data.data[0]))
//              this.setState({pData: [data.data[0], data.data[0]]})
//              if(data.data!=null && data.data!=''){

//                 if( data.data[0]?.profile_type?.length == 0 ||  data.data[0]?.profile_type?.[0] ==''){
//                   this.setState({profileOpen:false})
//                 }
//                 else{
//                     this.setState({profileOpen:true})
//                 }


               
//                 // eventBus.dispatch("completion", { message: data.data[0] });
//                 this.setState({loader: false})
//              }
         
//              console.log(this.state.profileDone)
//             }
//             else {
//                 throw new Error("Error in getting actor info")
//             }
//         })
//     }



//     getStartData =(e)=>{
        
//         this.setState({sendStartData:e})
    
  
//       }

//       inComplete = (e) => {

//         if(e==true){
//             this.setState({profileOpen:false})
//         }

//       }

//     saveStep1 = (data, isExist) => {
//         console.log('data', data)

//         this.setState({loader: true})

//         AdminService.httpUserPost('/data-import/actor/add', data).then((res)=>{

//             console.log(res['success'])
//             if(res['success']===true){
                
//                     // this.getDataAgain()
                    
          
//                         this.setState({loader: false})
//                         toast.success('Profile updated successfully!') 
//                         this.setState({profileOpen:true})

                     
//                    if(isExist == true){
                    
//                     history.push('/dashboard/tracker')
//                    }
                  



//             }else if(res['success']==false){
//                 this.setState({loader: false})
//                 this.getDataAgain()
//                 toast.error('Profile updation failed!') 
//             }
            
//         }).catch((err)=>{
//             this.setState({loader: false})
//             toast.error(err) 
//             console.log('Profile js updation failed error:---',err)
//         })

//     }





// getDataAgain =()=>{
//     const id1 = localStorage.getItem('userInfo')
//     const gData = JSON.parse(id1)
//     AdminService.httpGet(`/data-import/actor/getByUserId/${gData.id}`)
//     .then(data=>{

//         console.log('tab data is', data.data[0])

//         console.log('testcor', data.data[0]?.profile_type?.length ,  data.data[0]?.profile_type?.[0] )
//         if( data.data[0]?.profile_type?.length == 0 ||  data.data[0]?.profile_type?.[0] ==''){
//           this.setState({profileOpen:false})
//         }


//         if(data.success === true){
//           let dt =   data?.data?.[0]
//           this.setState({pData:dt})

      
//           this.forceUpdate();
//         }
//         else {
//             throw new Error("Error in getting actor info")
//         }
//     })
// }


// }


// export default connect(
   
//   )(ProfilePage);