import { Component, useRef } from 'react'
import './login-form.css';
import * as jsx from './login-form.module.jsx';
import * as constants from '../../../Config/app.constants';
import { connect } from "react-redux";
import { login, logout } from '../../../features/loginSlice';
import history from '../../../services/history';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as $ from 'jquery';
import { store } from '../../../store';

class LoginForm extends Component {

  constructor() {
    super();

    this.state = {
      email: '',
      password: '',
      captcha: '',
      forgotCaptcha: '',
      captchaCode: '',
      forgotCaptchaCode: '',
      formErrors: { email: '', password: '', captcha: '', forgotCaptcha: '', error: '' },
      emailValid: false,
      passwordValid: false,
      captchaValid: false,
      forgotCaptchaValid: false,
      formValid: false,
      loginAttempts: 0,
      loggedIn: false,
      showPassword: false,
      error: false,
      path: '/'
    }
    // this.globalState = stateUtilityInstance.default;
    // console.log(this.globalState.getPropertyByName('token'));
  }
  // ======================================== Password Show/hide
  handleClickShowPassword = () => this.setState({ showPassword: !this.state.showPassword });
  handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  // ======================================== Password Show/hide
  componentDidMount() {
    this.createCaptcha('captcha');
  }

  render() {
    // const [showModal, setShowModal] = useState(false);
    return jsx.default.bind(this)();
  }

  handleInvalidLogin() {
    this.setState({error: true});
}

  handleCaptcha(event){
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value },
      () => { this.validateField(name, value) });
    this.setState({captcha: event.target.value, error: false})
  }

  handleUserInput(event) {

    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value })
      
  }


  checkValidation(event){
    const name = event.target.name;
    const value = event.target.value;
    this.validateField(name, value);
  }


  validateField(fieldName, value) {
    let fieldValidationErrors = this.state.formErrors;
    let emailValid = this.state.emailValid;
    let passwordValid = this.state.passwordValid;
    let captchaValid = this.state.captchaValid;
    let forgotCaptchaValid = this.state.forgotCaptchaValid;

    switch (fieldName) {
      case 'email':
        emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
        fieldValidationErrors.email = emailValid ? '' : 'Email is invalid';
        break;
      case 'password':
        passwordValid = value.length > 0;
        fieldValidationErrors.password = passwordValid ? '' : 'Please Enter your password';
        break;
      case 'captcha':
        captchaValid = this.state.captcha === this.state.captchaCode;
        fieldValidationErrors.captcha = captchaValid ? '' : 'Captcha mismatch';
        break;
      case 'forgotCaptcha':
        forgotCaptchaValid = this.state.forgotCaptcha === this.state.forgotCaptchaCode;
        fieldValidationErrors.forgotCaptcha = forgotCaptchaValid ? '' : ' mismatch';
        break;
      default:
        break;
    }
    this.setState({
      formErrors: fieldValidationErrors,
      emailValid: emailValid,
      passwordValid: passwordValid,
      captchaValid: captchaValid,
      forgotCaptchaValid: forgotCaptchaValid
    }, this.validateForm);
  }

  validateForm() {
    // this.validateField('email', this.state.email);
    // this.validateField('password', this.state.password);
    // this.validateField('captcha', this.state.captcha);
    this.setState({ formValid: this.state.emailValid && this.state.passwordValid && this.state.captchaValid });
  }
  store


  
  makeid(length) {
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for (var i = 0; i < length; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    return text;
  }

   progressState = store.getState()

  login = (event) => {
    console.log('www', this.props)
    // let navigate = useNavigate();
    event.preventDefault();
    //this.validateForm();
    if (!this.state.formValid) {
      return;
    }
    let formError = "";
    let email = this.state.email
    let passwordString = this.makeid(10) + this.state.password
    let password = btoa(passwordString);
    let that = this;
    fetch(constants.server + "api/data-retrieval/users/login", {
      method: 'POST',
      body: JSON.stringify({
        email: email, password: password
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      },
    })
      .then((res) => res.json())
      .then((data) => {
        console.log('DATA', data, this.props)
        if (data?.token) {
          that.state.loggedIn = true;

          this.props.login({
            loggedIn: that.state.loggedIn,
            email: that.state.email,
            token: data.token,
            userInfo: data.user_details,
          });
          var el= document.getElementsByClassName("modal-backdrop");
          var mopen= document.getElementsByClassName("modal-open");
          while(el.length > 0){
            el[0].parentNode.removeChild(el[0]);
            
          }
          while(mopen.length > 0){
            $(".modal-open").css({ 'padding-right' : '0'});  
            $("body").removeClass("modal-open");                        
          }
          
          let path = '';
          if([1,2,4].includes(data.user_details?.role)) {
            path = '/dashboard/tracker'
          } else if(data.user_details?.role == 5 && data.user_details?.profile_percentage == 100) {
            path = '/dashboard/tracker'
          } else {
            path = '/dashboard/profile'
          }
          
          // if(data.user_details?.role == 5){
          //   // history.goBack();
          //   path = '/dashboard/tracker';
          // }  
          
          // else if(data.user_details?.role == 2){
          //   path = '/admin/data-entry';
          //   // path = '/admin/import-log';
          // }
          
          // else if(data.user_details?.role == 4){
          //   path = '/admin/data-approve';
          // }
            
          // else {
          //   if(this.props.name == "/dashboard/tracker") {
          //     path = '/dashboard/tracker'
          //   } else {
          //     path = '/admin/summary';
          //   }
          // }

          this.setState({path: path});

        } else {
          this.props.logout();
          formError = "Invalid Login";
          that.setState({ formErrors: { error: formError } });
          this.handleInvalidLogin();
          toast.error(formError);
          this.createCaptcha('captcha')
        }
      })
      .catch((err) => {
        this.props.logout();
        formError = err.message;
        that.setState({ formErrors: { error: formError } });
        toast.error(formError);
      });
  }

  createCaptcha(divId) {

    //clear the contents of captcha div first 
    document.getElementById(divId).innerHTML = "";
    var charsArray =
      "23456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ@#$%^&*";
    var lengthOtp = 6;
    var captcha = [];

    for (var i = 0; i < lengthOtp; i++) {
      //below code will not allow Repetition of Characters
      var index = Math.floor(Math.random() * charsArray.length + 1); //get the next character from the array
      if (captcha.indexOf(charsArray[index]) == -1)
        captcha.push(charsArray[index]);
      else i--;
    }

    var canv = document.createElement("canvas");
    canv.id = divId;
    canv.width = 130;
    canv.height = 46;
    var ctx = canv.getContext("2d");

    //storing captcha so that can validate you can save it somewhere else according to your specific requirements
    var imageObj = new Image();
    imageObj.onload = function () {
      //ctx.drawImage(imageObj, 0, 10);
      //ctx.rotate(Math.PI * 2 / (i * 5));
      //ctx.font = "40pt Calibri";
      //ctx.fillText("My TEXT!", 20, 20);
      ctx.font = "25px Mulish";


      ctx.beginPath();
      ctx.moveTo(0, 30);
      //ctx.bezierCurveTo(140, 10, 388, 10, 388, 170);
      ctx.lineWidth = 2;
      // line color
      ctx.strokeStyle = "black";
      ctx.stroke();

      ctx.beginPath();
      ctx.moveTo(0, 65);
      //ctx.bezierCurveTo(140, 10, 388, 10, 388, 170);
      ctx.lineWidth = 2;

      // line color
      ctx.strokeStyle = "black";
      ctx.stroke();
      canv.setAttribute('dir', 'ltr');


      ctx.strokeText(captcha.join(""), 15, 40);
    };

    imageObj.src = 'assets/lib/images/captchabg.png';

    if (divId == 'captchaForgot') {
      this.setState({...this.state, forgotCaptchaCode: captcha.join("") });
    }
    else {
      this.setState({...this.state, captchaCode: captcha.join("") });
    }
    document.getElementById(divId).appendChild(canv); // adds the canvas to the body element

  }

  // forgotPassword() {
  //   let email = this.forgetPasswordForm.get('email').value;
  //   this.apiService.add("api/data-retrieval/users/submit-forgot-password-form", {
  //     "email": email
  //   }).subscribe((res: any) => {
  //     console.log("resss", res)
  //     if (res.status == 1) {
  //       this.toastr.success(this.translate.instant('Email_sent_successfully'));
  //       this.forgetPasswordForm.reset();
  //       $('#forgotPassword').hide();
  //       location.reload();
  //     }
  //     else {
  //       this.toastr.error(this.translate.instant('invalid_login'));
  //       $('#cpatchaForgotTextboxrellax').val('');
  //       this.toastr.error(res.data);
  //       this.createCaptcha('captcha');
  //       this.forgetPasswordForm.reset();
  //       $('#forgotPassword').hide();
  //     }
  //   })

  // }

  errorClass(error) {
    return (error?.length === 0 ? '' : 'has-error');
  }


}

const mapDispatchToProps = { login, logout };

export default connect(
  null,
  mapDispatchToProps
)(LoginForm);