import "./ExportArea.css";
import { useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useLocation } from 'react-router-dom'
import * as constants from '../../../Config/app.constants';

let headers =[];
let fname="";
let ftitle="";
const ExportWithoutData = (props) => {
  const [data, setData] = useState([]);
  const [downloadedData, setDownloadedData] = useState([]);
  const csvDownloadRef = useRef(0);

 const today = new Date();
 const currentYear = today.getFullYear();
 const currentMonth = (today.getMonth() + 1) < 10 ? '0' + (today.getMonth() + 1) : (today.getMonth() + 1);
 const currentDate = today.getDate() < 10 ? '0' + today.getDate() : today.getDate();
 const todaysDate = String(currentYear) + String(currentMonth) + String(currentDate);
console.log("val--"+props.value)
const location = useLocation();
  const route = location.pathname;

const downloadFile = (e) => {

  if(props.value==4){


    let fname = 'Area_template.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'WMIS_' + 'Area_template' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);





  }else if(props.value==5){


    let fname = 'Indicator_template.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'WMIS_' + 'Indicator_template' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);





  }
  else if(props.value==1){


    let fname = 'Data_template.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = fname='WMIS_' + 'Data_template' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();

              }, 1000);
              document.body.removeChild(a);





  }
  else if(props.value==6){


    let fname = 'Metadata_template.csv';
    let filepath = constants.exportfile + fname;
                
                console.log("filepath:"+filepath);
                let filename = fname.replace(/\.[^/.]+$/, "");
                
                
                
                let a = document.createElement("a");
                a.href = filepath;
                a.download = 'WMIS_' + 'Metadata_template' + todaysDate + '.csv';
                
                document.body.appendChild(a);
                setTimeout(() => {
                a.click();
                
                
                
              }, 1000);
              document.body.removeChild(a);





  }

}

  
  


  return (
        <div className="w-100">
         
            <button  class="btn btn_browse w-100 popup_btn" id="download_icius_master_template" onClick={downloadFile}> Empty </button>            
          
         
        </div>



  );
}


export default ExportWithoutData;