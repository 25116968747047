import React, { Component } from 'react'
import './resources.css';
import * as jsx from './resources.module.jsx';
import * as js from '../../Modals/resources.modal.js';
// import * as stateUtilityInstance from '../../../sharedData';
import * as constants from '../../../Config/app.constants';
import * as $ from 'jquery';
import { data } from 'jquery';
import AddAreaModal from '../../Modals/addArea.modal';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import swal from 'sweetalert';
import AdminService from '../../../services/admin.service';
import Tooltip from '@mui/material/Tooltip';
class Resources extends Component {
    arr = ["dxgf"]

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            filteredData: [],
            searchText: '',
            // mainData: [],
            isEdit: false,
            editModeId: null,
            modal: false,
            loader: false,
            dataLimit: 10,
            // image:"",
            // document:"",
            // innovationLogoBase64:"",
            // filename:"",
            dataStatus: 2,
            indicatorData: [],
            rowIndData: [],
            subIndData: [],
            areaData: [],
            bulkDelete :[],
            editEntry:[],

        }
        // this.globalState = stateUtilityInstance.default;



    }

    componentDidMount() {
        this.getData();
        // this.getAllIndicators();
        // this.getAllAreaData()

        AdminService.checkToken();
      
    }


    render() {
        return jsx.default.bind(this)();
    }



    // update data limit===================//


    updateDataLimit = (e) => {


        this.setState({ dataLimit: e.target.value })
        this.getData();

    }

    //  validateImage = (event)=> {

        
    //     let files = event;
    //     console.log("uuuu---"+files)
    //     // if (files.length) {
    //     //   this.isImgSelected = true
    //     // }
    //     // else {
    //     //   this.isImgSelected = false
    //     // }
    
    //     let file = files[0];
    
    
         
    //      this.setState({filename:files[0].name})
    
    
    //     if (files && file) {
    //       var reader = new FileReader();
    //       console.log("image", file)
    //       reader.onload = this._handleReaderLoaded.bind(file);
    
    //     //   reader.readAsBinaryString(file);
    //       //this.image = "";
    //     }
    //   };

    //   _handleReaderLoaded = (readerEvt) => {
    //     console.log("------a------"+readerEvt)
    //     let binaryString = readerEvt.target.result;
    //     // setInnovationLogoBase64(btoa(binaryString));
    //     this.setState({innovationLogoBase64:btoa(binaryString)})
    //     console.log("innnno", this.state.innovationLogoBase64);
        
    //     this.setImage(this.state.filename + ';' + this.state.innovationLogoBase64)
        
    
    // };


    // get data by status=================//


    getDataByStatus = (e) => {

        this.setState({ dataStatus: e.target.value })
        this.getData();
        
    }







    // filter table and get data====================//

    onSearch = (val) => {
        this.setState({searchText: val});
        let d = this.state.data;
        let fd=null;
        if(val == '') {
            fd = d;
        } else {
            // fd = d.filter(
            //     item => (item.iusData[0].name && item.areaData[0].area_code.toLowerCase().includes(val.toLowerCase())
            //         || (item.areaData[0].name && item.source?.publisher?.toLowerCase().includes(val.toLowerCase()))
            //     ));
            fd = d.filter(
                item => (item.title && item.title.toLowerCase().includes(val.toLowerCase())
                    || (item.description && item.description.toLowerCase().includes(val.toLowerCase()))
                    || (item.type && item.type.toLowerCase().includes(val.toLowerCase()))
                    || (item.createdAt && item.createdAt.toLowerCase().includes(val.toLowerCase()))
                ));
        }
       
        this.setState({ filteredData: fd });
    }


    columns = [
        // {
        //     name: '#',
        //     selector: (row, index) => row.index + 1,
                    
                    
        //     sortable: false,
        //     width: "50px"
        // },
        {
            name: 'Title',
            selector: row => <Tooltip title={row.title} placement="top">
                <p>{row.title}</p>
                </Tooltip>,
            sortable: true,
            
        },
        {
            name: 'Description',
            selector: row =>  <Tooltip title={row.description} placement="top">
            <p>{row.description}</p>
        </Tooltip>,
            sortable: true,
            
        },
        {
            name: 'Date',
            selector: row => row.createdAt,
            sortable: true,
            width: "300px"
        },
        {
            name: 'Type',
            selector: row => row.type,
            sortable: true,
            
        },
   
        {
            name: 'Action',
            width: "200px",
            cell: row =>
                <>
                    {/* <span className="action_specific ng-star-inserted">
                        <img onClick={(e)=>{this.editDataEntry(row._id)}} className="cursor-pointer modal-btn"  src="assets/lib/images/icon/edit_icon.svg"
                            style={{
                                'marginRight': '20px', 'touchAction': 'none', 'userSelect': 'none',
                                'WebkitTapHighlightColor': 'rgba(0, 0, 0, 0)'
                            }}
                            ng-reflect-message="Edit" aria-describedby="cdk-describedby-message-25"
                            cdk-describedby-host=""

                        />
            
                        <span class="deleteIcon text-success" onClick={(e)=>{this.deleteEntry(row._id)}}><i class="fa fa-trash"></i></span>

                      <span>  <label  className="switchone switch-checked" 
                        mattooltipposition="above" ng-reflect-position="above" 
                        ng-reflect-message="Status" aria-describedby="cdk-describedby-message-26" 
                        cdk-describedby-host="" 
                        style={{'touchAction': 'none', 'userSelect': 'none', 'WebkitUserDrag': 'none', 
                        'WebkitTopHighlightColor': 'rgba(0, 0, 0, 0)'}}>
                <input onChange={(e) => this.enableDis( row._id,row.status)} 
                        data-size="mini" 
                        type="checkbox" 
                        defaultChecked={row.status? row.status == 1: false}/>
                <div>
                </div>
                </label> 
                </span>

                    </span> */}

                    <div class="dropdown table-action">
                    <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown">
                        Select
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a class="dropdown-item  text-bold "  onClick={(e) => this.changeDataStatus(e, row._id, row.status )}  href="javascript:;" > {row.status==1? 'Hide':'Show'}</a>
                        <a class="dropdown-item  text-bold" onClick={(e)=>{this.editDataEntry(row._id)}} href="javascript:;">Edit</a>
                     
                        <a class="dropdown-item text-bold"  onClick={(e)=>{this.deleteEntry(row._id)}} href="javascript:;">Delete</a>
                    </div>
                </div> 

                </>,
            sortable: false,
            ignoreRowClick: true,
            allowOverflow: true,
            button: true
        },
    ];







    editDataEntry = (id) => {


        const edData = [];
        this.state.filteredData.forEach((item)=>{

            if(item._id==id){
                edData.push(item)
               return;
            }

        });

        this.setState({editEntry:edData})
        this.setState({ isEdit: true })
        this.openAddModal();

    }



    getData = async () => {
        this.setState({ loader: true, searchText: "" });
        const dataToBeSend = {
            // "size": this.state.dataLimit,
            // "totalElements": 0,
            // "totalPages": 0,
            // "pageNumber": 0,
            // "filterKeyWord": "",
            // "userId": "618a57dcd7854836b1c28ac2",
            // "startOffset": 0,
            // "endOffset": 0,
            // "level": [
            //     this.state.dataStatus
            // ]
        }


        AdminService.httpGet('/data-retrieval/resources/get-all-resources', dataToBeSend).then((res)=>{
                    if(res){
                        this.setState({loader:false})
                        let d = res.data;
                        d.forEach((ele  , index)=>{ele.index = index })
                        this.setState({filteredData: d, data:d})
                    }

        }).catch((err) => {
            this.setState({ loader: false })
            console.log(err)
        });






    }



    // get all indicators===================//

    // getAllIndicators = async () => {


    //     AdminService.httpGet('/data-retrieval/ius/get-ius-maping').then((res) => {
    //         if (res) {

    //             let d = res.data;
    //             console.log(d)

    //             let bindData = [];
    //             d.forEach(item => {

    //                 bindData.push({ label: item.name, value: item._id })

    //             });
    //             this.setState({ indicatorData: bindData, rowIndData:d })


    //         }

    //     }).catch((err) => {

    //         console.log(err)
    //     });






    // }


    // get sub indi cators ===================//

    // getSubIndicators = async (id) => {


    //                     const bindData = [];
    //                 this.state.rowIndData.forEach(item => {

    //                     if(item._id==id){
        
    //                         bindData.push({ label: item.subgroup[0].name, value: item.subgroup[0]._id })
     
    //                     }

                      

    //                 });

    //                 this.setState({ subIndData: bindData })
    //             }
           







    // get area data =====================//

    // getAllAreaData = async () => {

    //     AdminService.httpGet('data-retrieval/area/getAreaData').then((res) => {
    //         if (res) {

    //             let d = res.data;
    //             console.log(d)

    //             let bindData = [];
    //             d.forEach(item => {

    //                 bindData.push({ label: item.name, value: item._id })

    //             });
    //             this.setState({ areaData: bindData })


    //         }

    //     }).catch((err) => {

    //         console.log(err)
    //     });


    // }


        // get area data==================================//


        // getAllAreaData = async () => {
    

        //     await AdminService.httpGet('/data-retrieval/area/getAreaData').then((res) => {
    
                
        //         if (res) {
        //             let d = res.data;
        //             this.setState({ areaData: d})
       

        //         }

    
    
        //     }).catch((err) => {
                
        //         console.log('errrr',err)
        //     });
    
        // }



        // save data===========================//

        saveData = async (e) => {

            if(e.id!=null){

                this.updateEntry(e)

            }
            else{
                this.addNewEntry(e)
            }

        }


        updateEntry = async(e) =>{

        
            // this.setState({ loader: true })
            const dataToBe2= {

                "id": e.id,
                "description": e.description,
                "title": e.title,
                "url": e.url,
                "type": e.type,
                "doc": e.document,
                "image": e.image
               

            }
    
            console.log('dten', dataToBe2)
    
            AdminService.httpPut('/data-import/resources/update/'+e.id, dataToBe2).then((res) => {
                if (res) {
                    this.setState({ loader: false })
                    let d = res.data;

                    console.log(res)
                    if(  res.success == true){
                        toast.success("Resource updated successfully!");
                        this.openAddModal();
                        this.getData();
                        this.setState({ isEdit: false })
            
                        this.setState({ editEntry: [] })
                    }
                    else{
                        toast.error(res.message);

                    }
                 
               
                }
    
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
    
    


        }

        // add new entry=============================///


        addNewEntry = async (e) =>{

            console.log('dten', e)
            this.setState({ loader: true })
            const dataToBeSend = {
             
          
                "description": e.description,
                "title": e.title,
                "url": e.url,
                "type": e.type,
                "doc": e.document,
                "image": e.image
            }
    

            console.log('check',dataToBeSend)
    
            AdminService.httpPost('/data-import/resources/add-resource', dataToBeSend).then((res) => {
                if (res) {
                    this.setState({ loader: false })
                    let d = res.data;

                    console.log(res)

                    if(  res.success == true){
                        toast.success("Resource created successfully!");
                        this.openAddModal();
                        this.getData();
                    }
                    else{
                        toast.error(res.message);

                    }
               
                }
    
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
    
    


        }
    


        // update status==================//
        changeDataStatus(e, id, status) {
            this.setState({ loader: true })
            let changed = 0;
            
            if(status==1){
                changed = 0;
            }
            else{
                changed = 1;
            }
    
            let statusData = { "id": id, "resStatus": changed };
    
    
            AdminService.httpPost('/data-import/resources/update-status', statusData).then((res) => {
        
                if (res) {
                    this.setState({ loader: false })
                    let d = res;
        
                    console.log(res)
        
                    if(  res.success == true){
                        toast.success("Status updated successfully!");
                        this.getData();
                    }
                    else{
                        toast.error("Failed to update status");
        
                    }
               
                }
        
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
    
    
        }
    

        // delete entry===========//


  deleteEntry = (id) =>{

    swal({
        title:"Are you sure you want to delete?",
        text: "Once deleted, you will not be able to recover this resource entry!",
          icon: "warning",
          buttons: true,
          dangerMode: true,
        })
        .then((willDelete) => {
            this.setState({ loader: true })
          if(willDelete) {
            const dataToBeSend = {
                "id": id
            }
        
            AdminService.httpPost('/data-import/resources/delete-resource', dataToBeSend).then((res) => {

                if (res) {
                
                    let d = res;
                 
                    console.log(res)
                    this.setState({ loader: false })
                    
                    if(  res.success == true){
                        toast.success("Resource deleted successfully!");
                        this.getData();
                      
                    }
                    else{
                        toast.error( res.message);
        
                    }
               
                }
        
            }).catch((err) => {
                this.setState({ loader: false })
                console.log(err)
            });
          }                
        });     

  }


    // open add modal=====================//


    openAddModal = () => {

        if (this.state.modal == false) {

            this.setState({ modal: true })
           

        } else {
            this.setState({ modal: false })
            this.setState({ isEdit: false })
            
            this.setState({ editEntry: [] })
        }

    }



    enableDis = (e,value) => {
        this.setState({ loader: true })
        console.log("e--"+e)
        if(value==0){
            value=1;
        }else{
            value=0;
        }
  


        // this.setState({loader:true})
        const dataToBeSend = {
            
            id:e,
            resStatus: value
        
        }


        AdminService.httpPost('/data-import/resources/update-status',dataToBeSend).then((res)=>{
                    if(res){
      
                        let d = res.data;
                        // this.setState({filteredData: d, data:d})
                        this.getData(this.state.dataStatus);
                        
                        if(  res.message == 'Status  successfully updated!'){
                            toast.success("Status  successfully updated!");
                            // this.openAddModal();
                            // this.getData();
                        }
                        else{
                            toast.error("Failed to update status ");
    
                        }
                    }

        }).catch((err)=>{
            this.setState({loader:false})
            console.log(err)
        });


    }


///multiple delete

handleChange = ({ selectedRows }) => {
  
    this.setState({bulkDelete:selectedRows})

  };
  
  

    handleChangeData = ({ toggleCleared }) => {
        this.setState({bulkDelete:toggleCleared})

  };
  




  

  //////////=========checkbox delete============/////////

  deleteBulkUser = (e) =>{
let ids = [];

this.state.bulkDelete.forEach(item=>{
    ids.push(item._id)
})


if(ids?.length > 0){
    
            swal({
                title:"Are you sure you want to delete?",
                text: "Once deleted, you will not be able to recover this resource entry!",
                  icon: "warning",
                  buttons: true,
                  dangerMode: true,
                })
                .then((willDelete) => {
                  if(willDelete) {
                    const data= {
                        "_id": ids.toString()
                    }
                    AdminService.httpPost('/data-import/resources/delete-multiple-resource', data).then((res) => {
        
                        if (res) {
                            this.setState({ loader: false })
                            let d = res;
                
                            console.log(res)
                
                            if(res.success==true){
                                toast.success("Resource deleted successfully!");
                                this.getData();
                            }
                            else{
                                toast.error("Failed to delete resource");
                
                            }
                       
                        }
                
                    }).catch((err) => {
                        this.setState({ loader: false })
                        console.log(err)
                    });
                  }                
                });     
            }
        

else{
    swal({
        title:"No Record Selected!",
        text: "Please select at a record to delete.",
          icon: "warning",
          button: true,
          
        })

        return false;


}

        }




}







export default Resources;