import React, { useState, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import swal from 'sweetalert';
import adminService from '../../../services/admin.service';

function ConfirmEmail() {

  const [loader, setLoader] = useState(true);
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  console.log(searchParams)

  const email = searchParams.get("email")
  const code = searchParams.get("code")

  const data = {
    email: email,
    code: code
  }

  useEffect(() => {
    adminService.httpUserPost('/data-import/users/confirmEmail', data).then((res) => {

      if (res.success === true) {
        setLoader(false)
        swal(
          `${res.message}`,
          'Press OK to redirect to login page',
          'success'
        )
          .then((ok) => {
            if (ok) {
              setLoader(true)
              navigate('/login')
              setLoader(false)
            }
          })
      }
      else if (res.success === false) {
        setLoader(true)
        swal(
          "Email couldn't be confirmed",
          `${res.message}`,
          'error'
        ) 
        .then((ok)=>{
          if(ok){
            setLoader(true)
            navigate('/login')
            setLoader(false)
          }
        })
        setLoader(false)
      } else {
        throw new Error("Some error occurred")
      }

    }).catch(error => {
      toast.error(error.message)
    })
  }, [])

  return (
    <>
      <div className={loader ? 'loader text-center ' : 'loader text-center hide'}>
        <img src="assets/images/Comp-2.gif" style={{ 'margin-top': '12%', 'width': '30%' }} />
      </div>
      <h1>Verifying your email...</h1>
    </>
  )
}

export default ConfirmEmail;